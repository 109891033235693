/* purgecss start ignore */

.search {
  width: 25px;
  height: 25px;
}

.close {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 1rem;
  right: 1rem
}

@media (min-width: 768px) {
  .close {
    top: 2rem;
    right: 2rem;
    display: block
  }
}

/* purgecss end ignore */
