/* purgecss start ignore */

.searchResults :global([class^=ais-]) {
  font-size: inherit;
}

.searchResults :global(.ais-Hits-list) {
  margin: 0;
  display: grid;
  gap: 1rem;
}

@media (min-width: 768px) {
  .searchResults :global(.ais-Hits-list) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .searchResults :global(.ais-Hits-list) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.searchResults :global(.ais-Hits-item) {
  margin: 0;
  margin-bottom: 2rem;
  width: auto;
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 0;
  border: 0;
}

.searchResults :global(.ais-Highlight-highlighted) {
  margin-bottom: 1.5rem;
  --tw-bg-opacity: 1;
  background-color: rgba(66, 153, 225, var(--tw-bg-opacity));
  font-style: normal;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.searchResultsContainer {
  height: 55vh;
  overflow-y: auto
}

.searchResultsContainer::-webkit-scrollbar-track {
  background: transparent;
}

.searchResultsContainer::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  --tw-bg-opacity: 1;
  background-color: rgba(237, 242, 247, var(--tw-bg-opacity));
}

.searchResultsContainer::-webkit-scrollbar-thumb {
  --tw-bg-opacity: 1;
  background-color: rgba(160, 174, 192, var(--tw-bg-opacity));
}

@media (min-width: 640px) {
  .searchResultsContainer {
    height: 50vh
  }
}

.searchResultsContainerWrapper {
  position: relative
}

.searchResultsContainerWrapper:after {
  pointer-events: none;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  content: "";
  height: 100px;
  background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
  width: 100%;
}

.searchBox :global(.ais-SearchBox-input) {
  font-weight: 500;
  border-radius: 7px;
  padding: 1rem 1rem 1rem 3rem;
  border: 0;
  box-shadow: 0 0 2rem rgb(0 0 0 / 10%)
}

.searchBox :global(.ais-SearchBox-input):focus {
  outline: none;
}

.searchBox :global(.ais-SearchBox-submit) {
  left: 1rem;
  width: 16px;
  height: 19px;
}

.searchBox :global(.ais-SearchBox-submitIcon) {
  --tw-text-opacity: 1;
  color: rgba(66, 153, 225, var(--tw-text-opacity));
  width: 16px;
  height: 16px;
}

.searchBox :global(.ais-SearchBox-resetIcon path), .searchBox :global(.ais-SearchBox-submitIcon path) {
  fill: currentColor;
}

.searchBox :global(.ais-SearchBox-reset) {
  width: auto;
  align-items: center;
  --tw-bg-opacity: 1;
  background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(160, 174, 192, var(--tw-text-opacity));
  top: 50%;
  right: 1rem
}

.searchBox :global(.ais-SearchBox-reset) svg {
  fill: currentColor;
  margin: 0 auto;
}

.searchBox :global(.ais-SearchBox-resetIcon) {
  position: relative;
  left: 0;
  margin-right: 0.5rem;
  top: 0;
  transform: none;
}

.pagination {
  margin-top: 2rem
}

.pagination :global(.ais-Pagination-link) {
  border-bottom-width: 2px;
  border-color: transparent;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgba(44, 44, 44, var(--tw-text-opacity));
  --tw-text-opacity: 1;
  color: rgba(160, 174, 192, var(--tw-text-opacity))
}

@media (min-width: 768px) {
  .pagination :global(.ais-Pagination-link) {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.pagination :global(.ais-Pagination-link) {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  border-top: 0;
}

.pagination :global(.ais-Pagination-link):hover {
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgba(44, 44, 44, var(--tw-text-opacity));
}

.pagination :global(.ais-Pagination-link--selected) {
  border-bottom-width: 2px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgba(44, 44, 44, var(--tw-border-opacity));
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgba(44, 44, 44, var(--tw-text-opacity))
}

.pagination :global(.ais-Pagination-link--selected):focus, .pagination :global(.ais-Pagination-link--selected):hover {
  --tw-text-opacity: 1;
  color: rgba(44, 44, 44, var(--tw-text-opacity));
}

.pagination :global(.ais-Pagination-item--previousPage) :global(.ais-Pagination-link), .pagination :global(.ais-Pagination-item--nextPage) :global(.ais-Pagination-link) {
  --tw-text-opacity: 1;
  color: rgba(44, 44, 44, var(--tw-text-opacity));
}

.hitDates {
}

.hitDatesItem {
  /* margin: 0 0.25rem; */
}

.hitDatesItem:after {
  display: inline-block;
  margin: 0 0.5rem;
  content: " | ";
}

.hitDatesItem:last-child:after {
  content: "";
}

.indexSelector {
}

.indexSelectorItem {
  margin-bottom: 0;
  cursor: pointer;
  border-radius: 0.375rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .indexSelectorItem {
    margin-right: 1.5rem;
  }
}

.indexSelectorItemActive {
  --tw-bg-opacity: 1;
  background-color: rgba(160, 174, 192, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

/* purgecss end ignore */
