/* purgecss start ignore */

@import "https://cloud.typography.com/715654/6644392/css/fonts.css";

@import "https://cdn.jsdelivr.net/npm/instantsearch.css@7/themes/algolia-min.css";

*, ::before, ::after{
  --tw-border-opacity: 1;
  border-color: rgba(237, 242, 247, var(--tw-border-opacity));
}

.tw-container{
  width: 100%;
}

@media (min-width: 640px){
  .tw-container{
    max-width: 640px;
  }
}

@media (min-width: 768px){
  .tw-container{
    max-width: 768px;
  }
}

@media (min-width: 1024px){
  .tw-container{
    max-width: 1024px;
  }
}

@media (min-width: 1280px){
  .tw-container{
    max-width: 1280px;
  }
}

body {
  font-family: "Gotham SSm A", "Gotham SSm B", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.ais-Breadcrumb,
.ais-ClearRefinements,
.ais-CurrentRefinements,
.ais-GeoSearch,
.ais-HierarchicalMenu,
.ais-Hits,
.ais-HitsPerPage,
.ais-InfiniteHits,
.ais-InfiniteResults,
.ais-Menu,
.ais-MenuSelect,
.ais-NumericMenu,
.ais-NumericSelector,
.ais-Pagination,
.ais-Panel,
.ais-PoweredBy,
.ais-RangeInput,
.ais-RangeSlider,
.ais-RatingMenu,
.ais-RefinementList,
.ais-Results,
.ais-ResultsPerPage,
.ais-SearchBox,
.ais-SortBy,
.ais-Stats,
.ais-ToggleRefinement {
  color: #2c2c2c;
}

.tw-sr-only{
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.tw-not-sr-only{
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.focus\:tw-sr-only:focus{
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.focus\:tw-not-sr-only:focus{
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.tw-pointer-events-none{
  pointer-events: none;
}

.tw-pointer-events-auto{
  pointer-events: auto;
}

.tw-visible{
  visibility: visible;
}

.tw-invisible{
  visibility: hidden;
}

.tw-static{
  position: static;
}

.tw-fixed{
  position: fixed;
}

.tw-absolute{
  position: absolute;
}

.tw-relative{
  position: relative;
}

.tw-sticky{
  position: -webkit-sticky;
  position: sticky;
}

.tw-inset-0{
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.tw-inset-auto{
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
}

.tw-inset-x-0{
  left: 0;
  right: 0;
}

.tw-inset-x-auto{
  left: auto;
  right: auto;
}

.tw-inset-y-0{
  top: 0;
  bottom: 0;
}

.tw-inset-y-auto{
  top: auto;
  bottom: auto;
}

.tw-top-0{
  top: 0;
}

.tw-top-auto{
  top: auto;
}

.tw-right-0{
  right: 0;
}

.tw-right-auto{
  right: auto;
}

.tw-bottom-0{
  bottom: 0;
}

.tw-bottom-auto{
  bottom: auto;
}

.tw-left-0{
  left: 0;
}

.tw-left-auto{
  left: auto;
}

.tw-isolate{
  isolation: isolate;
}

.tw-isolation-auto{
  isolation: auto;
}

.tw-z-0{
  z-index: 0;
}

.tw-z-10{
  z-index: 10;
}

.tw-z-20{
  z-index: 20;
}

.tw-z-30{
  z-index: 30;
}

.tw-z-40{
  z-index: 40;
}

.tw-z-50{
  z-index: 50;
}

.tw-z-auto{
  z-index: auto;
}

.tw-order-1{
  order: 1;
}

.tw-order-2{
  order: 2;
}

.tw-order-3{
  order: 3;
}

.tw-order-4{
  order: 4;
}

.tw-order-5{
  order: 5;
}

.tw-order-6{
  order: 6;
}

.tw-order-7{
  order: 7;
}

.tw-order-8{
  order: 8;
}

.tw-order-9{
  order: 9;
}

.tw-order-10{
  order: 10;
}

.tw-order-11{
  order: 11;
}

.tw-order-12{
  order: 12;
}

.tw-order-first{
  order: -9999;
}

.tw-order-last{
  order: 9999;
}

.tw-order-none{
  order: 0;
}

.tw-col-auto{
  grid-column: auto;
}

.tw-col-span-1{
  grid-column: span 1 / span 1;
}

.tw-col-span-2{
  grid-column: span 2 / span 2;
}

.tw-col-span-3{
  grid-column: span 3 / span 3;
}

.tw-col-span-4{
  grid-column: span 4 / span 4;
}

.tw-col-span-5{
  grid-column: span 5 / span 5;
}

.tw-col-span-6{
  grid-column: span 6 / span 6;
}

.tw-col-span-7{
  grid-column: span 7 / span 7;
}

.tw-col-span-8{
  grid-column: span 8 / span 8;
}

.tw-col-span-9{
  grid-column: span 9 / span 9;
}

.tw-col-span-10{
  grid-column: span 10 / span 10;
}

.tw-col-span-11{
  grid-column: span 11 / span 11;
}

.tw-col-span-12{
  grid-column: span 12 / span 12;
}

.tw-col-span-full{
  grid-column: 1 / -1;
}

.tw-col-start-1{
  grid-column-start: 1;
}

.tw-col-start-2{
  grid-column-start: 2;
}

.tw-col-start-3{
  grid-column-start: 3;
}

.tw-col-start-4{
  grid-column-start: 4;
}

.tw-col-start-5{
  grid-column-start: 5;
}

.tw-col-start-6{
  grid-column-start: 6;
}

.tw-col-start-7{
  grid-column-start: 7;
}

.tw-col-start-8{
  grid-column-start: 8;
}

.tw-col-start-9{
  grid-column-start: 9;
}

.tw-col-start-10{
  grid-column-start: 10;
}

.tw-col-start-11{
  grid-column-start: 11;
}

.tw-col-start-12{
  grid-column-start: 12;
}

.tw-col-start-13{
  grid-column-start: 13;
}

.tw-col-start-auto{
  grid-column-start: auto;
}

.tw-col-end-1{
  grid-column-end: 1;
}

.tw-col-end-2{
  grid-column-end: 2;
}

.tw-col-end-3{
  grid-column-end: 3;
}

.tw-col-end-4{
  grid-column-end: 4;
}

.tw-col-end-5{
  grid-column-end: 5;
}

.tw-col-end-6{
  grid-column-end: 6;
}

.tw-col-end-7{
  grid-column-end: 7;
}

.tw-col-end-8{
  grid-column-end: 8;
}

.tw-col-end-9{
  grid-column-end: 9;
}

.tw-col-end-10{
  grid-column-end: 10;
}

.tw-col-end-11{
  grid-column-end: 11;
}

.tw-col-end-12{
  grid-column-end: 12;
}

.tw-col-end-13{
  grid-column-end: 13;
}

.tw-col-end-auto{
  grid-column-end: auto;
}

.tw-row-auto{
  grid-row: auto;
}

.tw-row-span-1{
  grid-row: span 1 / span 1;
}

.tw-row-span-2{
  grid-row: span 2 / span 2;
}

.tw-row-span-3{
  grid-row: span 3 / span 3;
}

.tw-row-span-4{
  grid-row: span 4 / span 4;
}

.tw-row-span-5{
  grid-row: span 5 / span 5;
}

.tw-row-span-6{
  grid-row: span 6 / span 6;
}

.tw-row-span-full{
  grid-row: 1 / -1;
}

.tw-row-start-1{
  grid-row-start: 1;
}

.tw-row-start-2{
  grid-row-start: 2;
}

.tw-row-start-3{
  grid-row-start: 3;
}

.tw-row-start-4{
  grid-row-start: 4;
}

.tw-row-start-5{
  grid-row-start: 5;
}

.tw-row-start-6{
  grid-row-start: 6;
}

.tw-row-start-7{
  grid-row-start: 7;
}

.tw-row-start-auto{
  grid-row-start: auto;
}

.tw-row-end-1{
  grid-row-end: 1;
}

.tw-row-end-2{
  grid-row-end: 2;
}

.tw-row-end-3{
  grid-row-end: 3;
}

.tw-row-end-4{
  grid-row-end: 4;
}

.tw-row-end-5{
  grid-row-end: 5;
}

.tw-row-end-6{
  grid-row-end: 6;
}

.tw-row-end-7{
  grid-row-end: 7;
}

.tw-row-end-auto{
  grid-row-end: auto;
}

.tw-float-right{
  float: right;
}

.tw-float-left{
  float: left;
}

.tw-float-none{
  float: none;
}

.tw-clear-left{
  clear: left;
}

.tw-clear-right{
  clear: right;
}

.tw-clear-both{
  clear: both;
}

.tw-clear-none{
  clear: none;
}

.tw-m-0{
  margin: 0;
}

.tw-m-1{
  margin: 0.25rem;
}

.tw-m-2{
  margin: 0.5rem;
}

.tw-m-3{
  margin: 0.75rem;
}

.tw-m-4{
  margin: 1rem;
}

.tw-m-5{
  margin: 1.25rem;
}

.tw-m-6{
  margin: 1.5rem;
}

.tw-m-8{
  margin: 2rem;
}

.tw-m-10{
  margin: 2.5rem;
}

.tw-m-12{
  margin: 3rem;
}

.tw-m-16{
  margin: 4rem;
}

.tw-m-20{
  margin: 5rem;
}

.tw-m-24{
  margin: 6rem;
}

.tw-m-32{
  margin: 8rem;
}

.tw-m-40{
  margin: 10rem;
}

.tw-m-48{
  margin: 12rem;
}

.tw-m-56{
  margin: 14rem;
}

.tw-m-64{
  margin: 16rem;
}

.tw-m-auto{
  margin: auto;
}

.tw-m-px{
  margin: 1px;
}

.tw--m-1{
  margin: -0.25rem;
}

.tw--m-2{
  margin: -0.5rem;
}

.tw--m-3{
  margin: -0.75rem;
}

.tw--m-4{
  margin: -1rem;
}

.tw--m-5{
  margin: -1.25rem;
}

.tw--m-6{
  margin: -1.5rem;
}

.tw--m-8{
  margin: -2rem;
}

.tw--m-10{
  margin: -2.5rem;
}

.tw--m-12{
  margin: -3rem;
}

.tw--m-16{
  margin: -4rem;
}

.tw--m-20{
  margin: -5rem;
}

.tw--m-24{
  margin: -6rem;
}

.tw--m-32{
  margin: -8rem;
}

.tw--m-40{
  margin: -10rem;
}

.tw--m-48{
  margin: -12rem;
}

.tw--m-56{
  margin: -14rem;
}

.tw--m-64{
  margin: -16rem;
}

.tw--m-px{
  margin: -1px;
}

.tw-mx-0{
  margin-left: 0;
  margin-right: 0;
}

.tw-mx-1{
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.tw-mx-2{
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.tw-mx-3{
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.tw-mx-4{
  margin-left: 1rem;
  margin-right: 1rem;
}

.tw-mx-5{
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.tw-mx-6{
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.tw-mx-8{
  margin-left: 2rem;
  margin-right: 2rem;
}

.tw-mx-10{
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.tw-mx-12{
  margin-left: 3rem;
  margin-right: 3rem;
}

.tw-mx-16{
  margin-left: 4rem;
  margin-right: 4rem;
}

.tw-mx-20{
  margin-left: 5rem;
  margin-right: 5rem;
}

.tw-mx-24{
  margin-left: 6rem;
  margin-right: 6rem;
}

.tw-mx-32{
  margin-left: 8rem;
  margin-right: 8rem;
}

.tw-mx-40{
  margin-left: 10rem;
  margin-right: 10rem;
}

.tw-mx-48{
  margin-left: 12rem;
  margin-right: 12rem;
}

.tw-mx-56{
  margin-left: 14rem;
  margin-right: 14rem;
}

.tw-mx-64{
  margin-left: 16rem;
  margin-right: 16rem;
}

.tw-mx-auto{
  margin-left: auto;
  margin-right: auto;
}

.tw-mx-px{
  margin-left: 1px;
  margin-right: 1px;
}

.tw--mx-1{
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

.tw--mx-2{
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.tw--mx-3{
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.tw--mx-4{
  margin-left: -1rem;
  margin-right: -1rem;
}

.tw--mx-5{
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.tw--mx-6{
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.tw--mx-8{
  margin-left: -2rem;
  margin-right: -2rem;
}

.tw--mx-10{
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}

.tw--mx-12{
  margin-left: -3rem;
  margin-right: -3rem;
}

.tw--mx-16{
  margin-left: -4rem;
  margin-right: -4rem;
}

.tw--mx-20{
  margin-left: -5rem;
  margin-right: -5rem;
}

.tw--mx-24{
  margin-left: -6rem;
  margin-right: -6rem;
}

.tw--mx-32{
  margin-left: -8rem;
  margin-right: -8rem;
}

.tw--mx-40{
  margin-left: -10rem;
  margin-right: -10rem;
}

.tw--mx-48{
  margin-left: -12rem;
  margin-right: -12rem;
}

.tw--mx-56{
  margin-left: -14rem;
  margin-right: -14rem;
}

.tw--mx-64{
  margin-left: -16rem;
  margin-right: -16rem;
}

.tw--mx-px{
  margin-left: -1px;
  margin-right: -1px;
}

.tw-my-0{
  margin-top: 0;
  margin-bottom: 0;
}

.tw-my-1{
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.tw-my-2{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.tw-my-3{
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.tw-my-4{
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.tw-my-5{
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.tw-my-6{
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.tw-my-8{
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.tw-my-10{
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.tw-my-12{
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.tw-my-16{
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.tw-my-20{
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.tw-my-24{
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.tw-my-32{
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.tw-my-40{
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.tw-my-48{
  margin-top: 12rem;
  margin-bottom: 12rem;
}

.tw-my-56{
  margin-top: 14rem;
  margin-bottom: 14rem;
}

.tw-my-64{
  margin-top: 16rem;
  margin-bottom: 16rem;
}

.tw-my-auto{
  margin-top: auto;
  margin-bottom: auto;
}

.tw-my-px{
  margin-top: 1px;
  margin-bottom: 1px;
}

.tw--my-1{
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}

.tw--my-2{
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.tw--my-3{
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
}

.tw--my-4{
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.tw--my-5{
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}

.tw--my-6{
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}

.tw--my-8{
  margin-top: -2rem;
  margin-bottom: -2rem;
}

.tw--my-10{
  margin-top: -2.5rem;
  margin-bottom: -2.5rem;
}

.tw--my-12{
  margin-top: -3rem;
  margin-bottom: -3rem;
}

.tw--my-16{
  margin-top: -4rem;
  margin-bottom: -4rem;
}

.tw--my-20{
  margin-top: -5rem;
  margin-bottom: -5rem;
}

.tw--my-24{
  margin-top: -6rem;
  margin-bottom: -6rem;
}

.tw--my-32{
  margin-top: -8rem;
  margin-bottom: -8rem;
}

.tw--my-40{
  margin-top: -10rem;
  margin-bottom: -10rem;
}

.tw--my-48{
  margin-top: -12rem;
  margin-bottom: -12rem;
}

.tw--my-56{
  margin-top: -14rem;
  margin-bottom: -14rem;
}

.tw--my-64{
  margin-top: -16rem;
  margin-bottom: -16rem;
}

.tw--my-px{
  margin-top: -1px;
  margin-bottom: -1px;
}

.tw-mt-0{
  margin-top: 0;
}

.tw-mt-1{
  margin-top: 0.25rem;
}

.tw-mt-2{
  margin-top: 0.5rem;
}

.tw-mt-3{
  margin-top: 0.75rem;
}

.tw-mt-4{
  margin-top: 1rem;
}

.tw-mt-5{
  margin-top: 1.25rem;
}

.tw-mt-6{
  margin-top: 1.5rem;
}

.tw-mt-8{
  margin-top: 2rem;
}

.tw-mt-10{
  margin-top: 2.5rem;
}

.tw-mt-12{
  margin-top: 3rem;
}

.tw-mt-16{
  margin-top: 4rem;
}

.tw-mt-20{
  margin-top: 5rem;
}

.tw-mt-24{
  margin-top: 6rem;
}

.tw-mt-32{
  margin-top: 8rem;
}

.tw-mt-40{
  margin-top: 10rem;
}

.tw-mt-48{
  margin-top: 12rem;
}

.tw-mt-56{
  margin-top: 14rem;
}

.tw-mt-64{
  margin-top: 16rem;
}

.tw-mt-auto{
  margin-top: auto;
}

.tw-mt-px{
  margin-top: 1px;
}

.tw--mt-1{
  margin-top: -0.25rem;
}

.tw--mt-2{
  margin-top: -0.5rem;
}

.tw--mt-3{
  margin-top: -0.75rem;
}

.tw--mt-4{
  margin-top: -1rem;
}

.tw--mt-5{
  margin-top: -1.25rem;
}

.tw--mt-6{
  margin-top: -1.5rem;
}

.tw--mt-8{
  margin-top: -2rem;
}

.tw--mt-10{
  margin-top: -2.5rem;
}

.tw--mt-12{
  margin-top: -3rem;
}

.tw--mt-16{
  margin-top: -4rem;
}

.tw--mt-20{
  margin-top: -5rem;
}

.tw--mt-24{
  margin-top: -6rem;
}

.tw--mt-32{
  margin-top: -8rem;
}

.tw--mt-40{
  margin-top: -10rem;
}

.tw--mt-48{
  margin-top: -12rem;
}

.tw--mt-56{
  margin-top: -14rem;
}

.tw--mt-64{
  margin-top: -16rem;
}

.tw--mt-px{
  margin-top: -1px;
}

.tw-mr-0{
  margin-right: 0;
}

.tw-mr-1{
  margin-right: 0.25rem;
}

.tw-mr-2{
  margin-right: 0.5rem;
}

.tw-mr-3{
  margin-right: 0.75rem;
}

.tw-mr-4{
  margin-right: 1rem;
}

.tw-mr-5{
  margin-right: 1.25rem;
}

.tw-mr-6{
  margin-right: 1.5rem;
}

.tw-mr-8{
  margin-right: 2rem;
}

.tw-mr-10{
  margin-right: 2.5rem;
}

.tw-mr-12{
  margin-right: 3rem;
}

.tw-mr-16{
  margin-right: 4rem;
}

.tw-mr-20{
  margin-right: 5rem;
}

.tw-mr-24{
  margin-right: 6rem;
}

.tw-mr-32{
  margin-right: 8rem;
}

.tw-mr-40{
  margin-right: 10rem;
}

.tw-mr-48{
  margin-right: 12rem;
}

.tw-mr-56{
  margin-right: 14rem;
}

.tw-mr-64{
  margin-right: 16rem;
}

.tw-mr-auto{
  margin-right: auto;
}

.tw-mr-px{
  margin-right: 1px;
}

.tw--mr-1{
  margin-right: -0.25rem;
}

.tw--mr-2{
  margin-right: -0.5rem;
}

.tw--mr-3{
  margin-right: -0.75rem;
}

.tw--mr-4{
  margin-right: -1rem;
}

.tw--mr-5{
  margin-right: -1.25rem;
}

.tw--mr-6{
  margin-right: -1.5rem;
}

.tw--mr-8{
  margin-right: -2rem;
}

.tw--mr-10{
  margin-right: -2.5rem;
}

.tw--mr-12{
  margin-right: -3rem;
}

.tw--mr-16{
  margin-right: -4rem;
}

.tw--mr-20{
  margin-right: -5rem;
}

.tw--mr-24{
  margin-right: -6rem;
}

.tw--mr-32{
  margin-right: -8rem;
}

.tw--mr-40{
  margin-right: -10rem;
}

.tw--mr-48{
  margin-right: -12rem;
}

.tw--mr-56{
  margin-right: -14rem;
}

.tw--mr-64{
  margin-right: -16rem;
}

.tw--mr-px{
  margin-right: -1px;
}

.tw-mb-0{
  margin-bottom: 0;
}

.tw-mb-1{
  margin-bottom: 0.25rem;
}

.tw-mb-2{
  margin-bottom: 0.5rem;
}

.tw-mb-3{
  margin-bottom: 0.75rem;
}

.tw-mb-4{
  margin-bottom: 1rem;
}

.tw-mb-5{
  margin-bottom: 1.25rem;
}

.tw-mb-6{
  margin-bottom: 1.5rem;
}

.tw-mb-8{
  margin-bottom: 2rem;
}

.tw-mb-10{
  margin-bottom: 2.5rem;
}

.tw-mb-12{
  margin-bottom: 3rem;
}

.tw-mb-16{
  margin-bottom: 4rem;
}

.tw-mb-20{
  margin-bottom: 5rem;
}

.tw-mb-24{
  margin-bottom: 6rem;
}

.tw-mb-32{
  margin-bottom: 8rem;
}

.tw-mb-40{
  margin-bottom: 10rem;
}

.tw-mb-48{
  margin-bottom: 12rem;
}

.tw-mb-56{
  margin-bottom: 14rem;
}

.tw-mb-64{
  margin-bottom: 16rem;
}

.tw-mb-auto{
  margin-bottom: auto;
}

.tw-mb-px{
  margin-bottom: 1px;
}

.tw--mb-1{
  margin-bottom: -0.25rem;
}

.tw--mb-2{
  margin-bottom: -0.5rem;
}

.tw--mb-3{
  margin-bottom: -0.75rem;
}

.tw--mb-4{
  margin-bottom: -1rem;
}

.tw--mb-5{
  margin-bottom: -1.25rem;
}

.tw--mb-6{
  margin-bottom: -1.5rem;
}

.tw--mb-8{
  margin-bottom: -2rem;
}

.tw--mb-10{
  margin-bottom: -2.5rem;
}

.tw--mb-12{
  margin-bottom: -3rem;
}

.tw--mb-16{
  margin-bottom: -4rem;
}

.tw--mb-20{
  margin-bottom: -5rem;
}

.tw--mb-24{
  margin-bottom: -6rem;
}

.tw--mb-32{
  margin-bottom: -8rem;
}

.tw--mb-40{
  margin-bottom: -10rem;
}

.tw--mb-48{
  margin-bottom: -12rem;
}

.tw--mb-56{
  margin-bottom: -14rem;
}

.tw--mb-64{
  margin-bottom: -16rem;
}

.tw--mb-px{
  margin-bottom: -1px;
}

.tw-ml-0{
  margin-left: 0;
}

.tw-ml-1{
  margin-left: 0.25rem;
}

.tw-ml-2{
  margin-left: 0.5rem;
}

.tw-ml-3{
  margin-left: 0.75rem;
}

.tw-ml-4{
  margin-left: 1rem;
}

.tw-ml-5{
  margin-left: 1.25rem;
}

.tw-ml-6{
  margin-left: 1.5rem;
}

.tw-ml-8{
  margin-left: 2rem;
}

.tw-ml-10{
  margin-left: 2.5rem;
}

.tw-ml-12{
  margin-left: 3rem;
}

.tw-ml-16{
  margin-left: 4rem;
}

.tw-ml-20{
  margin-left: 5rem;
}

.tw-ml-24{
  margin-left: 6rem;
}

.tw-ml-32{
  margin-left: 8rem;
}

.tw-ml-40{
  margin-left: 10rem;
}

.tw-ml-48{
  margin-left: 12rem;
}

.tw-ml-56{
  margin-left: 14rem;
}

.tw-ml-64{
  margin-left: 16rem;
}

.tw-ml-auto{
  margin-left: auto;
}

.tw-ml-px{
  margin-left: 1px;
}

.tw--ml-1{
  margin-left: -0.25rem;
}

.tw--ml-2{
  margin-left: -0.5rem;
}

.tw--ml-3{
  margin-left: -0.75rem;
}

.tw--ml-4{
  margin-left: -1rem;
}

.tw--ml-5{
  margin-left: -1.25rem;
}

.tw--ml-6{
  margin-left: -1.5rem;
}

.tw--ml-8{
  margin-left: -2rem;
}

.tw--ml-10{
  margin-left: -2.5rem;
}

.tw--ml-12{
  margin-left: -3rem;
}

.tw--ml-16{
  margin-left: -4rem;
}

.tw--ml-20{
  margin-left: -5rem;
}

.tw--ml-24{
  margin-left: -6rem;
}

.tw--ml-32{
  margin-left: -8rem;
}

.tw--ml-40{
  margin-left: -10rem;
}

.tw--ml-48{
  margin-left: -12rem;
}

.tw--ml-56{
  margin-left: -14rem;
}

.tw--ml-64{
  margin-left: -16rem;
}

.tw--ml-px{
  margin-left: -1px;
}

.tw-box-border{
  box-sizing: border-box;
}

.tw-box-content{
  box-sizing: content-box;
}

.tw-block{
  display: block;
}

.tw-inline-block{
  display: inline-block;
}

.tw-inline{
  display: inline;
}

.tw-flex{
  display: flex;
}

.tw-inline-flex{
  display: inline-flex;
}

.tw-table{
  display: table;
}

.tw-inline-table{
  display: inline-table;
}

.tw-table-caption{
  display: table-caption;
}

.tw-table-cell{
  display: table-cell;
}

.tw-table-column{
  display: table-column;
}

.tw-table-column-group{
  display: table-column-group;
}

.tw-table-footer-group{
  display: table-footer-group;
}

.tw-table-header-group{
  display: table-header-group;
}

.tw-table-row-group{
  display: table-row-group;
}

.tw-table-row{
  display: table-row;
}

.tw-flow-root{
  display: flow-root;
}

.tw-grid{
  display: grid;
}

.tw-inline-grid{
  display: inline-grid;
}

.tw-contents{
  display: contents;
}

.tw-list-item{
  display: list-item;
}

.tw-hidden{
  display: none;
}

.tw-h-0{
  height: 0;
}

.tw-h-1{
  height: 0.25rem;
}

.tw-h-2{
  height: 0.5rem;
}

.tw-h-3{
  height: 0.75rem;
}

.tw-h-4{
  height: 1rem;
}

.tw-h-5{
  height: 1.25rem;
}

.tw-h-6{
  height: 1.5rem;
}

.tw-h-8{
  height: 2rem;
}

.tw-h-10{
  height: 2.5rem;
}

.tw-h-12{
  height: 3rem;
}

.tw-h-16{
  height: 4rem;
}

.tw-h-20{
  height: 5rem;
}

.tw-h-24{
  height: 6rem;
}

.tw-h-32{
  height: 8rem;
}

.tw-h-40{
  height: 10rem;
}

.tw-h-48{
  height: 12rem;
}

.tw-h-56{
  height: 14rem;
}

.tw-h-64{
  height: 16rem;
}

.tw-h-auto{
  height: auto;
}

.tw-h-px{
  height: 1px;
}

.tw-h-1\/2{
  height: 50%;
}

.tw-h-1\/3{
  height: 33.333333%;
}

.tw-h-2\/3{
  height: 66.666667%;
}

.tw-h-1\/4{
  height: 25%;
}

.tw-h-2\/4{
  height: 50%;
}

.tw-h-3\/4{
  height: 75%;
}

.tw-h-1\/5{
  height: 20%;
}

.tw-h-2\/5{
  height: 40%;
}

.tw-h-3\/5{
  height: 60%;
}

.tw-h-4\/5{
  height: 80%;
}

.tw-h-1\/6{
  height: 16.666667%;
}

.tw-h-2\/6{
  height: 33.333333%;
}

.tw-h-3\/6{
  height: 50%;
}

.tw-h-4\/6{
  height: 66.666667%;
}

.tw-h-5\/6{
  height: 83.333333%;
}

.tw-h-full{
  height: 100%;
}

.tw-h-screen{
  height: 100vh;
}

.tw-max-h-full{
  max-height: 100%;
}

.tw-max-h-screen{
  max-height: 100vh;
}

.tw-min-h-0{
  min-height: 0;
}

.tw-min-h-full{
  min-height: 100%;
}

.tw-min-h-screen{
  min-height: 100vh;
}

.tw-w-0{
  width: 0;
}

.tw-w-1{
  width: 0.25rem;
}

.tw-w-2{
  width: 0.5rem;
}

.tw-w-3{
  width: 0.75rem;
}

.tw-w-4{
  width: 1rem;
}

.tw-w-5{
  width: 1.25rem;
}

.tw-w-6{
  width: 1.5rem;
}

.tw-w-8{
  width: 2rem;
}

.tw-w-10{
  width: 2.5rem;
}

.tw-w-12{
  width: 3rem;
}

.tw-w-16{
  width: 4rem;
}

.tw-w-20{
  width: 5rem;
}

.tw-w-24{
  width: 6rem;
}

.tw-w-32{
  width: 8rem;
}

.tw-w-40{
  width: 10rem;
}

.tw-w-48{
  width: 12rem;
}

.tw-w-56{
  width: 14rem;
}

.tw-w-64{
  width: 16rem;
}

.tw-w-auto{
  width: auto;
}

.tw-w-px{
  width: 1px;
}

.tw-w-1\/2{
  width: 50%;
}

.tw-w-1\/3{
  width: 33.333333%;
}

.tw-w-2\/3{
  width: 66.666667%;
}

.tw-w-1\/4{
  width: 25%;
}

.tw-w-2\/4{
  width: 50%;
}

.tw-w-3\/4{
  width: 75%;
}

.tw-w-1\/5{
  width: 20%;
}

.tw-w-2\/5{
  width: 40%;
}

.tw-w-3\/5{
  width: 60%;
}

.tw-w-4\/5{
  width: 80%;
}

.tw-w-1\/6{
  width: 16.666667%;
}

.tw-w-2\/6{
  width: 33.333333%;
}

.tw-w-3\/6{
  width: 50%;
}

.tw-w-4\/6{
  width: 66.666667%;
}

.tw-w-5\/6{
  width: 83.333333%;
}

.tw-w-1\/12{
  width: 8.333333%;
}

.tw-w-2\/12{
  width: 16.666667%;
}

.tw-w-3\/12{
  width: 25%;
}

.tw-w-4\/12{
  width: 33.333333%;
}

.tw-w-5\/12{
  width: 41.666667%;
}

.tw-w-6\/12{
  width: 50%;
}

.tw-w-7\/12{
  width: 58.333333%;
}

.tw-w-8\/12{
  width: 66.666667%;
}

.tw-w-9\/12{
  width: 75%;
}

.tw-w-10\/12{
  width: 83.333333%;
}

.tw-w-11\/12{
  width: 91.666667%;
}

.tw-w-full{
  width: 100%;
}

.tw-w-screen{
  width: 100vw;
}

.tw-w-min{
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}

.tw-w-max{
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.tw-min-w-0{
  min-width: 0;
}

.tw-min-w-full{
  min-width: 100%;
}

.tw-max-w-0{
  max-width: 0rem;
}

.tw-max-w-none{
  max-width: none;
}

.tw-max-w-xs{
  max-width: 20rem;
}

.tw-max-w-sm{
  max-width: 24rem;
}

.tw-max-w-md{
  max-width: 28rem;
}

.tw-max-w-lg{
  max-width: 32rem;
}

.tw-max-w-xl{
  max-width: 36rem;
}

.tw-max-w-2xl{
  max-width: 42rem;
}

.tw-max-w-3xl{
  max-width: 48rem;
}

.tw-max-w-4xl{
  max-width: 56rem;
}

.tw-max-w-5xl{
  max-width: 64rem;
}

.tw-max-w-6xl{
  max-width: 72rem;
}

.tw-max-w-7xl{
  max-width: 80rem;
}

.tw-max-w-full{
  max-width: 100%;
}

.tw-max-w-min{
  max-width: -webkit-min-content;
  max-width: -moz-min-content;
  max-width: min-content;
}

.tw-max-w-max{
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
}

.tw-max-w-prose{
  max-width: 65ch;
}

.tw-max-w-screen-sm{
  max-width: 640px;
}

.tw-max-w-screen-md{
  max-width: 768px;
}

.tw-max-w-screen-lg{
  max-width: 1024px;
}

.tw-max-w-screen-xl{
  max-width: 1280px;
}

.tw-flex-1{
  flex: 1 1 0%;
}

.tw-flex-auto{
  flex: 1 1 auto;
}

.tw-flex-initial{
  flex: 0 1 auto;
}

.tw-flex-none{
  flex: none;
}

.tw-flex-shrink-0{
  flex-shrink: 0;
}

.tw-flex-shrink-default{
  flex-shrink: 1;
}

.tw-flex-grow-0{
  flex-grow: 0;
}

.tw-flex-grow-default{
  flex-grow: 1;
}

.tw-table-auto{
  table-layout: auto;
}

.tw-table-fixed{
  table-layout: fixed;
}

.tw-border-collapse{
  border-collapse: collapse;
}

.tw-border-separate{
  border-collapse: separate;
}

.tw-origin-center{
  transform-origin: center;
}

.tw-origin-top{
  transform-origin: top;
}

.tw-origin-top-right{
  transform-origin: top right;
}

.tw-origin-right{
  transform-origin: right;
}

.tw-origin-bottom-right{
  transform-origin: bottom right;
}

.tw-origin-bottom{
  transform-origin: bottom;
}

.tw-origin-bottom-left{
  transform-origin: bottom left;
}

.tw-origin-left{
  transform-origin: left;
}

.tw-origin-top-left{
  transform-origin: top left;
}

.tw-transform{
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-transform-gpu{
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-transform-none{
  transform: none;
}

.tw-translate-x-0{
  --tw-translate-x: 0;
}

.tw-translate-x-1{
  --tw-translate-x: 0.25rem;
}

.tw-translate-x-2{
  --tw-translate-x: 0.5rem;
}

.tw-translate-x-3{
  --tw-translate-x: 0.75rem;
}

.tw-translate-x-4{
  --tw-translate-x: 1rem;
}

.tw-translate-x-5{
  --tw-translate-x: 1.25rem;
}

.tw-translate-x-6{
  --tw-translate-x: 1.5rem;
}

.tw-translate-x-8{
  --tw-translate-x: 2rem;
}

.tw-translate-x-10{
  --tw-translate-x: 2.5rem;
}

.tw-translate-x-12{
  --tw-translate-x: 3rem;
}

.tw-translate-x-16{
  --tw-translate-x: 4rem;
}

.tw-translate-x-20{
  --tw-translate-x: 5rem;
}

.tw-translate-x-24{
  --tw-translate-x: 6rem;
}

.tw-translate-x-32{
  --tw-translate-x: 8rem;
}

.tw-translate-x-40{
  --tw-translate-x: 10rem;
}

.tw-translate-x-48{
  --tw-translate-x: 12rem;
}

.tw-translate-x-56{
  --tw-translate-x: 14rem;
}

.tw-translate-x-64{
  --tw-translate-x: 16rem;
}

.tw-translate-x-px{
  --tw-translate-x: 1px;
}

.tw--translate-x-1{
  --tw-translate-x: -0.25rem;
}

.tw--translate-x-2{
  --tw-translate-x: -0.5rem;
}

.tw--translate-x-3{
  --tw-translate-x: -0.75rem;
}

.tw--translate-x-4{
  --tw-translate-x: -1rem;
}

.tw--translate-x-5{
  --tw-translate-x: -1.25rem;
}

.tw--translate-x-6{
  --tw-translate-x: -1.5rem;
}

.tw--translate-x-8{
  --tw-translate-x: -2rem;
}

.tw--translate-x-10{
  --tw-translate-x: -2.5rem;
}

.tw--translate-x-12{
  --tw-translate-x: -3rem;
}

.tw--translate-x-16{
  --tw-translate-x: -4rem;
}

.tw--translate-x-20{
  --tw-translate-x: -5rem;
}

.tw--translate-x-24{
  --tw-translate-x: -6rem;
}

.tw--translate-x-32{
  --tw-translate-x: -8rem;
}

.tw--translate-x-40{
  --tw-translate-x: -10rem;
}

.tw--translate-x-48{
  --tw-translate-x: -12rem;
}

.tw--translate-x-56{
  --tw-translate-x: -14rem;
}

.tw--translate-x-64{
  --tw-translate-x: -16rem;
}

.tw--translate-x-px{
  --tw-translate-x: -1px;
}

.tw-translate-x-1\/2{
  --tw-translate-x: 50%;
}

.tw-translate-x-1\/3{
  --tw-translate-x: 33.333333%;
}

.tw-translate-x-2\/3{
  --tw-translate-x: 66.666667%;
}

.tw-translate-x-1\/4{
  --tw-translate-x: 25%;
}

.tw-translate-x-2\/4{
  --tw-translate-x: 50%;
}

.tw-translate-x-3\/4{
  --tw-translate-x: 75%;
}

.tw-translate-x-full{
  --tw-translate-x: 100%;
}

.tw--translate-x-1\/2{
  --tw-translate-x: -50%;
}

.tw--translate-x-1\/3{
  --tw-translate-x: -33.333333%;
}

.tw--translate-x-2\/3{
  --tw-translate-x: -66.666667%;
}

.tw--translate-x-1\/4{
  --tw-translate-x: -25%;
}

.tw--translate-x-2\/4{
  --tw-translate-x: -50%;
}

.tw--translate-x-3\/4{
  --tw-translate-x: -75%;
}

.tw--translate-x-full{
  --tw-translate-x: -100%;
}

.tw-translate-y-0{
  --tw-translate-y: 0;
}

.tw-translate-y-1{
  --tw-translate-y: 0.25rem;
}

.tw-translate-y-2{
  --tw-translate-y: 0.5rem;
}

.tw-translate-y-3{
  --tw-translate-y: 0.75rem;
}

.tw-translate-y-4{
  --tw-translate-y: 1rem;
}

.tw-translate-y-5{
  --tw-translate-y: 1.25rem;
}

.tw-translate-y-6{
  --tw-translate-y: 1.5rem;
}

.tw-translate-y-8{
  --tw-translate-y: 2rem;
}

.tw-translate-y-10{
  --tw-translate-y: 2.5rem;
}

.tw-translate-y-12{
  --tw-translate-y: 3rem;
}

.tw-translate-y-16{
  --tw-translate-y: 4rem;
}

.tw-translate-y-20{
  --tw-translate-y: 5rem;
}

.tw-translate-y-24{
  --tw-translate-y: 6rem;
}

.tw-translate-y-32{
  --tw-translate-y: 8rem;
}

.tw-translate-y-40{
  --tw-translate-y: 10rem;
}

.tw-translate-y-48{
  --tw-translate-y: 12rem;
}

.tw-translate-y-56{
  --tw-translate-y: 14rem;
}

.tw-translate-y-64{
  --tw-translate-y: 16rem;
}

.tw-translate-y-px{
  --tw-translate-y: 1px;
}

.tw--translate-y-1{
  --tw-translate-y: -0.25rem;
}

.tw--translate-y-2{
  --tw-translate-y: -0.5rem;
}

.tw--translate-y-3{
  --tw-translate-y: -0.75rem;
}

.tw--translate-y-4{
  --tw-translate-y: -1rem;
}

.tw--translate-y-5{
  --tw-translate-y: -1.25rem;
}

.tw--translate-y-6{
  --tw-translate-y: -1.5rem;
}

.tw--translate-y-8{
  --tw-translate-y: -2rem;
}

.tw--translate-y-10{
  --tw-translate-y: -2.5rem;
}

.tw--translate-y-12{
  --tw-translate-y: -3rem;
}

.tw--translate-y-16{
  --tw-translate-y: -4rem;
}

.tw--translate-y-20{
  --tw-translate-y: -5rem;
}

.tw--translate-y-24{
  --tw-translate-y: -6rem;
}

.tw--translate-y-32{
  --tw-translate-y: -8rem;
}

.tw--translate-y-40{
  --tw-translate-y: -10rem;
}

.tw--translate-y-48{
  --tw-translate-y: -12rem;
}

.tw--translate-y-56{
  --tw-translate-y: -14rem;
}

.tw--translate-y-64{
  --tw-translate-y: -16rem;
}

.tw--translate-y-px{
  --tw-translate-y: -1px;
}

.tw-translate-y-1\/2{
  --tw-translate-y: 50%;
}

.tw-translate-y-1\/3{
  --tw-translate-y: 33.333333%;
}

.tw-translate-y-2\/3{
  --tw-translate-y: 66.666667%;
}

.tw-translate-y-1\/4{
  --tw-translate-y: 25%;
}

.tw-translate-y-2\/4{
  --tw-translate-y: 50%;
}

.tw-translate-y-3\/4{
  --tw-translate-y: 75%;
}

.tw-translate-y-full{
  --tw-translate-y: 100%;
}

.tw--translate-y-1\/2{
  --tw-translate-y: -50%;
}

.tw--translate-y-1\/3{
  --tw-translate-y: -33.333333%;
}

.tw--translate-y-2\/3{
  --tw-translate-y: -66.666667%;
}

.tw--translate-y-1\/4{
  --tw-translate-y: -25%;
}

.tw--translate-y-2\/4{
  --tw-translate-y: -50%;
}

.tw--translate-y-3\/4{
  --tw-translate-y: -75%;
}

.tw--translate-y-full{
  --tw-translate-y: -100%;
}

.hover\:tw-translate-x-0:hover{
  --tw-translate-x: 0;
}

.hover\:tw-translate-x-1:hover{
  --tw-translate-x: 0.25rem;
}

.hover\:tw-translate-x-2:hover{
  --tw-translate-x: 0.5rem;
}

.hover\:tw-translate-x-3:hover{
  --tw-translate-x: 0.75rem;
}

.hover\:tw-translate-x-4:hover{
  --tw-translate-x: 1rem;
}

.hover\:tw-translate-x-5:hover{
  --tw-translate-x: 1.25rem;
}

.hover\:tw-translate-x-6:hover{
  --tw-translate-x: 1.5rem;
}

.hover\:tw-translate-x-8:hover{
  --tw-translate-x: 2rem;
}

.hover\:tw-translate-x-10:hover{
  --tw-translate-x: 2.5rem;
}

.hover\:tw-translate-x-12:hover{
  --tw-translate-x: 3rem;
}

.hover\:tw-translate-x-16:hover{
  --tw-translate-x: 4rem;
}

.hover\:tw-translate-x-20:hover{
  --tw-translate-x: 5rem;
}

.hover\:tw-translate-x-24:hover{
  --tw-translate-x: 6rem;
}

.hover\:tw-translate-x-32:hover{
  --tw-translate-x: 8rem;
}

.hover\:tw-translate-x-40:hover{
  --tw-translate-x: 10rem;
}

.hover\:tw-translate-x-48:hover{
  --tw-translate-x: 12rem;
}

.hover\:tw-translate-x-56:hover{
  --tw-translate-x: 14rem;
}

.hover\:tw-translate-x-64:hover{
  --tw-translate-x: 16rem;
}

.hover\:tw-translate-x-px:hover{
  --tw-translate-x: 1px;
}

.hover\:tw--translate-x-1:hover{
  --tw-translate-x: -0.25rem;
}

.hover\:tw--translate-x-2:hover{
  --tw-translate-x: -0.5rem;
}

.hover\:tw--translate-x-3:hover{
  --tw-translate-x: -0.75rem;
}

.hover\:tw--translate-x-4:hover{
  --tw-translate-x: -1rem;
}

.hover\:tw--translate-x-5:hover{
  --tw-translate-x: -1.25rem;
}

.hover\:tw--translate-x-6:hover{
  --tw-translate-x: -1.5rem;
}

.hover\:tw--translate-x-8:hover{
  --tw-translate-x: -2rem;
}

.hover\:tw--translate-x-10:hover{
  --tw-translate-x: -2.5rem;
}

.hover\:tw--translate-x-12:hover{
  --tw-translate-x: -3rem;
}

.hover\:tw--translate-x-16:hover{
  --tw-translate-x: -4rem;
}

.hover\:tw--translate-x-20:hover{
  --tw-translate-x: -5rem;
}

.hover\:tw--translate-x-24:hover{
  --tw-translate-x: -6rem;
}

.hover\:tw--translate-x-32:hover{
  --tw-translate-x: -8rem;
}

.hover\:tw--translate-x-40:hover{
  --tw-translate-x: -10rem;
}

.hover\:tw--translate-x-48:hover{
  --tw-translate-x: -12rem;
}

.hover\:tw--translate-x-56:hover{
  --tw-translate-x: -14rem;
}

.hover\:tw--translate-x-64:hover{
  --tw-translate-x: -16rem;
}

.hover\:tw--translate-x-px:hover{
  --tw-translate-x: -1px;
}

.hover\:tw-translate-x-1\/2:hover{
  --tw-translate-x: 50%;
}

.hover\:tw-translate-x-1\/3:hover{
  --tw-translate-x: 33.333333%;
}

.hover\:tw-translate-x-2\/3:hover{
  --tw-translate-x: 66.666667%;
}

.hover\:tw-translate-x-1\/4:hover{
  --tw-translate-x: 25%;
}

.hover\:tw-translate-x-2\/4:hover{
  --tw-translate-x: 50%;
}

.hover\:tw-translate-x-3\/4:hover{
  --tw-translate-x: 75%;
}

.hover\:tw-translate-x-full:hover{
  --tw-translate-x: 100%;
}

.hover\:tw--translate-x-1\/2:hover{
  --tw-translate-x: -50%;
}

.hover\:tw--translate-x-1\/3:hover{
  --tw-translate-x: -33.333333%;
}

.hover\:tw--translate-x-2\/3:hover{
  --tw-translate-x: -66.666667%;
}

.hover\:tw--translate-x-1\/4:hover{
  --tw-translate-x: -25%;
}

.hover\:tw--translate-x-2\/4:hover{
  --tw-translate-x: -50%;
}

.hover\:tw--translate-x-3\/4:hover{
  --tw-translate-x: -75%;
}

.hover\:tw--translate-x-full:hover{
  --tw-translate-x: -100%;
}

.hover\:tw-translate-y-0:hover{
  --tw-translate-y: 0;
}

.hover\:tw-translate-y-1:hover{
  --tw-translate-y: 0.25rem;
}

.hover\:tw-translate-y-2:hover{
  --tw-translate-y: 0.5rem;
}

.hover\:tw-translate-y-3:hover{
  --tw-translate-y: 0.75rem;
}

.hover\:tw-translate-y-4:hover{
  --tw-translate-y: 1rem;
}

.hover\:tw-translate-y-5:hover{
  --tw-translate-y: 1.25rem;
}

.hover\:tw-translate-y-6:hover{
  --tw-translate-y: 1.5rem;
}

.hover\:tw-translate-y-8:hover{
  --tw-translate-y: 2rem;
}

.hover\:tw-translate-y-10:hover{
  --tw-translate-y: 2.5rem;
}

.hover\:tw-translate-y-12:hover{
  --tw-translate-y: 3rem;
}

.hover\:tw-translate-y-16:hover{
  --tw-translate-y: 4rem;
}

.hover\:tw-translate-y-20:hover{
  --tw-translate-y: 5rem;
}

.hover\:tw-translate-y-24:hover{
  --tw-translate-y: 6rem;
}

.hover\:tw-translate-y-32:hover{
  --tw-translate-y: 8rem;
}

.hover\:tw-translate-y-40:hover{
  --tw-translate-y: 10rem;
}

.hover\:tw-translate-y-48:hover{
  --tw-translate-y: 12rem;
}

.hover\:tw-translate-y-56:hover{
  --tw-translate-y: 14rem;
}

.hover\:tw-translate-y-64:hover{
  --tw-translate-y: 16rem;
}

.hover\:tw-translate-y-px:hover{
  --tw-translate-y: 1px;
}

.hover\:tw--translate-y-1:hover{
  --tw-translate-y: -0.25rem;
}

.hover\:tw--translate-y-2:hover{
  --tw-translate-y: -0.5rem;
}

.hover\:tw--translate-y-3:hover{
  --tw-translate-y: -0.75rem;
}

.hover\:tw--translate-y-4:hover{
  --tw-translate-y: -1rem;
}

.hover\:tw--translate-y-5:hover{
  --tw-translate-y: -1.25rem;
}

.hover\:tw--translate-y-6:hover{
  --tw-translate-y: -1.5rem;
}

.hover\:tw--translate-y-8:hover{
  --tw-translate-y: -2rem;
}

.hover\:tw--translate-y-10:hover{
  --tw-translate-y: -2.5rem;
}

.hover\:tw--translate-y-12:hover{
  --tw-translate-y: -3rem;
}

.hover\:tw--translate-y-16:hover{
  --tw-translate-y: -4rem;
}

.hover\:tw--translate-y-20:hover{
  --tw-translate-y: -5rem;
}

.hover\:tw--translate-y-24:hover{
  --tw-translate-y: -6rem;
}

.hover\:tw--translate-y-32:hover{
  --tw-translate-y: -8rem;
}

.hover\:tw--translate-y-40:hover{
  --tw-translate-y: -10rem;
}

.hover\:tw--translate-y-48:hover{
  --tw-translate-y: -12rem;
}

.hover\:tw--translate-y-56:hover{
  --tw-translate-y: -14rem;
}

.hover\:tw--translate-y-64:hover{
  --tw-translate-y: -16rem;
}

.hover\:tw--translate-y-px:hover{
  --tw-translate-y: -1px;
}

.hover\:tw-translate-y-1\/2:hover{
  --tw-translate-y: 50%;
}

.hover\:tw-translate-y-1\/3:hover{
  --tw-translate-y: 33.333333%;
}

.hover\:tw-translate-y-2\/3:hover{
  --tw-translate-y: 66.666667%;
}

.hover\:tw-translate-y-1\/4:hover{
  --tw-translate-y: 25%;
}

.hover\:tw-translate-y-2\/4:hover{
  --tw-translate-y: 50%;
}

.hover\:tw-translate-y-3\/4:hover{
  --tw-translate-y: 75%;
}

.hover\:tw-translate-y-full:hover{
  --tw-translate-y: 100%;
}

.hover\:tw--translate-y-1\/2:hover{
  --tw-translate-y: -50%;
}

.hover\:tw--translate-y-1\/3:hover{
  --tw-translate-y: -33.333333%;
}

.hover\:tw--translate-y-2\/3:hover{
  --tw-translate-y: -66.666667%;
}

.hover\:tw--translate-y-1\/4:hover{
  --tw-translate-y: -25%;
}

.hover\:tw--translate-y-2\/4:hover{
  --tw-translate-y: -50%;
}

.hover\:tw--translate-y-3\/4:hover{
  --tw-translate-y: -75%;
}

.hover\:tw--translate-y-full:hover{
  --tw-translate-y: -100%;
}

.focus\:tw-translate-x-0:focus{
  --tw-translate-x: 0;
}

.focus\:tw-translate-x-1:focus{
  --tw-translate-x: 0.25rem;
}

.focus\:tw-translate-x-2:focus{
  --tw-translate-x: 0.5rem;
}

.focus\:tw-translate-x-3:focus{
  --tw-translate-x: 0.75rem;
}

.focus\:tw-translate-x-4:focus{
  --tw-translate-x: 1rem;
}

.focus\:tw-translate-x-5:focus{
  --tw-translate-x: 1.25rem;
}

.focus\:tw-translate-x-6:focus{
  --tw-translate-x: 1.5rem;
}

.focus\:tw-translate-x-8:focus{
  --tw-translate-x: 2rem;
}

.focus\:tw-translate-x-10:focus{
  --tw-translate-x: 2.5rem;
}

.focus\:tw-translate-x-12:focus{
  --tw-translate-x: 3rem;
}

.focus\:tw-translate-x-16:focus{
  --tw-translate-x: 4rem;
}

.focus\:tw-translate-x-20:focus{
  --tw-translate-x: 5rem;
}

.focus\:tw-translate-x-24:focus{
  --tw-translate-x: 6rem;
}

.focus\:tw-translate-x-32:focus{
  --tw-translate-x: 8rem;
}

.focus\:tw-translate-x-40:focus{
  --tw-translate-x: 10rem;
}

.focus\:tw-translate-x-48:focus{
  --tw-translate-x: 12rem;
}

.focus\:tw-translate-x-56:focus{
  --tw-translate-x: 14rem;
}

.focus\:tw-translate-x-64:focus{
  --tw-translate-x: 16rem;
}

.focus\:tw-translate-x-px:focus{
  --tw-translate-x: 1px;
}

.focus\:tw--translate-x-1:focus{
  --tw-translate-x: -0.25rem;
}

.focus\:tw--translate-x-2:focus{
  --tw-translate-x: -0.5rem;
}

.focus\:tw--translate-x-3:focus{
  --tw-translate-x: -0.75rem;
}

.focus\:tw--translate-x-4:focus{
  --tw-translate-x: -1rem;
}

.focus\:tw--translate-x-5:focus{
  --tw-translate-x: -1.25rem;
}

.focus\:tw--translate-x-6:focus{
  --tw-translate-x: -1.5rem;
}

.focus\:tw--translate-x-8:focus{
  --tw-translate-x: -2rem;
}

.focus\:tw--translate-x-10:focus{
  --tw-translate-x: -2.5rem;
}

.focus\:tw--translate-x-12:focus{
  --tw-translate-x: -3rem;
}

.focus\:tw--translate-x-16:focus{
  --tw-translate-x: -4rem;
}

.focus\:tw--translate-x-20:focus{
  --tw-translate-x: -5rem;
}

.focus\:tw--translate-x-24:focus{
  --tw-translate-x: -6rem;
}

.focus\:tw--translate-x-32:focus{
  --tw-translate-x: -8rem;
}

.focus\:tw--translate-x-40:focus{
  --tw-translate-x: -10rem;
}

.focus\:tw--translate-x-48:focus{
  --tw-translate-x: -12rem;
}

.focus\:tw--translate-x-56:focus{
  --tw-translate-x: -14rem;
}

.focus\:tw--translate-x-64:focus{
  --tw-translate-x: -16rem;
}

.focus\:tw--translate-x-px:focus{
  --tw-translate-x: -1px;
}

.focus\:tw-translate-x-1\/2:focus{
  --tw-translate-x: 50%;
}

.focus\:tw-translate-x-1\/3:focus{
  --tw-translate-x: 33.333333%;
}

.focus\:tw-translate-x-2\/3:focus{
  --tw-translate-x: 66.666667%;
}

.focus\:tw-translate-x-1\/4:focus{
  --tw-translate-x: 25%;
}

.focus\:tw-translate-x-2\/4:focus{
  --tw-translate-x: 50%;
}

.focus\:tw-translate-x-3\/4:focus{
  --tw-translate-x: 75%;
}

.focus\:tw-translate-x-full:focus{
  --tw-translate-x: 100%;
}

.focus\:tw--translate-x-1\/2:focus{
  --tw-translate-x: -50%;
}

.focus\:tw--translate-x-1\/3:focus{
  --tw-translate-x: -33.333333%;
}

.focus\:tw--translate-x-2\/3:focus{
  --tw-translate-x: -66.666667%;
}

.focus\:tw--translate-x-1\/4:focus{
  --tw-translate-x: -25%;
}

.focus\:tw--translate-x-2\/4:focus{
  --tw-translate-x: -50%;
}

.focus\:tw--translate-x-3\/4:focus{
  --tw-translate-x: -75%;
}

.focus\:tw--translate-x-full:focus{
  --tw-translate-x: -100%;
}

.focus\:tw-translate-y-0:focus{
  --tw-translate-y: 0;
}

.focus\:tw-translate-y-1:focus{
  --tw-translate-y: 0.25rem;
}

.focus\:tw-translate-y-2:focus{
  --tw-translate-y: 0.5rem;
}

.focus\:tw-translate-y-3:focus{
  --tw-translate-y: 0.75rem;
}

.focus\:tw-translate-y-4:focus{
  --tw-translate-y: 1rem;
}

.focus\:tw-translate-y-5:focus{
  --tw-translate-y: 1.25rem;
}

.focus\:tw-translate-y-6:focus{
  --tw-translate-y: 1.5rem;
}

.focus\:tw-translate-y-8:focus{
  --tw-translate-y: 2rem;
}

.focus\:tw-translate-y-10:focus{
  --tw-translate-y: 2.5rem;
}

.focus\:tw-translate-y-12:focus{
  --tw-translate-y: 3rem;
}

.focus\:tw-translate-y-16:focus{
  --tw-translate-y: 4rem;
}

.focus\:tw-translate-y-20:focus{
  --tw-translate-y: 5rem;
}

.focus\:tw-translate-y-24:focus{
  --tw-translate-y: 6rem;
}

.focus\:tw-translate-y-32:focus{
  --tw-translate-y: 8rem;
}

.focus\:tw-translate-y-40:focus{
  --tw-translate-y: 10rem;
}

.focus\:tw-translate-y-48:focus{
  --tw-translate-y: 12rem;
}

.focus\:tw-translate-y-56:focus{
  --tw-translate-y: 14rem;
}

.focus\:tw-translate-y-64:focus{
  --tw-translate-y: 16rem;
}

.focus\:tw-translate-y-px:focus{
  --tw-translate-y: 1px;
}

.focus\:tw--translate-y-1:focus{
  --tw-translate-y: -0.25rem;
}

.focus\:tw--translate-y-2:focus{
  --tw-translate-y: -0.5rem;
}

.focus\:tw--translate-y-3:focus{
  --tw-translate-y: -0.75rem;
}

.focus\:tw--translate-y-4:focus{
  --tw-translate-y: -1rem;
}

.focus\:tw--translate-y-5:focus{
  --tw-translate-y: -1.25rem;
}

.focus\:tw--translate-y-6:focus{
  --tw-translate-y: -1.5rem;
}

.focus\:tw--translate-y-8:focus{
  --tw-translate-y: -2rem;
}

.focus\:tw--translate-y-10:focus{
  --tw-translate-y: -2.5rem;
}

.focus\:tw--translate-y-12:focus{
  --tw-translate-y: -3rem;
}

.focus\:tw--translate-y-16:focus{
  --tw-translate-y: -4rem;
}

.focus\:tw--translate-y-20:focus{
  --tw-translate-y: -5rem;
}

.focus\:tw--translate-y-24:focus{
  --tw-translate-y: -6rem;
}

.focus\:tw--translate-y-32:focus{
  --tw-translate-y: -8rem;
}

.focus\:tw--translate-y-40:focus{
  --tw-translate-y: -10rem;
}

.focus\:tw--translate-y-48:focus{
  --tw-translate-y: -12rem;
}

.focus\:tw--translate-y-56:focus{
  --tw-translate-y: -14rem;
}

.focus\:tw--translate-y-64:focus{
  --tw-translate-y: -16rem;
}

.focus\:tw--translate-y-px:focus{
  --tw-translate-y: -1px;
}

.focus\:tw-translate-y-1\/2:focus{
  --tw-translate-y: 50%;
}

.focus\:tw-translate-y-1\/3:focus{
  --tw-translate-y: 33.333333%;
}

.focus\:tw-translate-y-2\/3:focus{
  --tw-translate-y: 66.666667%;
}

.focus\:tw-translate-y-1\/4:focus{
  --tw-translate-y: 25%;
}

.focus\:tw-translate-y-2\/4:focus{
  --tw-translate-y: 50%;
}

.focus\:tw-translate-y-3\/4:focus{
  --tw-translate-y: 75%;
}

.focus\:tw-translate-y-full:focus{
  --tw-translate-y: 100%;
}

.focus\:tw--translate-y-1\/2:focus{
  --tw-translate-y: -50%;
}

.focus\:tw--translate-y-1\/3:focus{
  --tw-translate-y: -33.333333%;
}

.focus\:tw--translate-y-2\/3:focus{
  --tw-translate-y: -66.666667%;
}

.focus\:tw--translate-y-1\/4:focus{
  --tw-translate-y: -25%;
}

.focus\:tw--translate-y-2\/4:focus{
  --tw-translate-y: -50%;
}

.focus\:tw--translate-y-3\/4:focus{
  --tw-translate-y: -75%;
}

.focus\:tw--translate-y-full:focus{
  --tw-translate-y: -100%;
}

.tw-rotate-0{
  --tw-rotate: 0;
}

.tw-rotate-1{
  --tw-rotate: 1deg;
}

.tw-rotate-2{
  --tw-rotate: 2deg;
}

.tw-rotate-3{
  --tw-rotate: 3deg;
}

.tw-rotate-6{
  --tw-rotate: 6deg;
}

.tw-rotate-12{
  --tw-rotate: 12deg;
}

.tw-rotate-45{
  --tw-rotate: 45deg;
}

.tw-rotate-90{
  --tw-rotate: 90deg;
}

.tw-rotate-180{
  --tw-rotate: 180deg;
}

.tw--rotate-180{
  --tw-rotate: -180deg;
}

.tw--rotate-90{
  --tw-rotate: -90deg;
}

.tw--rotate-45{
  --tw-rotate: -45deg;
}

.tw--rotate-12{
  --tw-rotate: -12deg;
}

.tw--rotate-6{
  --tw-rotate: -6deg;
}

.tw--rotate-3{
  --tw-rotate: -3deg;
}

.tw--rotate-2{
  --tw-rotate: -2deg;
}

.tw--rotate-1{
  --tw-rotate: -1deg;
}

.hover\:tw-rotate-0:hover{
  --tw-rotate: 0;
}

.hover\:tw-rotate-1:hover{
  --tw-rotate: 1deg;
}

.hover\:tw-rotate-2:hover{
  --tw-rotate: 2deg;
}

.hover\:tw-rotate-3:hover{
  --tw-rotate: 3deg;
}

.hover\:tw-rotate-6:hover{
  --tw-rotate: 6deg;
}

.hover\:tw-rotate-12:hover{
  --tw-rotate: 12deg;
}

.hover\:tw-rotate-45:hover{
  --tw-rotate: 45deg;
}

.hover\:tw-rotate-90:hover{
  --tw-rotate: 90deg;
}

.hover\:tw-rotate-180:hover{
  --tw-rotate: 180deg;
}

.hover\:tw--rotate-180:hover{
  --tw-rotate: -180deg;
}

.hover\:tw--rotate-90:hover{
  --tw-rotate: -90deg;
}

.hover\:tw--rotate-45:hover{
  --tw-rotate: -45deg;
}

.hover\:tw--rotate-12:hover{
  --tw-rotate: -12deg;
}

.hover\:tw--rotate-6:hover{
  --tw-rotate: -6deg;
}

.hover\:tw--rotate-3:hover{
  --tw-rotate: -3deg;
}

.hover\:tw--rotate-2:hover{
  --tw-rotate: -2deg;
}

.hover\:tw--rotate-1:hover{
  --tw-rotate: -1deg;
}

.focus\:tw-rotate-0:focus{
  --tw-rotate: 0;
}

.focus\:tw-rotate-1:focus{
  --tw-rotate: 1deg;
}

.focus\:tw-rotate-2:focus{
  --tw-rotate: 2deg;
}

.focus\:tw-rotate-3:focus{
  --tw-rotate: 3deg;
}

.focus\:tw-rotate-6:focus{
  --tw-rotate: 6deg;
}

.focus\:tw-rotate-12:focus{
  --tw-rotate: 12deg;
}

.focus\:tw-rotate-45:focus{
  --tw-rotate: 45deg;
}

.focus\:tw-rotate-90:focus{
  --tw-rotate: 90deg;
}

.focus\:tw-rotate-180:focus{
  --tw-rotate: 180deg;
}

.focus\:tw--rotate-180:focus{
  --tw-rotate: -180deg;
}

.focus\:tw--rotate-90:focus{
  --tw-rotate: -90deg;
}

.focus\:tw--rotate-45:focus{
  --tw-rotate: -45deg;
}

.focus\:tw--rotate-12:focus{
  --tw-rotate: -12deg;
}

.focus\:tw--rotate-6:focus{
  --tw-rotate: -6deg;
}

.focus\:tw--rotate-3:focus{
  --tw-rotate: -3deg;
}

.focus\:tw--rotate-2:focus{
  --tw-rotate: -2deg;
}

.focus\:tw--rotate-1:focus{
  --tw-rotate: -1deg;
}

.tw-skew-x-0{
  --tw-skew-x: 0;
}

.tw-skew-x-1{
  --tw-skew-x: 1deg;
}

.tw-skew-x-2{
  --tw-skew-x: 2deg;
}

.tw-skew-x-3{
  --tw-skew-x: 3deg;
}

.tw-skew-x-6{
  --tw-skew-x: 6deg;
}

.tw-skew-x-12{
  --tw-skew-x: 12deg;
}

.tw--skew-x-12{
  --tw-skew-x: -12deg;
}

.tw--skew-x-6{
  --tw-skew-x: -6deg;
}

.tw--skew-x-3{
  --tw-skew-x: -3deg;
}

.tw--skew-x-2{
  --tw-skew-x: -2deg;
}

.tw--skew-x-1{
  --tw-skew-x: -1deg;
}

.tw-skew-y-0{
  --tw-skew-y: 0;
}

.tw-skew-y-1{
  --tw-skew-y: 1deg;
}

.tw-skew-y-2{
  --tw-skew-y: 2deg;
}

.tw-skew-y-3{
  --tw-skew-y: 3deg;
}

.tw-skew-y-6{
  --tw-skew-y: 6deg;
}

.tw-skew-y-12{
  --tw-skew-y: 12deg;
}

.tw--skew-y-12{
  --tw-skew-y: -12deg;
}

.tw--skew-y-6{
  --tw-skew-y: -6deg;
}

.tw--skew-y-3{
  --tw-skew-y: -3deg;
}

.tw--skew-y-2{
  --tw-skew-y: -2deg;
}

.tw--skew-y-1{
  --tw-skew-y: -1deg;
}

.hover\:tw-skew-x-0:hover{
  --tw-skew-x: 0;
}

.hover\:tw-skew-x-1:hover{
  --tw-skew-x: 1deg;
}

.hover\:tw-skew-x-2:hover{
  --tw-skew-x: 2deg;
}

.hover\:tw-skew-x-3:hover{
  --tw-skew-x: 3deg;
}

.hover\:tw-skew-x-6:hover{
  --tw-skew-x: 6deg;
}

.hover\:tw-skew-x-12:hover{
  --tw-skew-x: 12deg;
}

.hover\:tw--skew-x-12:hover{
  --tw-skew-x: -12deg;
}

.hover\:tw--skew-x-6:hover{
  --tw-skew-x: -6deg;
}

.hover\:tw--skew-x-3:hover{
  --tw-skew-x: -3deg;
}

.hover\:tw--skew-x-2:hover{
  --tw-skew-x: -2deg;
}

.hover\:tw--skew-x-1:hover{
  --tw-skew-x: -1deg;
}

.hover\:tw-skew-y-0:hover{
  --tw-skew-y: 0;
}

.hover\:tw-skew-y-1:hover{
  --tw-skew-y: 1deg;
}

.hover\:tw-skew-y-2:hover{
  --tw-skew-y: 2deg;
}

.hover\:tw-skew-y-3:hover{
  --tw-skew-y: 3deg;
}

.hover\:tw-skew-y-6:hover{
  --tw-skew-y: 6deg;
}

.hover\:tw-skew-y-12:hover{
  --tw-skew-y: 12deg;
}

.hover\:tw--skew-y-12:hover{
  --tw-skew-y: -12deg;
}

.hover\:tw--skew-y-6:hover{
  --tw-skew-y: -6deg;
}

.hover\:tw--skew-y-3:hover{
  --tw-skew-y: -3deg;
}

.hover\:tw--skew-y-2:hover{
  --tw-skew-y: -2deg;
}

.hover\:tw--skew-y-1:hover{
  --tw-skew-y: -1deg;
}

.focus\:tw-skew-x-0:focus{
  --tw-skew-x: 0;
}

.focus\:tw-skew-x-1:focus{
  --tw-skew-x: 1deg;
}

.focus\:tw-skew-x-2:focus{
  --tw-skew-x: 2deg;
}

.focus\:tw-skew-x-3:focus{
  --tw-skew-x: 3deg;
}

.focus\:tw-skew-x-6:focus{
  --tw-skew-x: 6deg;
}

.focus\:tw-skew-x-12:focus{
  --tw-skew-x: 12deg;
}

.focus\:tw--skew-x-12:focus{
  --tw-skew-x: -12deg;
}

.focus\:tw--skew-x-6:focus{
  --tw-skew-x: -6deg;
}

.focus\:tw--skew-x-3:focus{
  --tw-skew-x: -3deg;
}

.focus\:tw--skew-x-2:focus{
  --tw-skew-x: -2deg;
}

.focus\:tw--skew-x-1:focus{
  --tw-skew-x: -1deg;
}

.focus\:tw-skew-y-0:focus{
  --tw-skew-y: 0;
}

.focus\:tw-skew-y-1:focus{
  --tw-skew-y: 1deg;
}

.focus\:tw-skew-y-2:focus{
  --tw-skew-y: 2deg;
}

.focus\:tw-skew-y-3:focus{
  --tw-skew-y: 3deg;
}

.focus\:tw-skew-y-6:focus{
  --tw-skew-y: 6deg;
}

.focus\:tw-skew-y-12:focus{
  --tw-skew-y: 12deg;
}

.focus\:tw--skew-y-12:focus{
  --tw-skew-y: -12deg;
}

.focus\:tw--skew-y-6:focus{
  --tw-skew-y: -6deg;
}

.focus\:tw--skew-y-3:focus{
  --tw-skew-y: -3deg;
}

.focus\:tw--skew-y-2:focus{
  --tw-skew-y: -2deg;
}

.focus\:tw--skew-y-1:focus{
  --tw-skew-y: -1deg;
}

.tw-scale-0{
  --tw-scale-x: 0;
  --tw-scale-y: 0;
}

.tw-scale-50{
  --tw-scale-x: .5;
  --tw-scale-y: .5;
}

.tw-scale-75{
  --tw-scale-x: .75;
  --tw-scale-y: .75;
}

.tw-scale-90{
  --tw-scale-x: .9;
  --tw-scale-y: .9;
}

.tw-scale-95{
  --tw-scale-x: .95;
  --tw-scale-y: .95;
}

.tw-scale-100{
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}

.tw-scale-105{
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
}

.tw-scale-110{
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
}

.tw-scale-125{
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
}

.tw-scale-150{
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
}

.hover\:tw-scale-0:hover{
  --tw-scale-x: 0;
  --tw-scale-y: 0;
}

.hover\:tw-scale-50:hover{
  --tw-scale-x: .5;
  --tw-scale-y: .5;
}

.hover\:tw-scale-75:hover{
  --tw-scale-x: .75;
  --tw-scale-y: .75;
}

.hover\:tw-scale-90:hover{
  --tw-scale-x: .9;
  --tw-scale-y: .9;
}

.hover\:tw-scale-95:hover{
  --tw-scale-x: .95;
  --tw-scale-y: .95;
}

.hover\:tw-scale-100:hover{
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}

.hover\:tw-scale-105:hover{
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
}

.hover\:tw-scale-110:hover{
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
}

.hover\:tw-scale-125:hover{
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
}

.hover\:tw-scale-150:hover{
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
}

.focus\:tw-scale-0:focus{
  --tw-scale-x: 0;
  --tw-scale-y: 0;
}

.focus\:tw-scale-50:focus{
  --tw-scale-x: .5;
  --tw-scale-y: .5;
}

.focus\:tw-scale-75:focus{
  --tw-scale-x: .75;
  --tw-scale-y: .75;
}

.focus\:tw-scale-90:focus{
  --tw-scale-x: .9;
  --tw-scale-y: .9;
}

.focus\:tw-scale-95:focus{
  --tw-scale-x: .95;
  --tw-scale-y: .95;
}

.focus\:tw-scale-100:focus{
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}

.focus\:tw-scale-105:focus{
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
}

.focus\:tw-scale-110:focus{
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
}

.focus\:tw-scale-125:focus{
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
}

.focus\:tw-scale-150:focus{
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
}

.tw-scale-x-0{
  --tw-scale-x: 0;
}

.tw-scale-x-50{
  --tw-scale-x: .5;
}

.tw-scale-x-75{
  --tw-scale-x: .75;
}

.tw-scale-x-90{
  --tw-scale-x: .9;
}

.tw-scale-x-95{
  --tw-scale-x: .95;
}

.tw-scale-x-100{
  --tw-scale-x: 1;
}

.tw-scale-x-105{
  --tw-scale-x: 1.05;
}

.tw-scale-x-110{
  --tw-scale-x: 1.1;
}

.tw-scale-x-125{
  --tw-scale-x: 1.25;
}

.tw-scale-x-150{
  --tw-scale-x: 1.5;
}

.tw-scale-y-0{
  --tw-scale-y: 0;
}

.tw-scale-y-50{
  --tw-scale-y: .5;
}

.tw-scale-y-75{
  --tw-scale-y: .75;
}

.tw-scale-y-90{
  --tw-scale-y: .9;
}

.tw-scale-y-95{
  --tw-scale-y: .95;
}

.tw-scale-y-100{
  --tw-scale-y: 1;
}

.tw-scale-y-105{
  --tw-scale-y: 1.05;
}

.tw-scale-y-110{
  --tw-scale-y: 1.1;
}

.tw-scale-y-125{
  --tw-scale-y: 1.25;
}

.tw-scale-y-150{
  --tw-scale-y: 1.5;
}

.hover\:tw-scale-x-0:hover{
  --tw-scale-x: 0;
}

.hover\:tw-scale-x-50:hover{
  --tw-scale-x: .5;
}

.hover\:tw-scale-x-75:hover{
  --tw-scale-x: .75;
}

.hover\:tw-scale-x-90:hover{
  --tw-scale-x: .9;
}

.hover\:tw-scale-x-95:hover{
  --tw-scale-x: .95;
}

.hover\:tw-scale-x-100:hover{
  --tw-scale-x: 1;
}

.hover\:tw-scale-x-105:hover{
  --tw-scale-x: 1.05;
}

.hover\:tw-scale-x-110:hover{
  --tw-scale-x: 1.1;
}

.hover\:tw-scale-x-125:hover{
  --tw-scale-x: 1.25;
}

.hover\:tw-scale-x-150:hover{
  --tw-scale-x: 1.5;
}

.hover\:tw-scale-y-0:hover{
  --tw-scale-y: 0;
}

.hover\:tw-scale-y-50:hover{
  --tw-scale-y: .5;
}

.hover\:tw-scale-y-75:hover{
  --tw-scale-y: .75;
}

.hover\:tw-scale-y-90:hover{
  --tw-scale-y: .9;
}

.hover\:tw-scale-y-95:hover{
  --tw-scale-y: .95;
}

.hover\:tw-scale-y-100:hover{
  --tw-scale-y: 1;
}

.hover\:tw-scale-y-105:hover{
  --tw-scale-y: 1.05;
}

.hover\:tw-scale-y-110:hover{
  --tw-scale-y: 1.1;
}

.hover\:tw-scale-y-125:hover{
  --tw-scale-y: 1.25;
}

.hover\:tw-scale-y-150:hover{
  --tw-scale-y: 1.5;
}

.focus\:tw-scale-x-0:focus{
  --tw-scale-x: 0;
}

.focus\:tw-scale-x-50:focus{
  --tw-scale-x: .5;
}

.focus\:tw-scale-x-75:focus{
  --tw-scale-x: .75;
}

.focus\:tw-scale-x-90:focus{
  --tw-scale-x: .9;
}

.focus\:tw-scale-x-95:focus{
  --tw-scale-x: .95;
}

.focus\:tw-scale-x-100:focus{
  --tw-scale-x: 1;
}

.focus\:tw-scale-x-105:focus{
  --tw-scale-x: 1.05;
}

.focus\:tw-scale-x-110:focus{
  --tw-scale-x: 1.1;
}

.focus\:tw-scale-x-125:focus{
  --tw-scale-x: 1.25;
}

.focus\:tw-scale-x-150:focus{
  --tw-scale-x: 1.5;
}

.focus\:tw-scale-y-0:focus{
  --tw-scale-y: 0;
}

.focus\:tw-scale-y-50:focus{
  --tw-scale-y: .5;
}

.focus\:tw-scale-y-75:focus{
  --tw-scale-y: .75;
}

.focus\:tw-scale-y-90:focus{
  --tw-scale-y: .9;
}

.focus\:tw-scale-y-95:focus{
  --tw-scale-y: .95;
}

.focus\:tw-scale-y-100:focus{
  --tw-scale-y: 1;
}

.focus\:tw-scale-y-105:focus{
  --tw-scale-y: 1.05;
}

.focus\:tw-scale-y-110:focus{
  --tw-scale-y: 1.1;
}

.focus\:tw-scale-y-125:focus{
  --tw-scale-y: 1.25;
}

.focus\:tw-scale-y-150:focus{
  --tw-scale-y: 1.5;
}

@-webkit-keyframes tw-spin{
  to{
    transform: rotate(360deg);
  }
}

@keyframes tw-spin{
  to{
    transform: rotate(360deg);
  }
}

@-webkit-keyframes tw-ping{
  75%, 100%{
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes tw-ping{
  75%, 100%{
    transform: scale(2);
    opacity: 0;
  }
}

@-webkit-keyframes tw-pulse{
  50%{
    opacity: .5;
  }
}

@keyframes tw-pulse{
  50%{
    opacity: .5;
  }
}

@-webkit-keyframes tw-bounce{
  0%, 100%{
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50%{
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@keyframes tw-bounce{
  0%, 100%{
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50%{
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.tw-animate-none{
  -webkit-animation: none;
  animation: none;
}

.tw-animate-spin{
  -webkit-animation: tw-spin 1s linear infinite;
  animation: tw-spin 1s linear infinite;
}

.tw-animate-ping{
  -webkit-animation: tw-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  animation: tw-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.tw-animate-pulse{
  -webkit-animation: tw-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  animation: tw-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.tw-animate-bounce{
  -webkit-animation: tw-bounce 1s infinite;
  animation: tw-bounce 1s infinite;
}

.tw-cursor-auto{
  cursor: auto;
}

.tw-cursor-default{
  cursor: default;
}

.tw-cursor-pointer{
  cursor: pointer;
}

.tw-cursor-wait{
  cursor: wait;
}

.tw-cursor-text{
  cursor: text;
}

.tw-cursor-move{
  cursor: move;
}

.tw-cursor-not-allowed{
  cursor: not-allowed;
}

.tw-select-none{
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tw-select-text{
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.tw-select-all{
  -webkit-user-select: all;
  -ms-user-select: all;
  user-select: all;
}

.tw-select-auto{
  -webkit-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}

.tw-resize-none{
  resize: none;
}

.tw-resize-y{
  resize: vertical;
}

.tw-resize-x{
  resize: horizontal;
}

.tw-resize{
  resize: both;
}

.tw-list-inside{
  list-style-position: inside;
}

.tw-list-outside{
  list-style-position: outside;
}

.tw-list-none{
  list-style-type: none;
}

.tw-list-disc{
  list-style-type: disc;
}

.tw-list-decimal{
  list-style-type: decimal;
}

.tw-appearance-none{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.tw-auto-cols-auto{
  grid-auto-columns: auto;
}

.tw-auto-cols-min{
  grid-auto-columns: -webkit-min-content;
  grid-auto-columns: min-content;
}

.tw-auto-cols-max{
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
}

.tw-auto-cols-fr{
  grid-auto-columns: minmax(0, 1fr);
}

.tw-grid-flow-row{
  grid-auto-flow: row;
}

.tw-grid-flow-col{
  grid-auto-flow: column;
}

.tw-grid-flow-row-dense{
  grid-auto-flow: row dense;
}

.tw-grid-flow-col-dense{
  grid-auto-flow: column dense;
}

.tw-auto-rows-auto{
  grid-auto-rows: auto;
}

.tw-auto-rows-min{
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
}

.tw-auto-rows-max{
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content;
}

.tw-auto-rows-fr{
  grid-auto-rows: minmax(0, 1fr);
}

.tw-grid-cols-1{
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.tw-grid-cols-2{
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.tw-grid-cols-3{
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.tw-grid-cols-4{
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.tw-grid-cols-5{
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.tw-grid-cols-6{
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.tw-grid-cols-7{
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.tw-grid-cols-8{
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.tw-grid-cols-9{
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.tw-grid-cols-10{
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.tw-grid-cols-11{
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.tw-grid-cols-12{
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.tw-grid-cols-none{
  grid-template-columns: none;
}

.tw-grid-rows-1{
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.tw-grid-rows-2{
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.tw-grid-rows-3{
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.tw-grid-rows-4{
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.tw-grid-rows-5{
  grid-template-rows: repeat(5, minmax(0, 1fr));
}

.tw-grid-rows-6{
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

.tw-grid-rows-none{
  grid-template-rows: none;
}

.tw-flex-row{
  flex-direction: row;
}

.tw-flex-row-reverse{
  flex-direction: row-reverse;
}

.tw-flex-col{
  flex-direction: column;
}

.tw-flex-col-reverse{
  flex-direction: column-reverse;
}

.tw-flex-wrap{
  flex-wrap: wrap;
}

.tw-flex-wrap-reverse{
  flex-wrap: wrap-reverse;
}

.tw-flex-nowrap{
  flex-wrap: nowrap;
}

.tw-place-content-center{
  place-content: center;
}

.tw-place-content-start{
  place-content: start;
}

.tw-place-content-end{
  place-content: end;
}

.tw-place-content-between{
  place-content: space-between;
}

.tw-place-content-around{
  place-content: space-around;
}

.tw-place-content-evenly{
  place-content: space-evenly;
}

.tw-place-content-stretch{
  place-content: stretch;
}

.tw-place-items-start{
  place-items: start;
}

.tw-place-items-end{
  place-items: end;
}

.tw-place-items-center{
  place-items: center;
}

.tw-place-items-stretch{
  place-items: stretch;
}

.tw-content-center{
  align-content: center;
}

.tw-content-start{
  align-content: flex-start;
}

.tw-content-end{
  align-content: flex-end;
}

.tw-content-between{
  align-content: space-between;
}

.tw-content-around{
  align-content: space-around;
}

.tw-content-evenly{
  align-content: space-evenly;
}

.tw-items-start{
  align-items: flex-start;
}

.tw-items-end{
  align-items: flex-end;
}

.tw-items-center{
  align-items: center;
}

.tw-items-baseline{
  align-items: baseline;
}

.tw-items-stretch{
  align-items: stretch;
}

.tw-justify-start{
  justify-content: flex-start;
}

.tw-justify-end{
  justify-content: flex-end;
}

.tw-justify-center{
  justify-content: center;
}

.tw-justify-between{
  justify-content: space-between;
}

.tw-justify-around{
  justify-content: space-around;
}

.tw-justify-evenly{
  justify-content: space-evenly;
}

.tw-justify-items-start{
  justify-items: start;
}

.tw-justify-items-end{
  justify-items: end;
}

.tw-justify-items-center{
  justify-items: center;
}

.tw-justify-items-stretch{
  justify-items: stretch;
}

.tw-gap-0{
  gap: 0;
}

.tw-gap-1{
  gap: 0.25rem;
}

.tw-gap-2{
  gap: 0.5rem;
}

.tw-gap-3{
  gap: 0.75rem;
}

.tw-gap-4{
  gap: 1rem;
}

.tw-gap-5{
  gap: 1.25rem;
}

.tw-gap-6{
  gap: 1.5rem;
}

.tw-gap-8{
  gap: 2rem;
}

.tw-gap-10{
  gap: 2.5rem;
}

.tw-gap-12{
  gap: 3rem;
}

.tw-gap-16{
  gap: 4rem;
}

.tw-gap-20{
  gap: 5rem;
}

.tw-gap-24{
  gap: 6rem;
}

.tw-gap-32{
  gap: 8rem;
}

.tw-gap-40{
  gap: 10rem;
}

.tw-gap-48{
  gap: 12rem;
}

.tw-gap-56{
  gap: 14rem;
}

.tw-gap-64{
  gap: 16rem;
}

.tw-gap-px{
  gap: 1px;
}

.tw-gap-x-0{
  -webkit-column-gap: 0;
  column-gap: 0;
}

.tw-gap-x-1{
  -webkit-column-gap: 0.25rem;
  column-gap: 0.25rem;
}

.tw-gap-x-2{
  -webkit-column-gap: 0.5rem;
  column-gap: 0.5rem;
}

.tw-gap-x-3{
  -webkit-column-gap: 0.75rem;
  column-gap: 0.75rem;
}

.tw-gap-x-4{
  -webkit-column-gap: 1rem;
  column-gap: 1rem;
}

.tw-gap-x-5{
  -webkit-column-gap: 1.25rem;
  column-gap: 1.25rem;
}

.tw-gap-x-6{
  -webkit-column-gap: 1.5rem;
  column-gap: 1.5rem;
}

.tw-gap-x-8{
  -webkit-column-gap: 2rem;
  column-gap: 2rem;
}

.tw-gap-x-10{
  -webkit-column-gap: 2.5rem;
  column-gap: 2.5rem;
}

.tw-gap-x-12{
  -webkit-column-gap: 3rem;
  column-gap: 3rem;
}

.tw-gap-x-16{
  -webkit-column-gap: 4rem;
  column-gap: 4rem;
}

.tw-gap-x-20{
  -webkit-column-gap: 5rem;
  column-gap: 5rem;
}

.tw-gap-x-24{
  -webkit-column-gap: 6rem;
  column-gap: 6rem;
}

.tw-gap-x-32{
  -webkit-column-gap: 8rem;
  column-gap: 8rem;
}

.tw-gap-x-40{
  -webkit-column-gap: 10rem;
  column-gap: 10rem;
}

.tw-gap-x-48{
  -webkit-column-gap: 12rem;
  column-gap: 12rem;
}

.tw-gap-x-56{
  -webkit-column-gap: 14rem;
  column-gap: 14rem;
}

.tw-gap-x-64{
  -webkit-column-gap: 16rem;
  column-gap: 16rem;
}

.tw-gap-x-px{
  -webkit-column-gap: 1px;
  column-gap: 1px;
}

.tw-gap-y-0{
  row-gap: 0;
}

.tw-gap-y-1{
  row-gap: 0.25rem;
}

.tw-gap-y-2{
  row-gap: 0.5rem;
}

.tw-gap-y-3{
  row-gap: 0.75rem;
}

.tw-gap-y-4{
  row-gap: 1rem;
}

.tw-gap-y-5{
  row-gap: 1.25rem;
}

.tw-gap-y-6{
  row-gap: 1.5rem;
}

.tw-gap-y-8{
  row-gap: 2rem;
}

.tw-gap-y-10{
  row-gap: 2.5rem;
}

.tw-gap-y-12{
  row-gap: 3rem;
}

.tw-gap-y-16{
  row-gap: 4rem;
}

.tw-gap-y-20{
  row-gap: 5rem;
}

.tw-gap-y-24{
  row-gap: 6rem;
}

.tw-gap-y-32{
  row-gap: 8rem;
}

.tw-gap-y-40{
  row-gap: 10rem;
}

.tw-gap-y-48{
  row-gap: 12rem;
}

.tw-gap-y-56{
  row-gap: 14rem;
}

.tw-gap-y-64{
  row-gap: 16rem;
}

.tw-gap-y-px{
  row-gap: 1px;
}

.tw-space-x-0 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-1 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-6 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-8 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-10 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-12 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(3rem * var(--tw-space-x-reverse));
  margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-16 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(4rem * var(--tw-space-x-reverse));
  margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-20 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(5rem * var(--tw-space-x-reverse));
  margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-24 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(6rem * var(--tw-space-x-reverse));
  margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-32 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(8rem * var(--tw-space-x-reverse));
  margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-40 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(10rem * var(--tw-space-x-reverse));
  margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-48 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(12rem * var(--tw-space-x-reverse));
  margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-56 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(14rem * var(--tw-space-x-reverse));
  margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-64 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(16rem * var(--tw-space-x-reverse));
  margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-px > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(1px * var(--tw-space-x-reverse));
  margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
}

.tw--space-x-1 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw--space-x-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw--space-x-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw--space-x-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-1rem * var(--tw-space-x-reverse));
  margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw--space-x-5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw--space-x-6 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw--space-x-8 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-2rem * var(--tw-space-x-reverse));
  margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw--space-x-10 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw--space-x-12 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-3rem * var(--tw-space-x-reverse));
  margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw--space-x-16 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-4rem * var(--tw-space-x-reverse));
  margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw--space-x-20 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-5rem * var(--tw-space-x-reverse));
  margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw--space-x-24 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-6rem * var(--tw-space-x-reverse));
  margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw--space-x-32 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-8rem * var(--tw-space-x-reverse));
  margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw--space-x-40 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-10rem * var(--tw-space-x-reverse));
  margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw--space-x-48 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-12rem * var(--tw-space-x-reverse));
  margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw--space-x-56 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-14rem * var(--tw-space-x-reverse));
  margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw--space-x-64 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-16rem * var(--tw-space-x-reverse));
  margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw--space-x-px > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-1px * var(--tw-space-x-reverse));
  margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-y-0 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse));
}

.tw-space-y-1 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

.tw-space-y-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.tw-space-y-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.tw-space-y-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.tw-space-y-5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.tw-space-y-6 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.tw-space-y-8 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.tw-space-y-10 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}

.tw-space-y-12 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}

.tw-space-y-16 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(4rem * var(--tw-space-y-reverse));
}

.tw-space-y-20 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(5rem * var(--tw-space-y-reverse));
}

.tw-space-y-24 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(6rem * var(--tw-space-y-reverse));
}

.tw-space-y-32 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(8rem * var(--tw-space-y-reverse));
}

.tw-space-y-40 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(10rem * var(--tw-space-y-reverse));
}

.tw-space-y-48 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(12rem * var(--tw-space-y-reverse));
}

.tw-space-y-56 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(14rem * var(--tw-space-y-reverse));
}

.tw-space-y-64 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(16rem * var(--tw-space-y-reverse));
}

.tw-space-y-px > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1px * var(--tw-space-y-reverse));
}

.tw--space-y-1 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
}

.tw--space-y-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
}

.tw--space-y-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
}

.tw--space-y-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
}

.tw--space-y-5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
}

.tw--space-y-6 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
}

.tw--space-y-8 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
}

.tw--space-y-10 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
}

.tw--space-y-12 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
}

.tw--space-y-16 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
}

.tw--space-y-20 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
}

.tw--space-y-24 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
}

.tw--space-y-32 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
}

.tw--space-y-40 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
}

.tw--space-y-48 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
}

.tw--space-y-56 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
}

.tw--space-y-64 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
}

.tw--space-y-px > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1px * var(--tw-space-y-reverse));
}

.tw-space-y-reverse > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 1;
}

.tw-space-x-reverse > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 1;
}

.tw-divide-x-0 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-x-reverse: 0;
  border-right-width: calc(0px * var(--tw-divide-x-reverse));
  border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
}

.tw-divide-x-2 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-x-reverse: 0;
  border-right-width: calc(2px * var(--tw-divide-x-reverse));
  border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
}

.tw-divide-x-4 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-x-reverse: 0;
  border-right-width: calc(4px * var(--tw-divide-x-reverse));
  border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
}

.tw-divide-x-8 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-x-reverse: 0;
  border-right-width: calc(8px * var(--tw-divide-x-reverse));
  border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
}

.tw-divide-x-default > :not([hidden]) ~ :not([hidden]){
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.tw-divide-y-0 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
}

.tw-divide-y-2 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
}

.tw-divide-y-4 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
}

.tw-divide-y-8 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
}

.tw-divide-y-default > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.tw-divide-y-reverse > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 1;
}

.tw-divide-x-reverse > :not([hidden]) ~ :not([hidden]){
  --tw-divide-x-reverse: 1;
}

.tw-divide-solid > :not([hidden]) ~ :not([hidden]){
  border-style: solid;
}

.tw-divide-dashed > :not([hidden]) ~ :not([hidden]){
  border-style: dashed;
}

.tw-divide-dotted > :not([hidden]) ~ :not([hidden]){
  border-style: dotted;
}

.tw-divide-double > :not([hidden]) ~ :not([hidden]){
  border-style: double;
}

.tw-divide-none > :not([hidden]) ~ :not([hidden]){
  border-style: none;
}

.tw-divide-transparent > :not([hidden]) ~ :not([hidden]){
  border-color: transparent;
}

.tw-divide-current > :not([hidden]) ~ :not([hidden]){
  border-color: currentColor;
}

.tw-divide-black > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(44, 44, 44, var(--tw-divide-opacity));
}

.tw-divide-white > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
}

.tw-divide-gray-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(247, 250, 252, var(--tw-divide-opacity));
}

.tw-divide-gray-200 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(237, 242, 247, var(--tw-divide-opacity));
}

.tw-divide-gray-300 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(226, 232, 240, var(--tw-divide-opacity));
}

.tw-divide-gray-400 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(203, 213, 224, var(--tw-divide-opacity));
}

.tw-divide-gray-500 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(160, 174, 192, var(--tw-divide-opacity));
}

.tw-divide-gray-600 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(113, 128, 150, var(--tw-divide-opacity));
}

.tw-divide-gray-700 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(74, 85, 104, var(--tw-divide-opacity));
}

.tw-divide-gray-800 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(45, 55, 72, var(--tw-divide-opacity));
}

.tw-divide-gray-900 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(26, 32, 44, var(--tw-divide-opacity));
}

.tw-divide-blue-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(235, 248, 255, var(--tw-divide-opacity));
}

.tw-divide-blue-200 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(190, 227, 248, var(--tw-divide-opacity));
}

.tw-divide-blue-300 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(144, 205, 244, var(--tw-divide-opacity));
}

.tw-divide-blue-400 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(99, 179, 237, var(--tw-divide-opacity));
}

.tw-divide-blue-500 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(66, 153, 225, var(--tw-divide-opacity));
}

.tw-divide-blue-600 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(49, 130, 206, var(--tw-divide-opacity));
}

.tw-divide-blue-700 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(43, 108, 176, var(--tw-divide-opacity));
}

.tw-divide-blue-800 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(44, 82, 130, var(--tw-divide-opacity));
}

.tw-divide-blue-900 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(42, 67, 101, var(--tw-divide-opacity));
}

.tw-divide-opacity-0 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0;
}

.tw-divide-opacity-25 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.25;
}

.tw-divide-opacity-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.5;
}

.tw-divide-opacity-75 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.75;
}

.tw-divide-opacity-90 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.9;
}

.tw-divide-opacity-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
}

.tw-place-self-auto{
  place-self: auto;
}

.tw-place-self-start{
  place-self: start;
}

.tw-place-self-end{
  place-self: end;
}

.tw-place-self-center{
  place-self: center;
}

.tw-place-self-stretch{
  place-self: stretch;
}

.tw-self-auto{
  align-self: auto;
}

.tw-self-start{
  align-self: flex-start;
}

.tw-self-end{
  align-self: flex-end;
}

.tw-self-center{
  align-self: center;
}

.tw-self-stretch{
  align-self: stretch;
}

.tw-self-baseline{
  align-self: baseline;
}

.tw-justify-self-auto{
  justify-self: auto;
}

.tw-justify-self-start{
  justify-self: start;
}

.tw-justify-self-end{
  justify-self: end;
}

.tw-justify-self-center{
  justify-self: center;
}

.tw-justify-self-stretch{
  justify-self: stretch;
}

.tw-overflow-auto{
  overflow: auto;
}

.tw-overflow-hidden{
  overflow: hidden;
}

.tw-overflow-visible{
  overflow: visible;
}

.tw-overflow-scroll{
  overflow: scroll;
}

.tw-overflow-x-auto{
  overflow-x: auto;
}

.tw-overflow-y-auto{
  overflow-y: auto;
}

.tw-overflow-x-hidden{
  overflow-x: hidden;
}

.tw-overflow-y-hidden{
  overflow-y: hidden;
}

.tw-overflow-x-visible{
  overflow-x: visible;
}

.tw-overflow-y-visible{
  overflow-y: visible;
}

.tw-overflow-x-scroll{
  overflow-x: scroll;
}

.tw-overflow-y-scroll{
  overflow-y: scroll;
}

.tw-overscroll-auto{
  overscroll-behavior: auto;
}

.tw-overscroll-contain{
  overscroll-behavior: contain;
}

.tw-overscroll-none{
  overscroll-behavior: none;
}

.tw-overscroll-y-auto{
  overscroll-behavior-y: auto;
}

.tw-overscroll-y-contain{
  overscroll-behavior-y: contain;
}

.tw-overscroll-y-none{
  overscroll-behavior-y: none;
}

.tw-overscroll-x-auto{
  overscroll-behavior-x: auto;
}

.tw-overscroll-x-contain{
  overscroll-behavior-x: contain;
}

.tw-overscroll-x-none{
  overscroll-behavior-x: none;
}

.tw-truncate{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tw-overflow-ellipsis{
  text-overflow: ellipsis;
}

.tw-overflow-clip{
  text-overflow: clip;
}

.tw-whitespace-normal{
  white-space: normal;
}

.tw-whitespace-nowrap{
  white-space: nowrap;
}

.tw-whitespace-pre{
  white-space: pre;
}

.tw-whitespace-pre-line{
  white-space: pre-line;
}

.tw-whitespace-pre-wrap{
  white-space: pre-wrap;
}

.tw-break-normal{
  overflow-wrap: normal;
  word-break: normal;
}

.tw-break-words{
  overflow-wrap: break-word;
}

.tw-break-all{
  word-break: break-all;
}

.tw-rounded-none{
  border-radius: 0;
}

.tw-rounded-sm{
  border-radius: 0.125rem;
}

.tw-rounded-default{
  border-radius: 0.25rem;
}

.tw-rounded-md{
  border-radius: 0.375rem;
}

.tw-rounded-lg{
  border-radius: 0.5rem;
}

.tw-rounded-xl{
  border-radius: 0.75rem;
}

.tw-rounded-2xl{
  border-radius: 1rem;
}

.tw-rounded-3xl{
  border-radius: 1.5rem;
}

.tw-rounded-full{
  border-radius: 9999px;
}

.tw-rounded-t-none{
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.tw-rounded-t-sm{
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
}

.tw-rounded-t-default{
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.tw-rounded-t-md{
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.tw-rounded-t-lg{
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.tw-rounded-t-xl{
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.tw-rounded-t-2xl{
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.tw-rounded-t-3xl{
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.tw-rounded-t-full{
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
}

.tw-rounded-r-none{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tw-rounded-r-sm{
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}

.tw-rounded-r-default{
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.tw-rounded-r-md{
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.tw-rounded-r-lg{
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.tw-rounded-r-xl{
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.tw-rounded-r-2xl{
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.tw-rounded-r-3xl{
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.tw-rounded-r-full{
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.tw-rounded-b-none{
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.tw-rounded-b-sm{
  border-bottom-right-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.tw-rounded-b-default{
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.tw-rounded-b-md{
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.tw-rounded-b-lg{
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.tw-rounded-b-xl{
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.tw-rounded-b-2xl{
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.tw-rounded-b-3xl{
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.tw-rounded-b-full{
  border-bottom-right-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.tw-rounded-l-none{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.tw-rounded-l-sm{
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.tw-rounded-l-default{
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.tw-rounded-l-md{
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.tw-rounded-l-lg{
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.tw-rounded-l-xl{
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.tw-rounded-l-2xl{
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.tw-rounded-l-3xl{
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.tw-rounded-l-full{
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.tw-rounded-tl-none{
  border-top-left-radius: 0;
}

.tw-rounded-tl-sm{
  border-top-left-radius: 0.125rem;
}

.tw-rounded-tl-default{
  border-top-left-radius: 0.25rem;
}

.tw-rounded-tl-md{
  border-top-left-radius: 0.375rem;
}

.tw-rounded-tl-lg{
  border-top-left-radius: 0.5rem;
}

.tw-rounded-tl-xl{
  border-top-left-radius: 0.75rem;
}

.tw-rounded-tl-2xl{
  border-top-left-radius: 1rem;
}

.tw-rounded-tl-3xl{
  border-top-left-radius: 1.5rem;
}

.tw-rounded-tl-full{
  border-top-left-radius: 9999px;
}

.tw-rounded-tr-none{
  border-top-right-radius: 0;
}

.tw-rounded-tr-sm{
  border-top-right-radius: 0.125rem;
}

.tw-rounded-tr-default{
  border-top-right-radius: 0.25rem;
}

.tw-rounded-tr-md{
  border-top-right-radius: 0.375rem;
}

.tw-rounded-tr-lg{
  border-top-right-radius: 0.5rem;
}

.tw-rounded-tr-xl{
  border-top-right-radius: 0.75rem;
}

.tw-rounded-tr-2xl{
  border-top-right-radius: 1rem;
}

.tw-rounded-tr-3xl{
  border-top-right-radius: 1.5rem;
}

.tw-rounded-tr-full{
  border-top-right-radius: 9999px;
}

.tw-rounded-br-none{
  border-bottom-right-radius: 0;
}

.tw-rounded-br-sm{
  border-bottom-right-radius: 0.125rem;
}

.tw-rounded-br-default{
  border-bottom-right-radius: 0.25rem;
}

.tw-rounded-br-md{
  border-bottom-right-radius: 0.375rem;
}

.tw-rounded-br-lg{
  border-bottom-right-radius: 0.5rem;
}

.tw-rounded-br-xl{
  border-bottom-right-radius: 0.75rem;
}

.tw-rounded-br-2xl{
  border-bottom-right-radius: 1rem;
}

.tw-rounded-br-3xl{
  border-bottom-right-radius: 1.5rem;
}

.tw-rounded-br-full{
  border-bottom-right-radius: 9999px;
}

.tw-rounded-bl-none{
  border-bottom-left-radius: 0;
}

.tw-rounded-bl-sm{
  border-bottom-left-radius: 0.125rem;
}

.tw-rounded-bl-default{
  border-bottom-left-radius: 0.25rem;
}

.tw-rounded-bl-md{
  border-bottom-left-radius: 0.375rem;
}

.tw-rounded-bl-lg{
  border-bottom-left-radius: 0.5rem;
}

.tw-rounded-bl-xl{
  border-bottom-left-radius: 0.75rem;
}

.tw-rounded-bl-2xl{
  border-bottom-left-radius: 1rem;
}

.tw-rounded-bl-3xl{
  border-bottom-left-radius: 1.5rem;
}

.tw-rounded-bl-full{
  border-bottom-left-radius: 9999px;
}

.tw-border-0{
  border-width: 0;
}

.tw-border-2{
  border-width: 2px;
}

.tw-border-4{
  border-width: 4px;
}

.tw-border-8{
  border-width: 8px;
}

.tw-border-default{
  border-width: 1px;
}

.tw-border-t-0{
  border-top-width: 0;
}

.tw-border-t-2{
  border-top-width: 2px;
}

.tw-border-t-4{
  border-top-width: 4px;
}

.tw-border-t-8{
  border-top-width: 8px;
}

.tw-border-t-default{
  border-top-width: 1px;
}

.tw-border-r-0{
  border-right-width: 0;
}

.tw-border-r-2{
  border-right-width: 2px;
}

.tw-border-r-4{
  border-right-width: 4px;
}

.tw-border-r-8{
  border-right-width: 8px;
}

.tw-border-r-default{
  border-right-width: 1px;
}

.tw-border-b-0{
  border-bottom-width: 0;
}

.tw-border-b-2{
  border-bottom-width: 2px;
}

.tw-border-b-4{
  border-bottom-width: 4px;
}

.tw-border-b-8{
  border-bottom-width: 8px;
}

.tw-border-b-default{
  border-bottom-width: 1px;
}

.tw-border-l-0{
  border-left-width: 0;
}

.tw-border-l-2{
  border-left-width: 2px;
}

.tw-border-l-4{
  border-left-width: 4px;
}

.tw-border-l-8{
  border-left-width: 8px;
}

.tw-border-l-default{
  border-left-width: 1px;
}

.tw-border-solid{
  border-style: solid;
}

.tw-border-dashed{
  border-style: dashed;
}

.tw-border-dotted{
  border-style: dotted;
}

.tw-border-double{
  border-style: double;
}

.tw-border-none{
  border-style: none;
}

.tw-border-transparent{
  border-color: transparent;
}

.tw-border-current{
  border-color: currentColor;
}

.tw-border-black{
  --tw-border-opacity: 1;
  border-color: rgba(44, 44, 44, var(--tw-border-opacity));
}

.tw-border-white{
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.tw-border-gray-100{
  --tw-border-opacity: 1;
  border-color: rgba(247, 250, 252, var(--tw-border-opacity));
}

.tw-border-gray-200{
  --tw-border-opacity: 1;
  border-color: rgba(237, 242, 247, var(--tw-border-opacity));
}

.tw-border-gray-300{
  --tw-border-opacity: 1;
  border-color: rgba(226, 232, 240, var(--tw-border-opacity));
}

.tw-border-gray-400{
  --tw-border-opacity: 1;
  border-color: rgba(203, 213, 224, var(--tw-border-opacity));
}

.tw-border-gray-500{
  --tw-border-opacity: 1;
  border-color: rgba(160, 174, 192, var(--tw-border-opacity));
}

.tw-border-gray-600{
  --tw-border-opacity: 1;
  border-color: rgba(113, 128, 150, var(--tw-border-opacity));
}

.tw-border-gray-700{
  --tw-border-opacity: 1;
  border-color: rgba(74, 85, 104, var(--tw-border-opacity));
}

.tw-border-gray-800{
  --tw-border-opacity: 1;
  border-color: rgba(45, 55, 72, var(--tw-border-opacity));
}

.tw-border-gray-900{
  --tw-border-opacity: 1;
  border-color: rgba(26, 32, 44, var(--tw-border-opacity));
}

.tw-border-blue-100{
  --tw-border-opacity: 1;
  border-color: rgba(235, 248, 255, var(--tw-border-opacity));
}

.tw-border-blue-200{
  --tw-border-opacity: 1;
  border-color: rgba(190, 227, 248, var(--tw-border-opacity));
}

.tw-border-blue-300{
  --tw-border-opacity: 1;
  border-color: rgba(144, 205, 244, var(--tw-border-opacity));
}

.tw-border-blue-400{
  --tw-border-opacity: 1;
  border-color: rgba(99, 179, 237, var(--tw-border-opacity));
}

.tw-border-blue-500{
  --tw-border-opacity: 1;
  border-color: rgba(66, 153, 225, var(--tw-border-opacity));
}

.tw-border-blue-600{
  --tw-border-opacity: 1;
  border-color: rgba(49, 130, 206, var(--tw-border-opacity));
}

.tw-border-blue-700{
  --tw-border-opacity: 1;
  border-color: rgba(43, 108, 176, var(--tw-border-opacity));
}

.tw-border-blue-800{
  --tw-border-opacity: 1;
  border-color: rgba(44, 82, 130, var(--tw-border-opacity));
}

.tw-border-blue-900{
  --tw-border-opacity: 1;
  border-color: rgba(42, 67, 101, var(--tw-border-opacity));
}

.hover\:tw-border-transparent:hover{
  border-color: transparent;
}

.hover\:tw-border-current:hover{
  border-color: currentColor;
}

.hover\:tw-border-black:hover{
  --tw-border-opacity: 1;
  border-color: rgba(44, 44, 44, var(--tw-border-opacity));
}

.hover\:tw-border-white:hover{
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.hover\:tw-border-gray-100:hover{
  --tw-border-opacity: 1;
  border-color: rgba(247, 250, 252, var(--tw-border-opacity));
}

.hover\:tw-border-gray-200:hover{
  --tw-border-opacity: 1;
  border-color: rgba(237, 242, 247, var(--tw-border-opacity));
}

.hover\:tw-border-gray-300:hover{
  --tw-border-opacity: 1;
  border-color: rgba(226, 232, 240, var(--tw-border-opacity));
}

.hover\:tw-border-gray-400:hover{
  --tw-border-opacity: 1;
  border-color: rgba(203, 213, 224, var(--tw-border-opacity));
}

.hover\:tw-border-gray-500:hover{
  --tw-border-opacity: 1;
  border-color: rgba(160, 174, 192, var(--tw-border-opacity));
}

.hover\:tw-border-gray-600:hover{
  --tw-border-opacity: 1;
  border-color: rgba(113, 128, 150, var(--tw-border-opacity));
}

.hover\:tw-border-gray-700:hover{
  --tw-border-opacity: 1;
  border-color: rgba(74, 85, 104, var(--tw-border-opacity));
}

.hover\:tw-border-gray-800:hover{
  --tw-border-opacity: 1;
  border-color: rgba(45, 55, 72, var(--tw-border-opacity));
}

.hover\:tw-border-gray-900:hover{
  --tw-border-opacity: 1;
  border-color: rgba(26, 32, 44, var(--tw-border-opacity));
}

.hover\:tw-border-blue-100:hover{
  --tw-border-opacity: 1;
  border-color: rgba(235, 248, 255, var(--tw-border-opacity));
}

.hover\:tw-border-blue-200:hover{
  --tw-border-opacity: 1;
  border-color: rgba(190, 227, 248, var(--tw-border-opacity));
}

.hover\:tw-border-blue-300:hover{
  --tw-border-opacity: 1;
  border-color: rgba(144, 205, 244, var(--tw-border-opacity));
}

.hover\:tw-border-blue-400:hover{
  --tw-border-opacity: 1;
  border-color: rgba(99, 179, 237, var(--tw-border-opacity));
}

.hover\:tw-border-blue-500:hover{
  --tw-border-opacity: 1;
  border-color: rgba(66, 153, 225, var(--tw-border-opacity));
}

.hover\:tw-border-blue-600:hover{
  --tw-border-opacity: 1;
  border-color: rgba(49, 130, 206, var(--tw-border-opacity));
}

.hover\:tw-border-blue-700:hover{
  --tw-border-opacity: 1;
  border-color: rgba(43, 108, 176, var(--tw-border-opacity));
}

.hover\:tw-border-blue-800:hover{
  --tw-border-opacity: 1;
  border-color: rgba(44, 82, 130, var(--tw-border-opacity));
}

.hover\:tw-border-blue-900:hover{
  --tw-border-opacity: 1;
  border-color: rgba(42, 67, 101, var(--tw-border-opacity));
}

.focus\:tw-border-transparent:focus{
  border-color: transparent;
}

.focus\:tw-border-current:focus{
  border-color: currentColor;
}

.focus\:tw-border-black:focus{
  --tw-border-opacity: 1;
  border-color: rgba(44, 44, 44, var(--tw-border-opacity));
}

.focus\:tw-border-white:focus{
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.focus\:tw-border-gray-100:focus{
  --tw-border-opacity: 1;
  border-color: rgba(247, 250, 252, var(--tw-border-opacity));
}

.focus\:tw-border-gray-200:focus{
  --tw-border-opacity: 1;
  border-color: rgba(237, 242, 247, var(--tw-border-opacity));
}

.focus\:tw-border-gray-300:focus{
  --tw-border-opacity: 1;
  border-color: rgba(226, 232, 240, var(--tw-border-opacity));
}

.focus\:tw-border-gray-400:focus{
  --tw-border-opacity: 1;
  border-color: rgba(203, 213, 224, var(--tw-border-opacity));
}

.focus\:tw-border-gray-500:focus{
  --tw-border-opacity: 1;
  border-color: rgba(160, 174, 192, var(--tw-border-opacity));
}

.focus\:tw-border-gray-600:focus{
  --tw-border-opacity: 1;
  border-color: rgba(113, 128, 150, var(--tw-border-opacity));
}

.focus\:tw-border-gray-700:focus{
  --tw-border-opacity: 1;
  border-color: rgba(74, 85, 104, var(--tw-border-opacity));
}

.focus\:tw-border-gray-800:focus{
  --tw-border-opacity: 1;
  border-color: rgba(45, 55, 72, var(--tw-border-opacity));
}

.focus\:tw-border-gray-900:focus{
  --tw-border-opacity: 1;
  border-color: rgba(26, 32, 44, var(--tw-border-opacity));
}

.focus\:tw-border-blue-100:focus{
  --tw-border-opacity: 1;
  border-color: rgba(235, 248, 255, var(--tw-border-opacity));
}

.focus\:tw-border-blue-200:focus{
  --tw-border-opacity: 1;
  border-color: rgba(190, 227, 248, var(--tw-border-opacity));
}

.focus\:tw-border-blue-300:focus{
  --tw-border-opacity: 1;
  border-color: rgba(144, 205, 244, var(--tw-border-opacity));
}

.focus\:tw-border-blue-400:focus{
  --tw-border-opacity: 1;
  border-color: rgba(99, 179, 237, var(--tw-border-opacity));
}

.focus\:tw-border-blue-500:focus{
  --tw-border-opacity: 1;
  border-color: rgba(66, 153, 225, var(--tw-border-opacity));
}

.focus\:tw-border-blue-600:focus{
  --tw-border-opacity: 1;
  border-color: rgba(49, 130, 206, var(--tw-border-opacity));
}

.focus\:tw-border-blue-700:focus{
  --tw-border-opacity: 1;
  border-color: rgba(43, 108, 176, var(--tw-border-opacity));
}

.focus\:tw-border-blue-800:focus{
  --tw-border-opacity: 1;
  border-color: rgba(44, 82, 130, var(--tw-border-opacity));
}

.focus\:tw-border-blue-900:focus{
  --tw-border-opacity: 1;
  border-color: rgba(42, 67, 101, var(--tw-border-opacity));
}

.tw-border-opacity-0{
  --tw-border-opacity: 0;
}

.tw-border-opacity-25{
  --tw-border-opacity: 0.25;
}

.tw-border-opacity-50{
  --tw-border-opacity: 0.5;
}

.tw-border-opacity-75{
  --tw-border-opacity: 0.75;
}

.tw-border-opacity-90{
  --tw-border-opacity: 0.9;
}

.tw-border-opacity-100{
  --tw-border-opacity: 1;
}

.hover\:tw-border-opacity-0:hover{
  --tw-border-opacity: 0;
}

.hover\:tw-border-opacity-25:hover{
  --tw-border-opacity: 0.25;
}

.hover\:tw-border-opacity-50:hover{
  --tw-border-opacity: 0.5;
}

.hover\:tw-border-opacity-75:hover{
  --tw-border-opacity: 0.75;
}

.hover\:tw-border-opacity-90:hover{
  --tw-border-opacity: 0.9;
}

.hover\:tw-border-opacity-100:hover{
  --tw-border-opacity: 1;
}

.focus\:tw-border-opacity-0:focus{
  --tw-border-opacity: 0;
}

.focus\:tw-border-opacity-25:focus{
  --tw-border-opacity: 0.25;
}

.focus\:tw-border-opacity-50:focus{
  --tw-border-opacity: 0.5;
}

.focus\:tw-border-opacity-75:focus{
  --tw-border-opacity: 0.75;
}

.focus\:tw-border-opacity-90:focus{
  --tw-border-opacity: 0.9;
}

.focus\:tw-border-opacity-100:focus{
  --tw-border-opacity: 1;
}

.tw-bg-transparent{
  background-color: transparent;
}

.tw-bg-current{
  background-color: currentColor;
}

.tw-bg-black{
  --tw-bg-opacity: 1;
  background-color: rgba(44, 44, 44, var(--tw-bg-opacity));
}

.tw-bg-white{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.tw-bg-gray-100{
  --tw-bg-opacity: 1;
  background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
}

.tw-bg-gray-200{
  --tw-bg-opacity: 1;
  background-color: rgba(237, 242, 247, var(--tw-bg-opacity));
}

.tw-bg-gray-300{
  --tw-bg-opacity: 1;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}

.tw-bg-gray-400{
  --tw-bg-opacity: 1;
  background-color: rgba(203, 213, 224, var(--tw-bg-opacity));
}

.tw-bg-gray-500{
  --tw-bg-opacity: 1;
  background-color: rgba(160, 174, 192, var(--tw-bg-opacity));
}

.tw-bg-gray-600{
  --tw-bg-opacity: 1;
  background-color: rgba(113, 128, 150, var(--tw-bg-opacity));
}

.tw-bg-gray-700{
  --tw-bg-opacity: 1;
  background-color: rgba(74, 85, 104, var(--tw-bg-opacity));
}

.tw-bg-gray-800{
  --tw-bg-opacity: 1;
  background-color: rgba(45, 55, 72, var(--tw-bg-opacity));
}

.tw-bg-gray-900{
  --tw-bg-opacity: 1;
  background-color: rgba(26, 32, 44, var(--tw-bg-opacity));
}

.tw-bg-blue-100{
  --tw-bg-opacity: 1;
  background-color: rgba(235, 248, 255, var(--tw-bg-opacity));
}

.tw-bg-blue-200{
  --tw-bg-opacity: 1;
  background-color: rgba(190, 227, 248, var(--tw-bg-opacity));
}

.tw-bg-blue-300{
  --tw-bg-opacity: 1;
  background-color: rgba(144, 205, 244, var(--tw-bg-opacity));
}

.tw-bg-blue-400{
  --tw-bg-opacity: 1;
  background-color: rgba(99, 179, 237, var(--tw-bg-opacity));
}

.tw-bg-blue-500{
  --tw-bg-opacity: 1;
  background-color: rgba(66, 153, 225, var(--tw-bg-opacity));
}

.tw-bg-blue-600{
  --tw-bg-opacity: 1;
  background-color: rgba(49, 130, 206, var(--tw-bg-opacity));
}

.tw-bg-blue-700{
  --tw-bg-opacity: 1;
  background-color: rgba(43, 108, 176, var(--tw-bg-opacity));
}

.tw-bg-blue-800{
  --tw-bg-opacity: 1;
  background-color: rgba(44, 82, 130, var(--tw-bg-opacity));
}

.tw-bg-blue-900{
  --tw-bg-opacity: 1;
  background-color: rgba(42, 67, 101, var(--tw-bg-opacity));
}

.hover\:tw-bg-transparent:hover{
  background-color: transparent;
}

.hover\:tw-bg-current:hover{
  background-color: currentColor;
}

.hover\:tw-bg-black:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(44, 44, 44, var(--tw-bg-opacity));
}

.hover\:tw-bg-white:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.hover\:tw-bg-gray-100:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
}

.hover\:tw-bg-gray-200:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(237, 242, 247, var(--tw-bg-opacity));
}

.hover\:tw-bg-gray-300:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}

.hover\:tw-bg-gray-400:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(203, 213, 224, var(--tw-bg-opacity));
}

.hover\:tw-bg-gray-500:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(160, 174, 192, var(--tw-bg-opacity));
}

.hover\:tw-bg-gray-600:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(113, 128, 150, var(--tw-bg-opacity));
}

.hover\:tw-bg-gray-700:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(74, 85, 104, var(--tw-bg-opacity));
}

.hover\:tw-bg-gray-800:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(45, 55, 72, var(--tw-bg-opacity));
}

.hover\:tw-bg-gray-900:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(26, 32, 44, var(--tw-bg-opacity));
}

.hover\:tw-bg-blue-100:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(235, 248, 255, var(--tw-bg-opacity));
}

.hover\:tw-bg-blue-200:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(190, 227, 248, var(--tw-bg-opacity));
}

.hover\:tw-bg-blue-300:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(144, 205, 244, var(--tw-bg-opacity));
}

.hover\:tw-bg-blue-400:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(99, 179, 237, var(--tw-bg-opacity));
}

.hover\:tw-bg-blue-500:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(66, 153, 225, var(--tw-bg-opacity));
}

.hover\:tw-bg-blue-600:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(49, 130, 206, var(--tw-bg-opacity));
}

.hover\:tw-bg-blue-700:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(43, 108, 176, var(--tw-bg-opacity));
}

.hover\:tw-bg-blue-800:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(44, 82, 130, var(--tw-bg-opacity));
}

.hover\:tw-bg-blue-900:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(42, 67, 101, var(--tw-bg-opacity));
}

.focus\:tw-bg-transparent:focus{
  background-color: transparent;
}

.focus\:tw-bg-current:focus{
  background-color: currentColor;
}

.focus\:tw-bg-black:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(44, 44, 44, var(--tw-bg-opacity));
}

.focus\:tw-bg-white:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.focus\:tw-bg-gray-100:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
}

.focus\:tw-bg-gray-200:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(237, 242, 247, var(--tw-bg-opacity));
}

.focus\:tw-bg-gray-300:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}

.focus\:tw-bg-gray-400:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(203, 213, 224, var(--tw-bg-opacity));
}

.focus\:tw-bg-gray-500:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(160, 174, 192, var(--tw-bg-opacity));
}

.focus\:tw-bg-gray-600:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(113, 128, 150, var(--tw-bg-opacity));
}

.focus\:tw-bg-gray-700:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(74, 85, 104, var(--tw-bg-opacity));
}

.focus\:tw-bg-gray-800:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(45, 55, 72, var(--tw-bg-opacity));
}

.focus\:tw-bg-gray-900:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(26, 32, 44, var(--tw-bg-opacity));
}

.focus\:tw-bg-blue-100:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(235, 248, 255, var(--tw-bg-opacity));
}

.focus\:tw-bg-blue-200:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(190, 227, 248, var(--tw-bg-opacity));
}

.focus\:tw-bg-blue-300:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(144, 205, 244, var(--tw-bg-opacity));
}

.focus\:tw-bg-blue-400:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(99, 179, 237, var(--tw-bg-opacity));
}

.focus\:tw-bg-blue-500:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(66, 153, 225, var(--tw-bg-opacity));
}

.focus\:tw-bg-blue-600:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(49, 130, 206, var(--tw-bg-opacity));
}

.focus\:tw-bg-blue-700:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(43, 108, 176, var(--tw-bg-opacity));
}

.focus\:tw-bg-blue-800:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(44, 82, 130, var(--tw-bg-opacity));
}

.focus\:tw-bg-blue-900:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(42, 67, 101, var(--tw-bg-opacity));
}

.tw-bg-opacity-0{
  --tw-bg-opacity: 0;
}

.tw-bg-opacity-25{
  --tw-bg-opacity: 0.25;
}

.tw-bg-opacity-50{
  --tw-bg-opacity: 0.5;
}

.tw-bg-opacity-75{
  --tw-bg-opacity: 0.75;
}

.tw-bg-opacity-90{
  --tw-bg-opacity: 0.9;
}

.tw-bg-opacity-100{
  --tw-bg-opacity: 1;
}

.hover\:tw-bg-opacity-0:hover{
  --tw-bg-opacity: 0;
}

.hover\:tw-bg-opacity-25:hover{
  --tw-bg-opacity: 0.25;
}

.hover\:tw-bg-opacity-50:hover{
  --tw-bg-opacity: 0.5;
}

.hover\:tw-bg-opacity-75:hover{
  --tw-bg-opacity: 0.75;
}

.hover\:tw-bg-opacity-90:hover{
  --tw-bg-opacity: 0.9;
}

.hover\:tw-bg-opacity-100:hover{
  --tw-bg-opacity: 1;
}

.focus\:tw-bg-opacity-0:focus{
  --tw-bg-opacity: 0;
}

.focus\:tw-bg-opacity-25:focus{
  --tw-bg-opacity: 0.25;
}

.focus\:tw-bg-opacity-50:focus{
  --tw-bg-opacity: 0.5;
}

.focus\:tw-bg-opacity-75:focus{
  --tw-bg-opacity: 0.75;
}

.focus\:tw-bg-opacity-90:focus{
  --tw-bg-opacity: 0.9;
}

.focus\:tw-bg-opacity-100:focus{
  --tw-bg-opacity: 1;
}

.tw-bg-none{
  background-image: none;
}

.tw-bg-gradient-to-t{
  background-image: linear-gradient(to top, var(--gradient-color-stops));
}

.tw-bg-gradient-to-tr{
  background-image: linear-gradient(to top right, var(--gradient-color-stops));
}

.tw-bg-gradient-to-r{
  background-image: linear-gradient(to right, var(--gradient-color-stops));
}

.tw-bg-gradient-to-br{
  background-image: linear-gradient(to bottom right, var(--gradient-color-stops));
}

.tw-bg-gradient-to-b{
  background-image: linear-gradient(to bottom, var(--gradient-color-stops));
}

.tw-bg-gradient-to-bl{
  background-image: linear-gradient(to bottom left, var(--gradient-color-stops));
}

.tw-bg-gradient-to-l{
  background-image: linear-gradient(to left, var(--gradient-color-stops));
}

.tw-bg-gradient-to-tl{
  background-image: linear-gradient(to top left, var(--gradient-color-stops));
}

.tw-from-transparent{
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
}

.tw-from-current{
  --tw-gradient-from: currentColor;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.tw-from-black{
  --tw-gradient-from: #2c2c2c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 44, 44, 0));
}

.tw-from-white{
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.tw-from-gray-100{
  --tw-gradient-from: #f7fafc;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 250, 252, 0));
}

.tw-from-gray-200{
  --tw-gradient-from: #edf2f7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 242, 247, 0));
}

.tw-from-gray-300{
  --tw-gradient-from: #e2e8f0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(226, 232, 240, 0));
}

.tw-from-gray-400{
  --tw-gradient-from: #cbd5e0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(203, 213, 224, 0));
}

.tw-from-gray-500{
  --tw-gradient-from: #a0aec0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(160, 174, 192, 0));
}

.tw-from-gray-600{
  --tw-gradient-from: #718096;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(113, 128, 150, 0));
}

.tw-from-gray-700{
  --tw-gradient-from: #4a5568;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 85, 104, 0));
}

.tw-from-gray-800{
  --tw-gradient-from: #2d3748;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 72, 0));
}

.tw-from-gray-900{
  --tw-gradient-from: #1a202c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 32, 44, 0));
}

.tw-from-blue-100{
  --tw-gradient-from: #ebf8ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(235, 248, 255, 0));
}

.tw-from-blue-200{
  --tw-gradient-from: #bee3f8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 227, 248, 0));
}

.tw-from-blue-300{
  --tw-gradient-from: #90cdf4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 205, 244, 0));
}

.tw-from-blue-400{
  --tw-gradient-from: #63b3ed;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 179, 237, 0));
}

.tw-from-blue-500{
  --tw-gradient-from: #4299e1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 153, 225, 0));
}

.tw-from-blue-600{
  --tw-gradient-from: #3182ce;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 130, 206, 0));
}

.tw-from-blue-700{
  --tw-gradient-from: #2b6cb0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(43, 108, 176, 0));
}

.tw-from-blue-800{
  --tw-gradient-from: #2c5282;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 82, 130, 0));
}

.tw-from-blue-900{
  --tw-gradient-from: #2a4365;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(42, 67, 101, 0));
}

.hover\:tw-from-transparent:hover{
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
}

.hover\:tw-from-current:hover{
  --tw-gradient-from: currentColor;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.hover\:tw-from-black:hover{
  --tw-gradient-from: #2c2c2c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 44, 44, 0));
}

.hover\:tw-from-white:hover{
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.hover\:tw-from-gray-100:hover{
  --tw-gradient-from: #f7fafc;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 250, 252, 0));
}

.hover\:tw-from-gray-200:hover{
  --tw-gradient-from: #edf2f7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 242, 247, 0));
}

.hover\:tw-from-gray-300:hover{
  --tw-gradient-from: #e2e8f0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(226, 232, 240, 0));
}

.hover\:tw-from-gray-400:hover{
  --tw-gradient-from: #cbd5e0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(203, 213, 224, 0));
}

.hover\:tw-from-gray-500:hover{
  --tw-gradient-from: #a0aec0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(160, 174, 192, 0));
}

.hover\:tw-from-gray-600:hover{
  --tw-gradient-from: #718096;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(113, 128, 150, 0));
}

.hover\:tw-from-gray-700:hover{
  --tw-gradient-from: #4a5568;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 85, 104, 0));
}

.hover\:tw-from-gray-800:hover{
  --tw-gradient-from: #2d3748;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 72, 0));
}

.hover\:tw-from-gray-900:hover{
  --tw-gradient-from: #1a202c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 32, 44, 0));
}

.hover\:tw-from-blue-100:hover{
  --tw-gradient-from: #ebf8ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(235, 248, 255, 0));
}

.hover\:tw-from-blue-200:hover{
  --tw-gradient-from: #bee3f8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 227, 248, 0));
}

.hover\:tw-from-blue-300:hover{
  --tw-gradient-from: #90cdf4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 205, 244, 0));
}

.hover\:tw-from-blue-400:hover{
  --tw-gradient-from: #63b3ed;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 179, 237, 0));
}

.hover\:tw-from-blue-500:hover{
  --tw-gradient-from: #4299e1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 153, 225, 0));
}

.hover\:tw-from-blue-600:hover{
  --tw-gradient-from: #3182ce;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 130, 206, 0));
}

.hover\:tw-from-blue-700:hover{
  --tw-gradient-from: #2b6cb0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(43, 108, 176, 0));
}

.hover\:tw-from-blue-800:hover{
  --tw-gradient-from: #2c5282;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 82, 130, 0));
}

.hover\:tw-from-blue-900:hover{
  --tw-gradient-from: #2a4365;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(42, 67, 101, 0));
}

.focus\:tw-from-transparent:focus{
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
}

.focus\:tw-from-current:focus{
  --tw-gradient-from: currentColor;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.focus\:tw-from-black:focus{
  --tw-gradient-from: #2c2c2c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 44, 44, 0));
}

.focus\:tw-from-white:focus{
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.focus\:tw-from-gray-100:focus{
  --tw-gradient-from: #f7fafc;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 250, 252, 0));
}

.focus\:tw-from-gray-200:focus{
  --tw-gradient-from: #edf2f7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 242, 247, 0));
}

.focus\:tw-from-gray-300:focus{
  --tw-gradient-from: #e2e8f0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(226, 232, 240, 0));
}

.focus\:tw-from-gray-400:focus{
  --tw-gradient-from: #cbd5e0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(203, 213, 224, 0));
}

.focus\:tw-from-gray-500:focus{
  --tw-gradient-from: #a0aec0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(160, 174, 192, 0));
}

.focus\:tw-from-gray-600:focus{
  --tw-gradient-from: #718096;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(113, 128, 150, 0));
}

.focus\:tw-from-gray-700:focus{
  --tw-gradient-from: #4a5568;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 85, 104, 0));
}

.focus\:tw-from-gray-800:focus{
  --tw-gradient-from: #2d3748;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 72, 0));
}

.focus\:tw-from-gray-900:focus{
  --tw-gradient-from: #1a202c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 32, 44, 0));
}

.focus\:tw-from-blue-100:focus{
  --tw-gradient-from: #ebf8ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(235, 248, 255, 0));
}

.focus\:tw-from-blue-200:focus{
  --tw-gradient-from: #bee3f8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 227, 248, 0));
}

.focus\:tw-from-blue-300:focus{
  --tw-gradient-from: #90cdf4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 205, 244, 0));
}

.focus\:tw-from-blue-400:focus{
  --tw-gradient-from: #63b3ed;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 179, 237, 0));
}

.focus\:tw-from-blue-500:focus{
  --tw-gradient-from: #4299e1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 153, 225, 0));
}

.focus\:tw-from-blue-600:focus{
  --tw-gradient-from: #3182ce;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 130, 206, 0));
}

.focus\:tw-from-blue-700:focus{
  --tw-gradient-from: #2b6cb0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(43, 108, 176, 0));
}

.focus\:tw-from-blue-800:focus{
  --tw-gradient-from: #2c5282;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 82, 130, 0));
}

.focus\:tw-from-blue-900:focus{
  --tw-gradient-from: #2a4365;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(42, 67, 101, 0));
}

.tw-via-transparent{
  --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
}

.tw-via-current{
  --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.tw-via-black{
  --tw-gradient-stops: var(--tw-gradient-from), #2c2c2c, var(--tw-gradient-to, rgba(44, 44, 44, 0));
}

.tw-via-white{
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.tw-via-gray-100{
  --tw-gradient-stops: var(--tw-gradient-from), #f7fafc, var(--tw-gradient-to, rgba(247, 250, 252, 0));
}

.tw-via-gray-200{
  --tw-gradient-stops: var(--tw-gradient-from), #edf2f7, var(--tw-gradient-to, rgba(237, 242, 247, 0));
}

.tw-via-gray-300{
  --tw-gradient-stops: var(--tw-gradient-from), #e2e8f0, var(--tw-gradient-to, rgba(226, 232, 240, 0));
}

.tw-via-gray-400{
  --tw-gradient-stops: var(--tw-gradient-from), #cbd5e0, var(--tw-gradient-to, rgba(203, 213, 224, 0));
}

.tw-via-gray-500{
  --tw-gradient-stops: var(--tw-gradient-from), #a0aec0, var(--tw-gradient-to, rgba(160, 174, 192, 0));
}

.tw-via-gray-600{
  --tw-gradient-stops: var(--tw-gradient-from), #718096, var(--tw-gradient-to, rgba(113, 128, 150, 0));
}

.tw-via-gray-700{
  --tw-gradient-stops: var(--tw-gradient-from), #4a5568, var(--tw-gradient-to, rgba(74, 85, 104, 0));
}

.tw-via-gray-800{
  --tw-gradient-stops: var(--tw-gradient-from), #2d3748, var(--tw-gradient-to, rgba(45, 55, 72, 0));
}

.tw-via-gray-900{
  --tw-gradient-stops: var(--tw-gradient-from), #1a202c, var(--tw-gradient-to, rgba(26, 32, 44, 0));
}

.tw-via-blue-100{
  --tw-gradient-stops: var(--tw-gradient-from), #ebf8ff, var(--tw-gradient-to, rgba(235, 248, 255, 0));
}

.tw-via-blue-200{
  --tw-gradient-stops: var(--tw-gradient-from), #bee3f8, var(--tw-gradient-to, rgba(190, 227, 248, 0));
}

.tw-via-blue-300{
  --tw-gradient-stops: var(--tw-gradient-from), #90cdf4, var(--tw-gradient-to, rgba(144, 205, 244, 0));
}

.tw-via-blue-400{
  --tw-gradient-stops: var(--tw-gradient-from), #63b3ed, var(--tw-gradient-to, rgba(99, 179, 237, 0));
}

.tw-via-blue-500{
  --tw-gradient-stops: var(--tw-gradient-from), #4299e1, var(--tw-gradient-to, rgba(66, 153, 225, 0));
}

.tw-via-blue-600{
  --tw-gradient-stops: var(--tw-gradient-from), #3182ce, var(--tw-gradient-to, rgba(49, 130, 206, 0));
}

.tw-via-blue-700{
  --tw-gradient-stops: var(--tw-gradient-from), #2b6cb0, var(--tw-gradient-to, rgba(43, 108, 176, 0));
}

.tw-via-blue-800{
  --tw-gradient-stops: var(--tw-gradient-from), #2c5282, var(--tw-gradient-to, rgba(44, 82, 130, 0));
}

.tw-via-blue-900{
  --tw-gradient-stops: var(--tw-gradient-from), #2a4365, var(--tw-gradient-to, rgba(42, 67, 101, 0));
}

.hover\:tw-via-transparent:hover{
  --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
}

.hover\:tw-via-current:hover{
  --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.hover\:tw-via-black:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #2c2c2c, var(--tw-gradient-to, rgba(44, 44, 44, 0));
}

.hover\:tw-via-white:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.hover\:tw-via-gray-100:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #f7fafc, var(--tw-gradient-to, rgba(247, 250, 252, 0));
}

.hover\:tw-via-gray-200:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #edf2f7, var(--tw-gradient-to, rgba(237, 242, 247, 0));
}

.hover\:tw-via-gray-300:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #e2e8f0, var(--tw-gradient-to, rgba(226, 232, 240, 0));
}

.hover\:tw-via-gray-400:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #cbd5e0, var(--tw-gradient-to, rgba(203, 213, 224, 0));
}

.hover\:tw-via-gray-500:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #a0aec0, var(--tw-gradient-to, rgba(160, 174, 192, 0));
}

.hover\:tw-via-gray-600:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #718096, var(--tw-gradient-to, rgba(113, 128, 150, 0));
}

.hover\:tw-via-gray-700:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #4a5568, var(--tw-gradient-to, rgba(74, 85, 104, 0));
}

.hover\:tw-via-gray-800:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #2d3748, var(--tw-gradient-to, rgba(45, 55, 72, 0));
}

.hover\:tw-via-gray-900:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #1a202c, var(--tw-gradient-to, rgba(26, 32, 44, 0));
}

.hover\:tw-via-blue-100:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #ebf8ff, var(--tw-gradient-to, rgba(235, 248, 255, 0));
}

.hover\:tw-via-blue-200:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #bee3f8, var(--tw-gradient-to, rgba(190, 227, 248, 0));
}

.hover\:tw-via-blue-300:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #90cdf4, var(--tw-gradient-to, rgba(144, 205, 244, 0));
}

.hover\:tw-via-blue-400:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #63b3ed, var(--tw-gradient-to, rgba(99, 179, 237, 0));
}

.hover\:tw-via-blue-500:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #4299e1, var(--tw-gradient-to, rgba(66, 153, 225, 0));
}

.hover\:tw-via-blue-600:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #3182ce, var(--tw-gradient-to, rgba(49, 130, 206, 0));
}

.hover\:tw-via-blue-700:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #2b6cb0, var(--tw-gradient-to, rgba(43, 108, 176, 0));
}

.hover\:tw-via-blue-800:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #2c5282, var(--tw-gradient-to, rgba(44, 82, 130, 0));
}

.hover\:tw-via-blue-900:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #2a4365, var(--tw-gradient-to, rgba(42, 67, 101, 0));
}

.focus\:tw-via-transparent:focus{
  --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
}

.focus\:tw-via-current:focus{
  --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.focus\:tw-via-black:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #2c2c2c, var(--tw-gradient-to, rgba(44, 44, 44, 0));
}

.focus\:tw-via-white:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.focus\:tw-via-gray-100:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #f7fafc, var(--tw-gradient-to, rgba(247, 250, 252, 0));
}

.focus\:tw-via-gray-200:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #edf2f7, var(--tw-gradient-to, rgba(237, 242, 247, 0));
}

.focus\:tw-via-gray-300:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #e2e8f0, var(--tw-gradient-to, rgba(226, 232, 240, 0));
}

.focus\:tw-via-gray-400:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #cbd5e0, var(--tw-gradient-to, rgba(203, 213, 224, 0));
}

.focus\:tw-via-gray-500:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #a0aec0, var(--tw-gradient-to, rgba(160, 174, 192, 0));
}

.focus\:tw-via-gray-600:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #718096, var(--tw-gradient-to, rgba(113, 128, 150, 0));
}

.focus\:tw-via-gray-700:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #4a5568, var(--tw-gradient-to, rgba(74, 85, 104, 0));
}

.focus\:tw-via-gray-800:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #2d3748, var(--tw-gradient-to, rgba(45, 55, 72, 0));
}

.focus\:tw-via-gray-900:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #1a202c, var(--tw-gradient-to, rgba(26, 32, 44, 0));
}

.focus\:tw-via-blue-100:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #ebf8ff, var(--tw-gradient-to, rgba(235, 248, 255, 0));
}

.focus\:tw-via-blue-200:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #bee3f8, var(--tw-gradient-to, rgba(190, 227, 248, 0));
}

.focus\:tw-via-blue-300:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #90cdf4, var(--tw-gradient-to, rgba(144, 205, 244, 0));
}

.focus\:tw-via-blue-400:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #63b3ed, var(--tw-gradient-to, rgba(99, 179, 237, 0));
}

.focus\:tw-via-blue-500:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #4299e1, var(--tw-gradient-to, rgba(66, 153, 225, 0));
}

.focus\:tw-via-blue-600:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #3182ce, var(--tw-gradient-to, rgba(49, 130, 206, 0));
}

.focus\:tw-via-blue-700:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #2b6cb0, var(--tw-gradient-to, rgba(43, 108, 176, 0));
}

.focus\:tw-via-blue-800:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #2c5282, var(--tw-gradient-to, rgba(44, 82, 130, 0));
}

.focus\:tw-via-blue-900:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #2a4365, var(--tw-gradient-to, rgba(42, 67, 101, 0));
}

.tw-to-transparent{
  --tw-gradient-to: transparent;
}

.tw-to-current{
  --tw-gradient-to: currentColor;
}

.tw-to-black{
  --tw-gradient-to: #2c2c2c;
}

.tw-to-white{
  --tw-gradient-to: #fff;
}

.tw-to-gray-100{
  --tw-gradient-to: #f7fafc;
}

.tw-to-gray-200{
  --tw-gradient-to: #edf2f7;
}

.tw-to-gray-300{
  --tw-gradient-to: #e2e8f0;
}

.tw-to-gray-400{
  --tw-gradient-to: #cbd5e0;
}

.tw-to-gray-500{
  --tw-gradient-to: #a0aec0;
}

.tw-to-gray-600{
  --tw-gradient-to: #718096;
}

.tw-to-gray-700{
  --tw-gradient-to: #4a5568;
}

.tw-to-gray-800{
  --tw-gradient-to: #2d3748;
}

.tw-to-gray-900{
  --tw-gradient-to: #1a202c;
}

.tw-to-blue-100{
  --tw-gradient-to: #ebf8ff;
}

.tw-to-blue-200{
  --tw-gradient-to: #bee3f8;
}

.tw-to-blue-300{
  --tw-gradient-to: #90cdf4;
}

.tw-to-blue-400{
  --tw-gradient-to: #63b3ed;
}

.tw-to-blue-500{
  --tw-gradient-to: #4299e1;
}

.tw-to-blue-600{
  --tw-gradient-to: #3182ce;
}

.tw-to-blue-700{
  --tw-gradient-to: #2b6cb0;
}

.tw-to-blue-800{
  --tw-gradient-to: #2c5282;
}

.tw-to-blue-900{
  --tw-gradient-to: #2a4365;
}

.hover\:tw-to-transparent:hover{
  --tw-gradient-to: transparent;
}

.hover\:tw-to-current:hover{
  --tw-gradient-to: currentColor;
}

.hover\:tw-to-black:hover{
  --tw-gradient-to: #2c2c2c;
}

.hover\:tw-to-white:hover{
  --tw-gradient-to: #fff;
}

.hover\:tw-to-gray-100:hover{
  --tw-gradient-to: #f7fafc;
}

.hover\:tw-to-gray-200:hover{
  --tw-gradient-to: #edf2f7;
}

.hover\:tw-to-gray-300:hover{
  --tw-gradient-to: #e2e8f0;
}

.hover\:tw-to-gray-400:hover{
  --tw-gradient-to: #cbd5e0;
}

.hover\:tw-to-gray-500:hover{
  --tw-gradient-to: #a0aec0;
}

.hover\:tw-to-gray-600:hover{
  --tw-gradient-to: #718096;
}

.hover\:tw-to-gray-700:hover{
  --tw-gradient-to: #4a5568;
}

.hover\:tw-to-gray-800:hover{
  --tw-gradient-to: #2d3748;
}

.hover\:tw-to-gray-900:hover{
  --tw-gradient-to: #1a202c;
}

.hover\:tw-to-blue-100:hover{
  --tw-gradient-to: #ebf8ff;
}

.hover\:tw-to-blue-200:hover{
  --tw-gradient-to: #bee3f8;
}

.hover\:tw-to-blue-300:hover{
  --tw-gradient-to: #90cdf4;
}

.hover\:tw-to-blue-400:hover{
  --tw-gradient-to: #63b3ed;
}

.hover\:tw-to-blue-500:hover{
  --tw-gradient-to: #4299e1;
}

.hover\:tw-to-blue-600:hover{
  --tw-gradient-to: #3182ce;
}

.hover\:tw-to-blue-700:hover{
  --tw-gradient-to: #2b6cb0;
}

.hover\:tw-to-blue-800:hover{
  --tw-gradient-to: #2c5282;
}

.hover\:tw-to-blue-900:hover{
  --tw-gradient-to: #2a4365;
}

.focus\:tw-to-transparent:focus{
  --tw-gradient-to: transparent;
}

.focus\:tw-to-current:focus{
  --tw-gradient-to: currentColor;
}

.focus\:tw-to-black:focus{
  --tw-gradient-to: #2c2c2c;
}

.focus\:tw-to-white:focus{
  --tw-gradient-to: #fff;
}

.focus\:tw-to-gray-100:focus{
  --tw-gradient-to: #f7fafc;
}

.focus\:tw-to-gray-200:focus{
  --tw-gradient-to: #edf2f7;
}

.focus\:tw-to-gray-300:focus{
  --tw-gradient-to: #e2e8f0;
}

.focus\:tw-to-gray-400:focus{
  --tw-gradient-to: #cbd5e0;
}

.focus\:tw-to-gray-500:focus{
  --tw-gradient-to: #a0aec0;
}

.focus\:tw-to-gray-600:focus{
  --tw-gradient-to: #718096;
}

.focus\:tw-to-gray-700:focus{
  --tw-gradient-to: #4a5568;
}

.focus\:tw-to-gray-800:focus{
  --tw-gradient-to: #2d3748;
}

.focus\:tw-to-gray-900:focus{
  --tw-gradient-to: #1a202c;
}

.focus\:tw-to-blue-100:focus{
  --tw-gradient-to: #ebf8ff;
}

.focus\:tw-to-blue-200:focus{
  --tw-gradient-to: #bee3f8;
}

.focus\:tw-to-blue-300:focus{
  --tw-gradient-to: #90cdf4;
}

.focus\:tw-to-blue-400:focus{
  --tw-gradient-to: #63b3ed;
}

.focus\:tw-to-blue-500:focus{
  --tw-gradient-to: #4299e1;
}

.focus\:tw-to-blue-600:focus{
  --tw-gradient-to: #3182ce;
}

.focus\:tw-to-blue-700:focus{
  --tw-gradient-to: #2b6cb0;
}

.focus\:tw-to-blue-800:focus{
  --tw-gradient-to: #2c5282;
}

.focus\:tw-to-blue-900:focus{
  --tw-gradient-to: #2a4365;
}

.tw-decoration-slice{
  -webkit-box-decoration-break: slice;
  box-decoration-break: slice;
}

.tw-decoration-clone{
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

.tw-bg-auto{
  background-size: auto;
}

.tw-bg-cover{
  background-size: cover;
}

.tw-bg-contain{
  background-size: contain;
}

.tw-bg-fixed{
  background-attachment: fixed;
}

.tw-bg-local{
  background-attachment: local;
}

.tw-bg-scroll{
  background-attachment: scroll;
}

.tw-bg-clip-border{
  background-clip: border-box;
}

.tw-bg-clip-padding{
  background-clip: padding-box;
}

.tw-bg-clip-content{
  background-clip: content-box;
}

.tw-bg-clip-text{
  -webkit-background-clip: text;
  background-clip: text;
}

.tw-bg-bottom{
  background-position: bottom;
}

.tw-bg-center{
  background-position: center;
}

.tw-bg-left{
  background-position: left;
}

.tw-bg-left-bottom{
  background-position: left bottom;
}

.tw-bg-left-top{
  background-position: left top;
}

.tw-bg-right{
  background-position: right;
}

.tw-bg-right-bottom{
  background-position: right bottom;
}

.tw-bg-right-top{
  background-position: right top;
}

.tw-bg-top{
  background-position: top;
}

.tw-bg-repeat{
  background-repeat: repeat;
}

.tw-bg-no-repeat{
  background-repeat: no-repeat;
}

.tw-bg-repeat-x{
  background-repeat: repeat-x;
}

.tw-bg-repeat-y{
  background-repeat: repeat-y;
}

.tw-bg-repeat-round{
  background-repeat: round;
}

.tw-bg-repeat-space{
  background-repeat: space;
}

.tw-bg-origin-border{
  background-origin: border-box;
}

.tw-bg-origin-padding{
  background-origin: padding-box;
}

.tw-bg-origin-content{
  background-origin: content-box;
}

.tw-fill-current{
  fill: currentColor;
}

.tw-stroke-current{
  stroke: currentColor;
}

.tw-stroke-0{
  stroke-width: 0;
}

.tw-stroke-1{
  stroke-width: 1;
}

.tw-stroke-2{
  stroke-width: 2;
}

.tw-object-contain{
  object-fit: contain;
}

.tw-object-cover{
  object-fit: cover;
}

.tw-object-fill{
  object-fit: fill;
}

.tw-object-none{
  object-fit: none;
}

.tw-object-scale-down{
  object-fit: scale-down;
}

.tw-object-bottom{
  object-position: bottom;
}

.tw-object-center{
  object-position: center;
}

.tw-object-left{
  object-position: left;
}

.tw-object-left-bottom{
  object-position: left bottom;
}

.tw-object-left-top{
  object-position: left top;
}

.tw-object-right{
  object-position: right;
}

.tw-object-right-bottom{
  object-position: right bottom;
}

.tw-object-right-top{
  object-position: right top;
}

.tw-object-top{
  object-position: top;
}

.tw-p-0{
  padding: 0;
}

.tw-p-1{
  padding: 0.25rem;
}

.tw-p-2{
  padding: 0.5rem;
}

.tw-p-3{
  padding: 0.75rem;
}

.tw-p-4{
  padding: 1rem;
}

.tw-p-5{
  padding: 1.25rem;
}

.tw-p-6{
  padding: 1.5rem;
}

.tw-p-8{
  padding: 2rem;
}

.tw-p-10{
  padding: 2.5rem;
}

.tw-p-12{
  padding: 3rem;
}

.tw-p-16{
  padding: 4rem;
}

.tw-p-20{
  padding: 5rem;
}

.tw-p-24{
  padding: 6rem;
}

.tw-p-32{
  padding: 8rem;
}

.tw-p-40{
  padding: 10rem;
}

.tw-p-48{
  padding: 12rem;
}

.tw-p-56{
  padding: 14rem;
}

.tw-p-64{
  padding: 16rem;
}

.tw-p-px{
  padding: 1px;
}

.tw-px-0{
  padding-left: 0;
  padding-right: 0;
}

.tw-px-1{
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.tw-px-2{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.tw-px-3{
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.tw-px-4{
  padding-left: 1rem;
  padding-right: 1rem;
}

.tw-px-5{
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.tw-px-6{
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.tw-px-8{
  padding-left: 2rem;
  padding-right: 2rem;
}

.tw-px-10{
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.tw-px-12{
  padding-left: 3rem;
  padding-right: 3rem;
}

.tw-px-16{
  padding-left: 4rem;
  padding-right: 4rem;
}

.tw-px-20{
  padding-left: 5rem;
  padding-right: 5rem;
}

.tw-px-24{
  padding-left: 6rem;
  padding-right: 6rem;
}

.tw-px-32{
  padding-left: 8rem;
  padding-right: 8rem;
}

.tw-px-40{
  padding-left: 10rem;
  padding-right: 10rem;
}

.tw-px-48{
  padding-left: 12rem;
  padding-right: 12rem;
}

.tw-px-56{
  padding-left: 14rem;
  padding-right: 14rem;
}

.tw-px-64{
  padding-left: 16rem;
  padding-right: 16rem;
}

.tw-px-px{
  padding-left: 1px;
  padding-right: 1px;
}

.tw-py-0{
  padding-top: 0;
  padding-bottom: 0;
}

.tw-py-1{
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.tw-py-2{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.tw-py-3{
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.tw-py-4{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.tw-py-5{
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.tw-py-6{
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.tw-py-8{
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.tw-py-10{
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.tw-py-12{
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.tw-py-16{
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.tw-py-20{
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.tw-py-24{
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.tw-py-32{
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.tw-py-40{
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.tw-py-48{
  padding-top: 12rem;
  padding-bottom: 12rem;
}

.tw-py-56{
  padding-top: 14rem;
  padding-bottom: 14rem;
}

.tw-py-64{
  padding-top: 16rem;
  padding-bottom: 16rem;
}

.tw-py-px{
  padding-top: 1px;
  padding-bottom: 1px;
}

.tw-pt-0{
  padding-top: 0;
}

.tw-pt-1{
  padding-top: 0.25rem;
}

.tw-pt-2{
  padding-top: 0.5rem;
}

.tw-pt-3{
  padding-top: 0.75rem;
}

.tw-pt-4{
  padding-top: 1rem;
}

.tw-pt-5{
  padding-top: 1.25rem;
}

.tw-pt-6{
  padding-top: 1.5rem;
}

.tw-pt-8{
  padding-top: 2rem;
}

.tw-pt-10{
  padding-top: 2.5rem;
}

.tw-pt-12{
  padding-top: 3rem;
}

.tw-pt-16{
  padding-top: 4rem;
}

.tw-pt-20{
  padding-top: 5rem;
}

.tw-pt-24{
  padding-top: 6rem;
}

.tw-pt-32{
  padding-top: 8rem;
}

.tw-pt-40{
  padding-top: 10rem;
}

.tw-pt-48{
  padding-top: 12rem;
}

.tw-pt-56{
  padding-top: 14rem;
}

.tw-pt-64{
  padding-top: 16rem;
}

.tw-pt-px{
  padding-top: 1px;
}

.tw-pr-0{
  padding-right: 0;
}

.tw-pr-1{
  padding-right: 0.25rem;
}

.tw-pr-2{
  padding-right: 0.5rem;
}

.tw-pr-3{
  padding-right: 0.75rem;
}

.tw-pr-4{
  padding-right: 1rem;
}

.tw-pr-5{
  padding-right: 1.25rem;
}

.tw-pr-6{
  padding-right: 1.5rem;
}

.tw-pr-8{
  padding-right: 2rem;
}

.tw-pr-10{
  padding-right: 2.5rem;
}

.tw-pr-12{
  padding-right: 3rem;
}

.tw-pr-16{
  padding-right: 4rem;
}

.tw-pr-20{
  padding-right: 5rem;
}

.tw-pr-24{
  padding-right: 6rem;
}

.tw-pr-32{
  padding-right: 8rem;
}

.tw-pr-40{
  padding-right: 10rem;
}

.tw-pr-48{
  padding-right: 12rem;
}

.tw-pr-56{
  padding-right: 14rem;
}

.tw-pr-64{
  padding-right: 16rem;
}

.tw-pr-px{
  padding-right: 1px;
}

.tw-pb-0{
  padding-bottom: 0;
}

.tw-pb-1{
  padding-bottom: 0.25rem;
}

.tw-pb-2{
  padding-bottom: 0.5rem;
}

.tw-pb-3{
  padding-bottom: 0.75rem;
}

.tw-pb-4{
  padding-bottom: 1rem;
}

.tw-pb-5{
  padding-bottom: 1.25rem;
}

.tw-pb-6{
  padding-bottom: 1.5rem;
}

.tw-pb-8{
  padding-bottom: 2rem;
}

.tw-pb-10{
  padding-bottom: 2.5rem;
}

.tw-pb-12{
  padding-bottom: 3rem;
}

.tw-pb-16{
  padding-bottom: 4rem;
}

.tw-pb-20{
  padding-bottom: 5rem;
}

.tw-pb-24{
  padding-bottom: 6rem;
}

.tw-pb-32{
  padding-bottom: 8rem;
}

.tw-pb-40{
  padding-bottom: 10rem;
}

.tw-pb-48{
  padding-bottom: 12rem;
}

.tw-pb-56{
  padding-bottom: 14rem;
}

.tw-pb-64{
  padding-bottom: 16rem;
}

.tw-pb-px{
  padding-bottom: 1px;
}

.tw-pl-0{
  padding-left: 0;
}

.tw-pl-1{
  padding-left: 0.25rem;
}

.tw-pl-2{
  padding-left: 0.5rem;
}

.tw-pl-3{
  padding-left: 0.75rem;
}

.tw-pl-4{
  padding-left: 1rem;
}

.tw-pl-5{
  padding-left: 1.25rem;
}

.tw-pl-6{
  padding-left: 1.5rem;
}

.tw-pl-8{
  padding-left: 2rem;
}

.tw-pl-10{
  padding-left: 2.5rem;
}

.tw-pl-12{
  padding-left: 3rem;
}

.tw-pl-16{
  padding-left: 4rem;
}

.tw-pl-20{
  padding-left: 5rem;
}

.tw-pl-24{
  padding-left: 6rem;
}

.tw-pl-32{
  padding-left: 8rem;
}

.tw-pl-40{
  padding-left: 10rem;
}

.tw-pl-48{
  padding-left: 12rem;
}

.tw-pl-56{
  padding-left: 14rem;
}

.tw-pl-64{
  padding-left: 16rem;
}

.tw-pl-px{
  padding-left: 1px;
}

.tw-text-left{
  text-align: left;
}

.tw-text-center{
  text-align: center;
}

.tw-text-right{
  text-align: right;
}

.tw-text-justify{
  text-align: justify;
}

.tw-align-baseline{
  vertical-align: baseline;
}

.tw-align-top{
  vertical-align: top;
}

.tw-align-middle{
  vertical-align: middle;
}

.tw-align-bottom{
  vertical-align: bottom;
}

.tw-align-text-top{
  vertical-align: text-top;
}

.tw-align-text-bottom{
  vertical-align: text-bottom;
}

.tw-font-sans{
  font-family: "Gotham SSm A", "Gotham SSm B", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.tw-font-serif{
  font-family: Georgia, Cambria, "Times New Roman", Times, serif;
}

.tw-font-mono{
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.tw-text-xs{
  font-size: 0.75rem;
}

.tw-text-sm{
  font-size: 0.875rem;
}

.tw-text-base{
  font-size: 1rem;
}

.tw-text-lg{
  font-size: 1.125rem;
}

.tw-text-xl{
  font-size: 1.25rem;
}

.tw-text-2xl{
  font-size: 1.5rem;
}

.tw-text-3xl{
  font-size: 1.875rem;
}

.tw-text-4xl{
  font-size: 2.25rem;
}

.tw-text-5xl{
  font-size: 3rem;
}

.tw-text-6xl{
  font-size: 4rem;
}

.tw-font-hairline{
  font-weight: 100;
}

.tw-font-thin{
  font-weight: 200;
}

.tw-font-light{
  font-weight: 300;
}

.tw-font-normal{
  font-weight: 400;
}

.tw-font-medium{
  font-weight: 500;
}

.tw-font-semibold{
  font-weight: 600;
}

.tw-font-bold{
  font-weight: 700;
}

.tw-font-extrabold{
  font-weight: 800;
}

.tw-font-black{
  font-weight: 900;
}

.hover\:tw-font-hairline:hover{
  font-weight: 100;
}

.hover\:tw-font-thin:hover{
  font-weight: 200;
}

.hover\:tw-font-light:hover{
  font-weight: 300;
}

.hover\:tw-font-normal:hover{
  font-weight: 400;
}

.hover\:tw-font-medium:hover{
  font-weight: 500;
}

.hover\:tw-font-semibold:hover{
  font-weight: 600;
}

.hover\:tw-font-bold:hover{
  font-weight: 700;
}

.hover\:tw-font-extrabold:hover{
  font-weight: 800;
}

.hover\:tw-font-black:hover{
  font-weight: 900;
}

.focus\:tw-font-hairline:focus{
  font-weight: 100;
}

.focus\:tw-font-thin:focus{
  font-weight: 200;
}

.focus\:tw-font-light:focus{
  font-weight: 300;
}

.focus\:tw-font-normal:focus{
  font-weight: 400;
}

.focus\:tw-font-medium:focus{
  font-weight: 500;
}

.focus\:tw-font-semibold:focus{
  font-weight: 600;
}

.focus\:tw-font-bold:focus{
  font-weight: 700;
}

.focus\:tw-font-extrabold:focus{
  font-weight: 800;
}

.focus\:tw-font-black:focus{
  font-weight: 900;
}

.tw-uppercase{
  text-transform: uppercase;
}

.tw-lowercase{
  text-transform: lowercase;
}

.tw-capitalize{
  text-transform: capitalize;
}

.tw-normal-case{
  text-transform: none;
}

.tw-italic{
  font-style: italic;
}

.tw-not-italic{
  font-style: normal;
}

.tw-ordinal, .tw-slashed-zero, .tw-lining-nums, .tw-oldstyle-nums, .tw-proportional-nums, .tw-tabular-nums, .tw-diagonal-fractions, .tw-stacked-fractions{
  --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
  --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.tw-normal-nums{
  font-variant-numeric: normal;
}

.tw-ordinal{
  --tw-ordinal: ordinal;
}

.tw-slashed-zero{
  --tw-slashed-zero: slashed-zero;
}

.tw-lining-nums{
  --tw-numeric-figure: lining-nums;
}

.tw-oldstyle-nums{
  --tw-numeric-figure: oldstyle-nums;
}

.tw-proportional-nums{
  --tw-numeric-spacing: proportional-nums;
}

.tw-tabular-nums{
  --tw-numeric-spacing: tabular-nums;
}

.tw-diagonal-fractions{
  --tw-numeric-fraction: diagonal-fractions;
}

.tw-stacked-fractions{
  --tw-numeric-fraction: stacked-fractions;
}

.tw-leading-3{
  line-height: .75rem;
}

.tw-leading-4{
  line-height: 1rem;
}

.tw-leading-5{
  line-height: 1.25rem;
}

.tw-leading-6{
  line-height: 1.5rem;
}

.tw-leading-7{
  line-height: 1.75rem;
}

.tw-leading-8{
  line-height: 2rem;
}

.tw-leading-9{
  line-height: 2.25rem;
}

.tw-leading-10{
  line-height: 2.5rem;
}

.tw-leading-none{
  line-height: 1;
}

.tw-leading-tight{
  line-height: 1.25;
}

.tw-leading-snug{
  line-height: 1.375;
}

.tw-leading-normal{
  line-height: 1.5;
}

.tw-leading-relaxed{
  line-height: 1.625;
}

.tw-leading-loose{
  line-height: 2;
}

.tw-tracking-tighter{
  letter-spacing: -0.05em;
}

.tw-tracking-tight{
  letter-spacing: -0.025em;
}

.tw-tracking-normal{
  letter-spacing: 0;
}

.tw-tracking-wide{
  letter-spacing: 0.025em;
}

.tw-tracking-wider{
  letter-spacing: 0.05em;
}

.tw-tracking-widest{
  letter-spacing: 0.1em;
}

.tw-text-transparent{
  color: transparent;
}

.tw-text-current{
  color: currentColor;
}

.tw-text-black{
  --tw-text-opacity: 1;
  color: rgba(44, 44, 44, var(--tw-text-opacity));
}

.tw-text-white{
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.tw-text-gray-100{
  --tw-text-opacity: 1;
  color: rgba(247, 250, 252, var(--tw-text-opacity));
}

.tw-text-gray-200{
  --tw-text-opacity: 1;
  color: rgba(237, 242, 247, var(--tw-text-opacity));
}

.tw-text-gray-300{
  --tw-text-opacity: 1;
  color: rgba(226, 232, 240, var(--tw-text-opacity));
}

.tw-text-gray-400{
  --tw-text-opacity: 1;
  color: rgba(203, 213, 224, var(--tw-text-opacity));
}

.tw-text-gray-500{
  --tw-text-opacity: 1;
  color: rgba(160, 174, 192, var(--tw-text-opacity));
}

.tw-text-gray-600{
  --tw-text-opacity: 1;
  color: rgba(113, 128, 150, var(--tw-text-opacity));
}

.tw-text-gray-700{
  --tw-text-opacity: 1;
  color: rgba(74, 85, 104, var(--tw-text-opacity));
}

.tw-text-gray-800{
  --tw-text-opacity: 1;
  color: rgba(45, 55, 72, var(--tw-text-opacity));
}

.tw-text-gray-900{
  --tw-text-opacity: 1;
  color: rgba(26, 32, 44, var(--tw-text-opacity));
}

.tw-text-blue-100{
  --tw-text-opacity: 1;
  color: rgba(235, 248, 255, var(--tw-text-opacity));
}

.tw-text-blue-200{
  --tw-text-opacity: 1;
  color: rgba(190, 227, 248, var(--tw-text-opacity));
}

.tw-text-blue-300{
  --tw-text-opacity: 1;
  color: rgba(144, 205, 244, var(--tw-text-opacity));
}

.tw-text-blue-400{
  --tw-text-opacity: 1;
  color: rgba(99, 179, 237, var(--tw-text-opacity));
}

.tw-text-blue-500{
  --tw-text-opacity: 1;
  color: rgba(66, 153, 225, var(--tw-text-opacity));
}

.tw-text-blue-600{
  --tw-text-opacity: 1;
  color: rgba(49, 130, 206, var(--tw-text-opacity));
}

.tw-text-blue-700{
  --tw-text-opacity: 1;
  color: rgba(43, 108, 176, var(--tw-text-opacity));
}

.tw-text-blue-800{
  --tw-text-opacity: 1;
  color: rgba(44, 82, 130, var(--tw-text-opacity));
}

.tw-text-blue-900{
  --tw-text-opacity: 1;
  color: rgba(42, 67, 101, var(--tw-text-opacity));
}

.hover\:tw-text-transparent:hover{
  color: transparent;
}

.hover\:tw-text-current:hover{
  color: currentColor;
}

.hover\:tw-text-black:hover{
  --tw-text-opacity: 1;
  color: rgba(44, 44, 44, var(--tw-text-opacity));
}

.hover\:tw-text-white:hover{
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.hover\:tw-text-gray-100:hover{
  --tw-text-opacity: 1;
  color: rgba(247, 250, 252, var(--tw-text-opacity));
}

.hover\:tw-text-gray-200:hover{
  --tw-text-opacity: 1;
  color: rgba(237, 242, 247, var(--tw-text-opacity));
}

.hover\:tw-text-gray-300:hover{
  --tw-text-opacity: 1;
  color: rgba(226, 232, 240, var(--tw-text-opacity));
}

.hover\:tw-text-gray-400:hover{
  --tw-text-opacity: 1;
  color: rgba(203, 213, 224, var(--tw-text-opacity));
}

.hover\:tw-text-gray-500:hover{
  --tw-text-opacity: 1;
  color: rgba(160, 174, 192, var(--tw-text-opacity));
}

.hover\:tw-text-gray-600:hover{
  --tw-text-opacity: 1;
  color: rgba(113, 128, 150, var(--tw-text-opacity));
}

.hover\:tw-text-gray-700:hover{
  --tw-text-opacity: 1;
  color: rgba(74, 85, 104, var(--tw-text-opacity));
}

.hover\:tw-text-gray-800:hover{
  --tw-text-opacity: 1;
  color: rgba(45, 55, 72, var(--tw-text-opacity));
}

.hover\:tw-text-gray-900:hover{
  --tw-text-opacity: 1;
  color: rgba(26, 32, 44, var(--tw-text-opacity));
}

.hover\:tw-text-blue-100:hover{
  --tw-text-opacity: 1;
  color: rgba(235, 248, 255, var(--tw-text-opacity));
}

.hover\:tw-text-blue-200:hover{
  --tw-text-opacity: 1;
  color: rgba(190, 227, 248, var(--tw-text-opacity));
}

.hover\:tw-text-blue-300:hover{
  --tw-text-opacity: 1;
  color: rgba(144, 205, 244, var(--tw-text-opacity));
}

.hover\:tw-text-blue-400:hover{
  --tw-text-opacity: 1;
  color: rgba(99, 179, 237, var(--tw-text-opacity));
}

.hover\:tw-text-blue-500:hover{
  --tw-text-opacity: 1;
  color: rgba(66, 153, 225, var(--tw-text-opacity));
}

.hover\:tw-text-blue-600:hover{
  --tw-text-opacity: 1;
  color: rgba(49, 130, 206, var(--tw-text-opacity));
}

.hover\:tw-text-blue-700:hover{
  --tw-text-opacity: 1;
  color: rgba(43, 108, 176, var(--tw-text-opacity));
}

.hover\:tw-text-blue-800:hover{
  --tw-text-opacity: 1;
  color: rgba(44, 82, 130, var(--tw-text-opacity));
}

.hover\:tw-text-blue-900:hover{
  --tw-text-opacity: 1;
  color: rgba(42, 67, 101, var(--tw-text-opacity));
}

.focus\:tw-text-transparent:focus{
  color: transparent;
}

.focus\:tw-text-current:focus{
  color: currentColor;
}

.focus\:tw-text-black:focus{
  --tw-text-opacity: 1;
  color: rgba(44, 44, 44, var(--tw-text-opacity));
}

.focus\:tw-text-white:focus{
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.focus\:tw-text-gray-100:focus{
  --tw-text-opacity: 1;
  color: rgba(247, 250, 252, var(--tw-text-opacity));
}

.focus\:tw-text-gray-200:focus{
  --tw-text-opacity: 1;
  color: rgba(237, 242, 247, var(--tw-text-opacity));
}

.focus\:tw-text-gray-300:focus{
  --tw-text-opacity: 1;
  color: rgba(226, 232, 240, var(--tw-text-opacity));
}

.focus\:tw-text-gray-400:focus{
  --tw-text-opacity: 1;
  color: rgba(203, 213, 224, var(--tw-text-opacity));
}

.focus\:tw-text-gray-500:focus{
  --tw-text-opacity: 1;
  color: rgba(160, 174, 192, var(--tw-text-opacity));
}

.focus\:tw-text-gray-600:focus{
  --tw-text-opacity: 1;
  color: rgba(113, 128, 150, var(--tw-text-opacity));
}

.focus\:tw-text-gray-700:focus{
  --tw-text-opacity: 1;
  color: rgba(74, 85, 104, var(--tw-text-opacity));
}

.focus\:tw-text-gray-800:focus{
  --tw-text-opacity: 1;
  color: rgba(45, 55, 72, var(--tw-text-opacity));
}

.focus\:tw-text-gray-900:focus{
  --tw-text-opacity: 1;
  color: rgba(26, 32, 44, var(--tw-text-opacity));
}

.focus\:tw-text-blue-100:focus{
  --tw-text-opacity: 1;
  color: rgba(235, 248, 255, var(--tw-text-opacity));
}

.focus\:tw-text-blue-200:focus{
  --tw-text-opacity: 1;
  color: rgba(190, 227, 248, var(--tw-text-opacity));
}

.focus\:tw-text-blue-300:focus{
  --tw-text-opacity: 1;
  color: rgba(144, 205, 244, var(--tw-text-opacity));
}

.focus\:tw-text-blue-400:focus{
  --tw-text-opacity: 1;
  color: rgba(99, 179, 237, var(--tw-text-opacity));
}

.focus\:tw-text-blue-500:focus{
  --tw-text-opacity: 1;
  color: rgba(66, 153, 225, var(--tw-text-opacity));
}

.focus\:tw-text-blue-600:focus{
  --tw-text-opacity: 1;
  color: rgba(49, 130, 206, var(--tw-text-opacity));
}

.focus\:tw-text-blue-700:focus{
  --tw-text-opacity: 1;
  color: rgba(43, 108, 176, var(--tw-text-opacity));
}

.focus\:tw-text-blue-800:focus{
  --tw-text-opacity: 1;
  color: rgba(44, 82, 130, var(--tw-text-opacity));
}

.focus\:tw-text-blue-900:focus{
  --tw-text-opacity: 1;
  color: rgba(42, 67, 101, var(--tw-text-opacity));
}

.tw-text-opacity-0{
  --tw-text-opacity: 0;
}

.tw-text-opacity-25{
  --tw-text-opacity: 0.25;
}

.tw-text-opacity-50{
  --tw-text-opacity: 0.5;
}

.tw-text-opacity-75{
  --tw-text-opacity: 0.75;
}

.tw-text-opacity-90{
  --tw-text-opacity: 0.9;
}

.tw-text-opacity-100{
  --tw-text-opacity: 1;
}

.hover\:tw-text-opacity-0:hover{
  --tw-text-opacity: 0;
}

.hover\:tw-text-opacity-25:hover{
  --tw-text-opacity: 0.25;
}

.hover\:tw-text-opacity-50:hover{
  --tw-text-opacity: 0.5;
}

.hover\:tw-text-opacity-75:hover{
  --tw-text-opacity: 0.75;
}

.hover\:tw-text-opacity-90:hover{
  --tw-text-opacity: 0.9;
}

.hover\:tw-text-opacity-100:hover{
  --tw-text-opacity: 1;
}

.focus\:tw-text-opacity-0:focus{
  --tw-text-opacity: 0;
}

.focus\:tw-text-opacity-25:focus{
  --tw-text-opacity: 0.25;
}

.focus\:tw-text-opacity-50:focus{
  --tw-text-opacity: 0.5;
}

.focus\:tw-text-opacity-75:focus{
  --tw-text-opacity: 0.75;
}

.focus\:tw-text-opacity-90:focus{
  --tw-text-opacity: 0.9;
}

.focus\:tw-text-opacity-100:focus{
  --tw-text-opacity: 1;
}

.tw-underline{
  text-decoration: underline;
}

.tw-line-through{
  text-decoration: line-through;
}

.tw-no-underline{
  text-decoration: none;
}

.hover\:tw-underline:hover{
  text-decoration: underline;
}

.hover\:tw-line-through:hover{
  text-decoration: line-through;
}

.hover\:tw-no-underline:hover{
  text-decoration: none;
}

.focus\:tw-underline:focus{
  text-decoration: underline;
}

.focus\:tw-line-through:focus{
  text-decoration: line-through;
}

.focus\:tw-no-underline:focus{
  text-decoration: none;
}

.tw-antialiased{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tw-subpixel-antialiased{
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.tw-placeholder-transparent::-webkit-input-placeholder{
  color: transparent;
}

.tw-placeholder-transparent::-ms-input-placeholder{
  color: transparent;
}

.tw-placeholder-transparent::placeholder{
  color: transparent;
}

.tw-placeholder-current::-webkit-input-placeholder{
  color: currentColor;
}

.tw-placeholder-current::-ms-input-placeholder{
  color: currentColor;
}

.tw-placeholder-current::placeholder{
  color: currentColor;
}

.tw-placeholder-black::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
}

.tw-placeholder-black::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
}

.tw-placeholder-black::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
}

.tw-placeholder-white::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}

.tw-placeholder-white::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}

.tw-placeholder-white::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-100::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-100::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-100::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-200::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-200::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-200::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-300::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-300::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-300::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-400::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-400::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-400::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-500::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-500::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-500::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-600::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-600::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-600::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-700::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-700::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-700::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-800::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-800::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-800::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-900::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-900::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-900::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
}

.tw-placeholder-blue-100::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
}

.tw-placeholder-blue-100::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
}

.tw-placeholder-blue-100::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
}

.tw-placeholder-blue-200::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
}

.tw-placeholder-blue-200::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
}

.tw-placeholder-blue-200::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
}

.tw-placeholder-blue-300::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
}

.tw-placeholder-blue-300::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
}

.tw-placeholder-blue-300::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
}

.tw-placeholder-blue-400::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
}

.tw-placeholder-blue-400::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
}

.tw-placeholder-blue-400::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
}

.tw-placeholder-blue-500::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
}

.tw-placeholder-blue-500::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
}

.tw-placeholder-blue-500::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
}

.tw-placeholder-blue-600::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
}

.tw-placeholder-blue-600::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
}

.tw-placeholder-blue-600::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
}

.tw-placeholder-blue-700::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
}

.tw-placeholder-blue-700::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
}

.tw-placeholder-blue-700::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
}

.tw-placeholder-blue-800::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
}

.tw-placeholder-blue-800::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
}

.tw-placeholder-blue-800::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
}

.tw-placeholder-blue-900::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
}

.tw-placeholder-blue-900::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
}

.tw-placeholder-blue-900::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-transparent:focus::-webkit-input-placeholder{
  color: transparent;
}

.focus\:tw-placeholder-transparent:focus::-ms-input-placeholder{
  color: transparent;
}

.focus\:tw-placeholder-transparent:focus::placeholder{
  color: transparent;
}

.focus\:tw-placeholder-current:focus::-webkit-input-placeholder{
  color: currentColor;
}

.focus\:tw-placeholder-current:focus::-ms-input-placeholder{
  color: currentColor;
}

.focus\:tw-placeholder-current:focus::placeholder{
  color: currentColor;
}

.focus\:tw-placeholder-black:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-black:focus::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-black:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-white:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-white:focus::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-white:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-100:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-100:focus::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-100:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-200:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-200:focus::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-200:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-300:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-300:focus::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-300:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-400:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-400:focus::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-400:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-500:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-500:focus::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-500:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-600:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-600:focus::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-600:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-700:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-700:focus::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-700:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-800:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-800:focus::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-800:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-900:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-900:focus::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-900:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-blue-100:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-blue-100:focus::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-blue-100:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-blue-200:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-blue-200:focus::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-blue-200:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-blue-300:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-blue-300:focus::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-blue-300:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-blue-400:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-blue-400:focus::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-blue-400:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-blue-500:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-blue-500:focus::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-blue-500:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-blue-600:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-blue-600:focus::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-blue-600:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-blue-700:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-blue-700:focus::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-blue-700:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-blue-800:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-blue-800:focus::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-blue-800:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-blue-900:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-blue-900:focus::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-blue-900:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
}

.tw-placeholder-opacity-0::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0;
}

.tw-placeholder-opacity-0::-ms-input-placeholder{
  --tw-placeholder-opacity: 0;
}

.tw-placeholder-opacity-0::placeholder{
  --tw-placeholder-opacity: 0;
}

.tw-placeholder-opacity-25::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.25;
}

.tw-placeholder-opacity-25::-ms-input-placeholder{
  --tw-placeholder-opacity: 0.25;
}

.tw-placeholder-opacity-25::placeholder{
  --tw-placeholder-opacity: 0.25;
}

.tw-placeholder-opacity-50::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.5;
}

.tw-placeholder-opacity-50::-ms-input-placeholder{
  --tw-placeholder-opacity: 0.5;
}

.tw-placeholder-opacity-50::placeholder{
  --tw-placeholder-opacity: 0.5;
}

.tw-placeholder-opacity-75::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.75;
}

.tw-placeholder-opacity-75::-ms-input-placeholder{
  --tw-placeholder-opacity: 0.75;
}

.tw-placeholder-opacity-75::placeholder{
  --tw-placeholder-opacity: 0.75;
}

.tw-placeholder-opacity-90::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.9;
}

.tw-placeholder-opacity-90::-ms-input-placeholder{
  --tw-placeholder-opacity: 0.9;
}

.tw-placeholder-opacity-90::placeholder{
  --tw-placeholder-opacity: 0.9;
}

.tw-placeholder-opacity-100::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
}

.tw-placeholder-opacity-100::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
}

.tw-placeholder-opacity-100::placeholder{
  --tw-placeholder-opacity: 1;
}

.focus\:tw-placeholder-opacity-0:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0;
}

.focus\:tw-placeholder-opacity-0:focus::-ms-input-placeholder{
  --tw-placeholder-opacity: 0;
}

.focus\:tw-placeholder-opacity-0:focus::placeholder{
  --tw-placeholder-opacity: 0;
}

.focus\:tw-placeholder-opacity-25:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.25;
}

.focus\:tw-placeholder-opacity-25:focus::-ms-input-placeholder{
  --tw-placeholder-opacity: 0.25;
}

.focus\:tw-placeholder-opacity-25:focus::placeholder{
  --tw-placeholder-opacity: 0.25;
}

.focus\:tw-placeholder-opacity-50:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.5;
}

.focus\:tw-placeholder-opacity-50:focus::-ms-input-placeholder{
  --tw-placeholder-opacity: 0.5;
}

.focus\:tw-placeholder-opacity-50:focus::placeholder{
  --tw-placeholder-opacity: 0.5;
}

.focus\:tw-placeholder-opacity-75:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.75;
}

.focus\:tw-placeholder-opacity-75:focus::-ms-input-placeholder{
  --tw-placeholder-opacity: 0.75;
}

.focus\:tw-placeholder-opacity-75:focus::placeholder{
  --tw-placeholder-opacity: 0.75;
}

.focus\:tw-placeholder-opacity-90:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.9;
}

.focus\:tw-placeholder-opacity-90:focus::-ms-input-placeholder{
  --tw-placeholder-opacity: 0.9;
}

.focus\:tw-placeholder-opacity-90:focus::placeholder{
  --tw-placeholder-opacity: 0.9;
}

.focus\:tw-placeholder-opacity-100:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
}

.focus\:tw-placeholder-opacity-100:focus::-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
}

.focus\:tw-placeholder-opacity-100:focus::placeholder{
  --tw-placeholder-opacity: 1;
}

.tw-opacity-0{
  opacity: 0;
}

.tw-opacity-25{
  opacity: 0.25;
}

.tw-opacity-50{
  opacity: 0.5;
}

.tw-opacity-75{
  opacity: 0.75;
}

.tw-opacity-90{
  opacity: 0.9;
}

.tw-opacity-100{
  opacity: 1;
}

.hover\:tw-opacity-0:hover{
  opacity: 0;
}

.hover\:tw-opacity-25:hover{
  opacity: 0.25;
}

.hover\:tw-opacity-50:hover{
  opacity: 0.5;
}

.hover\:tw-opacity-75:hover{
  opacity: 0.75;
}

.hover\:tw-opacity-90:hover{
  opacity: 0.9;
}

.hover\:tw-opacity-100:hover{
  opacity: 1;
}

.focus\:tw-opacity-0:focus{
  opacity: 0;
}

.focus\:tw-opacity-25:focus{
  opacity: 0.25;
}

.focus\:tw-opacity-50:focus{
  opacity: 0.5;
}

.focus\:tw-opacity-75:focus{
  opacity: 0.75;
}

.focus\:tw-opacity-90:focus{
  opacity: 0.9;
}

.focus\:tw-opacity-100:focus{
  opacity: 1;
}

.tw-bg-blend-normal{
  background-blend-mode: normal;
}

.tw-bg-blend-multiply{
  background-blend-mode: multiply;
}

.tw-bg-blend-screen{
  background-blend-mode: screen;
}

.tw-bg-blend-overlay{
  background-blend-mode: overlay;
}

.tw-bg-blend-darken{
  background-blend-mode: darken;
}

.tw-bg-blend-lighten{
  background-blend-mode: lighten;
}

.tw-bg-blend-color-dodge{
  background-blend-mode: color-dodge;
}

.tw-bg-blend-color-burn{
  background-blend-mode: color-burn;
}

.tw-bg-blend-hard-light{
  background-blend-mode: hard-light;
}

.tw-bg-blend-soft-light{
  background-blend-mode: soft-light;
}

.tw-bg-blend-difference{
  background-blend-mode: difference;
}

.tw-bg-blend-exclusion{
  background-blend-mode: exclusion;
}

.tw-bg-blend-hue{
  background-blend-mode: hue;
}

.tw-bg-blend-saturation{
  background-blend-mode: saturation;
}

.tw-bg-blend-color{
  background-blend-mode: color;
}

.tw-bg-blend-luminosity{
  background-blend-mode: luminosity;
}

.tw-mix-blend-normal{
  mix-blend-mode: normal;
}

.tw-mix-blend-multiply{
  mix-blend-mode: multiply;
}

.tw-mix-blend-screen{
  mix-blend-mode: screen;
}

.tw-mix-blend-overlay{
  mix-blend-mode: overlay;
}

.tw-mix-blend-darken{
  mix-blend-mode: darken;
}

.tw-mix-blend-lighten{
  mix-blend-mode: lighten;
}

.tw-mix-blend-color-dodge{
  mix-blend-mode: color-dodge;
}

.tw-mix-blend-color-burn{
  mix-blend-mode: color-burn;
}

.tw-mix-blend-hard-light{
  mix-blend-mode: hard-light;
}

.tw-mix-blend-soft-light{
  mix-blend-mode: soft-light;
}

.tw-mix-blend-difference{
  mix-blend-mode: difference;
}

.tw-mix-blend-exclusion{
  mix-blend-mode: exclusion;
}

.tw-mix-blend-hue{
  mix-blend-mode: hue;
}

.tw-mix-blend-saturation{
  mix-blend-mode: saturation;
}

.tw-mix-blend-color{
  mix-blend-mode: color;
}

.tw-mix-blend-luminosity{
  mix-blend-mode: luminosity;
}

*, ::before, ::after{
  --tw-shadow: 0 0 #0000;
}

.tw-shadow-xs{
  --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-sm{
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-default{
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-md{
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-lg{
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-xl{
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-2xl{
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-inner{
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-outline{
  --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-none{
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:tw-shadow-xs:hover{
  --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:tw-shadow-sm:hover{
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:tw-shadow-default:hover{
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:tw-shadow-md:hover{
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:tw-shadow-lg:hover{
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:tw-shadow-xl:hover{
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:tw-shadow-2xl:hover{
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:tw-shadow-inner:hover{
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:tw-shadow-outline:hover{
  --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:tw-shadow-none:hover{
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:tw-shadow-xs:focus{
  --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:tw-shadow-sm:focus{
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:tw-shadow-default:focus{
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:tw-shadow-md:focus{
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:tw-shadow-lg:focus{
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:tw-shadow-xl:focus{
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:tw-shadow-2xl:focus{
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:tw-shadow-inner:focus{
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:tw-shadow-outline:focus{
  --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:tw-shadow-none:focus{
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-outline-none{
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.tw-outline-white{
  outline: 2px dotted white;
  outline-offset: 2px;
}

.tw-outline-black{
  outline: 2px dotted black;
  outline-offset: 2px;
}

.focus\:tw-outline-none:focus{
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:tw-outline-white:focus{
  outline: 2px dotted white;
  outline-offset: 2px;
}

.focus\:tw-outline-black:focus{
  outline: 2px dotted black;
  outline-offset: 2px;
}

*, ::before, ::after{
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(66, 153, 225, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.tw-ring-0{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.tw-ring-1{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.tw-ring-2{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.tw-ring-4{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.tw-ring-8{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.tw-ring{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:tw-ring-0:focus-within{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:tw-ring-1:focus-within{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:tw-ring-2:focus-within{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:tw-ring-4:focus-within{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:tw-ring-8:focus-within{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:tw-ring:focus-within{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:tw-ring-0:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:tw-ring-1:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:tw-ring-2:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:tw-ring-4:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:tw-ring-8:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:tw-ring:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.tw-ring-inset{
  --tw-ring-inset: inset;
}

.focus-within\:tw-ring-inset:focus-within{
  --tw-ring-inset: inset;
}

.focus\:tw-ring-inset:focus{
  --tw-ring-inset: inset;
}

.tw-ring-transparent{
  --tw-ring-color: transparent;
}

.tw-ring-current{
  --tw-ring-color: currentColor;
}

.tw-ring-black{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(44, 44, 44, var(--tw-ring-opacity));
}

.tw-ring-white{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}

.tw-ring-gray-100{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(247, 250, 252, var(--tw-ring-opacity));
}

.tw-ring-gray-200{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(237, 242, 247, var(--tw-ring-opacity));
}

.tw-ring-gray-300{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(226, 232, 240, var(--tw-ring-opacity));
}

.tw-ring-gray-400{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(203, 213, 224, var(--tw-ring-opacity));
}

.tw-ring-gray-500{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(160, 174, 192, var(--tw-ring-opacity));
}

.tw-ring-gray-600{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(113, 128, 150, var(--tw-ring-opacity));
}

.tw-ring-gray-700{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(74, 85, 104, var(--tw-ring-opacity));
}

.tw-ring-gray-800{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(45, 55, 72, var(--tw-ring-opacity));
}

.tw-ring-gray-900{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(26, 32, 44, var(--tw-ring-opacity));
}

.tw-ring-blue-100{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(235, 248, 255, var(--tw-ring-opacity));
}

.tw-ring-blue-200{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 227, 248, var(--tw-ring-opacity));
}

.tw-ring-blue-300{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(144, 205, 244, var(--tw-ring-opacity));
}

.tw-ring-blue-400{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 179, 237, var(--tw-ring-opacity));
}

.tw-ring-blue-500{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(66, 153, 225, var(--tw-ring-opacity));
}

.tw-ring-blue-600{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(49, 130, 206, var(--tw-ring-opacity));
}

.tw-ring-blue-700{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(43, 108, 176, var(--tw-ring-opacity));
}

.tw-ring-blue-800{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(44, 82, 130, var(--tw-ring-opacity));
}

.tw-ring-blue-900{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(42, 67, 101, var(--tw-ring-opacity));
}

.focus-within\:tw-ring-transparent:focus-within{
  --tw-ring-color: transparent;
}

.focus-within\:tw-ring-current:focus-within{
  --tw-ring-color: currentColor;
}

.focus-within\:tw-ring-black:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(44, 44, 44, var(--tw-ring-opacity));
}

.focus-within\:tw-ring-white:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}

.focus-within\:tw-ring-gray-100:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(247, 250, 252, var(--tw-ring-opacity));
}

.focus-within\:tw-ring-gray-200:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(237, 242, 247, var(--tw-ring-opacity));
}

.focus-within\:tw-ring-gray-300:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(226, 232, 240, var(--tw-ring-opacity));
}

.focus-within\:tw-ring-gray-400:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(203, 213, 224, var(--tw-ring-opacity));
}

.focus-within\:tw-ring-gray-500:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(160, 174, 192, var(--tw-ring-opacity));
}

.focus-within\:tw-ring-gray-600:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(113, 128, 150, var(--tw-ring-opacity));
}

.focus-within\:tw-ring-gray-700:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(74, 85, 104, var(--tw-ring-opacity));
}

.focus-within\:tw-ring-gray-800:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(45, 55, 72, var(--tw-ring-opacity));
}

.focus-within\:tw-ring-gray-900:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(26, 32, 44, var(--tw-ring-opacity));
}

.focus-within\:tw-ring-blue-100:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(235, 248, 255, var(--tw-ring-opacity));
}

.focus-within\:tw-ring-blue-200:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 227, 248, var(--tw-ring-opacity));
}

.focus-within\:tw-ring-blue-300:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(144, 205, 244, var(--tw-ring-opacity));
}

.focus-within\:tw-ring-blue-400:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 179, 237, var(--tw-ring-opacity));
}

.focus-within\:tw-ring-blue-500:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(66, 153, 225, var(--tw-ring-opacity));
}

.focus-within\:tw-ring-blue-600:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(49, 130, 206, var(--tw-ring-opacity));
}

.focus-within\:tw-ring-blue-700:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(43, 108, 176, var(--tw-ring-opacity));
}

.focus-within\:tw-ring-blue-800:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(44, 82, 130, var(--tw-ring-opacity));
}

.focus-within\:tw-ring-blue-900:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(42, 67, 101, var(--tw-ring-opacity));
}

.focus\:tw-ring-transparent:focus{
  --tw-ring-color: transparent;
}

.focus\:tw-ring-current:focus{
  --tw-ring-color: currentColor;
}

.focus\:tw-ring-black:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(44, 44, 44, var(--tw-ring-opacity));
}

.focus\:tw-ring-white:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}

.focus\:tw-ring-gray-100:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(247, 250, 252, var(--tw-ring-opacity));
}

.focus\:tw-ring-gray-200:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(237, 242, 247, var(--tw-ring-opacity));
}

.focus\:tw-ring-gray-300:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(226, 232, 240, var(--tw-ring-opacity));
}

.focus\:tw-ring-gray-400:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(203, 213, 224, var(--tw-ring-opacity));
}

.focus\:tw-ring-gray-500:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(160, 174, 192, var(--tw-ring-opacity));
}

.focus\:tw-ring-gray-600:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(113, 128, 150, var(--tw-ring-opacity));
}

.focus\:tw-ring-gray-700:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(74, 85, 104, var(--tw-ring-opacity));
}

.focus\:tw-ring-gray-800:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(45, 55, 72, var(--tw-ring-opacity));
}

.focus\:tw-ring-gray-900:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(26, 32, 44, var(--tw-ring-opacity));
}

.focus\:tw-ring-blue-100:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(235, 248, 255, var(--tw-ring-opacity));
}

.focus\:tw-ring-blue-200:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 227, 248, var(--tw-ring-opacity));
}

.focus\:tw-ring-blue-300:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(144, 205, 244, var(--tw-ring-opacity));
}

.focus\:tw-ring-blue-400:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 179, 237, var(--tw-ring-opacity));
}

.focus\:tw-ring-blue-500:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(66, 153, 225, var(--tw-ring-opacity));
}

.focus\:tw-ring-blue-600:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(49, 130, 206, var(--tw-ring-opacity));
}

.focus\:tw-ring-blue-700:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(43, 108, 176, var(--tw-ring-opacity));
}

.focus\:tw-ring-blue-800:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(44, 82, 130, var(--tw-ring-opacity));
}

.focus\:tw-ring-blue-900:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(42, 67, 101, var(--tw-ring-opacity));
}

.tw-ring-opacity-0{
  --tw-ring-opacity: 0;
}

.tw-ring-opacity-25{
  --tw-ring-opacity: 0.25;
}

.tw-ring-opacity-50{
  --tw-ring-opacity: 0.5;
}

.tw-ring-opacity-75{
  --tw-ring-opacity: 0.75;
}

.tw-ring-opacity-90{
  --tw-ring-opacity: 0.9;
}

.tw-ring-opacity-100{
  --tw-ring-opacity: 1;
}

.focus-within\:tw-ring-opacity-0:focus-within{
  --tw-ring-opacity: 0;
}

.focus-within\:tw-ring-opacity-25:focus-within{
  --tw-ring-opacity: 0.25;
}

.focus-within\:tw-ring-opacity-50:focus-within{
  --tw-ring-opacity: 0.5;
}

.focus-within\:tw-ring-opacity-75:focus-within{
  --tw-ring-opacity: 0.75;
}

.focus-within\:tw-ring-opacity-90:focus-within{
  --tw-ring-opacity: 0.9;
}

.focus-within\:tw-ring-opacity-100:focus-within{
  --tw-ring-opacity: 1;
}

.focus\:tw-ring-opacity-0:focus{
  --tw-ring-opacity: 0;
}

.focus\:tw-ring-opacity-25:focus{
  --tw-ring-opacity: 0.25;
}

.focus\:tw-ring-opacity-50:focus{
  --tw-ring-opacity: 0.5;
}

.focus\:tw-ring-opacity-75:focus{
  --tw-ring-opacity: 0.75;
}

.focus\:tw-ring-opacity-90:focus{
  --tw-ring-opacity: 0.9;
}

.focus\:tw-ring-opacity-100:focus{
  --tw-ring-opacity: 1;
}

.tw-ring-offset-0{
  --tw-ring-offset-width: 0px;
}

.tw-ring-offset-1{
  --tw-ring-offset-width: 1px;
}

.tw-ring-offset-2{
  --tw-ring-offset-width: 2px;
}

.tw-ring-offset-4{
  --tw-ring-offset-width: 4px;
}

.tw-ring-offset-8{
  --tw-ring-offset-width: 8px;
}

.focus-within\:tw-ring-offset-0:focus-within{
  --tw-ring-offset-width: 0px;
}

.focus-within\:tw-ring-offset-1:focus-within{
  --tw-ring-offset-width: 1px;
}

.focus-within\:tw-ring-offset-2:focus-within{
  --tw-ring-offset-width: 2px;
}

.focus-within\:tw-ring-offset-4:focus-within{
  --tw-ring-offset-width: 4px;
}

.focus-within\:tw-ring-offset-8:focus-within{
  --tw-ring-offset-width: 8px;
}

.focus\:tw-ring-offset-0:focus{
  --tw-ring-offset-width: 0px;
}

.focus\:tw-ring-offset-1:focus{
  --tw-ring-offset-width: 1px;
}

.focus\:tw-ring-offset-2:focus{
  --tw-ring-offset-width: 2px;
}

.focus\:tw-ring-offset-4:focus{
  --tw-ring-offset-width: 4px;
}

.focus\:tw-ring-offset-8:focus{
  --tw-ring-offset-width: 8px;
}

.tw-ring-offset-transparent{
  --tw-ring-offset-color: transparent;
}

.tw-ring-offset-current{
  --tw-ring-offset-color: currentColor;
}

.tw-ring-offset-black{
  --tw-ring-offset-color: #2c2c2c;
}

.tw-ring-offset-white{
  --tw-ring-offset-color: #fff;
}

.tw-ring-offset-gray-100{
  --tw-ring-offset-color: #f7fafc;
}

.tw-ring-offset-gray-200{
  --tw-ring-offset-color: #edf2f7;
}

.tw-ring-offset-gray-300{
  --tw-ring-offset-color: #e2e8f0;
}

.tw-ring-offset-gray-400{
  --tw-ring-offset-color: #cbd5e0;
}

.tw-ring-offset-gray-500{
  --tw-ring-offset-color: #a0aec0;
}

.tw-ring-offset-gray-600{
  --tw-ring-offset-color: #718096;
}

.tw-ring-offset-gray-700{
  --tw-ring-offset-color: #4a5568;
}

.tw-ring-offset-gray-800{
  --tw-ring-offset-color: #2d3748;
}

.tw-ring-offset-gray-900{
  --tw-ring-offset-color: #1a202c;
}

.tw-ring-offset-blue-100{
  --tw-ring-offset-color: #ebf8ff;
}

.tw-ring-offset-blue-200{
  --tw-ring-offset-color: #bee3f8;
}

.tw-ring-offset-blue-300{
  --tw-ring-offset-color: #90cdf4;
}

.tw-ring-offset-blue-400{
  --tw-ring-offset-color: #63b3ed;
}

.tw-ring-offset-blue-500{
  --tw-ring-offset-color: #4299e1;
}

.tw-ring-offset-blue-600{
  --tw-ring-offset-color: #3182ce;
}

.tw-ring-offset-blue-700{
  --tw-ring-offset-color: #2b6cb0;
}

.tw-ring-offset-blue-800{
  --tw-ring-offset-color: #2c5282;
}

.tw-ring-offset-blue-900{
  --tw-ring-offset-color: #2a4365;
}

.focus-within\:tw-ring-offset-transparent:focus-within{
  --tw-ring-offset-color: transparent;
}

.focus-within\:tw-ring-offset-current:focus-within{
  --tw-ring-offset-color: currentColor;
}

.focus-within\:tw-ring-offset-black:focus-within{
  --tw-ring-offset-color: #2c2c2c;
}

.focus-within\:tw-ring-offset-white:focus-within{
  --tw-ring-offset-color: #fff;
}

.focus-within\:tw-ring-offset-gray-100:focus-within{
  --tw-ring-offset-color: #f7fafc;
}

.focus-within\:tw-ring-offset-gray-200:focus-within{
  --tw-ring-offset-color: #edf2f7;
}

.focus-within\:tw-ring-offset-gray-300:focus-within{
  --tw-ring-offset-color: #e2e8f0;
}

.focus-within\:tw-ring-offset-gray-400:focus-within{
  --tw-ring-offset-color: #cbd5e0;
}

.focus-within\:tw-ring-offset-gray-500:focus-within{
  --tw-ring-offset-color: #a0aec0;
}

.focus-within\:tw-ring-offset-gray-600:focus-within{
  --tw-ring-offset-color: #718096;
}

.focus-within\:tw-ring-offset-gray-700:focus-within{
  --tw-ring-offset-color: #4a5568;
}

.focus-within\:tw-ring-offset-gray-800:focus-within{
  --tw-ring-offset-color: #2d3748;
}

.focus-within\:tw-ring-offset-gray-900:focus-within{
  --tw-ring-offset-color: #1a202c;
}

.focus-within\:tw-ring-offset-blue-100:focus-within{
  --tw-ring-offset-color: #ebf8ff;
}

.focus-within\:tw-ring-offset-blue-200:focus-within{
  --tw-ring-offset-color: #bee3f8;
}

.focus-within\:tw-ring-offset-blue-300:focus-within{
  --tw-ring-offset-color: #90cdf4;
}

.focus-within\:tw-ring-offset-blue-400:focus-within{
  --tw-ring-offset-color: #63b3ed;
}

.focus-within\:tw-ring-offset-blue-500:focus-within{
  --tw-ring-offset-color: #4299e1;
}

.focus-within\:tw-ring-offset-blue-600:focus-within{
  --tw-ring-offset-color: #3182ce;
}

.focus-within\:tw-ring-offset-blue-700:focus-within{
  --tw-ring-offset-color: #2b6cb0;
}

.focus-within\:tw-ring-offset-blue-800:focus-within{
  --tw-ring-offset-color: #2c5282;
}

.focus-within\:tw-ring-offset-blue-900:focus-within{
  --tw-ring-offset-color: #2a4365;
}

.focus\:tw-ring-offset-transparent:focus{
  --tw-ring-offset-color: transparent;
}

.focus\:tw-ring-offset-current:focus{
  --tw-ring-offset-color: currentColor;
}

.focus\:tw-ring-offset-black:focus{
  --tw-ring-offset-color: #2c2c2c;
}

.focus\:tw-ring-offset-white:focus{
  --tw-ring-offset-color: #fff;
}

.focus\:tw-ring-offset-gray-100:focus{
  --tw-ring-offset-color: #f7fafc;
}

.focus\:tw-ring-offset-gray-200:focus{
  --tw-ring-offset-color: #edf2f7;
}

.focus\:tw-ring-offset-gray-300:focus{
  --tw-ring-offset-color: #e2e8f0;
}

.focus\:tw-ring-offset-gray-400:focus{
  --tw-ring-offset-color: #cbd5e0;
}

.focus\:tw-ring-offset-gray-500:focus{
  --tw-ring-offset-color: #a0aec0;
}

.focus\:tw-ring-offset-gray-600:focus{
  --tw-ring-offset-color: #718096;
}

.focus\:tw-ring-offset-gray-700:focus{
  --tw-ring-offset-color: #4a5568;
}

.focus\:tw-ring-offset-gray-800:focus{
  --tw-ring-offset-color: #2d3748;
}

.focus\:tw-ring-offset-gray-900:focus{
  --tw-ring-offset-color: #1a202c;
}

.focus\:tw-ring-offset-blue-100:focus{
  --tw-ring-offset-color: #ebf8ff;
}

.focus\:tw-ring-offset-blue-200:focus{
  --tw-ring-offset-color: #bee3f8;
}

.focus\:tw-ring-offset-blue-300:focus{
  --tw-ring-offset-color: #90cdf4;
}

.focus\:tw-ring-offset-blue-400:focus{
  --tw-ring-offset-color: #63b3ed;
}

.focus\:tw-ring-offset-blue-500:focus{
  --tw-ring-offset-color: #4299e1;
}

.focus\:tw-ring-offset-blue-600:focus{
  --tw-ring-offset-color: #3182ce;
}

.focus\:tw-ring-offset-blue-700:focus{
  --tw-ring-offset-color: #2b6cb0;
}

.focus\:tw-ring-offset-blue-800:focus{
  --tw-ring-offset-color: #2c5282;
}

.focus\:tw-ring-offset-blue-900:focus{
  --tw-ring-offset-color: #2a4365;
}

.tw-filter{
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.tw-filter-none{
  -webkit-filter: none;
  filter: none;
}

.tw-blur-0{
  --tw-blur: blur(0);
}

.tw-blur-none{
  --tw-blur: blur(0);
}

.tw-blur-sm{
  --tw-blur: blur(4px);
}

.tw-blur{
  --tw-blur: blur(8px);
}

.tw-blur-md{
  --tw-blur: blur(12px);
}

.tw-blur-lg{
  --tw-blur: blur(16px);
}

.tw-blur-xl{
  --tw-blur: blur(24px);
}

.tw-blur-2xl{
  --tw-blur: blur(40px);
}

.tw-blur-3xl{
  --tw-blur: blur(64px);
}

.tw-brightness-0{
  --tw-brightness: brightness(0);
}

.tw-brightness-50{
  --tw-brightness: brightness(.5);
}

.tw-brightness-75{
  --tw-brightness: brightness(.75);
}

.tw-brightness-90{
  --tw-brightness: brightness(.9);
}

.tw-brightness-95{
  --tw-brightness: brightness(.95);
}

.tw-brightness-100{
  --tw-brightness: brightness(1);
}

.tw-brightness-105{
  --tw-brightness: brightness(1.05);
}

.tw-brightness-110{
  --tw-brightness: brightness(1.1);
}

.tw-brightness-125{
  --tw-brightness: brightness(1.25);
}

.tw-brightness-150{
  --tw-brightness: brightness(1.5);
}

.tw-brightness-200{
  --tw-brightness: brightness(2);
}

.tw-contrast-0{
  --tw-contrast: contrast(0);
}

.tw-contrast-50{
  --tw-contrast: contrast(.5);
}

.tw-contrast-75{
  --tw-contrast: contrast(.75);
}

.tw-contrast-100{
  --tw-contrast: contrast(1);
}

.tw-contrast-125{
  --tw-contrast: contrast(1.25);
}

.tw-contrast-150{
  --tw-contrast: contrast(1.5);
}

.tw-contrast-200{
  --tw-contrast: contrast(2);
}

.tw-drop-shadow-sm{
  --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05));
}

.tw-drop-shadow{
  --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
}

.tw-drop-shadow-md{
  --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
}

.tw-drop-shadow-lg{
  --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
}

.tw-drop-shadow-xl{
  --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
}

.tw-drop-shadow-2xl{
  --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15));
}

.tw-drop-shadow-none{
  --tw-drop-shadow: drop-shadow(0 0 #0000);
}

.tw-grayscale-0{
  --tw-grayscale: grayscale(0);
}

.tw-grayscale{
  --tw-grayscale: grayscale(100%);
}

.tw-hue-rotate-0{
  --tw-hue-rotate: hue-rotate(0deg);
}

.tw-hue-rotate-15{
  --tw-hue-rotate: hue-rotate(15deg);
}

.tw-hue-rotate-30{
  --tw-hue-rotate: hue-rotate(30deg);
}

.tw-hue-rotate-60{
  --tw-hue-rotate: hue-rotate(60deg);
}

.tw-hue-rotate-90{
  --tw-hue-rotate: hue-rotate(90deg);
}

.tw-hue-rotate-180{
  --tw-hue-rotate: hue-rotate(180deg);
}

.tw--hue-rotate-180{
  --tw-hue-rotate: hue-rotate(-180deg);
}

.tw--hue-rotate-90{
  --tw-hue-rotate: hue-rotate(-90deg);
}

.tw--hue-rotate-60{
  --tw-hue-rotate: hue-rotate(-60deg);
}

.tw--hue-rotate-30{
  --tw-hue-rotate: hue-rotate(-30deg);
}

.tw--hue-rotate-15{
  --tw-hue-rotate: hue-rotate(-15deg);
}

.tw-invert-0{
  --tw-invert: invert(0);
}

.tw-invert{
  --tw-invert: invert(100%);
}

.tw-saturate-0{
  --tw-saturate: saturate(0);
}

.tw-saturate-50{
  --tw-saturate: saturate(.5);
}

.tw-saturate-100{
  --tw-saturate: saturate(1);
}

.tw-saturate-150{
  --tw-saturate: saturate(1.5);
}

.tw-saturate-200{
  --tw-saturate: saturate(2);
}

.tw-sepia-0{
  --tw-sepia: sepia(0);
}

.tw-sepia{
  --tw-sepia: sepia(100%);
}

.tw-backdrop-filter{
  --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.tw-backdrop-filter-none{
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
}

.tw-backdrop-blur-0{
  --tw-backdrop-blur: blur(0);
}

.tw-backdrop-blur-none{
  --tw-backdrop-blur: blur(0);
}

.tw-backdrop-blur-sm{
  --tw-backdrop-blur: blur(4px);
}

.tw-backdrop-blur{
  --tw-backdrop-blur: blur(8px);
}

.tw-backdrop-blur-md{
  --tw-backdrop-blur: blur(12px);
}

.tw-backdrop-blur-lg{
  --tw-backdrop-blur: blur(16px);
}

.tw-backdrop-blur-xl{
  --tw-backdrop-blur: blur(24px);
}

.tw-backdrop-blur-2xl{
  --tw-backdrop-blur: blur(40px);
}

.tw-backdrop-blur-3xl{
  --tw-backdrop-blur: blur(64px);
}

.tw-backdrop-brightness-0{
  --tw-backdrop-brightness: brightness(0);
}

.tw-backdrop-brightness-50{
  --tw-backdrop-brightness: brightness(.5);
}

.tw-backdrop-brightness-75{
  --tw-backdrop-brightness: brightness(.75);
}

.tw-backdrop-brightness-90{
  --tw-backdrop-brightness: brightness(.9);
}

.tw-backdrop-brightness-95{
  --tw-backdrop-brightness: brightness(.95);
}

.tw-backdrop-brightness-100{
  --tw-backdrop-brightness: brightness(1);
}

.tw-backdrop-brightness-105{
  --tw-backdrop-brightness: brightness(1.05);
}

.tw-backdrop-brightness-110{
  --tw-backdrop-brightness: brightness(1.1);
}

.tw-backdrop-brightness-125{
  --tw-backdrop-brightness: brightness(1.25);
}

.tw-backdrop-brightness-150{
  --tw-backdrop-brightness: brightness(1.5);
}

.tw-backdrop-brightness-200{
  --tw-backdrop-brightness: brightness(2);
}

.tw-backdrop-contrast-0{
  --tw-backdrop-contrast: contrast(0);
}

.tw-backdrop-contrast-50{
  --tw-backdrop-contrast: contrast(.5);
}

.tw-backdrop-contrast-75{
  --tw-backdrop-contrast: contrast(.75);
}

.tw-backdrop-contrast-100{
  --tw-backdrop-contrast: contrast(1);
}

.tw-backdrop-contrast-125{
  --tw-backdrop-contrast: contrast(1.25);
}

.tw-backdrop-contrast-150{
  --tw-backdrop-contrast: contrast(1.5);
}

.tw-backdrop-contrast-200{
  --tw-backdrop-contrast: contrast(2);
}

.tw-backdrop-grayscale-0{
  --tw-backdrop-grayscale: grayscale(0);
}

.tw-backdrop-grayscale{
  --tw-backdrop-grayscale: grayscale(100%);
}

.tw-backdrop-hue-rotate-0{
  --tw-backdrop-hue-rotate: hue-rotate(0deg);
}

.tw-backdrop-hue-rotate-15{
  --tw-backdrop-hue-rotate: hue-rotate(15deg);
}

.tw-backdrop-hue-rotate-30{
  --tw-backdrop-hue-rotate: hue-rotate(30deg);
}

.tw-backdrop-hue-rotate-60{
  --tw-backdrop-hue-rotate: hue-rotate(60deg);
}

.tw-backdrop-hue-rotate-90{
  --tw-backdrop-hue-rotate: hue-rotate(90deg);
}

.tw-backdrop-hue-rotate-180{
  --tw-backdrop-hue-rotate: hue-rotate(180deg);
}

.tw--backdrop-hue-rotate-180{
  --tw-backdrop-hue-rotate: hue-rotate(-180deg);
}

.tw--backdrop-hue-rotate-90{
  --tw-backdrop-hue-rotate: hue-rotate(-90deg);
}

.tw--backdrop-hue-rotate-60{
  --tw-backdrop-hue-rotate: hue-rotate(-60deg);
}

.tw--backdrop-hue-rotate-30{
  --tw-backdrop-hue-rotate: hue-rotate(-30deg);
}

.tw--backdrop-hue-rotate-15{
  --tw-backdrop-hue-rotate: hue-rotate(-15deg);
}

.tw-backdrop-invert-0{
  --tw-backdrop-invert: invert(0);
}

.tw-backdrop-invert{
  --tw-backdrop-invert: invert(100%);
}

.tw-backdrop-opacity-0{
  --tw-backdrop-opacity: opacity(0);
}

.tw-backdrop-opacity-25{
  --tw-backdrop-opacity: opacity(0.25);
}

.tw-backdrop-opacity-50{
  --tw-backdrop-opacity: opacity(0.5);
}

.tw-backdrop-opacity-75{
  --tw-backdrop-opacity: opacity(0.75);
}

.tw-backdrop-opacity-90{
  --tw-backdrop-opacity: opacity(0.9);
}

.tw-backdrop-opacity-100{
  --tw-backdrop-opacity: opacity(1);
}

.tw-backdrop-saturate-0{
  --tw-backdrop-saturate: saturate(0);
}

.tw-backdrop-saturate-50{
  --tw-backdrop-saturate: saturate(.5);
}

.tw-backdrop-saturate-100{
  --tw-backdrop-saturate: saturate(1);
}

.tw-backdrop-saturate-150{
  --tw-backdrop-saturate: saturate(1.5);
}

.tw-backdrop-saturate-200{
  --tw-backdrop-saturate: saturate(2);
}

.tw-backdrop-sepia-0{
  --tw-backdrop-sepia: sepia(0);
}

.tw-backdrop-sepia{
  --tw-backdrop-sepia: sepia(100%);
}

.tw-transition-none{
  transition-property: none;
}

.tw-transition-all{
  transition-property: all;
}

.tw-transition-default{
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
}

.tw-transition-colors{
  transition-property: background-color, border-color, color, fill, stroke;
}

.tw-transition-opacity{
  transition-property: opacity;
}

.tw-transition-shadow{
  transition-property: box-shadow;
}

.tw-transition-transform{
  transition-property: transform;
}

.tw-delay-75{
  transition-delay: 75ms;
}

.tw-delay-100{
  transition-delay: 100ms;
}

.tw-delay-150{
  transition-delay: 150ms;
}

.tw-delay-200{
  transition-delay: 200ms;
}

.tw-delay-300{
  transition-delay: 300ms;
}

.tw-delay-500{
  transition-delay: 500ms;
}

.tw-delay-700{
  transition-delay: 700ms;
}

.tw-delay-1000{
  transition-delay: 1000ms;
}

.tw-duration-75{
  transition-duration: 75ms;
}

.tw-duration-100{
  transition-duration: 100ms;
}

.tw-duration-150{
  transition-duration: 150ms;
}

.tw-duration-200{
  transition-duration: 200ms;
}

.tw-duration-300{
  transition-duration: 300ms;
}

.tw-duration-500{
  transition-duration: 500ms;
}

.tw-duration-700{
  transition-duration: 700ms;
}

.tw-duration-1000{
  transition-duration: 1000ms;
}

.tw-ease-linear{
  transition-timing-function: linear;
}

.tw-ease-in{
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.tw-ease-out{
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.tw-ease-in-out{
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@media (min-width: 640px){
  .sm\:tw-container{
    width: 100%;
  }
}

@media (min-width: 640px) and (min-width: 640px){
  .sm\:tw-container{
    max-width: 640px;
  }
}

@media (min-width: 640px) and (min-width: 768px){
  .sm\:tw-container{
    max-width: 768px;
  }
}

@media (min-width: 640px) and (min-width: 1024px){
  .sm\:tw-container{
    max-width: 1024px;
  }
}

@media (min-width: 640px) and (min-width: 1280px){
  .sm\:tw-container{
    max-width: 1280px;
  }
}

@media (min-width: 640px){
  .sm\:tw-sr-only{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .sm\:tw-not-sr-only{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .sm\:focus\:tw-sr-only:focus{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .sm\:focus\:tw-not-sr-only:focus{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .sm\:tw-pointer-events-none{
    pointer-events: none;
  }

  .sm\:tw-pointer-events-auto{
    pointer-events: auto;
  }

  .sm\:tw-visible{
    visibility: visible;
  }

  .sm\:tw-invisible{
    visibility: hidden;
  }

  .sm\:tw-static{
    position: static;
  }

  .sm\:tw-fixed{
    position: fixed;
  }

  .sm\:tw-absolute{
    position: absolute;
  }

  .sm\:tw-relative{
    position: relative;
  }

  .sm\:tw-sticky{
    position: -webkit-sticky;
    position: sticky;
  }

  .sm\:tw-inset-0{
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .sm\:tw-inset-auto{
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .sm\:tw-inset-x-0{
    left: 0;
    right: 0;
  }

  .sm\:tw-inset-x-auto{
    left: auto;
    right: auto;
  }

  .sm\:tw-inset-y-0{
    top: 0;
    bottom: 0;
  }

  .sm\:tw-inset-y-auto{
    top: auto;
    bottom: auto;
  }

  .sm\:tw-top-0{
    top: 0;
  }

  .sm\:tw-top-auto{
    top: auto;
  }

  .sm\:tw-right-0{
    right: 0;
  }

  .sm\:tw-right-auto{
    right: auto;
  }

  .sm\:tw-bottom-0{
    bottom: 0;
  }

  .sm\:tw-bottom-auto{
    bottom: auto;
  }

  .sm\:tw-left-0{
    left: 0;
  }

  .sm\:tw-left-auto{
    left: auto;
  }

  .sm\:tw-isolate{
    isolation: isolate;
  }

  .sm\:tw-isolation-auto{
    isolation: auto;
  }

  .sm\:tw-z-0{
    z-index: 0;
  }

  .sm\:tw-z-10{
    z-index: 10;
  }

  .sm\:tw-z-20{
    z-index: 20;
  }

  .sm\:tw-z-30{
    z-index: 30;
  }

  .sm\:tw-z-40{
    z-index: 40;
  }

  .sm\:tw-z-50{
    z-index: 50;
  }

  .sm\:tw-z-auto{
    z-index: auto;
  }

  .sm\:tw-order-1{
    order: 1;
  }

  .sm\:tw-order-2{
    order: 2;
  }

  .sm\:tw-order-3{
    order: 3;
  }

  .sm\:tw-order-4{
    order: 4;
  }

  .sm\:tw-order-5{
    order: 5;
  }

  .sm\:tw-order-6{
    order: 6;
  }

  .sm\:tw-order-7{
    order: 7;
  }

  .sm\:tw-order-8{
    order: 8;
  }

  .sm\:tw-order-9{
    order: 9;
  }

  .sm\:tw-order-10{
    order: 10;
  }

  .sm\:tw-order-11{
    order: 11;
  }

  .sm\:tw-order-12{
    order: 12;
  }

  .sm\:tw-order-first{
    order: -9999;
  }

  .sm\:tw-order-last{
    order: 9999;
  }

  .sm\:tw-order-none{
    order: 0;
  }

  .sm\:tw-col-auto{
    grid-column: auto;
  }

  .sm\:tw-col-span-1{
    grid-column: span 1 / span 1;
  }

  .sm\:tw-col-span-2{
    grid-column: span 2 / span 2;
  }

  .sm\:tw-col-span-3{
    grid-column: span 3 / span 3;
  }

  .sm\:tw-col-span-4{
    grid-column: span 4 / span 4;
  }

  .sm\:tw-col-span-5{
    grid-column: span 5 / span 5;
  }

  .sm\:tw-col-span-6{
    grid-column: span 6 / span 6;
  }

  .sm\:tw-col-span-7{
    grid-column: span 7 / span 7;
  }

  .sm\:tw-col-span-8{
    grid-column: span 8 / span 8;
  }

  .sm\:tw-col-span-9{
    grid-column: span 9 / span 9;
  }

  .sm\:tw-col-span-10{
    grid-column: span 10 / span 10;
  }

  .sm\:tw-col-span-11{
    grid-column: span 11 / span 11;
  }

  .sm\:tw-col-span-12{
    grid-column: span 12 / span 12;
  }

  .sm\:tw-col-span-full{
    grid-column: 1 / -1;
  }

  .sm\:tw-col-start-1{
    grid-column-start: 1;
  }

  .sm\:tw-col-start-2{
    grid-column-start: 2;
  }

  .sm\:tw-col-start-3{
    grid-column-start: 3;
  }

  .sm\:tw-col-start-4{
    grid-column-start: 4;
  }

  .sm\:tw-col-start-5{
    grid-column-start: 5;
  }

  .sm\:tw-col-start-6{
    grid-column-start: 6;
  }

  .sm\:tw-col-start-7{
    grid-column-start: 7;
  }

  .sm\:tw-col-start-8{
    grid-column-start: 8;
  }

  .sm\:tw-col-start-9{
    grid-column-start: 9;
  }

  .sm\:tw-col-start-10{
    grid-column-start: 10;
  }

  .sm\:tw-col-start-11{
    grid-column-start: 11;
  }

  .sm\:tw-col-start-12{
    grid-column-start: 12;
  }

  .sm\:tw-col-start-13{
    grid-column-start: 13;
  }

  .sm\:tw-col-start-auto{
    grid-column-start: auto;
  }

  .sm\:tw-col-end-1{
    grid-column-end: 1;
  }

  .sm\:tw-col-end-2{
    grid-column-end: 2;
  }

  .sm\:tw-col-end-3{
    grid-column-end: 3;
  }

  .sm\:tw-col-end-4{
    grid-column-end: 4;
  }

  .sm\:tw-col-end-5{
    grid-column-end: 5;
  }

  .sm\:tw-col-end-6{
    grid-column-end: 6;
  }

  .sm\:tw-col-end-7{
    grid-column-end: 7;
  }

  .sm\:tw-col-end-8{
    grid-column-end: 8;
  }

  .sm\:tw-col-end-9{
    grid-column-end: 9;
  }

  .sm\:tw-col-end-10{
    grid-column-end: 10;
  }

  .sm\:tw-col-end-11{
    grid-column-end: 11;
  }

  .sm\:tw-col-end-12{
    grid-column-end: 12;
  }

  .sm\:tw-col-end-13{
    grid-column-end: 13;
  }

  .sm\:tw-col-end-auto{
    grid-column-end: auto;
  }

  .sm\:tw-row-auto{
    grid-row: auto;
  }

  .sm\:tw-row-span-1{
    grid-row: span 1 / span 1;
  }

  .sm\:tw-row-span-2{
    grid-row: span 2 / span 2;
  }

  .sm\:tw-row-span-3{
    grid-row: span 3 / span 3;
  }

  .sm\:tw-row-span-4{
    grid-row: span 4 / span 4;
  }

  .sm\:tw-row-span-5{
    grid-row: span 5 / span 5;
  }

  .sm\:tw-row-span-6{
    grid-row: span 6 / span 6;
  }

  .sm\:tw-row-span-full{
    grid-row: 1 / -1;
  }

  .sm\:tw-row-start-1{
    grid-row-start: 1;
  }

  .sm\:tw-row-start-2{
    grid-row-start: 2;
  }

  .sm\:tw-row-start-3{
    grid-row-start: 3;
  }

  .sm\:tw-row-start-4{
    grid-row-start: 4;
  }

  .sm\:tw-row-start-5{
    grid-row-start: 5;
  }

  .sm\:tw-row-start-6{
    grid-row-start: 6;
  }

  .sm\:tw-row-start-7{
    grid-row-start: 7;
  }

  .sm\:tw-row-start-auto{
    grid-row-start: auto;
  }

  .sm\:tw-row-end-1{
    grid-row-end: 1;
  }

  .sm\:tw-row-end-2{
    grid-row-end: 2;
  }

  .sm\:tw-row-end-3{
    grid-row-end: 3;
  }

  .sm\:tw-row-end-4{
    grid-row-end: 4;
  }

  .sm\:tw-row-end-5{
    grid-row-end: 5;
  }

  .sm\:tw-row-end-6{
    grid-row-end: 6;
  }

  .sm\:tw-row-end-7{
    grid-row-end: 7;
  }

  .sm\:tw-row-end-auto{
    grid-row-end: auto;
  }

  .sm\:tw-float-right{
    float: right;
  }

  .sm\:tw-float-left{
    float: left;
  }

  .sm\:tw-float-none{
    float: none;
  }

  .sm\:tw-clear-left{
    clear: left;
  }

  .sm\:tw-clear-right{
    clear: right;
  }

  .sm\:tw-clear-both{
    clear: both;
  }

  .sm\:tw-clear-none{
    clear: none;
  }

  .sm\:tw-m-0{
    margin: 0;
  }

  .sm\:tw-m-1{
    margin: 0.25rem;
  }

  .sm\:tw-m-2{
    margin: 0.5rem;
  }

  .sm\:tw-m-3{
    margin: 0.75rem;
  }

  .sm\:tw-m-4{
    margin: 1rem;
  }

  .sm\:tw-m-5{
    margin: 1.25rem;
  }

  .sm\:tw-m-6{
    margin: 1.5rem;
  }

  .sm\:tw-m-8{
    margin: 2rem;
  }

  .sm\:tw-m-10{
    margin: 2.5rem;
  }

  .sm\:tw-m-12{
    margin: 3rem;
  }

  .sm\:tw-m-16{
    margin: 4rem;
  }

  .sm\:tw-m-20{
    margin: 5rem;
  }

  .sm\:tw-m-24{
    margin: 6rem;
  }

  .sm\:tw-m-32{
    margin: 8rem;
  }

  .sm\:tw-m-40{
    margin: 10rem;
  }

  .sm\:tw-m-48{
    margin: 12rem;
  }

  .sm\:tw-m-56{
    margin: 14rem;
  }

  .sm\:tw-m-64{
    margin: 16rem;
  }

  .sm\:tw-m-auto{
    margin: auto;
  }

  .sm\:tw-m-px{
    margin: 1px;
  }

  .sm\:tw--m-1{
    margin: -0.25rem;
  }

  .sm\:tw--m-2{
    margin: -0.5rem;
  }

  .sm\:tw--m-3{
    margin: -0.75rem;
  }

  .sm\:tw--m-4{
    margin: -1rem;
  }

  .sm\:tw--m-5{
    margin: -1.25rem;
  }

  .sm\:tw--m-6{
    margin: -1.5rem;
  }

  .sm\:tw--m-8{
    margin: -2rem;
  }

  .sm\:tw--m-10{
    margin: -2.5rem;
  }

  .sm\:tw--m-12{
    margin: -3rem;
  }

  .sm\:tw--m-16{
    margin: -4rem;
  }

  .sm\:tw--m-20{
    margin: -5rem;
  }

  .sm\:tw--m-24{
    margin: -6rem;
  }

  .sm\:tw--m-32{
    margin: -8rem;
  }

  .sm\:tw--m-40{
    margin: -10rem;
  }

  .sm\:tw--m-48{
    margin: -12rem;
  }

  .sm\:tw--m-56{
    margin: -14rem;
  }

  .sm\:tw--m-64{
    margin: -16rem;
  }

  .sm\:tw--m-px{
    margin: -1px;
  }

  .sm\:tw-mx-0{
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:tw-mx-1{
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .sm\:tw-mx-2{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .sm\:tw-mx-3{
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .sm\:tw-mx-4{
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .sm\:tw-mx-5{
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .sm\:tw-mx-6{
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .sm\:tw-mx-8{
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .sm\:tw-mx-10{
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .sm\:tw-mx-12{
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .sm\:tw-mx-16{
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .sm\:tw-mx-20{
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .sm\:tw-mx-24{
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .sm\:tw-mx-32{
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .sm\:tw-mx-40{
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .sm\:tw-mx-48{
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .sm\:tw-mx-56{
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .sm\:tw-mx-64{
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .sm\:tw-mx-auto{
    margin-left: auto;
    margin-right: auto;
  }

  .sm\:tw-mx-px{
    margin-left: 1px;
    margin-right: 1px;
  }

  .sm\:tw--mx-1{
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  .sm\:tw--mx-2{
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .sm\:tw--mx-3{
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  .sm\:tw--mx-4{
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .sm\:tw--mx-5{
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .sm\:tw--mx-6{
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .sm\:tw--mx-8{
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .sm\:tw--mx-10{
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .sm\:tw--mx-12{
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .sm\:tw--mx-16{
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .sm\:tw--mx-20{
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .sm\:tw--mx-24{
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .sm\:tw--mx-32{
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .sm\:tw--mx-40{
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .sm\:tw--mx-48{
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .sm\:tw--mx-56{
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .sm\:tw--mx-64{
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .sm\:tw--mx-px{
    margin-left: -1px;
    margin-right: -1px;
  }

  .sm\:tw-my-0{
    margin-top: 0;
    margin-bottom: 0;
  }

  .sm\:tw-my-1{
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .sm\:tw-my-2{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .sm\:tw-my-3{
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .sm\:tw-my-4{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .sm\:tw-my-5{
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .sm\:tw-my-6{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .sm\:tw-my-8{
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm\:tw-my-10{
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .sm\:tw-my-12{
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .sm\:tw-my-16{
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .sm\:tw-my-20{
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .sm\:tw-my-24{
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .sm\:tw-my-32{
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .sm\:tw-my-40{
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .sm\:tw-my-48{
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .sm\:tw-my-56{
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .sm\:tw-my-64{
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .sm\:tw-my-auto{
    margin-top: auto;
    margin-bottom: auto;
  }

  .sm\:tw-my-px{
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .sm\:tw--my-1{
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }

  .sm\:tw--my-2{
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .sm\:tw--my-3{
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }

  .sm\:tw--my-4{
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .sm\:tw--my-5{
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .sm\:tw--my-6{
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .sm\:tw--my-8{
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .sm\:tw--my-10{
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .sm\:tw--my-12{
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .sm\:tw--my-16{
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .sm\:tw--my-20{
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .sm\:tw--my-24{
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .sm\:tw--my-32{
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .sm\:tw--my-40{
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .sm\:tw--my-48{
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .sm\:tw--my-56{
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .sm\:tw--my-64{
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .sm\:tw--my-px{
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .sm\:tw-mt-0{
    margin-top: 0;
  }

  .sm\:tw-mt-1{
    margin-top: 0.25rem;
  }

  .sm\:tw-mt-2{
    margin-top: 0.5rem;
  }

  .sm\:tw-mt-3{
    margin-top: 0.75rem;
  }

  .sm\:tw-mt-4{
    margin-top: 1rem;
  }

  .sm\:tw-mt-5{
    margin-top: 1.25rem;
  }

  .sm\:tw-mt-6{
    margin-top: 1.5rem;
  }

  .sm\:tw-mt-8{
    margin-top: 2rem;
  }

  .sm\:tw-mt-10{
    margin-top: 2.5rem;
  }

  .sm\:tw-mt-12{
    margin-top: 3rem;
  }

  .sm\:tw-mt-16{
    margin-top: 4rem;
  }

  .sm\:tw-mt-20{
    margin-top: 5rem;
  }

  .sm\:tw-mt-24{
    margin-top: 6rem;
  }

  .sm\:tw-mt-32{
    margin-top: 8rem;
  }

  .sm\:tw-mt-40{
    margin-top: 10rem;
  }

  .sm\:tw-mt-48{
    margin-top: 12rem;
  }

  .sm\:tw-mt-56{
    margin-top: 14rem;
  }

  .sm\:tw-mt-64{
    margin-top: 16rem;
  }

  .sm\:tw-mt-auto{
    margin-top: auto;
  }

  .sm\:tw-mt-px{
    margin-top: 1px;
  }

  .sm\:tw--mt-1{
    margin-top: -0.25rem;
  }

  .sm\:tw--mt-2{
    margin-top: -0.5rem;
  }

  .sm\:tw--mt-3{
    margin-top: -0.75rem;
  }

  .sm\:tw--mt-4{
    margin-top: -1rem;
  }

  .sm\:tw--mt-5{
    margin-top: -1.25rem;
  }

  .sm\:tw--mt-6{
    margin-top: -1.5rem;
  }

  .sm\:tw--mt-8{
    margin-top: -2rem;
  }

  .sm\:tw--mt-10{
    margin-top: -2.5rem;
  }

  .sm\:tw--mt-12{
    margin-top: -3rem;
  }

  .sm\:tw--mt-16{
    margin-top: -4rem;
  }

  .sm\:tw--mt-20{
    margin-top: -5rem;
  }

  .sm\:tw--mt-24{
    margin-top: -6rem;
  }

  .sm\:tw--mt-32{
    margin-top: -8rem;
  }

  .sm\:tw--mt-40{
    margin-top: -10rem;
  }

  .sm\:tw--mt-48{
    margin-top: -12rem;
  }

  .sm\:tw--mt-56{
    margin-top: -14rem;
  }

  .sm\:tw--mt-64{
    margin-top: -16rem;
  }

  .sm\:tw--mt-px{
    margin-top: -1px;
  }

  .sm\:tw-mr-0{
    margin-right: 0;
  }

  .sm\:tw-mr-1{
    margin-right: 0.25rem;
  }

  .sm\:tw-mr-2{
    margin-right: 0.5rem;
  }

  .sm\:tw-mr-3{
    margin-right: 0.75rem;
  }

  .sm\:tw-mr-4{
    margin-right: 1rem;
  }

  .sm\:tw-mr-5{
    margin-right: 1.25rem;
  }

  .sm\:tw-mr-6{
    margin-right: 1.5rem;
  }

  .sm\:tw-mr-8{
    margin-right: 2rem;
  }

  .sm\:tw-mr-10{
    margin-right: 2.5rem;
  }

  .sm\:tw-mr-12{
    margin-right: 3rem;
  }

  .sm\:tw-mr-16{
    margin-right: 4rem;
  }

  .sm\:tw-mr-20{
    margin-right: 5rem;
  }

  .sm\:tw-mr-24{
    margin-right: 6rem;
  }

  .sm\:tw-mr-32{
    margin-right: 8rem;
  }

  .sm\:tw-mr-40{
    margin-right: 10rem;
  }

  .sm\:tw-mr-48{
    margin-right: 12rem;
  }

  .sm\:tw-mr-56{
    margin-right: 14rem;
  }

  .sm\:tw-mr-64{
    margin-right: 16rem;
  }

  .sm\:tw-mr-auto{
    margin-right: auto;
  }

  .sm\:tw-mr-px{
    margin-right: 1px;
  }

  .sm\:tw--mr-1{
    margin-right: -0.25rem;
  }

  .sm\:tw--mr-2{
    margin-right: -0.5rem;
  }

  .sm\:tw--mr-3{
    margin-right: -0.75rem;
  }

  .sm\:tw--mr-4{
    margin-right: -1rem;
  }

  .sm\:tw--mr-5{
    margin-right: -1.25rem;
  }

  .sm\:tw--mr-6{
    margin-right: -1.5rem;
  }

  .sm\:tw--mr-8{
    margin-right: -2rem;
  }

  .sm\:tw--mr-10{
    margin-right: -2.5rem;
  }

  .sm\:tw--mr-12{
    margin-right: -3rem;
  }

  .sm\:tw--mr-16{
    margin-right: -4rem;
  }

  .sm\:tw--mr-20{
    margin-right: -5rem;
  }

  .sm\:tw--mr-24{
    margin-right: -6rem;
  }

  .sm\:tw--mr-32{
    margin-right: -8rem;
  }

  .sm\:tw--mr-40{
    margin-right: -10rem;
  }

  .sm\:tw--mr-48{
    margin-right: -12rem;
  }

  .sm\:tw--mr-56{
    margin-right: -14rem;
  }

  .sm\:tw--mr-64{
    margin-right: -16rem;
  }

  .sm\:tw--mr-px{
    margin-right: -1px;
  }

  .sm\:tw-mb-0{
    margin-bottom: 0;
  }

  .sm\:tw-mb-1{
    margin-bottom: 0.25rem;
  }

  .sm\:tw-mb-2{
    margin-bottom: 0.5rem;
  }

  .sm\:tw-mb-3{
    margin-bottom: 0.75rem;
  }

  .sm\:tw-mb-4{
    margin-bottom: 1rem;
  }

  .sm\:tw-mb-5{
    margin-bottom: 1.25rem;
  }

  .sm\:tw-mb-6{
    margin-bottom: 1.5rem;
  }

  .sm\:tw-mb-8{
    margin-bottom: 2rem;
  }

  .sm\:tw-mb-10{
    margin-bottom: 2.5rem;
  }

  .sm\:tw-mb-12{
    margin-bottom: 3rem;
  }

  .sm\:tw-mb-16{
    margin-bottom: 4rem;
  }

  .sm\:tw-mb-20{
    margin-bottom: 5rem;
  }

  .sm\:tw-mb-24{
    margin-bottom: 6rem;
  }

  .sm\:tw-mb-32{
    margin-bottom: 8rem;
  }

  .sm\:tw-mb-40{
    margin-bottom: 10rem;
  }

  .sm\:tw-mb-48{
    margin-bottom: 12rem;
  }

  .sm\:tw-mb-56{
    margin-bottom: 14rem;
  }

  .sm\:tw-mb-64{
    margin-bottom: 16rem;
  }

  .sm\:tw-mb-auto{
    margin-bottom: auto;
  }

  .sm\:tw-mb-px{
    margin-bottom: 1px;
  }

  .sm\:tw--mb-1{
    margin-bottom: -0.25rem;
  }

  .sm\:tw--mb-2{
    margin-bottom: -0.5rem;
  }

  .sm\:tw--mb-3{
    margin-bottom: -0.75rem;
  }

  .sm\:tw--mb-4{
    margin-bottom: -1rem;
  }

  .sm\:tw--mb-5{
    margin-bottom: -1.25rem;
  }

  .sm\:tw--mb-6{
    margin-bottom: -1.5rem;
  }

  .sm\:tw--mb-8{
    margin-bottom: -2rem;
  }

  .sm\:tw--mb-10{
    margin-bottom: -2.5rem;
  }

  .sm\:tw--mb-12{
    margin-bottom: -3rem;
  }

  .sm\:tw--mb-16{
    margin-bottom: -4rem;
  }

  .sm\:tw--mb-20{
    margin-bottom: -5rem;
  }

  .sm\:tw--mb-24{
    margin-bottom: -6rem;
  }

  .sm\:tw--mb-32{
    margin-bottom: -8rem;
  }

  .sm\:tw--mb-40{
    margin-bottom: -10rem;
  }

  .sm\:tw--mb-48{
    margin-bottom: -12rem;
  }

  .sm\:tw--mb-56{
    margin-bottom: -14rem;
  }

  .sm\:tw--mb-64{
    margin-bottom: -16rem;
  }

  .sm\:tw--mb-px{
    margin-bottom: -1px;
  }

  .sm\:tw-ml-0{
    margin-left: 0;
  }

  .sm\:tw-ml-1{
    margin-left: 0.25rem;
  }

  .sm\:tw-ml-2{
    margin-left: 0.5rem;
  }

  .sm\:tw-ml-3{
    margin-left: 0.75rem;
  }

  .sm\:tw-ml-4{
    margin-left: 1rem;
  }

  .sm\:tw-ml-5{
    margin-left: 1.25rem;
  }

  .sm\:tw-ml-6{
    margin-left: 1.5rem;
  }

  .sm\:tw-ml-8{
    margin-left: 2rem;
  }

  .sm\:tw-ml-10{
    margin-left: 2.5rem;
  }

  .sm\:tw-ml-12{
    margin-left: 3rem;
  }

  .sm\:tw-ml-16{
    margin-left: 4rem;
  }

  .sm\:tw-ml-20{
    margin-left: 5rem;
  }

  .sm\:tw-ml-24{
    margin-left: 6rem;
  }

  .sm\:tw-ml-32{
    margin-left: 8rem;
  }

  .sm\:tw-ml-40{
    margin-left: 10rem;
  }

  .sm\:tw-ml-48{
    margin-left: 12rem;
  }

  .sm\:tw-ml-56{
    margin-left: 14rem;
  }

  .sm\:tw-ml-64{
    margin-left: 16rem;
  }

  .sm\:tw-ml-auto{
    margin-left: auto;
  }

  .sm\:tw-ml-px{
    margin-left: 1px;
  }

  .sm\:tw--ml-1{
    margin-left: -0.25rem;
  }

  .sm\:tw--ml-2{
    margin-left: -0.5rem;
  }

  .sm\:tw--ml-3{
    margin-left: -0.75rem;
  }

  .sm\:tw--ml-4{
    margin-left: -1rem;
  }

  .sm\:tw--ml-5{
    margin-left: -1.25rem;
  }

  .sm\:tw--ml-6{
    margin-left: -1.5rem;
  }

  .sm\:tw--ml-8{
    margin-left: -2rem;
  }

  .sm\:tw--ml-10{
    margin-left: -2.5rem;
  }

  .sm\:tw--ml-12{
    margin-left: -3rem;
  }

  .sm\:tw--ml-16{
    margin-left: -4rem;
  }

  .sm\:tw--ml-20{
    margin-left: -5rem;
  }

  .sm\:tw--ml-24{
    margin-left: -6rem;
  }

  .sm\:tw--ml-32{
    margin-left: -8rem;
  }

  .sm\:tw--ml-40{
    margin-left: -10rem;
  }

  .sm\:tw--ml-48{
    margin-left: -12rem;
  }

  .sm\:tw--ml-56{
    margin-left: -14rem;
  }

  .sm\:tw--ml-64{
    margin-left: -16rem;
  }

  .sm\:tw--ml-px{
    margin-left: -1px;
  }

  .sm\:tw-box-border{
    box-sizing: border-box;
  }

  .sm\:tw-box-content{
    box-sizing: content-box;
  }

  .sm\:tw-block{
    display: block;
  }

  .sm\:tw-inline-block{
    display: inline-block;
  }

  .sm\:tw-inline{
    display: inline;
  }

  .sm\:tw-flex{
    display: flex;
  }

  .sm\:tw-inline-flex{
    display: inline-flex;
  }

  .sm\:tw-table{
    display: table;
  }

  .sm\:tw-inline-table{
    display: inline-table;
  }

  .sm\:tw-table-caption{
    display: table-caption;
  }

  .sm\:tw-table-cell{
    display: table-cell;
  }

  .sm\:tw-table-column{
    display: table-column;
  }

  .sm\:tw-table-column-group{
    display: table-column-group;
  }

  .sm\:tw-table-footer-group{
    display: table-footer-group;
  }

  .sm\:tw-table-header-group{
    display: table-header-group;
  }

  .sm\:tw-table-row-group{
    display: table-row-group;
  }

  .sm\:tw-table-row{
    display: table-row;
  }

  .sm\:tw-flow-root{
    display: flow-root;
  }

  .sm\:tw-grid{
    display: grid;
  }

  .sm\:tw-inline-grid{
    display: inline-grid;
  }

  .sm\:tw-contents{
    display: contents;
  }

  .sm\:tw-list-item{
    display: list-item;
  }

  .sm\:tw-hidden{
    display: none;
  }

  .sm\:tw-h-0{
    height: 0;
  }

  .sm\:tw-h-1{
    height: 0.25rem;
  }

  .sm\:tw-h-2{
    height: 0.5rem;
  }

  .sm\:tw-h-3{
    height: 0.75rem;
  }

  .sm\:tw-h-4{
    height: 1rem;
  }

  .sm\:tw-h-5{
    height: 1.25rem;
  }

  .sm\:tw-h-6{
    height: 1.5rem;
  }

  .sm\:tw-h-8{
    height: 2rem;
  }

  .sm\:tw-h-10{
    height: 2.5rem;
  }

  .sm\:tw-h-12{
    height: 3rem;
  }

  .sm\:tw-h-16{
    height: 4rem;
  }

  .sm\:tw-h-20{
    height: 5rem;
  }

  .sm\:tw-h-24{
    height: 6rem;
  }

  .sm\:tw-h-32{
    height: 8rem;
  }

  .sm\:tw-h-40{
    height: 10rem;
  }

  .sm\:tw-h-48{
    height: 12rem;
  }

  .sm\:tw-h-56{
    height: 14rem;
  }

  .sm\:tw-h-64{
    height: 16rem;
  }

  .sm\:tw-h-auto{
    height: auto;
  }

  .sm\:tw-h-px{
    height: 1px;
  }

  .sm\:tw-h-1\/2{
    height: 50%;
  }

  .sm\:tw-h-1\/3{
    height: 33.333333%;
  }

  .sm\:tw-h-2\/3{
    height: 66.666667%;
  }

  .sm\:tw-h-1\/4{
    height: 25%;
  }

  .sm\:tw-h-2\/4{
    height: 50%;
  }

  .sm\:tw-h-3\/4{
    height: 75%;
  }

  .sm\:tw-h-1\/5{
    height: 20%;
  }

  .sm\:tw-h-2\/5{
    height: 40%;
  }

  .sm\:tw-h-3\/5{
    height: 60%;
  }

  .sm\:tw-h-4\/5{
    height: 80%;
  }

  .sm\:tw-h-1\/6{
    height: 16.666667%;
  }

  .sm\:tw-h-2\/6{
    height: 33.333333%;
  }

  .sm\:tw-h-3\/6{
    height: 50%;
  }

  .sm\:tw-h-4\/6{
    height: 66.666667%;
  }

  .sm\:tw-h-5\/6{
    height: 83.333333%;
  }

  .sm\:tw-h-full{
    height: 100%;
  }

  .sm\:tw-h-screen{
    height: 100vh;
  }

  .sm\:tw-max-h-full{
    max-height: 100%;
  }

  .sm\:tw-max-h-screen{
    max-height: 100vh;
  }

  .sm\:tw-min-h-0{
    min-height: 0;
  }

  .sm\:tw-min-h-full{
    min-height: 100%;
  }

  .sm\:tw-min-h-screen{
    min-height: 100vh;
  }

  .sm\:tw-w-0{
    width: 0;
  }

  .sm\:tw-w-1{
    width: 0.25rem;
  }

  .sm\:tw-w-2{
    width: 0.5rem;
  }

  .sm\:tw-w-3{
    width: 0.75rem;
  }

  .sm\:tw-w-4{
    width: 1rem;
  }

  .sm\:tw-w-5{
    width: 1.25rem;
  }

  .sm\:tw-w-6{
    width: 1.5rem;
  }

  .sm\:tw-w-8{
    width: 2rem;
  }

  .sm\:tw-w-10{
    width: 2.5rem;
  }

  .sm\:tw-w-12{
    width: 3rem;
  }

  .sm\:tw-w-16{
    width: 4rem;
  }

  .sm\:tw-w-20{
    width: 5rem;
  }

  .sm\:tw-w-24{
    width: 6rem;
  }

  .sm\:tw-w-32{
    width: 8rem;
  }

  .sm\:tw-w-40{
    width: 10rem;
  }

  .sm\:tw-w-48{
    width: 12rem;
  }

  .sm\:tw-w-56{
    width: 14rem;
  }

  .sm\:tw-w-64{
    width: 16rem;
  }

  .sm\:tw-w-auto{
    width: auto;
  }

  .sm\:tw-w-px{
    width: 1px;
  }

  .sm\:tw-w-1\/2{
    width: 50%;
  }

  .sm\:tw-w-1\/3{
    width: 33.333333%;
  }

  .sm\:tw-w-2\/3{
    width: 66.666667%;
  }

  .sm\:tw-w-1\/4{
    width: 25%;
  }

  .sm\:tw-w-2\/4{
    width: 50%;
  }

  .sm\:tw-w-3\/4{
    width: 75%;
  }

  .sm\:tw-w-1\/5{
    width: 20%;
  }

  .sm\:tw-w-2\/5{
    width: 40%;
  }

  .sm\:tw-w-3\/5{
    width: 60%;
  }

  .sm\:tw-w-4\/5{
    width: 80%;
  }

  .sm\:tw-w-1\/6{
    width: 16.666667%;
  }

  .sm\:tw-w-2\/6{
    width: 33.333333%;
  }

  .sm\:tw-w-3\/6{
    width: 50%;
  }

  .sm\:tw-w-4\/6{
    width: 66.666667%;
  }

  .sm\:tw-w-5\/6{
    width: 83.333333%;
  }

  .sm\:tw-w-1\/12{
    width: 8.333333%;
  }

  .sm\:tw-w-2\/12{
    width: 16.666667%;
  }

  .sm\:tw-w-3\/12{
    width: 25%;
  }

  .sm\:tw-w-4\/12{
    width: 33.333333%;
  }

  .sm\:tw-w-5\/12{
    width: 41.666667%;
  }

  .sm\:tw-w-6\/12{
    width: 50%;
  }

  .sm\:tw-w-7\/12{
    width: 58.333333%;
  }

  .sm\:tw-w-8\/12{
    width: 66.666667%;
  }

  .sm\:tw-w-9\/12{
    width: 75%;
  }

  .sm\:tw-w-10\/12{
    width: 83.333333%;
  }

  .sm\:tw-w-11\/12{
    width: 91.666667%;
  }

  .sm\:tw-w-full{
    width: 100%;
  }

  .sm\:tw-w-screen{
    width: 100vw;
  }

  .sm\:tw-w-min{
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
  }

  .sm\:tw-w-max{
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }

  .sm\:tw-min-w-0{
    min-width: 0;
  }

  .sm\:tw-min-w-full{
    min-width: 100%;
  }

  .sm\:tw-max-w-0{
    max-width: 0rem;
  }

  .sm\:tw-max-w-none{
    max-width: none;
  }

  .sm\:tw-max-w-xs{
    max-width: 20rem;
  }

  .sm\:tw-max-w-sm{
    max-width: 24rem;
  }

  .sm\:tw-max-w-md{
    max-width: 28rem;
  }

  .sm\:tw-max-w-lg{
    max-width: 32rem;
  }

  .sm\:tw-max-w-xl{
    max-width: 36rem;
  }

  .sm\:tw-max-w-2xl{
    max-width: 42rem;
  }

  .sm\:tw-max-w-3xl{
    max-width: 48rem;
  }

  .sm\:tw-max-w-4xl{
    max-width: 56rem;
  }

  .sm\:tw-max-w-5xl{
    max-width: 64rem;
  }

  .sm\:tw-max-w-6xl{
    max-width: 72rem;
  }

  .sm\:tw-max-w-7xl{
    max-width: 80rem;
  }

  .sm\:tw-max-w-full{
    max-width: 100%;
  }

  .sm\:tw-max-w-min{
    max-width: -webkit-min-content;
    max-width: -moz-min-content;
    max-width: min-content;
  }

  .sm\:tw-max-w-max{
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
  }

  .sm\:tw-max-w-prose{
    max-width: 65ch;
  }

  .sm\:tw-max-w-screen-sm{
    max-width: 640px;
  }

  .sm\:tw-max-w-screen-md{
    max-width: 768px;
  }

  .sm\:tw-max-w-screen-lg{
    max-width: 1024px;
  }

  .sm\:tw-max-w-screen-xl{
    max-width: 1280px;
  }

  .sm\:tw-flex-1{
    flex: 1 1 0%;
  }

  .sm\:tw-flex-auto{
    flex: 1 1 auto;
  }

  .sm\:tw-flex-initial{
    flex: 0 1 auto;
  }

  .sm\:tw-flex-none{
    flex: none;
  }

  .sm\:tw-flex-shrink-0{
    flex-shrink: 0;
  }

  .sm\:tw-flex-shrink-default{
    flex-shrink: 1;
  }

  .sm\:tw-flex-grow-0{
    flex-grow: 0;
  }

  .sm\:tw-flex-grow-default{
    flex-grow: 1;
  }

  .sm\:tw-table-auto{
    table-layout: auto;
  }

  .sm\:tw-table-fixed{
    table-layout: fixed;
  }

  .sm\:tw-border-collapse{
    border-collapse: collapse;
  }

  .sm\:tw-border-separate{
    border-collapse: separate;
  }

  .sm\:tw-origin-center{
    transform-origin: center;
  }

  .sm\:tw-origin-top{
    transform-origin: top;
  }

  .sm\:tw-origin-top-right{
    transform-origin: top right;
  }

  .sm\:tw-origin-right{
    transform-origin: right;
  }

  .sm\:tw-origin-bottom-right{
    transform-origin: bottom right;
  }

  .sm\:tw-origin-bottom{
    transform-origin: bottom;
  }

  .sm\:tw-origin-bottom-left{
    transform-origin: bottom left;
  }

  .sm\:tw-origin-left{
    transform-origin: left;
  }

  .sm\:tw-origin-top-left{
    transform-origin: top left;
  }

  .sm\:tw-transform{
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:tw-transform-gpu{
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:tw-transform-none{
    transform: none;
  }

  .sm\:tw-translate-x-0{
    --tw-translate-x: 0;
  }

  .sm\:tw-translate-x-1{
    --tw-translate-x: 0.25rem;
  }

  .sm\:tw-translate-x-2{
    --tw-translate-x: 0.5rem;
  }

  .sm\:tw-translate-x-3{
    --tw-translate-x: 0.75rem;
  }

  .sm\:tw-translate-x-4{
    --tw-translate-x: 1rem;
  }

  .sm\:tw-translate-x-5{
    --tw-translate-x: 1.25rem;
  }

  .sm\:tw-translate-x-6{
    --tw-translate-x: 1.5rem;
  }

  .sm\:tw-translate-x-8{
    --tw-translate-x: 2rem;
  }

  .sm\:tw-translate-x-10{
    --tw-translate-x: 2.5rem;
  }

  .sm\:tw-translate-x-12{
    --tw-translate-x: 3rem;
  }

  .sm\:tw-translate-x-16{
    --tw-translate-x: 4rem;
  }

  .sm\:tw-translate-x-20{
    --tw-translate-x: 5rem;
  }

  .sm\:tw-translate-x-24{
    --tw-translate-x: 6rem;
  }

  .sm\:tw-translate-x-32{
    --tw-translate-x: 8rem;
  }

  .sm\:tw-translate-x-40{
    --tw-translate-x: 10rem;
  }

  .sm\:tw-translate-x-48{
    --tw-translate-x: 12rem;
  }

  .sm\:tw-translate-x-56{
    --tw-translate-x: 14rem;
  }

  .sm\:tw-translate-x-64{
    --tw-translate-x: 16rem;
  }

  .sm\:tw-translate-x-px{
    --tw-translate-x: 1px;
  }

  .sm\:tw--translate-x-1{
    --tw-translate-x: -0.25rem;
  }

  .sm\:tw--translate-x-2{
    --tw-translate-x: -0.5rem;
  }

  .sm\:tw--translate-x-3{
    --tw-translate-x: -0.75rem;
  }

  .sm\:tw--translate-x-4{
    --tw-translate-x: -1rem;
  }

  .sm\:tw--translate-x-5{
    --tw-translate-x: -1.25rem;
  }

  .sm\:tw--translate-x-6{
    --tw-translate-x: -1.5rem;
  }

  .sm\:tw--translate-x-8{
    --tw-translate-x: -2rem;
  }

  .sm\:tw--translate-x-10{
    --tw-translate-x: -2.5rem;
  }

  .sm\:tw--translate-x-12{
    --tw-translate-x: -3rem;
  }

  .sm\:tw--translate-x-16{
    --tw-translate-x: -4rem;
  }

  .sm\:tw--translate-x-20{
    --tw-translate-x: -5rem;
  }

  .sm\:tw--translate-x-24{
    --tw-translate-x: -6rem;
  }

  .sm\:tw--translate-x-32{
    --tw-translate-x: -8rem;
  }

  .sm\:tw--translate-x-40{
    --tw-translate-x: -10rem;
  }

  .sm\:tw--translate-x-48{
    --tw-translate-x: -12rem;
  }

  .sm\:tw--translate-x-56{
    --tw-translate-x: -14rem;
  }

  .sm\:tw--translate-x-64{
    --tw-translate-x: -16rem;
  }

  .sm\:tw--translate-x-px{
    --tw-translate-x: -1px;
  }

  .sm\:tw-translate-x-1\/2{
    --tw-translate-x: 50%;
  }

  .sm\:tw-translate-x-1\/3{
    --tw-translate-x: 33.333333%;
  }

  .sm\:tw-translate-x-2\/3{
    --tw-translate-x: 66.666667%;
  }

  .sm\:tw-translate-x-1\/4{
    --tw-translate-x: 25%;
  }

  .sm\:tw-translate-x-2\/4{
    --tw-translate-x: 50%;
  }

  .sm\:tw-translate-x-3\/4{
    --tw-translate-x: 75%;
  }

  .sm\:tw-translate-x-full{
    --tw-translate-x: 100%;
  }

  .sm\:tw--translate-x-1\/2{
    --tw-translate-x: -50%;
  }

  .sm\:tw--translate-x-1\/3{
    --tw-translate-x: -33.333333%;
  }

  .sm\:tw--translate-x-2\/3{
    --tw-translate-x: -66.666667%;
  }

  .sm\:tw--translate-x-1\/4{
    --tw-translate-x: -25%;
  }

  .sm\:tw--translate-x-2\/4{
    --tw-translate-x: -50%;
  }

  .sm\:tw--translate-x-3\/4{
    --tw-translate-x: -75%;
  }

  .sm\:tw--translate-x-full{
    --tw-translate-x: -100%;
  }

  .sm\:tw-translate-y-0{
    --tw-translate-y: 0;
  }

  .sm\:tw-translate-y-1{
    --tw-translate-y: 0.25rem;
  }

  .sm\:tw-translate-y-2{
    --tw-translate-y: 0.5rem;
  }

  .sm\:tw-translate-y-3{
    --tw-translate-y: 0.75rem;
  }

  .sm\:tw-translate-y-4{
    --tw-translate-y: 1rem;
  }

  .sm\:tw-translate-y-5{
    --tw-translate-y: 1.25rem;
  }

  .sm\:tw-translate-y-6{
    --tw-translate-y: 1.5rem;
  }

  .sm\:tw-translate-y-8{
    --tw-translate-y: 2rem;
  }

  .sm\:tw-translate-y-10{
    --tw-translate-y: 2.5rem;
  }

  .sm\:tw-translate-y-12{
    --tw-translate-y: 3rem;
  }

  .sm\:tw-translate-y-16{
    --tw-translate-y: 4rem;
  }

  .sm\:tw-translate-y-20{
    --tw-translate-y: 5rem;
  }

  .sm\:tw-translate-y-24{
    --tw-translate-y: 6rem;
  }

  .sm\:tw-translate-y-32{
    --tw-translate-y: 8rem;
  }

  .sm\:tw-translate-y-40{
    --tw-translate-y: 10rem;
  }

  .sm\:tw-translate-y-48{
    --tw-translate-y: 12rem;
  }

  .sm\:tw-translate-y-56{
    --tw-translate-y: 14rem;
  }

  .sm\:tw-translate-y-64{
    --tw-translate-y: 16rem;
  }

  .sm\:tw-translate-y-px{
    --tw-translate-y: 1px;
  }

  .sm\:tw--translate-y-1{
    --tw-translate-y: -0.25rem;
  }

  .sm\:tw--translate-y-2{
    --tw-translate-y: -0.5rem;
  }

  .sm\:tw--translate-y-3{
    --tw-translate-y: -0.75rem;
  }

  .sm\:tw--translate-y-4{
    --tw-translate-y: -1rem;
  }

  .sm\:tw--translate-y-5{
    --tw-translate-y: -1.25rem;
  }

  .sm\:tw--translate-y-6{
    --tw-translate-y: -1.5rem;
  }

  .sm\:tw--translate-y-8{
    --tw-translate-y: -2rem;
  }

  .sm\:tw--translate-y-10{
    --tw-translate-y: -2.5rem;
  }

  .sm\:tw--translate-y-12{
    --tw-translate-y: -3rem;
  }

  .sm\:tw--translate-y-16{
    --tw-translate-y: -4rem;
  }

  .sm\:tw--translate-y-20{
    --tw-translate-y: -5rem;
  }

  .sm\:tw--translate-y-24{
    --tw-translate-y: -6rem;
  }

  .sm\:tw--translate-y-32{
    --tw-translate-y: -8rem;
  }

  .sm\:tw--translate-y-40{
    --tw-translate-y: -10rem;
  }

  .sm\:tw--translate-y-48{
    --tw-translate-y: -12rem;
  }

  .sm\:tw--translate-y-56{
    --tw-translate-y: -14rem;
  }

  .sm\:tw--translate-y-64{
    --tw-translate-y: -16rem;
  }

  .sm\:tw--translate-y-px{
    --tw-translate-y: -1px;
  }

  .sm\:tw-translate-y-1\/2{
    --tw-translate-y: 50%;
  }

  .sm\:tw-translate-y-1\/3{
    --tw-translate-y: 33.333333%;
  }

  .sm\:tw-translate-y-2\/3{
    --tw-translate-y: 66.666667%;
  }

  .sm\:tw-translate-y-1\/4{
    --tw-translate-y: 25%;
  }

  .sm\:tw-translate-y-2\/4{
    --tw-translate-y: 50%;
  }

  .sm\:tw-translate-y-3\/4{
    --tw-translate-y: 75%;
  }

  .sm\:tw-translate-y-full{
    --tw-translate-y: 100%;
  }

  .sm\:tw--translate-y-1\/2{
    --tw-translate-y: -50%;
  }

  .sm\:tw--translate-y-1\/3{
    --tw-translate-y: -33.333333%;
  }

  .sm\:tw--translate-y-2\/3{
    --tw-translate-y: -66.666667%;
  }

  .sm\:tw--translate-y-1\/4{
    --tw-translate-y: -25%;
  }

  .sm\:tw--translate-y-2\/4{
    --tw-translate-y: -50%;
  }

  .sm\:tw--translate-y-3\/4{
    --tw-translate-y: -75%;
  }

  .sm\:tw--translate-y-full{
    --tw-translate-y: -100%;
  }

  .sm\:hover\:tw-translate-x-0:hover{
    --tw-translate-x: 0;
  }

  .sm\:hover\:tw-translate-x-1:hover{
    --tw-translate-x: 0.25rem;
  }

  .sm\:hover\:tw-translate-x-2:hover{
    --tw-translate-x: 0.5rem;
  }

  .sm\:hover\:tw-translate-x-3:hover{
    --tw-translate-x: 0.75rem;
  }

  .sm\:hover\:tw-translate-x-4:hover{
    --tw-translate-x: 1rem;
  }

  .sm\:hover\:tw-translate-x-5:hover{
    --tw-translate-x: 1.25rem;
  }

  .sm\:hover\:tw-translate-x-6:hover{
    --tw-translate-x: 1.5rem;
  }

  .sm\:hover\:tw-translate-x-8:hover{
    --tw-translate-x: 2rem;
  }

  .sm\:hover\:tw-translate-x-10:hover{
    --tw-translate-x: 2.5rem;
  }

  .sm\:hover\:tw-translate-x-12:hover{
    --tw-translate-x: 3rem;
  }

  .sm\:hover\:tw-translate-x-16:hover{
    --tw-translate-x: 4rem;
  }

  .sm\:hover\:tw-translate-x-20:hover{
    --tw-translate-x: 5rem;
  }

  .sm\:hover\:tw-translate-x-24:hover{
    --tw-translate-x: 6rem;
  }

  .sm\:hover\:tw-translate-x-32:hover{
    --tw-translate-x: 8rem;
  }

  .sm\:hover\:tw-translate-x-40:hover{
    --tw-translate-x: 10rem;
  }

  .sm\:hover\:tw-translate-x-48:hover{
    --tw-translate-x: 12rem;
  }

  .sm\:hover\:tw-translate-x-56:hover{
    --tw-translate-x: 14rem;
  }

  .sm\:hover\:tw-translate-x-64:hover{
    --tw-translate-x: 16rem;
  }

  .sm\:hover\:tw-translate-x-px:hover{
    --tw-translate-x: 1px;
  }

  .sm\:hover\:tw--translate-x-1:hover{
    --tw-translate-x: -0.25rem;
  }

  .sm\:hover\:tw--translate-x-2:hover{
    --tw-translate-x: -0.5rem;
  }

  .sm\:hover\:tw--translate-x-3:hover{
    --tw-translate-x: -0.75rem;
  }

  .sm\:hover\:tw--translate-x-4:hover{
    --tw-translate-x: -1rem;
  }

  .sm\:hover\:tw--translate-x-5:hover{
    --tw-translate-x: -1.25rem;
  }

  .sm\:hover\:tw--translate-x-6:hover{
    --tw-translate-x: -1.5rem;
  }

  .sm\:hover\:tw--translate-x-8:hover{
    --tw-translate-x: -2rem;
  }

  .sm\:hover\:tw--translate-x-10:hover{
    --tw-translate-x: -2.5rem;
  }

  .sm\:hover\:tw--translate-x-12:hover{
    --tw-translate-x: -3rem;
  }

  .sm\:hover\:tw--translate-x-16:hover{
    --tw-translate-x: -4rem;
  }

  .sm\:hover\:tw--translate-x-20:hover{
    --tw-translate-x: -5rem;
  }

  .sm\:hover\:tw--translate-x-24:hover{
    --tw-translate-x: -6rem;
  }

  .sm\:hover\:tw--translate-x-32:hover{
    --tw-translate-x: -8rem;
  }

  .sm\:hover\:tw--translate-x-40:hover{
    --tw-translate-x: -10rem;
  }

  .sm\:hover\:tw--translate-x-48:hover{
    --tw-translate-x: -12rem;
  }

  .sm\:hover\:tw--translate-x-56:hover{
    --tw-translate-x: -14rem;
  }

  .sm\:hover\:tw--translate-x-64:hover{
    --tw-translate-x: -16rem;
  }

  .sm\:hover\:tw--translate-x-px:hover{
    --tw-translate-x: -1px;
  }

  .sm\:hover\:tw-translate-x-1\/2:hover{
    --tw-translate-x: 50%;
  }

  .sm\:hover\:tw-translate-x-1\/3:hover{
    --tw-translate-x: 33.333333%;
  }

  .sm\:hover\:tw-translate-x-2\/3:hover{
    --tw-translate-x: 66.666667%;
  }

  .sm\:hover\:tw-translate-x-1\/4:hover{
    --tw-translate-x: 25%;
  }

  .sm\:hover\:tw-translate-x-2\/4:hover{
    --tw-translate-x: 50%;
  }

  .sm\:hover\:tw-translate-x-3\/4:hover{
    --tw-translate-x: 75%;
  }

  .sm\:hover\:tw-translate-x-full:hover{
    --tw-translate-x: 100%;
  }

  .sm\:hover\:tw--translate-x-1\/2:hover{
    --tw-translate-x: -50%;
  }

  .sm\:hover\:tw--translate-x-1\/3:hover{
    --tw-translate-x: -33.333333%;
  }

  .sm\:hover\:tw--translate-x-2\/3:hover{
    --tw-translate-x: -66.666667%;
  }

  .sm\:hover\:tw--translate-x-1\/4:hover{
    --tw-translate-x: -25%;
  }

  .sm\:hover\:tw--translate-x-2\/4:hover{
    --tw-translate-x: -50%;
  }

  .sm\:hover\:tw--translate-x-3\/4:hover{
    --tw-translate-x: -75%;
  }

  .sm\:hover\:tw--translate-x-full:hover{
    --tw-translate-x: -100%;
  }

  .sm\:hover\:tw-translate-y-0:hover{
    --tw-translate-y: 0;
  }

  .sm\:hover\:tw-translate-y-1:hover{
    --tw-translate-y: 0.25rem;
  }

  .sm\:hover\:tw-translate-y-2:hover{
    --tw-translate-y: 0.5rem;
  }

  .sm\:hover\:tw-translate-y-3:hover{
    --tw-translate-y: 0.75rem;
  }

  .sm\:hover\:tw-translate-y-4:hover{
    --tw-translate-y: 1rem;
  }

  .sm\:hover\:tw-translate-y-5:hover{
    --tw-translate-y: 1.25rem;
  }

  .sm\:hover\:tw-translate-y-6:hover{
    --tw-translate-y: 1.5rem;
  }

  .sm\:hover\:tw-translate-y-8:hover{
    --tw-translate-y: 2rem;
  }

  .sm\:hover\:tw-translate-y-10:hover{
    --tw-translate-y: 2.5rem;
  }

  .sm\:hover\:tw-translate-y-12:hover{
    --tw-translate-y: 3rem;
  }

  .sm\:hover\:tw-translate-y-16:hover{
    --tw-translate-y: 4rem;
  }

  .sm\:hover\:tw-translate-y-20:hover{
    --tw-translate-y: 5rem;
  }

  .sm\:hover\:tw-translate-y-24:hover{
    --tw-translate-y: 6rem;
  }

  .sm\:hover\:tw-translate-y-32:hover{
    --tw-translate-y: 8rem;
  }

  .sm\:hover\:tw-translate-y-40:hover{
    --tw-translate-y: 10rem;
  }

  .sm\:hover\:tw-translate-y-48:hover{
    --tw-translate-y: 12rem;
  }

  .sm\:hover\:tw-translate-y-56:hover{
    --tw-translate-y: 14rem;
  }

  .sm\:hover\:tw-translate-y-64:hover{
    --tw-translate-y: 16rem;
  }

  .sm\:hover\:tw-translate-y-px:hover{
    --tw-translate-y: 1px;
  }

  .sm\:hover\:tw--translate-y-1:hover{
    --tw-translate-y: -0.25rem;
  }

  .sm\:hover\:tw--translate-y-2:hover{
    --tw-translate-y: -0.5rem;
  }

  .sm\:hover\:tw--translate-y-3:hover{
    --tw-translate-y: -0.75rem;
  }

  .sm\:hover\:tw--translate-y-4:hover{
    --tw-translate-y: -1rem;
  }

  .sm\:hover\:tw--translate-y-5:hover{
    --tw-translate-y: -1.25rem;
  }

  .sm\:hover\:tw--translate-y-6:hover{
    --tw-translate-y: -1.5rem;
  }

  .sm\:hover\:tw--translate-y-8:hover{
    --tw-translate-y: -2rem;
  }

  .sm\:hover\:tw--translate-y-10:hover{
    --tw-translate-y: -2.5rem;
  }

  .sm\:hover\:tw--translate-y-12:hover{
    --tw-translate-y: -3rem;
  }

  .sm\:hover\:tw--translate-y-16:hover{
    --tw-translate-y: -4rem;
  }

  .sm\:hover\:tw--translate-y-20:hover{
    --tw-translate-y: -5rem;
  }

  .sm\:hover\:tw--translate-y-24:hover{
    --tw-translate-y: -6rem;
  }

  .sm\:hover\:tw--translate-y-32:hover{
    --tw-translate-y: -8rem;
  }

  .sm\:hover\:tw--translate-y-40:hover{
    --tw-translate-y: -10rem;
  }

  .sm\:hover\:tw--translate-y-48:hover{
    --tw-translate-y: -12rem;
  }

  .sm\:hover\:tw--translate-y-56:hover{
    --tw-translate-y: -14rem;
  }

  .sm\:hover\:tw--translate-y-64:hover{
    --tw-translate-y: -16rem;
  }

  .sm\:hover\:tw--translate-y-px:hover{
    --tw-translate-y: -1px;
  }

  .sm\:hover\:tw-translate-y-1\/2:hover{
    --tw-translate-y: 50%;
  }

  .sm\:hover\:tw-translate-y-1\/3:hover{
    --tw-translate-y: 33.333333%;
  }

  .sm\:hover\:tw-translate-y-2\/3:hover{
    --tw-translate-y: 66.666667%;
  }

  .sm\:hover\:tw-translate-y-1\/4:hover{
    --tw-translate-y: 25%;
  }

  .sm\:hover\:tw-translate-y-2\/4:hover{
    --tw-translate-y: 50%;
  }

  .sm\:hover\:tw-translate-y-3\/4:hover{
    --tw-translate-y: 75%;
  }

  .sm\:hover\:tw-translate-y-full:hover{
    --tw-translate-y: 100%;
  }

  .sm\:hover\:tw--translate-y-1\/2:hover{
    --tw-translate-y: -50%;
  }

  .sm\:hover\:tw--translate-y-1\/3:hover{
    --tw-translate-y: -33.333333%;
  }

  .sm\:hover\:tw--translate-y-2\/3:hover{
    --tw-translate-y: -66.666667%;
  }

  .sm\:hover\:tw--translate-y-1\/4:hover{
    --tw-translate-y: -25%;
  }

  .sm\:hover\:tw--translate-y-2\/4:hover{
    --tw-translate-y: -50%;
  }

  .sm\:hover\:tw--translate-y-3\/4:hover{
    --tw-translate-y: -75%;
  }

  .sm\:hover\:tw--translate-y-full:hover{
    --tw-translate-y: -100%;
  }

  .sm\:focus\:tw-translate-x-0:focus{
    --tw-translate-x: 0;
  }

  .sm\:focus\:tw-translate-x-1:focus{
    --tw-translate-x: 0.25rem;
  }

  .sm\:focus\:tw-translate-x-2:focus{
    --tw-translate-x: 0.5rem;
  }

  .sm\:focus\:tw-translate-x-3:focus{
    --tw-translate-x: 0.75rem;
  }

  .sm\:focus\:tw-translate-x-4:focus{
    --tw-translate-x: 1rem;
  }

  .sm\:focus\:tw-translate-x-5:focus{
    --tw-translate-x: 1.25rem;
  }

  .sm\:focus\:tw-translate-x-6:focus{
    --tw-translate-x: 1.5rem;
  }

  .sm\:focus\:tw-translate-x-8:focus{
    --tw-translate-x: 2rem;
  }

  .sm\:focus\:tw-translate-x-10:focus{
    --tw-translate-x: 2.5rem;
  }

  .sm\:focus\:tw-translate-x-12:focus{
    --tw-translate-x: 3rem;
  }

  .sm\:focus\:tw-translate-x-16:focus{
    --tw-translate-x: 4rem;
  }

  .sm\:focus\:tw-translate-x-20:focus{
    --tw-translate-x: 5rem;
  }

  .sm\:focus\:tw-translate-x-24:focus{
    --tw-translate-x: 6rem;
  }

  .sm\:focus\:tw-translate-x-32:focus{
    --tw-translate-x: 8rem;
  }

  .sm\:focus\:tw-translate-x-40:focus{
    --tw-translate-x: 10rem;
  }

  .sm\:focus\:tw-translate-x-48:focus{
    --tw-translate-x: 12rem;
  }

  .sm\:focus\:tw-translate-x-56:focus{
    --tw-translate-x: 14rem;
  }

  .sm\:focus\:tw-translate-x-64:focus{
    --tw-translate-x: 16rem;
  }

  .sm\:focus\:tw-translate-x-px:focus{
    --tw-translate-x: 1px;
  }

  .sm\:focus\:tw--translate-x-1:focus{
    --tw-translate-x: -0.25rem;
  }

  .sm\:focus\:tw--translate-x-2:focus{
    --tw-translate-x: -0.5rem;
  }

  .sm\:focus\:tw--translate-x-3:focus{
    --tw-translate-x: -0.75rem;
  }

  .sm\:focus\:tw--translate-x-4:focus{
    --tw-translate-x: -1rem;
  }

  .sm\:focus\:tw--translate-x-5:focus{
    --tw-translate-x: -1.25rem;
  }

  .sm\:focus\:tw--translate-x-6:focus{
    --tw-translate-x: -1.5rem;
  }

  .sm\:focus\:tw--translate-x-8:focus{
    --tw-translate-x: -2rem;
  }

  .sm\:focus\:tw--translate-x-10:focus{
    --tw-translate-x: -2.5rem;
  }

  .sm\:focus\:tw--translate-x-12:focus{
    --tw-translate-x: -3rem;
  }

  .sm\:focus\:tw--translate-x-16:focus{
    --tw-translate-x: -4rem;
  }

  .sm\:focus\:tw--translate-x-20:focus{
    --tw-translate-x: -5rem;
  }

  .sm\:focus\:tw--translate-x-24:focus{
    --tw-translate-x: -6rem;
  }

  .sm\:focus\:tw--translate-x-32:focus{
    --tw-translate-x: -8rem;
  }

  .sm\:focus\:tw--translate-x-40:focus{
    --tw-translate-x: -10rem;
  }

  .sm\:focus\:tw--translate-x-48:focus{
    --tw-translate-x: -12rem;
  }

  .sm\:focus\:tw--translate-x-56:focus{
    --tw-translate-x: -14rem;
  }

  .sm\:focus\:tw--translate-x-64:focus{
    --tw-translate-x: -16rem;
  }

  .sm\:focus\:tw--translate-x-px:focus{
    --tw-translate-x: -1px;
  }

  .sm\:focus\:tw-translate-x-1\/2:focus{
    --tw-translate-x: 50%;
  }

  .sm\:focus\:tw-translate-x-1\/3:focus{
    --tw-translate-x: 33.333333%;
  }

  .sm\:focus\:tw-translate-x-2\/3:focus{
    --tw-translate-x: 66.666667%;
  }

  .sm\:focus\:tw-translate-x-1\/4:focus{
    --tw-translate-x: 25%;
  }

  .sm\:focus\:tw-translate-x-2\/4:focus{
    --tw-translate-x: 50%;
  }

  .sm\:focus\:tw-translate-x-3\/4:focus{
    --tw-translate-x: 75%;
  }

  .sm\:focus\:tw-translate-x-full:focus{
    --tw-translate-x: 100%;
  }

  .sm\:focus\:tw--translate-x-1\/2:focus{
    --tw-translate-x: -50%;
  }

  .sm\:focus\:tw--translate-x-1\/3:focus{
    --tw-translate-x: -33.333333%;
  }

  .sm\:focus\:tw--translate-x-2\/3:focus{
    --tw-translate-x: -66.666667%;
  }

  .sm\:focus\:tw--translate-x-1\/4:focus{
    --tw-translate-x: -25%;
  }

  .sm\:focus\:tw--translate-x-2\/4:focus{
    --tw-translate-x: -50%;
  }

  .sm\:focus\:tw--translate-x-3\/4:focus{
    --tw-translate-x: -75%;
  }

  .sm\:focus\:tw--translate-x-full:focus{
    --tw-translate-x: -100%;
  }

  .sm\:focus\:tw-translate-y-0:focus{
    --tw-translate-y: 0;
  }

  .sm\:focus\:tw-translate-y-1:focus{
    --tw-translate-y: 0.25rem;
  }

  .sm\:focus\:tw-translate-y-2:focus{
    --tw-translate-y: 0.5rem;
  }

  .sm\:focus\:tw-translate-y-3:focus{
    --tw-translate-y: 0.75rem;
  }

  .sm\:focus\:tw-translate-y-4:focus{
    --tw-translate-y: 1rem;
  }

  .sm\:focus\:tw-translate-y-5:focus{
    --tw-translate-y: 1.25rem;
  }

  .sm\:focus\:tw-translate-y-6:focus{
    --tw-translate-y: 1.5rem;
  }

  .sm\:focus\:tw-translate-y-8:focus{
    --tw-translate-y: 2rem;
  }

  .sm\:focus\:tw-translate-y-10:focus{
    --tw-translate-y: 2.5rem;
  }

  .sm\:focus\:tw-translate-y-12:focus{
    --tw-translate-y: 3rem;
  }

  .sm\:focus\:tw-translate-y-16:focus{
    --tw-translate-y: 4rem;
  }

  .sm\:focus\:tw-translate-y-20:focus{
    --tw-translate-y: 5rem;
  }

  .sm\:focus\:tw-translate-y-24:focus{
    --tw-translate-y: 6rem;
  }

  .sm\:focus\:tw-translate-y-32:focus{
    --tw-translate-y: 8rem;
  }

  .sm\:focus\:tw-translate-y-40:focus{
    --tw-translate-y: 10rem;
  }

  .sm\:focus\:tw-translate-y-48:focus{
    --tw-translate-y: 12rem;
  }

  .sm\:focus\:tw-translate-y-56:focus{
    --tw-translate-y: 14rem;
  }

  .sm\:focus\:tw-translate-y-64:focus{
    --tw-translate-y: 16rem;
  }

  .sm\:focus\:tw-translate-y-px:focus{
    --tw-translate-y: 1px;
  }

  .sm\:focus\:tw--translate-y-1:focus{
    --tw-translate-y: -0.25rem;
  }

  .sm\:focus\:tw--translate-y-2:focus{
    --tw-translate-y: -0.5rem;
  }

  .sm\:focus\:tw--translate-y-3:focus{
    --tw-translate-y: -0.75rem;
  }

  .sm\:focus\:tw--translate-y-4:focus{
    --tw-translate-y: -1rem;
  }

  .sm\:focus\:tw--translate-y-5:focus{
    --tw-translate-y: -1.25rem;
  }

  .sm\:focus\:tw--translate-y-6:focus{
    --tw-translate-y: -1.5rem;
  }

  .sm\:focus\:tw--translate-y-8:focus{
    --tw-translate-y: -2rem;
  }

  .sm\:focus\:tw--translate-y-10:focus{
    --tw-translate-y: -2.5rem;
  }

  .sm\:focus\:tw--translate-y-12:focus{
    --tw-translate-y: -3rem;
  }

  .sm\:focus\:tw--translate-y-16:focus{
    --tw-translate-y: -4rem;
  }

  .sm\:focus\:tw--translate-y-20:focus{
    --tw-translate-y: -5rem;
  }

  .sm\:focus\:tw--translate-y-24:focus{
    --tw-translate-y: -6rem;
  }

  .sm\:focus\:tw--translate-y-32:focus{
    --tw-translate-y: -8rem;
  }

  .sm\:focus\:tw--translate-y-40:focus{
    --tw-translate-y: -10rem;
  }

  .sm\:focus\:tw--translate-y-48:focus{
    --tw-translate-y: -12rem;
  }

  .sm\:focus\:tw--translate-y-56:focus{
    --tw-translate-y: -14rem;
  }

  .sm\:focus\:tw--translate-y-64:focus{
    --tw-translate-y: -16rem;
  }

  .sm\:focus\:tw--translate-y-px:focus{
    --tw-translate-y: -1px;
  }

  .sm\:focus\:tw-translate-y-1\/2:focus{
    --tw-translate-y: 50%;
  }

  .sm\:focus\:tw-translate-y-1\/3:focus{
    --tw-translate-y: 33.333333%;
  }

  .sm\:focus\:tw-translate-y-2\/3:focus{
    --tw-translate-y: 66.666667%;
  }

  .sm\:focus\:tw-translate-y-1\/4:focus{
    --tw-translate-y: 25%;
  }

  .sm\:focus\:tw-translate-y-2\/4:focus{
    --tw-translate-y: 50%;
  }

  .sm\:focus\:tw-translate-y-3\/4:focus{
    --tw-translate-y: 75%;
  }

  .sm\:focus\:tw-translate-y-full:focus{
    --tw-translate-y: 100%;
  }

  .sm\:focus\:tw--translate-y-1\/2:focus{
    --tw-translate-y: -50%;
  }

  .sm\:focus\:tw--translate-y-1\/3:focus{
    --tw-translate-y: -33.333333%;
  }

  .sm\:focus\:tw--translate-y-2\/3:focus{
    --tw-translate-y: -66.666667%;
  }

  .sm\:focus\:tw--translate-y-1\/4:focus{
    --tw-translate-y: -25%;
  }

  .sm\:focus\:tw--translate-y-2\/4:focus{
    --tw-translate-y: -50%;
  }

  .sm\:focus\:tw--translate-y-3\/4:focus{
    --tw-translate-y: -75%;
  }

  .sm\:focus\:tw--translate-y-full:focus{
    --tw-translate-y: -100%;
  }

  .sm\:tw-rotate-0{
    --tw-rotate: 0;
  }

  .sm\:tw-rotate-1{
    --tw-rotate: 1deg;
  }

  .sm\:tw-rotate-2{
    --tw-rotate: 2deg;
  }

  .sm\:tw-rotate-3{
    --tw-rotate: 3deg;
  }

  .sm\:tw-rotate-6{
    --tw-rotate: 6deg;
  }

  .sm\:tw-rotate-12{
    --tw-rotate: 12deg;
  }

  .sm\:tw-rotate-45{
    --tw-rotate: 45deg;
  }

  .sm\:tw-rotate-90{
    --tw-rotate: 90deg;
  }

  .sm\:tw-rotate-180{
    --tw-rotate: 180deg;
  }

  .sm\:tw--rotate-180{
    --tw-rotate: -180deg;
  }

  .sm\:tw--rotate-90{
    --tw-rotate: -90deg;
  }

  .sm\:tw--rotate-45{
    --tw-rotate: -45deg;
  }

  .sm\:tw--rotate-12{
    --tw-rotate: -12deg;
  }

  .sm\:tw--rotate-6{
    --tw-rotate: -6deg;
  }

  .sm\:tw--rotate-3{
    --tw-rotate: -3deg;
  }

  .sm\:tw--rotate-2{
    --tw-rotate: -2deg;
  }

  .sm\:tw--rotate-1{
    --tw-rotate: -1deg;
  }

  .sm\:hover\:tw-rotate-0:hover{
    --tw-rotate: 0;
  }

  .sm\:hover\:tw-rotate-1:hover{
    --tw-rotate: 1deg;
  }

  .sm\:hover\:tw-rotate-2:hover{
    --tw-rotate: 2deg;
  }

  .sm\:hover\:tw-rotate-3:hover{
    --tw-rotate: 3deg;
  }

  .sm\:hover\:tw-rotate-6:hover{
    --tw-rotate: 6deg;
  }

  .sm\:hover\:tw-rotate-12:hover{
    --tw-rotate: 12deg;
  }

  .sm\:hover\:tw-rotate-45:hover{
    --tw-rotate: 45deg;
  }

  .sm\:hover\:tw-rotate-90:hover{
    --tw-rotate: 90deg;
  }

  .sm\:hover\:tw-rotate-180:hover{
    --tw-rotate: 180deg;
  }

  .sm\:hover\:tw--rotate-180:hover{
    --tw-rotate: -180deg;
  }

  .sm\:hover\:tw--rotate-90:hover{
    --tw-rotate: -90deg;
  }

  .sm\:hover\:tw--rotate-45:hover{
    --tw-rotate: -45deg;
  }

  .sm\:hover\:tw--rotate-12:hover{
    --tw-rotate: -12deg;
  }

  .sm\:hover\:tw--rotate-6:hover{
    --tw-rotate: -6deg;
  }

  .sm\:hover\:tw--rotate-3:hover{
    --tw-rotate: -3deg;
  }

  .sm\:hover\:tw--rotate-2:hover{
    --tw-rotate: -2deg;
  }

  .sm\:hover\:tw--rotate-1:hover{
    --tw-rotate: -1deg;
  }

  .sm\:focus\:tw-rotate-0:focus{
    --tw-rotate: 0;
  }

  .sm\:focus\:tw-rotate-1:focus{
    --tw-rotate: 1deg;
  }

  .sm\:focus\:tw-rotate-2:focus{
    --tw-rotate: 2deg;
  }

  .sm\:focus\:tw-rotate-3:focus{
    --tw-rotate: 3deg;
  }

  .sm\:focus\:tw-rotate-6:focus{
    --tw-rotate: 6deg;
  }

  .sm\:focus\:tw-rotate-12:focus{
    --tw-rotate: 12deg;
  }

  .sm\:focus\:tw-rotate-45:focus{
    --tw-rotate: 45deg;
  }

  .sm\:focus\:tw-rotate-90:focus{
    --tw-rotate: 90deg;
  }

  .sm\:focus\:tw-rotate-180:focus{
    --tw-rotate: 180deg;
  }

  .sm\:focus\:tw--rotate-180:focus{
    --tw-rotate: -180deg;
  }

  .sm\:focus\:tw--rotate-90:focus{
    --tw-rotate: -90deg;
  }

  .sm\:focus\:tw--rotate-45:focus{
    --tw-rotate: -45deg;
  }

  .sm\:focus\:tw--rotate-12:focus{
    --tw-rotate: -12deg;
  }

  .sm\:focus\:tw--rotate-6:focus{
    --tw-rotate: -6deg;
  }

  .sm\:focus\:tw--rotate-3:focus{
    --tw-rotate: -3deg;
  }

  .sm\:focus\:tw--rotate-2:focus{
    --tw-rotate: -2deg;
  }

  .sm\:focus\:tw--rotate-1:focus{
    --tw-rotate: -1deg;
  }

  .sm\:tw-skew-x-0{
    --tw-skew-x: 0;
  }

  .sm\:tw-skew-x-1{
    --tw-skew-x: 1deg;
  }

  .sm\:tw-skew-x-2{
    --tw-skew-x: 2deg;
  }

  .sm\:tw-skew-x-3{
    --tw-skew-x: 3deg;
  }

  .sm\:tw-skew-x-6{
    --tw-skew-x: 6deg;
  }

  .sm\:tw-skew-x-12{
    --tw-skew-x: 12deg;
  }

  .sm\:tw--skew-x-12{
    --tw-skew-x: -12deg;
  }

  .sm\:tw--skew-x-6{
    --tw-skew-x: -6deg;
  }

  .sm\:tw--skew-x-3{
    --tw-skew-x: -3deg;
  }

  .sm\:tw--skew-x-2{
    --tw-skew-x: -2deg;
  }

  .sm\:tw--skew-x-1{
    --tw-skew-x: -1deg;
  }

  .sm\:tw-skew-y-0{
    --tw-skew-y: 0;
  }

  .sm\:tw-skew-y-1{
    --tw-skew-y: 1deg;
  }

  .sm\:tw-skew-y-2{
    --tw-skew-y: 2deg;
  }

  .sm\:tw-skew-y-3{
    --tw-skew-y: 3deg;
  }

  .sm\:tw-skew-y-6{
    --tw-skew-y: 6deg;
  }

  .sm\:tw-skew-y-12{
    --tw-skew-y: 12deg;
  }

  .sm\:tw--skew-y-12{
    --tw-skew-y: -12deg;
  }

  .sm\:tw--skew-y-6{
    --tw-skew-y: -6deg;
  }

  .sm\:tw--skew-y-3{
    --tw-skew-y: -3deg;
  }

  .sm\:tw--skew-y-2{
    --tw-skew-y: -2deg;
  }

  .sm\:tw--skew-y-1{
    --tw-skew-y: -1deg;
  }

  .sm\:hover\:tw-skew-x-0:hover{
    --tw-skew-x: 0;
  }

  .sm\:hover\:tw-skew-x-1:hover{
    --tw-skew-x: 1deg;
  }

  .sm\:hover\:tw-skew-x-2:hover{
    --tw-skew-x: 2deg;
  }

  .sm\:hover\:tw-skew-x-3:hover{
    --tw-skew-x: 3deg;
  }

  .sm\:hover\:tw-skew-x-6:hover{
    --tw-skew-x: 6deg;
  }

  .sm\:hover\:tw-skew-x-12:hover{
    --tw-skew-x: 12deg;
  }

  .sm\:hover\:tw--skew-x-12:hover{
    --tw-skew-x: -12deg;
  }

  .sm\:hover\:tw--skew-x-6:hover{
    --tw-skew-x: -6deg;
  }

  .sm\:hover\:tw--skew-x-3:hover{
    --tw-skew-x: -3deg;
  }

  .sm\:hover\:tw--skew-x-2:hover{
    --tw-skew-x: -2deg;
  }

  .sm\:hover\:tw--skew-x-1:hover{
    --tw-skew-x: -1deg;
  }

  .sm\:hover\:tw-skew-y-0:hover{
    --tw-skew-y: 0;
  }

  .sm\:hover\:tw-skew-y-1:hover{
    --tw-skew-y: 1deg;
  }

  .sm\:hover\:tw-skew-y-2:hover{
    --tw-skew-y: 2deg;
  }

  .sm\:hover\:tw-skew-y-3:hover{
    --tw-skew-y: 3deg;
  }

  .sm\:hover\:tw-skew-y-6:hover{
    --tw-skew-y: 6deg;
  }

  .sm\:hover\:tw-skew-y-12:hover{
    --tw-skew-y: 12deg;
  }

  .sm\:hover\:tw--skew-y-12:hover{
    --tw-skew-y: -12deg;
  }

  .sm\:hover\:tw--skew-y-6:hover{
    --tw-skew-y: -6deg;
  }

  .sm\:hover\:tw--skew-y-3:hover{
    --tw-skew-y: -3deg;
  }

  .sm\:hover\:tw--skew-y-2:hover{
    --tw-skew-y: -2deg;
  }

  .sm\:hover\:tw--skew-y-1:hover{
    --tw-skew-y: -1deg;
  }

  .sm\:focus\:tw-skew-x-0:focus{
    --tw-skew-x: 0;
  }

  .sm\:focus\:tw-skew-x-1:focus{
    --tw-skew-x: 1deg;
  }

  .sm\:focus\:tw-skew-x-2:focus{
    --tw-skew-x: 2deg;
  }

  .sm\:focus\:tw-skew-x-3:focus{
    --tw-skew-x: 3deg;
  }

  .sm\:focus\:tw-skew-x-6:focus{
    --tw-skew-x: 6deg;
  }

  .sm\:focus\:tw-skew-x-12:focus{
    --tw-skew-x: 12deg;
  }

  .sm\:focus\:tw--skew-x-12:focus{
    --tw-skew-x: -12deg;
  }

  .sm\:focus\:tw--skew-x-6:focus{
    --tw-skew-x: -6deg;
  }

  .sm\:focus\:tw--skew-x-3:focus{
    --tw-skew-x: -3deg;
  }

  .sm\:focus\:tw--skew-x-2:focus{
    --tw-skew-x: -2deg;
  }

  .sm\:focus\:tw--skew-x-1:focus{
    --tw-skew-x: -1deg;
  }

  .sm\:focus\:tw-skew-y-0:focus{
    --tw-skew-y: 0;
  }

  .sm\:focus\:tw-skew-y-1:focus{
    --tw-skew-y: 1deg;
  }

  .sm\:focus\:tw-skew-y-2:focus{
    --tw-skew-y: 2deg;
  }

  .sm\:focus\:tw-skew-y-3:focus{
    --tw-skew-y: 3deg;
  }

  .sm\:focus\:tw-skew-y-6:focus{
    --tw-skew-y: 6deg;
  }

  .sm\:focus\:tw-skew-y-12:focus{
    --tw-skew-y: 12deg;
  }

  .sm\:focus\:tw--skew-y-12:focus{
    --tw-skew-y: -12deg;
  }

  .sm\:focus\:tw--skew-y-6:focus{
    --tw-skew-y: -6deg;
  }

  .sm\:focus\:tw--skew-y-3:focus{
    --tw-skew-y: -3deg;
  }

  .sm\:focus\:tw--skew-y-2:focus{
    --tw-skew-y: -2deg;
  }

  .sm\:focus\:tw--skew-y-1:focus{
    --tw-skew-y: -1deg;
  }

  .sm\:tw-scale-0{
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .sm\:tw-scale-50{
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .sm\:tw-scale-75{
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .sm\:tw-scale-90{
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .sm\:tw-scale-95{
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .sm\:tw-scale-100{
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .sm\:tw-scale-105{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .sm\:tw-scale-110{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .sm\:tw-scale-125{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .sm\:tw-scale-150{
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .sm\:hover\:tw-scale-0:hover{
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .sm\:hover\:tw-scale-50:hover{
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .sm\:hover\:tw-scale-75:hover{
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .sm\:hover\:tw-scale-90:hover{
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .sm\:hover\:tw-scale-95:hover{
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .sm\:hover\:tw-scale-100:hover{
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .sm\:hover\:tw-scale-105:hover{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .sm\:hover\:tw-scale-110:hover{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .sm\:hover\:tw-scale-125:hover{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .sm\:hover\:tw-scale-150:hover{
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .sm\:focus\:tw-scale-0:focus{
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .sm\:focus\:tw-scale-50:focus{
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .sm\:focus\:tw-scale-75:focus{
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .sm\:focus\:tw-scale-90:focus{
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .sm\:focus\:tw-scale-95:focus{
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .sm\:focus\:tw-scale-100:focus{
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .sm\:focus\:tw-scale-105:focus{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .sm\:focus\:tw-scale-110:focus{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .sm\:focus\:tw-scale-125:focus{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .sm\:focus\:tw-scale-150:focus{
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .sm\:tw-scale-x-0{
    --tw-scale-x: 0;
  }

  .sm\:tw-scale-x-50{
    --tw-scale-x: .5;
  }

  .sm\:tw-scale-x-75{
    --tw-scale-x: .75;
  }

  .sm\:tw-scale-x-90{
    --tw-scale-x: .9;
  }

  .sm\:tw-scale-x-95{
    --tw-scale-x: .95;
  }

  .sm\:tw-scale-x-100{
    --tw-scale-x: 1;
  }

  .sm\:tw-scale-x-105{
    --tw-scale-x: 1.05;
  }

  .sm\:tw-scale-x-110{
    --tw-scale-x: 1.1;
  }

  .sm\:tw-scale-x-125{
    --tw-scale-x: 1.25;
  }

  .sm\:tw-scale-x-150{
    --tw-scale-x: 1.5;
  }

  .sm\:tw-scale-y-0{
    --tw-scale-y: 0;
  }

  .sm\:tw-scale-y-50{
    --tw-scale-y: .5;
  }

  .sm\:tw-scale-y-75{
    --tw-scale-y: .75;
  }

  .sm\:tw-scale-y-90{
    --tw-scale-y: .9;
  }

  .sm\:tw-scale-y-95{
    --tw-scale-y: .95;
  }

  .sm\:tw-scale-y-100{
    --tw-scale-y: 1;
  }

  .sm\:tw-scale-y-105{
    --tw-scale-y: 1.05;
  }

  .sm\:tw-scale-y-110{
    --tw-scale-y: 1.1;
  }

  .sm\:tw-scale-y-125{
    --tw-scale-y: 1.25;
  }

  .sm\:tw-scale-y-150{
    --tw-scale-y: 1.5;
  }

  .sm\:hover\:tw-scale-x-0:hover{
    --tw-scale-x: 0;
  }

  .sm\:hover\:tw-scale-x-50:hover{
    --tw-scale-x: .5;
  }

  .sm\:hover\:tw-scale-x-75:hover{
    --tw-scale-x: .75;
  }

  .sm\:hover\:tw-scale-x-90:hover{
    --tw-scale-x: .9;
  }

  .sm\:hover\:tw-scale-x-95:hover{
    --tw-scale-x: .95;
  }

  .sm\:hover\:tw-scale-x-100:hover{
    --tw-scale-x: 1;
  }

  .sm\:hover\:tw-scale-x-105:hover{
    --tw-scale-x: 1.05;
  }

  .sm\:hover\:tw-scale-x-110:hover{
    --tw-scale-x: 1.1;
  }

  .sm\:hover\:tw-scale-x-125:hover{
    --tw-scale-x: 1.25;
  }

  .sm\:hover\:tw-scale-x-150:hover{
    --tw-scale-x: 1.5;
  }

  .sm\:hover\:tw-scale-y-0:hover{
    --tw-scale-y: 0;
  }

  .sm\:hover\:tw-scale-y-50:hover{
    --tw-scale-y: .5;
  }

  .sm\:hover\:tw-scale-y-75:hover{
    --tw-scale-y: .75;
  }

  .sm\:hover\:tw-scale-y-90:hover{
    --tw-scale-y: .9;
  }

  .sm\:hover\:tw-scale-y-95:hover{
    --tw-scale-y: .95;
  }

  .sm\:hover\:tw-scale-y-100:hover{
    --tw-scale-y: 1;
  }

  .sm\:hover\:tw-scale-y-105:hover{
    --tw-scale-y: 1.05;
  }

  .sm\:hover\:tw-scale-y-110:hover{
    --tw-scale-y: 1.1;
  }

  .sm\:hover\:tw-scale-y-125:hover{
    --tw-scale-y: 1.25;
  }

  .sm\:hover\:tw-scale-y-150:hover{
    --tw-scale-y: 1.5;
  }

  .sm\:focus\:tw-scale-x-0:focus{
    --tw-scale-x: 0;
  }

  .sm\:focus\:tw-scale-x-50:focus{
    --tw-scale-x: .5;
  }

  .sm\:focus\:tw-scale-x-75:focus{
    --tw-scale-x: .75;
  }

  .sm\:focus\:tw-scale-x-90:focus{
    --tw-scale-x: .9;
  }

  .sm\:focus\:tw-scale-x-95:focus{
    --tw-scale-x: .95;
  }

  .sm\:focus\:tw-scale-x-100:focus{
    --tw-scale-x: 1;
  }

  .sm\:focus\:tw-scale-x-105:focus{
    --tw-scale-x: 1.05;
  }

  .sm\:focus\:tw-scale-x-110:focus{
    --tw-scale-x: 1.1;
  }

  .sm\:focus\:tw-scale-x-125:focus{
    --tw-scale-x: 1.25;
  }

  .sm\:focus\:tw-scale-x-150:focus{
    --tw-scale-x: 1.5;
  }

  .sm\:focus\:tw-scale-y-0:focus{
    --tw-scale-y: 0;
  }

  .sm\:focus\:tw-scale-y-50:focus{
    --tw-scale-y: .5;
  }

  .sm\:focus\:tw-scale-y-75:focus{
    --tw-scale-y: .75;
  }

  .sm\:focus\:tw-scale-y-90:focus{
    --tw-scale-y: .9;
  }

  .sm\:focus\:tw-scale-y-95:focus{
    --tw-scale-y: .95;
  }

  .sm\:focus\:tw-scale-y-100:focus{
    --tw-scale-y: 1;
  }

  .sm\:focus\:tw-scale-y-105:focus{
    --tw-scale-y: 1.05;
  }

  .sm\:focus\:tw-scale-y-110:focus{
    --tw-scale-y: 1.1;
  }

  .sm\:focus\:tw-scale-y-125:focus{
    --tw-scale-y: 1.25;
  }

  .sm\:focus\:tw-scale-y-150:focus{
    --tw-scale-y: 1.5;
  }

  .sm\:tw-animate-none{
    -webkit-animation: none;
    animation: none;
  }

  .sm\:tw-animate-spin{
    -webkit-animation: tw-spin 1s linear infinite;
    animation: tw-spin 1s linear infinite;
  }

  .sm\:tw-animate-ping{
    -webkit-animation: tw-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
    animation: tw-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .sm\:tw-animate-pulse{
    -webkit-animation: tw-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    animation: tw-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .sm\:tw-animate-bounce{
    -webkit-animation: tw-bounce 1s infinite;
    animation: tw-bounce 1s infinite;
  }

  .sm\:tw-cursor-auto{
    cursor: auto;
  }

  .sm\:tw-cursor-default{
    cursor: default;
  }

  .sm\:tw-cursor-pointer{
    cursor: pointer;
  }

  .sm\:tw-cursor-wait{
    cursor: wait;
  }

  .sm\:tw-cursor-text{
    cursor: text;
  }

  .sm\:tw-cursor-move{
    cursor: move;
  }

  .sm\:tw-cursor-not-allowed{
    cursor: not-allowed;
  }

  .sm\:tw-select-none{
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .sm\:tw-select-text{
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }

  .sm\:tw-select-all{
    -webkit-user-select: all;
    -ms-user-select: all;
    user-select: all;
  }

  .sm\:tw-select-auto{
    -webkit-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
  }

  .sm\:tw-resize-none{
    resize: none;
  }

  .sm\:tw-resize-y{
    resize: vertical;
  }

  .sm\:tw-resize-x{
    resize: horizontal;
  }

  .sm\:tw-resize{
    resize: both;
  }

  .sm\:tw-list-inside{
    list-style-position: inside;
  }

  .sm\:tw-list-outside{
    list-style-position: outside;
  }

  .sm\:tw-list-none{
    list-style-type: none;
  }

  .sm\:tw-list-disc{
    list-style-type: disc;
  }

  .sm\:tw-list-decimal{
    list-style-type: decimal;
  }

  .sm\:tw-appearance-none{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .sm\:tw-auto-cols-auto{
    grid-auto-columns: auto;
  }

  .sm\:tw-auto-cols-min{
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
  }

  .sm\:tw-auto-cols-max{
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
  }

  .sm\:tw-auto-cols-fr{
    grid-auto-columns: minmax(0, 1fr);
  }

  .sm\:tw-grid-flow-row{
    grid-auto-flow: row;
  }

  .sm\:tw-grid-flow-col{
    grid-auto-flow: column;
  }

  .sm\:tw-grid-flow-row-dense{
    grid-auto-flow: row dense;
  }

  .sm\:tw-grid-flow-col-dense{
    grid-auto-flow: column dense;
  }

  .sm\:tw-auto-rows-auto{
    grid-auto-rows: auto;
  }

  .sm\:tw-auto-rows-min{
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
  }

  .sm\:tw-auto-rows-max{
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
  }

  .sm\:tw-auto-rows-fr{
    grid-auto-rows: minmax(0, 1fr);
  }

  .sm\:tw-grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .sm\:tw-grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:tw-grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:tw-grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:tw-grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .sm\:tw-grid-cols-6{
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .sm\:tw-grid-cols-7{
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .sm\:tw-grid-cols-8{
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .sm\:tw-grid-cols-9{
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .sm\:tw-grid-cols-10{
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .sm\:tw-grid-cols-11{
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .sm\:tw-grid-cols-12{
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .sm\:tw-grid-cols-none{
    grid-template-columns: none;
  }

  .sm\:tw-grid-rows-1{
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .sm\:tw-grid-rows-2{
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .sm\:tw-grid-rows-3{
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .sm\:tw-grid-rows-4{
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .sm\:tw-grid-rows-5{
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .sm\:tw-grid-rows-6{
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .sm\:tw-grid-rows-none{
    grid-template-rows: none;
  }

  .sm\:tw-flex-row{
    flex-direction: row;
  }

  .sm\:tw-flex-row-reverse{
    flex-direction: row-reverse;
  }

  .sm\:tw-flex-col{
    flex-direction: column;
  }

  .sm\:tw-flex-col-reverse{
    flex-direction: column-reverse;
  }

  .sm\:tw-flex-wrap{
    flex-wrap: wrap;
  }

  .sm\:tw-flex-wrap-reverse{
    flex-wrap: wrap-reverse;
  }

  .sm\:tw-flex-nowrap{
    flex-wrap: nowrap;
  }

  .sm\:tw-place-content-center{
    place-content: center;
  }

  .sm\:tw-place-content-start{
    place-content: start;
  }

  .sm\:tw-place-content-end{
    place-content: end;
  }

  .sm\:tw-place-content-between{
    place-content: space-between;
  }

  .sm\:tw-place-content-around{
    place-content: space-around;
  }

  .sm\:tw-place-content-evenly{
    place-content: space-evenly;
  }

  .sm\:tw-place-content-stretch{
    place-content: stretch;
  }

  .sm\:tw-place-items-start{
    place-items: start;
  }

  .sm\:tw-place-items-end{
    place-items: end;
  }

  .sm\:tw-place-items-center{
    place-items: center;
  }

  .sm\:tw-place-items-stretch{
    place-items: stretch;
  }

  .sm\:tw-content-center{
    align-content: center;
  }

  .sm\:tw-content-start{
    align-content: flex-start;
  }

  .sm\:tw-content-end{
    align-content: flex-end;
  }

  .sm\:tw-content-between{
    align-content: space-between;
  }

  .sm\:tw-content-around{
    align-content: space-around;
  }

  .sm\:tw-content-evenly{
    align-content: space-evenly;
  }

  .sm\:tw-items-start{
    align-items: flex-start;
  }

  .sm\:tw-items-end{
    align-items: flex-end;
  }

  .sm\:tw-items-center{
    align-items: center;
  }

  .sm\:tw-items-baseline{
    align-items: baseline;
  }

  .sm\:tw-items-stretch{
    align-items: stretch;
  }

  .sm\:tw-justify-start{
    justify-content: flex-start;
  }

  .sm\:tw-justify-end{
    justify-content: flex-end;
  }

  .sm\:tw-justify-center{
    justify-content: center;
  }

  .sm\:tw-justify-between{
    justify-content: space-between;
  }

  .sm\:tw-justify-around{
    justify-content: space-around;
  }

  .sm\:tw-justify-evenly{
    justify-content: space-evenly;
  }

  .sm\:tw-justify-items-start{
    justify-items: start;
  }

  .sm\:tw-justify-items-end{
    justify-items: end;
  }

  .sm\:tw-justify-items-center{
    justify-items: center;
  }

  .sm\:tw-justify-items-stretch{
    justify-items: stretch;
  }

  .sm\:tw-gap-0{
    gap: 0;
  }

  .sm\:tw-gap-1{
    gap: 0.25rem;
  }

  .sm\:tw-gap-2{
    gap: 0.5rem;
  }

  .sm\:tw-gap-3{
    gap: 0.75rem;
  }

  .sm\:tw-gap-4{
    gap: 1rem;
  }

  .sm\:tw-gap-5{
    gap: 1.25rem;
  }

  .sm\:tw-gap-6{
    gap: 1.5rem;
  }

  .sm\:tw-gap-8{
    gap: 2rem;
  }

  .sm\:tw-gap-10{
    gap: 2.5rem;
  }

  .sm\:tw-gap-12{
    gap: 3rem;
  }

  .sm\:tw-gap-16{
    gap: 4rem;
  }

  .sm\:tw-gap-20{
    gap: 5rem;
  }

  .sm\:tw-gap-24{
    gap: 6rem;
  }

  .sm\:tw-gap-32{
    gap: 8rem;
  }

  .sm\:tw-gap-40{
    gap: 10rem;
  }

  .sm\:tw-gap-48{
    gap: 12rem;
  }

  .sm\:tw-gap-56{
    gap: 14rem;
  }

  .sm\:tw-gap-64{
    gap: 16rem;
  }

  .sm\:tw-gap-px{
    gap: 1px;
  }

  .sm\:tw-gap-x-0{
    -webkit-column-gap: 0;
    column-gap: 0;
  }

  .sm\:tw-gap-x-1{
    -webkit-column-gap: 0.25rem;
    column-gap: 0.25rem;
  }

  .sm\:tw-gap-x-2{
    -webkit-column-gap: 0.5rem;
    column-gap: 0.5rem;
  }

  .sm\:tw-gap-x-3{
    -webkit-column-gap: 0.75rem;
    column-gap: 0.75rem;
  }

  .sm\:tw-gap-x-4{
    -webkit-column-gap: 1rem;
    column-gap: 1rem;
  }

  .sm\:tw-gap-x-5{
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }

  .sm\:tw-gap-x-6{
    -webkit-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }

  .sm\:tw-gap-x-8{
    -webkit-column-gap: 2rem;
    column-gap: 2rem;
  }

  .sm\:tw-gap-x-10{
    -webkit-column-gap: 2.5rem;
    column-gap: 2.5rem;
  }

  .sm\:tw-gap-x-12{
    -webkit-column-gap: 3rem;
    column-gap: 3rem;
  }

  .sm\:tw-gap-x-16{
    -webkit-column-gap: 4rem;
    column-gap: 4rem;
  }

  .sm\:tw-gap-x-20{
    -webkit-column-gap: 5rem;
    column-gap: 5rem;
  }

  .sm\:tw-gap-x-24{
    -webkit-column-gap: 6rem;
    column-gap: 6rem;
  }

  .sm\:tw-gap-x-32{
    -webkit-column-gap: 8rem;
    column-gap: 8rem;
  }

  .sm\:tw-gap-x-40{
    -webkit-column-gap: 10rem;
    column-gap: 10rem;
  }

  .sm\:tw-gap-x-48{
    -webkit-column-gap: 12rem;
    column-gap: 12rem;
  }

  .sm\:tw-gap-x-56{
    -webkit-column-gap: 14rem;
    column-gap: 14rem;
  }

  .sm\:tw-gap-x-64{
    -webkit-column-gap: 16rem;
    column-gap: 16rem;
  }

  .sm\:tw-gap-x-px{
    -webkit-column-gap: 1px;
    column-gap: 1px;
  }

  .sm\:tw-gap-y-0{
    row-gap: 0;
  }

  .sm\:tw-gap-y-1{
    row-gap: 0.25rem;
  }

  .sm\:tw-gap-y-2{
    row-gap: 0.5rem;
  }

  .sm\:tw-gap-y-3{
    row-gap: 0.75rem;
  }

  .sm\:tw-gap-y-4{
    row-gap: 1rem;
  }

  .sm\:tw-gap-y-5{
    row-gap: 1.25rem;
  }

  .sm\:tw-gap-y-6{
    row-gap: 1.5rem;
  }

  .sm\:tw-gap-y-8{
    row-gap: 2rem;
  }

  .sm\:tw-gap-y-10{
    row-gap: 2.5rem;
  }

  .sm\:tw-gap-y-12{
    row-gap: 3rem;
  }

  .sm\:tw-gap-y-16{
    row-gap: 4rem;
  }

  .sm\:tw-gap-y-20{
    row-gap: 5rem;
  }

  .sm\:tw-gap-y-24{
    row-gap: 6rem;
  }

  .sm\:tw-gap-y-32{
    row-gap: 8rem;
  }

  .sm\:tw-gap-y-40{
    row-gap: 10rem;
  }

  .sm\:tw-gap-y-48{
    row-gap: 12rem;
  }

  .sm\:tw-gap-y-56{
    row-gap: 14rem;
  }

  .sm\:tw-gap-y-64{
    row-gap: 16rem;
  }

  .sm\:tw-gap-y-px{
    row-gap: 1px;
  }

  .sm\:tw-space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw-space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw-space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw-space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw-space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw-space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw-space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw-space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw-space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw-space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw-space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw-space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw-space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw-space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw-space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw-space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw-space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw-space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw-space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw--space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw--space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw--space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw--space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw--space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw--space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw--space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw--space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw--space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw--space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw--space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw--space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw--space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw--space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw--space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw--space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw--space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw--space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:tw-space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .sm\:tw-space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }

  .sm\:tw-space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }

  .sm\:tw-space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }

  .sm\:tw-space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .sm\:tw-space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .sm\:tw-space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .sm\:tw-space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .sm\:tw-space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }

  .sm\:tw-space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }

  .sm\:tw-space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .sm\:tw-space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .sm\:tw-space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }

  .sm\:tw-space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }

  .sm\:tw-space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }

  .sm\:tw-space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }

  .sm\:tw-space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }

  .sm\:tw-space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }

  .sm\:tw-space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }

  .sm\:tw--space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
  }

  .sm\:tw--space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
  }

  .sm\:tw--space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
  }

  .sm\:tw--space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }

  .sm\:tw--space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }

  .sm\:tw--space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }

  .sm\:tw--space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }

  .sm\:tw--space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }

  .sm\:tw--space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }

  .sm\:tw--space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }

  .sm\:tw--space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }

  .sm\:tw--space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }

  .sm\:tw--space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }

  .sm\:tw--space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }

  .sm\:tw--space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }

  .sm\:tw--space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }

  .sm\:tw--space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }

  .sm\:tw--space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }

  .sm\:tw-space-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 1;
  }

  .sm\:tw-space-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 1;
  }

  .sm\:tw-divide-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:tw-divide-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:tw-divide-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:tw-divide-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:tw-divide-x-default > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:tw-divide-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .sm\:tw-divide-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }

  .sm\:tw-divide-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }

  .sm\:tw-divide-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }

  .sm\:tw-divide-y-default > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .sm\:tw-divide-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 1;
  }

  .sm\:tw-divide-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 1;
  }

  .sm\:tw-divide-solid > :not([hidden]) ~ :not([hidden]){
    border-style: solid;
  }

  .sm\:tw-divide-dashed > :not([hidden]) ~ :not([hidden]){
    border-style: dashed;
  }

  .sm\:tw-divide-dotted > :not([hidden]) ~ :not([hidden]){
    border-style: dotted;
  }

  .sm\:tw-divide-double > :not([hidden]) ~ :not([hidden]){
    border-style: double;
  }

  .sm\:tw-divide-none > :not([hidden]) ~ :not([hidden]){
    border-style: none;
  }

  .sm\:tw-divide-transparent > :not([hidden]) ~ :not([hidden]){
    border-color: transparent;
  }

  .sm\:tw-divide-current > :not([hidden]) ~ :not([hidden]){
    border-color: currentColor;
  }

  .sm\:tw-divide-black > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(44, 44, 44, var(--tw-divide-opacity));
  }

  .sm\:tw-divide-white > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }

  .sm\:tw-divide-gray-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(247, 250, 252, var(--tw-divide-opacity));
  }

  .sm\:tw-divide-gray-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(237, 242, 247, var(--tw-divide-opacity));
  }

  .sm\:tw-divide-gray-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-divide-opacity));
  }

  .sm\:tw-divide-gray-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(203, 213, 224, var(--tw-divide-opacity));
  }

  .sm\:tw-divide-gray-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(160, 174, 192, var(--tw-divide-opacity));
  }

  .sm\:tw-divide-gray-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(113, 128, 150, var(--tw-divide-opacity));
  }

  .sm\:tw-divide-gray-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(74, 85, 104, var(--tw-divide-opacity));
  }

  .sm\:tw-divide-gray-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(45, 55, 72, var(--tw-divide-opacity));
  }

  .sm\:tw-divide-gray-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(26, 32, 44, var(--tw-divide-opacity));
  }

  .sm\:tw-divide-blue-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(235, 248, 255, var(--tw-divide-opacity));
  }

  .sm\:tw-divide-blue-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(190, 227, 248, var(--tw-divide-opacity));
  }

  .sm\:tw-divide-blue-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(144, 205, 244, var(--tw-divide-opacity));
  }

  .sm\:tw-divide-blue-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(99, 179, 237, var(--tw-divide-opacity));
  }

  .sm\:tw-divide-blue-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(66, 153, 225, var(--tw-divide-opacity));
  }

  .sm\:tw-divide-blue-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(49, 130, 206, var(--tw-divide-opacity));
  }

  .sm\:tw-divide-blue-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(43, 108, 176, var(--tw-divide-opacity));
  }

  .sm\:tw-divide-blue-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(44, 82, 130, var(--tw-divide-opacity));
  }

  .sm\:tw-divide-blue-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(42, 67, 101, var(--tw-divide-opacity));
  }

  .sm\:tw-divide-opacity-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0;
  }

  .sm\:tw-divide-opacity-25 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.25;
  }

  .sm\:tw-divide-opacity-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.5;
  }

  .sm\:tw-divide-opacity-75 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.75;
  }

  .sm\:tw-divide-opacity-90 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.9;
  }

  .sm\:tw-divide-opacity-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
  }

  .sm\:tw-place-self-auto{
    place-self: auto;
  }

  .sm\:tw-place-self-start{
    place-self: start;
  }

  .sm\:tw-place-self-end{
    place-self: end;
  }

  .sm\:tw-place-self-center{
    place-self: center;
  }

  .sm\:tw-place-self-stretch{
    place-self: stretch;
  }

  .sm\:tw-self-auto{
    align-self: auto;
  }

  .sm\:tw-self-start{
    align-self: flex-start;
  }

  .sm\:tw-self-end{
    align-self: flex-end;
  }

  .sm\:tw-self-center{
    align-self: center;
  }

  .sm\:tw-self-stretch{
    align-self: stretch;
  }

  .sm\:tw-self-baseline{
    align-self: baseline;
  }

  .sm\:tw-justify-self-auto{
    justify-self: auto;
  }

  .sm\:tw-justify-self-start{
    justify-self: start;
  }

  .sm\:tw-justify-self-end{
    justify-self: end;
  }

  .sm\:tw-justify-self-center{
    justify-self: center;
  }

  .sm\:tw-justify-self-stretch{
    justify-self: stretch;
  }

  .sm\:tw-overflow-auto{
    overflow: auto;
  }

  .sm\:tw-overflow-hidden{
    overflow: hidden;
  }

  .sm\:tw-overflow-visible{
    overflow: visible;
  }

  .sm\:tw-overflow-scroll{
    overflow: scroll;
  }

  .sm\:tw-overflow-x-auto{
    overflow-x: auto;
  }

  .sm\:tw-overflow-y-auto{
    overflow-y: auto;
  }

  .sm\:tw-overflow-x-hidden{
    overflow-x: hidden;
  }

  .sm\:tw-overflow-y-hidden{
    overflow-y: hidden;
  }

  .sm\:tw-overflow-x-visible{
    overflow-x: visible;
  }

  .sm\:tw-overflow-y-visible{
    overflow-y: visible;
  }

  .sm\:tw-overflow-x-scroll{
    overflow-x: scroll;
  }

  .sm\:tw-overflow-y-scroll{
    overflow-y: scroll;
  }

  .sm\:tw-overscroll-auto{
    overscroll-behavior: auto;
  }

  .sm\:tw-overscroll-contain{
    overscroll-behavior: contain;
  }

  .sm\:tw-overscroll-none{
    overscroll-behavior: none;
  }

  .sm\:tw-overscroll-y-auto{
    overscroll-behavior-y: auto;
  }

  .sm\:tw-overscroll-y-contain{
    overscroll-behavior-y: contain;
  }

  .sm\:tw-overscroll-y-none{
    overscroll-behavior-y: none;
  }

  .sm\:tw-overscroll-x-auto{
    overscroll-behavior-x: auto;
  }

  .sm\:tw-overscroll-x-contain{
    overscroll-behavior-x: contain;
  }

  .sm\:tw-overscroll-x-none{
    overscroll-behavior-x: none;
  }

  .sm\:tw-truncate{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .sm\:tw-overflow-ellipsis{
    text-overflow: ellipsis;
  }

  .sm\:tw-overflow-clip{
    text-overflow: clip;
  }

  .sm\:tw-whitespace-normal{
    white-space: normal;
  }

  .sm\:tw-whitespace-nowrap{
    white-space: nowrap;
  }

  .sm\:tw-whitespace-pre{
    white-space: pre;
  }

  .sm\:tw-whitespace-pre-line{
    white-space: pre-line;
  }

  .sm\:tw-whitespace-pre-wrap{
    white-space: pre-wrap;
  }

  .sm\:tw-break-normal{
    overflow-wrap: normal;
    word-break: normal;
  }

  .sm\:tw-break-words{
    overflow-wrap: break-word;
  }

  .sm\:tw-break-all{
    word-break: break-all;
  }

  .sm\:tw-rounded-none{
    border-radius: 0;
  }

  .sm\:tw-rounded-sm{
    border-radius: 0.125rem;
  }

  .sm\:tw-rounded-default{
    border-radius: 0.25rem;
  }

  .sm\:tw-rounded-md{
    border-radius: 0.375rem;
  }

  .sm\:tw-rounded-lg{
    border-radius: 0.5rem;
  }

  .sm\:tw-rounded-xl{
    border-radius: 0.75rem;
  }

  .sm\:tw-rounded-2xl{
    border-radius: 1rem;
  }

  .sm\:tw-rounded-3xl{
    border-radius: 1.5rem;
  }

  .sm\:tw-rounded-full{
    border-radius: 9999px;
  }

  .sm\:tw-rounded-t-none{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .sm\:tw-rounded-t-sm{
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }

  .sm\:tw-rounded-t-default{
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .sm\:tw-rounded-t-md{
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  .sm\:tw-rounded-t-lg{
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .sm\:tw-rounded-t-xl{
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }

  .sm\:tw-rounded-t-2xl{
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .sm\:tw-rounded-t-3xl{
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .sm\:tw-rounded-t-full{
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .sm\:tw-rounded-r-none{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .sm\:tw-rounded-r-sm{
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }

  .sm\:tw-rounded-r-default{
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .sm\:tw-rounded-r-md{
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .sm\:tw-rounded-r-lg{
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .sm\:tw-rounded-r-xl{
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  .sm\:tw-rounded-r-2xl{
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .sm\:tw-rounded-r-3xl{
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .sm\:tw-rounded-r-full{
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .sm\:tw-rounded-b-none{
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .sm\:tw-rounded-b-sm{
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .sm\:tw-rounded-b-default{
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .sm\:tw-rounded-b-md{
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .sm\:tw-rounded-b-lg{
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .sm\:tw-rounded-b-xl{
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .sm\:tw-rounded-b-2xl{
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .sm\:tw-rounded-b-3xl{
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .sm\:tw-rounded-b-full{
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .sm\:tw-rounded-l-none{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .sm\:tw-rounded-l-sm{
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .sm\:tw-rounded-l-default{
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .sm\:tw-rounded-l-md{
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .sm\:tw-rounded-l-lg{
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .sm\:tw-rounded-l-xl{
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .sm\:tw-rounded-l-2xl{
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .sm\:tw-rounded-l-3xl{
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .sm\:tw-rounded-l-full{
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .sm\:tw-rounded-tl-none{
    border-top-left-radius: 0;
  }

  .sm\:tw-rounded-tl-sm{
    border-top-left-radius: 0.125rem;
  }

  .sm\:tw-rounded-tl-default{
    border-top-left-radius: 0.25rem;
  }

  .sm\:tw-rounded-tl-md{
    border-top-left-radius: 0.375rem;
  }

  .sm\:tw-rounded-tl-lg{
    border-top-left-radius: 0.5rem;
  }

  .sm\:tw-rounded-tl-xl{
    border-top-left-radius: 0.75rem;
  }

  .sm\:tw-rounded-tl-2xl{
    border-top-left-radius: 1rem;
  }

  .sm\:tw-rounded-tl-3xl{
    border-top-left-radius: 1.5rem;
  }

  .sm\:tw-rounded-tl-full{
    border-top-left-radius: 9999px;
  }

  .sm\:tw-rounded-tr-none{
    border-top-right-radius: 0;
  }

  .sm\:tw-rounded-tr-sm{
    border-top-right-radius: 0.125rem;
  }

  .sm\:tw-rounded-tr-default{
    border-top-right-radius: 0.25rem;
  }

  .sm\:tw-rounded-tr-md{
    border-top-right-radius: 0.375rem;
  }

  .sm\:tw-rounded-tr-lg{
    border-top-right-radius: 0.5rem;
  }

  .sm\:tw-rounded-tr-xl{
    border-top-right-radius: 0.75rem;
  }

  .sm\:tw-rounded-tr-2xl{
    border-top-right-radius: 1rem;
  }

  .sm\:tw-rounded-tr-3xl{
    border-top-right-radius: 1.5rem;
  }

  .sm\:tw-rounded-tr-full{
    border-top-right-radius: 9999px;
  }

  .sm\:tw-rounded-br-none{
    border-bottom-right-radius: 0;
  }

  .sm\:tw-rounded-br-sm{
    border-bottom-right-radius: 0.125rem;
  }

  .sm\:tw-rounded-br-default{
    border-bottom-right-radius: 0.25rem;
  }

  .sm\:tw-rounded-br-md{
    border-bottom-right-radius: 0.375rem;
  }

  .sm\:tw-rounded-br-lg{
    border-bottom-right-radius: 0.5rem;
  }

  .sm\:tw-rounded-br-xl{
    border-bottom-right-radius: 0.75rem;
  }

  .sm\:tw-rounded-br-2xl{
    border-bottom-right-radius: 1rem;
  }

  .sm\:tw-rounded-br-3xl{
    border-bottom-right-radius: 1.5rem;
  }

  .sm\:tw-rounded-br-full{
    border-bottom-right-radius: 9999px;
  }

  .sm\:tw-rounded-bl-none{
    border-bottom-left-radius: 0;
  }

  .sm\:tw-rounded-bl-sm{
    border-bottom-left-radius: 0.125rem;
  }

  .sm\:tw-rounded-bl-default{
    border-bottom-left-radius: 0.25rem;
  }

  .sm\:tw-rounded-bl-md{
    border-bottom-left-radius: 0.375rem;
  }

  .sm\:tw-rounded-bl-lg{
    border-bottom-left-radius: 0.5rem;
  }

  .sm\:tw-rounded-bl-xl{
    border-bottom-left-radius: 0.75rem;
  }

  .sm\:tw-rounded-bl-2xl{
    border-bottom-left-radius: 1rem;
  }

  .sm\:tw-rounded-bl-3xl{
    border-bottom-left-radius: 1.5rem;
  }

  .sm\:tw-rounded-bl-full{
    border-bottom-left-radius: 9999px;
  }

  .sm\:tw-border-0{
    border-width: 0;
  }

  .sm\:tw-border-2{
    border-width: 2px;
  }

  .sm\:tw-border-4{
    border-width: 4px;
  }

  .sm\:tw-border-8{
    border-width: 8px;
  }

  .sm\:tw-border-default{
    border-width: 1px;
  }

  .sm\:tw-border-t-0{
    border-top-width: 0;
  }

  .sm\:tw-border-t-2{
    border-top-width: 2px;
  }

  .sm\:tw-border-t-4{
    border-top-width: 4px;
  }

  .sm\:tw-border-t-8{
    border-top-width: 8px;
  }

  .sm\:tw-border-t-default{
    border-top-width: 1px;
  }

  .sm\:tw-border-r-0{
    border-right-width: 0;
  }

  .sm\:tw-border-r-2{
    border-right-width: 2px;
  }

  .sm\:tw-border-r-4{
    border-right-width: 4px;
  }

  .sm\:tw-border-r-8{
    border-right-width: 8px;
  }

  .sm\:tw-border-r-default{
    border-right-width: 1px;
  }

  .sm\:tw-border-b-0{
    border-bottom-width: 0;
  }

  .sm\:tw-border-b-2{
    border-bottom-width: 2px;
  }

  .sm\:tw-border-b-4{
    border-bottom-width: 4px;
  }

  .sm\:tw-border-b-8{
    border-bottom-width: 8px;
  }

  .sm\:tw-border-b-default{
    border-bottom-width: 1px;
  }

  .sm\:tw-border-l-0{
    border-left-width: 0;
  }

  .sm\:tw-border-l-2{
    border-left-width: 2px;
  }

  .sm\:tw-border-l-4{
    border-left-width: 4px;
  }

  .sm\:tw-border-l-8{
    border-left-width: 8px;
  }

  .sm\:tw-border-l-default{
    border-left-width: 1px;
  }

  .sm\:tw-border-solid{
    border-style: solid;
  }

  .sm\:tw-border-dashed{
    border-style: dashed;
  }

  .sm\:tw-border-dotted{
    border-style: dotted;
  }

  .sm\:tw-border-double{
    border-style: double;
  }

  .sm\:tw-border-none{
    border-style: none;
  }

  .sm\:tw-border-transparent{
    border-color: transparent;
  }

  .sm\:tw-border-current{
    border-color: currentColor;
  }

  .sm\:tw-border-black{
    --tw-border-opacity: 1;
    border-color: rgba(44, 44, 44, var(--tw-border-opacity));
  }

  .sm\:tw-border-white{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .sm\:tw-border-gray-100{
    --tw-border-opacity: 1;
    border-color: rgba(247, 250, 252, var(--tw-border-opacity));
  }

  .sm\:tw-border-gray-200{
    --tw-border-opacity: 1;
    border-color: rgba(237, 242, 247, var(--tw-border-opacity));
  }

  .sm\:tw-border-gray-300{
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
  }

  .sm\:tw-border-gray-400{
    --tw-border-opacity: 1;
    border-color: rgba(203, 213, 224, var(--tw-border-opacity));
  }

  .sm\:tw-border-gray-500{
    --tw-border-opacity: 1;
    border-color: rgba(160, 174, 192, var(--tw-border-opacity));
  }

  .sm\:tw-border-gray-600{
    --tw-border-opacity: 1;
    border-color: rgba(113, 128, 150, var(--tw-border-opacity));
  }

  .sm\:tw-border-gray-700{
    --tw-border-opacity: 1;
    border-color: rgba(74, 85, 104, var(--tw-border-opacity));
  }

  .sm\:tw-border-gray-800{
    --tw-border-opacity: 1;
    border-color: rgba(45, 55, 72, var(--tw-border-opacity));
  }

  .sm\:tw-border-gray-900{
    --tw-border-opacity: 1;
    border-color: rgba(26, 32, 44, var(--tw-border-opacity));
  }

  .sm\:tw-border-blue-100{
    --tw-border-opacity: 1;
    border-color: rgba(235, 248, 255, var(--tw-border-opacity));
  }

  .sm\:tw-border-blue-200{
    --tw-border-opacity: 1;
    border-color: rgba(190, 227, 248, var(--tw-border-opacity));
  }

  .sm\:tw-border-blue-300{
    --tw-border-opacity: 1;
    border-color: rgba(144, 205, 244, var(--tw-border-opacity));
  }

  .sm\:tw-border-blue-400{
    --tw-border-opacity: 1;
    border-color: rgba(99, 179, 237, var(--tw-border-opacity));
  }

  .sm\:tw-border-blue-500{
    --tw-border-opacity: 1;
    border-color: rgba(66, 153, 225, var(--tw-border-opacity));
  }

  .sm\:tw-border-blue-600{
    --tw-border-opacity: 1;
    border-color: rgba(49, 130, 206, var(--tw-border-opacity));
  }

  .sm\:tw-border-blue-700{
    --tw-border-opacity: 1;
    border-color: rgba(43, 108, 176, var(--tw-border-opacity));
  }

  .sm\:tw-border-blue-800{
    --tw-border-opacity: 1;
    border-color: rgba(44, 82, 130, var(--tw-border-opacity));
  }

  .sm\:tw-border-blue-900{
    --tw-border-opacity: 1;
    border-color: rgba(42, 67, 101, var(--tw-border-opacity));
  }

  .sm\:hover\:tw-border-transparent:hover{
    border-color: transparent;
  }

  .sm\:hover\:tw-border-current:hover{
    border-color: currentColor;
  }

  .sm\:hover\:tw-border-black:hover{
    --tw-border-opacity: 1;
    border-color: rgba(44, 44, 44, var(--tw-border-opacity));
  }

  .sm\:hover\:tw-border-white:hover{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .sm\:hover\:tw-border-gray-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(247, 250, 252, var(--tw-border-opacity));
  }

  .sm\:hover\:tw-border-gray-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(237, 242, 247, var(--tw-border-opacity));
  }

  .sm\:hover\:tw-border-gray-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
  }

  .sm\:hover\:tw-border-gray-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(203, 213, 224, var(--tw-border-opacity));
  }

  .sm\:hover\:tw-border-gray-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(160, 174, 192, var(--tw-border-opacity));
  }

  .sm\:hover\:tw-border-gray-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(113, 128, 150, var(--tw-border-opacity));
  }

  .sm\:hover\:tw-border-gray-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(74, 85, 104, var(--tw-border-opacity));
  }

  .sm\:hover\:tw-border-gray-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(45, 55, 72, var(--tw-border-opacity));
  }

  .sm\:hover\:tw-border-gray-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(26, 32, 44, var(--tw-border-opacity));
  }

  .sm\:hover\:tw-border-blue-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(235, 248, 255, var(--tw-border-opacity));
  }

  .sm\:hover\:tw-border-blue-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(190, 227, 248, var(--tw-border-opacity));
  }

  .sm\:hover\:tw-border-blue-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(144, 205, 244, var(--tw-border-opacity));
  }

  .sm\:hover\:tw-border-blue-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(99, 179, 237, var(--tw-border-opacity));
  }

  .sm\:hover\:tw-border-blue-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(66, 153, 225, var(--tw-border-opacity));
  }

  .sm\:hover\:tw-border-blue-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(49, 130, 206, var(--tw-border-opacity));
  }

  .sm\:hover\:tw-border-blue-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(43, 108, 176, var(--tw-border-opacity));
  }

  .sm\:hover\:tw-border-blue-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(44, 82, 130, var(--tw-border-opacity));
  }

  .sm\:hover\:tw-border-blue-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(42, 67, 101, var(--tw-border-opacity));
  }

  .sm\:focus\:tw-border-transparent:focus{
    border-color: transparent;
  }

  .sm\:focus\:tw-border-current:focus{
    border-color: currentColor;
  }

  .sm\:focus\:tw-border-black:focus{
    --tw-border-opacity: 1;
    border-color: rgba(44, 44, 44, var(--tw-border-opacity));
  }

  .sm\:focus\:tw-border-white:focus{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .sm\:focus\:tw-border-gray-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(247, 250, 252, var(--tw-border-opacity));
  }

  .sm\:focus\:tw-border-gray-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(237, 242, 247, var(--tw-border-opacity));
  }

  .sm\:focus\:tw-border-gray-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
  }

  .sm\:focus\:tw-border-gray-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(203, 213, 224, var(--tw-border-opacity));
  }

  .sm\:focus\:tw-border-gray-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(160, 174, 192, var(--tw-border-opacity));
  }

  .sm\:focus\:tw-border-gray-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(113, 128, 150, var(--tw-border-opacity));
  }

  .sm\:focus\:tw-border-gray-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(74, 85, 104, var(--tw-border-opacity));
  }

  .sm\:focus\:tw-border-gray-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(45, 55, 72, var(--tw-border-opacity));
  }

  .sm\:focus\:tw-border-gray-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(26, 32, 44, var(--tw-border-opacity));
  }

  .sm\:focus\:tw-border-blue-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(235, 248, 255, var(--tw-border-opacity));
  }

  .sm\:focus\:tw-border-blue-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(190, 227, 248, var(--tw-border-opacity));
  }

  .sm\:focus\:tw-border-blue-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(144, 205, 244, var(--tw-border-opacity));
  }

  .sm\:focus\:tw-border-blue-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(99, 179, 237, var(--tw-border-opacity));
  }

  .sm\:focus\:tw-border-blue-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(66, 153, 225, var(--tw-border-opacity));
  }

  .sm\:focus\:tw-border-blue-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(49, 130, 206, var(--tw-border-opacity));
  }

  .sm\:focus\:tw-border-blue-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(43, 108, 176, var(--tw-border-opacity));
  }

  .sm\:focus\:tw-border-blue-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(44, 82, 130, var(--tw-border-opacity));
  }

  .sm\:focus\:tw-border-blue-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(42, 67, 101, var(--tw-border-opacity));
  }

  .sm\:tw-border-opacity-0{
    --tw-border-opacity: 0;
  }

  .sm\:tw-border-opacity-25{
    --tw-border-opacity: 0.25;
  }

  .sm\:tw-border-opacity-50{
    --tw-border-opacity: 0.5;
  }

  .sm\:tw-border-opacity-75{
    --tw-border-opacity: 0.75;
  }

  .sm\:tw-border-opacity-90{
    --tw-border-opacity: 0.9;
  }

  .sm\:tw-border-opacity-100{
    --tw-border-opacity: 1;
  }

  .sm\:hover\:tw-border-opacity-0:hover{
    --tw-border-opacity: 0;
  }

  .sm\:hover\:tw-border-opacity-25:hover{
    --tw-border-opacity: 0.25;
  }

  .sm\:hover\:tw-border-opacity-50:hover{
    --tw-border-opacity: 0.5;
  }

  .sm\:hover\:tw-border-opacity-75:hover{
    --tw-border-opacity: 0.75;
  }

  .sm\:hover\:tw-border-opacity-90:hover{
    --tw-border-opacity: 0.9;
  }

  .sm\:hover\:tw-border-opacity-100:hover{
    --tw-border-opacity: 1;
  }

  .sm\:focus\:tw-border-opacity-0:focus{
    --tw-border-opacity: 0;
  }

  .sm\:focus\:tw-border-opacity-25:focus{
    --tw-border-opacity: 0.25;
  }

  .sm\:focus\:tw-border-opacity-50:focus{
    --tw-border-opacity: 0.5;
  }

  .sm\:focus\:tw-border-opacity-75:focus{
    --tw-border-opacity: 0.75;
  }

  .sm\:focus\:tw-border-opacity-90:focus{
    --tw-border-opacity: 0.9;
  }

  .sm\:focus\:tw-border-opacity-100:focus{
    --tw-border-opacity: 1;
  }

  .sm\:tw-bg-transparent{
    background-color: transparent;
  }

  .sm\:tw-bg-current{
    background-color: currentColor;
  }

  .sm\:tw-bg-black{
    --tw-bg-opacity: 1;
    background-color: rgba(44, 44, 44, var(--tw-bg-opacity));
  }

  .sm\:tw-bg-white{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .sm\:tw-bg-gray-100{
    --tw-bg-opacity: 1;
    background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
  }

  .sm\:tw-bg-gray-200{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 242, 247, var(--tw-bg-opacity));
  }

  .sm\:tw-bg-gray-300{
    --tw-bg-opacity: 1;
    background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
  }

  .sm\:tw-bg-gray-400{
    --tw-bg-opacity: 1;
    background-color: rgba(203, 213, 224, var(--tw-bg-opacity));
  }

  .sm\:tw-bg-gray-500{
    --tw-bg-opacity: 1;
    background-color: rgba(160, 174, 192, var(--tw-bg-opacity));
  }

  .sm\:tw-bg-gray-600{
    --tw-bg-opacity: 1;
    background-color: rgba(113, 128, 150, var(--tw-bg-opacity));
  }

  .sm\:tw-bg-gray-700{
    --tw-bg-opacity: 1;
    background-color: rgba(74, 85, 104, var(--tw-bg-opacity));
  }

  .sm\:tw-bg-gray-800{
    --tw-bg-opacity: 1;
    background-color: rgba(45, 55, 72, var(--tw-bg-opacity));
  }

  .sm\:tw-bg-gray-900{
    --tw-bg-opacity: 1;
    background-color: rgba(26, 32, 44, var(--tw-bg-opacity));
  }

  .sm\:tw-bg-blue-100{
    --tw-bg-opacity: 1;
    background-color: rgba(235, 248, 255, var(--tw-bg-opacity));
  }

  .sm\:tw-bg-blue-200{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 227, 248, var(--tw-bg-opacity));
  }

  .sm\:tw-bg-blue-300{
    --tw-bg-opacity: 1;
    background-color: rgba(144, 205, 244, var(--tw-bg-opacity));
  }

  .sm\:tw-bg-blue-400{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 179, 237, var(--tw-bg-opacity));
  }

  .sm\:tw-bg-blue-500{
    --tw-bg-opacity: 1;
    background-color: rgba(66, 153, 225, var(--tw-bg-opacity));
  }

  .sm\:tw-bg-blue-600{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 130, 206, var(--tw-bg-opacity));
  }

  .sm\:tw-bg-blue-700{
    --tw-bg-opacity: 1;
    background-color: rgba(43, 108, 176, var(--tw-bg-opacity));
  }

  .sm\:tw-bg-blue-800{
    --tw-bg-opacity: 1;
    background-color: rgba(44, 82, 130, var(--tw-bg-opacity));
  }

  .sm\:tw-bg-blue-900{
    --tw-bg-opacity: 1;
    background-color: rgba(42, 67, 101, var(--tw-bg-opacity));
  }

  .sm\:hover\:tw-bg-transparent:hover{
    background-color: transparent;
  }

  .sm\:hover\:tw-bg-current:hover{
    background-color: currentColor;
  }

  .sm\:hover\:tw-bg-black:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(44, 44, 44, var(--tw-bg-opacity));
  }

  .sm\:hover\:tw-bg-white:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .sm\:hover\:tw-bg-gray-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
  }

  .sm\:hover\:tw-bg-gray-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 242, 247, var(--tw-bg-opacity));
  }

  .sm\:hover\:tw-bg-gray-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
  }

  .sm\:hover\:tw-bg-gray-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(203, 213, 224, var(--tw-bg-opacity));
  }

  .sm\:hover\:tw-bg-gray-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(160, 174, 192, var(--tw-bg-opacity));
  }

  .sm\:hover\:tw-bg-gray-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(113, 128, 150, var(--tw-bg-opacity));
  }

  .sm\:hover\:tw-bg-gray-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(74, 85, 104, var(--tw-bg-opacity));
  }

  .sm\:hover\:tw-bg-gray-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(45, 55, 72, var(--tw-bg-opacity));
  }

  .sm\:hover\:tw-bg-gray-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(26, 32, 44, var(--tw-bg-opacity));
  }

  .sm\:hover\:tw-bg-blue-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(235, 248, 255, var(--tw-bg-opacity));
  }

  .sm\:hover\:tw-bg-blue-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 227, 248, var(--tw-bg-opacity));
  }

  .sm\:hover\:tw-bg-blue-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(144, 205, 244, var(--tw-bg-opacity));
  }

  .sm\:hover\:tw-bg-blue-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 179, 237, var(--tw-bg-opacity));
  }

  .sm\:hover\:tw-bg-blue-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(66, 153, 225, var(--tw-bg-opacity));
  }

  .sm\:hover\:tw-bg-blue-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 130, 206, var(--tw-bg-opacity));
  }

  .sm\:hover\:tw-bg-blue-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(43, 108, 176, var(--tw-bg-opacity));
  }

  .sm\:hover\:tw-bg-blue-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(44, 82, 130, var(--tw-bg-opacity));
  }

  .sm\:hover\:tw-bg-blue-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(42, 67, 101, var(--tw-bg-opacity));
  }

  .sm\:focus\:tw-bg-transparent:focus{
    background-color: transparent;
  }

  .sm\:focus\:tw-bg-current:focus{
    background-color: currentColor;
  }

  .sm\:focus\:tw-bg-black:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(44, 44, 44, var(--tw-bg-opacity));
  }

  .sm\:focus\:tw-bg-white:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .sm\:focus\:tw-bg-gray-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
  }

  .sm\:focus\:tw-bg-gray-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 242, 247, var(--tw-bg-opacity));
  }

  .sm\:focus\:tw-bg-gray-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
  }

  .sm\:focus\:tw-bg-gray-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(203, 213, 224, var(--tw-bg-opacity));
  }

  .sm\:focus\:tw-bg-gray-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(160, 174, 192, var(--tw-bg-opacity));
  }

  .sm\:focus\:tw-bg-gray-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(113, 128, 150, var(--tw-bg-opacity));
  }

  .sm\:focus\:tw-bg-gray-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(74, 85, 104, var(--tw-bg-opacity));
  }

  .sm\:focus\:tw-bg-gray-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(45, 55, 72, var(--tw-bg-opacity));
  }

  .sm\:focus\:tw-bg-gray-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(26, 32, 44, var(--tw-bg-opacity));
  }

  .sm\:focus\:tw-bg-blue-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(235, 248, 255, var(--tw-bg-opacity));
  }

  .sm\:focus\:tw-bg-blue-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 227, 248, var(--tw-bg-opacity));
  }

  .sm\:focus\:tw-bg-blue-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(144, 205, 244, var(--tw-bg-opacity));
  }

  .sm\:focus\:tw-bg-blue-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 179, 237, var(--tw-bg-opacity));
  }

  .sm\:focus\:tw-bg-blue-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(66, 153, 225, var(--tw-bg-opacity));
  }

  .sm\:focus\:tw-bg-blue-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 130, 206, var(--tw-bg-opacity));
  }

  .sm\:focus\:tw-bg-blue-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(43, 108, 176, var(--tw-bg-opacity));
  }

  .sm\:focus\:tw-bg-blue-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(44, 82, 130, var(--tw-bg-opacity));
  }

  .sm\:focus\:tw-bg-blue-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(42, 67, 101, var(--tw-bg-opacity));
  }

  .sm\:tw-bg-opacity-0{
    --tw-bg-opacity: 0;
  }

  .sm\:tw-bg-opacity-25{
    --tw-bg-opacity: 0.25;
  }

  .sm\:tw-bg-opacity-50{
    --tw-bg-opacity: 0.5;
  }

  .sm\:tw-bg-opacity-75{
    --tw-bg-opacity: 0.75;
  }

  .sm\:tw-bg-opacity-90{
    --tw-bg-opacity: 0.9;
  }

  .sm\:tw-bg-opacity-100{
    --tw-bg-opacity: 1;
  }

  .sm\:hover\:tw-bg-opacity-0:hover{
    --tw-bg-opacity: 0;
  }

  .sm\:hover\:tw-bg-opacity-25:hover{
    --tw-bg-opacity: 0.25;
  }

  .sm\:hover\:tw-bg-opacity-50:hover{
    --tw-bg-opacity: 0.5;
  }

  .sm\:hover\:tw-bg-opacity-75:hover{
    --tw-bg-opacity: 0.75;
  }

  .sm\:hover\:tw-bg-opacity-90:hover{
    --tw-bg-opacity: 0.9;
  }

  .sm\:hover\:tw-bg-opacity-100:hover{
    --tw-bg-opacity: 1;
  }

  .sm\:focus\:tw-bg-opacity-0:focus{
    --tw-bg-opacity: 0;
  }

  .sm\:focus\:tw-bg-opacity-25:focus{
    --tw-bg-opacity: 0.25;
  }

  .sm\:focus\:tw-bg-opacity-50:focus{
    --tw-bg-opacity: 0.5;
  }

  .sm\:focus\:tw-bg-opacity-75:focus{
    --tw-bg-opacity: 0.75;
  }

  .sm\:focus\:tw-bg-opacity-90:focus{
    --tw-bg-opacity: 0.9;
  }

  .sm\:focus\:tw-bg-opacity-100:focus{
    --tw-bg-opacity: 1;
  }

  .sm\:tw-bg-none{
    background-image: none;
  }

  .sm\:tw-bg-gradient-to-t{
    background-image: linear-gradient(to top, var(--gradient-color-stops));
  }

  .sm\:tw-bg-gradient-to-tr{
    background-image: linear-gradient(to top right, var(--gradient-color-stops));
  }

  .sm\:tw-bg-gradient-to-r{
    background-image: linear-gradient(to right, var(--gradient-color-stops));
  }

  .sm\:tw-bg-gradient-to-br{
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops));
  }

  .sm\:tw-bg-gradient-to-b{
    background-image: linear-gradient(to bottom, var(--gradient-color-stops));
  }

  .sm\:tw-bg-gradient-to-bl{
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops));
  }

  .sm\:tw-bg-gradient-to-l{
    background-image: linear-gradient(to left, var(--gradient-color-stops));
  }

  .sm\:tw-bg-gradient-to-tl{
    background-image: linear-gradient(to top left, var(--gradient-color-stops));
  }

  .sm\:tw-from-transparent{
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .sm\:tw-from-current{
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:tw-from-black{
    --tw-gradient-from: #2c2c2c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 44, 44, 0));
  }

  .sm\:tw-from-white{
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:tw-from-gray-100{
    --tw-gradient-from: #f7fafc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 250, 252, 0));
  }

  .sm\:tw-from-gray-200{
    --tw-gradient-from: #edf2f7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 242, 247, 0));
  }

  .sm\:tw-from-gray-300{
    --tw-gradient-from: #e2e8f0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(226, 232, 240, 0));
  }

  .sm\:tw-from-gray-400{
    --tw-gradient-from: #cbd5e0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(203, 213, 224, 0));
  }

  .sm\:tw-from-gray-500{
    --tw-gradient-from: #a0aec0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(160, 174, 192, 0));
  }

  .sm\:tw-from-gray-600{
    --tw-gradient-from: #718096;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(113, 128, 150, 0));
  }

  .sm\:tw-from-gray-700{
    --tw-gradient-from: #4a5568;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 85, 104, 0));
  }

  .sm\:tw-from-gray-800{
    --tw-gradient-from: #2d3748;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 72, 0));
  }

  .sm\:tw-from-gray-900{
    --tw-gradient-from: #1a202c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 32, 44, 0));
  }

  .sm\:tw-from-blue-100{
    --tw-gradient-from: #ebf8ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(235, 248, 255, 0));
  }

  .sm\:tw-from-blue-200{
    --tw-gradient-from: #bee3f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 227, 248, 0));
  }

  .sm\:tw-from-blue-300{
    --tw-gradient-from: #90cdf4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 205, 244, 0));
  }

  .sm\:tw-from-blue-400{
    --tw-gradient-from: #63b3ed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 179, 237, 0));
  }

  .sm\:tw-from-blue-500{
    --tw-gradient-from: #4299e1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 153, 225, 0));
  }

  .sm\:tw-from-blue-600{
    --tw-gradient-from: #3182ce;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 130, 206, 0));
  }

  .sm\:tw-from-blue-700{
    --tw-gradient-from: #2b6cb0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(43, 108, 176, 0));
  }

  .sm\:tw-from-blue-800{
    --tw-gradient-from: #2c5282;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 82, 130, 0));
  }

  .sm\:tw-from-blue-900{
    --tw-gradient-from: #2a4365;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(42, 67, 101, 0));
  }

  .sm\:hover\:tw-from-transparent:hover{
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .sm\:hover\:tw-from-current:hover{
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:hover\:tw-from-black:hover{
    --tw-gradient-from: #2c2c2c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 44, 44, 0));
  }

  .sm\:hover\:tw-from-white:hover{
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:hover\:tw-from-gray-100:hover{
    --tw-gradient-from: #f7fafc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 250, 252, 0));
  }

  .sm\:hover\:tw-from-gray-200:hover{
    --tw-gradient-from: #edf2f7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 242, 247, 0));
  }

  .sm\:hover\:tw-from-gray-300:hover{
    --tw-gradient-from: #e2e8f0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(226, 232, 240, 0));
  }

  .sm\:hover\:tw-from-gray-400:hover{
    --tw-gradient-from: #cbd5e0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(203, 213, 224, 0));
  }

  .sm\:hover\:tw-from-gray-500:hover{
    --tw-gradient-from: #a0aec0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(160, 174, 192, 0));
  }

  .sm\:hover\:tw-from-gray-600:hover{
    --tw-gradient-from: #718096;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(113, 128, 150, 0));
  }

  .sm\:hover\:tw-from-gray-700:hover{
    --tw-gradient-from: #4a5568;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 85, 104, 0));
  }

  .sm\:hover\:tw-from-gray-800:hover{
    --tw-gradient-from: #2d3748;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 72, 0));
  }

  .sm\:hover\:tw-from-gray-900:hover{
    --tw-gradient-from: #1a202c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 32, 44, 0));
  }

  .sm\:hover\:tw-from-blue-100:hover{
    --tw-gradient-from: #ebf8ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(235, 248, 255, 0));
  }

  .sm\:hover\:tw-from-blue-200:hover{
    --tw-gradient-from: #bee3f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 227, 248, 0));
  }

  .sm\:hover\:tw-from-blue-300:hover{
    --tw-gradient-from: #90cdf4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 205, 244, 0));
  }

  .sm\:hover\:tw-from-blue-400:hover{
    --tw-gradient-from: #63b3ed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 179, 237, 0));
  }

  .sm\:hover\:tw-from-blue-500:hover{
    --tw-gradient-from: #4299e1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 153, 225, 0));
  }

  .sm\:hover\:tw-from-blue-600:hover{
    --tw-gradient-from: #3182ce;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 130, 206, 0));
  }

  .sm\:hover\:tw-from-blue-700:hover{
    --tw-gradient-from: #2b6cb0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(43, 108, 176, 0));
  }

  .sm\:hover\:tw-from-blue-800:hover{
    --tw-gradient-from: #2c5282;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 82, 130, 0));
  }

  .sm\:hover\:tw-from-blue-900:hover{
    --tw-gradient-from: #2a4365;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(42, 67, 101, 0));
  }

  .sm\:focus\:tw-from-transparent:focus{
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .sm\:focus\:tw-from-current:focus{
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:focus\:tw-from-black:focus{
    --tw-gradient-from: #2c2c2c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 44, 44, 0));
  }

  .sm\:focus\:tw-from-white:focus{
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:focus\:tw-from-gray-100:focus{
    --tw-gradient-from: #f7fafc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 250, 252, 0));
  }

  .sm\:focus\:tw-from-gray-200:focus{
    --tw-gradient-from: #edf2f7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 242, 247, 0));
  }

  .sm\:focus\:tw-from-gray-300:focus{
    --tw-gradient-from: #e2e8f0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(226, 232, 240, 0));
  }

  .sm\:focus\:tw-from-gray-400:focus{
    --tw-gradient-from: #cbd5e0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(203, 213, 224, 0));
  }

  .sm\:focus\:tw-from-gray-500:focus{
    --tw-gradient-from: #a0aec0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(160, 174, 192, 0));
  }

  .sm\:focus\:tw-from-gray-600:focus{
    --tw-gradient-from: #718096;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(113, 128, 150, 0));
  }

  .sm\:focus\:tw-from-gray-700:focus{
    --tw-gradient-from: #4a5568;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 85, 104, 0));
  }

  .sm\:focus\:tw-from-gray-800:focus{
    --tw-gradient-from: #2d3748;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 72, 0));
  }

  .sm\:focus\:tw-from-gray-900:focus{
    --tw-gradient-from: #1a202c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 32, 44, 0));
  }

  .sm\:focus\:tw-from-blue-100:focus{
    --tw-gradient-from: #ebf8ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(235, 248, 255, 0));
  }

  .sm\:focus\:tw-from-blue-200:focus{
    --tw-gradient-from: #bee3f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 227, 248, 0));
  }

  .sm\:focus\:tw-from-blue-300:focus{
    --tw-gradient-from: #90cdf4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 205, 244, 0));
  }

  .sm\:focus\:tw-from-blue-400:focus{
    --tw-gradient-from: #63b3ed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 179, 237, 0));
  }

  .sm\:focus\:tw-from-blue-500:focus{
    --tw-gradient-from: #4299e1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 153, 225, 0));
  }

  .sm\:focus\:tw-from-blue-600:focus{
    --tw-gradient-from: #3182ce;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 130, 206, 0));
  }

  .sm\:focus\:tw-from-blue-700:focus{
    --tw-gradient-from: #2b6cb0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(43, 108, 176, 0));
  }

  .sm\:focus\:tw-from-blue-800:focus{
    --tw-gradient-from: #2c5282;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 82, 130, 0));
  }

  .sm\:focus\:tw-from-blue-900:focus{
    --tw-gradient-from: #2a4365;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(42, 67, 101, 0));
  }

  .sm\:tw-via-transparent{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .sm\:tw-via-current{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:tw-via-black{
    --tw-gradient-stops: var(--tw-gradient-from), #2c2c2c, var(--tw-gradient-to, rgba(44, 44, 44, 0));
  }

  .sm\:tw-via-white{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:tw-via-gray-100{
    --tw-gradient-stops: var(--tw-gradient-from), #f7fafc, var(--tw-gradient-to, rgba(247, 250, 252, 0));
  }

  .sm\:tw-via-gray-200{
    --tw-gradient-stops: var(--tw-gradient-from), #edf2f7, var(--tw-gradient-to, rgba(237, 242, 247, 0));
  }

  .sm\:tw-via-gray-300{
    --tw-gradient-stops: var(--tw-gradient-from), #e2e8f0, var(--tw-gradient-to, rgba(226, 232, 240, 0));
  }

  .sm\:tw-via-gray-400{
    --tw-gradient-stops: var(--tw-gradient-from), #cbd5e0, var(--tw-gradient-to, rgba(203, 213, 224, 0));
  }

  .sm\:tw-via-gray-500{
    --tw-gradient-stops: var(--tw-gradient-from), #a0aec0, var(--tw-gradient-to, rgba(160, 174, 192, 0));
  }

  .sm\:tw-via-gray-600{
    --tw-gradient-stops: var(--tw-gradient-from), #718096, var(--tw-gradient-to, rgba(113, 128, 150, 0));
  }

  .sm\:tw-via-gray-700{
    --tw-gradient-stops: var(--tw-gradient-from), #4a5568, var(--tw-gradient-to, rgba(74, 85, 104, 0));
  }

  .sm\:tw-via-gray-800{
    --tw-gradient-stops: var(--tw-gradient-from), #2d3748, var(--tw-gradient-to, rgba(45, 55, 72, 0));
  }

  .sm\:tw-via-gray-900{
    --tw-gradient-stops: var(--tw-gradient-from), #1a202c, var(--tw-gradient-to, rgba(26, 32, 44, 0));
  }

  .sm\:tw-via-blue-100{
    --tw-gradient-stops: var(--tw-gradient-from), #ebf8ff, var(--tw-gradient-to, rgba(235, 248, 255, 0));
  }

  .sm\:tw-via-blue-200{
    --tw-gradient-stops: var(--tw-gradient-from), #bee3f8, var(--tw-gradient-to, rgba(190, 227, 248, 0));
  }

  .sm\:tw-via-blue-300{
    --tw-gradient-stops: var(--tw-gradient-from), #90cdf4, var(--tw-gradient-to, rgba(144, 205, 244, 0));
  }

  .sm\:tw-via-blue-400{
    --tw-gradient-stops: var(--tw-gradient-from), #63b3ed, var(--tw-gradient-to, rgba(99, 179, 237, 0));
  }

  .sm\:tw-via-blue-500{
    --tw-gradient-stops: var(--tw-gradient-from), #4299e1, var(--tw-gradient-to, rgba(66, 153, 225, 0));
  }

  .sm\:tw-via-blue-600{
    --tw-gradient-stops: var(--tw-gradient-from), #3182ce, var(--tw-gradient-to, rgba(49, 130, 206, 0));
  }

  .sm\:tw-via-blue-700{
    --tw-gradient-stops: var(--tw-gradient-from), #2b6cb0, var(--tw-gradient-to, rgba(43, 108, 176, 0));
  }

  .sm\:tw-via-blue-800{
    --tw-gradient-stops: var(--tw-gradient-from), #2c5282, var(--tw-gradient-to, rgba(44, 82, 130, 0));
  }

  .sm\:tw-via-blue-900{
    --tw-gradient-stops: var(--tw-gradient-from), #2a4365, var(--tw-gradient-to, rgba(42, 67, 101, 0));
  }

  .sm\:hover\:tw-via-transparent:hover{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .sm\:hover\:tw-via-current:hover{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:hover\:tw-via-black:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2c2c2c, var(--tw-gradient-to, rgba(44, 44, 44, 0));
  }

  .sm\:hover\:tw-via-white:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:hover\:tw-via-gray-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f7fafc, var(--tw-gradient-to, rgba(247, 250, 252, 0));
  }

  .sm\:hover\:tw-via-gray-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #edf2f7, var(--tw-gradient-to, rgba(237, 242, 247, 0));
  }

  .sm\:hover\:tw-via-gray-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e2e8f0, var(--tw-gradient-to, rgba(226, 232, 240, 0));
  }

  .sm\:hover\:tw-via-gray-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #cbd5e0, var(--tw-gradient-to, rgba(203, 213, 224, 0));
  }

  .sm\:hover\:tw-via-gray-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a0aec0, var(--tw-gradient-to, rgba(160, 174, 192, 0));
  }

  .sm\:hover\:tw-via-gray-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #718096, var(--tw-gradient-to, rgba(113, 128, 150, 0));
  }

  .sm\:hover\:tw-via-gray-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4a5568, var(--tw-gradient-to, rgba(74, 85, 104, 0));
  }

  .sm\:hover\:tw-via-gray-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2d3748, var(--tw-gradient-to, rgba(45, 55, 72, 0));
  }

  .sm\:hover\:tw-via-gray-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1a202c, var(--tw-gradient-to, rgba(26, 32, 44, 0));
  }

  .sm\:hover\:tw-via-blue-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ebf8ff, var(--tw-gradient-to, rgba(235, 248, 255, 0));
  }

  .sm\:hover\:tw-via-blue-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #bee3f8, var(--tw-gradient-to, rgba(190, 227, 248, 0));
  }

  .sm\:hover\:tw-via-blue-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #90cdf4, var(--tw-gradient-to, rgba(144, 205, 244, 0));
  }

  .sm\:hover\:tw-via-blue-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #63b3ed, var(--tw-gradient-to, rgba(99, 179, 237, 0));
  }

  .sm\:hover\:tw-via-blue-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4299e1, var(--tw-gradient-to, rgba(66, 153, 225, 0));
  }

  .sm\:hover\:tw-via-blue-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3182ce, var(--tw-gradient-to, rgba(49, 130, 206, 0));
  }

  .sm\:hover\:tw-via-blue-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2b6cb0, var(--tw-gradient-to, rgba(43, 108, 176, 0));
  }

  .sm\:hover\:tw-via-blue-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2c5282, var(--tw-gradient-to, rgba(44, 82, 130, 0));
  }

  .sm\:hover\:tw-via-blue-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2a4365, var(--tw-gradient-to, rgba(42, 67, 101, 0));
  }

  .sm\:focus\:tw-via-transparent:focus{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .sm\:focus\:tw-via-current:focus{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:focus\:tw-via-black:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2c2c2c, var(--tw-gradient-to, rgba(44, 44, 44, 0));
  }

  .sm\:focus\:tw-via-white:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:focus\:tw-via-gray-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f7fafc, var(--tw-gradient-to, rgba(247, 250, 252, 0));
  }

  .sm\:focus\:tw-via-gray-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #edf2f7, var(--tw-gradient-to, rgba(237, 242, 247, 0));
  }

  .sm\:focus\:tw-via-gray-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e2e8f0, var(--tw-gradient-to, rgba(226, 232, 240, 0));
  }

  .sm\:focus\:tw-via-gray-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #cbd5e0, var(--tw-gradient-to, rgba(203, 213, 224, 0));
  }

  .sm\:focus\:tw-via-gray-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a0aec0, var(--tw-gradient-to, rgba(160, 174, 192, 0));
  }

  .sm\:focus\:tw-via-gray-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #718096, var(--tw-gradient-to, rgba(113, 128, 150, 0));
  }

  .sm\:focus\:tw-via-gray-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4a5568, var(--tw-gradient-to, rgba(74, 85, 104, 0));
  }

  .sm\:focus\:tw-via-gray-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2d3748, var(--tw-gradient-to, rgba(45, 55, 72, 0));
  }

  .sm\:focus\:tw-via-gray-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1a202c, var(--tw-gradient-to, rgba(26, 32, 44, 0));
  }

  .sm\:focus\:tw-via-blue-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ebf8ff, var(--tw-gradient-to, rgba(235, 248, 255, 0));
  }

  .sm\:focus\:tw-via-blue-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #bee3f8, var(--tw-gradient-to, rgba(190, 227, 248, 0));
  }

  .sm\:focus\:tw-via-blue-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #90cdf4, var(--tw-gradient-to, rgba(144, 205, 244, 0));
  }

  .sm\:focus\:tw-via-blue-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #63b3ed, var(--tw-gradient-to, rgba(99, 179, 237, 0));
  }

  .sm\:focus\:tw-via-blue-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4299e1, var(--tw-gradient-to, rgba(66, 153, 225, 0));
  }

  .sm\:focus\:tw-via-blue-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3182ce, var(--tw-gradient-to, rgba(49, 130, 206, 0));
  }

  .sm\:focus\:tw-via-blue-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2b6cb0, var(--tw-gradient-to, rgba(43, 108, 176, 0));
  }

  .sm\:focus\:tw-via-blue-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2c5282, var(--tw-gradient-to, rgba(44, 82, 130, 0));
  }

  .sm\:focus\:tw-via-blue-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2a4365, var(--tw-gradient-to, rgba(42, 67, 101, 0));
  }

  .sm\:tw-to-transparent{
    --tw-gradient-to: transparent;
  }

  .sm\:tw-to-current{
    --tw-gradient-to: currentColor;
  }

  .sm\:tw-to-black{
    --tw-gradient-to: #2c2c2c;
  }

  .sm\:tw-to-white{
    --tw-gradient-to: #fff;
  }

  .sm\:tw-to-gray-100{
    --tw-gradient-to: #f7fafc;
  }

  .sm\:tw-to-gray-200{
    --tw-gradient-to: #edf2f7;
  }

  .sm\:tw-to-gray-300{
    --tw-gradient-to: #e2e8f0;
  }

  .sm\:tw-to-gray-400{
    --tw-gradient-to: #cbd5e0;
  }

  .sm\:tw-to-gray-500{
    --tw-gradient-to: #a0aec0;
  }

  .sm\:tw-to-gray-600{
    --tw-gradient-to: #718096;
  }

  .sm\:tw-to-gray-700{
    --tw-gradient-to: #4a5568;
  }

  .sm\:tw-to-gray-800{
    --tw-gradient-to: #2d3748;
  }

  .sm\:tw-to-gray-900{
    --tw-gradient-to: #1a202c;
  }

  .sm\:tw-to-blue-100{
    --tw-gradient-to: #ebf8ff;
  }

  .sm\:tw-to-blue-200{
    --tw-gradient-to: #bee3f8;
  }

  .sm\:tw-to-blue-300{
    --tw-gradient-to: #90cdf4;
  }

  .sm\:tw-to-blue-400{
    --tw-gradient-to: #63b3ed;
  }

  .sm\:tw-to-blue-500{
    --tw-gradient-to: #4299e1;
  }

  .sm\:tw-to-blue-600{
    --tw-gradient-to: #3182ce;
  }

  .sm\:tw-to-blue-700{
    --tw-gradient-to: #2b6cb0;
  }

  .sm\:tw-to-blue-800{
    --tw-gradient-to: #2c5282;
  }

  .sm\:tw-to-blue-900{
    --tw-gradient-to: #2a4365;
  }

  .sm\:hover\:tw-to-transparent:hover{
    --tw-gradient-to: transparent;
  }

  .sm\:hover\:tw-to-current:hover{
    --tw-gradient-to: currentColor;
  }

  .sm\:hover\:tw-to-black:hover{
    --tw-gradient-to: #2c2c2c;
  }

  .sm\:hover\:tw-to-white:hover{
    --tw-gradient-to: #fff;
  }

  .sm\:hover\:tw-to-gray-100:hover{
    --tw-gradient-to: #f7fafc;
  }

  .sm\:hover\:tw-to-gray-200:hover{
    --tw-gradient-to: #edf2f7;
  }

  .sm\:hover\:tw-to-gray-300:hover{
    --tw-gradient-to: #e2e8f0;
  }

  .sm\:hover\:tw-to-gray-400:hover{
    --tw-gradient-to: #cbd5e0;
  }

  .sm\:hover\:tw-to-gray-500:hover{
    --tw-gradient-to: #a0aec0;
  }

  .sm\:hover\:tw-to-gray-600:hover{
    --tw-gradient-to: #718096;
  }

  .sm\:hover\:tw-to-gray-700:hover{
    --tw-gradient-to: #4a5568;
  }

  .sm\:hover\:tw-to-gray-800:hover{
    --tw-gradient-to: #2d3748;
  }

  .sm\:hover\:tw-to-gray-900:hover{
    --tw-gradient-to: #1a202c;
  }

  .sm\:hover\:tw-to-blue-100:hover{
    --tw-gradient-to: #ebf8ff;
  }

  .sm\:hover\:tw-to-blue-200:hover{
    --tw-gradient-to: #bee3f8;
  }

  .sm\:hover\:tw-to-blue-300:hover{
    --tw-gradient-to: #90cdf4;
  }

  .sm\:hover\:tw-to-blue-400:hover{
    --tw-gradient-to: #63b3ed;
  }

  .sm\:hover\:tw-to-blue-500:hover{
    --tw-gradient-to: #4299e1;
  }

  .sm\:hover\:tw-to-blue-600:hover{
    --tw-gradient-to: #3182ce;
  }

  .sm\:hover\:tw-to-blue-700:hover{
    --tw-gradient-to: #2b6cb0;
  }

  .sm\:hover\:tw-to-blue-800:hover{
    --tw-gradient-to: #2c5282;
  }

  .sm\:hover\:tw-to-blue-900:hover{
    --tw-gradient-to: #2a4365;
  }

  .sm\:focus\:tw-to-transparent:focus{
    --tw-gradient-to: transparent;
  }

  .sm\:focus\:tw-to-current:focus{
    --tw-gradient-to: currentColor;
  }

  .sm\:focus\:tw-to-black:focus{
    --tw-gradient-to: #2c2c2c;
  }

  .sm\:focus\:tw-to-white:focus{
    --tw-gradient-to: #fff;
  }

  .sm\:focus\:tw-to-gray-100:focus{
    --tw-gradient-to: #f7fafc;
  }

  .sm\:focus\:tw-to-gray-200:focus{
    --tw-gradient-to: #edf2f7;
  }

  .sm\:focus\:tw-to-gray-300:focus{
    --tw-gradient-to: #e2e8f0;
  }

  .sm\:focus\:tw-to-gray-400:focus{
    --tw-gradient-to: #cbd5e0;
  }

  .sm\:focus\:tw-to-gray-500:focus{
    --tw-gradient-to: #a0aec0;
  }

  .sm\:focus\:tw-to-gray-600:focus{
    --tw-gradient-to: #718096;
  }

  .sm\:focus\:tw-to-gray-700:focus{
    --tw-gradient-to: #4a5568;
  }

  .sm\:focus\:tw-to-gray-800:focus{
    --tw-gradient-to: #2d3748;
  }

  .sm\:focus\:tw-to-gray-900:focus{
    --tw-gradient-to: #1a202c;
  }

  .sm\:focus\:tw-to-blue-100:focus{
    --tw-gradient-to: #ebf8ff;
  }

  .sm\:focus\:tw-to-blue-200:focus{
    --tw-gradient-to: #bee3f8;
  }

  .sm\:focus\:tw-to-blue-300:focus{
    --tw-gradient-to: #90cdf4;
  }

  .sm\:focus\:tw-to-blue-400:focus{
    --tw-gradient-to: #63b3ed;
  }

  .sm\:focus\:tw-to-blue-500:focus{
    --tw-gradient-to: #4299e1;
  }

  .sm\:focus\:tw-to-blue-600:focus{
    --tw-gradient-to: #3182ce;
  }

  .sm\:focus\:tw-to-blue-700:focus{
    --tw-gradient-to: #2b6cb0;
  }

  .sm\:focus\:tw-to-blue-800:focus{
    --tw-gradient-to: #2c5282;
  }

  .sm\:focus\:tw-to-blue-900:focus{
    --tw-gradient-to: #2a4365;
  }

  .sm\:tw-decoration-slice{
    -webkit-box-decoration-break: slice;
    box-decoration-break: slice;
  }

  .sm\:tw-decoration-clone{
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  .sm\:tw-bg-auto{
    background-size: auto;
  }

  .sm\:tw-bg-cover{
    background-size: cover;
  }

  .sm\:tw-bg-contain{
    background-size: contain;
  }

  .sm\:tw-bg-fixed{
    background-attachment: fixed;
  }

  .sm\:tw-bg-local{
    background-attachment: local;
  }

  .sm\:tw-bg-scroll{
    background-attachment: scroll;
  }

  .sm\:tw-bg-clip-border{
    background-clip: border-box;
  }

  .sm\:tw-bg-clip-padding{
    background-clip: padding-box;
  }

  .sm\:tw-bg-clip-content{
    background-clip: content-box;
  }

  .sm\:tw-bg-clip-text{
    -webkit-background-clip: text;
    background-clip: text;
  }

  .sm\:tw-bg-bottom{
    background-position: bottom;
  }

  .sm\:tw-bg-center{
    background-position: center;
  }

  .sm\:tw-bg-left{
    background-position: left;
  }

  .sm\:tw-bg-left-bottom{
    background-position: left bottom;
  }

  .sm\:tw-bg-left-top{
    background-position: left top;
  }

  .sm\:tw-bg-right{
    background-position: right;
  }

  .sm\:tw-bg-right-bottom{
    background-position: right bottom;
  }

  .sm\:tw-bg-right-top{
    background-position: right top;
  }

  .sm\:tw-bg-top{
    background-position: top;
  }

  .sm\:tw-bg-repeat{
    background-repeat: repeat;
  }

  .sm\:tw-bg-no-repeat{
    background-repeat: no-repeat;
  }

  .sm\:tw-bg-repeat-x{
    background-repeat: repeat-x;
  }

  .sm\:tw-bg-repeat-y{
    background-repeat: repeat-y;
  }

  .sm\:tw-bg-repeat-round{
    background-repeat: round;
  }

  .sm\:tw-bg-repeat-space{
    background-repeat: space;
  }

  .sm\:tw-bg-origin-border{
    background-origin: border-box;
  }

  .sm\:tw-bg-origin-padding{
    background-origin: padding-box;
  }

  .sm\:tw-bg-origin-content{
    background-origin: content-box;
  }

  .sm\:tw-fill-current{
    fill: currentColor;
  }

  .sm\:tw-stroke-current{
    stroke: currentColor;
  }

  .sm\:tw-stroke-0{
    stroke-width: 0;
  }

  .sm\:tw-stroke-1{
    stroke-width: 1;
  }

  .sm\:tw-stroke-2{
    stroke-width: 2;
  }

  .sm\:tw-object-contain{
    object-fit: contain;
  }

  .sm\:tw-object-cover{
    object-fit: cover;
  }

  .sm\:tw-object-fill{
    object-fit: fill;
  }

  .sm\:tw-object-none{
    object-fit: none;
  }

  .sm\:tw-object-scale-down{
    object-fit: scale-down;
  }

  .sm\:tw-object-bottom{
    object-position: bottom;
  }

  .sm\:tw-object-center{
    object-position: center;
  }

  .sm\:tw-object-left{
    object-position: left;
  }

  .sm\:tw-object-left-bottom{
    object-position: left bottom;
  }

  .sm\:tw-object-left-top{
    object-position: left top;
  }

  .sm\:tw-object-right{
    object-position: right;
  }

  .sm\:tw-object-right-bottom{
    object-position: right bottom;
  }

  .sm\:tw-object-right-top{
    object-position: right top;
  }

  .sm\:tw-object-top{
    object-position: top;
  }

  .sm\:tw-p-0{
    padding: 0;
  }

  .sm\:tw-p-1{
    padding: 0.25rem;
  }

  .sm\:tw-p-2{
    padding: 0.5rem;
  }

  .sm\:tw-p-3{
    padding: 0.75rem;
  }

  .sm\:tw-p-4{
    padding: 1rem;
  }

  .sm\:tw-p-5{
    padding: 1.25rem;
  }

  .sm\:tw-p-6{
    padding: 1.5rem;
  }

  .sm\:tw-p-8{
    padding: 2rem;
  }

  .sm\:tw-p-10{
    padding: 2.5rem;
  }

  .sm\:tw-p-12{
    padding: 3rem;
  }

  .sm\:tw-p-16{
    padding: 4rem;
  }

  .sm\:tw-p-20{
    padding: 5rem;
  }

  .sm\:tw-p-24{
    padding: 6rem;
  }

  .sm\:tw-p-32{
    padding: 8rem;
  }

  .sm\:tw-p-40{
    padding: 10rem;
  }

  .sm\:tw-p-48{
    padding: 12rem;
  }

  .sm\:tw-p-56{
    padding: 14rem;
  }

  .sm\:tw-p-64{
    padding: 16rem;
  }

  .sm\:tw-p-px{
    padding: 1px;
  }

  .sm\:tw-px-0{
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:tw-px-1{
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .sm\:tw-px-2{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .sm\:tw-px-3{
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .sm\:tw-px-4{
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:tw-px-5{
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .sm\:tw-px-6{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:tw-px-8{
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:tw-px-10{
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:tw-px-12{
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .sm\:tw-px-16{
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .sm\:tw-px-20{
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .sm\:tw-px-24{
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .sm\:tw-px-32{
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .sm\:tw-px-40{
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .sm\:tw-px-48{
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .sm\:tw-px-56{
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .sm\:tw-px-64{
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .sm\:tw-px-px{
    padding-left: 1px;
    padding-right: 1px;
  }

  .sm\:tw-py-0{
    padding-top: 0;
    padding-bottom: 0;
  }

  .sm\:tw-py-1{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .sm\:tw-py-2{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .sm\:tw-py-3{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .sm\:tw-py-4{
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .sm\:tw-py-5{
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .sm\:tw-py-6{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .sm\:tw-py-8{
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .sm\:tw-py-10{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .sm\:tw-py-12{
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .sm\:tw-py-16{
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .sm\:tw-py-20{
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .sm\:tw-py-24{
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .sm\:tw-py-32{
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .sm\:tw-py-40{
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .sm\:tw-py-48{
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .sm\:tw-py-56{
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .sm\:tw-py-64{
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .sm\:tw-py-px{
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .sm\:tw-pt-0{
    padding-top: 0;
  }

  .sm\:tw-pt-1{
    padding-top: 0.25rem;
  }

  .sm\:tw-pt-2{
    padding-top: 0.5rem;
  }

  .sm\:tw-pt-3{
    padding-top: 0.75rem;
  }

  .sm\:tw-pt-4{
    padding-top: 1rem;
  }

  .sm\:tw-pt-5{
    padding-top: 1.25rem;
  }

  .sm\:tw-pt-6{
    padding-top: 1.5rem;
  }

  .sm\:tw-pt-8{
    padding-top: 2rem;
  }

  .sm\:tw-pt-10{
    padding-top: 2.5rem;
  }

  .sm\:tw-pt-12{
    padding-top: 3rem;
  }

  .sm\:tw-pt-16{
    padding-top: 4rem;
  }

  .sm\:tw-pt-20{
    padding-top: 5rem;
  }

  .sm\:tw-pt-24{
    padding-top: 6rem;
  }

  .sm\:tw-pt-32{
    padding-top: 8rem;
  }

  .sm\:tw-pt-40{
    padding-top: 10rem;
  }

  .sm\:tw-pt-48{
    padding-top: 12rem;
  }

  .sm\:tw-pt-56{
    padding-top: 14rem;
  }

  .sm\:tw-pt-64{
    padding-top: 16rem;
  }

  .sm\:tw-pt-px{
    padding-top: 1px;
  }

  .sm\:tw-pr-0{
    padding-right: 0;
  }

  .sm\:tw-pr-1{
    padding-right: 0.25rem;
  }

  .sm\:tw-pr-2{
    padding-right: 0.5rem;
  }

  .sm\:tw-pr-3{
    padding-right: 0.75rem;
  }

  .sm\:tw-pr-4{
    padding-right: 1rem;
  }

  .sm\:tw-pr-5{
    padding-right: 1.25rem;
  }

  .sm\:tw-pr-6{
    padding-right: 1.5rem;
  }

  .sm\:tw-pr-8{
    padding-right: 2rem;
  }

  .sm\:tw-pr-10{
    padding-right: 2.5rem;
  }

  .sm\:tw-pr-12{
    padding-right: 3rem;
  }

  .sm\:tw-pr-16{
    padding-right: 4rem;
  }

  .sm\:tw-pr-20{
    padding-right: 5rem;
  }

  .sm\:tw-pr-24{
    padding-right: 6rem;
  }

  .sm\:tw-pr-32{
    padding-right: 8rem;
  }

  .sm\:tw-pr-40{
    padding-right: 10rem;
  }

  .sm\:tw-pr-48{
    padding-right: 12rem;
  }

  .sm\:tw-pr-56{
    padding-right: 14rem;
  }

  .sm\:tw-pr-64{
    padding-right: 16rem;
  }

  .sm\:tw-pr-px{
    padding-right: 1px;
  }

  .sm\:tw-pb-0{
    padding-bottom: 0;
  }

  .sm\:tw-pb-1{
    padding-bottom: 0.25rem;
  }

  .sm\:tw-pb-2{
    padding-bottom: 0.5rem;
  }

  .sm\:tw-pb-3{
    padding-bottom: 0.75rem;
  }

  .sm\:tw-pb-4{
    padding-bottom: 1rem;
  }

  .sm\:tw-pb-5{
    padding-bottom: 1.25rem;
  }

  .sm\:tw-pb-6{
    padding-bottom: 1.5rem;
  }

  .sm\:tw-pb-8{
    padding-bottom: 2rem;
  }

  .sm\:tw-pb-10{
    padding-bottom: 2.5rem;
  }

  .sm\:tw-pb-12{
    padding-bottom: 3rem;
  }

  .sm\:tw-pb-16{
    padding-bottom: 4rem;
  }

  .sm\:tw-pb-20{
    padding-bottom: 5rem;
  }

  .sm\:tw-pb-24{
    padding-bottom: 6rem;
  }

  .sm\:tw-pb-32{
    padding-bottom: 8rem;
  }

  .sm\:tw-pb-40{
    padding-bottom: 10rem;
  }

  .sm\:tw-pb-48{
    padding-bottom: 12rem;
  }

  .sm\:tw-pb-56{
    padding-bottom: 14rem;
  }

  .sm\:tw-pb-64{
    padding-bottom: 16rem;
  }

  .sm\:tw-pb-px{
    padding-bottom: 1px;
  }

  .sm\:tw-pl-0{
    padding-left: 0;
  }

  .sm\:tw-pl-1{
    padding-left: 0.25rem;
  }

  .sm\:tw-pl-2{
    padding-left: 0.5rem;
  }

  .sm\:tw-pl-3{
    padding-left: 0.75rem;
  }

  .sm\:tw-pl-4{
    padding-left: 1rem;
  }

  .sm\:tw-pl-5{
    padding-left: 1.25rem;
  }

  .sm\:tw-pl-6{
    padding-left: 1.5rem;
  }

  .sm\:tw-pl-8{
    padding-left: 2rem;
  }

  .sm\:tw-pl-10{
    padding-left: 2.5rem;
  }

  .sm\:tw-pl-12{
    padding-left: 3rem;
  }

  .sm\:tw-pl-16{
    padding-left: 4rem;
  }

  .sm\:tw-pl-20{
    padding-left: 5rem;
  }

  .sm\:tw-pl-24{
    padding-left: 6rem;
  }

  .sm\:tw-pl-32{
    padding-left: 8rem;
  }

  .sm\:tw-pl-40{
    padding-left: 10rem;
  }

  .sm\:tw-pl-48{
    padding-left: 12rem;
  }

  .sm\:tw-pl-56{
    padding-left: 14rem;
  }

  .sm\:tw-pl-64{
    padding-left: 16rem;
  }

  .sm\:tw-pl-px{
    padding-left: 1px;
  }

  .sm\:tw-text-left{
    text-align: left;
  }

  .sm\:tw-text-center{
    text-align: center;
  }

  .sm\:tw-text-right{
    text-align: right;
  }

  .sm\:tw-text-justify{
    text-align: justify;
  }

  .sm\:tw-align-baseline{
    vertical-align: baseline;
  }

  .sm\:tw-align-top{
    vertical-align: top;
  }

  .sm\:tw-align-middle{
    vertical-align: middle;
  }

  .sm\:tw-align-bottom{
    vertical-align: bottom;
  }

  .sm\:tw-align-text-top{
    vertical-align: text-top;
  }

  .sm\:tw-align-text-bottom{
    vertical-align: text-bottom;
  }

  .sm\:tw-font-sans{
    font-family: "Gotham SSm A", "Gotham SSm B", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  .sm\:tw-font-serif{
    font-family: Georgia, Cambria, "Times New Roman", Times, serif;
  }

  .sm\:tw-font-mono{
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

  .sm\:tw-text-xs{
    font-size: 0.75rem;
  }

  .sm\:tw-text-sm{
    font-size: 0.875rem;
  }

  .sm\:tw-text-base{
    font-size: 1rem;
  }

  .sm\:tw-text-lg{
    font-size: 1.125rem;
  }

  .sm\:tw-text-xl{
    font-size: 1.25rem;
  }

  .sm\:tw-text-2xl{
    font-size: 1.5rem;
  }

  .sm\:tw-text-3xl{
    font-size: 1.875rem;
  }

  .sm\:tw-text-4xl{
    font-size: 2.25rem;
  }

  .sm\:tw-text-5xl{
    font-size: 3rem;
  }

  .sm\:tw-text-6xl{
    font-size: 4rem;
  }

  .sm\:tw-font-hairline{
    font-weight: 100;
  }

  .sm\:tw-font-thin{
    font-weight: 200;
  }

  .sm\:tw-font-light{
    font-weight: 300;
  }

  .sm\:tw-font-normal{
    font-weight: 400;
  }

  .sm\:tw-font-medium{
    font-weight: 500;
  }

  .sm\:tw-font-semibold{
    font-weight: 600;
  }

  .sm\:tw-font-bold{
    font-weight: 700;
  }

  .sm\:tw-font-extrabold{
    font-weight: 800;
  }

  .sm\:tw-font-black{
    font-weight: 900;
  }

  .sm\:hover\:tw-font-hairline:hover{
    font-weight: 100;
  }

  .sm\:hover\:tw-font-thin:hover{
    font-weight: 200;
  }

  .sm\:hover\:tw-font-light:hover{
    font-weight: 300;
  }

  .sm\:hover\:tw-font-normal:hover{
    font-weight: 400;
  }

  .sm\:hover\:tw-font-medium:hover{
    font-weight: 500;
  }

  .sm\:hover\:tw-font-semibold:hover{
    font-weight: 600;
  }

  .sm\:hover\:tw-font-bold:hover{
    font-weight: 700;
  }

  .sm\:hover\:tw-font-extrabold:hover{
    font-weight: 800;
  }

  .sm\:hover\:tw-font-black:hover{
    font-weight: 900;
  }

  .sm\:focus\:tw-font-hairline:focus{
    font-weight: 100;
  }

  .sm\:focus\:tw-font-thin:focus{
    font-weight: 200;
  }

  .sm\:focus\:tw-font-light:focus{
    font-weight: 300;
  }

  .sm\:focus\:tw-font-normal:focus{
    font-weight: 400;
  }

  .sm\:focus\:tw-font-medium:focus{
    font-weight: 500;
  }

  .sm\:focus\:tw-font-semibold:focus{
    font-weight: 600;
  }

  .sm\:focus\:tw-font-bold:focus{
    font-weight: 700;
  }

  .sm\:focus\:tw-font-extrabold:focus{
    font-weight: 800;
  }

  .sm\:focus\:tw-font-black:focus{
    font-weight: 900;
  }

  .sm\:tw-uppercase{
    text-transform: uppercase;
  }

  .sm\:tw-lowercase{
    text-transform: lowercase;
  }

  .sm\:tw-capitalize{
    text-transform: capitalize;
  }

  .sm\:tw-normal-case{
    text-transform: none;
  }

  .sm\:tw-italic{
    font-style: italic;
  }

  .sm\:tw-not-italic{
    font-style: normal;
  }

  .sm\:tw-ordinal, .sm\:tw-slashed-zero, .sm\:tw-lining-nums, .sm\:tw-oldstyle-nums, .sm\:tw-proportional-nums, .sm\:tw-tabular-nums, .sm\:tw-diagonal-fractions, .sm\:tw-stacked-fractions{
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .sm\:tw-normal-nums{
    font-variant-numeric: normal;
  }

  .sm\:tw-ordinal{
    --tw-ordinal: ordinal;
  }

  .sm\:tw-slashed-zero{
    --tw-slashed-zero: slashed-zero;
  }

  .sm\:tw-lining-nums{
    --tw-numeric-figure: lining-nums;
  }

  .sm\:tw-oldstyle-nums{
    --tw-numeric-figure: oldstyle-nums;
  }

  .sm\:tw-proportional-nums{
    --tw-numeric-spacing: proportional-nums;
  }

  .sm\:tw-tabular-nums{
    --tw-numeric-spacing: tabular-nums;
  }

  .sm\:tw-diagonal-fractions{
    --tw-numeric-fraction: diagonal-fractions;
  }

  .sm\:tw-stacked-fractions{
    --tw-numeric-fraction: stacked-fractions;
  }

  .sm\:tw-leading-3{
    line-height: .75rem;
  }

  .sm\:tw-leading-4{
    line-height: 1rem;
  }

  .sm\:tw-leading-5{
    line-height: 1.25rem;
  }

  .sm\:tw-leading-6{
    line-height: 1.5rem;
  }

  .sm\:tw-leading-7{
    line-height: 1.75rem;
  }

  .sm\:tw-leading-8{
    line-height: 2rem;
  }

  .sm\:tw-leading-9{
    line-height: 2.25rem;
  }

  .sm\:tw-leading-10{
    line-height: 2.5rem;
  }

  .sm\:tw-leading-none{
    line-height: 1;
  }

  .sm\:tw-leading-tight{
    line-height: 1.25;
  }

  .sm\:tw-leading-snug{
    line-height: 1.375;
  }

  .sm\:tw-leading-normal{
    line-height: 1.5;
  }

  .sm\:tw-leading-relaxed{
    line-height: 1.625;
  }

  .sm\:tw-leading-loose{
    line-height: 2;
  }

  .sm\:tw-tracking-tighter{
    letter-spacing: -0.05em;
  }

  .sm\:tw-tracking-tight{
    letter-spacing: -0.025em;
  }

  .sm\:tw-tracking-normal{
    letter-spacing: 0;
  }

  .sm\:tw-tracking-wide{
    letter-spacing: 0.025em;
  }

  .sm\:tw-tracking-wider{
    letter-spacing: 0.05em;
  }

  .sm\:tw-tracking-widest{
    letter-spacing: 0.1em;
  }

  .sm\:tw-text-transparent{
    color: transparent;
  }

  .sm\:tw-text-current{
    color: currentColor;
  }

  .sm\:tw-text-black{
    --tw-text-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-text-opacity));
  }

  .sm\:tw-text-white{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .sm\:tw-text-gray-100{
    --tw-text-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-text-opacity));
  }

  .sm\:tw-text-gray-200{
    --tw-text-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-text-opacity));
  }

  .sm\:tw-text-gray-300{
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));
  }

  .sm\:tw-text-gray-400{
    --tw-text-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-text-opacity));
  }

  .sm\:tw-text-gray-500{
    --tw-text-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-text-opacity));
  }

  .sm\:tw-text-gray-600{
    --tw-text-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-text-opacity));
  }

  .sm\:tw-text-gray-700{
    --tw-text-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-text-opacity));
  }

  .sm\:tw-text-gray-800{
    --tw-text-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-text-opacity));
  }

  .sm\:tw-text-gray-900{
    --tw-text-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-text-opacity));
  }

  .sm\:tw-text-blue-100{
    --tw-text-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-text-opacity));
  }

  .sm\:tw-text-blue-200{
    --tw-text-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-text-opacity));
  }

  .sm\:tw-text-blue-300{
    --tw-text-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-text-opacity));
  }

  .sm\:tw-text-blue-400{
    --tw-text-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-text-opacity));
  }

  .sm\:tw-text-blue-500{
    --tw-text-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-text-opacity));
  }

  .sm\:tw-text-blue-600{
    --tw-text-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-text-opacity));
  }

  .sm\:tw-text-blue-700{
    --tw-text-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-text-opacity));
  }

  .sm\:tw-text-blue-800{
    --tw-text-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-text-opacity));
  }

  .sm\:tw-text-blue-900{
    --tw-text-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-text-opacity));
  }

  .sm\:hover\:tw-text-transparent:hover{
    color: transparent;
  }

  .sm\:hover\:tw-text-current:hover{
    color: currentColor;
  }

  .sm\:hover\:tw-text-black:hover{
    --tw-text-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-text-opacity));
  }

  .sm\:hover\:tw-text-white:hover{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .sm\:hover\:tw-text-gray-100:hover{
    --tw-text-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-text-opacity));
  }

  .sm\:hover\:tw-text-gray-200:hover{
    --tw-text-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-text-opacity));
  }

  .sm\:hover\:tw-text-gray-300:hover{
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));
  }

  .sm\:hover\:tw-text-gray-400:hover{
    --tw-text-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-text-opacity));
  }

  .sm\:hover\:tw-text-gray-500:hover{
    --tw-text-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-text-opacity));
  }

  .sm\:hover\:tw-text-gray-600:hover{
    --tw-text-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-text-opacity));
  }

  .sm\:hover\:tw-text-gray-700:hover{
    --tw-text-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-text-opacity));
  }

  .sm\:hover\:tw-text-gray-800:hover{
    --tw-text-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-text-opacity));
  }

  .sm\:hover\:tw-text-gray-900:hover{
    --tw-text-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-text-opacity));
  }

  .sm\:hover\:tw-text-blue-100:hover{
    --tw-text-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-text-opacity));
  }

  .sm\:hover\:tw-text-blue-200:hover{
    --tw-text-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-text-opacity));
  }

  .sm\:hover\:tw-text-blue-300:hover{
    --tw-text-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-text-opacity));
  }

  .sm\:hover\:tw-text-blue-400:hover{
    --tw-text-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-text-opacity));
  }

  .sm\:hover\:tw-text-blue-500:hover{
    --tw-text-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-text-opacity));
  }

  .sm\:hover\:tw-text-blue-600:hover{
    --tw-text-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-text-opacity));
  }

  .sm\:hover\:tw-text-blue-700:hover{
    --tw-text-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-text-opacity));
  }

  .sm\:hover\:tw-text-blue-800:hover{
    --tw-text-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-text-opacity));
  }

  .sm\:hover\:tw-text-blue-900:hover{
    --tw-text-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-text-opacity));
  }

  .sm\:focus\:tw-text-transparent:focus{
    color: transparent;
  }

  .sm\:focus\:tw-text-current:focus{
    color: currentColor;
  }

  .sm\:focus\:tw-text-black:focus{
    --tw-text-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-text-opacity));
  }

  .sm\:focus\:tw-text-white:focus{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .sm\:focus\:tw-text-gray-100:focus{
    --tw-text-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-text-opacity));
  }

  .sm\:focus\:tw-text-gray-200:focus{
    --tw-text-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-text-opacity));
  }

  .sm\:focus\:tw-text-gray-300:focus{
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));
  }

  .sm\:focus\:tw-text-gray-400:focus{
    --tw-text-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-text-opacity));
  }

  .sm\:focus\:tw-text-gray-500:focus{
    --tw-text-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-text-opacity));
  }

  .sm\:focus\:tw-text-gray-600:focus{
    --tw-text-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-text-opacity));
  }

  .sm\:focus\:tw-text-gray-700:focus{
    --tw-text-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-text-opacity));
  }

  .sm\:focus\:tw-text-gray-800:focus{
    --tw-text-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-text-opacity));
  }

  .sm\:focus\:tw-text-gray-900:focus{
    --tw-text-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-text-opacity));
  }

  .sm\:focus\:tw-text-blue-100:focus{
    --tw-text-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-text-opacity));
  }

  .sm\:focus\:tw-text-blue-200:focus{
    --tw-text-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-text-opacity));
  }

  .sm\:focus\:tw-text-blue-300:focus{
    --tw-text-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-text-opacity));
  }

  .sm\:focus\:tw-text-blue-400:focus{
    --tw-text-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-text-opacity));
  }

  .sm\:focus\:tw-text-blue-500:focus{
    --tw-text-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-text-opacity));
  }

  .sm\:focus\:tw-text-blue-600:focus{
    --tw-text-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-text-opacity));
  }

  .sm\:focus\:tw-text-blue-700:focus{
    --tw-text-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-text-opacity));
  }

  .sm\:focus\:tw-text-blue-800:focus{
    --tw-text-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-text-opacity));
  }

  .sm\:focus\:tw-text-blue-900:focus{
    --tw-text-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-text-opacity));
  }

  .sm\:tw-text-opacity-0{
    --tw-text-opacity: 0;
  }

  .sm\:tw-text-opacity-25{
    --tw-text-opacity: 0.25;
  }

  .sm\:tw-text-opacity-50{
    --tw-text-opacity: 0.5;
  }

  .sm\:tw-text-opacity-75{
    --tw-text-opacity: 0.75;
  }

  .sm\:tw-text-opacity-90{
    --tw-text-opacity: 0.9;
  }

  .sm\:tw-text-opacity-100{
    --tw-text-opacity: 1;
  }

  .sm\:hover\:tw-text-opacity-0:hover{
    --tw-text-opacity: 0;
  }

  .sm\:hover\:tw-text-opacity-25:hover{
    --tw-text-opacity: 0.25;
  }

  .sm\:hover\:tw-text-opacity-50:hover{
    --tw-text-opacity: 0.5;
  }

  .sm\:hover\:tw-text-opacity-75:hover{
    --tw-text-opacity: 0.75;
  }

  .sm\:hover\:tw-text-opacity-90:hover{
    --tw-text-opacity: 0.9;
  }

  .sm\:hover\:tw-text-opacity-100:hover{
    --tw-text-opacity: 1;
  }

  .sm\:focus\:tw-text-opacity-0:focus{
    --tw-text-opacity: 0;
  }

  .sm\:focus\:tw-text-opacity-25:focus{
    --tw-text-opacity: 0.25;
  }

  .sm\:focus\:tw-text-opacity-50:focus{
    --tw-text-opacity: 0.5;
  }

  .sm\:focus\:tw-text-opacity-75:focus{
    --tw-text-opacity: 0.75;
  }

  .sm\:focus\:tw-text-opacity-90:focus{
    --tw-text-opacity: 0.9;
  }

  .sm\:focus\:tw-text-opacity-100:focus{
    --tw-text-opacity: 1;
  }

  .sm\:tw-underline{
    text-decoration: underline;
  }

  .sm\:tw-line-through{
    text-decoration: line-through;
  }

  .sm\:tw-no-underline{
    text-decoration: none;
  }

  .sm\:hover\:tw-underline:hover{
    text-decoration: underline;
  }

  .sm\:hover\:tw-line-through:hover{
    text-decoration: line-through;
  }

  .sm\:hover\:tw-no-underline:hover{
    text-decoration: none;
  }

  .sm\:focus\:tw-underline:focus{
    text-decoration: underline;
  }

  .sm\:focus\:tw-line-through:focus{
    text-decoration: line-through;
  }

  .sm\:focus\:tw-no-underline:focus{
    text-decoration: none;
  }

  .sm\:tw-antialiased{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .sm\:tw-subpixel-antialiased{
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .sm\:tw-placeholder-transparent::-webkit-input-placeholder{
    color: transparent;
  }

  .sm\:tw-placeholder-transparent::-ms-input-placeholder{
    color: transparent;
  }

  .sm\:tw-placeholder-transparent::placeholder{
    color: transparent;
  }

  .sm\:tw-placeholder-current::-webkit-input-placeholder{
    color: currentColor;
  }

  .sm\:tw-placeholder-current::-ms-input-placeholder{
    color: currentColor;
  }

  .sm\:tw-placeholder-current::placeholder{
    color: currentColor;
  }

  .sm\:tw-placeholder-black::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-black::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-black::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-white::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-white::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-white::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-100::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-200::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-300::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-400::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-500::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-600::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-700::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-800::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-900::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-100::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-200::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-300::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-400::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-500::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-600::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-700::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-800::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-900::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-transparent:focus::-webkit-input-placeholder{
    color: transparent;
  }

  .sm\:focus\:tw-placeholder-transparent:focus::-ms-input-placeholder{
    color: transparent;
  }

  .sm\:focus\:tw-placeholder-transparent:focus::placeholder{
    color: transparent;
  }

  .sm\:focus\:tw-placeholder-current:focus::-webkit-input-placeholder{
    color: currentColor;
  }

  .sm\:focus\:tw-placeholder-current:focus::-ms-input-placeholder{
    color: currentColor;
  }

  .sm\:focus\:tw-placeholder-current:focus::placeholder{
    color: currentColor;
  }

  .sm\:focus\:tw-placeholder-black:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-black:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-black:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-white:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-white:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-white:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-100:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-200:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-300:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-400:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-500:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-600:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-700:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-800:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-900:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-100:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-200:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-300:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-400:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-500:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-600:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-700:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-800:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-900:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
  }

  .sm\:tw-placeholder-opacity-0::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0;
  }

  .sm\:tw-placeholder-opacity-0::-ms-input-placeholder{
    --tw-placeholder-opacity: 0;
  }

  .sm\:tw-placeholder-opacity-0::placeholder{
    --tw-placeholder-opacity: 0;
  }

  .sm\:tw-placeholder-opacity-25::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.25;
  }

  .sm\:tw-placeholder-opacity-25::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.25;
  }

  .sm\:tw-placeholder-opacity-25::placeholder{
    --tw-placeholder-opacity: 0.25;
  }

  .sm\:tw-placeholder-opacity-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.5;
  }

  .sm\:tw-placeholder-opacity-50::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.5;
  }

  .sm\:tw-placeholder-opacity-50::placeholder{
    --tw-placeholder-opacity: 0.5;
  }

  .sm\:tw-placeholder-opacity-75::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.75;
  }

  .sm\:tw-placeholder-opacity-75::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.75;
  }

  .sm\:tw-placeholder-opacity-75::placeholder{
    --tw-placeholder-opacity: 0.75;
  }

  .sm\:tw-placeholder-opacity-90::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.9;
  }

  .sm\:tw-placeholder-opacity-90::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.9;
  }

  .sm\:tw-placeholder-opacity-90::placeholder{
    --tw-placeholder-opacity: 0.9;
  }

  .sm\:tw-placeholder-opacity-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
  }

  .sm\:tw-placeholder-opacity-100::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
  }

  .sm\:tw-placeholder-opacity-100::placeholder{
    --tw-placeholder-opacity: 1;
  }

  .sm\:focus\:tw-placeholder-opacity-0:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0;
  }

  .sm\:focus\:tw-placeholder-opacity-0:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 0;
  }

  .sm\:focus\:tw-placeholder-opacity-0:focus::placeholder{
    --tw-placeholder-opacity: 0;
  }

  .sm\:focus\:tw-placeholder-opacity-25:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.25;
  }

  .sm\:focus\:tw-placeholder-opacity-25:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.25;
  }

  .sm\:focus\:tw-placeholder-opacity-25:focus::placeholder{
    --tw-placeholder-opacity: 0.25;
  }

  .sm\:focus\:tw-placeholder-opacity-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.5;
  }

  .sm\:focus\:tw-placeholder-opacity-50:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.5;
  }

  .sm\:focus\:tw-placeholder-opacity-50:focus::placeholder{
    --tw-placeholder-opacity: 0.5;
  }

  .sm\:focus\:tw-placeholder-opacity-75:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.75;
  }

  .sm\:focus\:tw-placeholder-opacity-75:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.75;
  }

  .sm\:focus\:tw-placeholder-opacity-75:focus::placeholder{
    --tw-placeholder-opacity: 0.75;
  }

  .sm\:focus\:tw-placeholder-opacity-90:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.9;
  }

  .sm\:focus\:tw-placeholder-opacity-90:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.9;
  }

  .sm\:focus\:tw-placeholder-opacity-90:focus::placeholder{
    --tw-placeholder-opacity: 0.9;
  }

  .sm\:focus\:tw-placeholder-opacity-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
  }

  .sm\:focus\:tw-placeholder-opacity-100:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
  }

  .sm\:focus\:tw-placeholder-opacity-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
  }

  .sm\:tw-opacity-0{
    opacity: 0;
  }

  .sm\:tw-opacity-25{
    opacity: 0.25;
  }

  .sm\:tw-opacity-50{
    opacity: 0.5;
  }

  .sm\:tw-opacity-75{
    opacity: 0.75;
  }

  .sm\:tw-opacity-90{
    opacity: 0.9;
  }

  .sm\:tw-opacity-100{
    opacity: 1;
  }

  .sm\:hover\:tw-opacity-0:hover{
    opacity: 0;
  }

  .sm\:hover\:tw-opacity-25:hover{
    opacity: 0.25;
  }

  .sm\:hover\:tw-opacity-50:hover{
    opacity: 0.5;
  }

  .sm\:hover\:tw-opacity-75:hover{
    opacity: 0.75;
  }

  .sm\:hover\:tw-opacity-90:hover{
    opacity: 0.9;
  }

  .sm\:hover\:tw-opacity-100:hover{
    opacity: 1;
  }

  .sm\:focus\:tw-opacity-0:focus{
    opacity: 0;
  }

  .sm\:focus\:tw-opacity-25:focus{
    opacity: 0.25;
  }

  .sm\:focus\:tw-opacity-50:focus{
    opacity: 0.5;
  }

  .sm\:focus\:tw-opacity-75:focus{
    opacity: 0.75;
  }

  .sm\:focus\:tw-opacity-90:focus{
    opacity: 0.9;
  }

  .sm\:focus\:tw-opacity-100:focus{
    opacity: 1;
  }

  .sm\:tw-bg-blend-normal{
    background-blend-mode: normal;
  }

  .sm\:tw-bg-blend-multiply{
    background-blend-mode: multiply;
  }

  .sm\:tw-bg-blend-screen{
    background-blend-mode: screen;
  }

  .sm\:tw-bg-blend-overlay{
    background-blend-mode: overlay;
  }

  .sm\:tw-bg-blend-darken{
    background-blend-mode: darken;
  }

  .sm\:tw-bg-blend-lighten{
    background-blend-mode: lighten;
  }

  .sm\:tw-bg-blend-color-dodge{
    background-blend-mode: color-dodge;
  }

  .sm\:tw-bg-blend-color-burn{
    background-blend-mode: color-burn;
  }

  .sm\:tw-bg-blend-hard-light{
    background-blend-mode: hard-light;
  }

  .sm\:tw-bg-blend-soft-light{
    background-blend-mode: soft-light;
  }

  .sm\:tw-bg-blend-difference{
    background-blend-mode: difference;
  }

  .sm\:tw-bg-blend-exclusion{
    background-blend-mode: exclusion;
  }

  .sm\:tw-bg-blend-hue{
    background-blend-mode: hue;
  }

  .sm\:tw-bg-blend-saturation{
    background-blend-mode: saturation;
  }

  .sm\:tw-bg-blend-color{
    background-blend-mode: color;
  }

  .sm\:tw-bg-blend-luminosity{
    background-blend-mode: luminosity;
  }

  .sm\:tw-mix-blend-normal{
    mix-blend-mode: normal;
  }

  .sm\:tw-mix-blend-multiply{
    mix-blend-mode: multiply;
  }

  .sm\:tw-mix-blend-screen{
    mix-blend-mode: screen;
  }

  .sm\:tw-mix-blend-overlay{
    mix-blend-mode: overlay;
  }

  .sm\:tw-mix-blend-darken{
    mix-blend-mode: darken;
  }

  .sm\:tw-mix-blend-lighten{
    mix-blend-mode: lighten;
  }

  .sm\:tw-mix-blend-color-dodge{
    mix-blend-mode: color-dodge;
  }

  .sm\:tw-mix-blend-color-burn{
    mix-blend-mode: color-burn;
  }

  .sm\:tw-mix-blend-hard-light{
    mix-blend-mode: hard-light;
  }

  .sm\:tw-mix-blend-soft-light{
    mix-blend-mode: soft-light;
  }

  .sm\:tw-mix-blend-difference{
    mix-blend-mode: difference;
  }

  .sm\:tw-mix-blend-exclusion{
    mix-blend-mode: exclusion;
  }

  .sm\:tw-mix-blend-hue{
    mix-blend-mode: hue;
  }

  .sm\:tw-mix-blend-saturation{
    mix-blend-mode: saturation;
  }

  .sm\:tw-mix-blend-color{
    mix-blend-mode: color;
  }

  .sm\:tw-mix-blend-luminosity{
    mix-blend-mode: luminosity;
  }

  .sm\:tw-shadow-xs{
    --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:tw-shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:tw-shadow-default{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:tw-shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:tw-shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:tw-shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:tw-shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:tw-shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:tw-shadow-outline{
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:tw-shadow-none{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:tw-shadow-xs:hover{
    --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:tw-shadow-sm:hover{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:tw-shadow-default:hover{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:tw-shadow-md:hover{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:tw-shadow-lg:hover{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:tw-shadow-xl:hover{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:tw-shadow-2xl:hover{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:tw-shadow-inner:hover{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:tw-shadow-outline:hover{
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:tw-shadow-none:hover{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:tw-shadow-xs:focus{
    --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:tw-shadow-sm:focus{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:tw-shadow-default:focus{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:tw-shadow-md:focus{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:tw-shadow-lg:focus{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:tw-shadow-xl:focus{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:tw-shadow-2xl:focus{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:tw-shadow-inner:focus{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:tw-shadow-outline:focus{
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:tw-shadow-none:focus{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:tw-outline-none{
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .sm\:tw-outline-white{
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .sm\:tw-outline-black{
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .sm\:focus\:tw-outline-none:focus{
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .sm\:focus\:tw-outline-white:focus{
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .sm\:focus\:tw-outline-black:focus{
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .sm\:tw-ring-0{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:tw-ring-1{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:tw-ring-2{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:tw-ring-4{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:tw-ring-8{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:tw-ring{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus-within\:tw-ring-0:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus-within\:tw-ring-1:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus-within\:tw-ring-2:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus-within\:tw-ring-4:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus-within\:tw-ring-8:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus-within\:tw-ring:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus\:tw-ring-0:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus\:tw-ring-1:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus\:tw-ring-2:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus\:tw-ring-4:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus\:tw-ring-8:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus\:tw-ring:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:tw-ring-inset{
    --tw-ring-inset: inset;
  }

  .sm\:focus-within\:tw-ring-inset:focus-within{
    --tw-ring-inset: inset;
  }

  .sm\:focus\:tw-ring-inset:focus{
    --tw-ring-inset: inset;
  }

  .sm\:tw-ring-transparent{
    --tw-ring-color: transparent;
  }

  .sm\:tw-ring-current{
    --tw-ring-color: currentColor;
  }

  .sm\:tw-ring-black{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(44, 44, 44, var(--tw-ring-opacity));
  }

  .sm\:tw-ring-white{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .sm\:tw-ring-gray-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 250, 252, var(--tw-ring-opacity));
  }

  .sm\:tw-ring-gray-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 242, 247, var(--tw-ring-opacity));
  }

  .sm\:tw-ring-gray-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(226, 232, 240, var(--tw-ring-opacity));
  }

  .sm\:tw-ring-gray-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(203, 213, 224, var(--tw-ring-opacity));
  }

  .sm\:tw-ring-gray-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(160, 174, 192, var(--tw-ring-opacity));
  }

  .sm\:tw-ring-gray-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(113, 128, 150, var(--tw-ring-opacity));
  }

  .sm\:tw-ring-gray-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 85, 104, var(--tw-ring-opacity));
  }

  .sm\:tw-ring-gray-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(45, 55, 72, var(--tw-ring-opacity));
  }

  .sm\:tw-ring-gray-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 32, 44, var(--tw-ring-opacity));
  }

  .sm\:tw-ring-blue-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(235, 248, 255, var(--tw-ring-opacity));
  }

  .sm\:tw-ring-blue-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 227, 248, var(--tw-ring-opacity));
  }

  .sm\:tw-ring-blue-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 205, 244, var(--tw-ring-opacity));
  }

  .sm\:tw-ring-blue-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 179, 237, var(--tw-ring-opacity));
  }

  .sm\:tw-ring-blue-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 153, 225, var(--tw-ring-opacity));
  }

  .sm\:tw-ring-blue-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 130, 206, var(--tw-ring-opacity));
  }

  .sm\:tw-ring-blue-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(43, 108, 176, var(--tw-ring-opacity));
  }

  .sm\:tw-ring-blue-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(44, 82, 130, var(--tw-ring-opacity));
  }

  .sm\:tw-ring-blue-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(42, 67, 101, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:tw-ring-transparent:focus-within{
    --tw-ring-color: transparent;
  }

  .sm\:focus-within\:tw-ring-current:focus-within{
    --tw-ring-color: currentColor;
  }

  .sm\:focus-within\:tw-ring-black:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(44, 44, 44, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:tw-ring-white:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:tw-ring-gray-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 250, 252, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:tw-ring-gray-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 242, 247, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:tw-ring-gray-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(226, 232, 240, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:tw-ring-gray-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(203, 213, 224, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:tw-ring-gray-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(160, 174, 192, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:tw-ring-gray-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(113, 128, 150, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:tw-ring-gray-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 85, 104, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:tw-ring-gray-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(45, 55, 72, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:tw-ring-gray-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 32, 44, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:tw-ring-blue-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(235, 248, 255, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:tw-ring-blue-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 227, 248, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:tw-ring-blue-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 205, 244, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:tw-ring-blue-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 179, 237, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:tw-ring-blue-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 153, 225, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:tw-ring-blue-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 130, 206, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:tw-ring-blue-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(43, 108, 176, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:tw-ring-blue-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(44, 82, 130, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:tw-ring-blue-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(42, 67, 101, var(--tw-ring-opacity));
  }

  .sm\:focus\:tw-ring-transparent:focus{
    --tw-ring-color: transparent;
  }

  .sm\:focus\:tw-ring-current:focus{
    --tw-ring-color: currentColor;
  }

  .sm\:focus\:tw-ring-black:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(44, 44, 44, var(--tw-ring-opacity));
  }

  .sm\:focus\:tw-ring-white:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .sm\:focus\:tw-ring-gray-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 250, 252, var(--tw-ring-opacity));
  }

  .sm\:focus\:tw-ring-gray-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 242, 247, var(--tw-ring-opacity));
  }

  .sm\:focus\:tw-ring-gray-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(226, 232, 240, var(--tw-ring-opacity));
  }

  .sm\:focus\:tw-ring-gray-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(203, 213, 224, var(--tw-ring-opacity));
  }

  .sm\:focus\:tw-ring-gray-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(160, 174, 192, var(--tw-ring-opacity));
  }

  .sm\:focus\:tw-ring-gray-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(113, 128, 150, var(--tw-ring-opacity));
  }

  .sm\:focus\:tw-ring-gray-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 85, 104, var(--tw-ring-opacity));
  }

  .sm\:focus\:tw-ring-gray-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(45, 55, 72, var(--tw-ring-opacity));
  }

  .sm\:focus\:tw-ring-gray-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 32, 44, var(--tw-ring-opacity));
  }

  .sm\:focus\:tw-ring-blue-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(235, 248, 255, var(--tw-ring-opacity));
  }

  .sm\:focus\:tw-ring-blue-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 227, 248, var(--tw-ring-opacity));
  }

  .sm\:focus\:tw-ring-blue-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 205, 244, var(--tw-ring-opacity));
  }

  .sm\:focus\:tw-ring-blue-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 179, 237, var(--tw-ring-opacity));
  }

  .sm\:focus\:tw-ring-blue-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 153, 225, var(--tw-ring-opacity));
  }

  .sm\:focus\:tw-ring-blue-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 130, 206, var(--tw-ring-opacity));
  }

  .sm\:focus\:tw-ring-blue-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(43, 108, 176, var(--tw-ring-opacity));
  }

  .sm\:focus\:tw-ring-blue-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(44, 82, 130, var(--tw-ring-opacity));
  }

  .sm\:focus\:tw-ring-blue-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(42, 67, 101, var(--tw-ring-opacity));
  }

  .sm\:tw-ring-opacity-0{
    --tw-ring-opacity: 0;
  }

  .sm\:tw-ring-opacity-25{
    --tw-ring-opacity: 0.25;
  }

  .sm\:tw-ring-opacity-50{
    --tw-ring-opacity: 0.5;
  }

  .sm\:tw-ring-opacity-75{
    --tw-ring-opacity: 0.75;
  }

  .sm\:tw-ring-opacity-90{
    --tw-ring-opacity: 0.9;
  }

  .sm\:tw-ring-opacity-100{
    --tw-ring-opacity: 1;
  }

  .sm\:focus-within\:tw-ring-opacity-0:focus-within{
    --tw-ring-opacity: 0;
  }

  .sm\:focus-within\:tw-ring-opacity-25:focus-within{
    --tw-ring-opacity: 0.25;
  }

  .sm\:focus-within\:tw-ring-opacity-50:focus-within{
    --tw-ring-opacity: 0.5;
  }

  .sm\:focus-within\:tw-ring-opacity-75:focus-within{
    --tw-ring-opacity: 0.75;
  }

  .sm\:focus-within\:tw-ring-opacity-90:focus-within{
    --tw-ring-opacity: 0.9;
  }

  .sm\:focus-within\:tw-ring-opacity-100:focus-within{
    --tw-ring-opacity: 1;
  }

  .sm\:focus\:tw-ring-opacity-0:focus{
    --tw-ring-opacity: 0;
  }

  .sm\:focus\:tw-ring-opacity-25:focus{
    --tw-ring-opacity: 0.25;
  }

  .sm\:focus\:tw-ring-opacity-50:focus{
    --tw-ring-opacity: 0.5;
  }

  .sm\:focus\:tw-ring-opacity-75:focus{
    --tw-ring-opacity: 0.75;
  }

  .sm\:focus\:tw-ring-opacity-90:focus{
    --tw-ring-opacity: 0.9;
  }

  .sm\:focus\:tw-ring-opacity-100:focus{
    --tw-ring-opacity: 1;
  }

  .sm\:tw-ring-offset-0{
    --tw-ring-offset-width: 0px;
  }

  .sm\:tw-ring-offset-1{
    --tw-ring-offset-width: 1px;
  }

  .sm\:tw-ring-offset-2{
    --tw-ring-offset-width: 2px;
  }

  .sm\:tw-ring-offset-4{
    --tw-ring-offset-width: 4px;
  }

  .sm\:tw-ring-offset-8{
    --tw-ring-offset-width: 8px;
  }

  .sm\:focus-within\:tw-ring-offset-0:focus-within{
    --tw-ring-offset-width: 0px;
  }

  .sm\:focus-within\:tw-ring-offset-1:focus-within{
    --tw-ring-offset-width: 1px;
  }

  .sm\:focus-within\:tw-ring-offset-2:focus-within{
    --tw-ring-offset-width: 2px;
  }

  .sm\:focus-within\:tw-ring-offset-4:focus-within{
    --tw-ring-offset-width: 4px;
  }

  .sm\:focus-within\:tw-ring-offset-8:focus-within{
    --tw-ring-offset-width: 8px;
  }

  .sm\:focus\:tw-ring-offset-0:focus{
    --tw-ring-offset-width: 0px;
  }

  .sm\:focus\:tw-ring-offset-1:focus{
    --tw-ring-offset-width: 1px;
  }

  .sm\:focus\:tw-ring-offset-2:focus{
    --tw-ring-offset-width: 2px;
  }

  .sm\:focus\:tw-ring-offset-4:focus{
    --tw-ring-offset-width: 4px;
  }

  .sm\:focus\:tw-ring-offset-8:focus{
    --tw-ring-offset-width: 8px;
  }

  .sm\:tw-ring-offset-transparent{
    --tw-ring-offset-color: transparent;
  }

  .sm\:tw-ring-offset-current{
    --tw-ring-offset-color: currentColor;
  }

  .sm\:tw-ring-offset-black{
    --tw-ring-offset-color: #2c2c2c;
  }

  .sm\:tw-ring-offset-white{
    --tw-ring-offset-color: #fff;
  }

  .sm\:tw-ring-offset-gray-100{
    --tw-ring-offset-color: #f7fafc;
  }

  .sm\:tw-ring-offset-gray-200{
    --tw-ring-offset-color: #edf2f7;
  }

  .sm\:tw-ring-offset-gray-300{
    --tw-ring-offset-color: #e2e8f0;
  }

  .sm\:tw-ring-offset-gray-400{
    --tw-ring-offset-color: #cbd5e0;
  }

  .sm\:tw-ring-offset-gray-500{
    --tw-ring-offset-color: #a0aec0;
  }

  .sm\:tw-ring-offset-gray-600{
    --tw-ring-offset-color: #718096;
  }

  .sm\:tw-ring-offset-gray-700{
    --tw-ring-offset-color: #4a5568;
  }

  .sm\:tw-ring-offset-gray-800{
    --tw-ring-offset-color: #2d3748;
  }

  .sm\:tw-ring-offset-gray-900{
    --tw-ring-offset-color: #1a202c;
  }

  .sm\:tw-ring-offset-blue-100{
    --tw-ring-offset-color: #ebf8ff;
  }

  .sm\:tw-ring-offset-blue-200{
    --tw-ring-offset-color: #bee3f8;
  }

  .sm\:tw-ring-offset-blue-300{
    --tw-ring-offset-color: #90cdf4;
  }

  .sm\:tw-ring-offset-blue-400{
    --tw-ring-offset-color: #63b3ed;
  }

  .sm\:tw-ring-offset-blue-500{
    --tw-ring-offset-color: #4299e1;
  }

  .sm\:tw-ring-offset-blue-600{
    --tw-ring-offset-color: #3182ce;
  }

  .sm\:tw-ring-offset-blue-700{
    --tw-ring-offset-color: #2b6cb0;
  }

  .sm\:tw-ring-offset-blue-800{
    --tw-ring-offset-color: #2c5282;
  }

  .sm\:tw-ring-offset-blue-900{
    --tw-ring-offset-color: #2a4365;
  }

  .sm\:focus-within\:tw-ring-offset-transparent:focus-within{
    --tw-ring-offset-color: transparent;
  }

  .sm\:focus-within\:tw-ring-offset-current:focus-within{
    --tw-ring-offset-color: currentColor;
  }

  .sm\:focus-within\:tw-ring-offset-black:focus-within{
    --tw-ring-offset-color: #2c2c2c;
  }

  .sm\:focus-within\:tw-ring-offset-white:focus-within{
    --tw-ring-offset-color: #fff;
  }

  .sm\:focus-within\:tw-ring-offset-gray-100:focus-within{
    --tw-ring-offset-color: #f7fafc;
  }

  .sm\:focus-within\:tw-ring-offset-gray-200:focus-within{
    --tw-ring-offset-color: #edf2f7;
  }

  .sm\:focus-within\:tw-ring-offset-gray-300:focus-within{
    --tw-ring-offset-color: #e2e8f0;
  }

  .sm\:focus-within\:tw-ring-offset-gray-400:focus-within{
    --tw-ring-offset-color: #cbd5e0;
  }

  .sm\:focus-within\:tw-ring-offset-gray-500:focus-within{
    --tw-ring-offset-color: #a0aec0;
  }

  .sm\:focus-within\:tw-ring-offset-gray-600:focus-within{
    --tw-ring-offset-color: #718096;
  }

  .sm\:focus-within\:tw-ring-offset-gray-700:focus-within{
    --tw-ring-offset-color: #4a5568;
  }

  .sm\:focus-within\:tw-ring-offset-gray-800:focus-within{
    --tw-ring-offset-color: #2d3748;
  }

  .sm\:focus-within\:tw-ring-offset-gray-900:focus-within{
    --tw-ring-offset-color: #1a202c;
  }

  .sm\:focus-within\:tw-ring-offset-blue-100:focus-within{
    --tw-ring-offset-color: #ebf8ff;
  }

  .sm\:focus-within\:tw-ring-offset-blue-200:focus-within{
    --tw-ring-offset-color: #bee3f8;
  }

  .sm\:focus-within\:tw-ring-offset-blue-300:focus-within{
    --tw-ring-offset-color: #90cdf4;
  }

  .sm\:focus-within\:tw-ring-offset-blue-400:focus-within{
    --tw-ring-offset-color: #63b3ed;
  }

  .sm\:focus-within\:tw-ring-offset-blue-500:focus-within{
    --tw-ring-offset-color: #4299e1;
  }

  .sm\:focus-within\:tw-ring-offset-blue-600:focus-within{
    --tw-ring-offset-color: #3182ce;
  }

  .sm\:focus-within\:tw-ring-offset-blue-700:focus-within{
    --tw-ring-offset-color: #2b6cb0;
  }

  .sm\:focus-within\:tw-ring-offset-blue-800:focus-within{
    --tw-ring-offset-color: #2c5282;
  }

  .sm\:focus-within\:tw-ring-offset-blue-900:focus-within{
    --tw-ring-offset-color: #2a4365;
  }

  .sm\:focus\:tw-ring-offset-transparent:focus{
    --tw-ring-offset-color: transparent;
  }

  .sm\:focus\:tw-ring-offset-current:focus{
    --tw-ring-offset-color: currentColor;
  }

  .sm\:focus\:tw-ring-offset-black:focus{
    --tw-ring-offset-color: #2c2c2c;
  }

  .sm\:focus\:tw-ring-offset-white:focus{
    --tw-ring-offset-color: #fff;
  }

  .sm\:focus\:tw-ring-offset-gray-100:focus{
    --tw-ring-offset-color: #f7fafc;
  }

  .sm\:focus\:tw-ring-offset-gray-200:focus{
    --tw-ring-offset-color: #edf2f7;
  }

  .sm\:focus\:tw-ring-offset-gray-300:focus{
    --tw-ring-offset-color: #e2e8f0;
  }

  .sm\:focus\:tw-ring-offset-gray-400:focus{
    --tw-ring-offset-color: #cbd5e0;
  }

  .sm\:focus\:tw-ring-offset-gray-500:focus{
    --tw-ring-offset-color: #a0aec0;
  }

  .sm\:focus\:tw-ring-offset-gray-600:focus{
    --tw-ring-offset-color: #718096;
  }

  .sm\:focus\:tw-ring-offset-gray-700:focus{
    --tw-ring-offset-color: #4a5568;
  }

  .sm\:focus\:tw-ring-offset-gray-800:focus{
    --tw-ring-offset-color: #2d3748;
  }

  .sm\:focus\:tw-ring-offset-gray-900:focus{
    --tw-ring-offset-color: #1a202c;
  }

  .sm\:focus\:tw-ring-offset-blue-100:focus{
    --tw-ring-offset-color: #ebf8ff;
  }

  .sm\:focus\:tw-ring-offset-blue-200:focus{
    --tw-ring-offset-color: #bee3f8;
  }

  .sm\:focus\:tw-ring-offset-blue-300:focus{
    --tw-ring-offset-color: #90cdf4;
  }

  .sm\:focus\:tw-ring-offset-blue-400:focus{
    --tw-ring-offset-color: #63b3ed;
  }

  .sm\:focus\:tw-ring-offset-blue-500:focus{
    --tw-ring-offset-color: #4299e1;
  }

  .sm\:focus\:tw-ring-offset-blue-600:focus{
    --tw-ring-offset-color: #3182ce;
  }

  .sm\:focus\:tw-ring-offset-blue-700:focus{
    --tw-ring-offset-color: #2b6cb0;
  }

  .sm\:focus\:tw-ring-offset-blue-800:focus{
    --tw-ring-offset-color: #2c5282;
  }

  .sm\:focus\:tw-ring-offset-blue-900:focus{
    --tw-ring-offset-color: #2a4365;
  }

  .sm\:tw-filter{
    --tw-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
    -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:tw-filter-none{
    -webkit-filter: none;
    filter: none;
  }

  .sm\:tw-blur-0{
    --tw-blur: blur(0);
  }

  .sm\:tw-blur-none{
    --tw-blur: blur(0);
  }

  .sm\:tw-blur-sm{
    --tw-blur: blur(4px);
  }

  .sm\:tw-blur{
    --tw-blur: blur(8px);
  }

  .sm\:tw-blur-md{
    --tw-blur: blur(12px);
  }

  .sm\:tw-blur-lg{
    --tw-blur: blur(16px);
  }

  .sm\:tw-blur-xl{
    --tw-blur: blur(24px);
  }

  .sm\:tw-blur-2xl{
    --tw-blur: blur(40px);
  }

  .sm\:tw-blur-3xl{
    --tw-blur: blur(64px);
  }

  .sm\:tw-brightness-0{
    --tw-brightness: brightness(0);
  }

  .sm\:tw-brightness-50{
    --tw-brightness: brightness(.5);
  }

  .sm\:tw-brightness-75{
    --tw-brightness: brightness(.75);
  }

  .sm\:tw-brightness-90{
    --tw-brightness: brightness(.9);
  }

  .sm\:tw-brightness-95{
    --tw-brightness: brightness(.95);
  }

  .sm\:tw-brightness-100{
    --tw-brightness: brightness(1);
  }

  .sm\:tw-brightness-105{
    --tw-brightness: brightness(1.05);
  }

  .sm\:tw-brightness-110{
    --tw-brightness: brightness(1.1);
  }

  .sm\:tw-brightness-125{
    --tw-brightness: brightness(1.25);
  }

  .sm\:tw-brightness-150{
    --tw-brightness: brightness(1.5);
  }

  .sm\:tw-brightness-200{
    --tw-brightness: brightness(2);
  }

  .sm\:tw-contrast-0{
    --tw-contrast: contrast(0);
  }

  .sm\:tw-contrast-50{
    --tw-contrast: contrast(.5);
  }

  .sm\:tw-contrast-75{
    --tw-contrast: contrast(.75);
  }

  .sm\:tw-contrast-100{
    --tw-contrast: contrast(1);
  }

  .sm\:tw-contrast-125{
    --tw-contrast: contrast(1.25);
  }

  .sm\:tw-contrast-150{
    --tw-contrast: contrast(1.5);
  }

  .sm\:tw-contrast-200{
    --tw-contrast: contrast(2);
  }

  .sm\:tw-drop-shadow-sm{
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05));
  }

  .sm\:tw-drop-shadow{
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
  }

  .sm\:tw-drop-shadow-md{
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
  }

  .sm\:tw-drop-shadow-lg{
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
  }

  .sm\:tw-drop-shadow-xl{
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
  }

  .sm\:tw-drop-shadow-2xl{
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15));
  }

  .sm\:tw-drop-shadow-none{
    --tw-drop-shadow: drop-shadow(0 0 #0000);
  }

  .sm\:tw-grayscale-0{
    --tw-grayscale: grayscale(0);
  }

  .sm\:tw-grayscale{
    --tw-grayscale: grayscale(100%);
  }

  .sm\:tw-hue-rotate-0{
    --tw-hue-rotate: hue-rotate(0deg);
  }

  .sm\:tw-hue-rotate-15{
    --tw-hue-rotate: hue-rotate(15deg);
  }

  .sm\:tw-hue-rotate-30{
    --tw-hue-rotate: hue-rotate(30deg);
  }

  .sm\:tw-hue-rotate-60{
    --tw-hue-rotate: hue-rotate(60deg);
  }

  .sm\:tw-hue-rotate-90{
    --tw-hue-rotate: hue-rotate(90deg);
  }

  .sm\:tw-hue-rotate-180{
    --tw-hue-rotate: hue-rotate(180deg);
  }

  .sm\:tw--hue-rotate-180{
    --tw-hue-rotate: hue-rotate(-180deg);
  }

  .sm\:tw--hue-rotate-90{
    --tw-hue-rotate: hue-rotate(-90deg);
  }

  .sm\:tw--hue-rotate-60{
    --tw-hue-rotate: hue-rotate(-60deg);
  }

  .sm\:tw--hue-rotate-30{
    --tw-hue-rotate: hue-rotate(-30deg);
  }

  .sm\:tw--hue-rotate-15{
    --tw-hue-rotate: hue-rotate(-15deg);
  }

  .sm\:tw-invert-0{
    --tw-invert: invert(0);
  }

  .sm\:tw-invert{
    --tw-invert: invert(100%);
  }

  .sm\:tw-saturate-0{
    --tw-saturate: saturate(0);
  }

  .sm\:tw-saturate-50{
    --tw-saturate: saturate(.5);
  }

  .sm\:tw-saturate-100{
    --tw-saturate: saturate(1);
  }

  .sm\:tw-saturate-150{
    --tw-saturate: saturate(1.5);
  }

  .sm\:tw-saturate-200{
    --tw-saturate: saturate(2);
  }

  .sm\:tw-sepia-0{
    --tw-sepia: sepia(0);
  }

  .sm\:tw-sepia{
    --tw-sepia: sepia(100%);
  }

  .sm\:tw-backdrop-filter{
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:tw-backdrop-filter-none{
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
  }

  .sm\:tw-backdrop-blur-0{
    --tw-backdrop-blur: blur(0);
  }

  .sm\:tw-backdrop-blur-none{
    --tw-backdrop-blur: blur(0);
  }

  .sm\:tw-backdrop-blur-sm{
    --tw-backdrop-blur: blur(4px);
  }

  .sm\:tw-backdrop-blur{
    --tw-backdrop-blur: blur(8px);
  }

  .sm\:tw-backdrop-blur-md{
    --tw-backdrop-blur: blur(12px);
  }

  .sm\:tw-backdrop-blur-lg{
    --tw-backdrop-blur: blur(16px);
  }

  .sm\:tw-backdrop-blur-xl{
    --tw-backdrop-blur: blur(24px);
  }

  .sm\:tw-backdrop-blur-2xl{
    --tw-backdrop-blur: blur(40px);
  }

  .sm\:tw-backdrop-blur-3xl{
    --tw-backdrop-blur: blur(64px);
  }

  .sm\:tw-backdrop-brightness-0{
    --tw-backdrop-brightness: brightness(0);
  }

  .sm\:tw-backdrop-brightness-50{
    --tw-backdrop-brightness: brightness(.5);
  }

  .sm\:tw-backdrop-brightness-75{
    --tw-backdrop-brightness: brightness(.75);
  }

  .sm\:tw-backdrop-brightness-90{
    --tw-backdrop-brightness: brightness(.9);
  }

  .sm\:tw-backdrop-brightness-95{
    --tw-backdrop-brightness: brightness(.95);
  }

  .sm\:tw-backdrop-brightness-100{
    --tw-backdrop-brightness: brightness(1);
  }

  .sm\:tw-backdrop-brightness-105{
    --tw-backdrop-brightness: brightness(1.05);
  }

  .sm\:tw-backdrop-brightness-110{
    --tw-backdrop-brightness: brightness(1.1);
  }

  .sm\:tw-backdrop-brightness-125{
    --tw-backdrop-brightness: brightness(1.25);
  }

  .sm\:tw-backdrop-brightness-150{
    --tw-backdrop-brightness: brightness(1.5);
  }

  .sm\:tw-backdrop-brightness-200{
    --tw-backdrop-brightness: brightness(2);
  }

  .sm\:tw-backdrop-contrast-0{
    --tw-backdrop-contrast: contrast(0);
  }

  .sm\:tw-backdrop-contrast-50{
    --tw-backdrop-contrast: contrast(.5);
  }

  .sm\:tw-backdrop-contrast-75{
    --tw-backdrop-contrast: contrast(.75);
  }

  .sm\:tw-backdrop-contrast-100{
    --tw-backdrop-contrast: contrast(1);
  }

  .sm\:tw-backdrop-contrast-125{
    --tw-backdrop-contrast: contrast(1.25);
  }

  .sm\:tw-backdrop-contrast-150{
    --tw-backdrop-contrast: contrast(1.5);
  }

  .sm\:tw-backdrop-contrast-200{
    --tw-backdrop-contrast: contrast(2);
  }

  .sm\:tw-backdrop-grayscale-0{
    --tw-backdrop-grayscale: grayscale(0);
  }

  .sm\:tw-backdrop-grayscale{
    --tw-backdrop-grayscale: grayscale(100%);
  }

  .sm\:tw-backdrop-hue-rotate-0{
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
  }

  .sm\:tw-backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
  }

  .sm\:tw-backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
  }

  .sm\:tw-backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
  }

  .sm\:tw-backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
  }

  .sm\:tw-backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
  }

  .sm\:tw--backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  }

  .sm\:tw--backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  }

  .sm\:tw--backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  }

  .sm\:tw--backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  }

  .sm\:tw--backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  }

  .sm\:tw-backdrop-invert-0{
    --tw-backdrop-invert: invert(0);
  }

  .sm\:tw-backdrop-invert{
    --tw-backdrop-invert: invert(100%);
  }

  .sm\:tw-backdrop-opacity-0{
    --tw-backdrop-opacity: opacity(0);
  }

  .sm\:tw-backdrop-opacity-25{
    --tw-backdrop-opacity: opacity(0.25);
  }

  .sm\:tw-backdrop-opacity-50{
    --tw-backdrop-opacity: opacity(0.5);
  }

  .sm\:tw-backdrop-opacity-75{
    --tw-backdrop-opacity: opacity(0.75);
  }

  .sm\:tw-backdrop-opacity-90{
    --tw-backdrop-opacity: opacity(0.9);
  }

  .sm\:tw-backdrop-opacity-100{
    --tw-backdrop-opacity: opacity(1);
  }

  .sm\:tw-backdrop-saturate-0{
    --tw-backdrop-saturate: saturate(0);
  }

  .sm\:tw-backdrop-saturate-50{
    --tw-backdrop-saturate: saturate(.5);
  }

  .sm\:tw-backdrop-saturate-100{
    --tw-backdrop-saturate: saturate(1);
  }

  .sm\:tw-backdrop-saturate-150{
    --tw-backdrop-saturate: saturate(1.5);
  }

  .sm\:tw-backdrop-saturate-200{
    --tw-backdrop-saturate: saturate(2);
  }

  .sm\:tw-backdrop-sepia-0{
    --tw-backdrop-sepia: sepia(0);
  }

  .sm\:tw-backdrop-sepia{
    --tw-backdrop-sepia: sepia(100%);
  }

  .sm\:tw-transition-none{
    transition-property: none;
  }

  .sm\:tw-transition-all{
    transition-property: all;
  }

  .sm\:tw-transition-default{
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  }

  .sm\:tw-transition-colors{
    transition-property: background-color, border-color, color, fill, stroke;
  }

  .sm\:tw-transition-opacity{
    transition-property: opacity;
  }

  .sm\:tw-transition-shadow{
    transition-property: box-shadow;
  }

  .sm\:tw-transition-transform{
    transition-property: transform;
  }

  .sm\:tw-delay-75{
    transition-delay: 75ms;
  }

  .sm\:tw-delay-100{
    transition-delay: 100ms;
  }

  .sm\:tw-delay-150{
    transition-delay: 150ms;
  }

  .sm\:tw-delay-200{
    transition-delay: 200ms;
  }

  .sm\:tw-delay-300{
    transition-delay: 300ms;
  }

  .sm\:tw-delay-500{
    transition-delay: 500ms;
  }

  .sm\:tw-delay-700{
    transition-delay: 700ms;
  }

  .sm\:tw-delay-1000{
    transition-delay: 1000ms;
  }

  .sm\:tw-duration-75{
    transition-duration: 75ms;
  }

  .sm\:tw-duration-100{
    transition-duration: 100ms;
  }

  .sm\:tw-duration-150{
    transition-duration: 150ms;
  }

  .sm\:tw-duration-200{
    transition-duration: 200ms;
  }

  .sm\:tw-duration-300{
    transition-duration: 300ms;
  }

  .sm\:tw-duration-500{
    transition-duration: 500ms;
  }

  .sm\:tw-duration-700{
    transition-duration: 700ms;
  }

  .sm\:tw-duration-1000{
    transition-duration: 1000ms;
  }

  .sm\:tw-ease-linear{
    transition-timing-function: linear;
  }

  .sm\:tw-ease-in{
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .sm\:tw-ease-out{
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .sm\:tw-ease-in-out{
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}

@media (min-width: 768px){
  .md\:tw-container{
    width: 100%;
  }
}

@media (min-width: 768px) and (min-width: 640px){
  .md\:tw-container{
    max-width: 640px;
  }
}

@media (min-width: 768px) and (min-width: 768px){
  .md\:tw-container{
    max-width: 768px;
  }
}

@media (min-width: 768px) and (min-width: 1024px){
  .md\:tw-container{
    max-width: 1024px;
  }
}

@media (min-width: 768px) and (min-width: 1280px){
  .md\:tw-container{
    max-width: 1280px;
  }
}

@media (min-width: 768px){
  .md\:tw-sr-only{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .md\:tw-not-sr-only{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .md\:focus\:tw-sr-only:focus{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .md\:focus\:tw-not-sr-only:focus{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .md\:tw-pointer-events-none{
    pointer-events: none;
  }

  .md\:tw-pointer-events-auto{
    pointer-events: auto;
  }

  .md\:tw-visible{
    visibility: visible;
  }

  .md\:tw-invisible{
    visibility: hidden;
  }

  .md\:tw-static{
    position: static;
  }

  .md\:tw-fixed{
    position: fixed;
  }

  .md\:tw-absolute{
    position: absolute;
  }

  .md\:tw-relative{
    position: relative;
  }

  .md\:tw-sticky{
    position: -webkit-sticky;
    position: sticky;
  }

  .md\:tw-inset-0{
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .md\:tw-inset-auto{
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .md\:tw-inset-x-0{
    left: 0;
    right: 0;
  }

  .md\:tw-inset-x-auto{
    left: auto;
    right: auto;
  }

  .md\:tw-inset-y-0{
    top: 0;
    bottom: 0;
  }

  .md\:tw-inset-y-auto{
    top: auto;
    bottom: auto;
  }

  .md\:tw-top-0{
    top: 0;
  }

  .md\:tw-top-auto{
    top: auto;
  }

  .md\:tw-right-0{
    right: 0;
  }

  .md\:tw-right-auto{
    right: auto;
  }

  .md\:tw-bottom-0{
    bottom: 0;
  }

  .md\:tw-bottom-auto{
    bottom: auto;
  }

  .md\:tw-left-0{
    left: 0;
  }

  .md\:tw-left-auto{
    left: auto;
  }

  .md\:tw-isolate{
    isolation: isolate;
  }

  .md\:tw-isolation-auto{
    isolation: auto;
  }

  .md\:tw-z-0{
    z-index: 0;
  }

  .md\:tw-z-10{
    z-index: 10;
  }

  .md\:tw-z-20{
    z-index: 20;
  }

  .md\:tw-z-30{
    z-index: 30;
  }

  .md\:tw-z-40{
    z-index: 40;
  }

  .md\:tw-z-50{
    z-index: 50;
  }

  .md\:tw-z-auto{
    z-index: auto;
  }

  .md\:tw-order-1{
    order: 1;
  }

  .md\:tw-order-2{
    order: 2;
  }

  .md\:tw-order-3{
    order: 3;
  }

  .md\:tw-order-4{
    order: 4;
  }

  .md\:tw-order-5{
    order: 5;
  }

  .md\:tw-order-6{
    order: 6;
  }

  .md\:tw-order-7{
    order: 7;
  }

  .md\:tw-order-8{
    order: 8;
  }

  .md\:tw-order-9{
    order: 9;
  }

  .md\:tw-order-10{
    order: 10;
  }

  .md\:tw-order-11{
    order: 11;
  }

  .md\:tw-order-12{
    order: 12;
  }

  .md\:tw-order-first{
    order: -9999;
  }

  .md\:tw-order-last{
    order: 9999;
  }

  .md\:tw-order-none{
    order: 0;
  }

  .md\:tw-col-auto{
    grid-column: auto;
  }

  .md\:tw-col-span-1{
    grid-column: span 1 / span 1;
  }

  .md\:tw-col-span-2{
    grid-column: span 2 / span 2;
  }

  .md\:tw-col-span-3{
    grid-column: span 3 / span 3;
  }

  .md\:tw-col-span-4{
    grid-column: span 4 / span 4;
  }

  .md\:tw-col-span-5{
    grid-column: span 5 / span 5;
  }

  .md\:tw-col-span-6{
    grid-column: span 6 / span 6;
  }

  .md\:tw-col-span-7{
    grid-column: span 7 / span 7;
  }

  .md\:tw-col-span-8{
    grid-column: span 8 / span 8;
  }

  .md\:tw-col-span-9{
    grid-column: span 9 / span 9;
  }

  .md\:tw-col-span-10{
    grid-column: span 10 / span 10;
  }

  .md\:tw-col-span-11{
    grid-column: span 11 / span 11;
  }

  .md\:tw-col-span-12{
    grid-column: span 12 / span 12;
  }

  .md\:tw-col-span-full{
    grid-column: 1 / -1;
  }

  .md\:tw-col-start-1{
    grid-column-start: 1;
  }

  .md\:tw-col-start-2{
    grid-column-start: 2;
  }

  .md\:tw-col-start-3{
    grid-column-start: 3;
  }

  .md\:tw-col-start-4{
    grid-column-start: 4;
  }

  .md\:tw-col-start-5{
    grid-column-start: 5;
  }

  .md\:tw-col-start-6{
    grid-column-start: 6;
  }

  .md\:tw-col-start-7{
    grid-column-start: 7;
  }

  .md\:tw-col-start-8{
    grid-column-start: 8;
  }

  .md\:tw-col-start-9{
    grid-column-start: 9;
  }

  .md\:tw-col-start-10{
    grid-column-start: 10;
  }

  .md\:tw-col-start-11{
    grid-column-start: 11;
  }

  .md\:tw-col-start-12{
    grid-column-start: 12;
  }

  .md\:tw-col-start-13{
    grid-column-start: 13;
  }

  .md\:tw-col-start-auto{
    grid-column-start: auto;
  }

  .md\:tw-col-end-1{
    grid-column-end: 1;
  }

  .md\:tw-col-end-2{
    grid-column-end: 2;
  }

  .md\:tw-col-end-3{
    grid-column-end: 3;
  }

  .md\:tw-col-end-4{
    grid-column-end: 4;
  }

  .md\:tw-col-end-5{
    grid-column-end: 5;
  }

  .md\:tw-col-end-6{
    grid-column-end: 6;
  }

  .md\:tw-col-end-7{
    grid-column-end: 7;
  }

  .md\:tw-col-end-8{
    grid-column-end: 8;
  }

  .md\:tw-col-end-9{
    grid-column-end: 9;
  }

  .md\:tw-col-end-10{
    grid-column-end: 10;
  }

  .md\:tw-col-end-11{
    grid-column-end: 11;
  }

  .md\:tw-col-end-12{
    grid-column-end: 12;
  }

  .md\:tw-col-end-13{
    grid-column-end: 13;
  }

  .md\:tw-col-end-auto{
    grid-column-end: auto;
  }

  .md\:tw-row-auto{
    grid-row: auto;
  }

  .md\:tw-row-span-1{
    grid-row: span 1 / span 1;
  }

  .md\:tw-row-span-2{
    grid-row: span 2 / span 2;
  }

  .md\:tw-row-span-3{
    grid-row: span 3 / span 3;
  }

  .md\:tw-row-span-4{
    grid-row: span 4 / span 4;
  }

  .md\:tw-row-span-5{
    grid-row: span 5 / span 5;
  }

  .md\:tw-row-span-6{
    grid-row: span 6 / span 6;
  }

  .md\:tw-row-span-full{
    grid-row: 1 / -1;
  }

  .md\:tw-row-start-1{
    grid-row-start: 1;
  }

  .md\:tw-row-start-2{
    grid-row-start: 2;
  }

  .md\:tw-row-start-3{
    grid-row-start: 3;
  }

  .md\:tw-row-start-4{
    grid-row-start: 4;
  }

  .md\:tw-row-start-5{
    grid-row-start: 5;
  }

  .md\:tw-row-start-6{
    grid-row-start: 6;
  }

  .md\:tw-row-start-7{
    grid-row-start: 7;
  }

  .md\:tw-row-start-auto{
    grid-row-start: auto;
  }

  .md\:tw-row-end-1{
    grid-row-end: 1;
  }

  .md\:tw-row-end-2{
    grid-row-end: 2;
  }

  .md\:tw-row-end-3{
    grid-row-end: 3;
  }

  .md\:tw-row-end-4{
    grid-row-end: 4;
  }

  .md\:tw-row-end-5{
    grid-row-end: 5;
  }

  .md\:tw-row-end-6{
    grid-row-end: 6;
  }

  .md\:tw-row-end-7{
    grid-row-end: 7;
  }

  .md\:tw-row-end-auto{
    grid-row-end: auto;
  }

  .md\:tw-float-right{
    float: right;
  }

  .md\:tw-float-left{
    float: left;
  }

  .md\:tw-float-none{
    float: none;
  }

  .md\:tw-clear-left{
    clear: left;
  }

  .md\:tw-clear-right{
    clear: right;
  }

  .md\:tw-clear-both{
    clear: both;
  }

  .md\:tw-clear-none{
    clear: none;
  }

  .md\:tw-m-0{
    margin: 0;
  }

  .md\:tw-m-1{
    margin: 0.25rem;
  }

  .md\:tw-m-2{
    margin: 0.5rem;
  }

  .md\:tw-m-3{
    margin: 0.75rem;
  }

  .md\:tw-m-4{
    margin: 1rem;
  }

  .md\:tw-m-5{
    margin: 1.25rem;
  }

  .md\:tw-m-6{
    margin: 1.5rem;
  }

  .md\:tw-m-8{
    margin: 2rem;
  }

  .md\:tw-m-10{
    margin: 2.5rem;
  }

  .md\:tw-m-12{
    margin: 3rem;
  }

  .md\:tw-m-16{
    margin: 4rem;
  }

  .md\:tw-m-20{
    margin: 5rem;
  }

  .md\:tw-m-24{
    margin: 6rem;
  }

  .md\:tw-m-32{
    margin: 8rem;
  }

  .md\:tw-m-40{
    margin: 10rem;
  }

  .md\:tw-m-48{
    margin: 12rem;
  }

  .md\:tw-m-56{
    margin: 14rem;
  }

  .md\:tw-m-64{
    margin: 16rem;
  }

  .md\:tw-m-auto{
    margin: auto;
  }

  .md\:tw-m-px{
    margin: 1px;
  }

  .md\:tw--m-1{
    margin: -0.25rem;
  }

  .md\:tw--m-2{
    margin: -0.5rem;
  }

  .md\:tw--m-3{
    margin: -0.75rem;
  }

  .md\:tw--m-4{
    margin: -1rem;
  }

  .md\:tw--m-5{
    margin: -1.25rem;
  }

  .md\:tw--m-6{
    margin: -1.5rem;
  }

  .md\:tw--m-8{
    margin: -2rem;
  }

  .md\:tw--m-10{
    margin: -2.5rem;
  }

  .md\:tw--m-12{
    margin: -3rem;
  }

  .md\:tw--m-16{
    margin: -4rem;
  }

  .md\:tw--m-20{
    margin: -5rem;
  }

  .md\:tw--m-24{
    margin: -6rem;
  }

  .md\:tw--m-32{
    margin: -8rem;
  }

  .md\:tw--m-40{
    margin: -10rem;
  }

  .md\:tw--m-48{
    margin: -12rem;
  }

  .md\:tw--m-56{
    margin: -14rem;
  }

  .md\:tw--m-64{
    margin: -16rem;
  }

  .md\:tw--m-px{
    margin: -1px;
  }

  .md\:tw-mx-0{
    margin-left: 0;
    margin-right: 0;
  }

  .md\:tw-mx-1{
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .md\:tw-mx-2{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .md\:tw-mx-3{
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .md\:tw-mx-4{
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .md\:tw-mx-5{
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .md\:tw-mx-6{
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .md\:tw-mx-8{
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .md\:tw-mx-10{
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .md\:tw-mx-12{
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .md\:tw-mx-16{
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .md\:tw-mx-20{
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .md\:tw-mx-24{
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .md\:tw-mx-32{
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .md\:tw-mx-40{
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .md\:tw-mx-48{
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .md\:tw-mx-56{
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .md\:tw-mx-64{
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .md\:tw-mx-auto{
    margin-left: auto;
    margin-right: auto;
  }

  .md\:tw-mx-px{
    margin-left: 1px;
    margin-right: 1px;
  }

  .md\:tw--mx-1{
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  .md\:tw--mx-2{
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .md\:tw--mx-3{
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  .md\:tw--mx-4{
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .md\:tw--mx-5{
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .md\:tw--mx-6{
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .md\:tw--mx-8{
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .md\:tw--mx-10{
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .md\:tw--mx-12{
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .md\:tw--mx-16{
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .md\:tw--mx-20{
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .md\:tw--mx-24{
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .md\:tw--mx-32{
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .md\:tw--mx-40{
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .md\:tw--mx-48{
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .md\:tw--mx-56{
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .md\:tw--mx-64{
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .md\:tw--mx-px{
    margin-left: -1px;
    margin-right: -1px;
  }

  .md\:tw-my-0{
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:tw-my-1{
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .md\:tw-my-2{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .md\:tw-my-3{
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .md\:tw-my-4{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .md\:tw-my-5{
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .md\:tw-my-6{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .md\:tw-my-8{
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .md\:tw-my-10{
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .md\:tw-my-12{
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .md\:tw-my-16{
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .md\:tw-my-20{
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .md\:tw-my-24{
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .md\:tw-my-32{
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .md\:tw-my-40{
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .md\:tw-my-48{
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .md\:tw-my-56{
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .md\:tw-my-64{
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .md\:tw-my-auto{
    margin-top: auto;
    margin-bottom: auto;
  }

  .md\:tw-my-px{
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .md\:tw--my-1{
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }

  .md\:tw--my-2{
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .md\:tw--my-3{
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }

  .md\:tw--my-4{
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .md\:tw--my-5{
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .md\:tw--my-6{
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .md\:tw--my-8{
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .md\:tw--my-10{
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .md\:tw--my-12{
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .md\:tw--my-16{
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .md\:tw--my-20{
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .md\:tw--my-24{
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .md\:tw--my-32{
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .md\:tw--my-40{
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .md\:tw--my-48{
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .md\:tw--my-56{
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .md\:tw--my-64{
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .md\:tw--my-px{
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .md\:tw-mt-0{
    margin-top: 0;
  }

  .md\:tw-mt-1{
    margin-top: 0.25rem;
  }

  .md\:tw-mt-2{
    margin-top: 0.5rem;
  }

  .md\:tw-mt-3{
    margin-top: 0.75rem;
  }

  .md\:tw-mt-4{
    margin-top: 1rem;
  }

  .md\:tw-mt-5{
    margin-top: 1.25rem;
  }

  .md\:tw-mt-6{
    margin-top: 1.5rem;
  }

  .md\:tw-mt-8{
    margin-top: 2rem;
  }

  .md\:tw-mt-10{
    margin-top: 2.5rem;
  }

  .md\:tw-mt-12{
    margin-top: 3rem;
  }

  .md\:tw-mt-16{
    margin-top: 4rem;
  }

  .md\:tw-mt-20{
    margin-top: 5rem;
  }

  .md\:tw-mt-24{
    margin-top: 6rem;
  }

  .md\:tw-mt-32{
    margin-top: 8rem;
  }

  .md\:tw-mt-40{
    margin-top: 10rem;
  }

  .md\:tw-mt-48{
    margin-top: 12rem;
  }

  .md\:tw-mt-56{
    margin-top: 14rem;
  }

  .md\:tw-mt-64{
    margin-top: 16rem;
  }

  .md\:tw-mt-auto{
    margin-top: auto;
  }

  .md\:tw-mt-px{
    margin-top: 1px;
  }

  .md\:tw--mt-1{
    margin-top: -0.25rem;
  }

  .md\:tw--mt-2{
    margin-top: -0.5rem;
  }

  .md\:tw--mt-3{
    margin-top: -0.75rem;
  }

  .md\:tw--mt-4{
    margin-top: -1rem;
  }

  .md\:tw--mt-5{
    margin-top: -1.25rem;
  }

  .md\:tw--mt-6{
    margin-top: -1.5rem;
  }

  .md\:tw--mt-8{
    margin-top: -2rem;
  }

  .md\:tw--mt-10{
    margin-top: -2.5rem;
  }

  .md\:tw--mt-12{
    margin-top: -3rem;
  }

  .md\:tw--mt-16{
    margin-top: -4rem;
  }

  .md\:tw--mt-20{
    margin-top: -5rem;
  }

  .md\:tw--mt-24{
    margin-top: -6rem;
  }

  .md\:tw--mt-32{
    margin-top: -8rem;
  }

  .md\:tw--mt-40{
    margin-top: -10rem;
  }

  .md\:tw--mt-48{
    margin-top: -12rem;
  }

  .md\:tw--mt-56{
    margin-top: -14rem;
  }

  .md\:tw--mt-64{
    margin-top: -16rem;
  }

  .md\:tw--mt-px{
    margin-top: -1px;
  }

  .md\:tw-mr-0{
    margin-right: 0;
  }

  .md\:tw-mr-1{
    margin-right: 0.25rem;
  }

  .md\:tw-mr-2{
    margin-right: 0.5rem;
  }

  .md\:tw-mr-3{
    margin-right: 0.75rem;
  }

  .md\:tw-mr-4{
    margin-right: 1rem;
  }

  .md\:tw-mr-5{
    margin-right: 1.25rem;
  }

  .md\:tw-mr-6{
    margin-right: 1.5rem;
  }

  .md\:tw-mr-8{
    margin-right: 2rem;
  }

  .md\:tw-mr-10{
    margin-right: 2.5rem;
  }

  .md\:tw-mr-12{
    margin-right: 3rem;
  }

  .md\:tw-mr-16{
    margin-right: 4rem;
  }

  .md\:tw-mr-20{
    margin-right: 5rem;
  }

  .md\:tw-mr-24{
    margin-right: 6rem;
  }

  .md\:tw-mr-32{
    margin-right: 8rem;
  }

  .md\:tw-mr-40{
    margin-right: 10rem;
  }

  .md\:tw-mr-48{
    margin-right: 12rem;
  }

  .md\:tw-mr-56{
    margin-right: 14rem;
  }

  .md\:tw-mr-64{
    margin-right: 16rem;
  }

  .md\:tw-mr-auto{
    margin-right: auto;
  }

  .md\:tw-mr-px{
    margin-right: 1px;
  }

  .md\:tw--mr-1{
    margin-right: -0.25rem;
  }

  .md\:tw--mr-2{
    margin-right: -0.5rem;
  }

  .md\:tw--mr-3{
    margin-right: -0.75rem;
  }

  .md\:tw--mr-4{
    margin-right: -1rem;
  }

  .md\:tw--mr-5{
    margin-right: -1.25rem;
  }

  .md\:tw--mr-6{
    margin-right: -1.5rem;
  }

  .md\:tw--mr-8{
    margin-right: -2rem;
  }

  .md\:tw--mr-10{
    margin-right: -2.5rem;
  }

  .md\:tw--mr-12{
    margin-right: -3rem;
  }

  .md\:tw--mr-16{
    margin-right: -4rem;
  }

  .md\:tw--mr-20{
    margin-right: -5rem;
  }

  .md\:tw--mr-24{
    margin-right: -6rem;
  }

  .md\:tw--mr-32{
    margin-right: -8rem;
  }

  .md\:tw--mr-40{
    margin-right: -10rem;
  }

  .md\:tw--mr-48{
    margin-right: -12rem;
  }

  .md\:tw--mr-56{
    margin-right: -14rem;
  }

  .md\:tw--mr-64{
    margin-right: -16rem;
  }

  .md\:tw--mr-px{
    margin-right: -1px;
  }

  .md\:tw-mb-0{
    margin-bottom: 0;
  }

  .md\:tw-mb-1{
    margin-bottom: 0.25rem;
  }

  .md\:tw-mb-2{
    margin-bottom: 0.5rem;
  }

  .md\:tw-mb-3{
    margin-bottom: 0.75rem;
  }

  .md\:tw-mb-4{
    margin-bottom: 1rem;
  }

  .md\:tw-mb-5{
    margin-bottom: 1.25rem;
  }

  .md\:tw-mb-6{
    margin-bottom: 1.5rem;
  }

  .md\:tw-mb-8{
    margin-bottom: 2rem;
  }

  .md\:tw-mb-10{
    margin-bottom: 2.5rem;
  }

  .md\:tw-mb-12{
    margin-bottom: 3rem;
  }

  .md\:tw-mb-16{
    margin-bottom: 4rem;
  }

  .md\:tw-mb-20{
    margin-bottom: 5rem;
  }

  .md\:tw-mb-24{
    margin-bottom: 6rem;
  }

  .md\:tw-mb-32{
    margin-bottom: 8rem;
  }

  .md\:tw-mb-40{
    margin-bottom: 10rem;
  }

  .md\:tw-mb-48{
    margin-bottom: 12rem;
  }

  .md\:tw-mb-56{
    margin-bottom: 14rem;
  }

  .md\:tw-mb-64{
    margin-bottom: 16rem;
  }

  .md\:tw-mb-auto{
    margin-bottom: auto;
  }

  .md\:tw-mb-px{
    margin-bottom: 1px;
  }

  .md\:tw--mb-1{
    margin-bottom: -0.25rem;
  }

  .md\:tw--mb-2{
    margin-bottom: -0.5rem;
  }

  .md\:tw--mb-3{
    margin-bottom: -0.75rem;
  }

  .md\:tw--mb-4{
    margin-bottom: -1rem;
  }

  .md\:tw--mb-5{
    margin-bottom: -1.25rem;
  }

  .md\:tw--mb-6{
    margin-bottom: -1.5rem;
  }

  .md\:tw--mb-8{
    margin-bottom: -2rem;
  }

  .md\:tw--mb-10{
    margin-bottom: -2.5rem;
  }

  .md\:tw--mb-12{
    margin-bottom: -3rem;
  }

  .md\:tw--mb-16{
    margin-bottom: -4rem;
  }

  .md\:tw--mb-20{
    margin-bottom: -5rem;
  }

  .md\:tw--mb-24{
    margin-bottom: -6rem;
  }

  .md\:tw--mb-32{
    margin-bottom: -8rem;
  }

  .md\:tw--mb-40{
    margin-bottom: -10rem;
  }

  .md\:tw--mb-48{
    margin-bottom: -12rem;
  }

  .md\:tw--mb-56{
    margin-bottom: -14rem;
  }

  .md\:tw--mb-64{
    margin-bottom: -16rem;
  }

  .md\:tw--mb-px{
    margin-bottom: -1px;
  }

  .md\:tw-ml-0{
    margin-left: 0;
  }

  .md\:tw-ml-1{
    margin-left: 0.25rem;
  }

  .md\:tw-ml-2{
    margin-left: 0.5rem;
  }

  .md\:tw-ml-3{
    margin-left: 0.75rem;
  }

  .md\:tw-ml-4{
    margin-left: 1rem;
  }

  .md\:tw-ml-5{
    margin-left: 1.25rem;
  }

  .md\:tw-ml-6{
    margin-left: 1.5rem;
  }

  .md\:tw-ml-8{
    margin-left: 2rem;
  }

  .md\:tw-ml-10{
    margin-left: 2.5rem;
  }

  .md\:tw-ml-12{
    margin-left: 3rem;
  }

  .md\:tw-ml-16{
    margin-left: 4rem;
  }

  .md\:tw-ml-20{
    margin-left: 5rem;
  }

  .md\:tw-ml-24{
    margin-left: 6rem;
  }

  .md\:tw-ml-32{
    margin-left: 8rem;
  }

  .md\:tw-ml-40{
    margin-left: 10rem;
  }

  .md\:tw-ml-48{
    margin-left: 12rem;
  }

  .md\:tw-ml-56{
    margin-left: 14rem;
  }

  .md\:tw-ml-64{
    margin-left: 16rem;
  }

  .md\:tw-ml-auto{
    margin-left: auto;
  }

  .md\:tw-ml-px{
    margin-left: 1px;
  }

  .md\:tw--ml-1{
    margin-left: -0.25rem;
  }

  .md\:tw--ml-2{
    margin-left: -0.5rem;
  }

  .md\:tw--ml-3{
    margin-left: -0.75rem;
  }

  .md\:tw--ml-4{
    margin-left: -1rem;
  }

  .md\:tw--ml-5{
    margin-left: -1.25rem;
  }

  .md\:tw--ml-6{
    margin-left: -1.5rem;
  }

  .md\:tw--ml-8{
    margin-left: -2rem;
  }

  .md\:tw--ml-10{
    margin-left: -2.5rem;
  }

  .md\:tw--ml-12{
    margin-left: -3rem;
  }

  .md\:tw--ml-16{
    margin-left: -4rem;
  }

  .md\:tw--ml-20{
    margin-left: -5rem;
  }

  .md\:tw--ml-24{
    margin-left: -6rem;
  }

  .md\:tw--ml-32{
    margin-left: -8rem;
  }

  .md\:tw--ml-40{
    margin-left: -10rem;
  }

  .md\:tw--ml-48{
    margin-left: -12rem;
  }

  .md\:tw--ml-56{
    margin-left: -14rem;
  }

  .md\:tw--ml-64{
    margin-left: -16rem;
  }

  .md\:tw--ml-px{
    margin-left: -1px;
  }

  .md\:tw-box-border{
    box-sizing: border-box;
  }

  .md\:tw-box-content{
    box-sizing: content-box;
  }

  .md\:tw-block{
    display: block;
  }

  .md\:tw-inline-block{
    display: inline-block;
  }

  .md\:tw-inline{
    display: inline;
  }

  .md\:tw-flex{
    display: flex;
  }

  .md\:tw-inline-flex{
    display: inline-flex;
  }

  .md\:tw-table{
    display: table;
  }

  .md\:tw-inline-table{
    display: inline-table;
  }

  .md\:tw-table-caption{
    display: table-caption;
  }

  .md\:tw-table-cell{
    display: table-cell;
  }

  .md\:tw-table-column{
    display: table-column;
  }

  .md\:tw-table-column-group{
    display: table-column-group;
  }

  .md\:tw-table-footer-group{
    display: table-footer-group;
  }

  .md\:tw-table-header-group{
    display: table-header-group;
  }

  .md\:tw-table-row-group{
    display: table-row-group;
  }

  .md\:tw-table-row{
    display: table-row;
  }

  .md\:tw-flow-root{
    display: flow-root;
  }

  .md\:tw-grid{
    display: grid;
  }

  .md\:tw-inline-grid{
    display: inline-grid;
  }

  .md\:tw-contents{
    display: contents;
  }

  .md\:tw-list-item{
    display: list-item;
  }

  .md\:tw-hidden{
    display: none;
  }

  .md\:tw-h-0{
    height: 0;
  }

  .md\:tw-h-1{
    height: 0.25rem;
  }

  .md\:tw-h-2{
    height: 0.5rem;
  }

  .md\:tw-h-3{
    height: 0.75rem;
  }

  .md\:tw-h-4{
    height: 1rem;
  }

  .md\:tw-h-5{
    height: 1.25rem;
  }

  .md\:tw-h-6{
    height: 1.5rem;
  }

  .md\:tw-h-8{
    height: 2rem;
  }

  .md\:tw-h-10{
    height: 2.5rem;
  }

  .md\:tw-h-12{
    height: 3rem;
  }

  .md\:tw-h-16{
    height: 4rem;
  }

  .md\:tw-h-20{
    height: 5rem;
  }

  .md\:tw-h-24{
    height: 6rem;
  }

  .md\:tw-h-32{
    height: 8rem;
  }

  .md\:tw-h-40{
    height: 10rem;
  }

  .md\:tw-h-48{
    height: 12rem;
  }

  .md\:tw-h-56{
    height: 14rem;
  }

  .md\:tw-h-64{
    height: 16rem;
  }

  .md\:tw-h-auto{
    height: auto;
  }

  .md\:tw-h-px{
    height: 1px;
  }

  .md\:tw-h-1\/2{
    height: 50%;
  }

  .md\:tw-h-1\/3{
    height: 33.333333%;
  }

  .md\:tw-h-2\/3{
    height: 66.666667%;
  }

  .md\:tw-h-1\/4{
    height: 25%;
  }

  .md\:tw-h-2\/4{
    height: 50%;
  }

  .md\:tw-h-3\/4{
    height: 75%;
  }

  .md\:tw-h-1\/5{
    height: 20%;
  }

  .md\:tw-h-2\/5{
    height: 40%;
  }

  .md\:tw-h-3\/5{
    height: 60%;
  }

  .md\:tw-h-4\/5{
    height: 80%;
  }

  .md\:tw-h-1\/6{
    height: 16.666667%;
  }

  .md\:tw-h-2\/6{
    height: 33.333333%;
  }

  .md\:tw-h-3\/6{
    height: 50%;
  }

  .md\:tw-h-4\/6{
    height: 66.666667%;
  }

  .md\:tw-h-5\/6{
    height: 83.333333%;
  }

  .md\:tw-h-full{
    height: 100%;
  }

  .md\:tw-h-screen{
    height: 100vh;
  }

  .md\:tw-max-h-full{
    max-height: 100%;
  }

  .md\:tw-max-h-screen{
    max-height: 100vh;
  }

  .md\:tw-min-h-0{
    min-height: 0;
  }

  .md\:tw-min-h-full{
    min-height: 100%;
  }

  .md\:tw-min-h-screen{
    min-height: 100vh;
  }

  .md\:tw-w-0{
    width: 0;
  }

  .md\:tw-w-1{
    width: 0.25rem;
  }

  .md\:tw-w-2{
    width: 0.5rem;
  }

  .md\:tw-w-3{
    width: 0.75rem;
  }

  .md\:tw-w-4{
    width: 1rem;
  }

  .md\:tw-w-5{
    width: 1.25rem;
  }

  .md\:tw-w-6{
    width: 1.5rem;
  }

  .md\:tw-w-8{
    width: 2rem;
  }

  .md\:tw-w-10{
    width: 2.5rem;
  }

  .md\:tw-w-12{
    width: 3rem;
  }

  .md\:tw-w-16{
    width: 4rem;
  }

  .md\:tw-w-20{
    width: 5rem;
  }

  .md\:tw-w-24{
    width: 6rem;
  }

  .md\:tw-w-32{
    width: 8rem;
  }

  .md\:tw-w-40{
    width: 10rem;
  }

  .md\:tw-w-48{
    width: 12rem;
  }

  .md\:tw-w-56{
    width: 14rem;
  }

  .md\:tw-w-64{
    width: 16rem;
  }

  .md\:tw-w-auto{
    width: auto;
  }

  .md\:tw-w-px{
    width: 1px;
  }

  .md\:tw-w-1\/2{
    width: 50%;
  }

  .md\:tw-w-1\/3{
    width: 33.333333%;
  }

  .md\:tw-w-2\/3{
    width: 66.666667%;
  }

  .md\:tw-w-1\/4{
    width: 25%;
  }

  .md\:tw-w-2\/4{
    width: 50%;
  }

  .md\:tw-w-3\/4{
    width: 75%;
  }

  .md\:tw-w-1\/5{
    width: 20%;
  }

  .md\:tw-w-2\/5{
    width: 40%;
  }

  .md\:tw-w-3\/5{
    width: 60%;
  }

  .md\:tw-w-4\/5{
    width: 80%;
  }

  .md\:tw-w-1\/6{
    width: 16.666667%;
  }

  .md\:tw-w-2\/6{
    width: 33.333333%;
  }

  .md\:tw-w-3\/6{
    width: 50%;
  }

  .md\:tw-w-4\/6{
    width: 66.666667%;
  }

  .md\:tw-w-5\/6{
    width: 83.333333%;
  }

  .md\:tw-w-1\/12{
    width: 8.333333%;
  }

  .md\:tw-w-2\/12{
    width: 16.666667%;
  }

  .md\:tw-w-3\/12{
    width: 25%;
  }

  .md\:tw-w-4\/12{
    width: 33.333333%;
  }

  .md\:tw-w-5\/12{
    width: 41.666667%;
  }

  .md\:tw-w-6\/12{
    width: 50%;
  }

  .md\:tw-w-7\/12{
    width: 58.333333%;
  }

  .md\:tw-w-8\/12{
    width: 66.666667%;
  }

  .md\:tw-w-9\/12{
    width: 75%;
  }

  .md\:tw-w-10\/12{
    width: 83.333333%;
  }

  .md\:tw-w-11\/12{
    width: 91.666667%;
  }

  .md\:tw-w-full{
    width: 100%;
  }

  .md\:tw-w-screen{
    width: 100vw;
  }

  .md\:tw-w-min{
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
  }

  .md\:tw-w-max{
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }

  .md\:tw-min-w-0{
    min-width: 0;
  }

  .md\:tw-min-w-full{
    min-width: 100%;
  }

  .md\:tw-max-w-0{
    max-width: 0rem;
  }

  .md\:tw-max-w-none{
    max-width: none;
  }

  .md\:tw-max-w-xs{
    max-width: 20rem;
  }

  .md\:tw-max-w-sm{
    max-width: 24rem;
  }

  .md\:tw-max-w-md{
    max-width: 28rem;
  }

  .md\:tw-max-w-lg{
    max-width: 32rem;
  }

  .md\:tw-max-w-xl{
    max-width: 36rem;
  }

  .md\:tw-max-w-2xl{
    max-width: 42rem;
  }

  .md\:tw-max-w-3xl{
    max-width: 48rem;
  }

  .md\:tw-max-w-4xl{
    max-width: 56rem;
  }

  .md\:tw-max-w-5xl{
    max-width: 64rem;
  }

  .md\:tw-max-w-6xl{
    max-width: 72rem;
  }

  .md\:tw-max-w-7xl{
    max-width: 80rem;
  }

  .md\:tw-max-w-full{
    max-width: 100%;
  }

  .md\:tw-max-w-min{
    max-width: -webkit-min-content;
    max-width: -moz-min-content;
    max-width: min-content;
  }

  .md\:tw-max-w-max{
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
  }

  .md\:tw-max-w-prose{
    max-width: 65ch;
  }

  .md\:tw-max-w-screen-sm{
    max-width: 640px;
  }

  .md\:tw-max-w-screen-md{
    max-width: 768px;
  }

  .md\:tw-max-w-screen-lg{
    max-width: 1024px;
  }

  .md\:tw-max-w-screen-xl{
    max-width: 1280px;
  }

  .md\:tw-flex-1{
    flex: 1 1 0%;
  }

  .md\:tw-flex-auto{
    flex: 1 1 auto;
  }

  .md\:tw-flex-initial{
    flex: 0 1 auto;
  }

  .md\:tw-flex-none{
    flex: none;
  }

  .md\:tw-flex-shrink-0{
    flex-shrink: 0;
  }

  .md\:tw-flex-shrink-default{
    flex-shrink: 1;
  }

  .md\:tw-flex-grow-0{
    flex-grow: 0;
  }

  .md\:tw-flex-grow-default{
    flex-grow: 1;
  }

  .md\:tw-table-auto{
    table-layout: auto;
  }

  .md\:tw-table-fixed{
    table-layout: fixed;
  }

  .md\:tw-border-collapse{
    border-collapse: collapse;
  }

  .md\:tw-border-separate{
    border-collapse: separate;
  }

  .md\:tw-origin-center{
    transform-origin: center;
  }

  .md\:tw-origin-top{
    transform-origin: top;
  }

  .md\:tw-origin-top-right{
    transform-origin: top right;
  }

  .md\:tw-origin-right{
    transform-origin: right;
  }

  .md\:tw-origin-bottom-right{
    transform-origin: bottom right;
  }

  .md\:tw-origin-bottom{
    transform-origin: bottom;
  }

  .md\:tw-origin-bottom-left{
    transform-origin: bottom left;
  }

  .md\:tw-origin-left{
    transform-origin: left;
  }

  .md\:tw-origin-top-left{
    transform-origin: top left;
  }

  .md\:tw-transform{
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:tw-transform-gpu{
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:tw-transform-none{
    transform: none;
  }

  .md\:tw-translate-x-0{
    --tw-translate-x: 0;
  }

  .md\:tw-translate-x-1{
    --tw-translate-x: 0.25rem;
  }

  .md\:tw-translate-x-2{
    --tw-translate-x: 0.5rem;
  }

  .md\:tw-translate-x-3{
    --tw-translate-x: 0.75rem;
  }

  .md\:tw-translate-x-4{
    --tw-translate-x: 1rem;
  }

  .md\:tw-translate-x-5{
    --tw-translate-x: 1.25rem;
  }

  .md\:tw-translate-x-6{
    --tw-translate-x: 1.5rem;
  }

  .md\:tw-translate-x-8{
    --tw-translate-x: 2rem;
  }

  .md\:tw-translate-x-10{
    --tw-translate-x: 2.5rem;
  }

  .md\:tw-translate-x-12{
    --tw-translate-x: 3rem;
  }

  .md\:tw-translate-x-16{
    --tw-translate-x: 4rem;
  }

  .md\:tw-translate-x-20{
    --tw-translate-x: 5rem;
  }

  .md\:tw-translate-x-24{
    --tw-translate-x: 6rem;
  }

  .md\:tw-translate-x-32{
    --tw-translate-x: 8rem;
  }

  .md\:tw-translate-x-40{
    --tw-translate-x: 10rem;
  }

  .md\:tw-translate-x-48{
    --tw-translate-x: 12rem;
  }

  .md\:tw-translate-x-56{
    --tw-translate-x: 14rem;
  }

  .md\:tw-translate-x-64{
    --tw-translate-x: 16rem;
  }

  .md\:tw-translate-x-px{
    --tw-translate-x: 1px;
  }

  .md\:tw--translate-x-1{
    --tw-translate-x: -0.25rem;
  }

  .md\:tw--translate-x-2{
    --tw-translate-x: -0.5rem;
  }

  .md\:tw--translate-x-3{
    --tw-translate-x: -0.75rem;
  }

  .md\:tw--translate-x-4{
    --tw-translate-x: -1rem;
  }

  .md\:tw--translate-x-5{
    --tw-translate-x: -1.25rem;
  }

  .md\:tw--translate-x-6{
    --tw-translate-x: -1.5rem;
  }

  .md\:tw--translate-x-8{
    --tw-translate-x: -2rem;
  }

  .md\:tw--translate-x-10{
    --tw-translate-x: -2.5rem;
  }

  .md\:tw--translate-x-12{
    --tw-translate-x: -3rem;
  }

  .md\:tw--translate-x-16{
    --tw-translate-x: -4rem;
  }

  .md\:tw--translate-x-20{
    --tw-translate-x: -5rem;
  }

  .md\:tw--translate-x-24{
    --tw-translate-x: -6rem;
  }

  .md\:tw--translate-x-32{
    --tw-translate-x: -8rem;
  }

  .md\:tw--translate-x-40{
    --tw-translate-x: -10rem;
  }

  .md\:tw--translate-x-48{
    --tw-translate-x: -12rem;
  }

  .md\:tw--translate-x-56{
    --tw-translate-x: -14rem;
  }

  .md\:tw--translate-x-64{
    --tw-translate-x: -16rem;
  }

  .md\:tw--translate-x-px{
    --tw-translate-x: -1px;
  }

  .md\:tw-translate-x-1\/2{
    --tw-translate-x: 50%;
  }

  .md\:tw-translate-x-1\/3{
    --tw-translate-x: 33.333333%;
  }

  .md\:tw-translate-x-2\/3{
    --tw-translate-x: 66.666667%;
  }

  .md\:tw-translate-x-1\/4{
    --tw-translate-x: 25%;
  }

  .md\:tw-translate-x-2\/4{
    --tw-translate-x: 50%;
  }

  .md\:tw-translate-x-3\/4{
    --tw-translate-x: 75%;
  }

  .md\:tw-translate-x-full{
    --tw-translate-x: 100%;
  }

  .md\:tw--translate-x-1\/2{
    --tw-translate-x: -50%;
  }

  .md\:tw--translate-x-1\/3{
    --tw-translate-x: -33.333333%;
  }

  .md\:tw--translate-x-2\/3{
    --tw-translate-x: -66.666667%;
  }

  .md\:tw--translate-x-1\/4{
    --tw-translate-x: -25%;
  }

  .md\:tw--translate-x-2\/4{
    --tw-translate-x: -50%;
  }

  .md\:tw--translate-x-3\/4{
    --tw-translate-x: -75%;
  }

  .md\:tw--translate-x-full{
    --tw-translate-x: -100%;
  }

  .md\:tw-translate-y-0{
    --tw-translate-y: 0;
  }

  .md\:tw-translate-y-1{
    --tw-translate-y: 0.25rem;
  }

  .md\:tw-translate-y-2{
    --tw-translate-y: 0.5rem;
  }

  .md\:tw-translate-y-3{
    --tw-translate-y: 0.75rem;
  }

  .md\:tw-translate-y-4{
    --tw-translate-y: 1rem;
  }

  .md\:tw-translate-y-5{
    --tw-translate-y: 1.25rem;
  }

  .md\:tw-translate-y-6{
    --tw-translate-y: 1.5rem;
  }

  .md\:tw-translate-y-8{
    --tw-translate-y: 2rem;
  }

  .md\:tw-translate-y-10{
    --tw-translate-y: 2.5rem;
  }

  .md\:tw-translate-y-12{
    --tw-translate-y: 3rem;
  }

  .md\:tw-translate-y-16{
    --tw-translate-y: 4rem;
  }

  .md\:tw-translate-y-20{
    --tw-translate-y: 5rem;
  }

  .md\:tw-translate-y-24{
    --tw-translate-y: 6rem;
  }

  .md\:tw-translate-y-32{
    --tw-translate-y: 8rem;
  }

  .md\:tw-translate-y-40{
    --tw-translate-y: 10rem;
  }

  .md\:tw-translate-y-48{
    --tw-translate-y: 12rem;
  }

  .md\:tw-translate-y-56{
    --tw-translate-y: 14rem;
  }

  .md\:tw-translate-y-64{
    --tw-translate-y: 16rem;
  }

  .md\:tw-translate-y-px{
    --tw-translate-y: 1px;
  }

  .md\:tw--translate-y-1{
    --tw-translate-y: -0.25rem;
  }

  .md\:tw--translate-y-2{
    --tw-translate-y: -0.5rem;
  }

  .md\:tw--translate-y-3{
    --tw-translate-y: -0.75rem;
  }

  .md\:tw--translate-y-4{
    --tw-translate-y: -1rem;
  }

  .md\:tw--translate-y-5{
    --tw-translate-y: -1.25rem;
  }

  .md\:tw--translate-y-6{
    --tw-translate-y: -1.5rem;
  }

  .md\:tw--translate-y-8{
    --tw-translate-y: -2rem;
  }

  .md\:tw--translate-y-10{
    --tw-translate-y: -2.5rem;
  }

  .md\:tw--translate-y-12{
    --tw-translate-y: -3rem;
  }

  .md\:tw--translate-y-16{
    --tw-translate-y: -4rem;
  }

  .md\:tw--translate-y-20{
    --tw-translate-y: -5rem;
  }

  .md\:tw--translate-y-24{
    --tw-translate-y: -6rem;
  }

  .md\:tw--translate-y-32{
    --tw-translate-y: -8rem;
  }

  .md\:tw--translate-y-40{
    --tw-translate-y: -10rem;
  }

  .md\:tw--translate-y-48{
    --tw-translate-y: -12rem;
  }

  .md\:tw--translate-y-56{
    --tw-translate-y: -14rem;
  }

  .md\:tw--translate-y-64{
    --tw-translate-y: -16rem;
  }

  .md\:tw--translate-y-px{
    --tw-translate-y: -1px;
  }

  .md\:tw-translate-y-1\/2{
    --tw-translate-y: 50%;
  }

  .md\:tw-translate-y-1\/3{
    --tw-translate-y: 33.333333%;
  }

  .md\:tw-translate-y-2\/3{
    --tw-translate-y: 66.666667%;
  }

  .md\:tw-translate-y-1\/4{
    --tw-translate-y: 25%;
  }

  .md\:tw-translate-y-2\/4{
    --tw-translate-y: 50%;
  }

  .md\:tw-translate-y-3\/4{
    --tw-translate-y: 75%;
  }

  .md\:tw-translate-y-full{
    --tw-translate-y: 100%;
  }

  .md\:tw--translate-y-1\/2{
    --tw-translate-y: -50%;
  }

  .md\:tw--translate-y-1\/3{
    --tw-translate-y: -33.333333%;
  }

  .md\:tw--translate-y-2\/3{
    --tw-translate-y: -66.666667%;
  }

  .md\:tw--translate-y-1\/4{
    --tw-translate-y: -25%;
  }

  .md\:tw--translate-y-2\/4{
    --tw-translate-y: -50%;
  }

  .md\:tw--translate-y-3\/4{
    --tw-translate-y: -75%;
  }

  .md\:tw--translate-y-full{
    --tw-translate-y: -100%;
  }

  .md\:hover\:tw-translate-x-0:hover{
    --tw-translate-x: 0;
  }

  .md\:hover\:tw-translate-x-1:hover{
    --tw-translate-x: 0.25rem;
  }

  .md\:hover\:tw-translate-x-2:hover{
    --tw-translate-x: 0.5rem;
  }

  .md\:hover\:tw-translate-x-3:hover{
    --tw-translate-x: 0.75rem;
  }

  .md\:hover\:tw-translate-x-4:hover{
    --tw-translate-x: 1rem;
  }

  .md\:hover\:tw-translate-x-5:hover{
    --tw-translate-x: 1.25rem;
  }

  .md\:hover\:tw-translate-x-6:hover{
    --tw-translate-x: 1.5rem;
  }

  .md\:hover\:tw-translate-x-8:hover{
    --tw-translate-x: 2rem;
  }

  .md\:hover\:tw-translate-x-10:hover{
    --tw-translate-x: 2.5rem;
  }

  .md\:hover\:tw-translate-x-12:hover{
    --tw-translate-x: 3rem;
  }

  .md\:hover\:tw-translate-x-16:hover{
    --tw-translate-x: 4rem;
  }

  .md\:hover\:tw-translate-x-20:hover{
    --tw-translate-x: 5rem;
  }

  .md\:hover\:tw-translate-x-24:hover{
    --tw-translate-x: 6rem;
  }

  .md\:hover\:tw-translate-x-32:hover{
    --tw-translate-x: 8rem;
  }

  .md\:hover\:tw-translate-x-40:hover{
    --tw-translate-x: 10rem;
  }

  .md\:hover\:tw-translate-x-48:hover{
    --tw-translate-x: 12rem;
  }

  .md\:hover\:tw-translate-x-56:hover{
    --tw-translate-x: 14rem;
  }

  .md\:hover\:tw-translate-x-64:hover{
    --tw-translate-x: 16rem;
  }

  .md\:hover\:tw-translate-x-px:hover{
    --tw-translate-x: 1px;
  }

  .md\:hover\:tw--translate-x-1:hover{
    --tw-translate-x: -0.25rem;
  }

  .md\:hover\:tw--translate-x-2:hover{
    --tw-translate-x: -0.5rem;
  }

  .md\:hover\:tw--translate-x-3:hover{
    --tw-translate-x: -0.75rem;
  }

  .md\:hover\:tw--translate-x-4:hover{
    --tw-translate-x: -1rem;
  }

  .md\:hover\:tw--translate-x-5:hover{
    --tw-translate-x: -1.25rem;
  }

  .md\:hover\:tw--translate-x-6:hover{
    --tw-translate-x: -1.5rem;
  }

  .md\:hover\:tw--translate-x-8:hover{
    --tw-translate-x: -2rem;
  }

  .md\:hover\:tw--translate-x-10:hover{
    --tw-translate-x: -2.5rem;
  }

  .md\:hover\:tw--translate-x-12:hover{
    --tw-translate-x: -3rem;
  }

  .md\:hover\:tw--translate-x-16:hover{
    --tw-translate-x: -4rem;
  }

  .md\:hover\:tw--translate-x-20:hover{
    --tw-translate-x: -5rem;
  }

  .md\:hover\:tw--translate-x-24:hover{
    --tw-translate-x: -6rem;
  }

  .md\:hover\:tw--translate-x-32:hover{
    --tw-translate-x: -8rem;
  }

  .md\:hover\:tw--translate-x-40:hover{
    --tw-translate-x: -10rem;
  }

  .md\:hover\:tw--translate-x-48:hover{
    --tw-translate-x: -12rem;
  }

  .md\:hover\:tw--translate-x-56:hover{
    --tw-translate-x: -14rem;
  }

  .md\:hover\:tw--translate-x-64:hover{
    --tw-translate-x: -16rem;
  }

  .md\:hover\:tw--translate-x-px:hover{
    --tw-translate-x: -1px;
  }

  .md\:hover\:tw-translate-x-1\/2:hover{
    --tw-translate-x: 50%;
  }

  .md\:hover\:tw-translate-x-1\/3:hover{
    --tw-translate-x: 33.333333%;
  }

  .md\:hover\:tw-translate-x-2\/3:hover{
    --tw-translate-x: 66.666667%;
  }

  .md\:hover\:tw-translate-x-1\/4:hover{
    --tw-translate-x: 25%;
  }

  .md\:hover\:tw-translate-x-2\/4:hover{
    --tw-translate-x: 50%;
  }

  .md\:hover\:tw-translate-x-3\/4:hover{
    --tw-translate-x: 75%;
  }

  .md\:hover\:tw-translate-x-full:hover{
    --tw-translate-x: 100%;
  }

  .md\:hover\:tw--translate-x-1\/2:hover{
    --tw-translate-x: -50%;
  }

  .md\:hover\:tw--translate-x-1\/3:hover{
    --tw-translate-x: -33.333333%;
  }

  .md\:hover\:tw--translate-x-2\/3:hover{
    --tw-translate-x: -66.666667%;
  }

  .md\:hover\:tw--translate-x-1\/4:hover{
    --tw-translate-x: -25%;
  }

  .md\:hover\:tw--translate-x-2\/4:hover{
    --tw-translate-x: -50%;
  }

  .md\:hover\:tw--translate-x-3\/4:hover{
    --tw-translate-x: -75%;
  }

  .md\:hover\:tw--translate-x-full:hover{
    --tw-translate-x: -100%;
  }

  .md\:hover\:tw-translate-y-0:hover{
    --tw-translate-y: 0;
  }

  .md\:hover\:tw-translate-y-1:hover{
    --tw-translate-y: 0.25rem;
  }

  .md\:hover\:tw-translate-y-2:hover{
    --tw-translate-y: 0.5rem;
  }

  .md\:hover\:tw-translate-y-3:hover{
    --tw-translate-y: 0.75rem;
  }

  .md\:hover\:tw-translate-y-4:hover{
    --tw-translate-y: 1rem;
  }

  .md\:hover\:tw-translate-y-5:hover{
    --tw-translate-y: 1.25rem;
  }

  .md\:hover\:tw-translate-y-6:hover{
    --tw-translate-y: 1.5rem;
  }

  .md\:hover\:tw-translate-y-8:hover{
    --tw-translate-y: 2rem;
  }

  .md\:hover\:tw-translate-y-10:hover{
    --tw-translate-y: 2.5rem;
  }

  .md\:hover\:tw-translate-y-12:hover{
    --tw-translate-y: 3rem;
  }

  .md\:hover\:tw-translate-y-16:hover{
    --tw-translate-y: 4rem;
  }

  .md\:hover\:tw-translate-y-20:hover{
    --tw-translate-y: 5rem;
  }

  .md\:hover\:tw-translate-y-24:hover{
    --tw-translate-y: 6rem;
  }

  .md\:hover\:tw-translate-y-32:hover{
    --tw-translate-y: 8rem;
  }

  .md\:hover\:tw-translate-y-40:hover{
    --tw-translate-y: 10rem;
  }

  .md\:hover\:tw-translate-y-48:hover{
    --tw-translate-y: 12rem;
  }

  .md\:hover\:tw-translate-y-56:hover{
    --tw-translate-y: 14rem;
  }

  .md\:hover\:tw-translate-y-64:hover{
    --tw-translate-y: 16rem;
  }

  .md\:hover\:tw-translate-y-px:hover{
    --tw-translate-y: 1px;
  }

  .md\:hover\:tw--translate-y-1:hover{
    --tw-translate-y: -0.25rem;
  }

  .md\:hover\:tw--translate-y-2:hover{
    --tw-translate-y: -0.5rem;
  }

  .md\:hover\:tw--translate-y-3:hover{
    --tw-translate-y: -0.75rem;
  }

  .md\:hover\:tw--translate-y-4:hover{
    --tw-translate-y: -1rem;
  }

  .md\:hover\:tw--translate-y-5:hover{
    --tw-translate-y: -1.25rem;
  }

  .md\:hover\:tw--translate-y-6:hover{
    --tw-translate-y: -1.5rem;
  }

  .md\:hover\:tw--translate-y-8:hover{
    --tw-translate-y: -2rem;
  }

  .md\:hover\:tw--translate-y-10:hover{
    --tw-translate-y: -2.5rem;
  }

  .md\:hover\:tw--translate-y-12:hover{
    --tw-translate-y: -3rem;
  }

  .md\:hover\:tw--translate-y-16:hover{
    --tw-translate-y: -4rem;
  }

  .md\:hover\:tw--translate-y-20:hover{
    --tw-translate-y: -5rem;
  }

  .md\:hover\:tw--translate-y-24:hover{
    --tw-translate-y: -6rem;
  }

  .md\:hover\:tw--translate-y-32:hover{
    --tw-translate-y: -8rem;
  }

  .md\:hover\:tw--translate-y-40:hover{
    --tw-translate-y: -10rem;
  }

  .md\:hover\:tw--translate-y-48:hover{
    --tw-translate-y: -12rem;
  }

  .md\:hover\:tw--translate-y-56:hover{
    --tw-translate-y: -14rem;
  }

  .md\:hover\:tw--translate-y-64:hover{
    --tw-translate-y: -16rem;
  }

  .md\:hover\:tw--translate-y-px:hover{
    --tw-translate-y: -1px;
  }

  .md\:hover\:tw-translate-y-1\/2:hover{
    --tw-translate-y: 50%;
  }

  .md\:hover\:tw-translate-y-1\/3:hover{
    --tw-translate-y: 33.333333%;
  }

  .md\:hover\:tw-translate-y-2\/3:hover{
    --tw-translate-y: 66.666667%;
  }

  .md\:hover\:tw-translate-y-1\/4:hover{
    --tw-translate-y: 25%;
  }

  .md\:hover\:tw-translate-y-2\/4:hover{
    --tw-translate-y: 50%;
  }

  .md\:hover\:tw-translate-y-3\/4:hover{
    --tw-translate-y: 75%;
  }

  .md\:hover\:tw-translate-y-full:hover{
    --tw-translate-y: 100%;
  }

  .md\:hover\:tw--translate-y-1\/2:hover{
    --tw-translate-y: -50%;
  }

  .md\:hover\:tw--translate-y-1\/3:hover{
    --tw-translate-y: -33.333333%;
  }

  .md\:hover\:tw--translate-y-2\/3:hover{
    --tw-translate-y: -66.666667%;
  }

  .md\:hover\:tw--translate-y-1\/4:hover{
    --tw-translate-y: -25%;
  }

  .md\:hover\:tw--translate-y-2\/4:hover{
    --tw-translate-y: -50%;
  }

  .md\:hover\:tw--translate-y-3\/4:hover{
    --tw-translate-y: -75%;
  }

  .md\:hover\:tw--translate-y-full:hover{
    --tw-translate-y: -100%;
  }

  .md\:focus\:tw-translate-x-0:focus{
    --tw-translate-x: 0;
  }

  .md\:focus\:tw-translate-x-1:focus{
    --tw-translate-x: 0.25rem;
  }

  .md\:focus\:tw-translate-x-2:focus{
    --tw-translate-x: 0.5rem;
  }

  .md\:focus\:tw-translate-x-3:focus{
    --tw-translate-x: 0.75rem;
  }

  .md\:focus\:tw-translate-x-4:focus{
    --tw-translate-x: 1rem;
  }

  .md\:focus\:tw-translate-x-5:focus{
    --tw-translate-x: 1.25rem;
  }

  .md\:focus\:tw-translate-x-6:focus{
    --tw-translate-x: 1.5rem;
  }

  .md\:focus\:tw-translate-x-8:focus{
    --tw-translate-x: 2rem;
  }

  .md\:focus\:tw-translate-x-10:focus{
    --tw-translate-x: 2.5rem;
  }

  .md\:focus\:tw-translate-x-12:focus{
    --tw-translate-x: 3rem;
  }

  .md\:focus\:tw-translate-x-16:focus{
    --tw-translate-x: 4rem;
  }

  .md\:focus\:tw-translate-x-20:focus{
    --tw-translate-x: 5rem;
  }

  .md\:focus\:tw-translate-x-24:focus{
    --tw-translate-x: 6rem;
  }

  .md\:focus\:tw-translate-x-32:focus{
    --tw-translate-x: 8rem;
  }

  .md\:focus\:tw-translate-x-40:focus{
    --tw-translate-x: 10rem;
  }

  .md\:focus\:tw-translate-x-48:focus{
    --tw-translate-x: 12rem;
  }

  .md\:focus\:tw-translate-x-56:focus{
    --tw-translate-x: 14rem;
  }

  .md\:focus\:tw-translate-x-64:focus{
    --tw-translate-x: 16rem;
  }

  .md\:focus\:tw-translate-x-px:focus{
    --tw-translate-x: 1px;
  }

  .md\:focus\:tw--translate-x-1:focus{
    --tw-translate-x: -0.25rem;
  }

  .md\:focus\:tw--translate-x-2:focus{
    --tw-translate-x: -0.5rem;
  }

  .md\:focus\:tw--translate-x-3:focus{
    --tw-translate-x: -0.75rem;
  }

  .md\:focus\:tw--translate-x-4:focus{
    --tw-translate-x: -1rem;
  }

  .md\:focus\:tw--translate-x-5:focus{
    --tw-translate-x: -1.25rem;
  }

  .md\:focus\:tw--translate-x-6:focus{
    --tw-translate-x: -1.5rem;
  }

  .md\:focus\:tw--translate-x-8:focus{
    --tw-translate-x: -2rem;
  }

  .md\:focus\:tw--translate-x-10:focus{
    --tw-translate-x: -2.5rem;
  }

  .md\:focus\:tw--translate-x-12:focus{
    --tw-translate-x: -3rem;
  }

  .md\:focus\:tw--translate-x-16:focus{
    --tw-translate-x: -4rem;
  }

  .md\:focus\:tw--translate-x-20:focus{
    --tw-translate-x: -5rem;
  }

  .md\:focus\:tw--translate-x-24:focus{
    --tw-translate-x: -6rem;
  }

  .md\:focus\:tw--translate-x-32:focus{
    --tw-translate-x: -8rem;
  }

  .md\:focus\:tw--translate-x-40:focus{
    --tw-translate-x: -10rem;
  }

  .md\:focus\:tw--translate-x-48:focus{
    --tw-translate-x: -12rem;
  }

  .md\:focus\:tw--translate-x-56:focus{
    --tw-translate-x: -14rem;
  }

  .md\:focus\:tw--translate-x-64:focus{
    --tw-translate-x: -16rem;
  }

  .md\:focus\:tw--translate-x-px:focus{
    --tw-translate-x: -1px;
  }

  .md\:focus\:tw-translate-x-1\/2:focus{
    --tw-translate-x: 50%;
  }

  .md\:focus\:tw-translate-x-1\/3:focus{
    --tw-translate-x: 33.333333%;
  }

  .md\:focus\:tw-translate-x-2\/3:focus{
    --tw-translate-x: 66.666667%;
  }

  .md\:focus\:tw-translate-x-1\/4:focus{
    --tw-translate-x: 25%;
  }

  .md\:focus\:tw-translate-x-2\/4:focus{
    --tw-translate-x: 50%;
  }

  .md\:focus\:tw-translate-x-3\/4:focus{
    --tw-translate-x: 75%;
  }

  .md\:focus\:tw-translate-x-full:focus{
    --tw-translate-x: 100%;
  }

  .md\:focus\:tw--translate-x-1\/2:focus{
    --tw-translate-x: -50%;
  }

  .md\:focus\:tw--translate-x-1\/3:focus{
    --tw-translate-x: -33.333333%;
  }

  .md\:focus\:tw--translate-x-2\/3:focus{
    --tw-translate-x: -66.666667%;
  }

  .md\:focus\:tw--translate-x-1\/4:focus{
    --tw-translate-x: -25%;
  }

  .md\:focus\:tw--translate-x-2\/4:focus{
    --tw-translate-x: -50%;
  }

  .md\:focus\:tw--translate-x-3\/4:focus{
    --tw-translate-x: -75%;
  }

  .md\:focus\:tw--translate-x-full:focus{
    --tw-translate-x: -100%;
  }

  .md\:focus\:tw-translate-y-0:focus{
    --tw-translate-y: 0;
  }

  .md\:focus\:tw-translate-y-1:focus{
    --tw-translate-y: 0.25rem;
  }

  .md\:focus\:tw-translate-y-2:focus{
    --tw-translate-y: 0.5rem;
  }

  .md\:focus\:tw-translate-y-3:focus{
    --tw-translate-y: 0.75rem;
  }

  .md\:focus\:tw-translate-y-4:focus{
    --tw-translate-y: 1rem;
  }

  .md\:focus\:tw-translate-y-5:focus{
    --tw-translate-y: 1.25rem;
  }

  .md\:focus\:tw-translate-y-6:focus{
    --tw-translate-y: 1.5rem;
  }

  .md\:focus\:tw-translate-y-8:focus{
    --tw-translate-y: 2rem;
  }

  .md\:focus\:tw-translate-y-10:focus{
    --tw-translate-y: 2.5rem;
  }

  .md\:focus\:tw-translate-y-12:focus{
    --tw-translate-y: 3rem;
  }

  .md\:focus\:tw-translate-y-16:focus{
    --tw-translate-y: 4rem;
  }

  .md\:focus\:tw-translate-y-20:focus{
    --tw-translate-y: 5rem;
  }

  .md\:focus\:tw-translate-y-24:focus{
    --tw-translate-y: 6rem;
  }

  .md\:focus\:tw-translate-y-32:focus{
    --tw-translate-y: 8rem;
  }

  .md\:focus\:tw-translate-y-40:focus{
    --tw-translate-y: 10rem;
  }

  .md\:focus\:tw-translate-y-48:focus{
    --tw-translate-y: 12rem;
  }

  .md\:focus\:tw-translate-y-56:focus{
    --tw-translate-y: 14rem;
  }

  .md\:focus\:tw-translate-y-64:focus{
    --tw-translate-y: 16rem;
  }

  .md\:focus\:tw-translate-y-px:focus{
    --tw-translate-y: 1px;
  }

  .md\:focus\:tw--translate-y-1:focus{
    --tw-translate-y: -0.25rem;
  }

  .md\:focus\:tw--translate-y-2:focus{
    --tw-translate-y: -0.5rem;
  }

  .md\:focus\:tw--translate-y-3:focus{
    --tw-translate-y: -0.75rem;
  }

  .md\:focus\:tw--translate-y-4:focus{
    --tw-translate-y: -1rem;
  }

  .md\:focus\:tw--translate-y-5:focus{
    --tw-translate-y: -1.25rem;
  }

  .md\:focus\:tw--translate-y-6:focus{
    --tw-translate-y: -1.5rem;
  }

  .md\:focus\:tw--translate-y-8:focus{
    --tw-translate-y: -2rem;
  }

  .md\:focus\:tw--translate-y-10:focus{
    --tw-translate-y: -2.5rem;
  }

  .md\:focus\:tw--translate-y-12:focus{
    --tw-translate-y: -3rem;
  }

  .md\:focus\:tw--translate-y-16:focus{
    --tw-translate-y: -4rem;
  }

  .md\:focus\:tw--translate-y-20:focus{
    --tw-translate-y: -5rem;
  }

  .md\:focus\:tw--translate-y-24:focus{
    --tw-translate-y: -6rem;
  }

  .md\:focus\:tw--translate-y-32:focus{
    --tw-translate-y: -8rem;
  }

  .md\:focus\:tw--translate-y-40:focus{
    --tw-translate-y: -10rem;
  }

  .md\:focus\:tw--translate-y-48:focus{
    --tw-translate-y: -12rem;
  }

  .md\:focus\:tw--translate-y-56:focus{
    --tw-translate-y: -14rem;
  }

  .md\:focus\:tw--translate-y-64:focus{
    --tw-translate-y: -16rem;
  }

  .md\:focus\:tw--translate-y-px:focus{
    --tw-translate-y: -1px;
  }

  .md\:focus\:tw-translate-y-1\/2:focus{
    --tw-translate-y: 50%;
  }

  .md\:focus\:tw-translate-y-1\/3:focus{
    --tw-translate-y: 33.333333%;
  }

  .md\:focus\:tw-translate-y-2\/3:focus{
    --tw-translate-y: 66.666667%;
  }

  .md\:focus\:tw-translate-y-1\/4:focus{
    --tw-translate-y: 25%;
  }

  .md\:focus\:tw-translate-y-2\/4:focus{
    --tw-translate-y: 50%;
  }

  .md\:focus\:tw-translate-y-3\/4:focus{
    --tw-translate-y: 75%;
  }

  .md\:focus\:tw-translate-y-full:focus{
    --tw-translate-y: 100%;
  }

  .md\:focus\:tw--translate-y-1\/2:focus{
    --tw-translate-y: -50%;
  }

  .md\:focus\:tw--translate-y-1\/3:focus{
    --tw-translate-y: -33.333333%;
  }

  .md\:focus\:tw--translate-y-2\/3:focus{
    --tw-translate-y: -66.666667%;
  }

  .md\:focus\:tw--translate-y-1\/4:focus{
    --tw-translate-y: -25%;
  }

  .md\:focus\:tw--translate-y-2\/4:focus{
    --tw-translate-y: -50%;
  }

  .md\:focus\:tw--translate-y-3\/4:focus{
    --tw-translate-y: -75%;
  }

  .md\:focus\:tw--translate-y-full:focus{
    --tw-translate-y: -100%;
  }

  .md\:tw-rotate-0{
    --tw-rotate: 0;
  }

  .md\:tw-rotate-1{
    --tw-rotate: 1deg;
  }

  .md\:tw-rotate-2{
    --tw-rotate: 2deg;
  }

  .md\:tw-rotate-3{
    --tw-rotate: 3deg;
  }

  .md\:tw-rotate-6{
    --tw-rotate: 6deg;
  }

  .md\:tw-rotate-12{
    --tw-rotate: 12deg;
  }

  .md\:tw-rotate-45{
    --tw-rotate: 45deg;
  }

  .md\:tw-rotate-90{
    --tw-rotate: 90deg;
  }

  .md\:tw-rotate-180{
    --tw-rotate: 180deg;
  }

  .md\:tw--rotate-180{
    --tw-rotate: -180deg;
  }

  .md\:tw--rotate-90{
    --tw-rotate: -90deg;
  }

  .md\:tw--rotate-45{
    --tw-rotate: -45deg;
  }

  .md\:tw--rotate-12{
    --tw-rotate: -12deg;
  }

  .md\:tw--rotate-6{
    --tw-rotate: -6deg;
  }

  .md\:tw--rotate-3{
    --tw-rotate: -3deg;
  }

  .md\:tw--rotate-2{
    --tw-rotate: -2deg;
  }

  .md\:tw--rotate-1{
    --tw-rotate: -1deg;
  }

  .md\:hover\:tw-rotate-0:hover{
    --tw-rotate: 0;
  }

  .md\:hover\:tw-rotate-1:hover{
    --tw-rotate: 1deg;
  }

  .md\:hover\:tw-rotate-2:hover{
    --tw-rotate: 2deg;
  }

  .md\:hover\:tw-rotate-3:hover{
    --tw-rotate: 3deg;
  }

  .md\:hover\:tw-rotate-6:hover{
    --tw-rotate: 6deg;
  }

  .md\:hover\:tw-rotate-12:hover{
    --tw-rotate: 12deg;
  }

  .md\:hover\:tw-rotate-45:hover{
    --tw-rotate: 45deg;
  }

  .md\:hover\:tw-rotate-90:hover{
    --tw-rotate: 90deg;
  }

  .md\:hover\:tw-rotate-180:hover{
    --tw-rotate: 180deg;
  }

  .md\:hover\:tw--rotate-180:hover{
    --tw-rotate: -180deg;
  }

  .md\:hover\:tw--rotate-90:hover{
    --tw-rotate: -90deg;
  }

  .md\:hover\:tw--rotate-45:hover{
    --tw-rotate: -45deg;
  }

  .md\:hover\:tw--rotate-12:hover{
    --tw-rotate: -12deg;
  }

  .md\:hover\:tw--rotate-6:hover{
    --tw-rotate: -6deg;
  }

  .md\:hover\:tw--rotate-3:hover{
    --tw-rotate: -3deg;
  }

  .md\:hover\:tw--rotate-2:hover{
    --tw-rotate: -2deg;
  }

  .md\:hover\:tw--rotate-1:hover{
    --tw-rotate: -1deg;
  }

  .md\:focus\:tw-rotate-0:focus{
    --tw-rotate: 0;
  }

  .md\:focus\:tw-rotate-1:focus{
    --tw-rotate: 1deg;
  }

  .md\:focus\:tw-rotate-2:focus{
    --tw-rotate: 2deg;
  }

  .md\:focus\:tw-rotate-3:focus{
    --tw-rotate: 3deg;
  }

  .md\:focus\:tw-rotate-6:focus{
    --tw-rotate: 6deg;
  }

  .md\:focus\:tw-rotate-12:focus{
    --tw-rotate: 12deg;
  }

  .md\:focus\:tw-rotate-45:focus{
    --tw-rotate: 45deg;
  }

  .md\:focus\:tw-rotate-90:focus{
    --tw-rotate: 90deg;
  }

  .md\:focus\:tw-rotate-180:focus{
    --tw-rotate: 180deg;
  }

  .md\:focus\:tw--rotate-180:focus{
    --tw-rotate: -180deg;
  }

  .md\:focus\:tw--rotate-90:focus{
    --tw-rotate: -90deg;
  }

  .md\:focus\:tw--rotate-45:focus{
    --tw-rotate: -45deg;
  }

  .md\:focus\:tw--rotate-12:focus{
    --tw-rotate: -12deg;
  }

  .md\:focus\:tw--rotate-6:focus{
    --tw-rotate: -6deg;
  }

  .md\:focus\:tw--rotate-3:focus{
    --tw-rotate: -3deg;
  }

  .md\:focus\:tw--rotate-2:focus{
    --tw-rotate: -2deg;
  }

  .md\:focus\:tw--rotate-1:focus{
    --tw-rotate: -1deg;
  }

  .md\:tw-skew-x-0{
    --tw-skew-x: 0;
  }

  .md\:tw-skew-x-1{
    --tw-skew-x: 1deg;
  }

  .md\:tw-skew-x-2{
    --tw-skew-x: 2deg;
  }

  .md\:tw-skew-x-3{
    --tw-skew-x: 3deg;
  }

  .md\:tw-skew-x-6{
    --tw-skew-x: 6deg;
  }

  .md\:tw-skew-x-12{
    --tw-skew-x: 12deg;
  }

  .md\:tw--skew-x-12{
    --tw-skew-x: -12deg;
  }

  .md\:tw--skew-x-6{
    --tw-skew-x: -6deg;
  }

  .md\:tw--skew-x-3{
    --tw-skew-x: -3deg;
  }

  .md\:tw--skew-x-2{
    --tw-skew-x: -2deg;
  }

  .md\:tw--skew-x-1{
    --tw-skew-x: -1deg;
  }

  .md\:tw-skew-y-0{
    --tw-skew-y: 0;
  }

  .md\:tw-skew-y-1{
    --tw-skew-y: 1deg;
  }

  .md\:tw-skew-y-2{
    --tw-skew-y: 2deg;
  }

  .md\:tw-skew-y-3{
    --tw-skew-y: 3deg;
  }

  .md\:tw-skew-y-6{
    --tw-skew-y: 6deg;
  }

  .md\:tw-skew-y-12{
    --tw-skew-y: 12deg;
  }

  .md\:tw--skew-y-12{
    --tw-skew-y: -12deg;
  }

  .md\:tw--skew-y-6{
    --tw-skew-y: -6deg;
  }

  .md\:tw--skew-y-3{
    --tw-skew-y: -3deg;
  }

  .md\:tw--skew-y-2{
    --tw-skew-y: -2deg;
  }

  .md\:tw--skew-y-1{
    --tw-skew-y: -1deg;
  }

  .md\:hover\:tw-skew-x-0:hover{
    --tw-skew-x: 0;
  }

  .md\:hover\:tw-skew-x-1:hover{
    --tw-skew-x: 1deg;
  }

  .md\:hover\:tw-skew-x-2:hover{
    --tw-skew-x: 2deg;
  }

  .md\:hover\:tw-skew-x-3:hover{
    --tw-skew-x: 3deg;
  }

  .md\:hover\:tw-skew-x-6:hover{
    --tw-skew-x: 6deg;
  }

  .md\:hover\:tw-skew-x-12:hover{
    --tw-skew-x: 12deg;
  }

  .md\:hover\:tw--skew-x-12:hover{
    --tw-skew-x: -12deg;
  }

  .md\:hover\:tw--skew-x-6:hover{
    --tw-skew-x: -6deg;
  }

  .md\:hover\:tw--skew-x-3:hover{
    --tw-skew-x: -3deg;
  }

  .md\:hover\:tw--skew-x-2:hover{
    --tw-skew-x: -2deg;
  }

  .md\:hover\:tw--skew-x-1:hover{
    --tw-skew-x: -1deg;
  }

  .md\:hover\:tw-skew-y-0:hover{
    --tw-skew-y: 0;
  }

  .md\:hover\:tw-skew-y-1:hover{
    --tw-skew-y: 1deg;
  }

  .md\:hover\:tw-skew-y-2:hover{
    --tw-skew-y: 2deg;
  }

  .md\:hover\:tw-skew-y-3:hover{
    --tw-skew-y: 3deg;
  }

  .md\:hover\:tw-skew-y-6:hover{
    --tw-skew-y: 6deg;
  }

  .md\:hover\:tw-skew-y-12:hover{
    --tw-skew-y: 12deg;
  }

  .md\:hover\:tw--skew-y-12:hover{
    --tw-skew-y: -12deg;
  }

  .md\:hover\:tw--skew-y-6:hover{
    --tw-skew-y: -6deg;
  }

  .md\:hover\:tw--skew-y-3:hover{
    --tw-skew-y: -3deg;
  }

  .md\:hover\:tw--skew-y-2:hover{
    --tw-skew-y: -2deg;
  }

  .md\:hover\:tw--skew-y-1:hover{
    --tw-skew-y: -1deg;
  }

  .md\:focus\:tw-skew-x-0:focus{
    --tw-skew-x: 0;
  }

  .md\:focus\:tw-skew-x-1:focus{
    --tw-skew-x: 1deg;
  }

  .md\:focus\:tw-skew-x-2:focus{
    --tw-skew-x: 2deg;
  }

  .md\:focus\:tw-skew-x-3:focus{
    --tw-skew-x: 3deg;
  }

  .md\:focus\:tw-skew-x-6:focus{
    --tw-skew-x: 6deg;
  }

  .md\:focus\:tw-skew-x-12:focus{
    --tw-skew-x: 12deg;
  }

  .md\:focus\:tw--skew-x-12:focus{
    --tw-skew-x: -12deg;
  }

  .md\:focus\:tw--skew-x-6:focus{
    --tw-skew-x: -6deg;
  }

  .md\:focus\:tw--skew-x-3:focus{
    --tw-skew-x: -3deg;
  }

  .md\:focus\:tw--skew-x-2:focus{
    --tw-skew-x: -2deg;
  }

  .md\:focus\:tw--skew-x-1:focus{
    --tw-skew-x: -1deg;
  }

  .md\:focus\:tw-skew-y-0:focus{
    --tw-skew-y: 0;
  }

  .md\:focus\:tw-skew-y-1:focus{
    --tw-skew-y: 1deg;
  }

  .md\:focus\:tw-skew-y-2:focus{
    --tw-skew-y: 2deg;
  }

  .md\:focus\:tw-skew-y-3:focus{
    --tw-skew-y: 3deg;
  }

  .md\:focus\:tw-skew-y-6:focus{
    --tw-skew-y: 6deg;
  }

  .md\:focus\:tw-skew-y-12:focus{
    --tw-skew-y: 12deg;
  }

  .md\:focus\:tw--skew-y-12:focus{
    --tw-skew-y: -12deg;
  }

  .md\:focus\:tw--skew-y-6:focus{
    --tw-skew-y: -6deg;
  }

  .md\:focus\:tw--skew-y-3:focus{
    --tw-skew-y: -3deg;
  }

  .md\:focus\:tw--skew-y-2:focus{
    --tw-skew-y: -2deg;
  }

  .md\:focus\:tw--skew-y-1:focus{
    --tw-skew-y: -1deg;
  }

  .md\:tw-scale-0{
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .md\:tw-scale-50{
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .md\:tw-scale-75{
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .md\:tw-scale-90{
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .md\:tw-scale-95{
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .md\:tw-scale-100{
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .md\:tw-scale-105{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .md\:tw-scale-110{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .md\:tw-scale-125{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .md\:tw-scale-150{
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .md\:hover\:tw-scale-0:hover{
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .md\:hover\:tw-scale-50:hover{
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .md\:hover\:tw-scale-75:hover{
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .md\:hover\:tw-scale-90:hover{
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .md\:hover\:tw-scale-95:hover{
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .md\:hover\:tw-scale-100:hover{
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .md\:hover\:tw-scale-105:hover{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .md\:hover\:tw-scale-110:hover{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .md\:hover\:tw-scale-125:hover{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .md\:hover\:tw-scale-150:hover{
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .md\:focus\:tw-scale-0:focus{
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .md\:focus\:tw-scale-50:focus{
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .md\:focus\:tw-scale-75:focus{
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .md\:focus\:tw-scale-90:focus{
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .md\:focus\:tw-scale-95:focus{
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .md\:focus\:tw-scale-100:focus{
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .md\:focus\:tw-scale-105:focus{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .md\:focus\:tw-scale-110:focus{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .md\:focus\:tw-scale-125:focus{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .md\:focus\:tw-scale-150:focus{
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .md\:tw-scale-x-0{
    --tw-scale-x: 0;
  }

  .md\:tw-scale-x-50{
    --tw-scale-x: .5;
  }

  .md\:tw-scale-x-75{
    --tw-scale-x: .75;
  }

  .md\:tw-scale-x-90{
    --tw-scale-x: .9;
  }

  .md\:tw-scale-x-95{
    --tw-scale-x: .95;
  }

  .md\:tw-scale-x-100{
    --tw-scale-x: 1;
  }

  .md\:tw-scale-x-105{
    --tw-scale-x: 1.05;
  }

  .md\:tw-scale-x-110{
    --tw-scale-x: 1.1;
  }

  .md\:tw-scale-x-125{
    --tw-scale-x: 1.25;
  }

  .md\:tw-scale-x-150{
    --tw-scale-x: 1.5;
  }

  .md\:tw-scale-y-0{
    --tw-scale-y: 0;
  }

  .md\:tw-scale-y-50{
    --tw-scale-y: .5;
  }

  .md\:tw-scale-y-75{
    --tw-scale-y: .75;
  }

  .md\:tw-scale-y-90{
    --tw-scale-y: .9;
  }

  .md\:tw-scale-y-95{
    --tw-scale-y: .95;
  }

  .md\:tw-scale-y-100{
    --tw-scale-y: 1;
  }

  .md\:tw-scale-y-105{
    --tw-scale-y: 1.05;
  }

  .md\:tw-scale-y-110{
    --tw-scale-y: 1.1;
  }

  .md\:tw-scale-y-125{
    --tw-scale-y: 1.25;
  }

  .md\:tw-scale-y-150{
    --tw-scale-y: 1.5;
  }

  .md\:hover\:tw-scale-x-0:hover{
    --tw-scale-x: 0;
  }

  .md\:hover\:tw-scale-x-50:hover{
    --tw-scale-x: .5;
  }

  .md\:hover\:tw-scale-x-75:hover{
    --tw-scale-x: .75;
  }

  .md\:hover\:tw-scale-x-90:hover{
    --tw-scale-x: .9;
  }

  .md\:hover\:tw-scale-x-95:hover{
    --tw-scale-x: .95;
  }

  .md\:hover\:tw-scale-x-100:hover{
    --tw-scale-x: 1;
  }

  .md\:hover\:tw-scale-x-105:hover{
    --tw-scale-x: 1.05;
  }

  .md\:hover\:tw-scale-x-110:hover{
    --tw-scale-x: 1.1;
  }

  .md\:hover\:tw-scale-x-125:hover{
    --tw-scale-x: 1.25;
  }

  .md\:hover\:tw-scale-x-150:hover{
    --tw-scale-x: 1.5;
  }

  .md\:hover\:tw-scale-y-0:hover{
    --tw-scale-y: 0;
  }

  .md\:hover\:tw-scale-y-50:hover{
    --tw-scale-y: .5;
  }

  .md\:hover\:tw-scale-y-75:hover{
    --tw-scale-y: .75;
  }

  .md\:hover\:tw-scale-y-90:hover{
    --tw-scale-y: .9;
  }

  .md\:hover\:tw-scale-y-95:hover{
    --tw-scale-y: .95;
  }

  .md\:hover\:tw-scale-y-100:hover{
    --tw-scale-y: 1;
  }

  .md\:hover\:tw-scale-y-105:hover{
    --tw-scale-y: 1.05;
  }

  .md\:hover\:tw-scale-y-110:hover{
    --tw-scale-y: 1.1;
  }

  .md\:hover\:tw-scale-y-125:hover{
    --tw-scale-y: 1.25;
  }

  .md\:hover\:tw-scale-y-150:hover{
    --tw-scale-y: 1.5;
  }

  .md\:focus\:tw-scale-x-0:focus{
    --tw-scale-x: 0;
  }

  .md\:focus\:tw-scale-x-50:focus{
    --tw-scale-x: .5;
  }

  .md\:focus\:tw-scale-x-75:focus{
    --tw-scale-x: .75;
  }

  .md\:focus\:tw-scale-x-90:focus{
    --tw-scale-x: .9;
  }

  .md\:focus\:tw-scale-x-95:focus{
    --tw-scale-x: .95;
  }

  .md\:focus\:tw-scale-x-100:focus{
    --tw-scale-x: 1;
  }

  .md\:focus\:tw-scale-x-105:focus{
    --tw-scale-x: 1.05;
  }

  .md\:focus\:tw-scale-x-110:focus{
    --tw-scale-x: 1.1;
  }

  .md\:focus\:tw-scale-x-125:focus{
    --tw-scale-x: 1.25;
  }

  .md\:focus\:tw-scale-x-150:focus{
    --tw-scale-x: 1.5;
  }

  .md\:focus\:tw-scale-y-0:focus{
    --tw-scale-y: 0;
  }

  .md\:focus\:tw-scale-y-50:focus{
    --tw-scale-y: .5;
  }

  .md\:focus\:tw-scale-y-75:focus{
    --tw-scale-y: .75;
  }

  .md\:focus\:tw-scale-y-90:focus{
    --tw-scale-y: .9;
  }

  .md\:focus\:tw-scale-y-95:focus{
    --tw-scale-y: .95;
  }

  .md\:focus\:tw-scale-y-100:focus{
    --tw-scale-y: 1;
  }

  .md\:focus\:tw-scale-y-105:focus{
    --tw-scale-y: 1.05;
  }

  .md\:focus\:tw-scale-y-110:focus{
    --tw-scale-y: 1.1;
  }

  .md\:focus\:tw-scale-y-125:focus{
    --tw-scale-y: 1.25;
  }

  .md\:focus\:tw-scale-y-150:focus{
    --tw-scale-y: 1.5;
  }

  .md\:tw-animate-none{
    -webkit-animation: none;
    animation: none;
  }

  .md\:tw-animate-spin{
    -webkit-animation: tw-spin 1s linear infinite;
    animation: tw-spin 1s linear infinite;
  }

  .md\:tw-animate-ping{
    -webkit-animation: tw-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
    animation: tw-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .md\:tw-animate-pulse{
    -webkit-animation: tw-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    animation: tw-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .md\:tw-animate-bounce{
    -webkit-animation: tw-bounce 1s infinite;
    animation: tw-bounce 1s infinite;
  }

  .md\:tw-cursor-auto{
    cursor: auto;
  }

  .md\:tw-cursor-default{
    cursor: default;
  }

  .md\:tw-cursor-pointer{
    cursor: pointer;
  }

  .md\:tw-cursor-wait{
    cursor: wait;
  }

  .md\:tw-cursor-text{
    cursor: text;
  }

  .md\:tw-cursor-move{
    cursor: move;
  }

  .md\:tw-cursor-not-allowed{
    cursor: not-allowed;
  }

  .md\:tw-select-none{
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .md\:tw-select-text{
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }

  .md\:tw-select-all{
    -webkit-user-select: all;
    -ms-user-select: all;
    user-select: all;
  }

  .md\:tw-select-auto{
    -webkit-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
  }

  .md\:tw-resize-none{
    resize: none;
  }

  .md\:tw-resize-y{
    resize: vertical;
  }

  .md\:tw-resize-x{
    resize: horizontal;
  }

  .md\:tw-resize{
    resize: both;
  }

  .md\:tw-list-inside{
    list-style-position: inside;
  }

  .md\:tw-list-outside{
    list-style-position: outside;
  }

  .md\:tw-list-none{
    list-style-type: none;
  }

  .md\:tw-list-disc{
    list-style-type: disc;
  }

  .md\:tw-list-decimal{
    list-style-type: decimal;
  }

  .md\:tw-appearance-none{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .md\:tw-auto-cols-auto{
    grid-auto-columns: auto;
  }

  .md\:tw-auto-cols-min{
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
  }

  .md\:tw-auto-cols-max{
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
  }

  .md\:tw-auto-cols-fr{
    grid-auto-columns: minmax(0, 1fr);
  }

  .md\:tw-grid-flow-row{
    grid-auto-flow: row;
  }

  .md\:tw-grid-flow-col{
    grid-auto-flow: column;
  }

  .md\:tw-grid-flow-row-dense{
    grid-auto-flow: row dense;
  }

  .md\:tw-grid-flow-col-dense{
    grid-auto-flow: column dense;
  }

  .md\:tw-auto-rows-auto{
    grid-auto-rows: auto;
  }

  .md\:tw-auto-rows-min{
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
  }

  .md\:tw-auto-rows-max{
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
  }

  .md\:tw-auto-rows-fr{
    grid-auto-rows: minmax(0, 1fr);
  }

  .md\:tw-grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-6{
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-7{
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-8{
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-9{
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-10{
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-11{
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-12{
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-none{
    grid-template-columns: none;
  }

  .md\:tw-grid-rows-1{
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .md\:tw-grid-rows-2{
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .md\:tw-grid-rows-3{
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .md\:tw-grid-rows-4{
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .md\:tw-grid-rows-5{
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .md\:tw-grid-rows-6{
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .md\:tw-grid-rows-none{
    grid-template-rows: none;
  }

  .md\:tw-flex-row{
    flex-direction: row;
  }

  .md\:tw-flex-row-reverse{
    flex-direction: row-reverse;
  }

  .md\:tw-flex-col{
    flex-direction: column;
  }

  .md\:tw-flex-col-reverse{
    flex-direction: column-reverse;
  }

  .md\:tw-flex-wrap{
    flex-wrap: wrap;
  }

  .md\:tw-flex-wrap-reverse{
    flex-wrap: wrap-reverse;
  }

  .md\:tw-flex-nowrap{
    flex-wrap: nowrap;
  }

  .md\:tw-place-content-center{
    place-content: center;
  }

  .md\:tw-place-content-start{
    place-content: start;
  }

  .md\:tw-place-content-end{
    place-content: end;
  }

  .md\:tw-place-content-between{
    place-content: space-between;
  }

  .md\:tw-place-content-around{
    place-content: space-around;
  }

  .md\:tw-place-content-evenly{
    place-content: space-evenly;
  }

  .md\:tw-place-content-stretch{
    place-content: stretch;
  }

  .md\:tw-place-items-start{
    place-items: start;
  }

  .md\:tw-place-items-end{
    place-items: end;
  }

  .md\:tw-place-items-center{
    place-items: center;
  }

  .md\:tw-place-items-stretch{
    place-items: stretch;
  }

  .md\:tw-content-center{
    align-content: center;
  }

  .md\:tw-content-start{
    align-content: flex-start;
  }

  .md\:tw-content-end{
    align-content: flex-end;
  }

  .md\:tw-content-between{
    align-content: space-between;
  }

  .md\:tw-content-around{
    align-content: space-around;
  }

  .md\:tw-content-evenly{
    align-content: space-evenly;
  }

  .md\:tw-items-start{
    align-items: flex-start;
  }

  .md\:tw-items-end{
    align-items: flex-end;
  }

  .md\:tw-items-center{
    align-items: center;
  }

  .md\:tw-items-baseline{
    align-items: baseline;
  }

  .md\:tw-items-stretch{
    align-items: stretch;
  }

  .md\:tw-justify-start{
    justify-content: flex-start;
  }

  .md\:tw-justify-end{
    justify-content: flex-end;
  }

  .md\:tw-justify-center{
    justify-content: center;
  }

  .md\:tw-justify-between{
    justify-content: space-between;
  }

  .md\:tw-justify-around{
    justify-content: space-around;
  }

  .md\:tw-justify-evenly{
    justify-content: space-evenly;
  }

  .md\:tw-justify-items-start{
    justify-items: start;
  }

  .md\:tw-justify-items-end{
    justify-items: end;
  }

  .md\:tw-justify-items-center{
    justify-items: center;
  }

  .md\:tw-justify-items-stretch{
    justify-items: stretch;
  }

  .md\:tw-gap-0{
    gap: 0;
  }

  .md\:tw-gap-1{
    gap: 0.25rem;
  }

  .md\:tw-gap-2{
    gap: 0.5rem;
  }

  .md\:tw-gap-3{
    gap: 0.75rem;
  }

  .md\:tw-gap-4{
    gap: 1rem;
  }

  .md\:tw-gap-5{
    gap: 1.25rem;
  }

  .md\:tw-gap-6{
    gap: 1.5rem;
  }

  .md\:tw-gap-8{
    gap: 2rem;
  }

  .md\:tw-gap-10{
    gap: 2.5rem;
  }

  .md\:tw-gap-12{
    gap: 3rem;
  }

  .md\:tw-gap-16{
    gap: 4rem;
  }

  .md\:tw-gap-20{
    gap: 5rem;
  }

  .md\:tw-gap-24{
    gap: 6rem;
  }

  .md\:tw-gap-32{
    gap: 8rem;
  }

  .md\:tw-gap-40{
    gap: 10rem;
  }

  .md\:tw-gap-48{
    gap: 12rem;
  }

  .md\:tw-gap-56{
    gap: 14rem;
  }

  .md\:tw-gap-64{
    gap: 16rem;
  }

  .md\:tw-gap-px{
    gap: 1px;
  }

  .md\:tw-gap-x-0{
    -webkit-column-gap: 0;
    column-gap: 0;
  }

  .md\:tw-gap-x-1{
    -webkit-column-gap: 0.25rem;
    column-gap: 0.25rem;
  }

  .md\:tw-gap-x-2{
    -webkit-column-gap: 0.5rem;
    column-gap: 0.5rem;
  }

  .md\:tw-gap-x-3{
    -webkit-column-gap: 0.75rem;
    column-gap: 0.75rem;
  }

  .md\:tw-gap-x-4{
    -webkit-column-gap: 1rem;
    column-gap: 1rem;
  }

  .md\:tw-gap-x-5{
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }

  .md\:tw-gap-x-6{
    -webkit-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }

  .md\:tw-gap-x-8{
    -webkit-column-gap: 2rem;
    column-gap: 2rem;
  }

  .md\:tw-gap-x-10{
    -webkit-column-gap: 2.5rem;
    column-gap: 2.5rem;
  }

  .md\:tw-gap-x-12{
    -webkit-column-gap: 3rem;
    column-gap: 3rem;
  }

  .md\:tw-gap-x-16{
    -webkit-column-gap: 4rem;
    column-gap: 4rem;
  }

  .md\:tw-gap-x-20{
    -webkit-column-gap: 5rem;
    column-gap: 5rem;
  }

  .md\:tw-gap-x-24{
    -webkit-column-gap: 6rem;
    column-gap: 6rem;
  }

  .md\:tw-gap-x-32{
    -webkit-column-gap: 8rem;
    column-gap: 8rem;
  }

  .md\:tw-gap-x-40{
    -webkit-column-gap: 10rem;
    column-gap: 10rem;
  }

  .md\:tw-gap-x-48{
    -webkit-column-gap: 12rem;
    column-gap: 12rem;
  }

  .md\:tw-gap-x-56{
    -webkit-column-gap: 14rem;
    column-gap: 14rem;
  }

  .md\:tw-gap-x-64{
    -webkit-column-gap: 16rem;
    column-gap: 16rem;
  }

  .md\:tw-gap-x-px{
    -webkit-column-gap: 1px;
    column-gap: 1px;
  }

  .md\:tw-gap-y-0{
    row-gap: 0;
  }

  .md\:tw-gap-y-1{
    row-gap: 0.25rem;
  }

  .md\:tw-gap-y-2{
    row-gap: 0.5rem;
  }

  .md\:tw-gap-y-3{
    row-gap: 0.75rem;
  }

  .md\:tw-gap-y-4{
    row-gap: 1rem;
  }

  .md\:tw-gap-y-5{
    row-gap: 1.25rem;
  }

  .md\:tw-gap-y-6{
    row-gap: 1.5rem;
  }

  .md\:tw-gap-y-8{
    row-gap: 2rem;
  }

  .md\:tw-gap-y-10{
    row-gap: 2.5rem;
  }

  .md\:tw-gap-y-12{
    row-gap: 3rem;
  }

  .md\:tw-gap-y-16{
    row-gap: 4rem;
  }

  .md\:tw-gap-y-20{
    row-gap: 5rem;
  }

  .md\:tw-gap-y-24{
    row-gap: 6rem;
  }

  .md\:tw-gap-y-32{
    row-gap: 8rem;
  }

  .md\:tw-gap-y-40{
    row-gap: 10rem;
  }

  .md\:tw-gap-y-48{
    row-gap: 12rem;
  }

  .md\:tw-gap-y-56{
    row-gap: 14rem;
  }

  .md\:tw-gap-y-64{
    row-gap: 16rem;
  }

  .md\:tw-gap-y-px{
    row-gap: 1px;
  }

  .md\:tw-space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw-space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw-space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw-space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw-space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw-space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw-space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw-space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw-space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw-space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw-space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw-space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw-space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw-space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw-space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw-space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw-space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw-space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw-space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw--space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw--space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw--space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw--space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw--space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw--space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw--space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw--space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw--space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw--space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw--space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw--space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw--space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw--space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw--space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw--space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw--space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw--space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw-space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:tw-space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }

  .md\:tw-space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }

  .md\:tw-space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }

  .md\:tw-space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .md\:tw-space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .md\:tw-space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .md\:tw-space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .md\:tw-space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }

  .md\:tw-space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }

  .md\:tw-space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .md\:tw-space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .md\:tw-space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }

  .md\:tw-space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }

  .md\:tw-space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }

  .md\:tw-space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }

  .md\:tw-space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }

  .md\:tw-space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }

  .md\:tw-space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }

  .md\:tw--space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
  }

  .md\:tw--space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
  }

  .md\:tw--space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
  }

  .md\:tw--space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }

  .md\:tw--space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }

  .md\:tw--space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }

  .md\:tw--space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }

  .md\:tw--space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }

  .md\:tw--space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }

  .md\:tw--space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }

  .md\:tw--space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }

  .md\:tw--space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }

  .md\:tw--space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }

  .md\:tw--space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }

  .md\:tw--space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }

  .md\:tw--space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }

  .md\:tw--space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }

  .md\:tw--space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }

  .md\:tw-space-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 1;
  }

  .md\:tw-space-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 1;
  }

  .md\:tw-divide-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:tw-divide-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:tw-divide-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:tw-divide-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:tw-divide-x-default > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:tw-divide-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .md\:tw-divide-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }

  .md\:tw-divide-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }

  .md\:tw-divide-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }

  .md\:tw-divide-y-default > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .md\:tw-divide-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 1;
  }

  .md\:tw-divide-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 1;
  }

  .md\:tw-divide-solid > :not([hidden]) ~ :not([hidden]){
    border-style: solid;
  }

  .md\:tw-divide-dashed > :not([hidden]) ~ :not([hidden]){
    border-style: dashed;
  }

  .md\:tw-divide-dotted > :not([hidden]) ~ :not([hidden]){
    border-style: dotted;
  }

  .md\:tw-divide-double > :not([hidden]) ~ :not([hidden]){
    border-style: double;
  }

  .md\:tw-divide-none > :not([hidden]) ~ :not([hidden]){
    border-style: none;
  }

  .md\:tw-divide-transparent > :not([hidden]) ~ :not([hidden]){
    border-color: transparent;
  }

  .md\:tw-divide-current > :not([hidden]) ~ :not([hidden]){
    border-color: currentColor;
  }

  .md\:tw-divide-black > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(44, 44, 44, var(--tw-divide-opacity));
  }

  .md\:tw-divide-white > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }

  .md\:tw-divide-gray-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(247, 250, 252, var(--tw-divide-opacity));
  }

  .md\:tw-divide-gray-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(237, 242, 247, var(--tw-divide-opacity));
  }

  .md\:tw-divide-gray-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-divide-opacity));
  }

  .md\:tw-divide-gray-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(203, 213, 224, var(--tw-divide-opacity));
  }

  .md\:tw-divide-gray-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(160, 174, 192, var(--tw-divide-opacity));
  }

  .md\:tw-divide-gray-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(113, 128, 150, var(--tw-divide-opacity));
  }

  .md\:tw-divide-gray-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(74, 85, 104, var(--tw-divide-opacity));
  }

  .md\:tw-divide-gray-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(45, 55, 72, var(--tw-divide-opacity));
  }

  .md\:tw-divide-gray-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(26, 32, 44, var(--tw-divide-opacity));
  }

  .md\:tw-divide-blue-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(235, 248, 255, var(--tw-divide-opacity));
  }

  .md\:tw-divide-blue-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(190, 227, 248, var(--tw-divide-opacity));
  }

  .md\:tw-divide-blue-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(144, 205, 244, var(--tw-divide-opacity));
  }

  .md\:tw-divide-blue-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(99, 179, 237, var(--tw-divide-opacity));
  }

  .md\:tw-divide-blue-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(66, 153, 225, var(--tw-divide-opacity));
  }

  .md\:tw-divide-blue-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(49, 130, 206, var(--tw-divide-opacity));
  }

  .md\:tw-divide-blue-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(43, 108, 176, var(--tw-divide-opacity));
  }

  .md\:tw-divide-blue-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(44, 82, 130, var(--tw-divide-opacity));
  }

  .md\:tw-divide-blue-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(42, 67, 101, var(--tw-divide-opacity));
  }

  .md\:tw-divide-opacity-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0;
  }

  .md\:tw-divide-opacity-25 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.25;
  }

  .md\:tw-divide-opacity-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.5;
  }

  .md\:tw-divide-opacity-75 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.75;
  }

  .md\:tw-divide-opacity-90 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.9;
  }

  .md\:tw-divide-opacity-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
  }

  .md\:tw-place-self-auto{
    place-self: auto;
  }

  .md\:tw-place-self-start{
    place-self: start;
  }

  .md\:tw-place-self-end{
    place-self: end;
  }

  .md\:tw-place-self-center{
    place-self: center;
  }

  .md\:tw-place-self-stretch{
    place-self: stretch;
  }

  .md\:tw-self-auto{
    align-self: auto;
  }

  .md\:tw-self-start{
    align-self: flex-start;
  }

  .md\:tw-self-end{
    align-self: flex-end;
  }

  .md\:tw-self-center{
    align-self: center;
  }

  .md\:tw-self-stretch{
    align-self: stretch;
  }

  .md\:tw-self-baseline{
    align-self: baseline;
  }

  .md\:tw-justify-self-auto{
    justify-self: auto;
  }

  .md\:tw-justify-self-start{
    justify-self: start;
  }

  .md\:tw-justify-self-end{
    justify-self: end;
  }

  .md\:tw-justify-self-center{
    justify-self: center;
  }

  .md\:tw-justify-self-stretch{
    justify-self: stretch;
  }

  .md\:tw-overflow-auto{
    overflow: auto;
  }

  .md\:tw-overflow-hidden{
    overflow: hidden;
  }

  .md\:tw-overflow-visible{
    overflow: visible;
  }

  .md\:tw-overflow-scroll{
    overflow: scroll;
  }

  .md\:tw-overflow-x-auto{
    overflow-x: auto;
  }

  .md\:tw-overflow-y-auto{
    overflow-y: auto;
  }

  .md\:tw-overflow-x-hidden{
    overflow-x: hidden;
  }

  .md\:tw-overflow-y-hidden{
    overflow-y: hidden;
  }

  .md\:tw-overflow-x-visible{
    overflow-x: visible;
  }

  .md\:tw-overflow-y-visible{
    overflow-y: visible;
  }

  .md\:tw-overflow-x-scroll{
    overflow-x: scroll;
  }

  .md\:tw-overflow-y-scroll{
    overflow-y: scroll;
  }

  .md\:tw-overscroll-auto{
    overscroll-behavior: auto;
  }

  .md\:tw-overscroll-contain{
    overscroll-behavior: contain;
  }

  .md\:tw-overscroll-none{
    overscroll-behavior: none;
  }

  .md\:tw-overscroll-y-auto{
    overscroll-behavior-y: auto;
  }

  .md\:tw-overscroll-y-contain{
    overscroll-behavior-y: contain;
  }

  .md\:tw-overscroll-y-none{
    overscroll-behavior-y: none;
  }

  .md\:tw-overscroll-x-auto{
    overscroll-behavior-x: auto;
  }

  .md\:tw-overscroll-x-contain{
    overscroll-behavior-x: contain;
  }

  .md\:tw-overscroll-x-none{
    overscroll-behavior-x: none;
  }

  .md\:tw-truncate{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .md\:tw-overflow-ellipsis{
    text-overflow: ellipsis;
  }

  .md\:tw-overflow-clip{
    text-overflow: clip;
  }

  .md\:tw-whitespace-normal{
    white-space: normal;
  }

  .md\:tw-whitespace-nowrap{
    white-space: nowrap;
  }

  .md\:tw-whitespace-pre{
    white-space: pre;
  }

  .md\:tw-whitespace-pre-line{
    white-space: pre-line;
  }

  .md\:tw-whitespace-pre-wrap{
    white-space: pre-wrap;
  }

  .md\:tw-break-normal{
    overflow-wrap: normal;
    word-break: normal;
  }

  .md\:tw-break-words{
    overflow-wrap: break-word;
  }

  .md\:tw-break-all{
    word-break: break-all;
  }

  .md\:tw-rounded-none{
    border-radius: 0;
  }

  .md\:tw-rounded-sm{
    border-radius: 0.125rem;
  }

  .md\:tw-rounded-default{
    border-radius: 0.25rem;
  }

  .md\:tw-rounded-md{
    border-radius: 0.375rem;
  }

  .md\:tw-rounded-lg{
    border-radius: 0.5rem;
  }

  .md\:tw-rounded-xl{
    border-radius: 0.75rem;
  }

  .md\:tw-rounded-2xl{
    border-radius: 1rem;
  }

  .md\:tw-rounded-3xl{
    border-radius: 1.5rem;
  }

  .md\:tw-rounded-full{
    border-radius: 9999px;
  }

  .md\:tw-rounded-t-none{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .md\:tw-rounded-t-sm{
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }

  .md\:tw-rounded-t-default{
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .md\:tw-rounded-t-md{
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  .md\:tw-rounded-t-lg{
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .md\:tw-rounded-t-xl{
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }

  .md\:tw-rounded-t-2xl{
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .md\:tw-rounded-t-3xl{
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .md\:tw-rounded-t-full{
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .md\:tw-rounded-r-none{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .md\:tw-rounded-r-sm{
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }

  .md\:tw-rounded-r-default{
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .md\:tw-rounded-r-md{
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .md\:tw-rounded-r-lg{
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .md\:tw-rounded-r-xl{
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  .md\:tw-rounded-r-2xl{
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .md\:tw-rounded-r-3xl{
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .md\:tw-rounded-r-full{
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .md\:tw-rounded-b-none{
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .md\:tw-rounded-b-sm{
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .md\:tw-rounded-b-default{
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .md\:tw-rounded-b-md{
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .md\:tw-rounded-b-lg{
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .md\:tw-rounded-b-xl{
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .md\:tw-rounded-b-2xl{
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .md\:tw-rounded-b-3xl{
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .md\:tw-rounded-b-full{
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .md\:tw-rounded-l-none{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .md\:tw-rounded-l-sm{
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .md\:tw-rounded-l-default{
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .md\:tw-rounded-l-md{
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .md\:tw-rounded-l-lg{
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .md\:tw-rounded-l-xl{
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .md\:tw-rounded-l-2xl{
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .md\:tw-rounded-l-3xl{
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .md\:tw-rounded-l-full{
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .md\:tw-rounded-tl-none{
    border-top-left-radius: 0;
  }

  .md\:tw-rounded-tl-sm{
    border-top-left-radius: 0.125rem;
  }

  .md\:tw-rounded-tl-default{
    border-top-left-radius: 0.25rem;
  }

  .md\:tw-rounded-tl-md{
    border-top-left-radius: 0.375rem;
  }

  .md\:tw-rounded-tl-lg{
    border-top-left-radius: 0.5rem;
  }

  .md\:tw-rounded-tl-xl{
    border-top-left-radius: 0.75rem;
  }

  .md\:tw-rounded-tl-2xl{
    border-top-left-radius: 1rem;
  }

  .md\:tw-rounded-tl-3xl{
    border-top-left-radius: 1.5rem;
  }

  .md\:tw-rounded-tl-full{
    border-top-left-radius: 9999px;
  }

  .md\:tw-rounded-tr-none{
    border-top-right-radius: 0;
  }

  .md\:tw-rounded-tr-sm{
    border-top-right-radius: 0.125rem;
  }

  .md\:tw-rounded-tr-default{
    border-top-right-radius: 0.25rem;
  }

  .md\:tw-rounded-tr-md{
    border-top-right-radius: 0.375rem;
  }

  .md\:tw-rounded-tr-lg{
    border-top-right-radius: 0.5rem;
  }

  .md\:tw-rounded-tr-xl{
    border-top-right-radius: 0.75rem;
  }

  .md\:tw-rounded-tr-2xl{
    border-top-right-radius: 1rem;
  }

  .md\:tw-rounded-tr-3xl{
    border-top-right-radius: 1.5rem;
  }

  .md\:tw-rounded-tr-full{
    border-top-right-radius: 9999px;
  }

  .md\:tw-rounded-br-none{
    border-bottom-right-radius: 0;
  }

  .md\:tw-rounded-br-sm{
    border-bottom-right-radius: 0.125rem;
  }

  .md\:tw-rounded-br-default{
    border-bottom-right-radius: 0.25rem;
  }

  .md\:tw-rounded-br-md{
    border-bottom-right-radius: 0.375rem;
  }

  .md\:tw-rounded-br-lg{
    border-bottom-right-radius: 0.5rem;
  }

  .md\:tw-rounded-br-xl{
    border-bottom-right-radius: 0.75rem;
  }

  .md\:tw-rounded-br-2xl{
    border-bottom-right-radius: 1rem;
  }

  .md\:tw-rounded-br-3xl{
    border-bottom-right-radius: 1.5rem;
  }

  .md\:tw-rounded-br-full{
    border-bottom-right-radius: 9999px;
  }

  .md\:tw-rounded-bl-none{
    border-bottom-left-radius: 0;
  }

  .md\:tw-rounded-bl-sm{
    border-bottom-left-radius: 0.125rem;
  }

  .md\:tw-rounded-bl-default{
    border-bottom-left-radius: 0.25rem;
  }

  .md\:tw-rounded-bl-md{
    border-bottom-left-radius: 0.375rem;
  }

  .md\:tw-rounded-bl-lg{
    border-bottom-left-radius: 0.5rem;
  }

  .md\:tw-rounded-bl-xl{
    border-bottom-left-radius: 0.75rem;
  }

  .md\:tw-rounded-bl-2xl{
    border-bottom-left-radius: 1rem;
  }

  .md\:tw-rounded-bl-3xl{
    border-bottom-left-radius: 1.5rem;
  }

  .md\:tw-rounded-bl-full{
    border-bottom-left-radius: 9999px;
  }

  .md\:tw-border-0{
    border-width: 0;
  }

  .md\:tw-border-2{
    border-width: 2px;
  }

  .md\:tw-border-4{
    border-width: 4px;
  }

  .md\:tw-border-8{
    border-width: 8px;
  }

  .md\:tw-border-default{
    border-width: 1px;
  }

  .md\:tw-border-t-0{
    border-top-width: 0;
  }

  .md\:tw-border-t-2{
    border-top-width: 2px;
  }

  .md\:tw-border-t-4{
    border-top-width: 4px;
  }

  .md\:tw-border-t-8{
    border-top-width: 8px;
  }

  .md\:tw-border-t-default{
    border-top-width: 1px;
  }

  .md\:tw-border-r-0{
    border-right-width: 0;
  }

  .md\:tw-border-r-2{
    border-right-width: 2px;
  }

  .md\:tw-border-r-4{
    border-right-width: 4px;
  }

  .md\:tw-border-r-8{
    border-right-width: 8px;
  }

  .md\:tw-border-r-default{
    border-right-width: 1px;
  }

  .md\:tw-border-b-0{
    border-bottom-width: 0;
  }

  .md\:tw-border-b-2{
    border-bottom-width: 2px;
  }

  .md\:tw-border-b-4{
    border-bottom-width: 4px;
  }

  .md\:tw-border-b-8{
    border-bottom-width: 8px;
  }

  .md\:tw-border-b-default{
    border-bottom-width: 1px;
  }

  .md\:tw-border-l-0{
    border-left-width: 0;
  }

  .md\:tw-border-l-2{
    border-left-width: 2px;
  }

  .md\:tw-border-l-4{
    border-left-width: 4px;
  }

  .md\:tw-border-l-8{
    border-left-width: 8px;
  }

  .md\:tw-border-l-default{
    border-left-width: 1px;
  }

  .md\:tw-border-solid{
    border-style: solid;
  }

  .md\:tw-border-dashed{
    border-style: dashed;
  }

  .md\:tw-border-dotted{
    border-style: dotted;
  }

  .md\:tw-border-double{
    border-style: double;
  }

  .md\:tw-border-none{
    border-style: none;
  }

  .md\:tw-border-transparent{
    border-color: transparent;
  }

  .md\:tw-border-current{
    border-color: currentColor;
  }

  .md\:tw-border-black{
    --tw-border-opacity: 1;
    border-color: rgba(44, 44, 44, var(--tw-border-opacity));
  }

  .md\:tw-border-white{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .md\:tw-border-gray-100{
    --tw-border-opacity: 1;
    border-color: rgba(247, 250, 252, var(--tw-border-opacity));
  }

  .md\:tw-border-gray-200{
    --tw-border-opacity: 1;
    border-color: rgba(237, 242, 247, var(--tw-border-opacity));
  }

  .md\:tw-border-gray-300{
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
  }

  .md\:tw-border-gray-400{
    --tw-border-opacity: 1;
    border-color: rgba(203, 213, 224, var(--tw-border-opacity));
  }

  .md\:tw-border-gray-500{
    --tw-border-opacity: 1;
    border-color: rgba(160, 174, 192, var(--tw-border-opacity));
  }

  .md\:tw-border-gray-600{
    --tw-border-opacity: 1;
    border-color: rgba(113, 128, 150, var(--tw-border-opacity));
  }

  .md\:tw-border-gray-700{
    --tw-border-opacity: 1;
    border-color: rgba(74, 85, 104, var(--tw-border-opacity));
  }

  .md\:tw-border-gray-800{
    --tw-border-opacity: 1;
    border-color: rgba(45, 55, 72, var(--tw-border-opacity));
  }

  .md\:tw-border-gray-900{
    --tw-border-opacity: 1;
    border-color: rgba(26, 32, 44, var(--tw-border-opacity));
  }

  .md\:tw-border-blue-100{
    --tw-border-opacity: 1;
    border-color: rgba(235, 248, 255, var(--tw-border-opacity));
  }

  .md\:tw-border-blue-200{
    --tw-border-opacity: 1;
    border-color: rgba(190, 227, 248, var(--tw-border-opacity));
  }

  .md\:tw-border-blue-300{
    --tw-border-opacity: 1;
    border-color: rgba(144, 205, 244, var(--tw-border-opacity));
  }

  .md\:tw-border-blue-400{
    --tw-border-opacity: 1;
    border-color: rgba(99, 179, 237, var(--tw-border-opacity));
  }

  .md\:tw-border-blue-500{
    --tw-border-opacity: 1;
    border-color: rgba(66, 153, 225, var(--tw-border-opacity));
  }

  .md\:tw-border-blue-600{
    --tw-border-opacity: 1;
    border-color: rgba(49, 130, 206, var(--tw-border-opacity));
  }

  .md\:tw-border-blue-700{
    --tw-border-opacity: 1;
    border-color: rgba(43, 108, 176, var(--tw-border-opacity));
  }

  .md\:tw-border-blue-800{
    --tw-border-opacity: 1;
    border-color: rgba(44, 82, 130, var(--tw-border-opacity));
  }

  .md\:tw-border-blue-900{
    --tw-border-opacity: 1;
    border-color: rgba(42, 67, 101, var(--tw-border-opacity));
  }

  .md\:hover\:tw-border-transparent:hover{
    border-color: transparent;
  }

  .md\:hover\:tw-border-current:hover{
    border-color: currentColor;
  }

  .md\:hover\:tw-border-black:hover{
    --tw-border-opacity: 1;
    border-color: rgba(44, 44, 44, var(--tw-border-opacity));
  }

  .md\:hover\:tw-border-white:hover{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .md\:hover\:tw-border-gray-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(247, 250, 252, var(--tw-border-opacity));
  }

  .md\:hover\:tw-border-gray-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(237, 242, 247, var(--tw-border-opacity));
  }

  .md\:hover\:tw-border-gray-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
  }

  .md\:hover\:tw-border-gray-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(203, 213, 224, var(--tw-border-opacity));
  }

  .md\:hover\:tw-border-gray-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(160, 174, 192, var(--tw-border-opacity));
  }

  .md\:hover\:tw-border-gray-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(113, 128, 150, var(--tw-border-opacity));
  }

  .md\:hover\:tw-border-gray-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(74, 85, 104, var(--tw-border-opacity));
  }

  .md\:hover\:tw-border-gray-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(45, 55, 72, var(--tw-border-opacity));
  }

  .md\:hover\:tw-border-gray-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(26, 32, 44, var(--tw-border-opacity));
  }

  .md\:hover\:tw-border-blue-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(235, 248, 255, var(--tw-border-opacity));
  }

  .md\:hover\:tw-border-blue-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(190, 227, 248, var(--tw-border-opacity));
  }

  .md\:hover\:tw-border-blue-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(144, 205, 244, var(--tw-border-opacity));
  }

  .md\:hover\:tw-border-blue-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(99, 179, 237, var(--tw-border-opacity));
  }

  .md\:hover\:tw-border-blue-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(66, 153, 225, var(--tw-border-opacity));
  }

  .md\:hover\:tw-border-blue-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(49, 130, 206, var(--tw-border-opacity));
  }

  .md\:hover\:tw-border-blue-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(43, 108, 176, var(--tw-border-opacity));
  }

  .md\:hover\:tw-border-blue-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(44, 82, 130, var(--tw-border-opacity));
  }

  .md\:hover\:tw-border-blue-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(42, 67, 101, var(--tw-border-opacity));
  }

  .md\:focus\:tw-border-transparent:focus{
    border-color: transparent;
  }

  .md\:focus\:tw-border-current:focus{
    border-color: currentColor;
  }

  .md\:focus\:tw-border-black:focus{
    --tw-border-opacity: 1;
    border-color: rgba(44, 44, 44, var(--tw-border-opacity));
  }

  .md\:focus\:tw-border-white:focus{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .md\:focus\:tw-border-gray-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(247, 250, 252, var(--tw-border-opacity));
  }

  .md\:focus\:tw-border-gray-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(237, 242, 247, var(--tw-border-opacity));
  }

  .md\:focus\:tw-border-gray-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
  }

  .md\:focus\:tw-border-gray-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(203, 213, 224, var(--tw-border-opacity));
  }

  .md\:focus\:tw-border-gray-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(160, 174, 192, var(--tw-border-opacity));
  }

  .md\:focus\:tw-border-gray-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(113, 128, 150, var(--tw-border-opacity));
  }

  .md\:focus\:tw-border-gray-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(74, 85, 104, var(--tw-border-opacity));
  }

  .md\:focus\:tw-border-gray-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(45, 55, 72, var(--tw-border-opacity));
  }

  .md\:focus\:tw-border-gray-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(26, 32, 44, var(--tw-border-opacity));
  }

  .md\:focus\:tw-border-blue-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(235, 248, 255, var(--tw-border-opacity));
  }

  .md\:focus\:tw-border-blue-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(190, 227, 248, var(--tw-border-opacity));
  }

  .md\:focus\:tw-border-blue-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(144, 205, 244, var(--tw-border-opacity));
  }

  .md\:focus\:tw-border-blue-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(99, 179, 237, var(--tw-border-opacity));
  }

  .md\:focus\:tw-border-blue-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(66, 153, 225, var(--tw-border-opacity));
  }

  .md\:focus\:tw-border-blue-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(49, 130, 206, var(--tw-border-opacity));
  }

  .md\:focus\:tw-border-blue-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(43, 108, 176, var(--tw-border-opacity));
  }

  .md\:focus\:tw-border-blue-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(44, 82, 130, var(--tw-border-opacity));
  }

  .md\:focus\:tw-border-blue-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(42, 67, 101, var(--tw-border-opacity));
  }

  .md\:tw-border-opacity-0{
    --tw-border-opacity: 0;
  }

  .md\:tw-border-opacity-25{
    --tw-border-opacity: 0.25;
  }

  .md\:tw-border-opacity-50{
    --tw-border-opacity: 0.5;
  }

  .md\:tw-border-opacity-75{
    --tw-border-opacity: 0.75;
  }

  .md\:tw-border-opacity-90{
    --tw-border-opacity: 0.9;
  }

  .md\:tw-border-opacity-100{
    --tw-border-opacity: 1;
  }

  .md\:hover\:tw-border-opacity-0:hover{
    --tw-border-opacity: 0;
  }

  .md\:hover\:tw-border-opacity-25:hover{
    --tw-border-opacity: 0.25;
  }

  .md\:hover\:tw-border-opacity-50:hover{
    --tw-border-opacity: 0.5;
  }

  .md\:hover\:tw-border-opacity-75:hover{
    --tw-border-opacity: 0.75;
  }

  .md\:hover\:tw-border-opacity-90:hover{
    --tw-border-opacity: 0.9;
  }

  .md\:hover\:tw-border-opacity-100:hover{
    --tw-border-opacity: 1;
  }

  .md\:focus\:tw-border-opacity-0:focus{
    --tw-border-opacity: 0;
  }

  .md\:focus\:tw-border-opacity-25:focus{
    --tw-border-opacity: 0.25;
  }

  .md\:focus\:tw-border-opacity-50:focus{
    --tw-border-opacity: 0.5;
  }

  .md\:focus\:tw-border-opacity-75:focus{
    --tw-border-opacity: 0.75;
  }

  .md\:focus\:tw-border-opacity-90:focus{
    --tw-border-opacity: 0.9;
  }

  .md\:focus\:tw-border-opacity-100:focus{
    --tw-border-opacity: 1;
  }

  .md\:tw-bg-transparent{
    background-color: transparent;
  }

  .md\:tw-bg-current{
    background-color: currentColor;
  }

  .md\:tw-bg-black{
    --tw-bg-opacity: 1;
    background-color: rgba(44, 44, 44, var(--tw-bg-opacity));
  }

  .md\:tw-bg-white{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .md\:tw-bg-gray-100{
    --tw-bg-opacity: 1;
    background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
  }

  .md\:tw-bg-gray-200{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 242, 247, var(--tw-bg-opacity));
  }

  .md\:tw-bg-gray-300{
    --tw-bg-opacity: 1;
    background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
  }

  .md\:tw-bg-gray-400{
    --tw-bg-opacity: 1;
    background-color: rgba(203, 213, 224, var(--tw-bg-opacity));
  }

  .md\:tw-bg-gray-500{
    --tw-bg-opacity: 1;
    background-color: rgba(160, 174, 192, var(--tw-bg-opacity));
  }

  .md\:tw-bg-gray-600{
    --tw-bg-opacity: 1;
    background-color: rgba(113, 128, 150, var(--tw-bg-opacity));
  }

  .md\:tw-bg-gray-700{
    --tw-bg-opacity: 1;
    background-color: rgba(74, 85, 104, var(--tw-bg-opacity));
  }

  .md\:tw-bg-gray-800{
    --tw-bg-opacity: 1;
    background-color: rgba(45, 55, 72, var(--tw-bg-opacity));
  }

  .md\:tw-bg-gray-900{
    --tw-bg-opacity: 1;
    background-color: rgba(26, 32, 44, var(--tw-bg-opacity));
  }

  .md\:tw-bg-blue-100{
    --tw-bg-opacity: 1;
    background-color: rgba(235, 248, 255, var(--tw-bg-opacity));
  }

  .md\:tw-bg-blue-200{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 227, 248, var(--tw-bg-opacity));
  }

  .md\:tw-bg-blue-300{
    --tw-bg-opacity: 1;
    background-color: rgba(144, 205, 244, var(--tw-bg-opacity));
  }

  .md\:tw-bg-blue-400{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 179, 237, var(--tw-bg-opacity));
  }

  .md\:tw-bg-blue-500{
    --tw-bg-opacity: 1;
    background-color: rgba(66, 153, 225, var(--tw-bg-opacity));
  }

  .md\:tw-bg-blue-600{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 130, 206, var(--tw-bg-opacity));
  }

  .md\:tw-bg-blue-700{
    --tw-bg-opacity: 1;
    background-color: rgba(43, 108, 176, var(--tw-bg-opacity));
  }

  .md\:tw-bg-blue-800{
    --tw-bg-opacity: 1;
    background-color: rgba(44, 82, 130, var(--tw-bg-opacity));
  }

  .md\:tw-bg-blue-900{
    --tw-bg-opacity: 1;
    background-color: rgba(42, 67, 101, var(--tw-bg-opacity));
  }

  .md\:hover\:tw-bg-transparent:hover{
    background-color: transparent;
  }

  .md\:hover\:tw-bg-current:hover{
    background-color: currentColor;
  }

  .md\:hover\:tw-bg-black:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(44, 44, 44, var(--tw-bg-opacity));
  }

  .md\:hover\:tw-bg-white:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .md\:hover\:tw-bg-gray-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
  }

  .md\:hover\:tw-bg-gray-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 242, 247, var(--tw-bg-opacity));
  }

  .md\:hover\:tw-bg-gray-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
  }

  .md\:hover\:tw-bg-gray-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(203, 213, 224, var(--tw-bg-opacity));
  }

  .md\:hover\:tw-bg-gray-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(160, 174, 192, var(--tw-bg-opacity));
  }

  .md\:hover\:tw-bg-gray-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(113, 128, 150, var(--tw-bg-opacity));
  }

  .md\:hover\:tw-bg-gray-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(74, 85, 104, var(--tw-bg-opacity));
  }

  .md\:hover\:tw-bg-gray-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(45, 55, 72, var(--tw-bg-opacity));
  }

  .md\:hover\:tw-bg-gray-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(26, 32, 44, var(--tw-bg-opacity));
  }

  .md\:hover\:tw-bg-blue-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(235, 248, 255, var(--tw-bg-opacity));
  }

  .md\:hover\:tw-bg-blue-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 227, 248, var(--tw-bg-opacity));
  }

  .md\:hover\:tw-bg-blue-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(144, 205, 244, var(--tw-bg-opacity));
  }

  .md\:hover\:tw-bg-blue-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 179, 237, var(--tw-bg-opacity));
  }

  .md\:hover\:tw-bg-blue-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(66, 153, 225, var(--tw-bg-opacity));
  }

  .md\:hover\:tw-bg-blue-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 130, 206, var(--tw-bg-opacity));
  }

  .md\:hover\:tw-bg-blue-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(43, 108, 176, var(--tw-bg-opacity));
  }

  .md\:hover\:tw-bg-blue-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(44, 82, 130, var(--tw-bg-opacity));
  }

  .md\:hover\:tw-bg-blue-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(42, 67, 101, var(--tw-bg-opacity));
  }

  .md\:focus\:tw-bg-transparent:focus{
    background-color: transparent;
  }

  .md\:focus\:tw-bg-current:focus{
    background-color: currentColor;
  }

  .md\:focus\:tw-bg-black:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(44, 44, 44, var(--tw-bg-opacity));
  }

  .md\:focus\:tw-bg-white:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .md\:focus\:tw-bg-gray-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
  }

  .md\:focus\:tw-bg-gray-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 242, 247, var(--tw-bg-opacity));
  }

  .md\:focus\:tw-bg-gray-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
  }

  .md\:focus\:tw-bg-gray-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(203, 213, 224, var(--tw-bg-opacity));
  }

  .md\:focus\:tw-bg-gray-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(160, 174, 192, var(--tw-bg-opacity));
  }

  .md\:focus\:tw-bg-gray-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(113, 128, 150, var(--tw-bg-opacity));
  }

  .md\:focus\:tw-bg-gray-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(74, 85, 104, var(--tw-bg-opacity));
  }

  .md\:focus\:tw-bg-gray-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(45, 55, 72, var(--tw-bg-opacity));
  }

  .md\:focus\:tw-bg-gray-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(26, 32, 44, var(--tw-bg-opacity));
  }

  .md\:focus\:tw-bg-blue-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(235, 248, 255, var(--tw-bg-opacity));
  }

  .md\:focus\:tw-bg-blue-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 227, 248, var(--tw-bg-opacity));
  }

  .md\:focus\:tw-bg-blue-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(144, 205, 244, var(--tw-bg-opacity));
  }

  .md\:focus\:tw-bg-blue-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 179, 237, var(--tw-bg-opacity));
  }

  .md\:focus\:tw-bg-blue-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(66, 153, 225, var(--tw-bg-opacity));
  }

  .md\:focus\:tw-bg-blue-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 130, 206, var(--tw-bg-opacity));
  }

  .md\:focus\:tw-bg-blue-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(43, 108, 176, var(--tw-bg-opacity));
  }

  .md\:focus\:tw-bg-blue-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(44, 82, 130, var(--tw-bg-opacity));
  }

  .md\:focus\:tw-bg-blue-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(42, 67, 101, var(--tw-bg-opacity));
  }

  .md\:tw-bg-opacity-0{
    --tw-bg-opacity: 0;
  }

  .md\:tw-bg-opacity-25{
    --tw-bg-opacity: 0.25;
  }

  .md\:tw-bg-opacity-50{
    --tw-bg-opacity: 0.5;
  }

  .md\:tw-bg-opacity-75{
    --tw-bg-opacity: 0.75;
  }

  .md\:tw-bg-opacity-90{
    --tw-bg-opacity: 0.9;
  }

  .md\:tw-bg-opacity-100{
    --tw-bg-opacity: 1;
  }

  .md\:hover\:tw-bg-opacity-0:hover{
    --tw-bg-opacity: 0;
  }

  .md\:hover\:tw-bg-opacity-25:hover{
    --tw-bg-opacity: 0.25;
  }

  .md\:hover\:tw-bg-opacity-50:hover{
    --tw-bg-opacity: 0.5;
  }

  .md\:hover\:tw-bg-opacity-75:hover{
    --tw-bg-opacity: 0.75;
  }

  .md\:hover\:tw-bg-opacity-90:hover{
    --tw-bg-opacity: 0.9;
  }

  .md\:hover\:tw-bg-opacity-100:hover{
    --tw-bg-opacity: 1;
  }

  .md\:focus\:tw-bg-opacity-0:focus{
    --tw-bg-opacity: 0;
  }

  .md\:focus\:tw-bg-opacity-25:focus{
    --tw-bg-opacity: 0.25;
  }

  .md\:focus\:tw-bg-opacity-50:focus{
    --tw-bg-opacity: 0.5;
  }

  .md\:focus\:tw-bg-opacity-75:focus{
    --tw-bg-opacity: 0.75;
  }

  .md\:focus\:tw-bg-opacity-90:focus{
    --tw-bg-opacity: 0.9;
  }

  .md\:focus\:tw-bg-opacity-100:focus{
    --tw-bg-opacity: 1;
  }

  .md\:tw-bg-none{
    background-image: none;
  }

  .md\:tw-bg-gradient-to-t{
    background-image: linear-gradient(to top, var(--gradient-color-stops));
  }

  .md\:tw-bg-gradient-to-tr{
    background-image: linear-gradient(to top right, var(--gradient-color-stops));
  }

  .md\:tw-bg-gradient-to-r{
    background-image: linear-gradient(to right, var(--gradient-color-stops));
  }

  .md\:tw-bg-gradient-to-br{
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops));
  }

  .md\:tw-bg-gradient-to-b{
    background-image: linear-gradient(to bottom, var(--gradient-color-stops));
  }

  .md\:tw-bg-gradient-to-bl{
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops));
  }

  .md\:tw-bg-gradient-to-l{
    background-image: linear-gradient(to left, var(--gradient-color-stops));
  }

  .md\:tw-bg-gradient-to-tl{
    background-image: linear-gradient(to top left, var(--gradient-color-stops));
  }

  .md\:tw-from-transparent{
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .md\:tw-from-current{
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:tw-from-black{
    --tw-gradient-from: #2c2c2c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 44, 44, 0));
  }

  .md\:tw-from-white{
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:tw-from-gray-100{
    --tw-gradient-from: #f7fafc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 250, 252, 0));
  }

  .md\:tw-from-gray-200{
    --tw-gradient-from: #edf2f7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 242, 247, 0));
  }

  .md\:tw-from-gray-300{
    --tw-gradient-from: #e2e8f0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(226, 232, 240, 0));
  }

  .md\:tw-from-gray-400{
    --tw-gradient-from: #cbd5e0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(203, 213, 224, 0));
  }

  .md\:tw-from-gray-500{
    --tw-gradient-from: #a0aec0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(160, 174, 192, 0));
  }

  .md\:tw-from-gray-600{
    --tw-gradient-from: #718096;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(113, 128, 150, 0));
  }

  .md\:tw-from-gray-700{
    --tw-gradient-from: #4a5568;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 85, 104, 0));
  }

  .md\:tw-from-gray-800{
    --tw-gradient-from: #2d3748;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 72, 0));
  }

  .md\:tw-from-gray-900{
    --tw-gradient-from: #1a202c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 32, 44, 0));
  }

  .md\:tw-from-blue-100{
    --tw-gradient-from: #ebf8ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(235, 248, 255, 0));
  }

  .md\:tw-from-blue-200{
    --tw-gradient-from: #bee3f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 227, 248, 0));
  }

  .md\:tw-from-blue-300{
    --tw-gradient-from: #90cdf4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 205, 244, 0));
  }

  .md\:tw-from-blue-400{
    --tw-gradient-from: #63b3ed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 179, 237, 0));
  }

  .md\:tw-from-blue-500{
    --tw-gradient-from: #4299e1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 153, 225, 0));
  }

  .md\:tw-from-blue-600{
    --tw-gradient-from: #3182ce;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 130, 206, 0));
  }

  .md\:tw-from-blue-700{
    --tw-gradient-from: #2b6cb0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(43, 108, 176, 0));
  }

  .md\:tw-from-blue-800{
    --tw-gradient-from: #2c5282;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 82, 130, 0));
  }

  .md\:tw-from-blue-900{
    --tw-gradient-from: #2a4365;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(42, 67, 101, 0));
  }

  .md\:hover\:tw-from-transparent:hover{
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .md\:hover\:tw-from-current:hover{
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:hover\:tw-from-black:hover{
    --tw-gradient-from: #2c2c2c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 44, 44, 0));
  }

  .md\:hover\:tw-from-white:hover{
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:hover\:tw-from-gray-100:hover{
    --tw-gradient-from: #f7fafc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 250, 252, 0));
  }

  .md\:hover\:tw-from-gray-200:hover{
    --tw-gradient-from: #edf2f7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 242, 247, 0));
  }

  .md\:hover\:tw-from-gray-300:hover{
    --tw-gradient-from: #e2e8f0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(226, 232, 240, 0));
  }

  .md\:hover\:tw-from-gray-400:hover{
    --tw-gradient-from: #cbd5e0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(203, 213, 224, 0));
  }

  .md\:hover\:tw-from-gray-500:hover{
    --tw-gradient-from: #a0aec0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(160, 174, 192, 0));
  }

  .md\:hover\:tw-from-gray-600:hover{
    --tw-gradient-from: #718096;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(113, 128, 150, 0));
  }

  .md\:hover\:tw-from-gray-700:hover{
    --tw-gradient-from: #4a5568;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 85, 104, 0));
  }

  .md\:hover\:tw-from-gray-800:hover{
    --tw-gradient-from: #2d3748;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 72, 0));
  }

  .md\:hover\:tw-from-gray-900:hover{
    --tw-gradient-from: #1a202c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 32, 44, 0));
  }

  .md\:hover\:tw-from-blue-100:hover{
    --tw-gradient-from: #ebf8ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(235, 248, 255, 0));
  }

  .md\:hover\:tw-from-blue-200:hover{
    --tw-gradient-from: #bee3f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 227, 248, 0));
  }

  .md\:hover\:tw-from-blue-300:hover{
    --tw-gradient-from: #90cdf4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 205, 244, 0));
  }

  .md\:hover\:tw-from-blue-400:hover{
    --tw-gradient-from: #63b3ed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 179, 237, 0));
  }

  .md\:hover\:tw-from-blue-500:hover{
    --tw-gradient-from: #4299e1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 153, 225, 0));
  }

  .md\:hover\:tw-from-blue-600:hover{
    --tw-gradient-from: #3182ce;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 130, 206, 0));
  }

  .md\:hover\:tw-from-blue-700:hover{
    --tw-gradient-from: #2b6cb0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(43, 108, 176, 0));
  }

  .md\:hover\:tw-from-blue-800:hover{
    --tw-gradient-from: #2c5282;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 82, 130, 0));
  }

  .md\:hover\:tw-from-blue-900:hover{
    --tw-gradient-from: #2a4365;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(42, 67, 101, 0));
  }

  .md\:focus\:tw-from-transparent:focus{
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .md\:focus\:tw-from-current:focus{
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:focus\:tw-from-black:focus{
    --tw-gradient-from: #2c2c2c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 44, 44, 0));
  }

  .md\:focus\:tw-from-white:focus{
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:focus\:tw-from-gray-100:focus{
    --tw-gradient-from: #f7fafc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 250, 252, 0));
  }

  .md\:focus\:tw-from-gray-200:focus{
    --tw-gradient-from: #edf2f7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 242, 247, 0));
  }

  .md\:focus\:tw-from-gray-300:focus{
    --tw-gradient-from: #e2e8f0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(226, 232, 240, 0));
  }

  .md\:focus\:tw-from-gray-400:focus{
    --tw-gradient-from: #cbd5e0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(203, 213, 224, 0));
  }

  .md\:focus\:tw-from-gray-500:focus{
    --tw-gradient-from: #a0aec0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(160, 174, 192, 0));
  }

  .md\:focus\:tw-from-gray-600:focus{
    --tw-gradient-from: #718096;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(113, 128, 150, 0));
  }

  .md\:focus\:tw-from-gray-700:focus{
    --tw-gradient-from: #4a5568;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 85, 104, 0));
  }

  .md\:focus\:tw-from-gray-800:focus{
    --tw-gradient-from: #2d3748;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 72, 0));
  }

  .md\:focus\:tw-from-gray-900:focus{
    --tw-gradient-from: #1a202c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 32, 44, 0));
  }

  .md\:focus\:tw-from-blue-100:focus{
    --tw-gradient-from: #ebf8ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(235, 248, 255, 0));
  }

  .md\:focus\:tw-from-blue-200:focus{
    --tw-gradient-from: #bee3f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 227, 248, 0));
  }

  .md\:focus\:tw-from-blue-300:focus{
    --tw-gradient-from: #90cdf4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 205, 244, 0));
  }

  .md\:focus\:tw-from-blue-400:focus{
    --tw-gradient-from: #63b3ed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 179, 237, 0));
  }

  .md\:focus\:tw-from-blue-500:focus{
    --tw-gradient-from: #4299e1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 153, 225, 0));
  }

  .md\:focus\:tw-from-blue-600:focus{
    --tw-gradient-from: #3182ce;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 130, 206, 0));
  }

  .md\:focus\:tw-from-blue-700:focus{
    --tw-gradient-from: #2b6cb0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(43, 108, 176, 0));
  }

  .md\:focus\:tw-from-blue-800:focus{
    --tw-gradient-from: #2c5282;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 82, 130, 0));
  }

  .md\:focus\:tw-from-blue-900:focus{
    --tw-gradient-from: #2a4365;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(42, 67, 101, 0));
  }

  .md\:tw-via-transparent{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .md\:tw-via-current{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:tw-via-black{
    --tw-gradient-stops: var(--tw-gradient-from), #2c2c2c, var(--tw-gradient-to, rgba(44, 44, 44, 0));
  }

  .md\:tw-via-white{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:tw-via-gray-100{
    --tw-gradient-stops: var(--tw-gradient-from), #f7fafc, var(--tw-gradient-to, rgba(247, 250, 252, 0));
  }

  .md\:tw-via-gray-200{
    --tw-gradient-stops: var(--tw-gradient-from), #edf2f7, var(--tw-gradient-to, rgba(237, 242, 247, 0));
  }

  .md\:tw-via-gray-300{
    --tw-gradient-stops: var(--tw-gradient-from), #e2e8f0, var(--tw-gradient-to, rgba(226, 232, 240, 0));
  }

  .md\:tw-via-gray-400{
    --tw-gradient-stops: var(--tw-gradient-from), #cbd5e0, var(--tw-gradient-to, rgba(203, 213, 224, 0));
  }

  .md\:tw-via-gray-500{
    --tw-gradient-stops: var(--tw-gradient-from), #a0aec0, var(--tw-gradient-to, rgba(160, 174, 192, 0));
  }

  .md\:tw-via-gray-600{
    --tw-gradient-stops: var(--tw-gradient-from), #718096, var(--tw-gradient-to, rgba(113, 128, 150, 0));
  }

  .md\:tw-via-gray-700{
    --tw-gradient-stops: var(--tw-gradient-from), #4a5568, var(--tw-gradient-to, rgba(74, 85, 104, 0));
  }

  .md\:tw-via-gray-800{
    --tw-gradient-stops: var(--tw-gradient-from), #2d3748, var(--tw-gradient-to, rgba(45, 55, 72, 0));
  }

  .md\:tw-via-gray-900{
    --tw-gradient-stops: var(--tw-gradient-from), #1a202c, var(--tw-gradient-to, rgba(26, 32, 44, 0));
  }

  .md\:tw-via-blue-100{
    --tw-gradient-stops: var(--tw-gradient-from), #ebf8ff, var(--tw-gradient-to, rgba(235, 248, 255, 0));
  }

  .md\:tw-via-blue-200{
    --tw-gradient-stops: var(--tw-gradient-from), #bee3f8, var(--tw-gradient-to, rgba(190, 227, 248, 0));
  }

  .md\:tw-via-blue-300{
    --tw-gradient-stops: var(--tw-gradient-from), #90cdf4, var(--tw-gradient-to, rgba(144, 205, 244, 0));
  }

  .md\:tw-via-blue-400{
    --tw-gradient-stops: var(--tw-gradient-from), #63b3ed, var(--tw-gradient-to, rgba(99, 179, 237, 0));
  }

  .md\:tw-via-blue-500{
    --tw-gradient-stops: var(--tw-gradient-from), #4299e1, var(--tw-gradient-to, rgba(66, 153, 225, 0));
  }

  .md\:tw-via-blue-600{
    --tw-gradient-stops: var(--tw-gradient-from), #3182ce, var(--tw-gradient-to, rgba(49, 130, 206, 0));
  }

  .md\:tw-via-blue-700{
    --tw-gradient-stops: var(--tw-gradient-from), #2b6cb0, var(--tw-gradient-to, rgba(43, 108, 176, 0));
  }

  .md\:tw-via-blue-800{
    --tw-gradient-stops: var(--tw-gradient-from), #2c5282, var(--tw-gradient-to, rgba(44, 82, 130, 0));
  }

  .md\:tw-via-blue-900{
    --tw-gradient-stops: var(--tw-gradient-from), #2a4365, var(--tw-gradient-to, rgba(42, 67, 101, 0));
  }

  .md\:hover\:tw-via-transparent:hover{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .md\:hover\:tw-via-current:hover{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:hover\:tw-via-black:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2c2c2c, var(--tw-gradient-to, rgba(44, 44, 44, 0));
  }

  .md\:hover\:tw-via-white:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:hover\:tw-via-gray-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f7fafc, var(--tw-gradient-to, rgba(247, 250, 252, 0));
  }

  .md\:hover\:tw-via-gray-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #edf2f7, var(--tw-gradient-to, rgba(237, 242, 247, 0));
  }

  .md\:hover\:tw-via-gray-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e2e8f0, var(--tw-gradient-to, rgba(226, 232, 240, 0));
  }

  .md\:hover\:tw-via-gray-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #cbd5e0, var(--tw-gradient-to, rgba(203, 213, 224, 0));
  }

  .md\:hover\:tw-via-gray-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a0aec0, var(--tw-gradient-to, rgba(160, 174, 192, 0));
  }

  .md\:hover\:tw-via-gray-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #718096, var(--tw-gradient-to, rgba(113, 128, 150, 0));
  }

  .md\:hover\:tw-via-gray-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4a5568, var(--tw-gradient-to, rgba(74, 85, 104, 0));
  }

  .md\:hover\:tw-via-gray-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2d3748, var(--tw-gradient-to, rgba(45, 55, 72, 0));
  }

  .md\:hover\:tw-via-gray-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1a202c, var(--tw-gradient-to, rgba(26, 32, 44, 0));
  }

  .md\:hover\:tw-via-blue-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ebf8ff, var(--tw-gradient-to, rgba(235, 248, 255, 0));
  }

  .md\:hover\:tw-via-blue-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #bee3f8, var(--tw-gradient-to, rgba(190, 227, 248, 0));
  }

  .md\:hover\:tw-via-blue-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #90cdf4, var(--tw-gradient-to, rgba(144, 205, 244, 0));
  }

  .md\:hover\:tw-via-blue-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #63b3ed, var(--tw-gradient-to, rgba(99, 179, 237, 0));
  }

  .md\:hover\:tw-via-blue-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4299e1, var(--tw-gradient-to, rgba(66, 153, 225, 0));
  }

  .md\:hover\:tw-via-blue-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3182ce, var(--tw-gradient-to, rgba(49, 130, 206, 0));
  }

  .md\:hover\:tw-via-blue-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2b6cb0, var(--tw-gradient-to, rgba(43, 108, 176, 0));
  }

  .md\:hover\:tw-via-blue-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2c5282, var(--tw-gradient-to, rgba(44, 82, 130, 0));
  }

  .md\:hover\:tw-via-blue-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2a4365, var(--tw-gradient-to, rgba(42, 67, 101, 0));
  }

  .md\:focus\:tw-via-transparent:focus{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .md\:focus\:tw-via-current:focus{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:focus\:tw-via-black:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2c2c2c, var(--tw-gradient-to, rgba(44, 44, 44, 0));
  }

  .md\:focus\:tw-via-white:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:focus\:tw-via-gray-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f7fafc, var(--tw-gradient-to, rgba(247, 250, 252, 0));
  }

  .md\:focus\:tw-via-gray-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #edf2f7, var(--tw-gradient-to, rgba(237, 242, 247, 0));
  }

  .md\:focus\:tw-via-gray-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e2e8f0, var(--tw-gradient-to, rgba(226, 232, 240, 0));
  }

  .md\:focus\:tw-via-gray-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #cbd5e0, var(--tw-gradient-to, rgba(203, 213, 224, 0));
  }

  .md\:focus\:tw-via-gray-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a0aec0, var(--tw-gradient-to, rgba(160, 174, 192, 0));
  }

  .md\:focus\:tw-via-gray-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #718096, var(--tw-gradient-to, rgba(113, 128, 150, 0));
  }

  .md\:focus\:tw-via-gray-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4a5568, var(--tw-gradient-to, rgba(74, 85, 104, 0));
  }

  .md\:focus\:tw-via-gray-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2d3748, var(--tw-gradient-to, rgba(45, 55, 72, 0));
  }

  .md\:focus\:tw-via-gray-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1a202c, var(--tw-gradient-to, rgba(26, 32, 44, 0));
  }

  .md\:focus\:tw-via-blue-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ebf8ff, var(--tw-gradient-to, rgba(235, 248, 255, 0));
  }

  .md\:focus\:tw-via-blue-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #bee3f8, var(--tw-gradient-to, rgba(190, 227, 248, 0));
  }

  .md\:focus\:tw-via-blue-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #90cdf4, var(--tw-gradient-to, rgba(144, 205, 244, 0));
  }

  .md\:focus\:tw-via-blue-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #63b3ed, var(--tw-gradient-to, rgba(99, 179, 237, 0));
  }

  .md\:focus\:tw-via-blue-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4299e1, var(--tw-gradient-to, rgba(66, 153, 225, 0));
  }

  .md\:focus\:tw-via-blue-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3182ce, var(--tw-gradient-to, rgba(49, 130, 206, 0));
  }

  .md\:focus\:tw-via-blue-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2b6cb0, var(--tw-gradient-to, rgba(43, 108, 176, 0));
  }

  .md\:focus\:tw-via-blue-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2c5282, var(--tw-gradient-to, rgba(44, 82, 130, 0));
  }

  .md\:focus\:tw-via-blue-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2a4365, var(--tw-gradient-to, rgba(42, 67, 101, 0));
  }

  .md\:tw-to-transparent{
    --tw-gradient-to: transparent;
  }

  .md\:tw-to-current{
    --tw-gradient-to: currentColor;
  }

  .md\:tw-to-black{
    --tw-gradient-to: #2c2c2c;
  }

  .md\:tw-to-white{
    --tw-gradient-to: #fff;
  }

  .md\:tw-to-gray-100{
    --tw-gradient-to: #f7fafc;
  }

  .md\:tw-to-gray-200{
    --tw-gradient-to: #edf2f7;
  }

  .md\:tw-to-gray-300{
    --tw-gradient-to: #e2e8f0;
  }

  .md\:tw-to-gray-400{
    --tw-gradient-to: #cbd5e0;
  }

  .md\:tw-to-gray-500{
    --tw-gradient-to: #a0aec0;
  }

  .md\:tw-to-gray-600{
    --tw-gradient-to: #718096;
  }

  .md\:tw-to-gray-700{
    --tw-gradient-to: #4a5568;
  }

  .md\:tw-to-gray-800{
    --tw-gradient-to: #2d3748;
  }

  .md\:tw-to-gray-900{
    --tw-gradient-to: #1a202c;
  }

  .md\:tw-to-blue-100{
    --tw-gradient-to: #ebf8ff;
  }

  .md\:tw-to-blue-200{
    --tw-gradient-to: #bee3f8;
  }

  .md\:tw-to-blue-300{
    --tw-gradient-to: #90cdf4;
  }

  .md\:tw-to-blue-400{
    --tw-gradient-to: #63b3ed;
  }

  .md\:tw-to-blue-500{
    --tw-gradient-to: #4299e1;
  }

  .md\:tw-to-blue-600{
    --tw-gradient-to: #3182ce;
  }

  .md\:tw-to-blue-700{
    --tw-gradient-to: #2b6cb0;
  }

  .md\:tw-to-blue-800{
    --tw-gradient-to: #2c5282;
  }

  .md\:tw-to-blue-900{
    --tw-gradient-to: #2a4365;
  }

  .md\:hover\:tw-to-transparent:hover{
    --tw-gradient-to: transparent;
  }

  .md\:hover\:tw-to-current:hover{
    --tw-gradient-to: currentColor;
  }

  .md\:hover\:tw-to-black:hover{
    --tw-gradient-to: #2c2c2c;
  }

  .md\:hover\:tw-to-white:hover{
    --tw-gradient-to: #fff;
  }

  .md\:hover\:tw-to-gray-100:hover{
    --tw-gradient-to: #f7fafc;
  }

  .md\:hover\:tw-to-gray-200:hover{
    --tw-gradient-to: #edf2f7;
  }

  .md\:hover\:tw-to-gray-300:hover{
    --tw-gradient-to: #e2e8f0;
  }

  .md\:hover\:tw-to-gray-400:hover{
    --tw-gradient-to: #cbd5e0;
  }

  .md\:hover\:tw-to-gray-500:hover{
    --tw-gradient-to: #a0aec0;
  }

  .md\:hover\:tw-to-gray-600:hover{
    --tw-gradient-to: #718096;
  }

  .md\:hover\:tw-to-gray-700:hover{
    --tw-gradient-to: #4a5568;
  }

  .md\:hover\:tw-to-gray-800:hover{
    --tw-gradient-to: #2d3748;
  }

  .md\:hover\:tw-to-gray-900:hover{
    --tw-gradient-to: #1a202c;
  }

  .md\:hover\:tw-to-blue-100:hover{
    --tw-gradient-to: #ebf8ff;
  }

  .md\:hover\:tw-to-blue-200:hover{
    --tw-gradient-to: #bee3f8;
  }

  .md\:hover\:tw-to-blue-300:hover{
    --tw-gradient-to: #90cdf4;
  }

  .md\:hover\:tw-to-blue-400:hover{
    --tw-gradient-to: #63b3ed;
  }

  .md\:hover\:tw-to-blue-500:hover{
    --tw-gradient-to: #4299e1;
  }

  .md\:hover\:tw-to-blue-600:hover{
    --tw-gradient-to: #3182ce;
  }

  .md\:hover\:tw-to-blue-700:hover{
    --tw-gradient-to: #2b6cb0;
  }

  .md\:hover\:tw-to-blue-800:hover{
    --tw-gradient-to: #2c5282;
  }

  .md\:hover\:tw-to-blue-900:hover{
    --tw-gradient-to: #2a4365;
  }

  .md\:focus\:tw-to-transparent:focus{
    --tw-gradient-to: transparent;
  }

  .md\:focus\:tw-to-current:focus{
    --tw-gradient-to: currentColor;
  }

  .md\:focus\:tw-to-black:focus{
    --tw-gradient-to: #2c2c2c;
  }

  .md\:focus\:tw-to-white:focus{
    --tw-gradient-to: #fff;
  }

  .md\:focus\:tw-to-gray-100:focus{
    --tw-gradient-to: #f7fafc;
  }

  .md\:focus\:tw-to-gray-200:focus{
    --tw-gradient-to: #edf2f7;
  }

  .md\:focus\:tw-to-gray-300:focus{
    --tw-gradient-to: #e2e8f0;
  }

  .md\:focus\:tw-to-gray-400:focus{
    --tw-gradient-to: #cbd5e0;
  }

  .md\:focus\:tw-to-gray-500:focus{
    --tw-gradient-to: #a0aec0;
  }

  .md\:focus\:tw-to-gray-600:focus{
    --tw-gradient-to: #718096;
  }

  .md\:focus\:tw-to-gray-700:focus{
    --tw-gradient-to: #4a5568;
  }

  .md\:focus\:tw-to-gray-800:focus{
    --tw-gradient-to: #2d3748;
  }

  .md\:focus\:tw-to-gray-900:focus{
    --tw-gradient-to: #1a202c;
  }

  .md\:focus\:tw-to-blue-100:focus{
    --tw-gradient-to: #ebf8ff;
  }

  .md\:focus\:tw-to-blue-200:focus{
    --tw-gradient-to: #bee3f8;
  }

  .md\:focus\:tw-to-blue-300:focus{
    --tw-gradient-to: #90cdf4;
  }

  .md\:focus\:tw-to-blue-400:focus{
    --tw-gradient-to: #63b3ed;
  }

  .md\:focus\:tw-to-blue-500:focus{
    --tw-gradient-to: #4299e1;
  }

  .md\:focus\:tw-to-blue-600:focus{
    --tw-gradient-to: #3182ce;
  }

  .md\:focus\:tw-to-blue-700:focus{
    --tw-gradient-to: #2b6cb0;
  }

  .md\:focus\:tw-to-blue-800:focus{
    --tw-gradient-to: #2c5282;
  }

  .md\:focus\:tw-to-blue-900:focus{
    --tw-gradient-to: #2a4365;
  }

  .md\:tw-decoration-slice{
    -webkit-box-decoration-break: slice;
    box-decoration-break: slice;
  }

  .md\:tw-decoration-clone{
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  .md\:tw-bg-auto{
    background-size: auto;
  }

  .md\:tw-bg-cover{
    background-size: cover;
  }

  .md\:tw-bg-contain{
    background-size: contain;
  }

  .md\:tw-bg-fixed{
    background-attachment: fixed;
  }

  .md\:tw-bg-local{
    background-attachment: local;
  }

  .md\:tw-bg-scroll{
    background-attachment: scroll;
  }

  .md\:tw-bg-clip-border{
    background-clip: border-box;
  }

  .md\:tw-bg-clip-padding{
    background-clip: padding-box;
  }

  .md\:tw-bg-clip-content{
    background-clip: content-box;
  }

  .md\:tw-bg-clip-text{
    -webkit-background-clip: text;
    background-clip: text;
  }

  .md\:tw-bg-bottom{
    background-position: bottom;
  }

  .md\:tw-bg-center{
    background-position: center;
  }

  .md\:tw-bg-left{
    background-position: left;
  }

  .md\:tw-bg-left-bottom{
    background-position: left bottom;
  }

  .md\:tw-bg-left-top{
    background-position: left top;
  }

  .md\:tw-bg-right{
    background-position: right;
  }

  .md\:tw-bg-right-bottom{
    background-position: right bottom;
  }

  .md\:tw-bg-right-top{
    background-position: right top;
  }

  .md\:tw-bg-top{
    background-position: top;
  }

  .md\:tw-bg-repeat{
    background-repeat: repeat;
  }

  .md\:tw-bg-no-repeat{
    background-repeat: no-repeat;
  }

  .md\:tw-bg-repeat-x{
    background-repeat: repeat-x;
  }

  .md\:tw-bg-repeat-y{
    background-repeat: repeat-y;
  }

  .md\:tw-bg-repeat-round{
    background-repeat: round;
  }

  .md\:tw-bg-repeat-space{
    background-repeat: space;
  }

  .md\:tw-bg-origin-border{
    background-origin: border-box;
  }

  .md\:tw-bg-origin-padding{
    background-origin: padding-box;
  }

  .md\:tw-bg-origin-content{
    background-origin: content-box;
  }

  .md\:tw-fill-current{
    fill: currentColor;
  }

  .md\:tw-stroke-current{
    stroke: currentColor;
  }

  .md\:tw-stroke-0{
    stroke-width: 0;
  }

  .md\:tw-stroke-1{
    stroke-width: 1;
  }

  .md\:tw-stroke-2{
    stroke-width: 2;
  }

  .md\:tw-object-contain{
    object-fit: contain;
  }

  .md\:tw-object-cover{
    object-fit: cover;
  }

  .md\:tw-object-fill{
    object-fit: fill;
  }

  .md\:tw-object-none{
    object-fit: none;
  }

  .md\:tw-object-scale-down{
    object-fit: scale-down;
  }

  .md\:tw-object-bottom{
    object-position: bottom;
  }

  .md\:tw-object-center{
    object-position: center;
  }

  .md\:tw-object-left{
    object-position: left;
  }

  .md\:tw-object-left-bottom{
    object-position: left bottom;
  }

  .md\:tw-object-left-top{
    object-position: left top;
  }

  .md\:tw-object-right{
    object-position: right;
  }

  .md\:tw-object-right-bottom{
    object-position: right bottom;
  }

  .md\:tw-object-right-top{
    object-position: right top;
  }

  .md\:tw-object-top{
    object-position: top;
  }

  .md\:tw-p-0{
    padding: 0;
  }

  .md\:tw-p-1{
    padding: 0.25rem;
  }

  .md\:tw-p-2{
    padding: 0.5rem;
  }

  .md\:tw-p-3{
    padding: 0.75rem;
  }

  .md\:tw-p-4{
    padding: 1rem;
  }

  .md\:tw-p-5{
    padding: 1.25rem;
  }

  .md\:tw-p-6{
    padding: 1.5rem;
  }

  .md\:tw-p-8{
    padding: 2rem;
  }

  .md\:tw-p-10{
    padding: 2.5rem;
  }

  .md\:tw-p-12{
    padding: 3rem;
  }

  .md\:tw-p-16{
    padding: 4rem;
  }

  .md\:tw-p-20{
    padding: 5rem;
  }

  .md\:tw-p-24{
    padding: 6rem;
  }

  .md\:tw-p-32{
    padding: 8rem;
  }

  .md\:tw-p-40{
    padding: 10rem;
  }

  .md\:tw-p-48{
    padding: 12rem;
  }

  .md\:tw-p-56{
    padding: 14rem;
  }

  .md\:tw-p-64{
    padding: 16rem;
  }

  .md\:tw-p-px{
    padding: 1px;
  }

  .md\:tw-px-0{
    padding-left: 0;
    padding-right: 0;
  }

  .md\:tw-px-1{
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .md\:tw-px-2{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .md\:tw-px-3{
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .md\:tw-px-4{
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:tw-px-5{
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .md\:tw-px-6{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:tw-px-8{
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:tw-px-10{
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:tw-px-12{
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:tw-px-16{
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .md\:tw-px-20{
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .md\:tw-px-24{
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .md\:tw-px-32{
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .md\:tw-px-40{
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .md\:tw-px-48{
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .md\:tw-px-56{
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .md\:tw-px-64{
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .md\:tw-px-px{
    padding-left: 1px;
    padding-right: 1px;
  }

  .md\:tw-py-0{
    padding-top: 0;
    padding-bottom: 0;
  }

  .md\:tw-py-1{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .md\:tw-py-2{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .md\:tw-py-3{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .md\:tw-py-4{
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .md\:tw-py-5{
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .md\:tw-py-6{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md\:tw-py-8{
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:tw-py-10{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .md\:tw-py-12{
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .md\:tw-py-16{
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .md\:tw-py-20{
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .md\:tw-py-24{
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .md\:tw-py-32{
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .md\:tw-py-40{
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .md\:tw-py-48{
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .md\:tw-py-56{
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .md\:tw-py-64{
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .md\:tw-py-px{
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .md\:tw-pt-0{
    padding-top: 0;
  }

  .md\:tw-pt-1{
    padding-top: 0.25rem;
  }

  .md\:tw-pt-2{
    padding-top: 0.5rem;
  }

  .md\:tw-pt-3{
    padding-top: 0.75rem;
  }

  .md\:tw-pt-4{
    padding-top: 1rem;
  }

  .md\:tw-pt-5{
    padding-top: 1.25rem;
  }

  .md\:tw-pt-6{
    padding-top: 1.5rem;
  }

  .md\:tw-pt-8{
    padding-top: 2rem;
  }

  .md\:tw-pt-10{
    padding-top: 2.5rem;
  }

  .md\:tw-pt-12{
    padding-top: 3rem;
  }

  .md\:tw-pt-16{
    padding-top: 4rem;
  }

  .md\:tw-pt-20{
    padding-top: 5rem;
  }

  .md\:tw-pt-24{
    padding-top: 6rem;
  }

  .md\:tw-pt-32{
    padding-top: 8rem;
  }

  .md\:tw-pt-40{
    padding-top: 10rem;
  }

  .md\:tw-pt-48{
    padding-top: 12rem;
  }

  .md\:tw-pt-56{
    padding-top: 14rem;
  }

  .md\:tw-pt-64{
    padding-top: 16rem;
  }

  .md\:tw-pt-px{
    padding-top: 1px;
  }

  .md\:tw-pr-0{
    padding-right: 0;
  }

  .md\:tw-pr-1{
    padding-right: 0.25rem;
  }

  .md\:tw-pr-2{
    padding-right: 0.5rem;
  }

  .md\:tw-pr-3{
    padding-right: 0.75rem;
  }

  .md\:tw-pr-4{
    padding-right: 1rem;
  }

  .md\:tw-pr-5{
    padding-right: 1.25rem;
  }

  .md\:tw-pr-6{
    padding-right: 1.5rem;
  }

  .md\:tw-pr-8{
    padding-right: 2rem;
  }

  .md\:tw-pr-10{
    padding-right: 2.5rem;
  }

  .md\:tw-pr-12{
    padding-right: 3rem;
  }

  .md\:tw-pr-16{
    padding-right: 4rem;
  }

  .md\:tw-pr-20{
    padding-right: 5rem;
  }

  .md\:tw-pr-24{
    padding-right: 6rem;
  }

  .md\:tw-pr-32{
    padding-right: 8rem;
  }

  .md\:tw-pr-40{
    padding-right: 10rem;
  }

  .md\:tw-pr-48{
    padding-right: 12rem;
  }

  .md\:tw-pr-56{
    padding-right: 14rem;
  }

  .md\:tw-pr-64{
    padding-right: 16rem;
  }

  .md\:tw-pr-px{
    padding-right: 1px;
  }

  .md\:tw-pb-0{
    padding-bottom: 0;
  }

  .md\:tw-pb-1{
    padding-bottom: 0.25rem;
  }

  .md\:tw-pb-2{
    padding-bottom: 0.5rem;
  }

  .md\:tw-pb-3{
    padding-bottom: 0.75rem;
  }

  .md\:tw-pb-4{
    padding-bottom: 1rem;
  }

  .md\:tw-pb-5{
    padding-bottom: 1.25rem;
  }

  .md\:tw-pb-6{
    padding-bottom: 1.5rem;
  }

  .md\:tw-pb-8{
    padding-bottom: 2rem;
  }

  .md\:tw-pb-10{
    padding-bottom: 2.5rem;
  }

  .md\:tw-pb-12{
    padding-bottom: 3rem;
  }

  .md\:tw-pb-16{
    padding-bottom: 4rem;
  }

  .md\:tw-pb-20{
    padding-bottom: 5rem;
  }

  .md\:tw-pb-24{
    padding-bottom: 6rem;
  }

  .md\:tw-pb-32{
    padding-bottom: 8rem;
  }

  .md\:tw-pb-40{
    padding-bottom: 10rem;
  }

  .md\:tw-pb-48{
    padding-bottom: 12rem;
  }

  .md\:tw-pb-56{
    padding-bottom: 14rem;
  }

  .md\:tw-pb-64{
    padding-bottom: 16rem;
  }

  .md\:tw-pb-px{
    padding-bottom: 1px;
  }

  .md\:tw-pl-0{
    padding-left: 0;
  }

  .md\:tw-pl-1{
    padding-left: 0.25rem;
  }

  .md\:tw-pl-2{
    padding-left: 0.5rem;
  }

  .md\:tw-pl-3{
    padding-left: 0.75rem;
  }

  .md\:tw-pl-4{
    padding-left: 1rem;
  }

  .md\:tw-pl-5{
    padding-left: 1.25rem;
  }

  .md\:tw-pl-6{
    padding-left: 1.5rem;
  }

  .md\:tw-pl-8{
    padding-left: 2rem;
  }

  .md\:tw-pl-10{
    padding-left: 2.5rem;
  }

  .md\:tw-pl-12{
    padding-left: 3rem;
  }

  .md\:tw-pl-16{
    padding-left: 4rem;
  }

  .md\:tw-pl-20{
    padding-left: 5rem;
  }

  .md\:tw-pl-24{
    padding-left: 6rem;
  }

  .md\:tw-pl-32{
    padding-left: 8rem;
  }

  .md\:tw-pl-40{
    padding-left: 10rem;
  }

  .md\:tw-pl-48{
    padding-left: 12rem;
  }

  .md\:tw-pl-56{
    padding-left: 14rem;
  }

  .md\:tw-pl-64{
    padding-left: 16rem;
  }

  .md\:tw-pl-px{
    padding-left: 1px;
  }

  .md\:tw-text-left{
    text-align: left;
  }

  .md\:tw-text-center{
    text-align: center;
  }

  .md\:tw-text-right{
    text-align: right;
  }

  .md\:tw-text-justify{
    text-align: justify;
  }

  .md\:tw-align-baseline{
    vertical-align: baseline;
  }

  .md\:tw-align-top{
    vertical-align: top;
  }

  .md\:tw-align-middle{
    vertical-align: middle;
  }

  .md\:tw-align-bottom{
    vertical-align: bottom;
  }

  .md\:tw-align-text-top{
    vertical-align: text-top;
  }

  .md\:tw-align-text-bottom{
    vertical-align: text-bottom;
  }

  .md\:tw-font-sans{
    font-family: "Gotham SSm A", "Gotham SSm B", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  .md\:tw-font-serif{
    font-family: Georgia, Cambria, "Times New Roman", Times, serif;
  }

  .md\:tw-font-mono{
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

  .md\:tw-text-xs{
    font-size: 0.75rem;
  }

  .md\:tw-text-sm{
    font-size: 0.875rem;
  }

  .md\:tw-text-base{
    font-size: 1rem;
  }

  .md\:tw-text-lg{
    font-size: 1.125rem;
  }

  .md\:tw-text-xl{
    font-size: 1.25rem;
  }

  .md\:tw-text-2xl{
    font-size: 1.5rem;
  }

  .md\:tw-text-3xl{
    font-size: 1.875rem;
  }

  .md\:tw-text-4xl{
    font-size: 2.25rem;
  }

  .md\:tw-text-5xl{
    font-size: 3rem;
  }

  .md\:tw-text-6xl{
    font-size: 4rem;
  }

  .md\:tw-font-hairline{
    font-weight: 100;
  }

  .md\:tw-font-thin{
    font-weight: 200;
  }

  .md\:tw-font-light{
    font-weight: 300;
  }

  .md\:tw-font-normal{
    font-weight: 400;
  }

  .md\:tw-font-medium{
    font-weight: 500;
  }

  .md\:tw-font-semibold{
    font-weight: 600;
  }

  .md\:tw-font-bold{
    font-weight: 700;
  }

  .md\:tw-font-extrabold{
    font-weight: 800;
  }

  .md\:tw-font-black{
    font-weight: 900;
  }

  .md\:hover\:tw-font-hairline:hover{
    font-weight: 100;
  }

  .md\:hover\:tw-font-thin:hover{
    font-weight: 200;
  }

  .md\:hover\:tw-font-light:hover{
    font-weight: 300;
  }

  .md\:hover\:tw-font-normal:hover{
    font-weight: 400;
  }

  .md\:hover\:tw-font-medium:hover{
    font-weight: 500;
  }

  .md\:hover\:tw-font-semibold:hover{
    font-weight: 600;
  }

  .md\:hover\:tw-font-bold:hover{
    font-weight: 700;
  }

  .md\:hover\:tw-font-extrabold:hover{
    font-weight: 800;
  }

  .md\:hover\:tw-font-black:hover{
    font-weight: 900;
  }

  .md\:focus\:tw-font-hairline:focus{
    font-weight: 100;
  }

  .md\:focus\:tw-font-thin:focus{
    font-weight: 200;
  }

  .md\:focus\:tw-font-light:focus{
    font-weight: 300;
  }

  .md\:focus\:tw-font-normal:focus{
    font-weight: 400;
  }

  .md\:focus\:tw-font-medium:focus{
    font-weight: 500;
  }

  .md\:focus\:tw-font-semibold:focus{
    font-weight: 600;
  }

  .md\:focus\:tw-font-bold:focus{
    font-weight: 700;
  }

  .md\:focus\:tw-font-extrabold:focus{
    font-weight: 800;
  }

  .md\:focus\:tw-font-black:focus{
    font-weight: 900;
  }

  .md\:tw-uppercase{
    text-transform: uppercase;
  }

  .md\:tw-lowercase{
    text-transform: lowercase;
  }

  .md\:tw-capitalize{
    text-transform: capitalize;
  }

  .md\:tw-normal-case{
    text-transform: none;
  }

  .md\:tw-italic{
    font-style: italic;
  }

  .md\:tw-not-italic{
    font-style: normal;
  }

  .md\:tw-ordinal, .md\:tw-slashed-zero, .md\:tw-lining-nums, .md\:tw-oldstyle-nums, .md\:tw-proportional-nums, .md\:tw-tabular-nums, .md\:tw-diagonal-fractions, .md\:tw-stacked-fractions{
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .md\:tw-normal-nums{
    font-variant-numeric: normal;
  }

  .md\:tw-ordinal{
    --tw-ordinal: ordinal;
  }

  .md\:tw-slashed-zero{
    --tw-slashed-zero: slashed-zero;
  }

  .md\:tw-lining-nums{
    --tw-numeric-figure: lining-nums;
  }

  .md\:tw-oldstyle-nums{
    --tw-numeric-figure: oldstyle-nums;
  }

  .md\:tw-proportional-nums{
    --tw-numeric-spacing: proportional-nums;
  }

  .md\:tw-tabular-nums{
    --tw-numeric-spacing: tabular-nums;
  }

  .md\:tw-diagonal-fractions{
    --tw-numeric-fraction: diagonal-fractions;
  }

  .md\:tw-stacked-fractions{
    --tw-numeric-fraction: stacked-fractions;
  }

  .md\:tw-leading-3{
    line-height: .75rem;
  }

  .md\:tw-leading-4{
    line-height: 1rem;
  }

  .md\:tw-leading-5{
    line-height: 1.25rem;
  }

  .md\:tw-leading-6{
    line-height: 1.5rem;
  }

  .md\:tw-leading-7{
    line-height: 1.75rem;
  }

  .md\:tw-leading-8{
    line-height: 2rem;
  }

  .md\:tw-leading-9{
    line-height: 2.25rem;
  }

  .md\:tw-leading-10{
    line-height: 2.5rem;
  }

  .md\:tw-leading-none{
    line-height: 1;
  }

  .md\:tw-leading-tight{
    line-height: 1.25;
  }

  .md\:tw-leading-snug{
    line-height: 1.375;
  }

  .md\:tw-leading-normal{
    line-height: 1.5;
  }

  .md\:tw-leading-relaxed{
    line-height: 1.625;
  }

  .md\:tw-leading-loose{
    line-height: 2;
  }

  .md\:tw-tracking-tighter{
    letter-spacing: -0.05em;
  }

  .md\:tw-tracking-tight{
    letter-spacing: -0.025em;
  }

  .md\:tw-tracking-normal{
    letter-spacing: 0;
  }

  .md\:tw-tracking-wide{
    letter-spacing: 0.025em;
  }

  .md\:tw-tracking-wider{
    letter-spacing: 0.05em;
  }

  .md\:tw-tracking-widest{
    letter-spacing: 0.1em;
  }

  .md\:tw-text-transparent{
    color: transparent;
  }

  .md\:tw-text-current{
    color: currentColor;
  }

  .md\:tw-text-black{
    --tw-text-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-text-opacity));
  }

  .md\:tw-text-white{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .md\:tw-text-gray-100{
    --tw-text-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-text-opacity));
  }

  .md\:tw-text-gray-200{
    --tw-text-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-text-opacity));
  }

  .md\:tw-text-gray-300{
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));
  }

  .md\:tw-text-gray-400{
    --tw-text-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-text-opacity));
  }

  .md\:tw-text-gray-500{
    --tw-text-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-text-opacity));
  }

  .md\:tw-text-gray-600{
    --tw-text-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-text-opacity));
  }

  .md\:tw-text-gray-700{
    --tw-text-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-text-opacity));
  }

  .md\:tw-text-gray-800{
    --tw-text-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-text-opacity));
  }

  .md\:tw-text-gray-900{
    --tw-text-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-text-opacity));
  }

  .md\:tw-text-blue-100{
    --tw-text-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-text-opacity));
  }

  .md\:tw-text-blue-200{
    --tw-text-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-text-opacity));
  }

  .md\:tw-text-blue-300{
    --tw-text-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-text-opacity));
  }

  .md\:tw-text-blue-400{
    --tw-text-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-text-opacity));
  }

  .md\:tw-text-blue-500{
    --tw-text-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-text-opacity));
  }

  .md\:tw-text-blue-600{
    --tw-text-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-text-opacity));
  }

  .md\:tw-text-blue-700{
    --tw-text-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-text-opacity));
  }

  .md\:tw-text-blue-800{
    --tw-text-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-text-opacity));
  }

  .md\:tw-text-blue-900{
    --tw-text-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-text-opacity));
  }

  .md\:hover\:tw-text-transparent:hover{
    color: transparent;
  }

  .md\:hover\:tw-text-current:hover{
    color: currentColor;
  }

  .md\:hover\:tw-text-black:hover{
    --tw-text-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-text-opacity));
  }

  .md\:hover\:tw-text-white:hover{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .md\:hover\:tw-text-gray-100:hover{
    --tw-text-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-text-opacity));
  }

  .md\:hover\:tw-text-gray-200:hover{
    --tw-text-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-text-opacity));
  }

  .md\:hover\:tw-text-gray-300:hover{
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));
  }

  .md\:hover\:tw-text-gray-400:hover{
    --tw-text-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-text-opacity));
  }

  .md\:hover\:tw-text-gray-500:hover{
    --tw-text-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-text-opacity));
  }

  .md\:hover\:tw-text-gray-600:hover{
    --tw-text-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-text-opacity));
  }

  .md\:hover\:tw-text-gray-700:hover{
    --tw-text-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-text-opacity));
  }

  .md\:hover\:tw-text-gray-800:hover{
    --tw-text-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-text-opacity));
  }

  .md\:hover\:tw-text-gray-900:hover{
    --tw-text-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-text-opacity));
  }

  .md\:hover\:tw-text-blue-100:hover{
    --tw-text-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-text-opacity));
  }

  .md\:hover\:tw-text-blue-200:hover{
    --tw-text-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-text-opacity));
  }

  .md\:hover\:tw-text-blue-300:hover{
    --tw-text-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-text-opacity));
  }

  .md\:hover\:tw-text-blue-400:hover{
    --tw-text-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-text-opacity));
  }

  .md\:hover\:tw-text-blue-500:hover{
    --tw-text-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-text-opacity));
  }

  .md\:hover\:tw-text-blue-600:hover{
    --tw-text-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-text-opacity));
  }

  .md\:hover\:tw-text-blue-700:hover{
    --tw-text-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-text-opacity));
  }

  .md\:hover\:tw-text-blue-800:hover{
    --tw-text-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-text-opacity));
  }

  .md\:hover\:tw-text-blue-900:hover{
    --tw-text-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-text-opacity));
  }

  .md\:focus\:tw-text-transparent:focus{
    color: transparent;
  }

  .md\:focus\:tw-text-current:focus{
    color: currentColor;
  }

  .md\:focus\:tw-text-black:focus{
    --tw-text-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-text-opacity));
  }

  .md\:focus\:tw-text-white:focus{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .md\:focus\:tw-text-gray-100:focus{
    --tw-text-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-text-opacity));
  }

  .md\:focus\:tw-text-gray-200:focus{
    --tw-text-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-text-opacity));
  }

  .md\:focus\:tw-text-gray-300:focus{
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));
  }

  .md\:focus\:tw-text-gray-400:focus{
    --tw-text-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-text-opacity));
  }

  .md\:focus\:tw-text-gray-500:focus{
    --tw-text-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-text-opacity));
  }

  .md\:focus\:tw-text-gray-600:focus{
    --tw-text-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-text-opacity));
  }

  .md\:focus\:tw-text-gray-700:focus{
    --tw-text-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-text-opacity));
  }

  .md\:focus\:tw-text-gray-800:focus{
    --tw-text-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-text-opacity));
  }

  .md\:focus\:tw-text-gray-900:focus{
    --tw-text-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-text-opacity));
  }

  .md\:focus\:tw-text-blue-100:focus{
    --tw-text-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-text-opacity));
  }

  .md\:focus\:tw-text-blue-200:focus{
    --tw-text-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-text-opacity));
  }

  .md\:focus\:tw-text-blue-300:focus{
    --tw-text-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-text-opacity));
  }

  .md\:focus\:tw-text-blue-400:focus{
    --tw-text-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-text-opacity));
  }

  .md\:focus\:tw-text-blue-500:focus{
    --tw-text-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-text-opacity));
  }

  .md\:focus\:tw-text-blue-600:focus{
    --tw-text-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-text-opacity));
  }

  .md\:focus\:tw-text-blue-700:focus{
    --tw-text-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-text-opacity));
  }

  .md\:focus\:tw-text-blue-800:focus{
    --tw-text-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-text-opacity));
  }

  .md\:focus\:tw-text-blue-900:focus{
    --tw-text-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-text-opacity));
  }

  .md\:tw-text-opacity-0{
    --tw-text-opacity: 0;
  }

  .md\:tw-text-opacity-25{
    --tw-text-opacity: 0.25;
  }

  .md\:tw-text-opacity-50{
    --tw-text-opacity: 0.5;
  }

  .md\:tw-text-opacity-75{
    --tw-text-opacity: 0.75;
  }

  .md\:tw-text-opacity-90{
    --tw-text-opacity: 0.9;
  }

  .md\:tw-text-opacity-100{
    --tw-text-opacity: 1;
  }

  .md\:hover\:tw-text-opacity-0:hover{
    --tw-text-opacity: 0;
  }

  .md\:hover\:tw-text-opacity-25:hover{
    --tw-text-opacity: 0.25;
  }

  .md\:hover\:tw-text-opacity-50:hover{
    --tw-text-opacity: 0.5;
  }

  .md\:hover\:tw-text-opacity-75:hover{
    --tw-text-opacity: 0.75;
  }

  .md\:hover\:tw-text-opacity-90:hover{
    --tw-text-opacity: 0.9;
  }

  .md\:hover\:tw-text-opacity-100:hover{
    --tw-text-opacity: 1;
  }

  .md\:focus\:tw-text-opacity-0:focus{
    --tw-text-opacity: 0;
  }

  .md\:focus\:tw-text-opacity-25:focus{
    --tw-text-opacity: 0.25;
  }

  .md\:focus\:tw-text-opacity-50:focus{
    --tw-text-opacity: 0.5;
  }

  .md\:focus\:tw-text-opacity-75:focus{
    --tw-text-opacity: 0.75;
  }

  .md\:focus\:tw-text-opacity-90:focus{
    --tw-text-opacity: 0.9;
  }

  .md\:focus\:tw-text-opacity-100:focus{
    --tw-text-opacity: 1;
  }

  .md\:tw-underline{
    text-decoration: underline;
  }

  .md\:tw-line-through{
    text-decoration: line-through;
  }

  .md\:tw-no-underline{
    text-decoration: none;
  }

  .md\:hover\:tw-underline:hover{
    text-decoration: underline;
  }

  .md\:hover\:tw-line-through:hover{
    text-decoration: line-through;
  }

  .md\:hover\:tw-no-underline:hover{
    text-decoration: none;
  }

  .md\:focus\:tw-underline:focus{
    text-decoration: underline;
  }

  .md\:focus\:tw-line-through:focus{
    text-decoration: line-through;
  }

  .md\:focus\:tw-no-underline:focus{
    text-decoration: none;
  }

  .md\:tw-antialiased{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .md\:tw-subpixel-antialiased{
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .md\:tw-placeholder-transparent::-webkit-input-placeholder{
    color: transparent;
  }

  .md\:tw-placeholder-transparent::-ms-input-placeholder{
    color: transparent;
  }

  .md\:tw-placeholder-transparent::placeholder{
    color: transparent;
  }

  .md\:tw-placeholder-current::-webkit-input-placeholder{
    color: currentColor;
  }

  .md\:tw-placeholder-current::-ms-input-placeholder{
    color: currentColor;
  }

  .md\:tw-placeholder-current::placeholder{
    color: currentColor;
  }

  .md\:tw-placeholder-black::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-black::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-black::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-white::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-white::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-white::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-gray-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-gray-100::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-gray-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-gray-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-gray-200::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-gray-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-gray-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-gray-300::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-gray-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-gray-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-gray-400::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-gray-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-gray-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-gray-500::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-gray-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-gray-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-gray-600::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-gray-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-gray-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-gray-700::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-gray-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-gray-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-gray-800::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-gray-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-gray-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-gray-900::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-gray-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-blue-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-blue-100::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-blue-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-blue-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-blue-200::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-blue-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-blue-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-blue-300::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-blue-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-blue-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-blue-400::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-blue-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-blue-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-blue-500::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-blue-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-blue-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-blue-600::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-blue-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-blue-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-blue-700::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-blue-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-blue-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-blue-800::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-blue-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-blue-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-blue-900::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-blue-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-transparent:focus::-webkit-input-placeholder{
    color: transparent;
  }

  .md\:focus\:tw-placeholder-transparent:focus::-ms-input-placeholder{
    color: transparent;
  }

  .md\:focus\:tw-placeholder-transparent:focus::placeholder{
    color: transparent;
  }

  .md\:focus\:tw-placeholder-current:focus::-webkit-input-placeholder{
    color: currentColor;
  }

  .md\:focus\:tw-placeholder-current:focus::-ms-input-placeholder{
    color: currentColor;
  }

  .md\:focus\:tw-placeholder-current:focus::placeholder{
    color: currentColor;
  }

  .md\:focus\:tw-placeholder-black:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-black:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-black:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-white:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-white:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-white:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-100:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-200:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-300:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-400:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-500:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-600:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-700:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-800:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-900:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-100:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-200:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-300:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-400:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-500:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-600:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-700:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-800:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-900:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
  }

  .md\:tw-placeholder-opacity-0::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0;
  }

  .md\:tw-placeholder-opacity-0::-ms-input-placeholder{
    --tw-placeholder-opacity: 0;
  }

  .md\:tw-placeholder-opacity-0::placeholder{
    --tw-placeholder-opacity: 0;
  }

  .md\:tw-placeholder-opacity-25::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.25;
  }

  .md\:tw-placeholder-opacity-25::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.25;
  }

  .md\:tw-placeholder-opacity-25::placeholder{
    --tw-placeholder-opacity: 0.25;
  }

  .md\:tw-placeholder-opacity-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.5;
  }

  .md\:tw-placeholder-opacity-50::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.5;
  }

  .md\:tw-placeholder-opacity-50::placeholder{
    --tw-placeholder-opacity: 0.5;
  }

  .md\:tw-placeholder-opacity-75::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.75;
  }

  .md\:tw-placeholder-opacity-75::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.75;
  }

  .md\:tw-placeholder-opacity-75::placeholder{
    --tw-placeholder-opacity: 0.75;
  }

  .md\:tw-placeholder-opacity-90::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.9;
  }

  .md\:tw-placeholder-opacity-90::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.9;
  }

  .md\:tw-placeholder-opacity-90::placeholder{
    --tw-placeholder-opacity: 0.9;
  }

  .md\:tw-placeholder-opacity-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
  }

  .md\:tw-placeholder-opacity-100::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
  }

  .md\:tw-placeholder-opacity-100::placeholder{
    --tw-placeholder-opacity: 1;
  }

  .md\:focus\:tw-placeholder-opacity-0:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0;
  }

  .md\:focus\:tw-placeholder-opacity-0:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 0;
  }

  .md\:focus\:tw-placeholder-opacity-0:focus::placeholder{
    --tw-placeholder-opacity: 0;
  }

  .md\:focus\:tw-placeholder-opacity-25:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.25;
  }

  .md\:focus\:tw-placeholder-opacity-25:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.25;
  }

  .md\:focus\:tw-placeholder-opacity-25:focus::placeholder{
    --tw-placeholder-opacity: 0.25;
  }

  .md\:focus\:tw-placeholder-opacity-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.5;
  }

  .md\:focus\:tw-placeholder-opacity-50:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.5;
  }

  .md\:focus\:tw-placeholder-opacity-50:focus::placeholder{
    --tw-placeholder-opacity: 0.5;
  }

  .md\:focus\:tw-placeholder-opacity-75:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.75;
  }

  .md\:focus\:tw-placeholder-opacity-75:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.75;
  }

  .md\:focus\:tw-placeholder-opacity-75:focus::placeholder{
    --tw-placeholder-opacity: 0.75;
  }

  .md\:focus\:tw-placeholder-opacity-90:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.9;
  }

  .md\:focus\:tw-placeholder-opacity-90:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.9;
  }

  .md\:focus\:tw-placeholder-opacity-90:focus::placeholder{
    --tw-placeholder-opacity: 0.9;
  }

  .md\:focus\:tw-placeholder-opacity-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
  }

  .md\:focus\:tw-placeholder-opacity-100:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
  }

  .md\:focus\:tw-placeholder-opacity-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
  }

  .md\:tw-opacity-0{
    opacity: 0;
  }

  .md\:tw-opacity-25{
    opacity: 0.25;
  }

  .md\:tw-opacity-50{
    opacity: 0.5;
  }

  .md\:tw-opacity-75{
    opacity: 0.75;
  }

  .md\:tw-opacity-90{
    opacity: 0.9;
  }

  .md\:tw-opacity-100{
    opacity: 1;
  }

  .md\:hover\:tw-opacity-0:hover{
    opacity: 0;
  }

  .md\:hover\:tw-opacity-25:hover{
    opacity: 0.25;
  }

  .md\:hover\:tw-opacity-50:hover{
    opacity: 0.5;
  }

  .md\:hover\:tw-opacity-75:hover{
    opacity: 0.75;
  }

  .md\:hover\:tw-opacity-90:hover{
    opacity: 0.9;
  }

  .md\:hover\:tw-opacity-100:hover{
    opacity: 1;
  }

  .md\:focus\:tw-opacity-0:focus{
    opacity: 0;
  }

  .md\:focus\:tw-opacity-25:focus{
    opacity: 0.25;
  }

  .md\:focus\:tw-opacity-50:focus{
    opacity: 0.5;
  }

  .md\:focus\:tw-opacity-75:focus{
    opacity: 0.75;
  }

  .md\:focus\:tw-opacity-90:focus{
    opacity: 0.9;
  }

  .md\:focus\:tw-opacity-100:focus{
    opacity: 1;
  }

  .md\:tw-bg-blend-normal{
    background-blend-mode: normal;
  }

  .md\:tw-bg-blend-multiply{
    background-blend-mode: multiply;
  }

  .md\:tw-bg-blend-screen{
    background-blend-mode: screen;
  }

  .md\:tw-bg-blend-overlay{
    background-blend-mode: overlay;
  }

  .md\:tw-bg-blend-darken{
    background-blend-mode: darken;
  }

  .md\:tw-bg-blend-lighten{
    background-blend-mode: lighten;
  }

  .md\:tw-bg-blend-color-dodge{
    background-blend-mode: color-dodge;
  }

  .md\:tw-bg-blend-color-burn{
    background-blend-mode: color-burn;
  }

  .md\:tw-bg-blend-hard-light{
    background-blend-mode: hard-light;
  }

  .md\:tw-bg-blend-soft-light{
    background-blend-mode: soft-light;
  }

  .md\:tw-bg-blend-difference{
    background-blend-mode: difference;
  }

  .md\:tw-bg-blend-exclusion{
    background-blend-mode: exclusion;
  }

  .md\:tw-bg-blend-hue{
    background-blend-mode: hue;
  }

  .md\:tw-bg-blend-saturation{
    background-blend-mode: saturation;
  }

  .md\:tw-bg-blend-color{
    background-blend-mode: color;
  }

  .md\:tw-bg-blend-luminosity{
    background-blend-mode: luminosity;
  }

  .md\:tw-mix-blend-normal{
    mix-blend-mode: normal;
  }

  .md\:tw-mix-blend-multiply{
    mix-blend-mode: multiply;
  }

  .md\:tw-mix-blend-screen{
    mix-blend-mode: screen;
  }

  .md\:tw-mix-blend-overlay{
    mix-blend-mode: overlay;
  }

  .md\:tw-mix-blend-darken{
    mix-blend-mode: darken;
  }

  .md\:tw-mix-blend-lighten{
    mix-blend-mode: lighten;
  }

  .md\:tw-mix-blend-color-dodge{
    mix-blend-mode: color-dodge;
  }

  .md\:tw-mix-blend-color-burn{
    mix-blend-mode: color-burn;
  }

  .md\:tw-mix-blend-hard-light{
    mix-blend-mode: hard-light;
  }

  .md\:tw-mix-blend-soft-light{
    mix-blend-mode: soft-light;
  }

  .md\:tw-mix-blend-difference{
    mix-blend-mode: difference;
  }

  .md\:tw-mix-blend-exclusion{
    mix-blend-mode: exclusion;
  }

  .md\:tw-mix-blend-hue{
    mix-blend-mode: hue;
  }

  .md\:tw-mix-blend-saturation{
    mix-blend-mode: saturation;
  }

  .md\:tw-mix-blend-color{
    mix-blend-mode: color;
  }

  .md\:tw-mix-blend-luminosity{
    mix-blend-mode: luminosity;
  }

  .md\:tw-shadow-xs{
    --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:tw-shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:tw-shadow-default{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:tw-shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:tw-shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:tw-shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:tw-shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:tw-shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:tw-shadow-outline{
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:tw-shadow-none{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:tw-shadow-xs:hover{
    --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:tw-shadow-sm:hover{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:tw-shadow-default:hover{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:tw-shadow-md:hover{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:tw-shadow-lg:hover{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:tw-shadow-xl:hover{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:tw-shadow-2xl:hover{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:tw-shadow-inner:hover{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:tw-shadow-outline:hover{
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:tw-shadow-none:hover{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:tw-shadow-xs:focus{
    --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:tw-shadow-sm:focus{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:tw-shadow-default:focus{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:tw-shadow-md:focus{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:tw-shadow-lg:focus{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:tw-shadow-xl:focus{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:tw-shadow-2xl:focus{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:tw-shadow-inner:focus{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:tw-shadow-outline:focus{
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:tw-shadow-none:focus{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:tw-outline-none{
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .md\:tw-outline-white{
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .md\:tw-outline-black{
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .md\:focus\:tw-outline-none:focus{
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .md\:focus\:tw-outline-white:focus{
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .md\:focus\:tw-outline-black:focus{
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .md\:tw-ring-0{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:tw-ring-1{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:tw-ring-2{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:tw-ring-4{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:tw-ring-8{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:tw-ring{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus-within\:tw-ring-0:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus-within\:tw-ring-1:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus-within\:tw-ring-2:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus-within\:tw-ring-4:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus-within\:tw-ring-8:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus-within\:tw-ring:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus\:tw-ring-0:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus\:tw-ring-1:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus\:tw-ring-2:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus\:tw-ring-4:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus\:tw-ring-8:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus\:tw-ring:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:tw-ring-inset{
    --tw-ring-inset: inset;
  }

  .md\:focus-within\:tw-ring-inset:focus-within{
    --tw-ring-inset: inset;
  }

  .md\:focus\:tw-ring-inset:focus{
    --tw-ring-inset: inset;
  }

  .md\:tw-ring-transparent{
    --tw-ring-color: transparent;
  }

  .md\:tw-ring-current{
    --tw-ring-color: currentColor;
  }

  .md\:tw-ring-black{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(44, 44, 44, var(--tw-ring-opacity));
  }

  .md\:tw-ring-white{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .md\:tw-ring-gray-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 250, 252, var(--tw-ring-opacity));
  }

  .md\:tw-ring-gray-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 242, 247, var(--tw-ring-opacity));
  }

  .md\:tw-ring-gray-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(226, 232, 240, var(--tw-ring-opacity));
  }

  .md\:tw-ring-gray-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(203, 213, 224, var(--tw-ring-opacity));
  }

  .md\:tw-ring-gray-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(160, 174, 192, var(--tw-ring-opacity));
  }

  .md\:tw-ring-gray-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(113, 128, 150, var(--tw-ring-opacity));
  }

  .md\:tw-ring-gray-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 85, 104, var(--tw-ring-opacity));
  }

  .md\:tw-ring-gray-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(45, 55, 72, var(--tw-ring-opacity));
  }

  .md\:tw-ring-gray-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 32, 44, var(--tw-ring-opacity));
  }

  .md\:tw-ring-blue-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(235, 248, 255, var(--tw-ring-opacity));
  }

  .md\:tw-ring-blue-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 227, 248, var(--tw-ring-opacity));
  }

  .md\:tw-ring-blue-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 205, 244, var(--tw-ring-opacity));
  }

  .md\:tw-ring-blue-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 179, 237, var(--tw-ring-opacity));
  }

  .md\:tw-ring-blue-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 153, 225, var(--tw-ring-opacity));
  }

  .md\:tw-ring-blue-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 130, 206, var(--tw-ring-opacity));
  }

  .md\:tw-ring-blue-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(43, 108, 176, var(--tw-ring-opacity));
  }

  .md\:tw-ring-blue-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(44, 82, 130, var(--tw-ring-opacity));
  }

  .md\:tw-ring-blue-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(42, 67, 101, var(--tw-ring-opacity));
  }

  .md\:focus-within\:tw-ring-transparent:focus-within{
    --tw-ring-color: transparent;
  }

  .md\:focus-within\:tw-ring-current:focus-within{
    --tw-ring-color: currentColor;
  }

  .md\:focus-within\:tw-ring-black:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(44, 44, 44, var(--tw-ring-opacity));
  }

  .md\:focus-within\:tw-ring-white:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .md\:focus-within\:tw-ring-gray-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 250, 252, var(--tw-ring-opacity));
  }

  .md\:focus-within\:tw-ring-gray-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 242, 247, var(--tw-ring-opacity));
  }

  .md\:focus-within\:tw-ring-gray-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(226, 232, 240, var(--tw-ring-opacity));
  }

  .md\:focus-within\:tw-ring-gray-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(203, 213, 224, var(--tw-ring-opacity));
  }

  .md\:focus-within\:tw-ring-gray-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(160, 174, 192, var(--tw-ring-opacity));
  }

  .md\:focus-within\:tw-ring-gray-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(113, 128, 150, var(--tw-ring-opacity));
  }

  .md\:focus-within\:tw-ring-gray-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 85, 104, var(--tw-ring-opacity));
  }

  .md\:focus-within\:tw-ring-gray-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(45, 55, 72, var(--tw-ring-opacity));
  }

  .md\:focus-within\:tw-ring-gray-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 32, 44, var(--tw-ring-opacity));
  }

  .md\:focus-within\:tw-ring-blue-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(235, 248, 255, var(--tw-ring-opacity));
  }

  .md\:focus-within\:tw-ring-blue-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 227, 248, var(--tw-ring-opacity));
  }

  .md\:focus-within\:tw-ring-blue-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 205, 244, var(--tw-ring-opacity));
  }

  .md\:focus-within\:tw-ring-blue-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 179, 237, var(--tw-ring-opacity));
  }

  .md\:focus-within\:tw-ring-blue-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 153, 225, var(--tw-ring-opacity));
  }

  .md\:focus-within\:tw-ring-blue-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 130, 206, var(--tw-ring-opacity));
  }

  .md\:focus-within\:tw-ring-blue-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(43, 108, 176, var(--tw-ring-opacity));
  }

  .md\:focus-within\:tw-ring-blue-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(44, 82, 130, var(--tw-ring-opacity));
  }

  .md\:focus-within\:tw-ring-blue-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(42, 67, 101, var(--tw-ring-opacity));
  }

  .md\:focus\:tw-ring-transparent:focus{
    --tw-ring-color: transparent;
  }

  .md\:focus\:tw-ring-current:focus{
    --tw-ring-color: currentColor;
  }

  .md\:focus\:tw-ring-black:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(44, 44, 44, var(--tw-ring-opacity));
  }

  .md\:focus\:tw-ring-white:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .md\:focus\:tw-ring-gray-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 250, 252, var(--tw-ring-opacity));
  }

  .md\:focus\:tw-ring-gray-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 242, 247, var(--tw-ring-opacity));
  }

  .md\:focus\:tw-ring-gray-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(226, 232, 240, var(--tw-ring-opacity));
  }

  .md\:focus\:tw-ring-gray-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(203, 213, 224, var(--tw-ring-opacity));
  }

  .md\:focus\:tw-ring-gray-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(160, 174, 192, var(--tw-ring-opacity));
  }

  .md\:focus\:tw-ring-gray-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(113, 128, 150, var(--tw-ring-opacity));
  }

  .md\:focus\:tw-ring-gray-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 85, 104, var(--tw-ring-opacity));
  }

  .md\:focus\:tw-ring-gray-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(45, 55, 72, var(--tw-ring-opacity));
  }

  .md\:focus\:tw-ring-gray-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 32, 44, var(--tw-ring-opacity));
  }

  .md\:focus\:tw-ring-blue-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(235, 248, 255, var(--tw-ring-opacity));
  }

  .md\:focus\:tw-ring-blue-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 227, 248, var(--tw-ring-opacity));
  }

  .md\:focus\:tw-ring-blue-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 205, 244, var(--tw-ring-opacity));
  }

  .md\:focus\:tw-ring-blue-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 179, 237, var(--tw-ring-opacity));
  }

  .md\:focus\:tw-ring-blue-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 153, 225, var(--tw-ring-opacity));
  }

  .md\:focus\:tw-ring-blue-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 130, 206, var(--tw-ring-opacity));
  }

  .md\:focus\:tw-ring-blue-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(43, 108, 176, var(--tw-ring-opacity));
  }

  .md\:focus\:tw-ring-blue-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(44, 82, 130, var(--tw-ring-opacity));
  }

  .md\:focus\:tw-ring-blue-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(42, 67, 101, var(--tw-ring-opacity));
  }

  .md\:tw-ring-opacity-0{
    --tw-ring-opacity: 0;
  }

  .md\:tw-ring-opacity-25{
    --tw-ring-opacity: 0.25;
  }

  .md\:tw-ring-opacity-50{
    --tw-ring-opacity: 0.5;
  }

  .md\:tw-ring-opacity-75{
    --tw-ring-opacity: 0.75;
  }

  .md\:tw-ring-opacity-90{
    --tw-ring-opacity: 0.9;
  }

  .md\:tw-ring-opacity-100{
    --tw-ring-opacity: 1;
  }

  .md\:focus-within\:tw-ring-opacity-0:focus-within{
    --tw-ring-opacity: 0;
  }

  .md\:focus-within\:tw-ring-opacity-25:focus-within{
    --tw-ring-opacity: 0.25;
  }

  .md\:focus-within\:tw-ring-opacity-50:focus-within{
    --tw-ring-opacity: 0.5;
  }

  .md\:focus-within\:tw-ring-opacity-75:focus-within{
    --tw-ring-opacity: 0.75;
  }

  .md\:focus-within\:tw-ring-opacity-90:focus-within{
    --tw-ring-opacity: 0.9;
  }

  .md\:focus-within\:tw-ring-opacity-100:focus-within{
    --tw-ring-opacity: 1;
  }

  .md\:focus\:tw-ring-opacity-0:focus{
    --tw-ring-opacity: 0;
  }

  .md\:focus\:tw-ring-opacity-25:focus{
    --tw-ring-opacity: 0.25;
  }

  .md\:focus\:tw-ring-opacity-50:focus{
    --tw-ring-opacity: 0.5;
  }

  .md\:focus\:tw-ring-opacity-75:focus{
    --tw-ring-opacity: 0.75;
  }

  .md\:focus\:tw-ring-opacity-90:focus{
    --tw-ring-opacity: 0.9;
  }

  .md\:focus\:tw-ring-opacity-100:focus{
    --tw-ring-opacity: 1;
  }

  .md\:tw-ring-offset-0{
    --tw-ring-offset-width: 0px;
  }

  .md\:tw-ring-offset-1{
    --tw-ring-offset-width: 1px;
  }

  .md\:tw-ring-offset-2{
    --tw-ring-offset-width: 2px;
  }

  .md\:tw-ring-offset-4{
    --tw-ring-offset-width: 4px;
  }

  .md\:tw-ring-offset-8{
    --tw-ring-offset-width: 8px;
  }

  .md\:focus-within\:tw-ring-offset-0:focus-within{
    --tw-ring-offset-width: 0px;
  }

  .md\:focus-within\:tw-ring-offset-1:focus-within{
    --tw-ring-offset-width: 1px;
  }

  .md\:focus-within\:tw-ring-offset-2:focus-within{
    --tw-ring-offset-width: 2px;
  }

  .md\:focus-within\:tw-ring-offset-4:focus-within{
    --tw-ring-offset-width: 4px;
  }

  .md\:focus-within\:tw-ring-offset-8:focus-within{
    --tw-ring-offset-width: 8px;
  }

  .md\:focus\:tw-ring-offset-0:focus{
    --tw-ring-offset-width: 0px;
  }

  .md\:focus\:tw-ring-offset-1:focus{
    --tw-ring-offset-width: 1px;
  }

  .md\:focus\:tw-ring-offset-2:focus{
    --tw-ring-offset-width: 2px;
  }

  .md\:focus\:tw-ring-offset-4:focus{
    --tw-ring-offset-width: 4px;
  }

  .md\:focus\:tw-ring-offset-8:focus{
    --tw-ring-offset-width: 8px;
  }

  .md\:tw-ring-offset-transparent{
    --tw-ring-offset-color: transparent;
  }

  .md\:tw-ring-offset-current{
    --tw-ring-offset-color: currentColor;
  }

  .md\:tw-ring-offset-black{
    --tw-ring-offset-color: #2c2c2c;
  }

  .md\:tw-ring-offset-white{
    --tw-ring-offset-color: #fff;
  }

  .md\:tw-ring-offset-gray-100{
    --tw-ring-offset-color: #f7fafc;
  }

  .md\:tw-ring-offset-gray-200{
    --tw-ring-offset-color: #edf2f7;
  }

  .md\:tw-ring-offset-gray-300{
    --tw-ring-offset-color: #e2e8f0;
  }

  .md\:tw-ring-offset-gray-400{
    --tw-ring-offset-color: #cbd5e0;
  }

  .md\:tw-ring-offset-gray-500{
    --tw-ring-offset-color: #a0aec0;
  }

  .md\:tw-ring-offset-gray-600{
    --tw-ring-offset-color: #718096;
  }

  .md\:tw-ring-offset-gray-700{
    --tw-ring-offset-color: #4a5568;
  }

  .md\:tw-ring-offset-gray-800{
    --tw-ring-offset-color: #2d3748;
  }

  .md\:tw-ring-offset-gray-900{
    --tw-ring-offset-color: #1a202c;
  }

  .md\:tw-ring-offset-blue-100{
    --tw-ring-offset-color: #ebf8ff;
  }

  .md\:tw-ring-offset-blue-200{
    --tw-ring-offset-color: #bee3f8;
  }

  .md\:tw-ring-offset-blue-300{
    --tw-ring-offset-color: #90cdf4;
  }

  .md\:tw-ring-offset-blue-400{
    --tw-ring-offset-color: #63b3ed;
  }

  .md\:tw-ring-offset-blue-500{
    --tw-ring-offset-color: #4299e1;
  }

  .md\:tw-ring-offset-blue-600{
    --tw-ring-offset-color: #3182ce;
  }

  .md\:tw-ring-offset-blue-700{
    --tw-ring-offset-color: #2b6cb0;
  }

  .md\:tw-ring-offset-blue-800{
    --tw-ring-offset-color: #2c5282;
  }

  .md\:tw-ring-offset-blue-900{
    --tw-ring-offset-color: #2a4365;
  }

  .md\:focus-within\:tw-ring-offset-transparent:focus-within{
    --tw-ring-offset-color: transparent;
  }

  .md\:focus-within\:tw-ring-offset-current:focus-within{
    --tw-ring-offset-color: currentColor;
  }

  .md\:focus-within\:tw-ring-offset-black:focus-within{
    --tw-ring-offset-color: #2c2c2c;
  }

  .md\:focus-within\:tw-ring-offset-white:focus-within{
    --tw-ring-offset-color: #fff;
  }

  .md\:focus-within\:tw-ring-offset-gray-100:focus-within{
    --tw-ring-offset-color: #f7fafc;
  }

  .md\:focus-within\:tw-ring-offset-gray-200:focus-within{
    --tw-ring-offset-color: #edf2f7;
  }

  .md\:focus-within\:tw-ring-offset-gray-300:focus-within{
    --tw-ring-offset-color: #e2e8f0;
  }

  .md\:focus-within\:tw-ring-offset-gray-400:focus-within{
    --tw-ring-offset-color: #cbd5e0;
  }

  .md\:focus-within\:tw-ring-offset-gray-500:focus-within{
    --tw-ring-offset-color: #a0aec0;
  }

  .md\:focus-within\:tw-ring-offset-gray-600:focus-within{
    --tw-ring-offset-color: #718096;
  }

  .md\:focus-within\:tw-ring-offset-gray-700:focus-within{
    --tw-ring-offset-color: #4a5568;
  }

  .md\:focus-within\:tw-ring-offset-gray-800:focus-within{
    --tw-ring-offset-color: #2d3748;
  }

  .md\:focus-within\:tw-ring-offset-gray-900:focus-within{
    --tw-ring-offset-color: #1a202c;
  }

  .md\:focus-within\:tw-ring-offset-blue-100:focus-within{
    --tw-ring-offset-color: #ebf8ff;
  }

  .md\:focus-within\:tw-ring-offset-blue-200:focus-within{
    --tw-ring-offset-color: #bee3f8;
  }

  .md\:focus-within\:tw-ring-offset-blue-300:focus-within{
    --tw-ring-offset-color: #90cdf4;
  }

  .md\:focus-within\:tw-ring-offset-blue-400:focus-within{
    --tw-ring-offset-color: #63b3ed;
  }

  .md\:focus-within\:tw-ring-offset-blue-500:focus-within{
    --tw-ring-offset-color: #4299e1;
  }

  .md\:focus-within\:tw-ring-offset-blue-600:focus-within{
    --tw-ring-offset-color: #3182ce;
  }

  .md\:focus-within\:tw-ring-offset-blue-700:focus-within{
    --tw-ring-offset-color: #2b6cb0;
  }

  .md\:focus-within\:tw-ring-offset-blue-800:focus-within{
    --tw-ring-offset-color: #2c5282;
  }

  .md\:focus-within\:tw-ring-offset-blue-900:focus-within{
    --tw-ring-offset-color: #2a4365;
  }

  .md\:focus\:tw-ring-offset-transparent:focus{
    --tw-ring-offset-color: transparent;
  }

  .md\:focus\:tw-ring-offset-current:focus{
    --tw-ring-offset-color: currentColor;
  }

  .md\:focus\:tw-ring-offset-black:focus{
    --tw-ring-offset-color: #2c2c2c;
  }

  .md\:focus\:tw-ring-offset-white:focus{
    --tw-ring-offset-color: #fff;
  }

  .md\:focus\:tw-ring-offset-gray-100:focus{
    --tw-ring-offset-color: #f7fafc;
  }

  .md\:focus\:tw-ring-offset-gray-200:focus{
    --tw-ring-offset-color: #edf2f7;
  }

  .md\:focus\:tw-ring-offset-gray-300:focus{
    --tw-ring-offset-color: #e2e8f0;
  }

  .md\:focus\:tw-ring-offset-gray-400:focus{
    --tw-ring-offset-color: #cbd5e0;
  }

  .md\:focus\:tw-ring-offset-gray-500:focus{
    --tw-ring-offset-color: #a0aec0;
  }

  .md\:focus\:tw-ring-offset-gray-600:focus{
    --tw-ring-offset-color: #718096;
  }

  .md\:focus\:tw-ring-offset-gray-700:focus{
    --tw-ring-offset-color: #4a5568;
  }

  .md\:focus\:tw-ring-offset-gray-800:focus{
    --tw-ring-offset-color: #2d3748;
  }

  .md\:focus\:tw-ring-offset-gray-900:focus{
    --tw-ring-offset-color: #1a202c;
  }

  .md\:focus\:tw-ring-offset-blue-100:focus{
    --tw-ring-offset-color: #ebf8ff;
  }

  .md\:focus\:tw-ring-offset-blue-200:focus{
    --tw-ring-offset-color: #bee3f8;
  }

  .md\:focus\:tw-ring-offset-blue-300:focus{
    --tw-ring-offset-color: #90cdf4;
  }

  .md\:focus\:tw-ring-offset-blue-400:focus{
    --tw-ring-offset-color: #63b3ed;
  }

  .md\:focus\:tw-ring-offset-blue-500:focus{
    --tw-ring-offset-color: #4299e1;
  }

  .md\:focus\:tw-ring-offset-blue-600:focus{
    --tw-ring-offset-color: #3182ce;
  }

  .md\:focus\:tw-ring-offset-blue-700:focus{
    --tw-ring-offset-color: #2b6cb0;
  }

  .md\:focus\:tw-ring-offset-blue-800:focus{
    --tw-ring-offset-color: #2c5282;
  }

  .md\:focus\:tw-ring-offset-blue-900:focus{
    --tw-ring-offset-color: #2a4365;
  }

  .md\:tw-filter{
    --tw-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
    -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:tw-filter-none{
    -webkit-filter: none;
    filter: none;
  }

  .md\:tw-blur-0{
    --tw-blur: blur(0);
  }

  .md\:tw-blur-none{
    --tw-blur: blur(0);
  }

  .md\:tw-blur-sm{
    --tw-blur: blur(4px);
  }

  .md\:tw-blur{
    --tw-blur: blur(8px);
  }

  .md\:tw-blur-md{
    --tw-blur: blur(12px);
  }

  .md\:tw-blur-lg{
    --tw-blur: blur(16px);
  }

  .md\:tw-blur-xl{
    --tw-blur: blur(24px);
  }

  .md\:tw-blur-2xl{
    --tw-blur: blur(40px);
  }

  .md\:tw-blur-3xl{
    --tw-blur: blur(64px);
  }

  .md\:tw-brightness-0{
    --tw-brightness: brightness(0);
  }

  .md\:tw-brightness-50{
    --tw-brightness: brightness(.5);
  }

  .md\:tw-brightness-75{
    --tw-brightness: brightness(.75);
  }

  .md\:tw-brightness-90{
    --tw-brightness: brightness(.9);
  }

  .md\:tw-brightness-95{
    --tw-brightness: brightness(.95);
  }

  .md\:tw-brightness-100{
    --tw-brightness: brightness(1);
  }

  .md\:tw-brightness-105{
    --tw-brightness: brightness(1.05);
  }

  .md\:tw-brightness-110{
    --tw-brightness: brightness(1.1);
  }

  .md\:tw-brightness-125{
    --tw-brightness: brightness(1.25);
  }

  .md\:tw-brightness-150{
    --tw-brightness: brightness(1.5);
  }

  .md\:tw-brightness-200{
    --tw-brightness: brightness(2);
  }

  .md\:tw-contrast-0{
    --tw-contrast: contrast(0);
  }

  .md\:tw-contrast-50{
    --tw-contrast: contrast(.5);
  }

  .md\:tw-contrast-75{
    --tw-contrast: contrast(.75);
  }

  .md\:tw-contrast-100{
    --tw-contrast: contrast(1);
  }

  .md\:tw-contrast-125{
    --tw-contrast: contrast(1.25);
  }

  .md\:tw-contrast-150{
    --tw-contrast: contrast(1.5);
  }

  .md\:tw-contrast-200{
    --tw-contrast: contrast(2);
  }

  .md\:tw-drop-shadow-sm{
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05));
  }

  .md\:tw-drop-shadow{
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
  }

  .md\:tw-drop-shadow-md{
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
  }

  .md\:tw-drop-shadow-lg{
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
  }

  .md\:tw-drop-shadow-xl{
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
  }

  .md\:tw-drop-shadow-2xl{
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15));
  }

  .md\:tw-drop-shadow-none{
    --tw-drop-shadow: drop-shadow(0 0 #0000);
  }

  .md\:tw-grayscale-0{
    --tw-grayscale: grayscale(0);
  }

  .md\:tw-grayscale{
    --tw-grayscale: grayscale(100%);
  }

  .md\:tw-hue-rotate-0{
    --tw-hue-rotate: hue-rotate(0deg);
  }

  .md\:tw-hue-rotate-15{
    --tw-hue-rotate: hue-rotate(15deg);
  }

  .md\:tw-hue-rotate-30{
    --tw-hue-rotate: hue-rotate(30deg);
  }

  .md\:tw-hue-rotate-60{
    --tw-hue-rotate: hue-rotate(60deg);
  }

  .md\:tw-hue-rotate-90{
    --tw-hue-rotate: hue-rotate(90deg);
  }

  .md\:tw-hue-rotate-180{
    --tw-hue-rotate: hue-rotate(180deg);
  }

  .md\:tw--hue-rotate-180{
    --tw-hue-rotate: hue-rotate(-180deg);
  }

  .md\:tw--hue-rotate-90{
    --tw-hue-rotate: hue-rotate(-90deg);
  }

  .md\:tw--hue-rotate-60{
    --tw-hue-rotate: hue-rotate(-60deg);
  }

  .md\:tw--hue-rotate-30{
    --tw-hue-rotate: hue-rotate(-30deg);
  }

  .md\:tw--hue-rotate-15{
    --tw-hue-rotate: hue-rotate(-15deg);
  }

  .md\:tw-invert-0{
    --tw-invert: invert(0);
  }

  .md\:tw-invert{
    --tw-invert: invert(100%);
  }

  .md\:tw-saturate-0{
    --tw-saturate: saturate(0);
  }

  .md\:tw-saturate-50{
    --tw-saturate: saturate(.5);
  }

  .md\:tw-saturate-100{
    --tw-saturate: saturate(1);
  }

  .md\:tw-saturate-150{
    --tw-saturate: saturate(1.5);
  }

  .md\:tw-saturate-200{
    --tw-saturate: saturate(2);
  }

  .md\:tw-sepia-0{
    --tw-sepia: sepia(0);
  }

  .md\:tw-sepia{
    --tw-sepia: sepia(100%);
  }

  .md\:tw-backdrop-filter{
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:tw-backdrop-filter-none{
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
  }

  .md\:tw-backdrop-blur-0{
    --tw-backdrop-blur: blur(0);
  }

  .md\:tw-backdrop-blur-none{
    --tw-backdrop-blur: blur(0);
  }

  .md\:tw-backdrop-blur-sm{
    --tw-backdrop-blur: blur(4px);
  }

  .md\:tw-backdrop-blur{
    --tw-backdrop-blur: blur(8px);
  }

  .md\:tw-backdrop-blur-md{
    --tw-backdrop-blur: blur(12px);
  }

  .md\:tw-backdrop-blur-lg{
    --tw-backdrop-blur: blur(16px);
  }

  .md\:tw-backdrop-blur-xl{
    --tw-backdrop-blur: blur(24px);
  }

  .md\:tw-backdrop-blur-2xl{
    --tw-backdrop-blur: blur(40px);
  }

  .md\:tw-backdrop-blur-3xl{
    --tw-backdrop-blur: blur(64px);
  }

  .md\:tw-backdrop-brightness-0{
    --tw-backdrop-brightness: brightness(0);
  }

  .md\:tw-backdrop-brightness-50{
    --tw-backdrop-brightness: brightness(.5);
  }

  .md\:tw-backdrop-brightness-75{
    --tw-backdrop-brightness: brightness(.75);
  }

  .md\:tw-backdrop-brightness-90{
    --tw-backdrop-brightness: brightness(.9);
  }

  .md\:tw-backdrop-brightness-95{
    --tw-backdrop-brightness: brightness(.95);
  }

  .md\:tw-backdrop-brightness-100{
    --tw-backdrop-brightness: brightness(1);
  }

  .md\:tw-backdrop-brightness-105{
    --tw-backdrop-brightness: brightness(1.05);
  }

  .md\:tw-backdrop-brightness-110{
    --tw-backdrop-brightness: brightness(1.1);
  }

  .md\:tw-backdrop-brightness-125{
    --tw-backdrop-brightness: brightness(1.25);
  }

  .md\:tw-backdrop-brightness-150{
    --tw-backdrop-brightness: brightness(1.5);
  }

  .md\:tw-backdrop-brightness-200{
    --tw-backdrop-brightness: brightness(2);
  }

  .md\:tw-backdrop-contrast-0{
    --tw-backdrop-contrast: contrast(0);
  }

  .md\:tw-backdrop-contrast-50{
    --tw-backdrop-contrast: contrast(.5);
  }

  .md\:tw-backdrop-contrast-75{
    --tw-backdrop-contrast: contrast(.75);
  }

  .md\:tw-backdrop-contrast-100{
    --tw-backdrop-contrast: contrast(1);
  }

  .md\:tw-backdrop-contrast-125{
    --tw-backdrop-contrast: contrast(1.25);
  }

  .md\:tw-backdrop-contrast-150{
    --tw-backdrop-contrast: contrast(1.5);
  }

  .md\:tw-backdrop-contrast-200{
    --tw-backdrop-contrast: contrast(2);
  }

  .md\:tw-backdrop-grayscale-0{
    --tw-backdrop-grayscale: grayscale(0);
  }

  .md\:tw-backdrop-grayscale{
    --tw-backdrop-grayscale: grayscale(100%);
  }

  .md\:tw-backdrop-hue-rotate-0{
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
  }

  .md\:tw-backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
  }

  .md\:tw-backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
  }

  .md\:tw-backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
  }

  .md\:tw-backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
  }

  .md\:tw-backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
  }

  .md\:tw--backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  }

  .md\:tw--backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  }

  .md\:tw--backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  }

  .md\:tw--backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  }

  .md\:tw--backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  }

  .md\:tw-backdrop-invert-0{
    --tw-backdrop-invert: invert(0);
  }

  .md\:tw-backdrop-invert{
    --tw-backdrop-invert: invert(100%);
  }

  .md\:tw-backdrop-opacity-0{
    --tw-backdrop-opacity: opacity(0);
  }

  .md\:tw-backdrop-opacity-25{
    --tw-backdrop-opacity: opacity(0.25);
  }

  .md\:tw-backdrop-opacity-50{
    --tw-backdrop-opacity: opacity(0.5);
  }

  .md\:tw-backdrop-opacity-75{
    --tw-backdrop-opacity: opacity(0.75);
  }

  .md\:tw-backdrop-opacity-90{
    --tw-backdrop-opacity: opacity(0.9);
  }

  .md\:tw-backdrop-opacity-100{
    --tw-backdrop-opacity: opacity(1);
  }

  .md\:tw-backdrop-saturate-0{
    --tw-backdrop-saturate: saturate(0);
  }

  .md\:tw-backdrop-saturate-50{
    --tw-backdrop-saturate: saturate(.5);
  }

  .md\:tw-backdrop-saturate-100{
    --tw-backdrop-saturate: saturate(1);
  }

  .md\:tw-backdrop-saturate-150{
    --tw-backdrop-saturate: saturate(1.5);
  }

  .md\:tw-backdrop-saturate-200{
    --tw-backdrop-saturate: saturate(2);
  }

  .md\:tw-backdrop-sepia-0{
    --tw-backdrop-sepia: sepia(0);
  }

  .md\:tw-backdrop-sepia{
    --tw-backdrop-sepia: sepia(100%);
  }

  .md\:tw-transition-none{
    transition-property: none;
  }

  .md\:tw-transition-all{
    transition-property: all;
  }

  .md\:tw-transition-default{
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  }

  .md\:tw-transition-colors{
    transition-property: background-color, border-color, color, fill, stroke;
  }

  .md\:tw-transition-opacity{
    transition-property: opacity;
  }

  .md\:tw-transition-shadow{
    transition-property: box-shadow;
  }

  .md\:tw-transition-transform{
    transition-property: transform;
  }

  .md\:tw-delay-75{
    transition-delay: 75ms;
  }

  .md\:tw-delay-100{
    transition-delay: 100ms;
  }

  .md\:tw-delay-150{
    transition-delay: 150ms;
  }

  .md\:tw-delay-200{
    transition-delay: 200ms;
  }

  .md\:tw-delay-300{
    transition-delay: 300ms;
  }

  .md\:tw-delay-500{
    transition-delay: 500ms;
  }

  .md\:tw-delay-700{
    transition-delay: 700ms;
  }

  .md\:tw-delay-1000{
    transition-delay: 1000ms;
  }

  .md\:tw-duration-75{
    transition-duration: 75ms;
  }

  .md\:tw-duration-100{
    transition-duration: 100ms;
  }

  .md\:tw-duration-150{
    transition-duration: 150ms;
  }

  .md\:tw-duration-200{
    transition-duration: 200ms;
  }

  .md\:tw-duration-300{
    transition-duration: 300ms;
  }

  .md\:tw-duration-500{
    transition-duration: 500ms;
  }

  .md\:tw-duration-700{
    transition-duration: 700ms;
  }

  .md\:tw-duration-1000{
    transition-duration: 1000ms;
  }

  .md\:tw-ease-linear{
    transition-timing-function: linear;
  }

  .md\:tw-ease-in{
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .md\:tw-ease-out{
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .md\:tw-ease-in-out{
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}

@media (min-width: 1024px){
  .lg\:tw-container{
    width: 100%;
  }
}

@media (min-width: 1024px) and (min-width: 640px){
  .lg\:tw-container{
    max-width: 640px;
  }
}

@media (min-width: 1024px) and (min-width: 768px){
  .lg\:tw-container{
    max-width: 768px;
  }
}

@media (min-width: 1024px) and (min-width: 1024px){
  .lg\:tw-container{
    max-width: 1024px;
  }
}

@media (min-width: 1024px) and (min-width: 1280px){
  .lg\:tw-container{
    max-width: 1280px;
  }
}

@media (min-width: 1024px){
  .lg\:tw-sr-only{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .lg\:tw-not-sr-only{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .lg\:focus\:tw-sr-only:focus{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .lg\:focus\:tw-not-sr-only:focus{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .lg\:tw-pointer-events-none{
    pointer-events: none;
  }

  .lg\:tw-pointer-events-auto{
    pointer-events: auto;
  }

  .lg\:tw-visible{
    visibility: visible;
  }

  .lg\:tw-invisible{
    visibility: hidden;
  }

  .lg\:tw-static{
    position: static;
  }

  .lg\:tw-fixed{
    position: fixed;
  }

  .lg\:tw-absolute{
    position: absolute;
  }

  .lg\:tw-relative{
    position: relative;
  }

  .lg\:tw-sticky{
    position: -webkit-sticky;
    position: sticky;
  }

  .lg\:tw-inset-0{
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .lg\:tw-inset-auto{
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .lg\:tw-inset-x-0{
    left: 0;
    right: 0;
  }

  .lg\:tw-inset-x-auto{
    left: auto;
    right: auto;
  }

  .lg\:tw-inset-y-0{
    top: 0;
    bottom: 0;
  }

  .lg\:tw-inset-y-auto{
    top: auto;
    bottom: auto;
  }

  .lg\:tw-top-0{
    top: 0;
  }

  .lg\:tw-top-auto{
    top: auto;
  }

  .lg\:tw-right-0{
    right: 0;
  }

  .lg\:tw-right-auto{
    right: auto;
  }

  .lg\:tw-bottom-0{
    bottom: 0;
  }

  .lg\:tw-bottom-auto{
    bottom: auto;
  }

  .lg\:tw-left-0{
    left: 0;
  }

  .lg\:tw-left-auto{
    left: auto;
  }

  .lg\:tw-isolate{
    isolation: isolate;
  }

  .lg\:tw-isolation-auto{
    isolation: auto;
  }

  .lg\:tw-z-0{
    z-index: 0;
  }

  .lg\:tw-z-10{
    z-index: 10;
  }

  .lg\:tw-z-20{
    z-index: 20;
  }

  .lg\:tw-z-30{
    z-index: 30;
  }

  .lg\:tw-z-40{
    z-index: 40;
  }

  .lg\:tw-z-50{
    z-index: 50;
  }

  .lg\:tw-z-auto{
    z-index: auto;
  }

  .lg\:tw-order-1{
    order: 1;
  }

  .lg\:tw-order-2{
    order: 2;
  }

  .lg\:tw-order-3{
    order: 3;
  }

  .lg\:tw-order-4{
    order: 4;
  }

  .lg\:tw-order-5{
    order: 5;
  }

  .lg\:tw-order-6{
    order: 6;
  }

  .lg\:tw-order-7{
    order: 7;
  }

  .lg\:tw-order-8{
    order: 8;
  }

  .lg\:tw-order-9{
    order: 9;
  }

  .lg\:tw-order-10{
    order: 10;
  }

  .lg\:tw-order-11{
    order: 11;
  }

  .lg\:tw-order-12{
    order: 12;
  }

  .lg\:tw-order-first{
    order: -9999;
  }

  .lg\:tw-order-last{
    order: 9999;
  }

  .lg\:tw-order-none{
    order: 0;
  }

  .lg\:tw-col-auto{
    grid-column: auto;
  }

  .lg\:tw-col-span-1{
    grid-column: span 1 / span 1;
  }

  .lg\:tw-col-span-2{
    grid-column: span 2 / span 2;
  }

  .lg\:tw-col-span-3{
    grid-column: span 3 / span 3;
  }

  .lg\:tw-col-span-4{
    grid-column: span 4 / span 4;
  }

  .lg\:tw-col-span-5{
    grid-column: span 5 / span 5;
  }

  .lg\:tw-col-span-6{
    grid-column: span 6 / span 6;
  }

  .lg\:tw-col-span-7{
    grid-column: span 7 / span 7;
  }

  .lg\:tw-col-span-8{
    grid-column: span 8 / span 8;
  }

  .lg\:tw-col-span-9{
    grid-column: span 9 / span 9;
  }

  .lg\:tw-col-span-10{
    grid-column: span 10 / span 10;
  }

  .lg\:tw-col-span-11{
    grid-column: span 11 / span 11;
  }

  .lg\:tw-col-span-12{
    grid-column: span 12 / span 12;
  }

  .lg\:tw-col-span-full{
    grid-column: 1 / -1;
  }

  .lg\:tw-col-start-1{
    grid-column-start: 1;
  }

  .lg\:tw-col-start-2{
    grid-column-start: 2;
  }

  .lg\:tw-col-start-3{
    grid-column-start: 3;
  }

  .lg\:tw-col-start-4{
    grid-column-start: 4;
  }

  .lg\:tw-col-start-5{
    grid-column-start: 5;
  }

  .lg\:tw-col-start-6{
    grid-column-start: 6;
  }

  .lg\:tw-col-start-7{
    grid-column-start: 7;
  }

  .lg\:tw-col-start-8{
    grid-column-start: 8;
  }

  .lg\:tw-col-start-9{
    grid-column-start: 9;
  }

  .lg\:tw-col-start-10{
    grid-column-start: 10;
  }

  .lg\:tw-col-start-11{
    grid-column-start: 11;
  }

  .lg\:tw-col-start-12{
    grid-column-start: 12;
  }

  .lg\:tw-col-start-13{
    grid-column-start: 13;
  }

  .lg\:tw-col-start-auto{
    grid-column-start: auto;
  }

  .lg\:tw-col-end-1{
    grid-column-end: 1;
  }

  .lg\:tw-col-end-2{
    grid-column-end: 2;
  }

  .lg\:tw-col-end-3{
    grid-column-end: 3;
  }

  .lg\:tw-col-end-4{
    grid-column-end: 4;
  }

  .lg\:tw-col-end-5{
    grid-column-end: 5;
  }

  .lg\:tw-col-end-6{
    grid-column-end: 6;
  }

  .lg\:tw-col-end-7{
    grid-column-end: 7;
  }

  .lg\:tw-col-end-8{
    grid-column-end: 8;
  }

  .lg\:tw-col-end-9{
    grid-column-end: 9;
  }

  .lg\:tw-col-end-10{
    grid-column-end: 10;
  }

  .lg\:tw-col-end-11{
    grid-column-end: 11;
  }

  .lg\:tw-col-end-12{
    grid-column-end: 12;
  }

  .lg\:tw-col-end-13{
    grid-column-end: 13;
  }

  .lg\:tw-col-end-auto{
    grid-column-end: auto;
  }

  .lg\:tw-row-auto{
    grid-row: auto;
  }

  .lg\:tw-row-span-1{
    grid-row: span 1 / span 1;
  }

  .lg\:tw-row-span-2{
    grid-row: span 2 / span 2;
  }

  .lg\:tw-row-span-3{
    grid-row: span 3 / span 3;
  }

  .lg\:tw-row-span-4{
    grid-row: span 4 / span 4;
  }

  .lg\:tw-row-span-5{
    grid-row: span 5 / span 5;
  }

  .lg\:tw-row-span-6{
    grid-row: span 6 / span 6;
  }

  .lg\:tw-row-span-full{
    grid-row: 1 / -1;
  }

  .lg\:tw-row-start-1{
    grid-row-start: 1;
  }

  .lg\:tw-row-start-2{
    grid-row-start: 2;
  }

  .lg\:tw-row-start-3{
    grid-row-start: 3;
  }

  .lg\:tw-row-start-4{
    grid-row-start: 4;
  }

  .lg\:tw-row-start-5{
    grid-row-start: 5;
  }

  .lg\:tw-row-start-6{
    grid-row-start: 6;
  }

  .lg\:tw-row-start-7{
    grid-row-start: 7;
  }

  .lg\:tw-row-start-auto{
    grid-row-start: auto;
  }

  .lg\:tw-row-end-1{
    grid-row-end: 1;
  }

  .lg\:tw-row-end-2{
    grid-row-end: 2;
  }

  .lg\:tw-row-end-3{
    grid-row-end: 3;
  }

  .lg\:tw-row-end-4{
    grid-row-end: 4;
  }

  .lg\:tw-row-end-5{
    grid-row-end: 5;
  }

  .lg\:tw-row-end-6{
    grid-row-end: 6;
  }

  .lg\:tw-row-end-7{
    grid-row-end: 7;
  }

  .lg\:tw-row-end-auto{
    grid-row-end: auto;
  }

  .lg\:tw-float-right{
    float: right;
  }

  .lg\:tw-float-left{
    float: left;
  }

  .lg\:tw-float-none{
    float: none;
  }

  .lg\:tw-clear-left{
    clear: left;
  }

  .lg\:tw-clear-right{
    clear: right;
  }

  .lg\:tw-clear-both{
    clear: both;
  }

  .lg\:tw-clear-none{
    clear: none;
  }

  .lg\:tw-m-0{
    margin: 0;
  }

  .lg\:tw-m-1{
    margin: 0.25rem;
  }

  .lg\:tw-m-2{
    margin: 0.5rem;
  }

  .lg\:tw-m-3{
    margin: 0.75rem;
  }

  .lg\:tw-m-4{
    margin: 1rem;
  }

  .lg\:tw-m-5{
    margin: 1.25rem;
  }

  .lg\:tw-m-6{
    margin: 1.5rem;
  }

  .lg\:tw-m-8{
    margin: 2rem;
  }

  .lg\:tw-m-10{
    margin: 2.5rem;
  }

  .lg\:tw-m-12{
    margin: 3rem;
  }

  .lg\:tw-m-16{
    margin: 4rem;
  }

  .lg\:tw-m-20{
    margin: 5rem;
  }

  .lg\:tw-m-24{
    margin: 6rem;
  }

  .lg\:tw-m-32{
    margin: 8rem;
  }

  .lg\:tw-m-40{
    margin: 10rem;
  }

  .lg\:tw-m-48{
    margin: 12rem;
  }

  .lg\:tw-m-56{
    margin: 14rem;
  }

  .lg\:tw-m-64{
    margin: 16rem;
  }

  .lg\:tw-m-auto{
    margin: auto;
  }

  .lg\:tw-m-px{
    margin: 1px;
  }

  .lg\:tw--m-1{
    margin: -0.25rem;
  }

  .lg\:tw--m-2{
    margin: -0.5rem;
  }

  .lg\:tw--m-3{
    margin: -0.75rem;
  }

  .lg\:tw--m-4{
    margin: -1rem;
  }

  .lg\:tw--m-5{
    margin: -1.25rem;
  }

  .lg\:tw--m-6{
    margin: -1.5rem;
  }

  .lg\:tw--m-8{
    margin: -2rem;
  }

  .lg\:tw--m-10{
    margin: -2.5rem;
  }

  .lg\:tw--m-12{
    margin: -3rem;
  }

  .lg\:tw--m-16{
    margin: -4rem;
  }

  .lg\:tw--m-20{
    margin: -5rem;
  }

  .lg\:tw--m-24{
    margin: -6rem;
  }

  .lg\:tw--m-32{
    margin: -8rem;
  }

  .lg\:tw--m-40{
    margin: -10rem;
  }

  .lg\:tw--m-48{
    margin: -12rem;
  }

  .lg\:tw--m-56{
    margin: -14rem;
  }

  .lg\:tw--m-64{
    margin: -16rem;
  }

  .lg\:tw--m-px{
    margin: -1px;
  }

  .lg\:tw-mx-0{
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:tw-mx-1{
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .lg\:tw-mx-2{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .lg\:tw-mx-3{
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .lg\:tw-mx-4{
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .lg\:tw-mx-5{
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .lg\:tw-mx-6{
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .lg\:tw-mx-8{
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .lg\:tw-mx-10{
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .lg\:tw-mx-12{
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .lg\:tw-mx-16{
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .lg\:tw-mx-20{
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .lg\:tw-mx-24{
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .lg\:tw-mx-32{
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .lg\:tw-mx-40{
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .lg\:tw-mx-48{
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .lg\:tw-mx-56{
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .lg\:tw-mx-64{
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .lg\:tw-mx-auto{
    margin-left: auto;
    margin-right: auto;
  }

  .lg\:tw-mx-px{
    margin-left: 1px;
    margin-right: 1px;
  }

  .lg\:tw--mx-1{
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  .lg\:tw--mx-2{
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .lg\:tw--mx-3{
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  .lg\:tw--mx-4{
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .lg\:tw--mx-5{
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .lg\:tw--mx-6{
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .lg\:tw--mx-8{
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .lg\:tw--mx-10{
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .lg\:tw--mx-12{
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .lg\:tw--mx-16{
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .lg\:tw--mx-20{
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .lg\:tw--mx-24{
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .lg\:tw--mx-32{
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .lg\:tw--mx-40{
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .lg\:tw--mx-48{
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .lg\:tw--mx-56{
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .lg\:tw--mx-64{
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .lg\:tw--mx-px{
    margin-left: -1px;
    margin-right: -1px;
  }

  .lg\:tw-my-0{
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:tw-my-1{
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .lg\:tw-my-2{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .lg\:tw-my-3{
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .lg\:tw-my-4{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .lg\:tw-my-5{
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .lg\:tw-my-6{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .lg\:tw-my-8{
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .lg\:tw-my-10{
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .lg\:tw-my-12{
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .lg\:tw-my-16{
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .lg\:tw-my-20{
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .lg\:tw-my-24{
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .lg\:tw-my-32{
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .lg\:tw-my-40{
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .lg\:tw-my-48{
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .lg\:tw-my-56{
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .lg\:tw-my-64{
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .lg\:tw-my-auto{
    margin-top: auto;
    margin-bottom: auto;
  }

  .lg\:tw-my-px{
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .lg\:tw--my-1{
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }

  .lg\:tw--my-2{
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .lg\:tw--my-3{
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }

  .lg\:tw--my-4{
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .lg\:tw--my-5{
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .lg\:tw--my-6{
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .lg\:tw--my-8{
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .lg\:tw--my-10{
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .lg\:tw--my-12{
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .lg\:tw--my-16{
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .lg\:tw--my-20{
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .lg\:tw--my-24{
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .lg\:tw--my-32{
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .lg\:tw--my-40{
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .lg\:tw--my-48{
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .lg\:tw--my-56{
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .lg\:tw--my-64{
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .lg\:tw--my-px{
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .lg\:tw-mt-0{
    margin-top: 0;
  }

  .lg\:tw-mt-1{
    margin-top: 0.25rem;
  }

  .lg\:tw-mt-2{
    margin-top: 0.5rem;
  }

  .lg\:tw-mt-3{
    margin-top: 0.75rem;
  }

  .lg\:tw-mt-4{
    margin-top: 1rem;
  }

  .lg\:tw-mt-5{
    margin-top: 1.25rem;
  }

  .lg\:tw-mt-6{
    margin-top: 1.5rem;
  }

  .lg\:tw-mt-8{
    margin-top: 2rem;
  }

  .lg\:tw-mt-10{
    margin-top: 2.5rem;
  }

  .lg\:tw-mt-12{
    margin-top: 3rem;
  }

  .lg\:tw-mt-16{
    margin-top: 4rem;
  }

  .lg\:tw-mt-20{
    margin-top: 5rem;
  }

  .lg\:tw-mt-24{
    margin-top: 6rem;
  }

  .lg\:tw-mt-32{
    margin-top: 8rem;
  }

  .lg\:tw-mt-40{
    margin-top: 10rem;
  }

  .lg\:tw-mt-48{
    margin-top: 12rem;
  }

  .lg\:tw-mt-56{
    margin-top: 14rem;
  }

  .lg\:tw-mt-64{
    margin-top: 16rem;
  }

  .lg\:tw-mt-auto{
    margin-top: auto;
  }

  .lg\:tw-mt-px{
    margin-top: 1px;
  }

  .lg\:tw--mt-1{
    margin-top: -0.25rem;
  }

  .lg\:tw--mt-2{
    margin-top: -0.5rem;
  }

  .lg\:tw--mt-3{
    margin-top: -0.75rem;
  }

  .lg\:tw--mt-4{
    margin-top: -1rem;
  }

  .lg\:tw--mt-5{
    margin-top: -1.25rem;
  }

  .lg\:tw--mt-6{
    margin-top: -1.5rem;
  }

  .lg\:tw--mt-8{
    margin-top: -2rem;
  }

  .lg\:tw--mt-10{
    margin-top: -2.5rem;
  }

  .lg\:tw--mt-12{
    margin-top: -3rem;
  }

  .lg\:tw--mt-16{
    margin-top: -4rem;
  }

  .lg\:tw--mt-20{
    margin-top: -5rem;
  }

  .lg\:tw--mt-24{
    margin-top: -6rem;
  }

  .lg\:tw--mt-32{
    margin-top: -8rem;
  }

  .lg\:tw--mt-40{
    margin-top: -10rem;
  }

  .lg\:tw--mt-48{
    margin-top: -12rem;
  }

  .lg\:tw--mt-56{
    margin-top: -14rem;
  }

  .lg\:tw--mt-64{
    margin-top: -16rem;
  }

  .lg\:tw--mt-px{
    margin-top: -1px;
  }

  .lg\:tw-mr-0{
    margin-right: 0;
  }

  .lg\:tw-mr-1{
    margin-right: 0.25rem;
  }

  .lg\:tw-mr-2{
    margin-right: 0.5rem;
  }

  .lg\:tw-mr-3{
    margin-right: 0.75rem;
  }

  .lg\:tw-mr-4{
    margin-right: 1rem;
  }

  .lg\:tw-mr-5{
    margin-right: 1.25rem;
  }

  .lg\:tw-mr-6{
    margin-right: 1.5rem;
  }

  .lg\:tw-mr-8{
    margin-right: 2rem;
  }

  .lg\:tw-mr-10{
    margin-right: 2.5rem;
  }

  .lg\:tw-mr-12{
    margin-right: 3rem;
  }

  .lg\:tw-mr-16{
    margin-right: 4rem;
  }

  .lg\:tw-mr-20{
    margin-right: 5rem;
  }

  .lg\:tw-mr-24{
    margin-right: 6rem;
  }

  .lg\:tw-mr-32{
    margin-right: 8rem;
  }

  .lg\:tw-mr-40{
    margin-right: 10rem;
  }

  .lg\:tw-mr-48{
    margin-right: 12rem;
  }

  .lg\:tw-mr-56{
    margin-right: 14rem;
  }

  .lg\:tw-mr-64{
    margin-right: 16rem;
  }

  .lg\:tw-mr-auto{
    margin-right: auto;
  }

  .lg\:tw-mr-px{
    margin-right: 1px;
  }

  .lg\:tw--mr-1{
    margin-right: -0.25rem;
  }

  .lg\:tw--mr-2{
    margin-right: -0.5rem;
  }

  .lg\:tw--mr-3{
    margin-right: -0.75rem;
  }

  .lg\:tw--mr-4{
    margin-right: -1rem;
  }

  .lg\:tw--mr-5{
    margin-right: -1.25rem;
  }

  .lg\:tw--mr-6{
    margin-right: -1.5rem;
  }

  .lg\:tw--mr-8{
    margin-right: -2rem;
  }

  .lg\:tw--mr-10{
    margin-right: -2.5rem;
  }

  .lg\:tw--mr-12{
    margin-right: -3rem;
  }

  .lg\:tw--mr-16{
    margin-right: -4rem;
  }

  .lg\:tw--mr-20{
    margin-right: -5rem;
  }

  .lg\:tw--mr-24{
    margin-right: -6rem;
  }

  .lg\:tw--mr-32{
    margin-right: -8rem;
  }

  .lg\:tw--mr-40{
    margin-right: -10rem;
  }

  .lg\:tw--mr-48{
    margin-right: -12rem;
  }

  .lg\:tw--mr-56{
    margin-right: -14rem;
  }

  .lg\:tw--mr-64{
    margin-right: -16rem;
  }

  .lg\:tw--mr-px{
    margin-right: -1px;
  }

  .lg\:tw-mb-0{
    margin-bottom: 0;
  }

  .lg\:tw-mb-1{
    margin-bottom: 0.25rem;
  }

  .lg\:tw-mb-2{
    margin-bottom: 0.5rem;
  }

  .lg\:tw-mb-3{
    margin-bottom: 0.75rem;
  }

  .lg\:tw-mb-4{
    margin-bottom: 1rem;
  }

  .lg\:tw-mb-5{
    margin-bottom: 1.25rem;
  }

  .lg\:tw-mb-6{
    margin-bottom: 1.5rem;
  }

  .lg\:tw-mb-8{
    margin-bottom: 2rem;
  }

  .lg\:tw-mb-10{
    margin-bottom: 2.5rem;
  }

  .lg\:tw-mb-12{
    margin-bottom: 3rem;
  }

  .lg\:tw-mb-16{
    margin-bottom: 4rem;
  }

  .lg\:tw-mb-20{
    margin-bottom: 5rem;
  }

  .lg\:tw-mb-24{
    margin-bottom: 6rem;
  }

  .lg\:tw-mb-32{
    margin-bottom: 8rem;
  }

  .lg\:tw-mb-40{
    margin-bottom: 10rem;
  }

  .lg\:tw-mb-48{
    margin-bottom: 12rem;
  }

  .lg\:tw-mb-56{
    margin-bottom: 14rem;
  }

  .lg\:tw-mb-64{
    margin-bottom: 16rem;
  }

  .lg\:tw-mb-auto{
    margin-bottom: auto;
  }

  .lg\:tw-mb-px{
    margin-bottom: 1px;
  }

  .lg\:tw--mb-1{
    margin-bottom: -0.25rem;
  }

  .lg\:tw--mb-2{
    margin-bottom: -0.5rem;
  }

  .lg\:tw--mb-3{
    margin-bottom: -0.75rem;
  }

  .lg\:tw--mb-4{
    margin-bottom: -1rem;
  }

  .lg\:tw--mb-5{
    margin-bottom: -1.25rem;
  }

  .lg\:tw--mb-6{
    margin-bottom: -1.5rem;
  }

  .lg\:tw--mb-8{
    margin-bottom: -2rem;
  }

  .lg\:tw--mb-10{
    margin-bottom: -2.5rem;
  }

  .lg\:tw--mb-12{
    margin-bottom: -3rem;
  }

  .lg\:tw--mb-16{
    margin-bottom: -4rem;
  }

  .lg\:tw--mb-20{
    margin-bottom: -5rem;
  }

  .lg\:tw--mb-24{
    margin-bottom: -6rem;
  }

  .lg\:tw--mb-32{
    margin-bottom: -8rem;
  }

  .lg\:tw--mb-40{
    margin-bottom: -10rem;
  }

  .lg\:tw--mb-48{
    margin-bottom: -12rem;
  }

  .lg\:tw--mb-56{
    margin-bottom: -14rem;
  }

  .lg\:tw--mb-64{
    margin-bottom: -16rem;
  }

  .lg\:tw--mb-px{
    margin-bottom: -1px;
  }

  .lg\:tw-ml-0{
    margin-left: 0;
  }

  .lg\:tw-ml-1{
    margin-left: 0.25rem;
  }

  .lg\:tw-ml-2{
    margin-left: 0.5rem;
  }

  .lg\:tw-ml-3{
    margin-left: 0.75rem;
  }

  .lg\:tw-ml-4{
    margin-left: 1rem;
  }

  .lg\:tw-ml-5{
    margin-left: 1.25rem;
  }

  .lg\:tw-ml-6{
    margin-left: 1.5rem;
  }

  .lg\:tw-ml-8{
    margin-left: 2rem;
  }

  .lg\:tw-ml-10{
    margin-left: 2.5rem;
  }

  .lg\:tw-ml-12{
    margin-left: 3rem;
  }

  .lg\:tw-ml-16{
    margin-left: 4rem;
  }

  .lg\:tw-ml-20{
    margin-left: 5rem;
  }

  .lg\:tw-ml-24{
    margin-left: 6rem;
  }

  .lg\:tw-ml-32{
    margin-left: 8rem;
  }

  .lg\:tw-ml-40{
    margin-left: 10rem;
  }

  .lg\:tw-ml-48{
    margin-left: 12rem;
  }

  .lg\:tw-ml-56{
    margin-left: 14rem;
  }

  .lg\:tw-ml-64{
    margin-left: 16rem;
  }

  .lg\:tw-ml-auto{
    margin-left: auto;
  }

  .lg\:tw-ml-px{
    margin-left: 1px;
  }

  .lg\:tw--ml-1{
    margin-left: -0.25rem;
  }

  .lg\:tw--ml-2{
    margin-left: -0.5rem;
  }

  .lg\:tw--ml-3{
    margin-left: -0.75rem;
  }

  .lg\:tw--ml-4{
    margin-left: -1rem;
  }

  .lg\:tw--ml-5{
    margin-left: -1.25rem;
  }

  .lg\:tw--ml-6{
    margin-left: -1.5rem;
  }

  .lg\:tw--ml-8{
    margin-left: -2rem;
  }

  .lg\:tw--ml-10{
    margin-left: -2.5rem;
  }

  .lg\:tw--ml-12{
    margin-left: -3rem;
  }

  .lg\:tw--ml-16{
    margin-left: -4rem;
  }

  .lg\:tw--ml-20{
    margin-left: -5rem;
  }

  .lg\:tw--ml-24{
    margin-left: -6rem;
  }

  .lg\:tw--ml-32{
    margin-left: -8rem;
  }

  .lg\:tw--ml-40{
    margin-left: -10rem;
  }

  .lg\:tw--ml-48{
    margin-left: -12rem;
  }

  .lg\:tw--ml-56{
    margin-left: -14rem;
  }

  .lg\:tw--ml-64{
    margin-left: -16rem;
  }

  .lg\:tw--ml-px{
    margin-left: -1px;
  }

  .lg\:tw-box-border{
    box-sizing: border-box;
  }

  .lg\:tw-box-content{
    box-sizing: content-box;
  }

  .lg\:tw-block{
    display: block;
  }

  .lg\:tw-inline-block{
    display: inline-block;
  }

  .lg\:tw-inline{
    display: inline;
  }

  .lg\:tw-flex{
    display: flex;
  }

  .lg\:tw-inline-flex{
    display: inline-flex;
  }

  .lg\:tw-table{
    display: table;
  }

  .lg\:tw-inline-table{
    display: inline-table;
  }

  .lg\:tw-table-caption{
    display: table-caption;
  }

  .lg\:tw-table-cell{
    display: table-cell;
  }

  .lg\:tw-table-column{
    display: table-column;
  }

  .lg\:tw-table-column-group{
    display: table-column-group;
  }

  .lg\:tw-table-footer-group{
    display: table-footer-group;
  }

  .lg\:tw-table-header-group{
    display: table-header-group;
  }

  .lg\:tw-table-row-group{
    display: table-row-group;
  }

  .lg\:tw-table-row{
    display: table-row;
  }

  .lg\:tw-flow-root{
    display: flow-root;
  }

  .lg\:tw-grid{
    display: grid;
  }

  .lg\:tw-inline-grid{
    display: inline-grid;
  }

  .lg\:tw-contents{
    display: contents;
  }

  .lg\:tw-list-item{
    display: list-item;
  }

  .lg\:tw-hidden{
    display: none;
  }

  .lg\:tw-h-0{
    height: 0;
  }

  .lg\:tw-h-1{
    height: 0.25rem;
  }

  .lg\:tw-h-2{
    height: 0.5rem;
  }

  .lg\:tw-h-3{
    height: 0.75rem;
  }

  .lg\:tw-h-4{
    height: 1rem;
  }

  .lg\:tw-h-5{
    height: 1.25rem;
  }

  .lg\:tw-h-6{
    height: 1.5rem;
  }

  .lg\:tw-h-8{
    height: 2rem;
  }

  .lg\:tw-h-10{
    height: 2.5rem;
  }

  .lg\:tw-h-12{
    height: 3rem;
  }

  .lg\:tw-h-16{
    height: 4rem;
  }

  .lg\:tw-h-20{
    height: 5rem;
  }

  .lg\:tw-h-24{
    height: 6rem;
  }

  .lg\:tw-h-32{
    height: 8rem;
  }

  .lg\:tw-h-40{
    height: 10rem;
  }

  .lg\:tw-h-48{
    height: 12rem;
  }

  .lg\:tw-h-56{
    height: 14rem;
  }

  .lg\:tw-h-64{
    height: 16rem;
  }

  .lg\:tw-h-auto{
    height: auto;
  }

  .lg\:tw-h-px{
    height: 1px;
  }

  .lg\:tw-h-1\/2{
    height: 50%;
  }

  .lg\:tw-h-1\/3{
    height: 33.333333%;
  }

  .lg\:tw-h-2\/3{
    height: 66.666667%;
  }

  .lg\:tw-h-1\/4{
    height: 25%;
  }

  .lg\:tw-h-2\/4{
    height: 50%;
  }

  .lg\:tw-h-3\/4{
    height: 75%;
  }

  .lg\:tw-h-1\/5{
    height: 20%;
  }

  .lg\:tw-h-2\/5{
    height: 40%;
  }

  .lg\:tw-h-3\/5{
    height: 60%;
  }

  .lg\:tw-h-4\/5{
    height: 80%;
  }

  .lg\:tw-h-1\/6{
    height: 16.666667%;
  }

  .lg\:tw-h-2\/6{
    height: 33.333333%;
  }

  .lg\:tw-h-3\/6{
    height: 50%;
  }

  .lg\:tw-h-4\/6{
    height: 66.666667%;
  }

  .lg\:tw-h-5\/6{
    height: 83.333333%;
  }

  .lg\:tw-h-full{
    height: 100%;
  }

  .lg\:tw-h-screen{
    height: 100vh;
  }

  .lg\:tw-max-h-full{
    max-height: 100%;
  }

  .lg\:tw-max-h-screen{
    max-height: 100vh;
  }

  .lg\:tw-min-h-0{
    min-height: 0;
  }

  .lg\:tw-min-h-full{
    min-height: 100%;
  }

  .lg\:tw-min-h-screen{
    min-height: 100vh;
  }

  .lg\:tw-w-0{
    width: 0;
  }

  .lg\:tw-w-1{
    width: 0.25rem;
  }

  .lg\:tw-w-2{
    width: 0.5rem;
  }

  .lg\:tw-w-3{
    width: 0.75rem;
  }

  .lg\:tw-w-4{
    width: 1rem;
  }

  .lg\:tw-w-5{
    width: 1.25rem;
  }

  .lg\:tw-w-6{
    width: 1.5rem;
  }

  .lg\:tw-w-8{
    width: 2rem;
  }

  .lg\:tw-w-10{
    width: 2.5rem;
  }

  .lg\:tw-w-12{
    width: 3rem;
  }

  .lg\:tw-w-16{
    width: 4rem;
  }

  .lg\:tw-w-20{
    width: 5rem;
  }

  .lg\:tw-w-24{
    width: 6rem;
  }

  .lg\:tw-w-32{
    width: 8rem;
  }

  .lg\:tw-w-40{
    width: 10rem;
  }

  .lg\:tw-w-48{
    width: 12rem;
  }

  .lg\:tw-w-56{
    width: 14rem;
  }

  .lg\:tw-w-64{
    width: 16rem;
  }

  .lg\:tw-w-auto{
    width: auto;
  }

  .lg\:tw-w-px{
    width: 1px;
  }

  .lg\:tw-w-1\/2{
    width: 50%;
  }

  .lg\:tw-w-1\/3{
    width: 33.333333%;
  }

  .lg\:tw-w-2\/3{
    width: 66.666667%;
  }

  .lg\:tw-w-1\/4{
    width: 25%;
  }

  .lg\:tw-w-2\/4{
    width: 50%;
  }

  .lg\:tw-w-3\/4{
    width: 75%;
  }

  .lg\:tw-w-1\/5{
    width: 20%;
  }

  .lg\:tw-w-2\/5{
    width: 40%;
  }

  .lg\:tw-w-3\/5{
    width: 60%;
  }

  .lg\:tw-w-4\/5{
    width: 80%;
  }

  .lg\:tw-w-1\/6{
    width: 16.666667%;
  }

  .lg\:tw-w-2\/6{
    width: 33.333333%;
  }

  .lg\:tw-w-3\/6{
    width: 50%;
  }

  .lg\:tw-w-4\/6{
    width: 66.666667%;
  }

  .lg\:tw-w-5\/6{
    width: 83.333333%;
  }

  .lg\:tw-w-1\/12{
    width: 8.333333%;
  }

  .lg\:tw-w-2\/12{
    width: 16.666667%;
  }

  .lg\:tw-w-3\/12{
    width: 25%;
  }

  .lg\:tw-w-4\/12{
    width: 33.333333%;
  }

  .lg\:tw-w-5\/12{
    width: 41.666667%;
  }

  .lg\:tw-w-6\/12{
    width: 50%;
  }

  .lg\:tw-w-7\/12{
    width: 58.333333%;
  }

  .lg\:tw-w-8\/12{
    width: 66.666667%;
  }

  .lg\:tw-w-9\/12{
    width: 75%;
  }

  .lg\:tw-w-10\/12{
    width: 83.333333%;
  }

  .lg\:tw-w-11\/12{
    width: 91.666667%;
  }

  .lg\:tw-w-full{
    width: 100%;
  }

  .lg\:tw-w-screen{
    width: 100vw;
  }

  .lg\:tw-w-min{
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
  }

  .lg\:tw-w-max{
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }

  .lg\:tw-min-w-0{
    min-width: 0;
  }

  .lg\:tw-min-w-full{
    min-width: 100%;
  }

  .lg\:tw-max-w-0{
    max-width: 0rem;
  }

  .lg\:tw-max-w-none{
    max-width: none;
  }

  .lg\:tw-max-w-xs{
    max-width: 20rem;
  }

  .lg\:tw-max-w-sm{
    max-width: 24rem;
  }

  .lg\:tw-max-w-md{
    max-width: 28rem;
  }

  .lg\:tw-max-w-lg{
    max-width: 32rem;
  }

  .lg\:tw-max-w-xl{
    max-width: 36rem;
  }

  .lg\:tw-max-w-2xl{
    max-width: 42rem;
  }

  .lg\:tw-max-w-3xl{
    max-width: 48rem;
  }

  .lg\:tw-max-w-4xl{
    max-width: 56rem;
  }

  .lg\:tw-max-w-5xl{
    max-width: 64rem;
  }

  .lg\:tw-max-w-6xl{
    max-width: 72rem;
  }

  .lg\:tw-max-w-7xl{
    max-width: 80rem;
  }

  .lg\:tw-max-w-full{
    max-width: 100%;
  }

  .lg\:tw-max-w-min{
    max-width: -webkit-min-content;
    max-width: -moz-min-content;
    max-width: min-content;
  }

  .lg\:tw-max-w-max{
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
  }

  .lg\:tw-max-w-prose{
    max-width: 65ch;
  }

  .lg\:tw-max-w-screen-sm{
    max-width: 640px;
  }

  .lg\:tw-max-w-screen-md{
    max-width: 768px;
  }

  .lg\:tw-max-w-screen-lg{
    max-width: 1024px;
  }

  .lg\:tw-max-w-screen-xl{
    max-width: 1280px;
  }

  .lg\:tw-flex-1{
    flex: 1 1 0%;
  }

  .lg\:tw-flex-auto{
    flex: 1 1 auto;
  }

  .lg\:tw-flex-initial{
    flex: 0 1 auto;
  }

  .lg\:tw-flex-none{
    flex: none;
  }

  .lg\:tw-flex-shrink-0{
    flex-shrink: 0;
  }

  .lg\:tw-flex-shrink-default{
    flex-shrink: 1;
  }

  .lg\:tw-flex-grow-0{
    flex-grow: 0;
  }

  .lg\:tw-flex-grow-default{
    flex-grow: 1;
  }

  .lg\:tw-table-auto{
    table-layout: auto;
  }

  .lg\:tw-table-fixed{
    table-layout: fixed;
  }

  .lg\:tw-border-collapse{
    border-collapse: collapse;
  }

  .lg\:tw-border-separate{
    border-collapse: separate;
  }

  .lg\:tw-origin-center{
    transform-origin: center;
  }

  .lg\:tw-origin-top{
    transform-origin: top;
  }

  .lg\:tw-origin-top-right{
    transform-origin: top right;
  }

  .lg\:tw-origin-right{
    transform-origin: right;
  }

  .lg\:tw-origin-bottom-right{
    transform-origin: bottom right;
  }

  .lg\:tw-origin-bottom{
    transform-origin: bottom;
  }

  .lg\:tw-origin-bottom-left{
    transform-origin: bottom left;
  }

  .lg\:tw-origin-left{
    transform-origin: left;
  }

  .lg\:tw-origin-top-left{
    transform-origin: top left;
  }

  .lg\:tw-transform{
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:tw-transform-gpu{
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:tw-transform-none{
    transform: none;
  }

  .lg\:tw-translate-x-0{
    --tw-translate-x: 0;
  }

  .lg\:tw-translate-x-1{
    --tw-translate-x: 0.25rem;
  }

  .lg\:tw-translate-x-2{
    --tw-translate-x: 0.5rem;
  }

  .lg\:tw-translate-x-3{
    --tw-translate-x: 0.75rem;
  }

  .lg\:tw-translate-x-4{
    --tw-translate-x: 1rem;
  }

  .lg\:tw-translate-x-5{
    --tw-translate-x: 1.25rem;
  }

  .lg\:tw-translate-x-6{
    --tw-translate-x: 1.5rem;
  }

  .lg\:tw-translate-x-8{
    --tw-translate-x: 2rem;
  }

  .lg\:tw-translate-x-10{
    --tw-translate-x: 2.5rem;
  }

  .lg\:tw-translate-x-12{
    --tw-translate-x: 3rem;
  }

  .lg\:tw-translate-x-16{
    --tw-translate-x: 4rem;
  }

  .lg\:tw-translate-x-20{
    --tw-translate-x: 5rem;
  }

  .lg\:tw-translate-x-24{
    --tw-translate-x: 6rem;
  }

  .lg\:tw-translate-x-32{
    --tw-translate-x: 8rem;
  }

  .lg\:tw-translate-x-40{
    --tw-translate-x: 10rem;
  }

  .lg\:tw-translate-x-48{
    --tw-translate-x: 12rem;
  }

  .lg\:tw-translate-x-56{
    --tw-translate-x: 14rem;
  }

  .lg\:tw-translate-x-64{
    --tw-translate-x: 16rem;
  }

  .lg\:tw-translate-x-px{
    --tw-translate-x: 1px;
  }

  .lg\:tw--translate-x-1{
    --tw-translate-x: -0.25rem;
  }

  .lg\:tw--translate-x-2{
    --tw-translate-x: -0.5rem;
  }

  .lg\:tw--translate-x-3{
    --tw-translate-x: -0.75rem;
  }

  .lg\:tw--translate-x-4{
    --tw-translate-x: -1rem;
  }

  .lg\:tw--translate-x-5{
    --tw-translate-x: -1.25rem;
  }

  .lg\:tw--translate-x-6{
    --tw-translate-x: -1.5rem;
  }

  .lg\:tw--translate-x-8{
    --tw-translate-x: -2rem;
  }

  .lg\:tw--translate-x-10{
    --tw-translate-x: -2.5rem;
  }

  .lg\:tw--translate-x-12{
    --tw-translate-x: -3rem;
  }

  .lg\:tw--translate-x-16{
    --tw-translate-x: -4rem;
  }

  .lg\:tw--translate-x-20{
    --tw-translate-x: -5rem;
  }

  .lg\:tw--translate-x-24{
    --tw-translate-x: -6rem;
  }

  .lg\:tw--translate-x-32{
    --tw-translate-x: -8rem;
  }

  .lg\:tw--translate-x-40{
    --tw-translate-x: -10rem;
  }

  .lg\:tw--translate-x-48{
    --tw-translate-x: -12rem;
  }

  .lg\:tw--translate-x-56{
    --tw-translate-x: -14rem;
  }

  .lg\:tw--translate-x-64{
    --tw-translate-x: -16rem;
  }

  .lg\:tw--translate-x-px{
    --tw-translate-x: -1px;
  }

  .lg\:tw-translate-x-1\/2{
    --tw-translate-x: 50%;
  }

  .lg\:tw-translate-x-1\/3{
    --tw-translate-x: 33.333333%;
  }

  .lg\:tw-translate-x-2\/3{
    --tw-translate-x: 66.666667%;
  }

  .lg\:tw-translate-x-1\/4{
    --tw-translate-x: 25%;
  }

  .lg\:tw-translate-x-2\/4{
    --tw-translate-x: 50%;
  }

  .lg\:tw-translate-x-3\/4{
    --tw-translate-x: 75%;
  }

  .lg\:tw-translate-x-full{
    --tw-translate-x: 100%;
  }

  .lg\:tw--translate-x-1\/2{
    --tw-translate-x: -50%;
  }

  .lg\:tw--translate-x-1\/3{
    --tw-translate-x: -33.333333%;
  }

  .lg\:tw--translate-x-2\/3{
    --tw-translate-x: -66.666667%;
  }

  .lg\:tw--translate-x-1\/4{
    --tw-translate-x: -25%;
  }

  .lg\:tw--translate-x-2\/4{
    --tw-translate-x: -50%;
  }

  .lg\:tw--translate-x-3\/4{
    --tw-translate-x: -75%;
  }

  .lg\:tw--translate-x-full{
    --tw-translate-x: -100%;
  }

  .lg\:tw-translate-y-0{
    --tw-translate-y: 0;
  }

  .lg\:tw-translate-y-1{
    --tw-translate-y: 0.25rem;
  }

  .lg\:tw-translate-y-2{
    --tw-translate-y: 0.5rem;
  }

  .lg\:tw-translate-y-3{
    --tw-translate-y: 0.75rem;
  }

  .lg\:tw-translate-y-4{
    --tw-translate-y: 1rem;
  }

  .lg\:tw-translate-y-5{
    --tw-translate-y: 1.25rem;
  }

  .lg\:tw-translate-y-6{
    --tw-translate-y: 1.5rem;
  }

  .lg\:tw-translate-y-8{
    --tw-translate-y: 2rem;
  }

  .lg\:tw-translate-y-10{
    --tw-translate-y: 2.5rem;
  }

  .lg\:tw-translate-y-12{
    --tw-translate-y: 3rem;
  }

  .lg\:tw-translate-y-16{
    --tw-translate-y: 4rem;
  }

  .lg\:tw-translate-y-20{
    --tw-translate-y: 5rem;
  }

  .lg\:tw-translate-y-24{
    --tw-translate-y: 6rem;
  }

  .lg\:tw-translate-y-32{
    --tw-translate-y: 8rem;
  }

  .lg\:tw-translate-y-40{
    --tw-translate-y: 10rem;
  }

  .lg\:tw-translate-y-48{
    --tw-translate-y: 12rem;
  }

  .lg\:tw-translate-y-56{
    --tw-translate-y: 14rem;
  }

  .lg\:tw-translate-y-64{
    --tw-translate-y: 16rem;
  }

  .lg\:tw-translate-y-px{
    --tw-translate-y: 1px;
  }

  .lg\:tw--translate-y-1{
    --tw-translate-y: -0.25rem;
  }

  .lg\:tw--translate-y-2{
    --tw-translate-y: -0.5rem;
  }

  .lg\:tw--translate-y-3{
    --tw-translate-y: -0.75rem;
  }

  .lg\:tw--translate-y-4{
    --tw-translate-y: -1rem;
  }

  .lg\:tw--translate-y-5{
    --tw-translate-y: -1.25rem;
  }

  .lg\:tw--translate-y-6{
    --tw-translate-y: -1.5rem;
  }

  .lg\:tw--translate-y-8{
    --tw-translate-y: -2rem;
  }

  .lg\:tw--translate-y-10{
    --tw-translate-y: -2.5rem;
  }

  .lg\:tw--translate-y-12{
    --tw-translate-y: -3rem;
  }

  .lg\:tw--translate-y-16{
    --tw-translate-y: -4rem;
  }

  .lg\:tw--translate-y-20{
    --tw-translate-y: -5rem;
  }

  .lg\:tw--translate-y-24{
    --tw-translate-y: -6rem;
  }

  .lg\:tw--translate-y-32{
    --tw-translate-y: -8rem;
  }

  .lg\:tw--translate-y-40{
    --tw-translate-y: -10rem;
  }

  .lg\:tw--translate-y-48{
    --tw-translate-y: -12rem;
  }

  .lg\:tw--translate-y-56{
    --tw-translate-y: -14rem;
  }

  .lg\:tw--translate-y-64{
    --tw-translate-y: -16rem;
  }

  .lg\:tw--translate-y-px{
    --tw-translate-y: -1px;
  }

  .lg\:tw-translate-y-1\/2{
    --tw-translate-y: 50%;
  }

  .lg\:tw-translate-y-1\/3{
    --tw-translate-y: 33.333333%;
  }

  .lg\:tw-translate-y-2\/3{
    --tw-translate-y: 66.666667%;
  }

  .lg\:tw-translate-y-1\/4{
    --tw-translate-y: 25%;
  }

  .lg\:tw-translate-y-2\/4{
    --tw-translate-y: 50%;
  }

  .lg\:tw-translate-y-3\/4{
    --tw-translate-y: 75%;
  }

  .lg\:tw-translate-y-full{
    --tw-translate-y: 100%;
  }

  .lg\:tw--translate-y-1\/2{
    --tw-translate-y: -50%;
  }

  .lg\:tw--translate-y-1\/3{
    --tw-translate-y: -33.333333%;
  }

  .lg\:tw--translate-y-2\/3{
    --tw-translate-y: -66.666667%;
  }

  .lg\:tw--translate-y-1\/4{
    --tw-translate-y: -25%;
  }

  .lg\:tw--translate-y-2\/4{
    --tw-translate-y: -50%;
  }

  .lg\:tw--translate-y-3\/4{
    --tw-translate-y: -75%;
  }

  .lg\:tw--translate-y-full{
    --tw-translate-y: -100%;
  }

  .lg\:hover\:tw-translate-x-0:hover{
    --tw-translate-x: 0;
  }

  .lg\:hover\:tw-translate-x-1:hover{
    --tw-translate-x: 0.25rem;
  }

  .lg\:hover\:tw-translate-x-2:hover{
    --tw-translate-x: 0.5rem;
  }

  .lg\:hover\:tw-translate-x-3:hover{
    --tw-translate-x: 0.75rem;
  }

  .lg\:hover\:tw-translate-x-4:hover{
    --tw-translate-x: 1rem;
  }

  .lg\:hover\:tw-translate-x-5:hover{
    --tw-translate-x: 1.25rem;
  }

  .lg\:hover\:tw-translate-x-6:hover{
    --tw-translate-x: 1.5rem;
  }

  .lg\:hover\:tw-translate-x-8:hover{
    --tw-translate-x: 2rem;
  }

  .lg\:hover\:tw-translate-x-10:hover{
    --tw-translate-x: 2.5rem;
  }

  .lg\:hover\:tw-translate-x-12:hover{
    --tw-translate-x: 3rem;
  }

  .lg\:hover\:tw-translate-x-16:hover{
    --tw-translate-x: 4rem;
  }

  .lg\:hover\:tw-translate-x-20:hover{
    --tw-translate-x: 5rem;
  }

  .lg\:hover\:tw-translate-x-24:hover{
    --tw-translate-x: 6rem;
  }

  .lg\:hover\:tw-translate-x-32:hover{
    --tw-translate-x: 8rem;
  }

  .lg\:hover\:tw-translate-x-40:hover{
    --tw-translate-x: 10rem;
  }

  .lg\:hover\:tw-translate-x-48:hover{
    --tw-translate-x: 12rem;
  }

  .lg\:hover\:tw-translate-x-56:hover{
    --tw-translate-x: 14rem;
  }

  .lg\:hover\:tw-translate-x-64:hover{
    --tw-translate-x: 16rem;
  }

  .lg\:hover\:tw-translate-x-px:hover{
    --tw-translate-x: 1px;
  }

  .lg\:hover\:tw--translate-x-1:hover{
    --tw-translate-x: -0.25rem;
  }

  .lg\:hover\:tw--translate-x-2:hover{
    --tw-translate-x: -0.5rem;
  }

  .lg\:hover\:tw--translate-x-3:hover{
    --tw-translate-x: -0.75rem;
  }

  .lg\:hover\:tw--translate-x-4:hover{
    --tw-translate-x: -1rem;
  }

  .lg\:hover\:tw--translate-x-5:hover{
    --tw-translate-x: -1.25rem;
  }

  .lg\:hover\:tw--translate-x-6:hover{
    --tw-translate-x: -1.5rem;
  }

  .lg\:hover\:tw--translate-x-8:hover{
    --tw-translate-x: -2rem;
  }

  .lg\:hover\:tw--translate-x-10:hover{
    --tw-translate-x: -2.5rem;
  }

  .lg\:hover\:tw--translate-x-12:hover{
    --tw-translate-x: -3rem;
  }

  .lg\:hover\:tw--translate-x-16:hover{
    --tw-translate-x: -4rem;
  }

  .lg\:hover\:tw--translate-x-20:hover{
    --tw-translate-x: -5rem;
  }

  .lg\:hover\:tw--translate-x-24:hover{
    --tw-translate-x: -6rem;
  }

  .lg\:hover\:tw--translate-x-32:hover{
    --tw-translate-x: -8rem;
  }

  .lg\:hover\:tw--translate-x-40:hover{
    --tw-translate-x: -10rem;
  }

  .lg\:hover\:tw--translate-x-48:hover{
    --tw-translate-x: -12rem;
  }

  .lg\:hover\:tw--translate-x-56:hover{
    --tw-translate-x: -14rem;
  }

  .lg\:hover\:tw--translate-x-64:hover{
    --tw-translate-x: -16rem;
  }

  .lg\:hover\:tw--translate-x-px:hover{
    --tw-translate-x: -1px;
  }

  .lg\:hover\:tw-translate-x-1\/2:hover{
    --tw-translate-x: 50%;
  }

  .lg\:hover\:tw-translate-x-1\/3:hover{
    --tw-translate-x: 33.333333%;
  }

  .lg\:hover\:tw-translate-x-2\/3:hover{
    --tw-translate-x: 66.666667%;
  }

  .lg\:hover\:tw-translate-x-1\/4:hover{
    --tw-translate-x: 25%;
  }

  .lg\:hover\:tw-translate-x-2\/4:hover{
    --tw-translate-x: 50%;
  }

  .lg\:hover\:tw-translate-x-3\/4:hover{
    --tw-translate-x: 75%;
  }

  .lg\:hover\:tw-translate-x-full:hover{
    --tw-translate-x: 100%;
  }

  .lg\:hover\:tw--translate-x-1\/2:hover{
    --tw-translate-x: -50%;
  }

  .lg\:hover\:tw--translate-x-1\/3:hover{
    --tw-translate-x: -33.333333%;
  }

  .lg\:hover\:tw--translate-x-2\/3:hover{
    --tw-translate-x: -66.666667%;
  }

  .lg\:hover\:tw--translate-x-1\/4:hover{
    --tw-translate-x: -25%;
  }

  .lg\:hover\:tw--translate-x-2\/4:hover{
    --tw-translate-x: -50%;
  }

  .lg\:hover\:tw--translate-x-3\/4:hover{
    --tw-translate-x: -75%;
  }

  .lg\:hover\:tw--translate-x-full:hover{
    --tw-translate-x: -100%;
  }

  .lg\:hover\:tw-translate-y-0:hover{
    --tw-translate-y: 0;
  }

  .lg\:hover\:tw-translate-y-1:hover{
    --tw-translate-y: 0.25rem;
  }

  .lg\:hover\:tw-translate-y-2:hover{
    --tw-translate-y: 0.5rem;
  }

  .lg\:hover\:tw-translate-y-3:hover{
    --tw-translate-y: 0.75rem;
  }

  .lg\:hover\:tw-translate-y-4:hover{
    --tw-translate-y: 1rem;
  }

  .lg\:hover\:tw-translate-y-5:hover{
    --tw-translate-y: 1.25rem;
  }

  .lg\:hover\:tw-translate-y-6:hover{
    --tw-translate-y: 1.5rem;
  }

  .lg\:hover\:tw-translate-y-8:hover{
    --tw-translate-y: 2rem;
  }

  .lg\:hover\:tw-translate-y-10:hover{
    --tw-translate-y: 2.5rem;
  }

  .lg\:hover\:tw-translate-y-12:hover{
    --tw-translate-y: 3rem;
  }

  .lg\:hover\:tw-translate-y-16:hover{
    --tw-translate-y: 4rem;
  }

  .lg\:hover\:tw-translate-y-20:hover{
    --tw-translate-y: 5rem;
  }

  .lg\:hover\:tw-translate-y-24:hover{
    --tw-translate-y: 6rem;
  }

  .lg\:hover\:tw-translate-y-32:hover{
    --tw-translate-y: 8rem;
  }

  .lg\:hover\:tw-translate-y-40:hover{
    --tw-translate-y: 10rem;
  }

  .lg\:hover\:tw-translate-y-48:hover{
    --tw-translate-y: 12rem;
  }

  .lg\:hover\:tw-translate-y-56:hover{
    --tw-translate-y: 14rem;
  }

  .lg\:hover\:tw-translate-y-64:hover{
    --tw-translate-y: 16rem;
  }

  .lg\:hover\:tw-translate-y-px:hover{
    --tw-translate-y: 1px;
  }

  .lg\:hover\:tw--translate-y-1:hover{
    --tw-translate-y: -0.25rem;
  }

  .lg\:hover\:tw--translate-y-2:hover{
    --tw-translate-y: -0.5rem;
  }

  .lg\:hover\:tw--translate-y-3:hover{
    --tw-translate-y: -0.75rem;
  }

  .lg\:hover\:tw--translate-y-4:hover{
    --tw-translate-y: -1rem;
  }

  .lg\:hover\:tw--translate-y-5:hover{
    --tw-translate-y: -1.25rem;
  }

  .lg\:hover\:tw--translate-y-6:hover{
    --tw-translate-y: -1.5rem;
  }

  .lg\:hover\:tw--translate-y-8:hover{
    --tw-translate-y: -2rem;
  }

  .lg\:hover\:tw--translate-y-10:hover{
    --tw-translate-y: -2.5rem;
  }

  .lg\:hover\:tw--translate-y-12:hover{
    --tw-translate-y: -3rem;
  }

  .lg\:hover\:tw--translate-y-16:hover{
    --tw-translate-y: -4rem;
  }

  .lg\:hover\:tw--translate-y-20:hover{
    --tw-translate-y: -5rem;
  }

  .lg\:hover\:tw--translate-y-24:hover{
    --tw-translate-y: -6rem;
  }

  .lg\:hover\:tw--translate-y-32:hover{
    --tw-translate-y: -8rem;
  }

  .lg\:hover\:tw--translate-y-40:hover{
    --tw-translate-y: -10rem;
  }

  .lg\:hover\:tw--translate-y-48:hover{
    --tw-translate-y: -12rem;
  }

  .lg\:hover\:tw--translate-y-56:hover{
    --tw-translate-y: -14rem;
  }

  .lg\:hover\:tw--translate-y-64:hover{
    --tw-translate-y: -16rem;
  }

  .lg\:hover\:tw--translate-y-px:hover{
    --tw-translate-y: -1px;
  }

  .lg\:hover\:tw-translate-y-1\/2:hover{
    --tw-translate-y: 50%;
  }

  .lg\:hover\:tw-translate-y-1\/3:hover{
    --tw-translate-y: 33.333333%;
  }

  .lg\:hover\:tw-translate-y-2\/3:hover{
    --tw-translate-y: 66.666667%;
  }

  .lg\:hover\:tw-translate-y-1\/4:hover{
    --tw-translate-y: 25%;
  }

  .lg\:hover\:tw-translate-y-2\/4:hover{
    --tw-translate-y: 50%;
  }

  .lg\:hover\:tw-translate-y-3\/4:hover{
    --tw-translate-y: 75%;
  }

  .lg\:hover\:tw-translate-y-full:hover{
    --tw-translate-y: 100%;
  }

  .lg\:hover\:tw--translate-y-1\/2:hover{
    --tw-translate-y: -50%;
  }

  .lg\:hover\:tw--translate-y-1\/3:hover{
    --tw-translate-y: -33.333333%;
  }

  .lg\:hover\:tw--translate-y-2\/3:hover{
    --tw-translate-y: -66.666667%;
  }

  .lg\:hover\:tw--translate-y-1\/4:hover{
    --tw-translate-y: -25%;
  }

  .lg\:hover\:tw--translate-y-2\/4:hover{
    --tw-translate-y: -50%;
  }

  .lg\:hover\:tw--translate-y-3\/4:hover{
    --tw-translate-y: -75%;
  }

  .lg\:hover\:tw--translate-y-full:hover{
    --tw-translate-y: -100%;
  }

  .lg\:focus\:tw-translate-x-0:focus{
    --tw-translate-x: 0;
  }

  .lg\:focus\:tw-translate-x-1:focus{
    --tw-translate-x: 0.25rem;
  }

  .lg\:focus\:tw-translate-x-2:focus{
    --tw-translate-x: 0.5rem;
  }

  .lg\:focus\:tw-translate-x-3:focus{
    --tw-translate-x: 0.75rem;
  }

  .lg\:focus\:tw-translate-x-4:focus{
    --tw-translate-x: 1rem;
  }

  .lg\:focus\:tw-translate-x-5:focus{
    --tw-translate-x: 1.25rem;
  }

  .lg\:focus\:tw-translate-x-6:focus{
    --tw-translate-x: 1.5rem;
  }

  .lg\:focus\:tw-translate-x-8:focus{
    --tw-translate-x: 2rem;
  }

  .lg\:focus\:tw-translate-x-10:focus{
    --tw-translate-x: 2.5rem;
  }

  .lg\:focus\:tw-translate-x-12:focus{
    --tw-translate-x: 3rem;
  }

  .lg\:focus\:tw-translate-x-16:focus{
    --tw-translate-x: 4rem;
  }

  .lg\:focus\:tw-translate-x-20:focus{
    --tw-translate-x: 5rem;
  }

  .lg\:focus\:tw-translate-x-24:focus{
    --tw-translate-x: 6rem;
  }

  .lg\:focus\:tw-translate-x-32:focus{
    --tw-translate-x: 8rem;
  }

  .lg\:focus\:tw-translate-x-40:focus{
    --tw-translate-x: 10rem;
  }

  .lg\:focus\:tw-translate-x-48:focus{
    --tw-translate-x: 12rem;
  }

  .lg\:focus\:tw-translate-x-56:focus{
    --tw-translate-x: 14rem;
  }

  .lg\:focus\:tw-translate-x-64:focus{
    --tw-translate-x: 16rem;
  }

  .lg\:focus\:tw-translate-x-px:focus{
    --tw-translate-x: 1px;
  }

  .lg\:focus\:tw--translate-x-1:focus{
    --tw-translate-x: -0.25rem;
  }

  .lg\:focus\:tw--translate-x-2:focus{
    --tw-translate-x: -0.5rem;
  }

  .lg\:focus\:tw--translate-x-3:focus{
    --tw-translate-x: -0.75rem;
  }

  .lg\:focus\:tw--translate-x-4:focus{
    --tw-translate-x: -1rem;
  }

  .lg\:focus\:tw--translate-x-5:focus{
    --tw-translate-x: -1.25rem;
  }

  .lg\:focus\:tw--translate-x-6:focus{
    --tw-translate-x: -1.5rem;
  }

  .lg\:focus\:tw--translate-x-8:focus{
    --tw-translate-x: -2rem;
  }

  .lg\:focus\:tw--translate-x-10:focus{
    --tw-translate-x: -2.5rem;
  }

  .lg\:focus\:tw--translate-x-12:focus{
    --tw-translate-x: -3rem;
  }

  .lg\:focus\:tw--translate-x-16:focus{
    --tw-translate-x: -4rem;
  }

  .lg\:focus\:tw--translate-x-20:focus{
    --tw-translate-x: -5rem;
  }

  .lg\:focus\:tw--translate-x-24:focus{
    --tw-translate-x: -6rem;
  }

  .lg\:focus\:tw--translate-x-32:focus{
    --tw-translate-x: -8rem;
  }

  .lg\:focus\:tw--translate-x-40:focus{
    --tw-translate-x: -10rem;
  }

  .lg\:focus\:tw--translate-x-48:focus{
    --tw-translate-x: -12rem;
  }

  .lg\:focus\:tw--translate-x-56:focus{
    --tw-translate-x: -14rem;
  }

  .lg\:focus\:tw--translate-x-64:focus{
    --tw-translate-x: -16rem;
  }

  .lg\:focus\:tw--translate-x-px:focus{
    --tw-translate-x: -1px;
  }

  .lg\:focus\:tw-translate-x-1\/2:focus{
    --tw-translate-x: 50%;
  }

  .lg\:focus\:tw-translate-x-1\/3:focus{
    --tw-translate-x: 33.333333%;
  }

  .lg\:focus\:tw-translate-x-2\/3:focus{
    --tw-translate-x: 66.666667%;
  }

  .lg\:focus\:tw-translate-x-1\/4:focus{
    --tw-translate-x: 25%;
  }

  .lg\:focus\:tw-translate-x-2\/4:focus{
    --tw-translate-x: 50%;
  }

  .lg\:focus\:tw-translate-x-3\/4:focus{
    --tw-translate-x: 75%;
  }

  .lg\:focus\:tw-translate-x-full:focus{
    --tw-translate-x: 100%;
  }

  .lg\:focus\:tw--translate-x-1\/2:focus{
    --tw-translate-x: -50%;
  }

  .lg\:focus\:tw--translate-x-1\/3:focus{
    --tw-translate-x: -33.333333%;
  }

  .lg\:focus\:tw--translate-x-2\/3:focus{
    --tw-translate-x: -66.666667%;
  }

  .lg\:focus\:tw--translate-x-1\/4:focus{
    --tw-translate-x: -25%;
  }

  .lg\:focus\:tw--translate-x-2\/4:focus{
    --tw-translate-x: -50%;
  }

  .lg\:focus\:tw--translate-x-3\/4:focus{
    --tw-translate-x: -75%;
  }

  .lg\:focus\:tw--translate-x-full:focus{
    --tw-translate-x: -100%;
  }

  .lg\:focus\:tw-translate-y-0:focus{
    --tw-translate-y: 0;
  }

  .lg\:focus\:tw-translate-y-1:focus{
    --tw-translate-y: 0.25rem;
  }

  .lg\:focus\:tw-translate-y-2:focus{
    --tw-translate-y: 0.5rem;
  }

  .lg\:focus\:tw-translate-y-3:focus{
    --tw-translate-y: 0.75rem;
  }

  .lg\:focus\:tw-translate-y-4:focus{
    --tw-translate-y: 1rem;
  }

  .lg\:focus\:tw-translate-y-5:focus{
    --tw-translate-y: 1.25rem;
  }

  .lg\:focus\:tw-translate-y-6:focus{
    --tw-translate-y: 1.5rem;
  }

  .lg\:focus\:tw-translate-y-8:focus{
    --tw-translate-y: 2rem;
  }

  .lg\:focus\:tw-translate-y-10:focus{
    --tw-translate-y: 2.5rem;
  }

  .lg\:focus\:tw-translate-y-12:focus{
    --tw-translate-y: 3rem;
  }

  .lg\:focus\:tw-translate-y-16:focus{
    --tw-translate-y: 4rem;
  }

  .lg\:focus\:tw-translate-y-20:focus{
    --tw-translate-y: 5rem;
  }

  .lg\:focus\:tw-translate-y-24:focus{
    --tw-translate-y: 6rem;
  }

  .lg\:focus\:tw-translate-y-32:focus{
    --tw-translate-y: 8rem;
  }

  .lg\:focus\:tw-translate-y-40:focus{
    --tw-translate-y: 10rem;
  }

  .lg\:focus\:tw-translate-y-48:focus{
    --tw-translate-y: 12rem;
  }

  .lg\:focus\:tw-translate-y-56:focus{
    --tw-translate-y: 14rem;
  }

  .lg\:focus\:tw-translate-y-64:focus{
    --tw-translate-y: 16rem;
  }

  .lg\:focus\:tw-translate-y-px:focus{
    --tw-translate-y: 1px;
  }

  .lg\:focus\:tw--translate-y-1:focus{
    --tw-translate-y: -0.25rem;
  }

  .lg\:focus\:tw--translate-y-2:focus{
    --tw-translate-y: -0.5rem;
  }

  .lg\:focus\:tw--translate-y-3:focus{
    --tw-translate-y: -0.75rem;
  }

  .lg\:focus\:tw--translate-y-4:focus{
    --tw-translate-y: -1rem;
  }

  .lg\:focus\:tw--translate-y-5:focus{
    --tw-translate-y: -1.25rem;
  }

  .lg\:focus\:tw--translate-y-6:focus{
    --tw-translate-y: -1.5rem;
  }

  .lg\:focus\:tw--translate-y-8:focus{
    --tw-translate-y: -2rem;
  }

  .lg\:focus\:tw--translate-y-10:focus{
    --tw-translate-y: -2.5rem;
  }

  .lg\:focus\:tw--translate-y-12:focus{
    --tw-translate-y: -3rem;
  }

  .lg\:focus\:tw--translate-y-16:focus{
    --tw-translate-y: -4rem;
  }

  .lg\:focus\:tw--translate-y-20:focus{
    --tw-translate-y: -5rem;
  }

  .lg\:focus\:tw--translate-y-24:focus{
    --tw-translate-y: -6rem;
  }

  .lg\:focus\:tw--translate-y-32:focus{
    --tw-translate-y: -8rem;
  }

  .lg\:focus\:tw--translate-y-40:focus{
    --tw-translate-y: -10rem;
  }

  .lg\:focus\:tw--translate-y-48:focus{
    --tw-translate-y: -12rem;
  }

  .lg\:focus\:tw--translate-y-56:focus{
    --tw-translate-y: -14rem;
  }

  .lg\:focus\:tw--translate-y-64:focus{
    --tw-translate-y: -16rem;
  }

  .lg\:focus\:tw--translate-y-px:focus{
    --tw-translate-y: -1px;
  }

  .lg\:focus\:tw-translate-y-1\/2:focus{
    --tw-translate-y: 50%;
  }

  .lg\:focus\:tw-translate-y-1\/3:focus{
    --tw-translate-y: 33.333333%;
  }

  .lg\:focus\:tw-translate-y-2\/3:focus{
    --tw-translate-y: 66.666667%;
  }

  .lg\:focus\:tw-translate-y-1\/4:focus{
    --tw-translate-y: 25%;
  }

  .lg\:focus\:tw-translate-y-2\/4:focus{
    --tw-translate-y: 50%;
  }

  .lg\:focus\:tw-translate-y-3\/4:focus{
    --tw-translate-y: 75%;
  }

  .lg\:focus\:tw-translate-y-full:focus{
    --tw-translate-y: 100%;
  }

  .lg\:focus\:tw--translate-y-1\/2:focus{
    --tw-translate-y: -50%;
  }

  .lg\:focus\:tw--translate-y-1\/3:focus{
    --tw-translate-y: -33.333333%;
  }

  .lg\:focus\:tw--translate-y-2\/3:focus{
    --tw-translate-y: -66.666667%;
  }

  .lg\:focus\:tw--translate-y-1\/4:focus{
    --tw-translate-y: -25%;
  }

  .lg\:focus\:tw--translate-y-2\/4:focus{
    --tw-translate-y: -50%;
  }

  .lg\:focus\:tw--translate-y-3\/4:focus{
    --tw-translate-y: -75%;
  }

  .lg\:focus\:tw--translate-y-full:focus{
    --tw-translate-y: -100%;
  }

  .lg\:tw-rotate-0{
    --tw-rotate: 0;
  }

  .lg\:tw-rotate-1{
    --tw-rotate: 1deg;
  }

  .lg\:tw-rotate-2{
    --tw-rotate: 2deg;
  }

  .lg\:tw-rotate-3{
    --tw-rotate: 3deg;
  }

  .lg\:tw-rotate-6{
    --tw-rotate: 6deg;
  }

  .lg\:tw-rotate-12{
    --tw-rotate: 12deg;
  }

  .lg\:tw-rotate-45{
    --tw-rotate: 45deg;
  }

  .lg\:tw-rotate-90{
    --tw-rotate: 90deg;
  }

  .lg\:tw-rotate-180{
    --tw-rotate: 180deg;
  }

  .lg\:tw--rotate-180{
    --tw-rotate: -180deg;
  }

  .lg\:tw--rotate-90{
    --tw-rotate: -90deg;
  }

  .lg\:tw--rotate-45{
    --tw-rotate: -45deg;
  }

  .lg\:tw--rotate-12{
    --tw-rotate: -12deg;
  }

  .lg\:tw--rotate-6{
    --tw-rotate: -6deg;
  }

  .lg\:tw--rotate-3{
    --tw-rotate: -3deg;
  }

  .lg\:tw--rotate-2{
    --tw-rotate: -2deg;
  }

  .lg\:tw--rotate-1{
    --tw-rotate: -1deg;
  }

  .lg\:hover\:tw-rotate-0:hover{
    --tw-rotate: 0;
  }

  .lg\:hover\:tw-rotate-1:hover{
    --tw-rotate: 1deg;
  }

  .lg\:hover\:tw-rotate-2:hover{
    --tw-rotate: 2deg;
  }

  .lg\:hover\:tw-rotate-3:hover{
    --tw-rotate: 3deg;
  }

  .lg\:hover\:tw-rotate-6:hover{
    --tw-rotate: 6deg;
  }

  .lg\:hover\:tw-rotate-12:hover{
    --tw-rotate: 12deg;
  }

  .lg\:hover\:tw-rotate-45:hover{
    --tw-rotate: 45deg;
  }

  .lg\:hover\:tw-rotate-90:hover{
    --tw-rotate: 90deg;
  }

  .lg\:hover\:tw-rotate-180:hover{
    --tw-rotate: 180deg;
  }

  .lg\:hover\:tw--rotate-180:hover{
    --tw-rotate: -180deg;
  }

  .lg\:hover\:tw--rotate-90:hover{
    --tw-rotate: -90deg;
  }

  .lg\:hover\:tw--rotate-45:hover{
    --tw-rotate: -45deg;
  }

  .lg\:hover\:tw--rotate-12:hover{
    --tw-rotate: -12deg;
  }

  .lg\:hover\:tw--rotate-6:hover{
    --tw-rotate: -6deg;
  }

  .lg\:hover\:tw--rotate-3:hover{
    --tw-rotate: -3deg;
  }

  .lg\:hover\:tw--rotate-2:hover{
    --tw-rotate: -2deg;
  }

  .lg\:hover\:tw--rotate-1:hover{
    --tw-rotate: -1deg;
  }

  .lg\:focus\:tw-rotate-0:focus{
    --tw-rotate: 0;
  }

  .lg\:focus\:tw-rotate-1:focus{
    --tw-rotate: 1deg;
  }

  .lg\:focus\:tw-rotate-2:focus{
    --tw-rotate: 2deg;
  }

  .lg\:focus\:tw-rotate-3:focus{
    --tw-rotate: 3deg;
  }

  .lg\:focus\:tw-rotate-6:focus{
    --tw-rotate: 6deg;
  }

  .lg\:focus\:tw-rotate-12:focus{
    --tw-rotate: 12deg;
  }

  .lg\:focus\:tw-rotate-45:focus{
    --tw-rotate: 45deg;
  }

  .lg\:focus\:tw-rotate-90:focus{
    --tw-rotate: 90deg;
  }

  .lg\:focus\:tw-rotate-180:focus{
    --tw-rotate: 180deg;
  }

  .lg\:focus\:tw--rotate-180:focus{
    --tw-rotate: -180deg;
  }

  .lg\:focus\:tw--rotate-90:focus{
    --tw-rotate: -90deg;
  }

  .lg\:focus\:tw--rotate-45:focus{
    --tw-rotate: -45deg;
  }

  .lg\:focus\:tw--rotate-12:focus{
    --tw-rotate: -12deg;
  }

  .lg\:focus\:tw--rotate-6:focus{
    --tw-rotate: -6deg;
  }

  .lg\:focus\:tw--rotate-3:focus{
    --tw-rotate: -3deg;
  }

  .lg\:focus\:tw--rotate-2:focus{
    --tw-rotate: -2deg;
  }

  .lg\:focus\:tw--rotate-1:focus{
    --tw-rotate: -1deg;
  }

  .lg\:tw-skew-x-0{
    --tw-skew-x: 0;
  }

  .lg\:tw-skew-x-1{
    --tw-skew-x: 1deg;
  }

  .lg\:tw-skew-x-2{
    --tw-skew-x: 2deg;
  }

  .lg\:tw-skew-x-3{
    --tw-skew-x: 3deg;
  }

  .lg\:tw-skew-x-6{
    --tw-skew-x: 6deg;
  }

  .lg\:tw-skew-x-12{
    --tw-skew-x: 12deg;
  }

  .lg\:tw--skew-x-12{
    --tw-skew-x: -12deg;
  }

  .lg\:tw--skew-x-6{
    --tw-skew-x: -6deg;
  }

  .lg\:tw--skew-x-3{
    --tw-skew-x: -3deg;
  }

  .lg\:tw--skew-x-2{
    --tw-skew-x: -2deg;
  }

  .lg\:tw--skew-x-1{
    --tw-skew-x: -1deg;
  }

  .lg\:tw-skew-y-0{
    --tw-skew-y: 0;
  }

  .lg\:tw-skew-y-1{
    --tw-skew-y: 1deg;
  }

  .lg\:tw-skew-y-2{
    --tw-skew-y: 2deg;
  }

  .lg\:tw-skew-y-3{
    --tw-skew-y: 3deg;
  }

  .lg\:tw-skew-y-6{
    --tw-skew-y: 6deg;
  }

  .lg\:tw-skew-y-12{
    --tw-skew-y: 12deg;
  }

  .lg\:tw--skew-y-12{
    --tw-skew-y: -12deg;
  }

  .lg\:tw--skew-y-6{
    --tw-skew-y: -6deg;
  }

  .lg\:tw--skew-y-3{
    --tw-skew-y: -3deg;
  }

  .lg\:tw--skew-y-2{
    --tw-skew-y: -2deg;
  }

  .lg\:tw--skew-y-1{
    --tw-skew-y: -1deg;
  }

  .lg\:hover\:tw-skew-x-0:hover{
    --tw-skew-x: 0;
  }

  .lg\:hover\:tw-skew-x-1:hover{
    --tw-skew-x: 1deg;
  }

  .lg\:hover\:tw-skew-x-2:hover{
    --tw-skew-x: 2deg;
  }

  .lg\:hover\:tw-skew-x-3:hover{
    --tw-skew-x: 3deg;
  }

  .lg\:hover\:tw-skew-x-6:hover{
    --tw-skew-x: 6deg;
  }

  .lg\:hover\:tw-skew-x-12:hover{
    --tw-skew-x: 12deg;
  }

  .lg\:hover\:tw--skew-x-12:hover{
    --tw-skew-x: -12deg;
  }

  .lg\:hover\:tw--skew-x-6:hover{
    --tw-skew-x: -6deg;
  }

  .lg\:hover\:tw--skew-x-3:hover{
    --tw-skew-x: -3deg;
  }

  .lg\:hover\:tw--skew-x-2:hover{
    --tw-skew-x: -2deg;
  }

  .lg\:hover\:tw--skew-x-1:hover{
    --tw-skew-x: -1deg;
  }

  .lg\:hover\:tw-skew-y-0:hover{
    --tw-skew-y: 0;
  }

  .lg\:hover\:tw-skew-y-1:hover{
    --tw-skew-y: 1deg;
  }

  .lg\:hover\:tw-skew-y-2:hover{
    --tw-skew-y: 2deg;
  }

  .lg\:hover\:tw-skew-y-3:hover{
    --tw-skew-y: 3deg;
  }

  .lg\:hover\:tw-skew-y-6:hover{
    --tw-skew-y: 6deg;
  }

  .lg\:hover\:tw-skew-y-12:hover{
    --tw-skew-y: 12deg;
  }

  .lg\:hover\:tw--skew-y-12:hover{
    --tw-skew-y: -12deg;
  }

  .lg\:hover\:tw--skew-y-6:hover{
    --tw-skew-y: -6deg;
  }

  .lg\:hover\:tw--skew-y-3:hover{
    --tw-skew-y: -3deg;
  }

  .lg\:hover\:tw--skew-y-2:hover{
    --tw-skew-y: -2deg;
  }

  .lg\:hover\:tw--skew-y-1:hover{
    --tw-skew-y: -1deg;
  }

  .lg\:focus\:tw-skew-x-0:focus{
    --tw-skew-x: 0;
  }

  .lg\:focus\:tw-skew-x-1:focus{
    --tw-skew-x: 1deg;
  }

  .lg\:focus\:tw-skew-x-2:focus{
    --tw-skew-x: 2deg;
  }

  .lg\:focus\:tw-skew-x-3:focus{
    --tw-skew-x: 3deg;
  }

  .lg\:focus\:tw-skew-x-6:focus{
    --tw-skew-x: 6deg;
  }

  .lg\:focus\:tw-skew-x-12:focus{
    --tw-skew-x: 12deg;
  }

  .lg\:focus\:tw--skew-x-12:focus{
    --tw-skew-x: -12deg;
  }

  .lg\:focus\:tw--skew-x-6:focus{
    --tw-skew-x: -6deg;
  }

  .lg\:focus\:tw--skew-x-3:focus{
    --tw-skew-x: -3deg;
  }

  .lg\:focus\:tw--skew-x-2:focus{
    --tw-skew-x: -2deg;
  }

  .lg\:focus\:tw--skew-x-1:focus{
    --tw-skew-x: -1deg;
  }

  .lg\:focus\:tw-skew-y-0:focus{
    --tw-skew-y: 0;
  }

  .lg\:focus\:tw-skew-y-1:focus{
    --tw-skew-y: 1deg;
  }

  .lg\:focus\:tw-skew-y-2:focus{
    --tw-skew-y: 2deg;
  }

  .lg\:focus\:tw-skew-y-3:focus{
    --tw-skew-y: 3deg;
  }

  .lg\:focus\:tw-skew-y-6:focus{
    --tw-skew-y: 6deg;
  }

  .lg\:focus\:tw-skew-y-12:focus{
    --tw-skew-y: 12deg;
  }

  .lg\:focus\:tw--skew-y-12:focus{
    --tw-skew-y: -12deg;
  }

  .lg\:focus\:tw--skew-y-6:focus{
    --tw-skew-y: -6deg;
  }

  .lg\:focus\:tw--skew-y-3:focus{
    --tw-skew-y: -3deg;
  }

  .lg\:focus\:tw--skew-y-2:focus{
    --tw-skew-y: -2deg;
  }

  .lg\:focus\:tw--skew-y-1:focus{
    --tw-skew-y: -1deg;
  }

  .lg\:tw-scale-0{
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .lg\:tw-scale-50{
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .lg\:tw-scale-75{
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .lg\:tw-scale-90{
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .lg\:tw-scale-95{
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .lg\:tw-scale-100{
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .lg\:tw-scale-105{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .lg\:tw-scale-110{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .lg\:tw-scale-125{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .lg\:tw-scale-150{
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .lg\:hover\:tw-scale-0:hover{
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .lg\:hover\:tw-scale-50:hover{
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .lg\:hover\:tw-scale-75:hover{
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .lg\:hover\:tw-scale-90:hover{
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .lg\:hover\:tw-scale-95:hover{
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .lg\:hover\:tw-scale-100:hover{
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .lg\:hover\:tw-scale-105:hover{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .lg\:hover\:tw-scale-110:hover{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .lg\:hover\:tw-scale-125:hover{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .lg\:hover\:tw-scale-150:hover{
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .lg\:focus\:tw-scale-0:focus{
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .lg\:focus\:tw-scale-50:focus{
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .lg\:focus\:tw-scale-75:focus{
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .lg\:focus\:tw-scale-90:focus{
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .lg\:focus\:tw-scale-95:focus{
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .lg\:focus\:tw-scale-100:focus{
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .lg\:focus\:tw-scale-105:focus{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .lg\:focus\:tw-scale-110:focus{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .lg\:focus\:tw-scale-125:focus{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .lg\:focus\:tw-scale-150:focus{
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .lg\:tw-scale-x-0{
    --tw-scale-x: 0;
  }

  .lg\:tw-scale-x-50{
    --tw-scale-x: .5;
  }

  .lg\:tw-scale-x-75{
    --tw-scale-x: .75;
  }

  .lg\:tw-scale-x-90{
    --tw-scale-x: .9;
  }

  .lg\:tw-scale-x-95{
    --tw-scale-x: .95;
  }

  .lg\:tw-scale-x-100{
    --tw-scale-x: 1;
  }

  .lg\:tw-scale-x-105{
    --tw-scale-x: 1.05;
  }

  .lg\:tw-scale-x-110{
    --tw-scale-x: 1.1;
  }

  .lg\:tw-scale-x-125{
    --tw-scale-x: 1.25;
  }

  .lg\:tw-scale-x-150{
    --tw-scale-x: 1.5;
  }

  .lg\:tw-scale-y-0{
    --tw-scale-y: 0;
  }

  .lg\:tw-scale-y-50{
    --tw-scale-y: .5;
  }

  .lg\:tw-scale-y-75{
    --tw-scale-y: .75;
  }

  .lg\:tw-scale-y-90{
    --tw-scale-y: .9;
  }

  .lg\:tw-scale-y-95{
    --tw-scale-y: .95;
  }

  .lg\:tw-scale-y-100{
    --tw-scale-y: 1;
  }

  .lg\:tw-scale-y-105{
    --tw-scale-y: 1.05;
  }

  .lg\:tw-scale-y-110{
    --tw-scale-y: 1.1;
  }

  .lg\:tw-scale-y-125{
    --tw-scale-y: 1.25;
  }

  .lg\:tw-scale-y-150{
    --tw-scale-y: 1.5;
  }

  .lg\:hover\:tw-scale-x-0:hover{
    --tw-scale-x: 0;
  }

  .lg\:hover\:tw-scale-x-50:hover{
    --tw-scale-x: .5;
  }

  .lg\:hover\:tw-scale-x-75:hover{
    --tw-scale-x: .75;
  }

  .lg\:hover\:tw-scale-x-90:hover{
    --tw-scale-x: .9;
  }

  .lg\:hover\:tw-scale-x-95:hover{
    --tw-scale-x: .95;
  }

  .lg\:hover\:tw-scale-x-100:hover{
    --tw-scale-x: 1;
  }

  .lg\:hover\:tw-scale-x-105:hover{
    --tw-scale-x: 1.05;
  }

  .lg\:hover\:tw-scale-x-110:hover{
    --tw-scale-x: 1.1;
  }

  .lg\:hover\:tw-scale-x-125:hover{
    --tw-scale-x: 1.25;
  }

  .lg\:hover\:tw-scale-x-150:hover{
    --tw-scale-x: 1.5;
  }

  .lg\:hover\:tw-scale-y-0:hover{
    --tw-scale-y: 0;
  }

  .lg\:hover\:tw-scale-y-50:hover{
    --tw-scale-y: .5;
  }

  .lg\:hover\:tw-scale-y-75:hover{
    --tw-scale-y: .75;
  }

  .lg\:hover\:tw-scale-y-90:hover{
    --tw-scale-y: .9;
  }

  .lg\:hover\:tw-scale-y-95:hover{
    --tw-scale-y: .95;
  }

  .lg\:hover\:tw-scale-y-100:hover{
    --tw-scale-y: 1;
  }

  .lg\:hover\:tw-scale-y-105:hover{
    --tw-scale-y: 1.05;
  }

  .lg\:hover\:tw-scale-y-110:hover{
    --tw-scale-y: 1.1;
  }

  .lg\:hover\:tw-scale-y-125:hover{
    --tw-scale-y: 1.25;
  }

  .lg\:hover\:tw-scale-y-150:hover{
    --tw-scale-y: 1.5;
  }

  .lg\:focus\:tw-scale-x-0:focus{
    --tw-scale-x: 0;
  }

  .lg\:focus\:tw-scale-x-50:focus{
    --tw-scale-x: .5;
  }

  .lg\:focus\:tw-scale-x-75:focus{
    --tw-scale-x: .75;
  }

  .lg\:focus\:tw-scale-x-90:focus{
    --tw-scale-x: .9;
  }

  .lg\:focus\:tw-scale-x-95:focus{
    --tw-scale-x: .95;
  }

  .lg\:focus\:tw-scale-x-100:focus{
    --tw-scale-x: 1;
  }

  .lg\:focus\:tw-scale-x-105:focus{
    --tw-scale-x: 1.05;
  }

  .lg\:focus\:tw-scale-x-110:focus{
    --tw-scale-x: 1.1;
  }

  .lg\:focus\:tw-scale-x-125:focus{
    --tw-scale-x: 1.25;
  }

  .lg\:focus\:tw-scale-x-150:focus{
    --tw-scale-x: 1.5;
  }

  .lg\:focus\:tw-scale-y-0:focus{
    --tw-scale-y: 0;
  }

  .lg\:focus\:tw-scale-y-50:focus{
    --tw-scale-y: .5;
  }

  .lg\:focus\:tw-scale-y-75:focus{
    --tw-scale-y: .75;
  }

  .lg\:focus\:tw-scale-y-90:focus{
    --tw-scale-y: .9;
  }

  .lg\:focus\:tw-scale-y-95:focus{
    --tw-scale-y: .95;
  }

  .lg\:focus\:tw-scale-y-100:focus{
    --tw-scale-y: 1;
  }

  .lg\:focus\:tw-scale-y-105:focus{
    --tw-scale-y: 1.05;
  }

  .lg\:focus\:tw-scale-y-110:focus{
    --tw-scale-y: 1.1;
  }

  .lg\:focus\:tw-scale-y-125:focus{
    --tw-scale-y: 1.25;
  }

  .lg\:focus\:tw-scale-y-150:focus{
    --tw-scale-y: 1.5;
  }

  .lg\:tw-animate-none{
    -webkit-animation: none;
    animation: none;
  }

  .lg\:tw-animate-spin{
    -webkit-animation: tw-spin 1s linear infinite;
    animation: tw-spin 1s linear infinite;
  }

  .lg\:tw-animate-ping{
    -webkit-animation: tw-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
    animation: tw-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .lg\:tw-animate-pulse{
    -webkit-animation: tw-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    animation: tw-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .lg\:tw-animate-bounce{
    -webkit-animation: tw-bounce 1s infinite;
    animation: tw-bounce 1s infinite;
  }

  .lg\:tw-cursor-auto{
    cursor: auto;
  }

  .lg\:tw-cursor-default{
    cursor: default;
  }

  .lg\:tw-cursor-pointer{
    cursor: pointer;
  }

  .lg\:tw-cursor-wait{
    cursor: wait;
  }

  .lg\:tw-cursor-text{
    cursor: text;
  }

  .lg\:tw-cursor-move{
    cursor: move;
  }

  .lg\:tw-cursor-not-allowed{
    cursor: not-allowed;
  }

  .lg\:tw-select-none{
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .lg\:tw-select-text{
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }

  .lg\:tw-select-all{
    -webkit-user-select: all;
    -ms-user-select: all;
    user-select: all;
  }

  .lg\:tw-select-auto{
    -webkit-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
  }

  .lg\:tw-resize-none{
    resize: none;
  }

  .lg\:tw-resize-y{
    resize: vertical;
  }

  .lg\:tw-resize-x{
    resize: horizontal;
  }

  .lg\:tw-resize{
    resize: both;
  }

  .lg\:tw-list-inside{
    list-style-position: inside;
  }

  .lg\:tw-list-outside{
    list-style-position: outside;
  }

  .lg\:tw-list-none{
    list-style-type: none;
  }

  .lg\:tw-list-disc{
    list-style-type: disc;
  }

  .lg\:tw-list-decimal{
    list-style-type: decimal;
  }

  .lg\:tw-appearance-none{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .lg\:tw-auto-cols-auto{
    grid-auto-columns: auto;
  }

  .lg\:tw-auto-cols-min{
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
  }

  .lg\:tw-auto-cols-max{
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
  }

  .lg\:tw-auto-cols-fr{
    grid-auto-columns: minmax(0, 1fr);
  }

  .lg\:tw-grid-flow-row{
    grid-auto-flow: row;
  }

  .lg\:tw-grid-flow-col{
    grid-auto-flow: column;
  }

  .lg\:tw-grid-flow-row-dense{
    grid-auto-flow: row dense;
  }

  .lg\:tw-grid-flow-col-dense{
    grid-auto-flow: column dense;
  }

  .lg\:tw-auto-rows-auto{
    grid-auto-rows: auto;
  }

  .lg\:tw-auto-rows-min{
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
  }

  .lg\:tw-auto-rows-max{
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
  }

  .lg\:tw-auto-rows-fr{
    grid-auto-rows: minmax(0, 1fr);
  }

  .lg\:tw-grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .lg\:tw-grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:tw-grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:tw-grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:tw-grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:tw-grid-cols-6{
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .lg\:tw-grid-cols-7{
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .lg\:tw-grid-cols-8{
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .lg\:tw-grid-cols-9{
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .lg\:tw-grid-cols-10{
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .lg\:tw-grid-cols-11{
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .lg\:tw-grid-cols-12{
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:tw-grid-cols-none{
    grid-template-columns: none;
  }

  .lg\:tw-grid-rows-1{
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .lg\:tw-grid-rows-2{
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .lg\:tw-grid-rows-3{
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .lg\:tw-grid-rows-4{
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .lg\:tw-grid-rows-5{
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .lg\:tw-grid-rows-6{
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .lg\:tw-grid-rows-none{
    grid-template-rows: none;
  }

  .lg\:tw-flex-row{
    flex-direction: row;
  }

  .lg\:tw-flex-row-reverse{
    flex-direction: row-reverse;
  }

  .lg\:tw-flex-col{
    flex-direction: column;
  }

  .lg\:tw-flex-col-reverse{
    flex-direction: column-reverse;
  }

  .lg\:tw-flex-wrap{
    flex-wrap: wrap;
  }

  .lg\:tw-flex-wrap-reverse{
    flex-wrap: wrap-reverse;
  }

  .lg\:tw-flex-nowrap{
    flex-wrap: nowrap;
  }

  .lg\:tw-place-content-center{
    place-content: center;
  }

  .lg\:tw-place-content-start{
    place-content: start;
  }

  .lg\:tw-place-content-end{
    place-content: end;
  }

  .lg\:tw-place-content-between{
    place-content: space-between;
  }

  .lg\:tw-place-content-around{
    place-content: space-around;
  }

  .lg\:tw-place-content-evenly{
    place-content: space-evenly;
  }

  .lg\:tw-place-content-stretch{
    place-content: stretch;
  }

  .lg\:tw-place-items-start{
    place-items: start;
  }

  .lg\:tw-place-items-end{
    place-items: end;
  }

  .lg\:tw-place-items-center{
    place-items: center;
  }

  .lg\:tw-place-items-stretch{
    place-items: stretch;
  }

  .lg\:tw-content-center{
    align-content: center;
  }

  .lg\:tw-content-start{
    align-content: flex-start;
  }

  .lg\:tw-content-end{
    align-content: flex-end;
  }

  .lg\:tw-content-between{
    align-content: space-between;
  }

  .lg\:tw-content-around{
    align-content: space-around;
  }

  .lg\:tw-content-evenly{
    align-content: space-evenly;
  }

  .lg\:tw-items-start{
    align-items: flex-start;
  }

  .lg\:tw-items-end{
    align-items: flex-end;
  }

  .lg\:tw-items-center{
    align-items: center;
  }

  .lg\:tw-items-baseline{
    align-items: baseline;
  }

  .lg\:tw-items-stretch{
    align-items: stretch;
  }

  .lg\:tw-justify-start{
    justify-content: flex-start;
  }

  .lg\:tw-justify-end{
    justify-content: flex-end;
  }

  .lg\:tw-justify-center{
    justify-content: center;
  }

  .lg\:tw-justify-between{
    justify-content: space-between;
  }

  .lg\:tw-justify-around{
    justify-content: space-around;
  }

  .lg\:tw-justify-evenly{
    justify-content: space-evenly;
  }

  .lg\:tw-justify-items-start{
    justify-items: start;
  }

  .lg\:tw-justify-items-end{
    justify-items: end;
  }

  .lg\:tw-justify-items-center{
    justify-items: center;
  }

  .lg\:tw-justify-items-stretch{
    justify-items: stretch;
  }

  .lg\:tw-gap-0{
    gap: 0;
  }

  .lg\:tw-gap-1{
    gap: 0.25rem;
  }

  .lg\:tw-gap-2{
    gap: 0.5rem;
  }

  .lg\:tw-gap-3{
    gap: 0.75rem;
  }

  .lg\:tw-gap-4{
    gap: 1rem;
  }

  .lg\:tw-gap-5{
    gap: 1.25rem;
  }

  .lg\:tw-gap-6{
    gap: 1.5rem;
  }

  .lg\:tw-gap-8{
    gap: 2rem;
  }

  .lg\:tw-gap-10{
    gap: 2.5rem;
  }

  .lg\:tw-gap-12{
    gap: 3rem;
  }

  .lg\:tw-gap-16{
    gap: 4rem;
  }

  .lg\:tw-gap-20{
    gap: 5rem;
  }

  .lg\:tw-gap-24{
    gap: 6rem;
  }

  .lg\:tw-gap-32{
    gap: 8rem;
  }

  .lg\:tw-gap-40{
    gap: 10rem;
  }

  .lg\:tw-gap-48{
    gap: 12rem;
  }

  .lg\:tw-gap-56{
    gap: 14rem;
  }

  .lg\:tw-gap-64{
    gap: 16rem;
  }

  .lg\:tw-gap-px{
    gap: 1px;
  }

  .lg\:tw-gap-x-0{
    -webkit-column-gap: 0;
    column-gap: 0;
  }

  .lg\:tw-gap-x-1{
    -webkit-column-gap: 0.25rem;
    column-gap: 0.25rem;
  }

  .lg\:tw-gap-x-2{
    -webkit-column-gap: 0.5rem;
    column-gap: 0.5rem;
  }

  .lg\:tw-gap-x-3{
    -webkit-column-gap: 0.75rem;
    column-gap: 0.75rem;
  }

  .lg\:tw-gap-x-4{
    -webkit-column-gap: 1rem;
    column-gap: 1rem;
  }

  .lg\:tw-gap-x-5{
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }

  .lg\:tw-gap-x-6{
    -webkit-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }

  .lg\:tw-gap-x-8{
    -webkit-column-gap: 2rem;
    column-gap: 2rem;
  }

  .lg\:tw-gap-x-10{
    -webkit-column-gap: 2.5rem;
    column-gap: 2.5rem;
  }

  .lg\:tw-gap-x-12{
    -webkit-column-gap: 3rem;
    column-gap: 3rem;
  }

  .lg\:tw-gap-x-16{
    -webkit-column-gap: 4rem;
    column-gap: 4rem;
  }

  .lg\:tw-gap-x-20{
    -webkit-column-gap: 5rem;
    column-gap: 5rem;
  }

  .lg\:tw-gap-x-24{
    -webkit-column-gap: 6rem;
    column-gap: 6rem;
  }

  .lg\:tw-gap-x-32{
    -webkit-column-gap: 8rem;
    column-gap: 8rem;
  }

  .lg\:tw-gap-x-40{
    -webkit-column-gap: 10rem;
    column-gap: 10rem;
  }

  .lg\:tw-gap-x-48{
    -webkit-column-gap: 12rem;
    column-gap: 12rem;
  }

  .lg\:tw-gap-x-56{
    -webkit-column-gap: 14rem;
    column-gap: 14rem;
  }

  .lg\:tw-gap-x-64{
    -webkit-column-gap: 16rem;
    column-gap: 16rem;
  }

  .lg\:tw-gap-x-px{
    -webkit-column-gap: 1px;
    column-gap: 1px;
  }

  .lg\:tw-gap-y-0{
    row-gap: 0;
  }

  .lg\:tw-gap-y-1{
    row-gap: 0.25rem;
  }

  .lg\:tw-gap-y-2{
    row-gap: 0.5rem;
  }

  .lg\:tw-gap-y-3{
    row-gap: 0.75rem;
  }

  .lg\:tw-gap-y-4{
    row-gap: 1rem;
  }

  .lg\:tw-gap-y-5{
    row-gap: 1.25rem;
  }

  .lg\:tw-gap-y-6{
    row-gap: 1.5rem;
  }

  .lg\:tw-gap-y-8{
    row-gap: 2rem;
  }

  .lg\:tw-gap-y-10{
    row-gap: 2.5rem;
  }

  .lg\:tw-gap-y-12{
    row-gap: 3rem;
  }

  .lg\:tw-gap-y-16{
    row-gap: 4rem;
  }

  .lg\:tw-gap-y-20{
    row-gap: 5rem;
  }

  .lg\:tw-gap-y-24{
    row-gap: 6rem;
  }

  .lg\:tw-gap-y-32{
    row-gap: 8rem;
  }

  .lg\:tw-gap-y-40{
    row-gap: 10rem;
  }

  .lg\:tw-gap-y-48{
    row-gap: 12rem;
  }

  .lg\:tw-gap-y-56{
    row-gap: 14rem;
  }

  .lg\:tw-gap-y-64{
    row-gap: 16rem;
  }

  .lg\:tw-gap-y-px{
    row-gap: 1px;
  }

  .lg\:tw-space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw-space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw-space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw-space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw-space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw-space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw-space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw-space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw-space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw-space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw-space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw-space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw-space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw-space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw-space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw-space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw-space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw-space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw-space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw--space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw--space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw--space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw--space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw--space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw--space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw--space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw--space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw--space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw--space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw--space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw--space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw--space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw--space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw--space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw--space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw--space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw--space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw-space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .lg\:tw-space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }

  .lg\:tw-space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }

  .lg\:tw-space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }

  .lg\:tw-space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .lg\:tw-space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .lg\:tw-space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .lg\:tw-space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .lg\:tw-space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }

  .lg\:tw-space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }

  .lg\:tw-space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .lg\:tw-space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .lg\:tw-space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }

  .lg\:tw-space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }

  .lg\:tw-space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }

  .lg\:tw-space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }

  .lg\:tw-space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }

  .lg\:tw-space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }

  .lg\:tw-space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }

  .lg\:tw--space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
  }

  .lg\:tw--space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
  }

  .lg\:tw--space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
  }

  .lg\:tw--space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }

  .lg\:tw--space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }

  .lg\:tw--space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }

  .lg\:tw--space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }

  .lg\:tw--space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }

  .lg\:tw--space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }

  .lg\:tw--space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }

  .lg\:tw--space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }

  .lg\:tw--space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }

  .lg\:tw--space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }

  .lg\:tw--space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }

  .lg\:tw--space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }

  .lg\:tw--space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }

  .lg\:tw--space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }

  .lg\:tw--space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }

  .lg\:tw-space-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 1;
  }

  .lg\:tw-space-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 1;
  }

  .lg\:tw-divide-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg\:tw-divide-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg\:tw-divide-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg\:tw-divide-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg\:tw-divide-x-default > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg\:tw-divide-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .lg\:tw-divide-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }

  .lg\:tw-divide-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }

  .lg\:tw-divide-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }

  .lg\:tw-divide-y-default > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .lg\:tw-divide-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 1;
  }

  .lg\:tw-divide-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 1;
  }

  .lg\:tw-divide-solid > :not([hidden]) ~ :not([hidden]){
    border-style: solid;
  }

  .lg\:tw-divide-dashed > :not([hidden]) ~ :not([hidden]){
    border-style: dashed;
  }

  .lg\:tw-divide-dotted > :not([hidden]) ~ :not([hidden]){
    border-style: dotted;
  }

  .lg\:tw-divide-double > :not([hidden]) ~ :not([hidden]){
    border-style: double;
  }

  .lg\:tw-divide-none > :not([hidden]) ~ :not([hidden]){
    border-style: none;
  }

  .lg\:tw-divide-transparent > :not([hidden]) ~ :not([hidden]){
    border-color: transparent;
  }

  .lg\:tw-divide-current > :not([hidden]) ~ :not([hidden]){
    border-color: currentColor;
  }

  .lg\:tw-divide-black > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(44, 44, 44, var(--tw-divide-opacity));
  }

  .lg\:tw-divide-white > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }

  .lg\:tw-divide-gray-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(247, 250, 252, var(--tw-divide-opacity));
  }

  .lg\:tw-divide-gray-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(237, 242, 247, var(--tw-divide-opacity));
  }

  .lg\:tw-divide-gray-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-divide-opacity));
  }

  .lg\:tw-divide-gray-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(203, 213, 224, var(--tw-divide-opacity));
  }

  .lg\:tw-divide-gray-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(160, 174, 192, var(--tw-divide-opacity));
  }

  .lg\:tw-divide-gray-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(113, 128, 150, var(--tw-divide-opacity));
  }

  .lg\:tw-divide-gray-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(74, 85, 104, var(--tw-divide-opacity));
  }

  .lg\:tw-divide-gray-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(45, 55, 72, var(--tw-divide-opacity));
  }

  .lg\:tw-divide-gray-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(26, 32, 44, var(--tw-divide-opacity));
  }

  .lg\:tw-divide-blue-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(235, 248, 255, var(--tw-divide-opacity));
  }

  .lg\:tw-divide-blue-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(190, 227, 248, var(--tw-divide-opacity));
  }

  .lg\:tw-divide-blue-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(144, 205, 244, var(--tw-divide-opacity));
  }

  .lg\:tw-divide-blue-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(99, 179, 237, var(--tw-divide-opacity));
  }

  .lg\:tw-divide-blue-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(66, 153, 225, var(--tw-divide-opacity));
  }

  .lg\:tw-divide-blue-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(49, 130, 206, var(--tw-divide-opacity));
  }

  .lg\:tw-divide-blue-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(43, 108, 176, var(--tw-divide-opacity));
  }

  .lg\:tw-divide-blue-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(44, 82, 130, var(--tw-divide-opacity));
  }

  .lg\:tw-divide-blue-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(42, 67, 101, var(--tw-divide-opacity));
  }

  .lg\:tw-divide-opacity-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0;
  }

  .lg\:tw-divide-opacity-25 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.25;
  }

  .lg\:tw-divide-opacity-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.5;
  }

  .lg\:tw-divide-opacity-75 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.75;
  }

  .lg\:tw-divide-opacity-90 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.9;
  }

  .lg\:tw-divide-opacity-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
  }

  .lg\:tw-place-self-auto{
    place-self: auto;
  }

  .lg\:tw-place-self-start{
    place-self: start;
  }

  .lg\:tw-place-self-end{
    place-self: end;
  }

  .lg\:tw-place-self-center{
    place-self: center;
  }

  .lg\:tw-place-self-stretch{
    place-self: stretch;
  }

  .lg\:tw-self-auto{
    align-self: auto;
  }

  .lg\:tw-self-start{
    align-self: flex-start;
  }

  .lg\:tw-self-end{
    align-self: flex-end;
  }

  .lg\:tw-self-center{
    align-self: center;
  }

  .lg\:tw-self-stretch{
    align-self: stretch;
  }

  .lg\:tw-self-baseline{
    align-self: baseline;
  }

  .lg\:tw-justify-self-auto{
    justify-self: auto;
  }

  .lg\:tw-justify-self-start{
    justify-self: start;
  }

  .lg\:tw-justify-self-end{
    justify-self: end;
  }

  .lg\:tw-justify-self-center{
    justify-self: center;
  }

  .lg\:tw-justify-self-stretch{
    justify-self: stretch;
  }

  .lg\:tw-overflow-auto{
    overflow: auto;
  }

  .lg\:tw-overflow-hidden{
    overflow: hidden;
  }

  .lg\:tw-overflow-visible{
    overflow: visible;
  }

  .lg\:tw-overflow-scroll{
    overflow: scroll;
  }

  .lg\:tw-overflow-x-auto{
    overflow-x: auto;
  }

  .lg\:tw-overflow-y-auto{
    overflow-y: auto;
  }

  .lg\:tw-overflow-x-hidden{
    overflow-x: hidden;
  }

  .lg\:tw-overflow-y-hidden{
    overflow-y: hidden;
  }

  .lg\:tw-overflow-x-visible{
    overflow-x: visible;
  }

  .lg\:tw-overflow-y-visible{
    overflow-y: visible;
  }

  .lg\:tw-overflow-x-scroll{
    overflow-x: scroll;
  }

  .lg\:tw-overflow-y-scroll{
    overflow-y: scroll;
  }

  .lg\:tw-overscroll-auto{
    overscroll-behavior: auto;
  }

  .lg\:tw-overscroll-contain{
    overscroll-behavior: contain;
  }

  .lg\:tw-overscroll-none{
    overscroll-behavior: none;
  }

  .lg\:tw-overscroll-y-auto{
    overscroll-behavior-y: auto;
  }

  .lg\:tw-overscroll-y-contain{
    overscroll-behavior-y: contain;
  }

  .lg\:tw-overscroll-y-none{
    overscroll-behavior-y: none;
  }

  .lg\:tw-overscroll-x-auto{
    overscroll-behavior-x: auto;
  }

  .lg\:tw-overscroll-x-contain{
    overscroll-behavior-x: contain;
  }

  .lg\:tw-overscroll-x-none{
    overscroll-behavior-x: none;
  }

  .lg\:tw-truncate{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .lg\:tw-overflow-ellipsis{
    text-overflow: ellipsis;
  }

  .lg\:tw-overflow-clip{
    text-overflow: clip;
  }

  .lg\:tw-whitespace-normal{
    white-space: normal;
  }

  .lg\:tw-whitespace-nowrap{
    white-space: nowrap;
  }

  .lg\:tw-whitespace-pre{
    white-space: pre;
  }

  .lg\:tw-whitespace-pre-line{
    white-space: pre-line;
  }

  .lg\:tw-whitespace-pre-wrap{
    white-space: pre-wrap;
  }

  .lg\:tw-break-normal{
    overflow-wrap: normal;
    word-break: normal;
  }

  .lg\:tw-break-words{
    overflow-wrap: break-word;
  }

  .lg\:tw-break-all{
    word-break: break-all;
  }

  .lg\:tw-rounded-none{
    border-radius: 0;
  }

  .lg\:tw-rounded-sm{
    border-radius: 0.125rem;
  }

  .lg\:tw-rounded-default{
    border-radius: 0.25rem;
  }

  .lg\:tw-rounded-md{
    border-radius: 0.375rem;
  }

  .lg\:tw-rounded-lg{
    border-radius: 0.5rem;
  }

  .lg\:tw-rounded-xl{
    border-radius: 0.75rem;
  }

  .lg\:tw-rounded-2xl{
    border-radius: 1rem;
  }

  .lg\:tw-rounded-3xl{
    border-radius: 1.5rem;
  }

  .lg\:tw-rounded-full{
    border-radius: 9999px;
  }

  .lg\:tw-rounded-t-none{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .lg\:tw-rounded-t-sm{
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }

  .lg\:tw-rounded-t-default{
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .lg\:tw-rounded-t-md{
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  .lg\:tw-rounded-t-lg{
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .lg\:tw-rounded-t-xl{
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }

  .lg\:tw-rounded-t-2xl{
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .lg\:tw-rounded-t-3xl{
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .lg\:tw-rounded-t-full{
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .lg\:tw-rounded-r-none{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .lg\:tw-rounded-r-sm{
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }

  .lg\:tw-rounded-r-default{
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .lg\:tw-rounded-r-md{
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .lg\:tw-rounded-r-lg{
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .lg\:tw-rounded-r-xl{
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  .lg\:tw-rounded-r-2xl{
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .lg\:tw-rounded-r-3xl{
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .lg\:tw-rounded-r-full{
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .lg\:tw-rounded-b-none{
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .lg\:tw-rounded-b-sm{
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .lg\:tw-rounded-b-default{
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .lg\:tw-rounded-b-md{
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .lg\:tw-rounded-b-lg{
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .lg\:tw-rounded-b-xl{
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .lg\:tw-rounded-b-2xl{
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .lg\:tw-rounded-b-3xl{
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .lg\:tw-rounded-b-full{
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .lg\:tw-rounded-l-none{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .lg\:tw-rounded-l-sm{
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .lg\:tw-rounded-l-default{
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .lg\:tw-rounded-l-md{
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .lg\:tw-rounded-l-lg{
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .lg\:tw-rounded-l-xl{
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .lg\:tw-rounded-l-2xl{
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .lg\:tw-rounded-l-3xl{
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .lg\:tw-rounded-l-full{
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .lg\:tw-rounded-tl-none{
    border-top-left-radius: 0;
  }

  .lg\:tw-rounded-tl-sm{
    border-top-left-radius: 0.125rem;
  }

  .lg\:tw-rounded-tl-default{
    border-top-left-radius: 0.25rem;
  }

  .lg\:tw-rounded-tl-md{
    border-top-left-radius: 0.375rem;
  }

  .lg\:tw-rounded-tl-lg{
    border-top-left-radius: 0.5rem;
  }

  .lg\:tw-rounded-tl-xl{
    border-top-left-radius: 0.75rem;
  }

  .lg\:tw-rounded-tl-2xl{
    border-top-left-radius: 1rem;
  }

  .lg\:tw-rounded-tl-3xl{
    border-top-left-radius: 1.5rem;
  }

  .lg\:tw-rounded-tl-full{
    border-top-left-radius: 9999px;
  }

  .lg\:tw-rounded-tr-none{
    border-top-right-radius: 0;
  }

  .lg\:tw-rounded-tr-sm{
    border-top-right-radius: 0.125rem;
  }

  .lg\:tw-rounded-tr-default{
    border-top-right-radius: 0.25rem;
  }

  .lg\:tw-rounded-tr-md{
    border-top-right-radius: 0.375rem;
  }

  .lg\:tw-rounded-tr-lg{
    border-top-right-radius: 0.5rem;
  }

  .lg\:tw-rounded-tr-xl{
    border-top-right-radius: 0.75rem;
  }

  .lg\:tw-rounded-tr-2xl{
    border-top-right-radius: 1rem;
  }

  .lg\:tw-rounded-tr-3xl{
    border-top-right-radius: 1.5rem;
  }

  .lg\:tw-rounded-tr-full{
    border-top-right-radius: 9999px;
  }

  .lg\:tw-rounded-br-none{
    border-bottom-right-radius: 0;
  }

  .lg\:tw-rounded-br-sm{
    border-bottom-right-radius: 0.125rem;
  }

  .lg\:tw-rounded-br-default{
    border-bottom-right-radius: 0.25rem;
  }

  .lg\:tw-rounded-br-md{
    border-bottom-right-radius: 0.375rem;
  }

  .lg\:tw-rounded-br-lg{
    border-bottom-right-radius: 0.5rem;
  }

  .lg\:tw-rounded-br-xl{
    border-bottom-right-radius: 0.75rem;
  }

  .lg\:tw-rounded-br-2xl{
    border-bottom-right-radius: 1rem;
  }

  .lg\:tw-rounded-br-3xl{
    border-bottom-right-radius: 1.5rem;
  }

  .lg\:tw-rounded-br-full{
    border-bottom-right-radius: 9999px;
  }

  .lg\:tw-rounded-bl-none{
    border-bottom-left-radius: 0;
  }

  .lg\:tw-rounded-bl-sm{
    border-bottom-left-radius: 0.125rem;
  }

  .lg\:tw-rounded-bl-default{
    border-bottom-left-radius: 0.25rem;
  }

  .lg\:tw-rounded-bl-md{
    border-bottom-left-radius: 0.375rem;
  }

  .lg\:tw-rounded-bl-lg{
    border-bottom-left-radius: 0.5rem;
  }

  .lg\:tw-rounded-bl-xl{
    border-bottom-left-radius: 0.75rem;
  }

  .lg\:tw-rounded-bl-2xl{
    border-bottom-left-radius: 1rem;
  }

  .lg\:tw-rounded-bl-3xl{
    border-bottom-left-radius: 1.5rem;
  }

  .lg\:tw-rounded-bl-full{
    border-bottom-left-radius: 9999px;
  }

  .lg\:tw-border-0{
    border-width: 0;
  }

  .lg\:tw-border-2{
    border-width: 2px;
  }

  .lg\:tw-border-4{
    border-width: 4px;
  }

  .lg\:tw-border-8{
    border-width: 8px;
  }

  .lg\:tw-border-default{
    border-width: 1px;
  }

  .lg\:tw-border-t-0{
    border-top-width: 0;
  }

  .lg\:tw-border-t-2{
    border-top-width: 2px;
  }

  .lg\:tw-border-t-4{
    border-top-width: 4px;
  }

  .lg\:tw-border-t-8{
    border-top-width: 8px;
  }

  .lg\:tw-border-t-default{
    border-top-width: 1px;
  }

  .lg\:tw-border-r-0{
    border-right-width: 0;
  }

  .lg\:tw-border-r-2{
    border-right-width: 2px;
  }

  .lg\:tw-border-r-4{
    border-right-width: 4px;
  }

  .lg\:tw-border-r-8{
    border-right-width: 8px;
  }

  .lg\:tw-border-r-default{
    border-right-width: 1px;
  }

  .lg\:tw-border-b-0{
    border-bottom-width: 0;
  }

  .lg\:tw-border-b-2{
    border-bottom-width: 2px;
  }

  .lg\:tw-border-b-4{
    border-bottom-width: 4px;
  }

  .lg\:tw-border-b-8{
    border-bottom-width: 8px;
  }

  .lg\:tw-border-b-default{
    border-bottom-width: 1px;
  }

  .lg\:tw-border-l-0{
    border-left-width: 0;
  }

  .lg\:tw-border-l-2{
    border-left-width: 2px;
  }

  .lg\:tw-border-l-4{
    border-left-width: 4px;
  }

  .lg\:tw-border-l-8{
    border-left-width: 8px;
  }

  .lg\:tw-border-l-default{
    border-left-width: 1px;
  }

  .lg\:tw-border-solid{
    border-style: solid;
  }

  .lg\:tw-border-dashed{
    border-style: dashed;
  }

  .lg\:tw-border-dotted{
    border-style: dotted;
  }

  .lg\:tw-border-double{
    border-style: double;
  }

  .lg\:tw-border-none{
    border-style: none;
  }

  .lg\:tw-border-transparent{
    border-color: transparent;
  }

  .lg\:tw-border-current{
    border-color: currentColor;
  }

  .lg\:tw-border-black{
    --tw-border-opacity: 1;
    border-color: rgba(44, 44, 44, var(--tw-border-opacity));
  }

  .lg\:tw-border-white{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .lg\:tw-border-gray-100{
    --tw-border-opacity: 1;
    border-color: rgba(247, 250, 252, var(--tw-border-opacity));
  }

  .lg\:tw-border-gray-200{
    --tw-border-opacity: 1;
    border-color: rgba(237, 242, 247, var(--tw-border-opacity));
  }

  .lg\:tw-border-gray-300{
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
  }

  .lg\:tw-border-gray-400{
    --tw-border-opacity: 1;
    border-color: rgba(203, 213, 224, var(--tw-border-opacity));
  }

  .lg\:tw-border-gray-500{
    --tw-border-opacity: 1;
    border-color: rgba(160, 174, 192, var(--tw-border-opacity));
  }

  .lg\:tw-border-gray-600{
    --tw-border-opacity: 1;
    border-color: rgba(113, 128, 150, var(--tw-border-opacity));
  }

  .lg\:tw-border-gray-700{
    --tw-border-opacity: 1;
    border-color: rgba(74, 85, 104, var(--tw-border-opacity));
  }

  .lg\:tw-border-gray-800{
    --tw-border-opacity: 1;
    border-color: rgba(45, 55, 72, var(--tw-border-opacity));
  }

  .lg\:tw-border-gray-900{
    --tw-border-opacity: 1;
    border-color: rgba(26, 32, 44, var(--tw-border-opacity));
  }

  .lg\:tw-border-blue-100{
    --tw-border-opacity: 1;
    border-color: rgba(235, 248, 255, var(--tw-border-opacity));
  }

  .lg\:tw-border-blue-200{
    --tw-border-opacity: 1;
    border-color: rgba(190, 227, 248, var(--tw-border-opacity));
  }

  .lg\:tw-border-blue-300{
    --tw-border-opacity: 1;
    border-color: rgba(144, 205, 244, var(--tw-border-opacity));
  }

  .lg\:tw-border-blue-400{
    --tw-border-opacity: 1;
    border-color: rgba(99, 179, 237, var(--tw-border-opacity));
  }

  .lg\:tw-border-blue-500{
    --tw-border-opacity: 1;
    border-color: rgba(66, 153, 225, var(--tw-border-opacity));
  }

  .lg\:tw-border-blue-600{
    --tw-border-opacity: 1;
    border-color: rgba(49, 130, 206, var(--tw-border-opacity));
  }

  .lg\:tw-border-blue-700{
    --tw-border-opacity: 1;
    border-color: rgba(43, 108, 176, var(--tw-border-opacity));
  }

  .lg\:tw-border-blue-800{
    --tw-border-opacity: 1;
    border-color: rgba(44, 82, 130, var(--tw-border-opacity));
  }

  .lg\:tw-border-blue-900{
    --tw-border-opacity: 1;
    border-color: rgba(42, 67, 101, var(--tw-border-opacity));
  }

  .lg\:hover\:tw-border-transparent:hover{
    border-color: transparent;
  }

  .lg\:hover\:tw-border-current:hover{
    border-color: currentColor;
  }

  .lg\:hover\:tw-border-black:hover{
    --tw-border-opacity: 1;
    border-color: rgba(44, 44, 44, var(--tw-border-opacity));
  }

  .lg\:hover\:tw-border-white:hover{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .lg\:hover\:tw-border-gray-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(247, 250, 252, var(--tw-border-opacity));
  }

  .lg\:hover\:tw-border-gray-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(237, 242, 247, var(--tw-border-opacity));
  }

  .lg\:hover\:tw-border-gray-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
  }

  .lg\:hover\:tw-border-gray-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(203, 213, 224, var(--tw-border-opacity));
  }

  .lg\:hover\:tw-border-gray-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(160, 174, 192, var(--tw-border-opacity));
  }

  .lg\:hover\:tw-border-gray-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(113, 128, 150, var(--tw-border-opacity));
  }

  .lg\:hover\:tw-border-gray-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(74, 85, 104, var(--tw-border-opacity));
  }

  .lg\:hover\:tw-border-gray-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(45, 55, 72, var(--tw-border-opacity));
  }

  .lg\:hover\:tw-border-gray-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(26, 32, 44, var(--tw-border-opacity));
  }

  .lg\:hover\:tw-border-blue-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(235, 248, 255, var(--tw-border-opacity));
  }

  .lg\:hover\:tw-border-blue-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(190, 227, 248, var(--tw-border-opacity));
  }

  .lg\:hover\:tw-border-blue-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(144, 205, 244, var(--tw-border-opacity));
  }

  .lg\:hover\:tw-border-blue-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(99, 179, 237, var(--tw-border-opacity));
  }

  .lg\:hover\:tw-border-blue-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(66, 153, 225, var(--tw-border-opacity));
  }

  .lg\:hover\:tw-border-blue-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(49, 130, 206, var(--tw-border-opacity));
  }

  .lg\:hover\:tw-border-blue-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(43, 108, 176, var(--tw-border-opacity));
  }

  .lg\:hover\:tw-border-blue-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(44, 82, 130, var(--tw-border-opacity));
  }

  .lg\:hover\:tw-border-blue-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(42, 67, 101, var(--tw-border-opacity));
  }

  .lg\:focus\:tw-border-transparent:focus{
    border-color: transparent;
  }

  .lg\:focus\:tw-border-current:focus{
    border-color: currentColor;
  }

  .lg\:focus\:tw-border-black:focus{
    --tw-border-opacity: 1;
    border-color: rgba(44, 44, 44, var(--tw-border-opacity));
  }

  .lg\:focus\:tw-border-white:focus{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .lg\:focus\:tw-border-gray-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(247, 250, 252, var(--tw-border-opacity));
  }

  .lg\:focus\:tw-border-gray-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(237, 242, 247, var(--tw-border-opacity));
  }

  .lg\:focus\:tw-border-gray-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
  }

  .lg\:focus\:tw-border-gray-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(203, 213, 224, var(--tw-border-opacity));
  }

  .lg\:focus\:tw-border-gray-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(160, 174, 192, var(--tw-border-opacity));
  }

  .lg\:focus\:tw-border-gray-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(113, 128, 150, var(--tw-border-opacity));
  }

  .lg\:focus\:tw-border-gray-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(74, 85, 104, var(--tw-border-opacity));
  }

  .lg\:focus\:tw-border-gray-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(45, 55, 72, var(--tw-border-opacity));
  }

  .lg\:focus\:tw-border-gray-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(26, 32, 44, var(--tw-border-opacity));
  }

  .lg\:focus\:tw-border-blue-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(235, 248, 255, var(--tw-border-opacity));
  }

  .lg\:focus\:tw-border-blue-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(190, 227, 248, var(--tw-border-opacity));
  }

  .lg\:focus\:tw-border-blue-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(144, 205, 244, var(--tw-border-opacity));
  }

  .lg\:focus\:tw-border-blue-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(99, 179, 237, var(--tw-border-opacity));
  }

  .lg\:focus\:tw-border-blue-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(66, 153, 225, var(--tw-border-opacity));
  }

  .lg\:focus\:tw-border-blue-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(49, 130, 206, var(--tw-border-opacity));
  }

  .lg\:focus\:tw-border-blue-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(43, 108, 176, var(--tw-border-opacity));
  }

  .lg\:focus\:tw-border-blue-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(44, 82, 130, var(--tw-border-opacity));
  }

  .lg\:focus\:tw-border-blue-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(42, 67, 101, var(--tw-border-opacity));
  }

  .lg\:tw-border-opacity-0{
    --tw-border-opacity: 0;
  }

  .lg\:tw-border-opacity-25{
    --tw-border-opacity: 0.25;
  }

  .lg\:tw-border-opacity-50{
    --tw-border-opacity: 0.5;
  }

  .lg\:tw-border-opacity-75{
    --tw-border-opacity: 0.75;
  }

  .lg\:tw-border-opacity-90{
    --tw-border-opacity: 0.9;
  }

  .lg\:tw-border-opacity-100{
    --tw-border-opacity: 1;
  }

  .lg\:hover\:tw-border-opacity-0:hover{
    --tw-border-opacity: 0;
  }

  .lg\:hover\:tw-border-opacity-25:hover{
    --tw-border-opacity: 0.25;
  }

  .lg\:hover\:tw-border-opacity-50:hover{
    --tw-border-opacity: 0.5;
  }

  .lg\:hover\:tw-border-opacity-75:hover{
    --tw-border-opacity: 0.75;
  }

  .lg\:hover\:tw-border-opacity-90:hover{
    --tw-border-opacity: 0.9;
  }

  .lg\:hover\:tw-border-opacity-100:hover{
    --tw-border-opacity: 1;
  }

  .lg\:focus\:tw-border-opacity-0:focus{
    --tw-border-opacity: 0;
  }

  .lg\:focus\:tw-border-opacity-25:focus{
    --tw-border-opacity: 0.25;
  }

  .lg\:focus\:tw-border-opacity-50:focus{
    --tw-border-opacity: 0.5;
  }

  .lg\:focus\:tw-border-opacity-75:focus{
    --tw-border-opacity: 0.75;
  }

  .lg\:focus\:tw-border-opacity-90:focus{
    --tw-border-opacity: 0.9;
  }

  .lg\:focus\:tw-border-opacity-100:focus{
    --tw-border-opacity: 1;
  }

  .lg\:tw-bg-transparent{
    background-color: transparent;
  }

  .lg\:tw-bg-current{
    background-color: currentColor;
  }

  .lg\:tw-bg-black{
    --tw-bg-opacity: 1;
    background-color: rgba(44, 44, 44, var(--tw-bg-opacity));
  }

  .lg\:tw-bg-white{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .lg\:tw-bg-gray-100{
    --tw-bg-opacity: 1;
    background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
  }

  .lg\:tw-bg-gray-200{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 242, 247, var(--tw-bg-opacity));
  }

  .lg\:tw-bg-gray-300{
    --tw-bg-opacity: 1;
    background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
  }

  .lg\:tw-bg-gray-400{
    --tw-bg-opacity: 1;
    background-color: rgba(203, 213, 224, var(--tw-bg-opacity));
  }

  .lg\:tw-bg-gray-500{
    --tw-bg-opacity: 1;
    background-color: rgba(160, 174, 192, var(--tw-bg-opacity));
  }

  .lg\:tw-bg-gray-600{
    --tw-bg-opacity: 1;
    background-color: rgba(113, 128, 150, var(--tw-bg-opacity));
  }

  .lg\:tw-bg-gray-700{
    --tw-bg-opacity: 1;
    background-color: rgba(74, 85, 104, var(--tw-bg-opacity));
  }

  .lg\:tw-bg-gray-800{
    --tw-bg-opacity: 1;
    background-color: rgba(45, 55, 72, var(--tw-bg-opacity));
  }

  .lg\:tw-bg-gray-900{
    --tw-bg-opacity: 1;
    background-color: rgba(26, 32, 44, var(--tw-bg-opacity));
  }

  .lg\:tw-bg-blue-100{
    --tw-bg-opacity: 1;
    background-color: rgba(235, 248, 255, var(--tw-bg-opacity));
  }

  .lg\:tw-bg-blue-200{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 227, 248, var(--tw-bg-opacity));
  }

  .lg\:tw-bg-blue-300{
    --tw-bg-opacity: 1;
    background-color: rgba(144, 205, 244, var(--tw-bg-opacity));
  }

  .lg\:tw-bg-blue-400{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 179, 237, var(--tw-bg-opacity));
  }

  .lg\:tw-bg-blue-500{
    --tw-bg-opacity: 1;
    background-color: rgba(66, 153, 225, var(--tw-bg-opacity));
  }

  .lg\:tw-bg-blue-600{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 130, 206, var(--tw-bg-opacity));
  }

  .lg\:tw-bg-blue-700{
    --tw-bg-opacity: 1;
    background-color: rgba(43, 108, 176, var(--tw-bg-opacity));
  }

  .lg\:tw-bg-blue-800{
    --tw-bg-opacity: 1;
    background-color: rgba(44, 82, 130, var(--tw-bg-opacity));
  }

  .lg\:tw-bg-blue-900{
    --tw-bg-opacity: 1;
    background-color: rgba(42, 67, 101, var(--tw-bg-opacity));
  }

  .lg\:hover\:tw-bg-transparent:hover{
    background-color: transparent;
  }

  .lg\:hover\:tw-bg-current:hover{
    background-color: currentColor;
  }

  .lg\:hover\:tw-bg-black:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(44, 44, 44, var(--tw-bg-opacity));
  }

  .lg\:hover\:tw-bg-white:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .lg\:hover\:tw-bg-gray-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
  }

  .lg\:hover\:tw-bg-gray-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 242, 247, var(--tw-bg-opacity));
  }

  .lg\:hover\:tw-bg-gray-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
  }

  .lg\:hover\:tw-bg-gray-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(203, 213, 224, var(--tw-bg-opacity));
  }

  .lg\:hover\:tw-bg-gray-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(160, 174, 192, var(--tw-bg-opacity));
  }

  .lg\:hover\:tw-bg-gray-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(113, 128, 150, var(--tw-bg-opacity));
  }

  .lg\:hover\:tw-bg-gray-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(74, 85, 104, var(--tw-bg-opacity));
  }

  .lg\:hover\:tw-bg-gray-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(45, 55, 72, var(--tw-bg-opacity));
  }

  .lg\:hover\:tw-bg-gray-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(26, 32, 44, var(--tw-bg-opacity));
  }

  .lg\:hover\:tw-bg-blue-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(235, 248, 255, var(--tw-bg-opacity));
  }

  .lg\:hover\:tw-bg-blue-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 227, 248, var(--tw-bg-opacity));
  }

  .lg\:hover\:tw-bg-blue-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(144, 205, 244, var(--tw-bg-opacity));
  }

  .lg\:hover\:tw-bg-blue-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 179, 237, var(--tw-bg-opacity));
  }

  .lg\:hover\:tw-bg-blue-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(66, 153, 225, var(--tw-bg-opacity));
  }

  .lg\:hover\:tw-bg-blue-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 130, 206, var(--tw-bg-opacity));
  }

  .lg\:hover\:tw-bg-blue-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(43, 108, 176, var(--tw-bg-opacity));
  }

  .lg\:hover\:tw-bg-blue-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(44, 82, 130, var(--tw-bg-opacity));
  }

  .lg\:hover\:tw-bg-blue-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(42, 67, 101, var(--tw-bg-opacity));
  }

  .lg\:focus\:tw-bg-transparent:focus{
    background-color: transparent;
  }

  .lg\:focus\:tw-bg-current:focus{
    background-color: currentColor;
  }

  .lg\:focus\:tw-bg-black:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(44, 44, 44, var(--tw-bg-opacity));
  }

  .lg\:focus\:tw-bg-white:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .lg\:focus\:tw-bg-gray-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
  }

  .lg\:focus\:tw-bg-gray-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 242, 247, var(--tw-bg-opacity));
  }

  .lg\:focus\:tw-bg-gray-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
  }

  .lg\:focus\:tw-bg-gray-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(203, 213, 224, var(--tw-bg-opacity));
  }

  .lg\:focus\:tw-bg-gray-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(160, 174, 192, var(--tw-bg-opacity));
  }

  .lg\:focus\:tw-bg-gray-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(113, 128, 150, var(--tw-bg-opacity));
  }

  .lg\:focus\:tw-bg-gray-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(74, 85, 104, var(--tw-bg-opacity));
  }

  .lg\:focus\:tw-bg-gray-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(45, 55, 72, var(--tw-bg-opacity));
  }

  .lg\:focus\:tw-bg-gray-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(26, 32, 44, var(--tw-bg-opacity));
  }

  .lg\:focus\:tw-bg-blue-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(235, 248, 255, var(--tw-bg-opacity));
  }

  .lg\:focus\:tw-bg-blue-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 227, 248, var(--tw-bg-opacity));
  }

  .lg\:focus\:tw-bg-blue-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(144, 205, 244, var(--tw-bg-opacity));
  }

  .lg\:focus\:tw-bg-blue-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 179, 237, var(--tw-bg-opacity));
  }

  .lg\:focus\:tw-bg-blue-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(66, 153, 225, var(--tw-bg-opacity));
  }

  .lg\:focus\:tw-bg-blue-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 130, 206, var(--tw-bg-opacity));
  }

  .lg\:focus\:tw-bg-blue-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(43, 108, 176, var(--tw-bg-opacity));
  }

  .lg\:focus\:tw-bg-blue-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(44, 82, 130, var(--tw-bg-opacity));
  }

  .lg\:focus\:tw-bg-blue-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(42, 67, 101, var(--tw-bg-opacity));
  }

  .lg\:tw-bg-opacity-0{
    --tw-bg-opacity: 0;
  }

  .lg\:tw-bg-opacity-25{
    --tw-bg-opacity: 0.25;
  }

  .lg\:tw-bg-opacity-50{
    --tw-bg-opacity: 0.5;
  }

  .lg\:tw-bg-opacity-75{
    --tw-bg-opacity: 0.75;
  }

  .lg\:tw-bg-opacity-90{
    --tw-bg-opacity: 0.9;
  }

  .lg\:tw-bg-opacity-100{
    --tw-bg-opacity: 1;
  }

  .lg\:hover\:tw-bg-opacity-0:hover{
    --tw-bg-opacity: 0;
  }

  .lg\:hover\:tw-bg-opacity-25:hover{
    --tw-bg-opacity: 0.25;
  }

  .lg\:hover\:tw-bg-opacity-50:hover{
    --tw-bg-opacity: 0.5;
  }

  .lg\:hover\:tw-bg-opacity-75:hover{
    --tw-bg-opacity: 0.75;
  }

  .lg\:hover\:tw-bg-opacity-90:hover{
    --tw-bg-opacity: 0.9;
  }

  .lg\:hover\:tw-bg-opacity-100:hover{
    --tw-bg-opacity: 1;
  }

  .lg\:focus\:tw-bg-opacity-0:focus{
    --tw-bg-opacity: 0;
  }

  .lg\:focus\:tw-bg-opacity-25:focus{
    --tw-bg-opacity: 0.25;
  }

  .lg\:focus\:tw-bg-opacity-50:focus{
    --tw-bg-opacity: 0.5;
  }

  .lg\:focus\:tw-bg-opacity-75:focus{
    --tw-bg-opacity: 0.75;
  }

  .lg\:focus\:tw-bg-opacity-90:focus{
    --tw-bg-opacity: 0.9;
  }

  .lg\:focus\:tw-bg-opacity-100:focus{
    --tw-bg-opacity: 1;
  }

  .lg\:tw-bg-none{
    background-image: none;
  }

  .lg\:tw-bg-gradient-to-t{
    background-image: linear-gradient(to top, var(--gradient-color-stops));
  }

  .lg\:tw-bg-gradient-to-tr{
    background-image: linear-gradient(to top right, var(--gradient-color-stops));
  }

  .lg\:tw-bg-gradient-to-r{
    background-image: linear-gradient(to right, var(--gradient-color-stops));
  }

  .lg\:tw-bg-gradient-to-br{
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops));
  }

  .lg\:tw-bg-gradient-to-b{
    background-image: linear-gradient(to bottom, var(--gradient-color-stops));
  }

  .lg\:tw-bg-gradient-to-bl{
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops));
  }

  .lg\:tw-bg-gradient-to-l{
    background-image: linear-gradient(to left, var(--gradient-color-stops));
  }

  .lg\:tw-bg-gradient-to-tl{
    background-image: linear-gradient(to top left, var(--gradient-color-stops));
  }

  .lg\:tw-from-transparent{
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .lg\:tw-from-current{
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:tw-from-black{
    --tw-gradient-from: #2c2c2c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 44, 44, 0));
  }

  .lg\:tw-from-white{
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:tw-from-gray-100{
    --tw-gradient-from: #f7fafc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 250, 252, 0));
  }

  .lg\:tw-from-gray-200{
    --tw-gradient-from: #edf2f7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 242, 247, 0));
  }

  .lg\:tw-from-gray-300{
    --tw-gradient-from: #e2e8f0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(226, 232, 240, 0));
  }

  .lg\:tw-from-gray-400{
    --tw-gradient-from: #cbd5e0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(203, 213, 224, 0));
  }

  .lg\:tw-from-gray-500{
    --tw-gradient-from: #a0aec0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(160, 174, 192, 0));
  }

  .lg\:tw-from-gray-600{
    --tw-gradient-from: #718096;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(113, 128, 150, 0));
  }

  .lg\:tw-from-gray-700{
    --tw-gradient-from: #4a5568;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 85, 104, 0));
  }

  .lg\:tw-from-gray-800{
    --tw-gradient-from: #2d3748;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 72, 0));
  }

  .lg\:tw-from-gray-900{
    --tw-gradient-from: #1a202c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 32, 44, 0));
  }

  .lg\:tw-from-blue-100{
    --tw-gradient-from: #ebf8ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(235, 248, 255, 0));
  }

  .lg\:tw-from-blue-200{
    --tw-gradient-from: #bee3f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 227, 248, 0));
  }

  .lg\:tw-from-blue-300{
    --tw-gradient-from: #90cdf4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 205, 244, 0));
  }

  .lg\:tw-from-blue-400{
    --tw-gradient-from: #63b3ed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 179, 237, 0));
  }

  .lg\:tw-from-blue-500{
    --tw-gradient-from: #4299e1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 153, 225, 0));
  }

  .lg\:tw-from-blue-600{
    --tw-gradient-from: #3182ce;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 130, 206, 0));
  }

  .lg\:tw-from-blue-700{
    --tw-gradient-from: #2b6cb0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(43, 108, 176, 0));
  }

  .lg\:tw-from-blue-800{
    --tw-gradient-from: #2c5282;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 82, 130, 0));
  }

  .lg\:tw-from-blue-900{
    --tw-gradient-from: #2a4365;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(42, 67, 101, 0));
  }

  .lg\:hover\:tw-from-transparent:hover{
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .lg\:hover\:tw-from-current:hover{
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:hover\:tw-from-black:hover{
    --tw-gradient-from: #2c2c2c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 44, 44, 0));
  }

  .lg\:hover\:tw-from-white:hover{
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:hover\:tw-from-gray-100:hover{
    --tw-gradient-from: #f7fafc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 250, 252, 0));
  }

  .lg\:hover\:tw-from-gray-200:hover{
    --tw-gradient-from: #edf2f7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 242, 247, 0));
  }

  .lg\:hover\:tw-from-gray-300:hover{
    --tw-gradient-from: #e2e8f0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(226, 232, 240, 0));
  }

  .lg\:hover\:tw-from-gray-400:hover{
    --tw-gradient-from: #cbd5e0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(203, 213, 224, 0));
  }

  .lg\:hover\:tw-from-gray-500:hover{
    --tw-gradient-from: #a0aec0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(160, 174, 192, 0));
  }

  .lg\:hover\:tw-from-gray-600:hover{
    --tw-gradient-from: #718096;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(113, 128, 150, 0));
  }

  .lg\:hover\:tw-from-gray-700:hover{
    --tw-gradient-from: #4a5568;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 85, 104, 0));
  }

  .lg\:hover\:tw-from-gray-800:hover{
    --tw-gradient-from: #2d3748;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 72, 0));
  }

  .lg\:hover\:tw-from-gray-900:hover{
    --tw-gradient-from: #1a202c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 32, 44, 0));
  }

  .lg\:hover\:tw-from-blue-100:hover{
    --tw-gradient-from: #ebf8ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(235, 248, 255, 0));
  }

  .lg\:hover\:tw-from-blue-200:hover{
    --tw-gradient-from: #bee3f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 227, 248, 0));
  }

  .lg\:hover\:tw-from-blue-300:hover{
    --tw-gradient-from: #90cdf4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 205, 244, 0));
  }

  .lg\:hover\:tw-from-blue-400:hover{
    --tw-gradient-from: #63b3ed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 179, 237, 0));
  }

  .lg\:hover\:tw-from-blue-500:hover{
    --tw-gradient-from: #4299e1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 153, 225, 0));
  }

  .lg\:hover\:tw-from-blue-600:hover{
    --tw-gradient-from: #3182ce;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 130, 206, 0));
  }

  .lg\:hover\:tw-from-blue-700:hover{
    --tw-gradient-from: #2b6cb0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(43, 108, 176, 0));
  }

  .lg\:hover\:tw-from-blue-800:hover{
    --tw-gradient-from: #2c5282;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 82, 130, 0));
  }

  .lg\:hover\:tw-from-blue-900:hover{
    --tw-gradient-from: #2a4365;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(42, 67, 101, 0));
  }

  .lg\:focus\:tw-from-transparent:focus{
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .lg\:focus\:tw-from-current:focus{
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:focus\:tw-from-black:focus{
    --tw-gradient-from: #2c2c2c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 44, 44, 0));
  }

  .lg\:focus\:tw-from-white:focus{
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:focus\:tw-from-gray-100:focus{
    --tw-gradient-from: #f7fafc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 250, 252, 0));
  }

  .lg\:focus\:tw-from-gray-200:focus{
    --tw-gradient-from: #edf2f7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 242, 247, 0));
  }

  .lg\:focus\:tw-from-gray-300:focus{
    --tw-gradient-from: #e2e8f0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(226, 232, 240, 0));
  }

  .lg\:focus\:tw-from-gray-400:focus{
    --tw-gradient-from: #cbd5e0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(203, 213, 224, 0));
  }

  .lg\:focus\:tw-from-gray-500:focus{
    --tw-gradient-from: #a0aec0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(160, 174, 192, 0));
  }

  .lg\:focus\:tw-from-gray-600:focus{
    --tw-gradient-from: #718096;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(113, 128, 150, 0));
  }

  .lg\:focus\:tw-from-gray-700:focus{
    --tw-gradient-from: #4a5568;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 85, 104, 0));
  }

  .lg\:focus\:tw-from-gray-800:focus{
    --tw-gradient-from: #2d3748;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 72, 0));
  }

  .lg\:focus\:tw-from-gray-900:focus{
    --tw-gradient-from: #1a202c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 32, 44, 0));
  }

  .lg\:focus\:tw-from-blue-100:focus{
    --tw-gradient-from: #ebf8ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(235, 248, 255, 0));
  }

  .lg\:focus\:tw-from-blue-200:focus{
    --tw-gradient-from: #bee3f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 227, 248, 0));
  }

  .lg\:focus\:tw-from-blue-300:focus{
    --tw-gradient-from: #90cdf4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 205, 244, 0));
  }

  .lg\:focus\:tw-from-blue-400:focus{
    --tw-gradient-from: #63b3ed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 179, 237, 0));
  }

  .lg\:focus\:tw-from-blue-500:focus{
    --tw-gradient-from: #4299e1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 153, 225, 0));
  }

  .lg\:focus\:tw-from-blue-600:focus{
    --tw-gradient-from: #3182ce;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 130, 206, 0));
  }

  .lg\:focus\:tw-from-blue-700:focus{
    --tw-gradient-from: #2b6cb0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(43, 108, 176, 0));
  }

  .lg\:focus\:tw-from-blue-800:focus{
    --tw-gradient-from: #2c5282;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 82, 130, 0));
  }

  .lg\:focus\:tw-from-blue-900:focus{
    --tw-gradient-from: #2a4365;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(42, 67, 101, 0));
  }

  .lg\:tw-via-transparent{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .lg\:tw-via-current{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:tw-via-black{
    --tw-gradient-stops: var(--tw-gradient-from), #2c2c2c, var(--tw-gradient-to, rgba(44, 44, 44, 0));
  }

  .lg\:tw-via-white{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:tw-via-gray-100{
    --tw-gradient-stops: var(--tw-gradient-from), #f7fafc, var(--tw-gradient-to, rgba(247, 250, 252, 0));
  }

  .lg\:tw-via-gray-200{
    --tw-gradient-stops: var(--tw-gradient-from), #edf2f7, var(--tw-gradient-to, rgba(237, 242, 247, 0));
  }

  .lg\:tw-via-gray-300{
    --tw-gradient-stops: var(--tw-gradient-from), #e2e8f0, var(--tw-gradient-to, rgba(226, 232, 240, 0));
  }

  .lg\:tw-via-gray-400{
    --tw-gradient-stops: var(--tw-gradient-from), #cbd5e0, var(--tw-gradient-to, rgba(203, 213, 224, 0));
  }

  .lg\:tw-via-gray-500{
    --tw-gradient-stops: var(--tw-gradient-from), #a0aec0, var(--tw-gradient-to, rgba(160, 174, 192, 0));
  }

  .lg\:tw-via-gray-600{
    --tw-gradient-stops: var(--tw-gradient-from), #718096, var(--tw-gradient-to, rgba(113, 128, 150, 0));
  }

  .lg\:tw-via-gray-700{
    --tw-gradient-stops: var(--tw-gradient-from), #4a5568, var(--tw-gradient-to, rgba(74, 85, 104, 0));
  }

  .lg\:tw-via-gray-800{
    --tw-gradient-stops: var(--tw-gradient-from), #2d3748, var(--tw-gradient-to, rgba(45, 55, 72, 0));
  }

  .lg\:tw-via-gray-900{
    --tw-gradient-stops: var(--tw-gradient-from), #1a202c, var(--tw-gradient-to, rgba(26, 32, 44, 0));
  }

  .lg\:tw-via-blue-100{
    --tw-gradient-stops: var(--tw-gradient-from), #ebf8ff, var(--tw-gradient-to, rgba(235, 248, 255, 0));
  }

  .lg\:tw-via-blue-200{
    --tw-gradient-stops: var(--tw-gradient-from), #bee3f8, var(--tw-gradient-to, rgba(190, 227, 248, 0));
  }

  .lg\:tw-via-blue-300{
    --tw-gradient-stops: var(--tw-gradient-from), #90cdf4, var(--tw-gradient-to, rgba(144, 205, 244, 0));
  }

  .lg\:tw-via-blue-400{
    --tw-gradient-stops: var(--tw-gradient-from), #63b3ed, var(--tw-gradient-to, rgba(99, 179, 237, 0));
  }

  .lg\:tw-via-blue-500{
    --tw-gradient-stops: var(--tw-gradient-from), #4299e1, var(--tw-gradient-to, rgba(66, 153, 225, 0));
  }

  .lg\:tw-via-blue-600{
    --tw-gradient-stops: var(--tw-gradient-from), #3182ce, var(--tw-gradient-to, rgba(49, 130, 206, 0));
  }

  .lg\:tw-via-blue-700{
    --tw-gradient-stops: var(--tw-gradient-from), #2b6cb0, var(--tw-gradient-to, rgba(43, 108, 176, 0));
  }

  .lg\:tw-via-blue-800{
    --tw-gradient-stops: var(--tw-gradient-from), #2c5282, var(--tw-gradient-to, rgba(44, 82, 130, 0));
  }

  .lg\:tw-via-blue-900{
    --tw-gradient-stops: var(--tw-gradient-from), #2a4365, var(--tw-gradient-to, rgba(42, 67, 101, 0));
  }

  .lg\:hover\:tw-via-transparent:hover{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .lg\:hover\:tw-via-current:hover{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:hover\:tw-via-black:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2c2c2c, var(--tw-gradient-to, rgba(44, 44, 44, 0));
  }

  .lg\:hover\:tw-via-white:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:hover\:tw-via-gray-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f7fafc, var(--tw-gradient-to, rgba(247, 250, 252, 0));
  }

  .lg\:hover\:tw-via-gray-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #edf2f7, var(--tw-gradient-to, rgba(237, 242, 247, 0));
  }

  .lg\:hover\:tw-via-gray-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e2e8f0, var(--tw-gradient-to, rgba(226, 232, 240, 0));
  }

  .lg\:hover\:tw-via-gray-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #cbd5e0, var(--tw-gradient-to, rgba(203, 213, 224, 0));
  }

  .lg\:hover\:tw-via-gray-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a0aec0, var(--tw-gradient-to, rgba(160, 174, 192, 0));
  }

  .lg\:hover\:tw-via-gray-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #718096, var(--tw-gradient-to, rgba(113, 128, 150, 0));
  }

  .lg\:hover\:tw-via-gray-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4a5568, var(--tw-gradient-to, rgba(74, 85, 104, 0));
  }

  .lg\:hover\:tw-via-gray-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2d3748, var(--tw-gradient-to, rgba(45, 55, 72, 0));
  }

  .lg\:hover\:tw-via-gray-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1a202c, var(--tw-gradient-to, rgba(26, 32, 44, 0));
  }

  .lg\:hover\:tw-via-blue-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ebf8ff, var(--tw-gradient-to, rgba(235, 248, 255, 0));
  }

  .lg\:hover\:tw-via-blue-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #bee3f8, var(--tw-gradient-to, rgba(190, 227, 248, 0));
  }

  .lg\:hover\:tw-via-blue-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #90cdf4, var(--tw-gradient-to, rgba(144, 205, 244, 0));
  }

  .lg\:hover\:tw-via-blue-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #63b3ed, var(--tw-gradient-to, rgba(99, 179, 237, 0));
  }

  .lg\:hover\:tw-via-blue-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4299e1, var(--tw-gradient-to, rgba(66, 153, 225, 0));
  }

  .lg\:hover\:tw-via-blue-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3182ce, var(--tw-gradient-to, rgba(49, 130, 206, 0));
  }

  .lg\:hover\:tw-via-blue-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2b6cb0, var(--tw-gradient-to, rgba(43, 108, 176, 0));
  }

  .lg\:hover\:tw-via-blue-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2c5282, var(--tw-gradient-to, rgba(44, 82, 130, 0));
  }

  .lg\:hover\:tw-via-blue-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2a4365, var(--tw-gradient-to, rgba(42, 67, 101, 0));
  }

  .lg\:focus\:tw-via-transparent:focus{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .lg\:focus\:tw-via-current:focus{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:focus\:tw-via-black:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2c2c2c, var(--tw-gradient-to, rgba(44, 44, 44, 0));
  }

  .lg\:focus\:tw-via-white:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:focus\:tw-via-gray-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f7fafc, var(--tw-gradient-to, rgba(247, 250, 252, 0));
  }

  .lg\:focus\:tw-via-gray-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #edf2f7, var(--tw-gradient-to, rgba(237, 242, 247, 0));
  }

  .lg\:focus\:tw-via-gray-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e2e8f0, var(--tw-gradient-to, rgba(226, 232, 240, 0));
  }

  .lg\:focus\:tw-via-gray-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #cbd5e0, var(--tw-gradient-to, rgba(203, 213, 224, 0));
  }

  .lg\:focus\:tw-via-gray-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a0aec0, var(--tw-gradient-to, rgba(160, 174, 192, 0));
  }

  .lg\:focus\:tw-via-gray-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #718096, var(--tw-gradient-to, rgba(113, 128, 150, 0));
  }

  .lg\:focus\:tw-via-gray-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4a5568, var(--tw-gradient-to, rgba(74, 85, 104, 0));
  }

  .lg\:focus\:tw-via-gray-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2d3748, var(--tw-gradient-to, rgba(45, 55, 72, 0));
  }

  .lg\:focus\:tw-via-gray-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1a202c, var(--tw-gradient-to, rgba(26, 32, 44, 0));
  }

  .lg\:focus\:tw-via-blue-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ebf8ff, var(--tw-gradient-to, rgba(235, 248, 255, 0));
  }

  .lg\:focus\:tw-via-blue-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #bee3f8, var(--tw-gradient-to, rgba(190, 227, 248, 0));
  }

  .lg\:focus\:tw-via-blue-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #90cdf4, var(--tw-gradient-to, rgba(144, 205, 244, 0));
  }

  .lg\:focus\:tw-via-blue-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #63b3ed, var(--tw-gradient-to, rgba(99, 179, 237, 0));
  }

  .lg\:focus\:tw-via-blue-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4299e1, var(--tw-gradient-to, rgba(66, 153, 225, 0));
  }

  .lg\:focus\:tw-via-blue-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3182ce, var(--tw-gradient-to, rgba(49, 130, 206, 0));
  }

  .lg\:focus\:tw-via-blue-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2b6cb0, var(--tw-gradient-to, rgba(43, 108, 176, 0));
  }

  .lg\:focus\:tw-via-blue-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2c5282, var(--tw-gradient-to, rgba(44, 82, 130, 0));
  }

  .lg\:focus\:tw-via-blue-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2a4365, var(--tw-gradient-to, rgba(42, 67, 101, 0));
  }

  .lg\:tw-to-transparent{
    --tw-gradient-to: transparent;
  }

  .lg\:tw-to-current{
    --tw-gradient-to: currentColor;
  }

  .lg\:tw-to-black{
    --tw-gradient-to: #2c2c2c;
  }

  .lg\:tw-to-white{
    --tw-gradient-to: #fff;
  }

  .lg\:tw-to-gray-100{
    --tw-gradient-to: #f7fafc;
  }

  .lg\:tw-to-gray-200{
    --tw-gradient-to: #edf2f7;
  }

  .lg\:tw-to-gray-300{
    --tw-gradient-to: #e2e8f0;
  }

  .lg\:tw-to-gray-400{
    --tw-gradient-to: #cbd5e0;
  }

  .lg\:tw-to-gray-500{
    --tw-gradient-to: #a0aec0;
  }

  .lg\:tw-to-gray-600{
    --tw-gradient-to: #718096;
  }

  .lg\:tw-to-gray-700{
    --tw-gradient-to: #4a5568;
  }

  .lg\:tw-to-gray-800{
    --tw-gradient-to: #2d3748;
  }

  .lg\:tw-to-gray-900{
    --tw-gradient-to: #1a202c;
  }

  .lg\:tw-to-blue-100{
    --tw-gradient-to: #ebf8ff;
  }

  .lg\:tw-to-blue-200{
    --tw-gradient-to: #bee3f8;
  }

  .lg\:tw-to-blue-300{
    --tw-gradient-to: #90cdf4;
  }

  .lg\:tw-to-blue-400{
    --tw-gradient-to: #63b3ed;
  }

  .lg\:tw-to-blue-500{
    --tw-gradient-to: #4299e1;
  }

  .lg\:tw-to-blue-600{
    --tw-gradient-to: #3182ce;
  }

  .lg\:tw-to-blue-700{
    --tw-gradient-to: #2b6cb0;
  }

  .lg\:tw-to-blue-800{
    --tw-gradient-to: #2c5282;
  }

  .lg\:tw-to-blue-900{
    --tw-gradient-to: #2a4365;
  }

  .lg\:hover\:tw-to-transparent:hover{
    --tw-gradient-to: transparent;
  }

  .lg\:hover\:tw-to-current:hover{
    --tw-gradient-to: currentColor;
  }

  .lg\:hover\:tw-to-black:hover{
    --tw-gradient-to: #2c2c2c;
  }

  .lg\:hover\:tw-to-white:hover{
    --tw-gradient-to: #fff;
  }

  .lg\:hover\:tw-to-gray-100:hover{
    --tw-gradient-to: #f7fafc;
  }

  .lg\:hover\:tw-to-gray-200:hover{
    --tw-gradient-to: #edf2f7;
  }

  .lg\:hover\:tw-to-gray-300:hover{
    --tw-gradient-to: #e2e8f0;
  }

  .lg\:hover\:tw-to-gray-400:hover{
    --tw-gradient-to: #cbd5e0;
  }

  .lg\:hover\:tw-to-gray-500:hover{
    --tw-gradient-to: #a0aec0;
  }

  .lg\:hover\:tw-to-gray-600:hover{
    --tw-gradient-to: #718096;
  }

  .lg\:hover\:tw-to-gray-700:hover{
    --tw-gradient-to: #4a5568;
  }

  .lg\:hover\:tw-to-gray-800:hover{
    --tw-gradient-to: #2d3748;
  }

  .lg\:hover\:tw-to-gray-900:hover{
    --tw-gradient-to: #1a202c;
  }

  .lg\:hover\:tw-to-blue-100:hover{
    --tw-gradient-to: #ebf8ff;
  }

  .lg\:hover\:tw-to-blue-200:hover{
    --tw-gradient-to: #bee3f8;
  }

  .lg\:hover\:tw-to-blue-300:hover{
    --tw-gradient-to: #90cdf4;
  }

  .lg\:hover\:tw-to-blue-400:hover{
    --tw-gradient-to: #63b3ed;
  }

  .lg\:hover\:tw-to-blue-500:hover{
    --tw-gradient-to: #4299e1;
  }

  .lg\:hover\:tw-to-blue-600:hover{
    --tw-gradient-to: #3182ce;
  }

  .lg\:hover\:tw-to-blue-700:hover{
    --tw-gradient-to: #2b6cb0;
  }

  .lg\:hover\:tw-to-blue-800:hover{
    --tw-gradient-to: #2c5282;
  }

  .lg\:hover\:tw-to-blue-900:hover{
    --tw-gradient-to: #2a4365;
  }

  .lg\:focus\:tw-to-transparent:focus{
    --tw-gradient-to: transparent;
  }

  .lg\:focus\:tw-to-current:focus{
    --tw-gradient-to: currentColor;
  }

  .lg\:focus\:tw-to-black:focus{
    --tw-gradient-to: #2c2c2c;
  }

  .lg\:focus\:tw-to-white:focus{
    --tw-gradient-to: #fff;
  }

  .lg\:focus\:tw-to-gray-100:focus{
    --tw-gradient-to: #f7fafc;
  }

  .lg\:focus\:tw-to-gray-200:focus{
    --tw-gradient-to: #edf2f7;
  }

  .lg\:focus\:tw-to-gray-300:focus{
    --tw-gradient-to: #e2e8f0;
  }

  .lg\:focus\:tw-to-gray-400:focus{
    --tw-gradient-to: #cbd5e0;
  }

  .lg\:focus\:tw-to-gray-500:focus{
    --tw-gradient-to: #a0aec0;
  }

  .lg\:focus\:tw-to-gray-600:focus{
    --tw-gradient-to: #718096;
  }

  .lg\:focus\:tw-to-gray-700:focus{
    --tw-gradient-to: #4a5568;
  }

  .lg\:focus\:tw-to-gray-800:focus{
    --tw-gradient-to: #2d3748;
  }

  .lg\:focus\:tw-to-gray-900:focus{
    --tw-gradient-to: #1a202c;
  }

  .lg\:focus\:tw-to-blue-100:focus{
    --tw-gradient-to: #ebf8ff;
  }

  .lg\:focus\:tw-to-blue-200:focus{
    --tw-gradient-to: #bee3f8;
  }

  .lg\:focus\:tw-to-blue-300:focus{
    --tw-gradient-to: #90cdf4;
  }

  .lg\:focus\:tw-to-blue-400:focus{
    --tw-gradient-to: #63b3ed;
  }

  .lg\:focus\:tw-to-blue-500:focus{
    --tw-gradient-to: #4299e1;
  }

  .lg\:focus\:tw-to-blue-600:focus{
    --tw-gradient-to: #3182ce;
  }

  .lg\:focus\:tw-to-blue-700:focus{
    --tw-gradient-to: #2b6cb0;
  }

  .lg\:focus\:tw-to-blue-800:focus{
    --tw-gradient-to: #2c5282;
  }

  .lg\:focus\:tw-to-blue-900:focus{
    --tw-gradient-to: #2a4365;
  }

  .lg\:tw-decoration-slice{
    -webkit-box-decoration-break: slice;
    box-decoration-break: slice;
  }

  .lg\:tw-decoration-clone{
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  .lg\:tw-bg-auto{
    background-size: auto;
  }

  .lg\:tw-bg-cover{
    background-size: cover;
  }

  .lg\:tw-bg-contain{
    background-size: contain;
  }

  .lg\:tw-bg-fixed{
    background-attachment: fixed;
  }

  .lg\:tw-bg-local{
    background-attachment: local;
  }

  .lg\:tw-bg-scroll{
    background-attachment: scroll;
  }

  .lg\:tw-bg-clip-border{
    background-clip: border-box;
  }

  .lg\:tw-bg-clip-padding{
    background-clip: padding-box;
  }

  .lg\:tw-bg-clip-content{
    background-clip: content-box;
  }

  .lg\:tw-bg-clip-text{
    -webkit-background-clip: text;
    background-clip: text;
  }

  .lg\:tw-bg-bottom{
    background-position: bottom;
  }

  .lg\:tw-bg-center{
    background-position: center;
  }

  .lg\:tw-bg-left{
    background-position: left;
  }

  .lg\:tw-bg-left-bottom{
    background-position: left bottom;
  }

  .lg\:tw-bg-left-top{
    background-position: left top;
  }

  .lg\:tw-bg-right{
    background-position: right;
  }

  .lg\:tw-bg-right-bottom{
    background-position: right bottom;
  }

  .lg\:tw-bg-right-top{
    background-position: right top;
  }

  .lg\:tw-bg-top{
    background-position: top;
  }

  .lg\:tw-bg-repeat{
    background-repeat: repeat;
  }

  .lg\:tw-bg-no-repeat{
    background-repeat: no-repeat;
  }

  .lg\:tw-bg-repeat-x{
    background-repeat: repeat-x;
  }

  .lg\:tw-bg-repeat-y{
    background-repeat: repeat-y;
  }

  .lg\:tw-bg-repeat-round{
    background-repeat: round;
  }

  .lg\:tw-bg-repeat-space{
    background-repeat: space;
  }

  .lg\:tw-bg-origin-border{
    background-origin: border-box;
  }

  .lg\:tw-bg-origin-padding{
    background-origin: padding-box;
  }

  .lg\:tw-bg-origin-content{
    background-origin: content-box;
  }

  .lg\:tw-fill-current{
    fill: currentColor;
  }

  .lg\:tw-stroke-current{
    stroke: currentColor;
  }

  .lg\:tw-stroke-0{
    stroke-width: 0;
  }

  .lg\:tw-stroke-1{
    stroke-width: 1;
  }

  .lg\:tw-stroke-2{
    stroke-width: 2;
  }

  .lg\:tw-object-contain{
    object-fit: contain;
  }

  .lg\:tw-object-cover{
    object-fit: cover;
  }

  .lg\:tw-object-fill{
    object-fit: fill;
  }

  .lg\:tw-object-none{
    object-fit: none;
  }

  .lg\:tw-object-scale-down{
    object-fit: scale-down;
  }

  .lg\:tw-object-bottom{
    object-position: bottom;
  }

  .lg\:tw-object-center{
    object-position: center;
  }

  .lg\:tw-object-left{
    object-position: left;
  }

  .lg\:tw-object-left-bottom{
    object-position: left bottom;
  }

  .lg\:tw-object-left-top{
    object-position: left top;
  }

  .lg\:tw-object-right{
    object-position: right;
  }

  .lg\:tw-object-right-bottom{
    object-position: right bottom;
  }

  .lg\:tw-object-right-top{
    object-position: right top;
  }

  .lg\:tw-object-top{
    object-position: top;
  }

  .lg\:tw-p-0{
    padding: 0;
  }

  .lg\:tw-p-1{
    padding: 0.25rem;
  }

  .lg\:tw-p-2{
    padding: 0.5rem;
  }

  .lg\:tw-p-3{
    padding: 0.75rem;
  }

  .lg\:tw-p-4{
    padding: 1rem;
  }

  .lg\:tw-p-5{
    padding: 1.25rem;
  }

  .lg\:tw-p-6{
    padding: 1.5rem;
  }

  .lg\:tw-p-8{
    padding: 2rem;
  }

  .lg\:tw-p-10{
    padding: 2.5rem;
  }

  .lg\:tw-p-12{
    padding: 3rem;
  }

  .lg\:tw-p-16{
    padding: 4rem;
  }

  .lg\:tw-p-20{
    padding: 5rem;
  }

  .lg\:tw-p-24{
    padding: 6rem;
  }

  .lg\:tw-p-32{
    padding: 8rem;
  }

  .lg\:tw-p-40{
    padding: 10rem;
  }

  .lg\:tw-p-48{
    padding: 12rem;
  }

  .lg\:tw-p-56{
    padding: 14rem;
  }

  .lg\:tw-p-64{
    padding: 16rem;
  }

  .lg\:tw-p-px{
    padding: 1px;
  }

  .lg\:tw-px-0{
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:tw-px-1{
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .lg\:tw-px-2{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .lg\:tw-px-3{
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .lg\:tw-px-4{
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:tw-px-5{
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg\:tw-px-6{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:tw-px-8{
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:tw-px-10{
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:tw-px-12{
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .lg\:tw-px-16{
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg\:tw-px-20{
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .lg\:tw-px-24{
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .lg\:tw-px-32{
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .lg\:tw-px-40{
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .lg\:tw-px-48{
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .lg\:tw-px-56{
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .lg\:tw-px-64{
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .lg\:tw-px-px{
    padding-left: 1px;
    padding-right: 1px;
  }

  .lg\:tw-py-0{
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:tw-py-1{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .lg\:tw-py-2{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .lg\:tw-py-3{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .lg\:tw-py-4{
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .lg\:tw-py-5{
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .lg\:tw-py-6{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:tw-py-8{
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:tw-py-10{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .lg\:tw-py-12{
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .lg\:tw-py-16{
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg\:tw-py-20{
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .lg\:tw-py-24{
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .lg\:tw-py-32{
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .lg\:tw-py-40{
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .lg\:tw-py-48{
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .lg\:tw-py-56{
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .lg\:tw-py-64{
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .lg\:tw-py-px{
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .lg\:tw-pt-0{
    padding-top: 0;
  }

  .lg\:tw-pt-1{
    padding-top: 0.25rem;
  }

  .lg\:tw-pt-2{
    padding-top: 0.5rem;
  }

  .lg\:tw-pt-3{
    padding-top: 0.75rem;
  }

  .lg\:tw-pt-4{
    padding-top: 1rem;
  }

  .lg\:tw-pt-5{
    padding-top: 1.25rem;
  }

  .lg\:tw-pt-6{
    padding-top: 1.5rem;
  }

  .lg\:tw-pt-8{
    padding-top: 2rem;
  }

  .lg\:tw-pt-10{
    padding-top: 2.5rem;
  }

  .lg\:tw-pt-12{
    padding-top: 3rem;
  }

  .lg\:tw-pt-16{
    padding-top: 4rem;
  }

  .lg\:tw-pt-20{
    padding-top: 5rem;
  }

  .lg\:tw-pt-24{
    padding-top: 6rem;
  }

  .lg\:tw-pt-32{
    padding-top: 8rem;
  }

  .lg\:tw-pt-40{
    padding-top: 10rem;
  }

  .lg\:tw-pt-48{
    padding-top: 12rem;
  }

  .lg\:tw-pt-56{
    padding-top: 14rem;
  }

  .lg\:tw-pt-64{
    padding-top: 16rem;
  }

  .lg\:tw-pt-px{
    padding-top: 1px;
  }

  .lg\:tw-pr-0{
    padding-right: 0;
  }

  .lg\:tw-pr-1{
    padding-right: 0.25rem;
  }

  .lg\:tw-pr-2{
    padding-right: 0.5rem;
  }

  .lg\:tw-pr-3{
    padding-right: 0.75rem;
  }

  .lg\:tw-pr-4{
    padding-right: 1rem;
  }

  .lg\:tw-pr-5{
    padding-right: 1.25rem;
  }

  .lg\:tw-pr-6{
    padding-right: 1.5rem;
  }

  .lg\:tw-pr-8{
    padding-right: 2rem;
  }

  .lg\:tw-pr-10{
    padding-right: 2.5rem;
  }

  .lg\:tw-pr-12{
    padding-right: 3rem;
  }

  .lg\:tw-pr-16{
    padding-right: 4rem;
  }

  .lg\:tw-pr-20{
    padding-right: 5rem;
  }

  .lg\:tw-pr-24{
    padding-right: 6rem;
  }

  .lg\:tw-pr-32{
    padding-right: 8rem;
  }

  .lg\:tw-pr-40{
    padding-right: 10rem;
  }

  .lg\:tw-pr-48{
    padding-right: 12rem;
  }

  .lg\:tw-pr-56{
    padding-right: 14rem;
  }

  .lg\:tw-pr-64{
    padding-right: 16rem;
  }

  .lg\:tw-pr-px{
    padding-right: 1px;
  }

  .lg\:tw-pb-0{
    padding-bottom: 0;
  }

  .lg\:tw-pb-1{
    padding-bottom: 0.25rem;
  }

  .lg\:tw-pb-2{
    padding-bottom: 0.5rem;
  }

  .lg\:tw-pb-3{
    padding-bottom: 0.75rem;
  }

  .lg\:tw-pb-4{
    padding-bottom: 1rem;
  }

  .lg\:tw-pb-5{
    padding-bottom: 1.25rem;
  }

  .lg\:tw-pb-6{
    padding-bottom: 1.5rem;
  }

  .lg\:tw-pb-8{
    padding-bottom: 2rem;
  }

  .lg\:tw-pb-10{
    padding-bottom: 2.5rem;
  }

  .lg\:tw-pb-12{
    padding-bottom: 3rem;
  }

  .lg\:tw-pb-16{
    padding-bottom: 4rem;
  }

  .lg\:tw-pb-20{
    padding-bottom: 5rem;
  }

  .lg\:tw-pb-24{
    padding-bottom: 6rem;
  }

  .lg\:tw-pb-32{
    padding-bottom: 8rem;
  }

  .lg\:tw-pb-40{
    padding-bottom: 10rem;
  }

  .lg\:tw-pb-48{
    padding-bottom: 12rem;
  }

  .lg\:tw-pb-56{
    padding-bottom: 14rem;
  }

  .lg\:tw-pb-64{
    padding-bottom: 16rem;
  }

  .lg\:tw-pb-px{
    padding-bottom: 1px;
  }

  .lg\:tw-pl-0{
    padding-left: 0;
  }

  .lg\:tw-pl-1{
    padding-left: 0.25rem;
  }

  .lg\:tw-pl-2{
    padding-left: 0.5rem;
  }

  .lg\:tw-pl-3{
    padding-left: 0.75rem;
  }

  .lg\:tw-pl-4{
    padding-left: 1rem;
  }

  .lg\:tw-pl-5{
    padding-left: 1.25rem;
  }

  .lg\:tw-pl-6{
    padding-left: 1.5rem;
  }

  .lg\:tw-pl-8{
    padding-left: 2rem;
  }

  .lg\:tw-pl-10{
    padding-left: 2.5rem;
  }

  .lg\:tw-pl-12{
    padding-left: 3rem;
  }

  .lg\:tw-pl-16{
    padding-left: 4rem;
  }

  .lg\:tw-pl-20{
    padding-left: 5rem;
  }

  .lg\:tw-pl-24{
    padding-left: 6rem;
  }

  .lg\:tw-pl-32{
    padding-left: 8rem;
  }

  .lg\:tw-pl-40{
    padding-left: 10rem;
  }

  .lg\:tw-pl-48{
    padding-left: 12rem;
  }

  .lg\:tw-pl-56{
    padding-left: 14rem;
  }

  .lg\:tw-pl-64{
    padding-left: 16rem;
  }

  .lg\:tw-pl-px{
    padding-left: 1px;
  }

  .lg\:tw-text-left{
    text-align: left;
  }

  .lg\:tw-text-center{
    text-align: center;
  }

  .lg\:tw-text-right{
    text-align: right;
  }

  .lg\:tw-text-justify{
    text-align: justify;
  }

  .lg\:tw-align-baseline{
    vertical-align: baseline;
  }

  .lg\:tw-align-top{
    vertical-align: top;
  }

  .lg\:tw-align-middle{
    vertical-align: middle;
  }

  .lg\:tw-align-bottom{
    vertical-align: bottom;
  }

  .lg\:tw-align-text-top{
    vertical-align: text-top;
  }

  .lg\:tw-align-text-bottom{
    vertical-align: text-bottom;
  }

  .lg\:tw-font-sans{
    font-family: "Gotham SSm A", "Gotham SSm B", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  .lg\:tw-font-serif{
    font-family: Georgia, Cambria, "Times New Roman", Times, serif;
  }

  .lg\:tw-font-mono{
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

  .lg\:tw-text-xs{
    font-size: 0.75rem;
  }

  .lg\:tw-text-sm{
    font-size: 0.875rem;
  }

  .lg\:tw-text-base{
    font-size: 1rem;
  }

  .lg\:tw-text-lg{
    font-size: 1.125rem;
  }

  .lg\:tw-text-xl{
    font-size: 1.25rem;
  }

  .lg\:tw-text-2xl{
    font-size: 1.5rem;
  }

  .lg\:tw-text-3xl{
    font-size: 1.875rem;
  }

  .lg\:tw-text-4xl{
    font-size: 2.25rem;
  }

  .lg\:tw-text-5xl{
    font-size: 3rem;
  }

  .lg\:tw-text-6xl{
    font-size: 4rem;
  }

  .lg\:tw-font-hairline{
    font-weight: 100;
  }

  .lg\:tw-font-thin{
    font-weight: 200;
  }

  .lg\:tw-font-light{
    font-weight: 300;
  }

  .lg\:tw-font-normal{
    font-weight: 400;
  }

  .lg\:tw-font-medium{
    font-weight: 500;
  }

  .lg\:tw-font-semibold{
    font-weight: 600;
  }

  .lg\:tw-font-bold{
    font-weight: 700;
  }

  .lg\:tw-font-extrabold{
    font-weight: 800;
  }

  .lg\:tw-font-black{
    font-weight: 900;
  }

  .lg\:hover\:tw-font-hairline:hover{
    font-weight: 100;
  }

  .lg\:hover\:tw-font-thin:hover{
    font-weight: 200;
  }

  .lg\:hover\:tw-font-light:hover{
    font-weight: 300;
  }

  .lg\:hover\:tw-font-normal:hover{
    font-weight: 400;
  }

  .lg\:hover\:tw-font-medium:hover{
    font-weight: 500;
  }

  .lg\:hover\:tw-font-semibold:hover{
    font-weight: 600;
  }

  .lg\:hover\:tw-font-bold:hover{
    font-weight: 700;
  }

  .lg\:hover\:tw-font-extrabold:hover{
    font-weight: 800;
  }

  .lg\:hover\:tw-font-black:hover{
    font-weight: 900;
  }

  .lg\:focus\:tw-font-hairline:focus{
    font-weight: 100;
  }

  .lg\:focus\:tw-font-thin:focus{
    font-weight: 200;
  }

  .lg\:focus\:tw-font-light:focus{
    font-weight: 300;
  }

  .lg\:focus\:tw-font-normal:focus{
    font-weight: 400;
  }

  .lg\:focus\:tw-font-medium:focus{
    font-weight: 500;
  }

  .lg\:focus\:tw-font-semibold:focus{
    font-weight: 600;
  }

  .lg\:focus\:tw-font-bold:focus{
    font-weight: 700;
  }

  .lg\:focus\:tw-font-extrabold:focus{
    font-weight: 800;
  }

  .lg\:focus\:tw-font-black:focus{
    font-weight: 900;
  }

  .lg\:tw-uppercase{
    text-transform: uppercase;
  }

  .lg\:tw-lowercase{
    text-transform: lowercase;
  }

  .lg\:tw-capitalize{
    text-transform: capitalize;
  }

  .lg\:tw-normal-case{
    text-transform: none;
  }

  .lg\:tw-italic{
    font-style: italic;
  }

  .lg\:tw-not-italic{
    font-style: normal;
  }

  .lg\:tw-ordinal, .lg\:tw-slashed-zero, .lg\:tw-lining-nums, .lg\:tw-oldstyle-nums, .lg\:tw-proportional-nums, .lg\:tw-tabular-nums, .lg\:tw-diagonal-fractions, .lg\:tw-stacked-fractions{
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .lg\:tw-normal-nums{
    font-variant-numeric: normal;
  }

  .lg\:tw-ordinal{
    --tw-ordinal: ordinal;
  }

  .lg\:tw-slashed-zero{
    --tw-slashed-zero: slashed-zero;
  }

  .lg\:tw-lining-nums{
    --tw-numeric-figure: lining-nums;
  }

  .lg\:tw-oldstyle-nums{
    --tw-numeric-figure: oldstyle-nums;
  }

  .lg\:tw-proportional-nums{
    --tw-numeric-spacing: proportional-nums;
  }

  .lg\:tw-tabular-nums{
    --tw-numeric-spacing: tabular-nums;
  }

  .lg\:tw-diagonal-fractions{
    --tw-numeric-fraction: diagonal-fractions;
  }

  .lg\:tw-stacked-fractions{
    --tw-numeric-fraction: stacked-fractions;
  }

  .lg\:tw-leading-3{
    line-height: .75rem;
  }

  .lg\:tw-leading-4{
    line-height: 1rem;
  }

  .lg\:tw-leading-5{
    line-height: 1.25rem;
  }

  .lg\:tw-leading-6{
    line-height: 1.5rem;
  }

  .lg\:tw-leading-7{
    line-height: 1.75rem;
  }

  .lg\:tw-leading-8{
    line-height: 2rem;
  }

  .lg\:tw-leading-9{
    line-height: 2.25rem;
  }

  .lg\:tw-leading-10{
    line-height: 2.5rem;
  }

  .lg\:tw-leading-none{
    line-height: 1;
  }

  .lg\:tw-leading-tight{
    line-height: 1.25;
  }

  .lg\:tw-leading-snug{
    line-height: 1.375;
  }

  .lg\:tw-leading-normal{
    line-height: 1.5;
  }

  .lg\:tw-leading-relaxed{
    line-height: 1.625;
  }

  .lg\:tw-leading-loose{
    line-height: 2;
  }

  .lg\:tw-tracking-tighter{
    letter-spacing: -0.05em;
  }

  .lg\:tw-tracking-tight{
    letter-spacing: -0.025em;
  }

  .lg\:tw-tracking-normal{
    letter-spacing: 0;
  }

  .lg\:tw-tracking-wide{
    letter-spacing: 0.025em;
  }

  .lg\:tw-tracking-wider{
    letter-spacing: 0.05em;
  }

  .lg\:tw-tracking-widest{
    letter-spacing: 0.1em;
  }

  .lg\:tw-text-transparent{
    color: transparent;
  }

  .lg\:tw-text-current{
    color: currentColor;
  }

  .lg\:tw-text-black{
    --tw-text-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-text-opacity));
  }

  .lg\:tw-text-white{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .lg\:tw-text-gray-100{
    --tw-text-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-text-opacity));
  }

  .lg\:tw-text-gray-200{
    --tw-text-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-text-opacity));
  }

  .lg\:tw-text-gray-300{
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));
  }

  .lg\:tw-text-gray-400{
    --tw-text-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-text-opacity));
  }

  .lg\:tw-text-gray-500{
    --tw-text-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-text-opacity));
  }

  .lg\:tw-text-gray-600{
    --tw-text-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-text-opacity));
  }

  .lg\:tw-text-gray-700{
    --tw-text-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-text-opacity));
  }

  .lg\:tw-text-gray-800{
    --tw-text-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-text-opacity));
  }

  .lg\:tw-text-gray-900{
    --tw-text-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-text-opacity));
  }

  .lg\:tw-text-blue-100{
    --tw-text-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-text-opacity));
  }

  .lg\:tw-text-blue-200{
    --tw-text-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-text-opacity));
  }

  .lg\:tw-text-blue-300{
    --tw-text-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-text-opacity));
  }

  .lg\:tw-text-blue-400{
    --tw-text-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-text-opacity));
  }

  .lg\:tw-text-blue-500{
    --tw-text-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-text-opacity));
  }

  .lg\:tw-text-blue-600{
    --tw-text-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-text-opacity));
  }

  .lg\:tw-text-blue-700{
    --tw-text-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-text-opacity));
  }

  .lg\:tw-text-blue-800{
    --tw-text-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-text-opacity));
  }

  .lg\:tw-text-blue-900{
    --tw-text-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-text-opacity));
  }

  .lg\:hover\:tw-text-transparent:hover{
    color: transparent;
  }

  .lg\:hover\:tw-text-current:hover{
    color: currentColor;
  }

  .lg\:hover\:tw-text-black:hover{
    --tw-text-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-text-opacity));
  }

  .lg\:hover\:tw-text-white:hover{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .lg\:hover\:tw-text-gray-100:hover{
    --tw-text-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-text-opacity));
  }

  .lg\:hover\:tw-text-gray-200:hover{
    --tw-text-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-text-opacity));
  }

  .lg\:hover\:tw-text-gray-300:hover{
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));
  }

  .lg\:hover\:tw-text-gray-400:hover{
    --tw-text-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-text-opacity));
  }

  .lg\:hover\:tw-text-gray-500:hover{
    --tw-text-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-text-opacity));
  }

  .lg\:hover\:tw-text-gray-600:hover{
    --tw-text-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-text-opacity));
  }

  .lg\:hover\:tw-text-gray-700:hover{
    --tw-text-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-text-opacity));
  }

  .lg\:hover\:tw-text-gray-800:hover{
    --tw-text-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-text-opacity));
  }

  .lg\:hover\:tw-text-gray-900:hover{
    --tw-text-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-text-opacity));
  }

  .lg\:hover\:tw-text-blue-100:hover{
    --tw-text-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-text-opacity));
  }

  .lg\:hover\:tw-text-blue-200:hover{
    --tw-text-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-text-opacity));
  }

  .lg\:hover\:tw-text-blue-300:hover{
    --tw-text-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-text-opacity));
  }

  .lg\:hover\:tw-text-blue-400:hover{
    --tw-text-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-text-opacity));
  }

  .lg\:hover\:tw-text-blue-500:hover{
    --tw-text-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-text-opacity));
  }

  .lg\:hover\:tw-text-blue-600:hover{
    --tw-text-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-text-opacity));
  }

  .lg\:hover\:tw-text-blue-700:hover{
    --tw-text-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-text-opacity));
  }

  .lg\:hover\:tw-text-blue-800:hover{
    --tw-text-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-text-opacity));
  }

  .lg\:hover\:tw-text-blue-900:hover{
    --tw-text-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-text-opacity));
  }

  .lg\:focus\:tw-text-transparent:focus{
    color: transparent;
  }

  .lg\:focus\:tw-text-current:focus{
    color: currentColor;
  }

  .lg\:focus\:tw-text-black:focus{
    --tw-text-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-text-opacity));
  }

  .lg\:focus\:tw-text-white:focus{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .lg\:focus\:tw-text-gray-100:focus{
    --tw-text-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-text-opacity));
  }

  .lg\:focus\:tw-text-gray-200:focus{
    --tw-text-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-text-opacity));
  }

  .lg\:focus\:tw-text-gray-300:focus{
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));
  }

  .lg\:focus\:tw-text-gray-400:focus{
    --tw-text-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-text-opacity));
  }

  .lg\:focus\:tw-text-gray-500:focus{
    --tw-text-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-text-opacity));
  }

  .lg\:focus\:tw-text-gray-600:focus{
    --tw-text-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-text-opacity));
  }

  .lg\:focus\:tw-text-gray-700:focus{
    --tw-text-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-text-opacity));
  }

  .lg\:focus\:tw-text-gray-800:focus{
    --tw-text-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-text-opacity));
  }

  .lg\:focus\:tw-text-gray-900:focus{
    --tw-text-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-text-opacity));
  }

  .lg\:focus\:tw-text-blue-100:focus{
    --tw-text-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-text-opacity));
  }

  .lg\:focus\:tw-text-blue-200:focus{
    --tw-text-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-text-opacity));
  }

  .lg\:focus\:tw-text-blue-300:focus{
    --tw-text-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-text-opacity));
  }

  .lg\:focus\:tw-text-blue-400:focus{
    --tw-text-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-text-opacity));
  }

  .lg\:focus\:tw-text-blue-500:focus{
    --tw-text-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-text-opacity));
  }

  .lg\:focus\:tw-text-blue-600:focus{
    --tw-text-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-text-opacity));
  }

  .lg\:focus\:tw-text-blue-700:focus{
    --tw-text-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-text-opacity));
  }

  .lg\:focus\:tw-text-blue-800:focus{
    --tw-text-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-text-opacity));
  }

  .lg\:focus\:tw-text-blue-900:focus{
    --tw-text-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-text-opacity));
  }

  .lg\:tw-text-opacity-0{
    --tw-text-opacity: 0;
  }

  .lg\:tw-text-opacity-25{
    --tw-text-opacity: 0.25;
  }

  .lg\:tw-text-opacity-50{
    --tw-text-opacity: 0.5;
  }

  .lg\:tw-text-opacity-75{
    --tw-text-opacity: 0.75;
  }

  .lg\:tw-text-opacity-90{
    --tw-text-opacity: 0.9;
  }

  .lg\:tw-text-opacity-100{
    --tw-text-opacity: 1;
  }

  .lg\:hover\:tw-text-opacity-0:hover{
    --tw-text-opacity: 0;
  }

  .lg\:hover\:tw-text-opacity-25:hover{
    --tw-text-opacity: 0.25;
  }

  .lg\:hover\:tw-text-opacity-50:hover{
    --tw-text-opacity: 0.5;
  }

  .lg\:hover\:tw-text-opacity-75:hover{
    --tw-text-opacity: 0.75;
  }

  .lg\:hover\:tw-text-opacity-90:hover{
    --tw-text-opacity: 0.9;
  }

  .lg\:hover\:tw-text-opacity-100:hover{
    --tw-text-opacity: 1;
  }

  .lg\:focus\:tw-text-opacity-0:focus{
    --tw-text-opacity: 0;
  }

  .lg\:focus\:tw-text-opacity-25:focus{
    --tw-text-opacity: 0.25;
  }

  .lg\:focus\:tw-text-opacity-50:focus{
    --tw-text-opacity: 0.5;
  }

  .lg\:focus\:tw-text-opacity-75:focus{
    --tw-text-opacity: 0.75;
  }

  .lg\:focus\:tw-text-opacity-90:focus{
    --tw-text-opacity: 0.9;
  }

  .lg\:focus\:tw-text-opacity-100:focus{
    --tw-text-opacity: 1;
  }

  .lg\:tw-underline{
    text-decoration: underline;
  }

  .lg\:tw-line-through{
    text-decoration: line-through;
  }

  .lg\:tw-no-underline{
    text-decoration: none;
  }

  .lg\:hover\:tw-underline:hover{
    text-decoration: underline;
  }

  .lg\:hover\:tw-line-through:hover{
    text-decoration: line-through;
  }

  .lg\:hover\:tw-no-underline:hover{
    text-decoration: none;
  }

  .lg\:focus\:tw-underline:focus{
    text-decoration: underline;
  }

  .lg\:focus\:tw-line-through:focus{
    text-decoration: line-through;
  }

  .lg\:focus\:tw-no-underline:focus{
    text-decoration: none;
  }

  .lg\:tw-antialiased{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .lg\:tw-subpixel-antialiased{
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .lg\:tw-placeholder-transparent::-webkit-input-placeholder{
    color: transparent;
  }

  .lg\:tw-placeholder-transparent::-ms-input-placeholder{
    color: transparent;
  }

  .lg\:tw-placeholder-transparent::placeholder{
    color: transparent;
  }

  .lg\:tw-placeholder-current::-webkit-input-placeholder{
    color: currentColor;
  }

  .lg\:tw-placeholder-current::-ms-input-placeholder{
    color: currentColor;
  }

  .lg\:tw-placeholder-current::placeholder{
    color: currentColor;
  }

  .lg\:tw-placeholder-black::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-black::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-black::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-white::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-white::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-white::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-100::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-200::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-300::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-400::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-500::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-600::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-700::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-800::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-900::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-100::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-200::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-300::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-400::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-500::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-600::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-700::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-800::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-900::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-transparent:focus::-webkit-input-placeholder{
    color: transparent;
  }

  .lg\:focus\:tw-placeholder-transparent:focus::-ms-input-placeholder{
    color: transparent;
  }

  .lg\:focus\:tw-placeholder-transparent:focus::placeholder{
    color: transparent;
  }

  .lg\:focus\:tw-placeholder-current:focus::-webkit-input-placeholder{
    color: currentColor;
  }

  .lg\:focus\:tw-placeholder-current:focus::-ms-input-placeholder{
    color: currentColor;
  }

  .lg\:focus\:tw-placeholder-current:focus::placeholder{
    color: currentColor;
  }

  .lg\:focus\:tw-placeholder-black:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-black:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-black:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-white:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-white:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-white:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-100:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-200:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-300:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-400:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-500:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-600:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-700:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-800:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-900:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-100:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-200:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-300:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-400:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-500:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-600:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-700:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-800:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-900:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
  }

  .lg\:tw-placeholder-opacity-0::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0;
  }

  .lg\:tw-placeholder-opacity-0::-ms-input-placeholder{
    --tw-placeholder-opacity: 0;
  }

  .lg\:tw-placeholder-opacity-0::placeholder{
    --tw-placeholder-opacity: 0;
  }

  .lg\:tw-placeholder-opacity-25::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.25;
  }

  .lg\:tw-placeholder-opacity-25::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.25;
  }

  .lg\:tw-placeholder-opacity-25::placeholder{
    --tw-placeholder-opacity: 0.25;
  }

  .lg\:tw-placeholder-opacity-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.5;
  }

  .lg\:tw-placeholder-opacity-50::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.5;
  }

  .lg\:tw-placeholder-opacity-50::placeholder{
    --tw-placeholder-opacity: 0.5;
  }

  .lg\:tw-placeholder-opacity-75::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.75;
  }

  .lg\:tw-placeholder-opacity-75::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.75;
  }

  .lg\:tw-placeholder-opacity-75::placeholder{
    --tw-placeholder-opacity: 0.75;
  }

  .lg\:tw-placeholder-opacity-90::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.9;
  }

  .lg\:tw-placeholder-opacity-90::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.9;
  }

  .lg\:tw-placeholder-opacity-90::placeholder{
    --tw-placeholder-opacity: 0.9;
  }

  .lg\:tw-placeholder-opacity-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
  }

  .lg\:tw-placeholder-opacity-100::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
  }

  .lg\:tw-placeholder-opacity-100::placeholder{
    --tw-placeholder-opacity: 1;
  }

  .lg\:focus\:tw-placeholder-opacity-0:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0;
  }

  .lg\:focus\:tw-placeholder-opacity-0:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 0;
  }

  .lg\:focus\:tw-placeholder-opacity-0:focus::placeholder{
    --tw-placeholder-opacity: 0;
  }

  .lg\:focus\:tw-placeholder-opacity-25:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.25;
  }

  .lg\:focus\:tw-placeholder-opacity-25:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.25;
  }

  .lg\:focus\:tw-placeholder-opacity-25:focus::placeholder{
    --tw-placeholder-opacity: 0.25;
  }

  .lg\:focus\:tw-placeholder-opacity-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.5;
  }

  .lg\:focus\:tw-placeholder-opacity-50:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.5;
  }

  .lg\:focus\:tw-placeholder-opacity-50:focus::placeholder{
    --tw-placeholder-opacity: 0.5;
  }

  .lg\:focus\:tw-placeholder-opacity-75:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.75;
  }

  .lg\:focus\:tw-placeholder-opacity-75:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.75;
  }

  .lg\:focus\:tw-placeholder-opacity-75:focus::placeholder{
    --tw-placeholder-opacity: 0.75;
  }

  .lg\:focus\:tw-placeholder-opacity-90:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.9;
  }

  .lg\:focus\:tw-placeholder-opacity-90:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.9;
  }

  .lg\:focus\:tw-placeholder-opacity-90:focus::placeholder{
    --tw-placeholder-opacity: 0.9;
  }

  .lg\:focus\:tw-placeholder-opacity-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
  }

  .lg\:focus\:tw-placeholder-opacity-100:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
  }

  .lg\:focus\:tw-placeholder-opacity-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
  }

  .lg\:tw-opacity-0{
    opacity: 0;
  }

  .lg\:tw-opacity-25{
    opacity: 0.25;
  }

  .lg\:tw-opacity-50{
    opacity: 0.5;
  }

  .lg\:tw-opacity-75{
    opacity: 0.75;
  }

  .lg\:tw-opacity-90{
    opacity: 0.9;
  }

  .lg\:tw-opacity-100{
    opacity: 1;
  }

  .lg\:hover\:tw-opacity-0:hover{
    opacity: 0;
  }

  .lg\:hover\:tw-opacity-25:hover{
    opacity: 0.25;
  }

  .lg\:hover\:tw-opacity-50:hover{
    opacity: 0.5;
  }

  .lg\:hover\:tw-opacity-75:hover{
    opacity: 0.75;
  }

  .lg\:hover\:tw-opacity-90:hover{
    opacity: 0.9;
  }

  .lg\:hover\:tw-opacity-100:hover{
    opacity: 1;
  }

  .lg\:focus\:tw-opacity-0:focus{
    opacity: 0;
  }

  .lg\:focus\:tw-opacity-25:focus{
    opacity: 0.25;
  }

  .lg\:focus\:tw-opacity-50:focus{
    opacity: 0.5;
  }

  .lg\:focus\:tw-opacity-75:focus{
    opacity: 0.75;
  }

  .lg\:focus\:tw-opacity-90:focus{
    opacity: 0.9;
  }

  .lg\:focus\:tw-opacity-100:focus{
    opacity: 1;
  }

  .lg\:tw-bg-blend-normal{
    background-blend-mode: normal;
  }

  .lg\:tw-bg-blend-multiply{
    background-blend-mode: multiply;
  }

  .lg\:tw-bg-blend-screen{
    background-blend-mode: screen;
  }

  .lg\:tw-bg-blend-overlay{
    background-blend-mode: overlay;
  }

  .lg\:tw-bg-blend-darken{
    background-blend-mode: darken;
  }

  .lg\:tw-bg-blend-lighten{
    background-blend-mode: lighten;
  }

  .lg\:tw-bg-blend-color-dodge{
    background-blend-mode: color-dodge;
  }

  .lg\:tw-bg-blend-color-burn{
    background-blend-mode: color-burn;
  }

  .lg\:tw-bg-blend-hard-light{
    background-blend-mode: hard-light;
  }

  .lg\:tw-bg-blend-soft-light{
    background-blend-mode: soft-light;
  }

  .lg\:tw-bg-blend-difference{
    background-blend-mode: difference;
  }

  .lg\:tw-bg-blend-exclusion{
    background-blend-mode: exclusion;
  }

  .lg\:tw-bg-blend-hue{
    background-blend-mode: hue;
  }

  .lg\:tw-bg-blend-saturation{
    background-blend-mode: saturation;
  }

  .lg\:tw-bg-blend-color{
    background-blend-mode: color;
  }

  .lg\:tw-bg-blend-luminosity{
    background-blend-mode: luminosity;
  }

  .lg\:tw-mix-blend-normal{
    mix-blend-mode: normal;
  }

  .lg\:tw-mix-blend-multiply{
    mix-blend-mode: multiply;
  }

  .lg\:tw-mix-blend-screen{
    mix-blend-mode: screen;
  }

  .lg\:tw-mix-blend-overlay{
    mix-blend-mode: overlay;
  }

  .lg\:tw-mix-blend-darken{
    mix-blend-mode: darken;
  }

  .lg\:tw-mix-blend-lighten{
    mix-blend-mode: lighten;
  }

  .lg\:tw-mix-blend-color-dodge{
    mix-blend-mode: color-dodge;
  }

  .lg\:tw-mix-blend-color-burn{
    mix-blend-mode: color-burn;
  }

  .lg\:tw-mix-blend-hard-light{
    mix-blend-mode: hard-light;
  }

  .lg\:tw-mix-blend-soft-light{
    mix-blend-mode: soft-light;
  }

  .lg\:tw-mix-blend-difference{
    mix-blend-mode: difference;
  }

  .lg\:tw-mix-blend-exclusion{
    mix-blend-mode: exclusion;
  }

  .lg\:tw-mix-blend-hue{
    mix-blend-mode: hue;
  }

  .lg\:tw-mix-blend-saturation{
    mix-blend-mode: saturation;
  }

  .lg\:tw-mix-blend-color{
    mix-blend-mode: color;
  }

  .lg\:tw-mix-blend-luminosity{
    mix-blend-mode: luminosity;
  }

  .lg\:tw-shadow-xs{
    --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:tw-shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:tw-shadow-default{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:tw-shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:tw-shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:tw-shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:tw-shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:tw-shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:tw-shadow-outline{
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:tw-shadow-none{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:tw-shadow-xs:hover{
    --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:tw-shadow-sm:hover{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:tw-shadow-default:hover{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:tw-shadow-md:hover{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:tw-shadow-lg:hover{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:tw-shadow-xl:hover{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:tw-shadow-2xl:hover{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:tw-shadow-inner:hover{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:tw-shadow-outline:hover{
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:tw-shadow-none:hover{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:tw-shadow-xs:focus{
    --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:tw-shadow-sm:focus{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:tw-shadow-default:focus{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:tw-shadow-md:focus{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:tw-shadow-lg:focus{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:tw-shadow-xl:focus{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:tw-shadow-2xl:focus{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:tw-shadow-inner:focus{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:tw-shadow-outline:focus{
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:tw-shadow-none:focus{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:tw-outline-none{
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .lg\:tw-outline-white{
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .lg\:tw-outline-black{
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .lg\:focus\:tw-outline-none:focus{
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .lg\:focus\:tw-outline-white:focus{
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .lg\:focus\:tw-outline-black:focus{
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .lg\:tw-ring-0{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:tw-ring-1{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:tw-ring-2{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:tw-ring-4{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:tw-ring-8{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:tw-ring{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus-within\:tw-ring-0:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus-within\:tw-ring-1:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus-within\:tw-ring-2:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus-within\:tw-ring-4:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus-within\:tw-ring-8:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus-within\:tw-ring:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus\:tw-ring-0:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus\:tw-ring-1:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus\:tw-ring-2:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus\:tw-ring-4:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus\:tw-ring-8:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus\:tw-ring:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:tw-ring-inset{
    --tw-ring-inset: inset;
  }

  .lg\:focus-within\:tw-ring-inset:focus-within{
    --tw-ring-inset: inset;
  }

  .lg\:focus\:tw-ring-inset:focus{
    --tw-ring-inset: inset;
  }

  .lg\:tw-ring-transparent{
    --tw-ring-color: transparent;
  }

  .lg\:tw-ring-current{
    --tw-ring-color: currentColor;
  }

  .lg\:tw-ring-black{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(44, 44, 44, var(--tw-ring-opacity));
  }

  .lg\:tw-ring-white{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .lg\:tw-ring-gray-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 250, 252, var(--tw-ring-opacity));
  }

  .lg\:tw-ring-gray-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 242, 247, var(--tw-ring-opacity));
  }

  .lg\:tw-ring-gray-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(226, 232, 240, var(--tw-ring-opacity));
  }

  .lg\:tw-ring-gray-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(203, 213, 224, var(--tw-ring-opacity));
  }

  .lg\:tw-ring-gray-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(160, 174, 192, var(--tw-ring-opacity));
  }

  .lg\:tw-ring-gray-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(113, 128, 150, var(--tw-ring-opacity));
  }

  .lg\:tw-ring-gray-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 85, 104, var(--tw-ring-opacity));
  }

  .lg\:tw-ring-gray-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(45, 55, 72, var(--tw-ring-opacity));
  }

  .lg\:tw-ring-gray-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 32, 44, var(--tw-ring-opacity));
  }

  .lg\:tw-ring-blue-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(235, 248, 255, var(--tw-ring-opacity));
  }

  .lg\:tw-ring-blue-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 227, 248, var(--tw-ring-opacity));
  }

  .lg\:tw-ring-blue-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 205, 244, var(--tw-ring-opacity));
  }

  .lg\:tw-ring-blue-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 179, 237, var(--tw-ring-opacity));
  }

  .lg\:tw-ring-blue-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 153, 225, var(--tw-ring-opacity));
  }

  .lg\:tw-ring-blue-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 130, 206, var(--tw-ring-opacity));
  }

  .lg\:tw-ring-blue-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(43, 108, 176, var(--tw-ring-opacity));
  }

  .lg\:tw-ring-blue-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(44, 82, 130, var(--tw-ring-opacity));
  }

  .lg\:tw-ring-blue-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(42, 67, 101, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:tw-ring-transparent:focus-within{
    --tw-ring-color: transparent;
  }

  .lg\:focus-within\:tw-ring-current:focus-within{
    --tw-ring-color: currentColor;
  }

  .lg\:focus-within\:tw-ring-black:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(44, 44, 44, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:tw-ring-white:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:tw-ring-gray-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 250, 252, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:tw-ring-gray-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 242, 247, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:tw-ring-gray-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(226, 232, 240, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:tw-ring-gray-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(203, 213, 224, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:tw-ring-gray-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(160, 174, 192, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:tw-ring-gray-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(113, 128, 150, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:tw-ring-gray-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 85, 104, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:tw-ring-gray-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(45, 55, 72, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:tw-ring-gray-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 32, 44, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:tw-ring-blue-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(235, 248, 255, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:tw-ring-blue-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 227, 248, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:tw-ring-blue-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 205, 244, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:tw-ring-blue-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 179, 237, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:tw-ring-blue-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 153, 225, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:tw-ring-blue-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 130, 206, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:tw-ring-blue-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(43, 108, 176, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:tw-ring-blue-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(44, 82, 130, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:tw-ring-blue-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(42, 67, 101, var(--tw-ring-opacity));
  }

  .lg\:focus\:tw-ring-transparent:focus{
    --tw-ring-color: transparent;
  }

  .lg\:focus\:tw-ring-current:focus{
    --tw-ring-color: currentColor;
  }

  .lg\:focus\:tw-ring-black:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(44, 44, 44, var(--tw-ring-opacity));
  }

  .lg\:focus\:tw-ring-white:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .lg\:focus\:tw-ring-gray-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 250, 252, var(--tw-ring-opacity));
  }

  .lg\:focus\:tw-ring-gray-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 242, 247, var(--tw-ring-opacity));
  }

  .lg\:focus\:tw-ring-gray-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(226, 232, 240, var(--tw-ring-opacity));
  }

  .lg\:focus\:tw-ring-gray-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(203, 213, 224, var(--tw-ring-opacity));
  }

  .lg\:focus\:tw-ring-gray-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(160, 174, 192, var(--tw-ring-opacity));
  }

  .lg\:focus\:tw-ring-gray-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(113, 128, 150, var(--tw-ring-opacity));
  }

  .lg\:focus\:tw-ring-gray-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 85, 104, var(--tw-ring-opacity));
  }

  .lg\:focus\:tw-ring-gray-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(45, 55, 72, var(--tw-ring-opacity));
  }

  .lg\:focus\:tw-ring-gray-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 32, 44, var(--tw-ring-opacity));
  }

  .lg\:focus\:tw-ring-blue-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(235, 248, 255, var(--tw-ring-opacity));
  }

  .lg\:focus\:tw-ring-blue-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 227, 248, var(--tw-ring-opacity));
  }

  .lg\:focus\:tw-ring-blue-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 205, 244, var(--tw-ring-opacity));
  }

  .lg\:focus\:tw-ring-blue-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 179, 237, var(--tw-ring-opacity));
  }

  .lg\:focus\:tw-ring-blue-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 153, 225, var(--tw-ring-opacity));
  }

  .lg\:focus\:tw-ring-blue-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 130, 206, var(--tw-ring-opacity));
  }

  .lg\:focus\:tw-ring-blue-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(43, 108, 176, var(--tw-ring-opacity));
  }

  .lg\:focus\:tw-ring-blue-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(44, 82, 130, var(--tw-ring-opacity));
  }

  .lg\:focus\:tw-ring-blue-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(42, 67, 101, var(--tw-ring-opacity));
  }

  .lg\:tw-ring-opacity-0{
    --tw-ring-opacity: 0;
  }

  .lg\:tw-ring-opacity-25{
    --tw-ring-opacity: 0.25;
  }

  .lg\:tw-ring-opacity-50{
    --tw-ring-opacity: 0.5;
  }

  .lg\:tw-ring-opacity-75{
    --tw-ring-opacity: 0.75;
  }

  .lg\:tw-ring-opacity-90{
    --tw-ring-opacity: 0.9;
  }

  .lg\:tw-ring-opacity-100{
    --tw-ring-opacity: 1;
  }

  .lg\:focus-within\:tw-ring-opacity-0:focus-within{
    --tw-ring-opacity: 0;
  }

  .lg\:focus-within\:tw-ring-opacity-25:focus-within{
    --tw-ring-opacity: 0.25;
  }

  .lg\:focus-within\:tw-ring-opacity-50:focus-within{
    --tw-ring-opacity: 0.5;
  }

  .lg\:focus-within\:tw-ring-opacity-75:focus-within{
    --tw-ring-opacity: 0.75;
  }

  .lg\:focus-within\:tw-ring-opacity-90:focus-within{
    --tw-ring-opacity: 0.9;
  }

  .lg\:focus-within\:tw-ring-opacity-100:focus-within{
    --tw-ring-opacity: 1;
  }

  .lg\:focus\:tw-ring-opacity-0:focus{
    --tw-ring-opacity: 0;
  }

  .lg\:focus\:tw-ring-opacity-25:focus{
    --tw-ring-opacity: 0.25;
  }

  .lg\:focus\:tw-ring-opacity-50:focus{
    --tw-ring-opacity: 0.5;
  }

  .lg\:focus\:tw-ring-opacity-75:focus{
    --tw-ring-opacity: 0.75;
  }

  .lg\:focus\:tw-ring-opacity-90:focus{
    --tw-ring-opacity: 0.9;
  }

  .lg\:focus\:tw-ring-opacity-100:focus{
    --tw-ring-opacity: 1;
  }

  .lg\:tw-ring-offset-0{
    --tw-ring-offset-width: 0px;
  }

  .lg\:tw-ring-offset-1{
    --tw-ring-offset-width: 1px;
  }

  .lg\:tw-ring-offset-2{
    --tw-ring-offset-width: 2px;
  }

  .lg\:tw-ring-offset-4{
    --tw-ring-offset-width: 4px;
  }

  .lg\:tw-ring-offset-8{
    --tw-ring-offset-width: 8px;
  }

  .lg\:focus-within\:tw-ring-offset-0:focus-within{
    --tw-ring-offset-width: 0px;
  }

  .lg\:focus-within\:tw-ring-offset-1:focus-within{
    --tw-ring-offset-width: 1px;
  }

  .lg\:focus-within\:tw-ring-offset-2:focus-within{
    --tw-ring-offset-width: 2px;
  }

  .lg\:focus-within\:tw-ring-offset-4:focus-within{
    --tw-ring-offset-width: 4px;
  }

  .lg\:focus-within\:tw-ring-offset-8:focus-within{
    --tw-ring-offset-width: 8px;
  }

  .lg\:focus\:tw-ring-offset-0:focus{
    --tw-ring-offset-width: 0px;
  }

  .lg\:focus\:tw-ring-offset-1:focus{
    --tw-ring-offset-width: 1px;
  }

  .lg\:focus\:tw-ring-offset-2:focus{
    --tw-ring-offset-width: 2px;
  }

  .lg\:focus\:tw-ring-offset-4:focus{
    --tw-ring-offset-width: 4px;
  }

  .lg\:focus\:tw-ring-offset-8:focus{
    --tw-ring-offset-width: 8px;
  }

  .lg\:tw-ring-offset-transparent{
    --tw-ring-offset-color: transparent;
  }

  .lg\:tw-ring-offset-current{
    --tw-ring-offset-color: currentColor;
  }

  .lg\:tw-ring-offset-black{
    --tw-ring-offset-color: #2c2c2c;
  }

  .lg\:tw-ring-offset-white{
    --tw-ring-offset-color: #fff;
  }

  .lg\:tw-ring-offset-gray-100{
    --tw-ring-offset-color: #f7fafc;
  }

  .lg\:tw-ring-offset-gray-200{
    --tw-ring-offset-color: #edf2f7;
  }

  .lg\:tw-ring-offset-gray-300{
    --tw-ring-offset-color: #e2e8f0;
  }

  .lg\:tw-ring-offset-gray-400{
    --tw-ring-offset-color: #cbd5e0;
  }

  .lg\:tw-ring-offset-gray-500{
    --tw-ring-offset-color: #a0aec0;
  }

  .lg\:tw-ring-offset-gray-600{
    --tw-ring-offset-color: #718096;
  }

  .lg\:tw-ring-offset-gray-700{
    --tw-ring-offset-color: #4a5568;
  }

  .lg\:tw-ring-offset-gray-800{
    --tw-ring-offset-color: #2d3748;
  }

  .lg\:tw-ring-offset-gray-900{
    --tw-ring-offset-color: #1a202c;
  }

  .lg\:tw-ring-offset-blue-100{
    --tw-ring-offset-color: #ebf8ff;
  }

  .lg\:tw-ring-offset-blue-200{
    --tw-ring-offset-color: #bee3f8;
  }

  .lg\:tw-ring-offset-blue-300{
    --tw-ring-offset-color: #90cdf4;
  }

  .lg\:tw-ring-offset-blue-400{
    --tw-ring-offset-color: #63b3ed;
  }

  .lg\:tw-ring-offset-blue-500{
    --tw-ring-offset-color: #4299e1;
  }

  .lg\:tw-ring-offset-blue-600{
    --tw-ring-offset-color: #3182ce;
  }

  .lg\:tw-ring-offset-blue-700{
    --tw-ring-offset-color: #2b6cb0;
  }

  .lg\:tw-ring-offset-blue-800{
    --tw-ring-offset-color: #2c5282;
  }

  .lg\:tw-ring-offset-blue-900{
    --tw-ring-offset-color: #2a4365;
  }

  .lg\:focus-within\:tw-ring-offset-transparent:focus-within{
    --tw-ring-offset-color: transparent;
  }

  .lg\:focus-within\:tw-ring-offset-current:focus-within{
    --tw-ring-offset-color: currentColor;
  }

  .lg\:focus-within\:tw-ring-offset-black:focus-within{
    --tw-ring-offset-color: #2c2c2c;
  }

  .lg\:focus-within\:tw-ring-offset-white:focus-within{
    --tw-ring-offset-color: #fff;
  }

  .lg\:focus-within\:tw-ring-offset-gray-100:focus-within{
    --tw-ring-offset-color: #f7fafc;
  }

  .lg\:focus-within\:tw-ring-offset-gray-200:focus-within{
    --tw-ring-offset-color: #edf2f7;
  }

  .lg\:focus-within\:tw-ring-offset-gray-300:focus-within{
    --tw-ring-offset-color: #e2e8f0;
  }

  .lg\:focus-within\:tw-ring-offset-gray-400:focus-within{
    --tw-ring-offset-color: #cbd5e0;
  }

  .lg\:focus-within\:tw-ring-offset-gray-500:focus-within{
    --tw-ring-offset-color: #a0aec0;
  }

  .lg\:focus-within\:tw-ring-offset-gray-600:focus-within{
    --tw-ring-offset-color: #718096;
  }

  .lg\:focus-within\:tw-ring-offset-gray-700:focus-within{
    --tw-ring-offset-color: #4a5568;
  }

  .lg\:focus-within\:tw-ring-offset-gray-800:focus-within{
    --tw-ring-offset-color: #2d3748;
  }

  .lg\:focus-within\:tw-ring-offset-gray-900:focus-within{
    --tw-ring-offset-color: #1a202c;
  }

  .lg\:focus-within\:tw-ring-offset-blue-100:focus-within{
    --tw-ring-offset-color: #ebf8ff;
  }

  .lg\:focus-within\:tw-ring-offset-blue-200:focus-within{
    --tw-ring-offset-color: #bee3f8;
  }

  .lg\:focus-within\:tw-ring-offset-blue-300:focus-within{
    --tw-ring-offset-color: #90cdf4;
  }

  .lg\:focus-within\:tw-ring-offset-blue-400:focus-within{
    --tw-ring-offset-color: #63b3ed;
  }

  .lg\:focus-within\:tw-ring-offset-blue-500:focus-within{
    --tw-ring-offset-color: #4299e1;
  }

  .lg\:focus-within\:tw-ring-offset-blue-600:focus-within{
    --tw-ring-offset-color: #3182ce;
  }

  .lg\:focus-within\:tw-ring-offset-blue-700:focus-within{
    --tw-ring-offset-color: #2b6cb0;
  }

  .lg\:focus-within\:tw-ring-offset-blue-800:focus-within{
    --tw-ring-offset-color: #2c5282;
  }

  .lg\:focus-within\:tw-ring-offset-blue-900:focus-within{
    --tw-ring-offset-color: #2a4365;
  }

  .lg\:focus\:tw-ring-offset-transparent:focus{
    --tw-ring-offset-color: transparent;
  }

  .lg\:focus\:tw-ring-offset-current:focus{
    --tw-ring-offset-color: currentColor;
  }

  .lg\:focus\:tw-ring-offset-black:focus{
    --tw-ring-offset-color: #2c2c2c;
  }

  .lg\:focus\:tw-ring-offset-white:focus{
    --tw-ring-offset-color: #fff;
  }

  .lg\:focus\:tw-ring-offset-gray-100:focus{
    --tw-ring-offset-color: #f7fafc;
  }

  .lg\:focus\:tw-ring-offset-gray-200:focus{
    --tw-ring-offset-color: #edf2f7;
  }

  .lg\:focus\:tw-ring-offset-gray-300:focus{
    --tw-ring-offset-color: #e2e8f0;
  }

  .lg\:focus\:tw-ring-offset-gray-400:focus{
    --tw-ring-offset-color: #cbd5e0;
  }

  .lg\:focus\:tw-ring-offset-gray-500:focus{
    --tw-ring-offset-color: #a0aec0;
  }

  .lg\:focus\:tw-ring-offset-gray-600:focus{
    --tw-ring-offset-color: #718096;
  }

  .lg\:focus\:tw-ring-offset-gray-700:focus{
    --tw-ring-offset-color: #4a5568;
  }

  .lg\:focus\:tw-ring-offset-gray-800:focus{
    --tw-ring-offset-color: #2d3748;
  }

  .lg\:focus\:tw-ring-offset-gray-900:focus{
    --tw-ring-offset-color: #1a202c;
  }

  .lg\:focus\:tw-ring-offset-blue-100:focus{
    --tw-ring-offset-color: #ebf8ff;
  }

  .lg\:focus\:tw-ring-offset-blue-200:focus{
    --tw-ring-offset-color: #bee3f8;
  }

  .lg\:focus\:tw-ring-offset-blue-300:focus{
    --tw-ring-offset-color: #90cdf4;
  }

  .lg\:focus\:tw-ring-offset-blue-400:focus{
    --tw-ring-offset-color: #63b3ed;
  }

  .lg\:focus\:tw-ring-offset-blue-500:focus{
    --tw-ring-offset-color: #4299e1;
  }

  .lg\:focus\:tw-ring-offset-blue-600:focus{
    --tw-ring-offset-color: #3182ce;
  }

  .lg\:focus\:tw-ring-offset-blue-700:focus{
    --tw-ring-offset-color: #2b6cb0;
  }

  .lg\:focus\:tw-ring-offset-blue-800:focus{
    --tw-ring-offset-color: #2c5282;
  }

  .lg\:focus\:tw-ring-offset-blue-900:focus{
    --tw-ring-offset-color: #2a4365;
  }

  .lg\:tw-filter{
    --tw-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
    -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:tw-filter-none{
    -webkit-filter: none;
    filter: none;
  }

  .lg\:tw-blur-0{
    --tw-blur: blur(0);
  }

  .lg\:tw-blur-none{
    --tw-blur: blur(0);
  }

  .lg\:tw-blur-sm{
    --tw-blur: blur(4px);
  }

  .lg\:tw-blur{
    --tw-blur: blur(8px);
  }

  .lg\:tw-blur-md{
    --tw-blur: blur(12px);
  }

  .lg\:tw-blur-lg{
    --tw-blur: blur(16px);
  }

  .lg\:tw-blur-xl{
    --tw-blur: blur(24px);
  }

  .lg\:tw-blur-2xl{
    --tw-blur: blur(40px);
  }

  .lg\:tw-blur-3xl{
    --tw-blur: blur(64px);
  }

  .lg\:tw-brightness-0{
    --tw-brightness: brightness(0);
  }

  .lg\:tw-brightness-50{
    --tw-brightness: brightness(.5);
  }

  .lg\:tw-brightness-75{
    --tw-brightness: brightness(.75);
  }

  .lg\:tw-brightness-90{
    --tw-brightness: brightness(.9);
  }

  .lg\:tw-brightness-95{
    --tw-brightness: brightness(.95);
  }

  .lg\:tw-brightness-100{
    --tw-brightness: brightness(1);
  }

  .lg\:tw-brightness-105{
    --tw-brightness: brightness(1.05);
  }

  .lg\:tw-brightness-110{
    --tw-brightness: brightness(1.1);
  }

  .lg\:tw-brightness-125{
    --tw-brightness: brightness(1.25);
  }

  .lg\:tw-brightness-150{
    --tw-brightness: brightness(1.5);
  }

  .lg\:tw-brightness-200{
    --tw-brightness: brightness(2);
  }

  .lg\:tw-contrast-0{
    --tw-contrast: contrast(0);
  }

  .lg\:tw-contrast-50{
    --tw-contrast: contrast(.5);
  }

  .lg\:tw-contrast-75{
    --tw-contrast: contrast(.75);
  }

  .lg\:tw-contrast-100{
    --tw-contrast: contrast(1);
  }

  .lg\:tw-contrast-125{
    --tw-contrast: contrast(1.25);
  }

  .lg\:tw-contrast-150{
    --tw-contrast: contrast(1.5);
  }

  .lg\:tw-contrast-200{
    --tw-contrast: contrast(2);
  }

  .lg\:tw-drop-shadow-sm{
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05));
  }

  .lg\:tw-drop-shadow{
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
  }

  .lg\:tw-drop-shadow-md{
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
  }

  .lg\:tw-drop-shadow-lg{
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
  }

  .lg\:tw-drop-shadow-xl{
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
  }

  .lg\:tw-drop-shadow-2xl{
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15));
  }

  .lg\:tw-drop-shadow-none{
    --tw-drop-shadow: drop-shadow(0 0 #0000);
  }

  .lg\:tw-grayscale-0{
    --tw-grayscale: grayscale(0);
  }

  .lg\:tw-grayscale{
    --tw-grayscale: grayscale(100%);
  }

  .lg\:tw-hue-rotate-0{
    --tw-hue-rotate: hue-rotate(0deg);
  }

  .lg\:tw-hue-rotate-15{
    --tw-hue-rotate: hue-rotate(15deg);
  }

  .lg\:tw-hue-rotate-30{
    --tw-hue-rotate: hue-rotate(30deg);
  }

  .lg\:tw-hue-rotate-60{
    --tw-hue-rotate: hue-rotate(60deg);
  }

  .lg\:tw-hue-rotate-90{
    --tw-hue-rotate: hue-rotate(90deg);
  }

  .lg\:tw-hue-rotate-180{
    --tw-hue-rotate: hue-rotate(180deg);
  }

  .lg\:tw--hue-rotate-180{
    --tw-hue-rotate: hue-rotate(-180deg);
  }

  .lg\:tw--hue-rotate-90{
    --tw-hue-rotate: hue-rotate(-90deg);
  }

  .lg\:tw--hue-rotate-60{
    --tw-hue-rotate: hue-rotate(-60deg);
  }

  .lg\:tw--hue-rotate-30{
    --tw-hue-rotate: hue-rotate(-30deg);
  }

  .lg\:tw--hue-rotate-15{
    --tw-hue-rotate: hue-rotate(-15deg);
  }

  .lg\:tw-invert-0{
    --tw-invert: invert(0);
  }

  .lg\:tw-invert{
    --tw-invert: invert(100%);
  }

  .lg\:tw-saturate-0{
    --tw-saturate: saturate(0);
  }

  .lg\:tw-saturate-50{
    --tw-saturate: saturate(.5);
  }

  .lg\:tw-saturate-100{
    --tw-saturate: saturate(1);
  }

  .lg\:tw-saturate-150{
    --tw-saturate: saturate(1.5);
  }

  .lg\:tw-saturate-200{
    --tw-saturate: saturate(2);
  }

  .lg\:tw-sepia-0{
    --tw-sepia: sepia(0);
  }

  .lg\:tw-sepia{
    --tw-sepia: sepia(100%);
  }

  .lg\:tw-backdrop-filter{
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:tw-backdrop-filter-none{
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
  }

  .lg\:tw-backdrop-blur-0{
    --tw-backdrop-blur: blur(0);
  }

  .lg\:tw-backdrop-blur-none{
    --tw-backdrop-blur: blur(0);
  }

  .lg\:tw-backdrop-blur-sm{
    --tw-backdrop-blur: blur(4px);
  }

  .lg\:tw-backdrop-blur{
    --tw-backdrop-blur: blur(8px);
  }

  .lg\:tw-backdrop-blur-md{
    --tw-backdrop-blur: blur(12px);
  }

  .lg\:tw-backdrop-blur-lg{
    --tw-backdrop-blur: blur(16px);
  }

  .lg\:tw-backdrop-blur-xl{
    --tw-backdrop-blur: blur(24px);
  }

  .lg\:tw-backdrop-blur-2xl{
    --tw-backdrop-blur: blur(40px);
  }

  .lg\:tw-backdrop-blur-3xl{
    --tw-backdrop-blur: blur(64px);
  }

  .lg\:tw-backdrop-brightness-0{
    --tw-backdrop-brightness: brightness(0);
  }

  .lg\:tw-backdrop-brightness-50{
    --tw-backdrop-brightness: brightness(.5);
  }

  .lg\:tw-backdrop-brightness-75{
    --tw-backdrop-brightness: brightness(.75);
  }

  .lg\:tw-backdrop-brightness-90{
    --tw-backdrop-brightness: brightness(.9);
  }

  .lg\:tw-backdrop-brightness-95{
    --tw-backdrop-brightness: brightness(.95);
  }

  .lg\:tw-backdrop-brightness-100{
    --tw-backdrop-brightness: brightness(1);
  }

  .lg\:tw-backdrop-brightness-105{
    --tw-backdrop-brightness: brightness(1.05);
  }

  .lg\:tw-backdrop-brightness-110{
    --tw-backdrop-brightness: brightness(1.1);
  }

  .lg\:tw-backdrop-brightness-125{
    --tw-backdrop-brightness: brightness(1.25);
  }

  .lg\:tw-backdrop-brightness-150{
    --tw-backdrop-brightness: brightness(1.5);
  }

  .lg\:tw-backdrop-brightness-200{
    --tw-backdrop-brightness: brightness(2);
  }

  .lg\:tw-backdrop-contrast-0{
    --tw-backdrop-contrast: contrast(0);
  }

  .lg\:tw-backdrop-contrast-50{
    --tw-backdrop-contrast: contrast(.5);
  }

  .lg\:tw-backdrop-contrast-75{
    --tw-backdrop-contrast: contrast(.75);
  }

  .lg\:tw-backdrop-contrast-100{
    --tw-backdrop-contrast: contrast(1);
  }

  .lg\:tw-backdrop-contrast-125{
    --tw-backdrop-contrast: contrast(1.25);
  }

  .lg\:tw-backdrop-contrast-150{
    --tw-backdrop-contrast: contrast(1.5);
  }

  .lg\:tw-backdrop-contrast-200{
    --tw-backdrop-contrast: contrast(2);
  }

  .lg\:tw-backdrop-grayscale-0{
    --tw-backdrop-grayscale: grayscale(0);
  }

  .lg\:tw-backdrop-grayscale{
    --tw-backdrop-grayscale: grayscale(100%);
  }

  .lg\:tw-backdrop-hue-rotate-0{
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
  }

  .lg\:tw-backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
  }

  .lg\:tw-backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
  }

  .lg\:tw-backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
  }

  .lg\:tw-backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
  }

  .lg\:tw-backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
  }

  .lg\:tw--backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  }

  .lg\:tw--backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  }

  .lg\:tw--backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  }

  .lg\:tw--backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  }

  .lg\:tw--backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  }

  .lg\:tw-backdrop-invert-0{
    --tw-backdrop-invert: invert(0);
  }

  .lg\:tw-backdrop-invert{
    --tw-backdrop-invert: invert(100%);
  }

  .lg\:tw-backdrop-opacity-0{
    --tw-backdrop-opacity: opacity(0);
  }

  .lg\:tw-backdrop-opacity-25{
    --tw-backdrop-opacity: opacity(0.25);
  }

  .lg\:tw-backdrop-opacity-50{
    --tw-backdrop-opacity: opacity(0.5);
  }

  .lg\:tw-backdrop-opacity-75{
    --tw-backdrop-opacity: opacity(0.75);
  }

  .lg\:tw-backdrop-opacity-90{
    --tw-backdrop-opacity: opacity(0.9);
  }

  .lg\:tw-backdrop-opacity-100{
    --tw-backdrop-opacity: opacity(1);
  }

  .lg\:tw-backdrop-saturate-0{
    --tw-backdrop-saturate: saturate(0);
  }

  .lg\:tw-backdrop-saturate-50{
    --tw-backdrop-saturate: saturate(.5);
  }

  .lg\:tw-backdrop-saturate-100{
    --tw-backdrop-saturate: saturate(1);
  }

  .lg\:tw-backdrop-saturate-150{
    --tw-backdrop-saturate: saturate(1.5);
  }

  .lg\:tw-backdrop-saturate-200{
    --tw-backdrop-saturate: saturate(2);
  }

  .lg\:tw-backdrop-sepia-0{
    --tw-backdrop-sepia: sepia(0);
  }

  .lg\:tw-backdrop-sepia{
    --tw-backdrop-sepia: sepia(100%);
  }

  .lg\:tw-transition-none{
    transition-property: none;
  }

  .lg\:tw-transition-all{
    transition-property: all;
  }

  .lg\:tw-transition-default{
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  }

  .lg\:tw-transition-colors{
    transition-property: background-color, border-color, color, fill, stroke;
  }

  .lg\:tw-transition-opacity{
    transition-property: opacity;
  }

  .lg\:tw-transition-shadow{
    transition-property: box-shadow;
  }

  .lg\:tw-transition-transform{
    transition-property: transform;
  }

  .lg\:tw-delay-75{
    transition-delay: 75ms;
  }

  .lg\:tw-delay-100{
    transition-delay: 100ms;
  }

  .lg\:tw-delay-150{
    transition-delay: 150ms;
  }

  .lg\:tw-delay-200{
    transition-delay: 200ms;
  }

  .lg\:tw-delay-300{
    transition-delay: 300ms;
  }

  .lg\:tw-delay-500{
    transition-delay: 500ms;
  }

  .lg\:tw-delay-700{
    transition-delay: 700ms;
  }

  .lg\:tw-delay-1000{
    transition-delay: 1000ms;
  }

  .lg\:tw-duration-75{
    transition-duration: 75ms;
  }

  .lg\:tw-duration-100{
    transition-duration: 100ms;
  }

  .lg\:tw-duration-150{
    transition-duration: 150ms;
  }

  .lg\:tw-duration-200{
    transition-duration: 200ms;
  }

  .lg\:tw-duration-300{
    transition-duration: 300ms;
  }

  .lg\:tw-duration-500{
    transition-duration: 500ms;
  }

  .lg\:tw-duration-700{
    transition-duration: 700ms;
  }

  .lg\:tw-duration-1000{
    transition-duration: 1000ms;
  }

  .lg\:tw-ease-linear{
    transition-timing-function: linear;
  }

  .lg\:tw-ease-in{
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .lg\:tw-ease-out{
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .lg\:tw-ease-in-out{
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}

@media (min-width: 1280px){
  .xl\:tw-container{
    width: 100%;
  }
}

@media (min-width: 1280px) and (min-width: 640px){
  .xl\:tw-container{
    max-width: 640px;
  }
}

@media (min-width: 1280px) and (min-width: 768px){
  .xl\:tw-container{
    max-width: 768px;
  }
}

@media (min-width: 1280px) and (min-width: 1024px){
  .xl\:tw-container{
    max-width: 1024px;
  }
}

@media (min-width: 1280px) and (min-width: 1280px){
  .xl\:tw-container{
    max-width: 1280px;
  }
}

@media (min-width: 1280px){
  .xl\:tw-sr-only{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .xl\:tw-not-sr-only{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .xl\:focus\:tw-sr-only:focus{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .xl\:focus\:tw-not-sr-only:focus{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .xl\:tw-pointer-events-none{
    pointer-events: none;
  }

  .xl\:tw-pointer-events-auto{
    pointer-events: auto;
  }

  .xl\:tw-visible{
    visibility: visible;
  }

  .xl\:tw-invisible{
    visibility: hidden;
  }

  .xl\:tw-static{
    position: static;
  }

  .xl\:tw-fixed{
    position: fixed;
  }

  .xl\:tw-absolute{
    position: absolute;
  }

  .xl\:tw-relative{
    position: relative;
  }

  .xl\:tw-sticky{
    position: -webkit-sticky;
    position: sticky;
  }

  .xl\:tw-inset-0{
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .xl\:tw-inset-auto{
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .xl\:tw-inset-x-0{
    left: 0;
    right: 0;
  }

  .xl\:tw-inset-x-auto{
    left: auto;
    right: auto;
  }

  .xl\:tw-inset-y-0{
    top: 0;
    bottom: 0;
  }

  .xl\:tw-inset-y-auto{
    top: auto;
    bottom: auto;
  }

  .xl\:tw-top-0{
    top: 0;
  }

  .xl\:tw-top-auto{
    top: auto;
  }

  .xl\:tw-right-0{
    right: 0;
  }

  .xl\:tw-right-auto{
    right: auto;
  }

  .xl\:tw-bottom-0{
    bottom: 0;
  }

  .xl\:tw-bottom-auto{
    bottom: auto;
  }

  .xl\:tw-left-0{
    left: 0;
  }

  .xl\:tw-left-auto{
    left: auto;
  }

  .xl\:tw-isolate{
    isolation: isolate;
  }

  .xl\:tw-isolation-auto{
    isolation: auto;
  }

  .xl\:tw-z-0{
    z-index: 0;
  }

  .xl\:tw-z-10{
    z-index: 10;
  }

  .xl\:tw-z-20{
    z-index: 20;
  }

  .xl\:tw-z-30{
    z-index: 30;
  }

  .xl\:tw-z-40{
    z-index: 40;
  }

  .xl\:tw-z-50{
    z-index: 50;
  }

  .xl\:tw-z-auto{
    z-index: auto;
  }

  .xl\:tw-order-1{
    order: 1;
  }

  .xl\:tw-order-2{
    order: 2;
  }

  .xl\:tw-order-3{
    order: 3;
  }

  .xl\:tw-order-4{
    order: 4;
  }

  .xl\:tw-order-5{
    order: 5;
  }

  .xl\:tw-order-6{
    order: 6;
  }

  .xl\:tw-order-7{
    order: 7;
  }

  .xl\:tw-order-8{
    order: 8;
  }

  .xl\:tw-order-9{
    order: 9;
  }

  .xl\:tw-order-10{
    order: 10;
  }

  .xl\:tw-order-11{
    order: 11;
  }

  .xl\:tw-order-12{
    order: 12;
  }

  .xl\:tw-order-first{
    order: -9999;
  }

  .xl\:tw-order-last{
    order: 9999;
  }

  .xl\:tw-order-none{
    order: 0;
  }

  .xl\:tw-col-auto{
    grid-column: auto;
  }

  .xl\:tw-col-span-1{
    grid-column: span 1 / span 1;
  }

  .xl\:tw-col-span-2{
    grid-column: span 2 / span 2;
  }

  .xl\:tw-col-span-3{
    grid-column: span 3 / span 3;
  }

  .xl\:tw-col-span-4{
    grid-column: span 4 / span 4;
  }

  .xl\:tw-col-span-5{
    grid-column: span 5 / span 5;
  }

  .xl\:tw-col-span-6{
    grid-column: span 6 / span 6;
  }

  .xl\:tw-col-span-7{
    grid-column: span 7 / span 7;
  }

  .xl\:tw-col-span-8{
    grid-column: span 8 / span 8;
  }

  .xl\:tw-col-span-9{
    grid-column: span 9 / span 9;
  }

  .xl\:tw-col-span-10{
    grid-column: span 10 / span 10;
  }

  .xl\:tw-col-span-11{
    grid-column: span 11 / span 11;
  }

  .xl\:tw-col-span-12{
    grid-column: span 12 / span 12;
  }

  .xl\:tw-col-span-full{
    grid-column: 1 / -1;
  }

  .xl\:tw-col-start-1{
    grid-column-start: 1;
  }

  .xl\:tw-col-start-2{
    grid-column-start: 2;
  }

  .xl\:tw-col-start-3{
    grid-column-start: 3;
  }

  .xl\:tw-col-start-4{
    grid-column-start: 4;
  }

  .xl\:tw-col-start-5{
    grid-column-start: 5;
  }

  .xl\:tw-col-start-6{
    grid-column-start: 6;
  }

  .xl\:tw-col-start-7{
    grid-column-start: 7;
  }

  .xl\:tw-col-start-8{
    grid-column-start: 8;
  }

  .xl\:tw-col-start-9{
    grid-column-start: 9;
  }

  .xl\:tw-col-start-10{
    grid-column-start: 10;
  }

  .xl\:tw-col-start-11{
    grid-column-start: 11;
  }

  .xl\:tw-col-start-12{
    grid-column-start: 12;
  }

  .xl\:tw-col-start-13{
    grid-column-start: 13;
  }

  .xl\:tw-col-start-auto{
    grid-column-start: auto;
  }

  .xl\:tw-col-end-1{
    grid-column-end: 1;
  }

  .xl\:tw-col-end-2{
    grid-column-end: 2;
  }

  .xl\:tw-col-end-3{
    grid-column-end: 3;
  }

  .xl\:tw-col-end-4{
    grid-column-end: 4;
  }

  .xl\:tw-col-end-5{
    grid-column-end: 5;
  }

  .xl\:tw-col-end-6{
    grid-column-end: 6;
  }

  .xl\:tw-col-end-7{
    grid-column-end: 7;
  }

  .xl\:tw-col-end-8{
    grid-column-end: 8;
  }

  .xl\:tw-col-end-9{
    grid-column-end: 9;
  }

  .xl\:tw-col-end-10{
    grid-column-end: 10;
  }

  .xl\:tw-col-end-11{
    grid-column-end: 11;
  }

  .xl\:tw-col-end-12{
    grid-column-end: 12;
  }

  .xl\:tw-col-end-13{
    grid-column-end: 13;
  }

  .xl\:tw-col-end-auto{
    grid-column-end: auto;
  }

  .xl\:tw-row-auto{
    grid-row: auto;
  }

  .xl\:tw-row-span-1{
    grid-row: span 1 / span 1;
  }

  .xl\:tw-row-span-2{
    grid-row: span 2 / span 2;
  }

  .xl\:tw-row-span-3{
    grid-row: span 3 / span 3;
  }

  .xl\:tw-row-span-4{
    grid-row: span 4 / span 4;
  }

  .xl\:tw-row-span-5{
    grid-row: span 5 / span 5;
  }

  .xl\:tw-row-span-6{
    grid-row: span 6 / span 6;
  }

  .xl\:tw-row-span-full{
    grid-row: 1 / -1;
  }

  .xl\:tw-row-start-1{
    grid-row-start: 1;
  }

  .xl\:tw-row-start-2{
    grid-row-start: 2;
  }

  .xl\:tw-row-start-3{
    grid-row-start: 3;
  }

  .xl\:tw-row-start-4{
    grid-row-start: 4;
  }

  .xl\:tw-row-start-5{
    grid-row-start: 5;
  }

  .xl\:tw-row-start-6{
    grid-row-start: 6;
  }

  .xl\:tw-row-start-7{
    grid-row-start: 7;
  }

  .xl\:tw-row-start-auto{
    grid-row-start: auto;
  }

  .xl\:tw-row-end-1{
    grid-row-end: 1;
  }

  .xl\:tw-row-end-2{
    grid-row-end: 2;
  }

  .xl\:tw-row-end-3{
    grid-row-end: 3;
  }

  .xl\:tw-row-end-4{
    grid-row-end: 4;
  }

  .xl\:tw-row-end-5{
    grid-row-end: 5;
  }

  .xl\:tw-row-end-6{
    grid-row-end: 6;
  }

  .xl\:tw-row-end-7{
    grid-row-end: 7;
  }

  .xl\:tw-row-end-auto{
    grid-row-end: auto;
  }

  .xl\:tw-float-right{
    float: right;
  }

  .xl\:tw-float-left{
    float: left;
  }

  .xl\:tw-float-none{
    float: none;
  }

  .xl\:tw-clear-left{
    clear: left;
  }

  .xl\:tw-clear-right{
    clear: right;
  }

  .xl\:tw-clear-both{
    clear: both;
  }

  .xl\:tw-clear-none{
    clear: none;
  }

  .xl\:tw-m-0{
    margin: 0;
  }

  .xl\:tw-m-1{
    margin: 0.25rem;
  }

  .xl\:tw-m-2{
    margin: 0.5rem;
  }

  .xl\:tw-m-3{
    margin: 0.75rem;
  }

  .xl\:tw-m-4{
    margin: 1rem;
  }

  .xl\:tw-m-5{
    margin: 1.25rem;
  }

  .xl\:tw-m-6{
    margin: 1.5rem;
  }

  .xl\:tw-m-8{
    margin: 2rem;
  }

  .xl\:tw-m-10{
    margin: 2.5rem;
  }

  .xl\:tw-m-12{
    margin: 3rem;
  }

  .xl\:tw-m-16{
    margin: 4rem;
  }

  .xl\:tw-m-20{
    margin: 5rem;
  }

  .xl\:tw-m-24{
    margin: 6rem;
  }

  .xl\:tw-m-32{
    margin: 8rem;
  }

  .xl\:tw-m-40{
    margin: 10rem;
  }

  .xl\:tw-m-48{
    margin: 12rem;
  }

  .xl\:tw-m-56{
    margin: 14rem;
  }

  .xl\:tw-m-64{
    margin: 16rem;
  }

  .xl\:tw-m-auto{
    margin: auto;
  }

  .xl\:tw-m-px{
    margin: 1px;
  }

  .xl\:tw--m-1{
    margin: -0.25rem;
  }

  .xl\:tw--m-2{
    margin: -0.5rem;
  }

  .xl\:tw--m-3{
    margin: -0.75rem;
  }

  .xl\:tw--m-4{
    margin: -1rem;
  }

  .xl\:tw--m-5{
    margin: -1.25rem;
  }

  .xl\:tw--m-6{
    margin: -1.5rem;
  }

  .xl\:tw--m-8{
    margin: -2rem;
  }

  .xl\:tw--m-10{
    margin: -2.5rem;
  }

  .xl\:tw--m-12{
    margin: -3rem;
  }

  .xl\:tw--m-16{
    margin: -4rem;
  }

  .xl\:tw--m-20{
    margin: -5rem;
  }

  .xl\:tw--m-24{
    margin: -6rem;
  }

  .xl\:tw--m-32{
    margin: -8rem;
  }

  .xl\:tw--m-40{
    margin: -10rem;
  }

  .xl\:tw--m-48{
    margin: -12rem;
  }

  .xl\:tw--m-56{
    margin: -14rem;
  }

  .xl\:tw--m-64{
    margin: -16rem;
  }

  .xl\:tw--m-px{
    margin: -1px;
  }

  .xl\:tw-mx-0{
    margin-left: 0;
    margin-right: 0;
  }

  .xl\:tw-mx-1{
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .xl\:tw-mx-2{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .xl\:tw-mx-3{
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .xl\:tw-mx-4{
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .xl\:tw-mx-5{
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .xl\:tw-mx-6{
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .xl\:tw-mx-8{
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .xl\:tw-mx-10{
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .xl\:tw-mx-12{
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .xl\:tw-mx-16{
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .xl\:tw-mx-20{
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .xl\:tw-mx-24{
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .xl\:tw-mx-32{
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .xl\:tw-mx-40{
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .xl\:tw-mx-48{
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .xl\:tw-mx-56{
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .xl\:tw-mx-64{
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .xl\:tw-mx-auto{
    margin-left: auto;
    margin-right: auto;
  }

  .xl\:tw-mx-px{
    margin-left: 1px;
    margin-right: 1px;
  }

  .xl\:tw--mx-1{
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  .xl\:tw--mx-2{
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .xl\:tw--mx-3{
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  .xl\:tw--mx-4{
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .xl\:tw--mx-5{
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .xl\:tw--mx-6{
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .xl\:tw--mx-8{
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .xl\:tw--mx-10{
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .xl\:tw--mx-12{
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .xl\:tw--mx-16{
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .xl\:tw--mx-20{
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .xl\:tw--mx-24{
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .xl\:tw--mx-32{
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .xl\:tw--mx-40{
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .xl\:tw--mx-48{
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .xl\:tw--mx-56{
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .xl\:tw--mx-64{
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .xl\:tw--mx-px{
    margin-left: -1px;
    margin-right: -1px;
  }

  .xl\:tw-my-0{
    margin-top: 0;
    margin-bottom: 0;
  }

  .xl\:tw-my-1{
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .xl\:tw-my-2{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .xl\:tw-my-3{
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .xl\:tw-my-4{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .xl\:tw-my-5{
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .xl\:tw-my-6{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .xl\:tw-my-8{
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .xl\:tw-my-10{
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .xl\:tw-my-12{
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .xl\:tw-my-16{
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .xl\:tw-my-20{
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .xl\:tw-my-24{
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .xl\:tw-my-32{
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .xl\:tw-my-40{
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .xl\:tw-my-48{
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .xl\:tw-my-56{
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .xl\:tw-my-64{
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .xl\:tw-my-auto{
    margin-top: auto;
    margin-bottom: auto;
  }

  .xl\:tw-my-px{
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .xl\:tw--my-1{
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }

  .xl\:tw--my-2{
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .xl\:tw--my-3{
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }

  .xl\:tw--my-4{
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .xl\:tw--my-5{
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .xl\:tw--my-6{
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .xl\:tw--my-8{
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .xl\:tw--my-10{
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .xl\:tw--my-12{
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .xl\:tw--my-16{
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .xl\:tw--my-20{
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .xl\:tw--my-24{
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .xl\:tw--my-32{
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .xl\:tw--my-40{
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .xl\:tw--my-48{
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .xl\:tw--my-56{
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .xl\:tw--my-64{
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .xl\:tw--my-px{
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .xl\:tw-mt-0{
    margin-top: 0;
  }

  .xl\:tw-mt-1{
    margin-top: 0.25rem;
  }

  .xl\:tw-mt-2{
    margin-top: 0.5rem;
  }

  .xl\:tw-mt-3{
    margin-top: 0.75rem;
  }

  .xl\:tw-mt-4{
    margin-top: 1rem;
  }

  .xl\:tw-mt-5{
    margin-top: 1.25rem;
  }

  .xl\:tw-mt-6{
    margin-top: 1.5rem;
  }

  .xl\:tw-mt-8{
    margin-top: 2rem;
  }

  .xl\:tw-mt-10{
    margin-top: 2.5rem;
  }

  .xl\:tw-mt-12{
    margin-top: 3rem;
  }

  .xl\:tw-mt-16{
    margin-top: 4rem;
  }

  .xl\:tw-mt-20{
    margin-top: 5rem;
  }

  .xl\:tw-mt-24{
    margin-top: 6rem;
  }

  .xl\:tw-mt-32{
    margin-top: 8rem;
  }

  .xl\:tw-mt-40{
    margin-top: 10rem;
  }

  .xl\:tw-mt-48{
    margin-top: 12rem;
  }

  .xl\:tw-mt-56{
    margin-top: 14rem;
  }

  .xl\:tw-mt-64{
    margin-top: 16rem;
  }

  .xl\:tw-mt-auto{
    margin-top: auto;
  }

  .xl\:tw-mt-px{
    margin-top: 1px;
  }

  .xl\:tw--mt-1{
    margin-top: -0.25rem;
  }

  .xl\:tw--mt-2{
    margin-top: -0.5rem;
  }

  .xl\:tw--mt-3{
    margin-top: -0.75rem;
  }

  .xl\:tw--mt-4{
    margin-top: -1rem;
  }

  .xl\:tw--mt-5{
    margin-top: -1.25rem;
  }

  .xl\:tw--mt-6{
    margin-top: -1.5rem;
  }

  .xl\:tw--mt-8{
    margin-top: -2rem;
  }

  .xl\:tw--mt-10{
    margin-top: -2.5rem;
  }

  .xl\:tw--mt-12{
    margin-top: -3rem;
  }

  .xl\:tw--mt-16{
    margin-top: -4rem;
  }

  .xl\:tw--mt-20{
    margin-top: -5rem;
  }

  .xl\:tw--mt-24{
    margin-top: -6rem;
  }

  .xl\:tw--mt-32{
    margin-top: -8rem;
  }

  .xl\:tw--mt-40{
    margin-top: -10rem;
  }

  .xl\:tw--mt-48{
    margin-top: -12rem;
  }

  .xl\:tw--mt-56{
    margin-top: -14rem;
  }

  .xl\:tw--mt-64{
    margin-top: -16rem;
  }

  .xl\:tw--mt-px{
    margin-top: -1px;
  }

  .xl\:tw-mr-0{
    margin-right: 0;
  }

  .xl\:tw-mr-1{
    margin-right: 0.25rem;
  }

  .xl\:tw-mr-2{
    margin-right: 0.5rem;
  }

  .xl\:tw-mr-3{
    margin-right: 0.75rem;
  }

  .xl\:tw-mr-4{
    margin-right: 1rem;
  }

  .xl\:tw-mr-5{
    margin-right: 1.25rem;
  }

  .xl\:tw-mr-6{
    margin-right: 1.5rem;
  }

  .xl\:tw-mr-8{
    margin-right: 2rem;
  }

  .xl\:tw-mr-10{
    margin-right: 2.5rem;
  }

  .xl\:tw-mr-12{
    margin-right: 3rem;
  }

  .xl\:tw-mr-16{
    margin-right: 4rem;
  }

  .xl\:tw-mr-20{
    margin-right: 5rem;
  }

  .xl\:tw-mr-24{
    margin-right: 6rem;
  }

  .xl\:tw-mr-32{
    margin-right: 8rem;
  }

  .xl\:tw-mr-40{
    margin-right: 10rem;
  }

  .xl\:tw-mr-48{
    margin-right: 12rem;
  }

  .xl\:tw-mr-56{
    margin-right: 14rem;
  }

  .xl\:tw-mr-64{
    margin-right: 16rem;
  }

  .xl\:tw-mr-auto{
    margin-right: auto;
  }

  .xl\:tw-mr-px{
    margin-right: 1px;
  }

  .xl\:tw--mr-1{
    margin-right: -0.25rem;
  }

  .xl\:tw--mr-2{
    margin-right: -0.5rem;
  }

  .xl\:tw--mr-3{
    margin-right: -0.75rem;
  }

  .xl\:tw--mr-4{
    margin-right: -1rem;
  }

  .xl\:tw--mr-5{
    margin-right: -1.25rem;
  }

  .xl\:tw--mr-6{
    margin-right: -1.5rem;
  }

  .xl\:tw--mr-8{
    margin-right: -2rem;
  }

  .xl\:tw--mr-10{
    margin-right: -2.5rem;
  }

  .xl\:tw--mr-12{
    margin-right: -3rem;
  }

  .xl\:tw--mr-16{
    margin-right: -4rem;
  }

  .xl\:tw--mr-20{
    margin-right: -5rem;
  }

  .xl\:tw--mr-24{
    margin-right: -6rem;
  }

  .xl\:tw--mr-32{
    margin-right: -8rem;
  }

  .xl\:tw--mr-40{
    margin-right: -10rem;
  }

  .xl\:tw--mr-48{
    margin-right: -12rem;
  }

  .xl\:tw--mr-56{
    margin-right: -14rem;
  }

  .xl\:tw--mr-64{
    margin-right: -16rem;
  }

  .xl\:tw--mr-px{
    margin-right: -1px;
  }

  .xl\:tw-mb-0{
    margin-bottom: 0;
  }

  .xl\:tw-mb-1{
    margin-bottom: 0.25rem;
  }

  .xl\:tw-mb-2{
    margin-bottom: 0.5rem;
  }

  .xl\:tw-mb-3{
    margin-bottom: 0.75rem;
  }

  .xl\:tw-mb-4{
    margin-bottom: 1rem;
  }

  .xl\:tw-mb-5{
    margin-bottom: 1.25rem;
  }

  .xl\:tw-mb-6{
    margin-bottom: 1.5rem;
  }

  .xl\:tw-mb-8{
    margin-bottom: 2rem;
  }

  .xl\:tw-mb-10{
    margin-bottom: 2.5rem;
  }

  .xl\:tw-mb-12{
    margin-bottom: 3rem;
  }

  .xl\:tw-mb-16{
    margin-bottom: 4rem;
  }

  .xl\:tw-mb-20{
    margin-bottom: 5rem;
  }

  .xl\:tw-mb-24{
    margin-bottom: 6rem;
  }

  .xl\:tw-mb-32{
    margin-bottom: 8rem;
  }

  .xl\:tw-mb-40{
    margin-bottom: 10rem;
  }

  .xl\:tw-mb-48{
    margin-bottom: 12rem;
  }

  .xl\:tw-mb-56{
    margin-bottom: 14rem;
  }

  .xl\:tw-mb-64{
    margin-bottom: 16rem;
  }

  .xl\:tw-mb-auto{
    margin-bottom: auto;
  }

  .xl\:tw-mb-px{
    margin-bottom: 1px;
  }

  .xl\:tw--mb-1{
    margin-bottom: -0.25rem;
  }

  .xl\:tw--mb-2{
    margin-bottom: -0.5rem;
  }

  .xl\:tw--mb-3{
    margin-bottom: -0.75rem;
  }

  .xl\:tw--mb-4{
    margin-bottom: -1rem;
  }

  .xl\:tw--mb-5{
    margin-bottom: -1.25rem;
  }

  .xl\:tw--mb-6{
    margin-bottom: -1.5rem;
  }

  .xl\:tw--mb-8{
    margin-bottom: -2rem;
  }

  .xl\:tw--mb-10{
    margin-bottom: -2.5rem;
  }

  .xl\:tw--mb-12{
    margin-bottom: -3rem;
  }

  .xl\:tw--mb-16{
    margin-bottom: -4rem;
  }

  .xl\:tw--mb-20{
    margin-bottom: -5rem;
  }

  .xl\:tw--mb-24{
    margin-bottom: -6rem;
  }

  .xl\:tw--mb-32{
    margin-bottom: -8rem;
  }

  .xl\:tw--mb-40{
    margin-bottom: -10rem;
  }

  .xl\:tw--mb-48{
    margin-bottom: -12rem;
  }

  .xl\:tw--mb-56{
    margin-bottom: -14rem;
  }

  .xl\:tw--mb-64{
    margin-bottom: -16rem;
  }

  .xl\:tw--mb-px{
    margin-bottom: -1px;
  }

  .xl\:tw-ml-0{
    margin-left: 0;
  }

  .xl\:tw-ml-1{
    margin-left: 0.25rem;
  }

  .xl\:tw-ml-2{
    margin-left: 0.5rem;
  }

  .xl\:tw-ml-3{
    margin-left: 0.75rem;
  }

  .xl\:tw-ml-4{
    margin-left: 1rem;
  }

  .xl\:tw-ml-5{
    margin-left: 1.25rem;
  }

  .xl\:tw-ml-6{
    margin-left: 1.5rem;
  }

  .xl\:tw-ml-8{
    margin-left: 2rem;
  }

  .xl\:tw-ml-10{
    margin-left: 2.5rem;
  }

  .xl\:tw-ml-12{
    margin-left: 3rem;
  }

  .xl\:tw-ml-16{
    margin-left: 4rem;
  }

  .xl\:tw-ml-20{
    margin-left: 5rem;
  }

  .xl\:tw-ml-24{
    margin-left: 6rem;
  }

  .xl\:tw-ml-32{
    margin-left: 8rem;
  }

  .xl\:tw-ml-40{
    margin-left: 10rem;
  }

  .xl\:tw-ml-48{
    margin-left: 12rem;
  }

  .xl\:tw-ml-56{
    margin-left: 14rem;
  }

  .xl\:tw-ml-64{
    margin-left: 16rem;
  }

  .xl\:tw-ml-auto{
    margin-left: auto;
  }

  .xl\:tw-ml-px{
    margin-left: 1px;
  }

  .xl\:tw--ml-1{
    margin-left: -0.25rem;
  }

  .xl\:tw--ml-2{
    margin-left: -0.5rem;
  }

  .xl\:tw--ml-3{
    margin-left: -0.75rem;
  }

  .xl\:tw--ml-4{
    margin-left: -1rem;
  }

  .xl\:tw--ml-5{
    margin-left: -1.25rem;
  }

  .xl\:tw--ml-6{
    margin-left: -1.5rem;
  }

  .xl\:tw--ml-8{
    margin-left: -2rem;
  }

  .xl\:tw--ml-10{
    margin-left: -2.5rem;
  }

  .xl\:tw--ml-12{
    margin-left: -3rem;
  }

  .xl\:tw--ml-16{
    margin-left: -4rem;
  }

  .xl\:tw--ml-20{
    margin-left: -5rem;
  }

  .xl\:tw--ml-24{
    margin-left: -6rem;
  }

  .xl\:tw--ml-32{
    margin-left: -8rem;
  }

  .xl\:tw--ml-40{
    margin-left: -10rem;
  }

  .xl\:tw--ml-48{
    margin-left: -12rem;
  }

  .xl\:tw--ml-56{
    margin-left: -14rem;
  }

  .xl\:tw--ml-64{
    margin-left: -16rem;
  }

  .xl\:tw--ml-px{
    margin-left: -1px;
  }

  .xl\:tw-box-border{
    box-sizing: border-box;
  }

  .xl\:tw-box-content{
    box-sizing: content-box;
  }

  .xl\:tw-block{
    display: block;
  }

  .xl\:tw-inline-block{
    display: inline-block;
  }

  .xl\:tw-inline{
    display: inline;
  }

  .xl\:tw-flex{
    display: flex;
  }

  .xl\:tw-inline-flex{
    display: inline-flex;
  }

  .xl\:tw-table{
    display: table;
  }

  .xl\:tw-inline-table{
    display: inline-table;
  }

  .xl\:tw-table-caption{
    display: table-caption;
  }

  .xl\:tw-table-cell{
    display: table-cell;
  }

  .xl\:tw-table-column{
    display: table-column;
  }

  .xl\:tw-table-column-group{
    display: table-column-group;
  }

  .xl\:tw-table-footer-group{
    display: table-footer-group;
  }

  .xl\:tw-table-header-group{
    display: table-header-group;
  }

  .xl\:tw-table-row-group{
    display: table-row-group;
  }

  .xl\:tw-table-row{
    display: table-row;
  }

  .xl\:tw-flow-root{
    display: flow-root;
  }

  .xl\:tw-grid{
    display: grid;
  }

  .xl\:tw-inline-grid{
    display: inline-grid;
  }

  .xl\:tw-contents{
    display: contents;
  }

  .xl\:tw-list-item{
    display: list-item;
  }

  .xl\:tw-hidden{
    display: none;
  }

  .xl\:tw-h-0{
    height: 0;
  }

  .xl\:tw-h-1{
    height: 0.25rem;
  }

  .xl\:tw-h-2{
    height: 0.5rem;
  }

  .xl\:tw-h-3{
    height: 0.75rem;
  }

  .xl\:tw-h-4{
    height: 1rem;
  }

  .xl\:tw-h-5{
    height: 1.25rem;
  }

  .xl\:tw-h-6{
    height: 1.5rem;
  }

  .xl\:tw-h-8{
    height: 2rem;
  }

  .xl\:tw-h-10{
    height: 2.5rem;
  }

  .xl\:tw-h-12{
    height: 3rem;
  }

  .xl\:tw-h-16{
    height: 4rem;
  }

  .xl\:tw-h-20{
    height: 5rem;
  }

  .xl\:tw-h-24{
    height: 6rem;
  }

  .xl\:tw-h-32{
    height: 8rem;
  }

  .xl\:tw-h-40{
    height: 10rem;
  }

  .xl\:tw-h-48{
    height: 12rem;
  }

  .xl\:tw-h-56{
    height: 14rem;
  }

  .xl\:tw-h-64{
    height: 16rem;
  }

  .xl\:tw-h-auto{
    height: auto;
  }

  .xl\:tw-h-px{
    height: 1px;
  }

  .xl\:tw-h-1\/2{
    height: 50%;
  }

  .xl\:tw-h-1\/3{
    height: 33.333333%;
  }

  .xl\:tw-h-2\/3{
    height: 66.666667%;
  }

  .xl\:tw-h-1\/4{
    height: 25%;
  }

  .xl\:tw-h-2\/4{
    height: 50%;
  }

  .xl\:tw-h-3\/4{
    height: 75%;
  }

  .xl\:tw-h-1\/5{
    height: 20%;
  }

  .xl\:tw-h-2\/5{
    height: 40%;
  }

  .xl\:tw-h-3\/5{
    height: 60%;
  }

  .xl\:tw-h-4\/5{
    height: 80%;
  }

  .xl\:tw-h-1\/6{
    height: 16.666667%;
  }

  .xl\:tw-h-2\/6{
    height: 33.333333%;
  }

  .xl\:tw-h-3\/6{
    height: 50%;
  }

  .xl\:tw-h-4\/6{
    height: 66.666667%;
  }

  .xl\:tw-h-5\/6{
    height: 83.333333%;
  }

  .xl\:tw-h-full{
    height: 100%;
  }

  .xl\:tw-h-screen{
    height: 100vh;
  }

  .xl\:tw-max-h-full{
    max-height: 100%;
  }

  .xl\:tw-max-h-screen{
    max-height: 100vh;
  }

  .xl\:tw-min-h-0{
    min-height: 0;
  }

  .xl\:tw-min-h-full{
    min-height: 100%;
  }

  .xl\:tw-min-h-screen{
    min-height: 100vh;
  }

  .xl\:tw-w-0{
    width: 0;
  }

  .xl\:tw-w-1{
    width: 0.25rem;
  }

  .xl\:tw-w-2{
    width: 0.5rem;
  }

  .xl\:tw-w-3{
    width: 0.75rem;
  }

  .xl\:tw-w-4{
    width: 1rem;
  }

  .xl\:tw-w-5{
    width: 1.25rem;
  }

  .xl\:tw-w-6{
    width: 1.5rem;
  }

  .xl\:tw-w-8{
    width: 2rem;
  }

  .xl\:tw-w-10{
    width: 2.5rem;
  }

  .xl\:tw-w-12{
    width: 3rem;
  }

  .xl\:tw-w-16{
    width: 4rem;
  }

  .xl\:tw-w-20{
    width: 5rem;
  }

  .xl\:tw-w-24{
    width: 6rem;
  }

  .xl\:tw-w-32{
    width: 8rem;
  }

  .xl\:tw-w-40{
    width: 10rem;
  }

  .xl\:tw-w-48{
    width: 12rem;
  }

  .xl\:tw-w-56{
    width: 14rem;
  }

  .xl\:tw-w-64{
    width: 16rem;
  }

  .xl\:tw-w-auto{
    width: auto;
  }

  .xl\:tw-w-px{
    width: 1px;
  }

  .xl\:tw-w-1\/2{
    width: 50%;
  }

  .xl\:tw-w-1\/3{
    width: 33.333333%;
  }

  .xl\:tw-w-2\/3{
    width: 66.666667%;
  }

  .xl\:tw-w-1\/4{
    width: 25%;
  }

  .xl\:tw-w-2\/4{
    width: 50%;
  }

  .xl\:tw-w-3\/4{
    width: 75%;
  }

  .xl\:tw-w-1\/5{
    width: 20%;
  }

  .xl\:tw-w-2\/5{
    width: 40%;
  }

  .xl\:tw-w-3\/5{
    width: 60%;
  }

  .xl\:tw-w-4\/5{
    width: 80%;
  }

  .xl\:tw-w-1\/6{
    width: 16.666667%;
  }

  .xl\:tw-w-2\/6{
    width: 33.333333%;
  }

  .xl\:tw-w-3\/6{
    width: 50%;
  }

  .xl\:tw-w-4\/6{
    width: 66.666667%;
  }

  .xl\:tw-w-5\/6{
    width: 83.333333%;
  }

  .xl\:tw-w-1\/12{
    width: 8.333333%;
  }

  .xl\:tw-w-2\/12{
    width: 16.666667%;
  }

  .xl\:tw-w-3\/12{
    width: 25%;
  }

  .xl\:tw-w-4\/12{
    width: 33.333333%;
  }

  .xl\:tw-w-5\/12{
    width: 41.666667%;
  }

  .xl\:tw-w-6\/12{
    width: 50%;
  }

  .xl\:tw-w-7\/12{
    width: 58.333333%;
  }

  .xl\:tw-w-8\/12{
    width: 66.666667%;
  }

  .xl\:tw-w-9\/12{
    width: 75%;
  }

  .xl\:tw-w-10\/12{
    width: 83.333333%;
  }

  .xl\:tw-w-11\/12{
    width: 91.666667%;
  }

  .xl\:tw-w-full{
    width: 100%;
  }

  .xl\:tw-w-screen{
    width: 100vw;
  }

  .xl\:tw-w-min{
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
  }

  .xl\:tw-w-max{
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }

  .xl\:tw-min-w-0{
    min-width: 0;
  }

  .xl\:tw-min-w-full{
    min-width: 100%;
  }

  .xl\:tw-max-w-0{
    max-width: 0rem;
  }

  .xl\:tw-max-w-none{
    max-width: none;
  }

  .xl\:tw-max-w-xs{
    max-width: 20rem;
  }

  .xl\:tw-max-w-sm{
    max-width: 24rem;
  }

  .xl\:tw-max-w-md{
    max-width: 28rem;
  }

  .xl\:tw-max-w-lg{
    max-width: 32rem;
  }

  .xl\:tw-max-w-xl{
    max-width: 36rem;
  }

  .xl\:tw-max-w-2xl{
    max-width: 42rem;
  }

  .xl\:tw-max-w-3xl{
    max-width: 48rem;
  }

  .xl\:tw-max-w-4xl{
    max-width: 56rem;
  }

  .xl\:tw-max-w-5xl{
    max-width: 64rem;
  }

  .xl\:tw-max-w-6xl{
    max-width: 72rem;
  }

  .xl\:tw-max-w-7xl{
    max-width: 80rem;
  }

  .xl\:tw-max-w-full{
    max-width: 100%;
  }

  .xl\:tw-max-w-min{
    max-width: -webkit-min-content;
    max-width: -moz-min-content;
    max-width: min-content;
  }

  .xl\:tw-max-w-max{
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
  }

  .xl\:tw-max-w-prose{
    max-width: 65ch;
  }

  .xl\:tw-max-w-screen-sm{
    max-width: 640px;
  }

  .xl\:tw-max-w-screen-md{
    max-width: 768px;
  }

  .xl\:tw-max-w-screen-lg{
    max-width: 1024px;
  }

  .xl\:tw-max-w-screen-xl{
    max-width: 1280px;
  }

  .xl\:tw-flex-1{
    flex: 1 1 0%;
  }

  .xl\:tw-flex-auto{
    flex: 1 1 auto;
  }

  .xl\:tw-flex-initial{
    flex: 0 1 auto;
  }

  .xl\:tw-flex-none{
    flex: none;
  }

  .xl\:tw-flex-shrink-0{
    flex-shrink: 0;
  }

  .xl\:tw-flex-shrink-default{
    flex-shrink: 1;
  }

  .xl\:tw-flex-grow-0{
    flex-grow: 0;
  }

  .xl\:tw-flex-grow-default{
    flex-grow: 1;
  }

  .xl\:tw-table-auto{
    table-layout: auto;
  }

  .xl\:tw-table-fixed{
    table-layout: fixed;
  }

  .xl\:tw-border-collapse{
    border-collapse: collapse;
  }

  .xl\:tw-border-separate{
    border-collapse: separate;
  }

  .xl\:tw-origin-center{
    transform-origin: center;
  }

  .xl\:tw-origin-top{
    transform-origin: top;
  }

  .xl\:tw-origin-top-right{
    transform-origin: top right;
  }

  .xl\:tw-origin-right{
    transform-origin: right;
  }

  .xl\:tw-origin-bottom-right{
    transform-origin: bottom right;
  }

  .xl\:tw-origin-bottom{
    transform-origin: bottom;
  }

  .xl\:tw-origin-bottom-left{
    transform-origin: bottom left;
  }

  .xl\:tw-origin-left{
    transform-origin: left;
  }

  .xl\:tw-origin-top-left{
    transform-origin: top left;
  }

  .xl\:tw-transform{
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:tw-transform-gpu{
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:tw-transform-none{
    transform: none;
  }

  .xl\:tw-translate-x-0{
    --tw-translate-x: 0;
  }

  .xl\:tw-translate-x-1{
    --tw-translate-x: 0.25rem;
  }

  .xl\:tw-translate-x-2{
    --tw-translate-x: 0.5rem;
  }

  .xl\:tw-translate-x-3{
    --tw-translate-x: 0.75rem;
  }

  .xl\:tw-translate-x-4{
    --tw-translate-x: 1rem;
  }

  .xl\:tw-translate-x-5{
    --tw-translate-x: 1.25rem;
  }

  .xl\:tw-translate-x-6{
    --tw-translate-x: 1.5rem;
  }

  .xl\:tw-translate-x-8{
    --tw-translate-x: 2rem;
  }

  .xl\:tw-translate-x-10{
    --tw-translate-x: 2.5rem;
  }

  .xl\:tw-translate-x-12{
    --tw-translate-x: 3rem;
  }

  .xl\:tw-translate-x-16{
    --tw-translate-x: 4rem;
  }

  .xl\:tw-translate-x-20{
    --tw-translate-x: 5rem;
  }

  .xl\:tw-translate-x-24{
    --tw-translate-x: 6rem;
  }

  .xl\:tw-translate-x-32{
    --tw-translate-x: 8rem;
  }

  .xl\:tw-translate-x-40{
    --tw-translate-x: 10rem;
  }

  .xl\:tw-translate-x-48{
    --tw-translate-x: 12rem;
  }

  .xl\:tw-translate-x-56{
    --tw-translate-x: 14rem;
  }

  .xl\:tw-translate-x-64{
    --tw-translate-x: 16rem;
  }

  .xl\:tw-translate-x-px{
    --tw-translate-x: 1px;
  }

  .xl\:tw--translate-x-1{
    --tw-translate-x: -0.25rem;
  }

  .xl\:tw--translate-x-2{
    --tw-translate-x: -0.5rem;
  }

  .xl\:tw--translate-x-3{
    --tw-translate-x: -0.75rem;
  }

  .xl\:tw--translate-x-4{
    --tw-translate-x: -1rem;
  }

  .xl\:tw--translate-x-5{
    --tw-translate-x: -1.25rem;
  }

  .xl\:tw--translate-x-6{
    --tw-translate-x: -1.5rem;
  }

  .xl\:tw--translate-x-8{
    --tw-translate-x: -2rem;
  }

  .xl\:tw--translate-x-10{
    --tw-translate-x: -2.5rem;
  }

  .xl\:tw--translate-x-12{
    --tw-translate-x: -3rem;
  }

  .xl\:tw--translate-x-16{
    --tw-translate-x: -4rem;
  }

  .xl\:tw--translate-x-20{
    --tw-translate-x: -5rem;
  }

  .xl\:tw--translate-x-24{
    --tw-translate-x: -6rem;
  }

  .xl\:tw--translate-x-32{
    --tw-translate-x: -8rem;
  }

  .xl\:tw--translate-x-40{
    --tw-translate-x: -10rem;
  }

  .xl\:tw--translate-x-48{
    --tw-translate-x: -12rem;
  }

  .xl\:tw--translate-x-56{
    --tw-translate-x: -14rem;
  }

  .xl\:tw--translate-x-64{
    --tw-translate-x: -16rem;
  }

  .xl\:tw--translate-x-px{
    --tw-translate-x: -1px;
  }

  .xl\:tw-translate-x-1\/2{
    --tw-translate-x: 50%;
  }

  .xl\:tw-translate-x-1\/3{
    --tw-translate-x: 33.333333%;
  }

  .xl\:tw-translate-x-2\/3{
    --tw-translate-x: 66.666667%;
  }

  .xl\:tw-translate-x-1\/4{
    --tw-translate-x: 25%;
  }

  .xl\:tw-translate-x-2\/4{
    --tw-translate-x: 50%;
  }

  .xl\:tw-translate-x-3\/4{
    --tw-translate-x: 75%;
  }

  .xl\:tw-translate-x-full{
    --tw-translate-x: 100%;
  }

  .xl\:tw--translate-x-1\/2{
    --tw-translate-x: -50%;
  }

  .xl\:tw--translate-x-1\/3{
    --tw-translate-x: -33.333333%;
  }

  .xl\:tw--translate-x-2\/3{
    --tw-translate-x: -66.666667%;
  }

  .xl\:tw--translate-x-1\/4{
    --tw-translate-x: -25%;
  }

  .xl\:tw--translate-x-2\/4{
    --tw-translate-x: -50%;
  }

  .xl\:tw--translate-x-3\/4{
    --tw-translate-x: -75%;
  }

  .xl\:tw--translate-x-full{
    --tw-translate-x: -100%;
  }

  .xl\:tw-translate-y-0{
    --tw-translate-y: 0;
  }

  .xl\:tw-translate-y-1{
    --tw-translate-y: 0.25rem;
  }

  .xl\:tw-translate-y-2{
    --tw-translate-y: 0.5rem;
  }

  .xl\:tw-translate-y-3{
    --tw-translate-y: 0.75rem;
  }

  .xl\:tw-translate-y-4{
    --tw-translate-y: 1rem;
  }

  .xl\:tw-translate-y-5{
    --tw-translate-y: 1.25rem;
  }

  .xl\:tw-translate-y-6{
    --tw-translate-y: 1.5rem;
  }

  .xl\:tw-translate-y-8{
    --tw-translate-y: 2rem;
  }

  .xl\:tw-translate-y-10{
    --tw-translate-y: 2.5rem;
  }

  .xl\:tw-translate-y-12{
    --tw-translate-y: 3rem;
  }

  .xl\:tw-translate-y-16{
    --tw-translate-y: 4rem;
  }

  .xl\:tw-translate-y-20{
    --tw-translate-y: 5rem;
  }

  .xl\:tw-translate-y-24{
    --tw-translate-y: 6rem;
  }

  .xl\:tw-translate-y-32{
    --tw-translate-y: 8rem;
  }

  .xl\:tw-translate-y-40{
    --tw-translate-y: 10rem;
  }

  .xl\:tw-translate-y-48{
    --tw-translate-y: 12rem;
  }

  .xl\:tw-translate-y-56{
    --tw-translate-y: 14rem;
  }

  .xl\:tw-translate-y-64{
    --tw-translate-y: 16rem;
  }

  .xl\:tw-translate-y-px{
    --tw-translate-y: 1px;
  }

  .xl\:tw--translate-y-1{
    --tw-translate-y: -0.25rem;
  }

  .xl\:tw--translate-y-2{
    --tw-translate-y: -0.5rem;
  }

  .xl\:tw--translate-y-3{
    --tw-translate-y: -0.75rem;
  }

  .xl\:tw--translate-y-4{
    --tw-translate-y: -1rem;
  }

  .xl\:tw--translate-y-5{
    --tw-translate-y: -1.25rem;
  }

  .xl\:tw--translate-y-6{
    --tw-translate-y: -1.5rem;
  }

  .xl\:tw--translate-y-8{
    --tw-translate-y: -2rem;
  }

  .xl\:tw--translate-y-10{
    --tw-translate-y: -2.5rem;
  }

  .xl\:tw--translate-y-12{
    --tw-translate-y: -3rem;
  }

  .xl\:tw--translate-y-16{
    --tw-translate-y: -4rem;
  }

  .xl\:tw--translate-y-20{
    --tw-translate-y: -5rem;
  }

  .xl\:tw--translate-y-24{
    --tw-translate-y: -6rem;
  }

  .xl\:tw--translate-y-32{
    --tw-translate-y: -8rem;
  }

  .xl\:tw--translate-y-40{
    --tw-translate-y: -10rem;
  }

  .xl\:tw--translate-y-48{
    --tw-translate-y: -12rem;
  }

  .xl\:tw--translate-y-56{
    --tw-translate-y: -14rem;
  }

  .xl\:tw--translate-y-64{
    --tw-translate-y: -16rem;
  }

  .xl\:tw--translate-y-px{
    --tw-translate-y: -1px;
  }

  .xl\:tw-translate-y-1\/2{
    --tw-translate-y: 50%;
  }

  .xl\:tw-translate-y-1\/3{
    --tw-translate-y: 33.333333%;
  }

  .xl\:tw-translate-y-2\/3{
    --tw-translate-y: 66.666667%;
  }

  .xl\:tw-translate-y-1\/4{
    --tw-translate-y: 25%;
  }

  .xl\:tw-translate-y-2\/4{
    --tw-translate-y: 50%;
  }

  .xl\:tw-translate-y-3\/4{
    --tw-translate-y: 75%;
  }

  .xl\:tw-translate-y-full{
    --tw-translate-y: 100%;
  }

  .xl\:tw--translate-y-1\/2{
    --tw-translate-y: -50%;
  }

  .xl\:tw--translate-y-1\/3{
    --tw-translate-y: -33.333333%;
  }

  .xl\:tw--translate-y-2\/3{
    --tw-translate-y: -66.666667%;
  }

  .xl\:tw--translate-y-1\/4{
    --tw-translate-y: -25%;
  }

  .xl\:tw--translate-y-2\/4{
    --tw-translate-y: -50%;
  }

  .xl\:tw--translate-y-3\/4{
    --tw-translate-y: -75%;
  }

  .xl\:tw--translate-y-full{
    --tw-translate-y: -100%;
  }

  .xl\:hover\:tw-translate-x-0:hover{
    --tw-translate-x: 0;
  }

  .xl\:hover\:tw-translate-x-1:hover{
    --tw-translate-x: 0.25rem;
  }

  .xl\:hover\:tw-translate-x-2:hover{
    --tw-translate-x: 0.5rem;
  }

  .xl\:hover\:tw-translate-x-3:hover{
    --tw-translate-x: 0.75rem;
  }

  .xl\:hover\:tw-translate-x-4:hover{
    --tw-translate-x: 1rem;
  }

  .xl\:hover\:tw-translate-x-5:hover{
    --tw-translate-x: 1.25rem;
  }

  .xl\:hover\:tw-translate-x-6:hover{
    --tw-translate-x: 1.5rem;
  }

  .xl\:hover\:tw-translate-x-8:hover{
    --tw-translate-x: 2rem;
  }

  .xl\:hover\:tw-translate-x-10:hover{
    --tw-translate-x: 2.5rem;
  }

  .xl\:hover\:tw-translate-x-12:hover{
    --tw-translate-x: 3rem;
  }

  .xl\:hover\:tw-translate-x-16:hover{
    --tw-translate-x: 4rem;
  }

  .xl\:hover\:tw-translate-x-20:hover{
    --tw-translate-x: 5rem;
  }

  .xl\:hover\:tw-translate-x-24:hover{
    --tw-translate-x: 6rem;
  }

  .xl\:hover\:tw-translate-x-32:hover{
    --tw-translate-x: 8rem;
  }

  .xl\:hover\:tw-translate-x-40:hover{
    --tw-translate-x: 10rem;
  }

  .xl\:hover\:tw-translate-x-48:hover{
    --tw-translate-x: 12rem;
  }

  .xl\:hover\:tw-translate-x-56:hover{
    --tw-translate-x: 14rem;
  }

  .xl\:hover\:tw-translate-x-64:hover{
    --tw-translate-x: 16rem;
  }

  .xl\:hover\:tw-translate-x-px:hover{
    --tw-translate-x: 1px;
  }

  .xl\:hover\:tw--translate-x-1:hover{
    --tw-translate-x: -0.25rem;
  }

  .xl\:hover\:tw--translate-x-2:hover{
    --tw-translate-x: -0.5rem;
  }

  .xl\:hover\:tw--translate-x-3:hover{
    --tw-translate-x: -0.75rem;
  }

  .xl\:hover\:tw--translate-x-4:hover{
    --tw-translate-x: -1rem;
  }

  .xl\:hover\:tw--translate-x-5:hover{
    --tw-translate-x: -1.25rem;
  }

  .xl\:hover\:tw--translate-x-6:hover{
    --tw-translate-x: -1.5rem;
  }

  .xl\:hover\:tw--translate-x-8:hover{
    --tw-translate-x: -2rem;
  }

  .xl\:hover\:tw--translate-x-10:hover{
    --tw-translate-x: -2.5rem;
  }

  .xl\:hover\:tw--translate-x-12:hover{
    --tw-translate-x: -3rem;
  }

  .xl\:hover\:tw--translate-x-16:hover{
    --tw-translate-x: -4rem;
  }

  .xl\:hover\:tw--translate-x-20:hover{
    --tw-translate-x: -5rem;
  }

  .xl\:hover\:tw--translate-x-24:hover{
    --tw-translate-x: -6rem;
  }

  .xl\:hover\:tw--translate-x-32:hover{
    --tw-translate-x: -8rem;
  }

  .xl\:hover\:tw--translate-x-40:hover{
    --tw-translate-x: -10rem;
  }

  .xl\:hover\:tw--translate-x-48:hover{
    --tw-translate-x: -12rem;
  }

  .xl\:hover\:tw--translate-x-56:hover{
    --tw-translate-x: -14rem;
  }

  .xl\:hover\:tw--translate-x-64:hover{
    --tw-translate-x: -16rem;
  }

  .xl\:hover\:tw--translate-x-px:hover{
    --tw-translate-x: -1px;
  }

  .xl\:hover\:tw-translate-x-1\/2:hover{
    --tw-translate-x: 50%;
  }

  .xl\:hover\:tw-translate-x-1\/3:hover{
    --tw-translate-x: 33.333333%;
  }

  .xl\:hover\:tw-translate-x-2\/3:hover{
    --tw-translate-x: 66.666667%;
  }

  .xl\:hover\:tw-translate-x-1\/4:hover{
    --tw-translate-x: 25%;
  }

  .xl\:hover\:tw-translate-x-2\/4:hover{
    --tw-translate-x: 50%;
  }

  .xl\:hover\:tw-translate-x-3\/4:hover{
    --tw-translate-x: 75%;
  }

  .xl\:hover\:tw-translate-x-full:hover{
    --tw-translate-x: 100%;
  }

  .xl\:hover\:tw--translate-x-1\/2:hover{
    --tw-translate-x: -50%;
  }

  .xl\:hover\:tw--translate-x-1\/3:hover{
    --tw-translate-x: -33.333333%;
  }

  .xl\:hover\:tw--translate-x-2\/3:hover{
    --tw-translate-x: -66.666667%;
  }

  .xl\:hover\:tw--translate-x-1\/4:hover{
    --tw-translate-x: -25%;
  }

  .xl\:hover\:tw--translate-x-2\/4:hover{
    --tw-translate-x: -50%;
  }

  .xl\:hover\:tw--translate-x-3\/4:hover{
    --tw-translate-x: -75%;
  }

  .xl\:hover\:tw--translate-x-full:hover{
    --tw-translate-x: -100%;
  }

  .xl\:hover\:tw-translate-y-0:hover{
    --tw-translate-y: 0;
  }

  .xl\:hover\:tw-translate-y-1:hover{
    --tw-translate-y: 0.25rem;
  }

  .xl\:hover\:tw-translate-y-2:hover{
    --tw-translate-y: 0.5rem;
  }

  .xl\:hover\:tw-translate-y-3:hover{
    --tw-translate-y: 0.75rem;
  }

  .xl\:hover\:tw-translate-y-4:hover{
    --tw-translate-y: 1rem;
  }

  .xl\:hover\:tw-translate-y-5:hover{
    --tw-translate-y: 1.25rem;
  }

  .xl\:hover\:tw-translate-y-6:hover{
    --tw-translate-y: 1.5rem;
  }

  .xl\:hover\:tw-translate-y-8:hover{
    --tw-translate-y: 2rem;
  }

  .xl\:hover\:tw-translate-y-10:hover{
    --tw-translate-y: 2.5rem;
  }

  .xl\:hover\:tw-translate-y-12:hover{
    --tw-translate-y: 3rem;
  }

  .xl\:hover\:tw-translate-y-16:hover{
    --tw-translate-y: 4rem;
  }

  .xl\:hover\:tw-translate-y-20:hover{
    --tw-translate-y: 5rem;
  }

  .xl\:hover\:tw-translate-y-24:hover{
    --tw-translate-y: 6rem;
  }

  .xl\:hover\:tw-translate-y-32:hover{
    --tw-translate-y: 8rem;
  }

  .xl\:hover\:tw-translate-y-40:hover{
    --tw-translate-y: 10rem;
  }

  .xl\:hover\:tw-translate-y-48:hover{
    --tw-translate-y: 12rem;
  }

  .xl\:hover\:tw-translate-y-56:hover{
    --tw-translate-y: 14rem;
  }

  .xl\:hover\:tw-translate-y-64:hover{
    --tw-translate-y: 16rem;
  }

  .xl\:hover\:tw-translate-y-px:hover{
    --tw-translate-y: 1px;
  }

  .xl\:hover\:tw--translate-y-1:hover{
    --tw-translate-y: -0.25rem;
  }

  .xl\:hover\:tw--translate-y-2:hover{
    --tw-translate-y: -0.5rem;
  }

  .xl\:hover\:tw--translate-y-3:hover{
    --tw-translate-y: -0.75rem;
  }

  .xl\:hover\:tw--translate-y-4:hover{
    --tw-translate-y: -1rem;
  }

  .xl\:hover\:tw--translate-y-5:hover{
    --tw-translate-y: -1.25rem;
  }

  .xl\:hover\:tw--translate-y-6:hover{
    --tw-translate-y: -1.5rem;
  }

  .xl\:hover\:tw--translate-y-8:hover{
    --tw-translate-y: -2rem;
  }

  .xl\:hover\:tw--translate-y-10:hover{
    --tw-translate-y: -2.5rem;
  }

  .xl\:hover\:tw--translate-y-12:hover{
    --tw-translate-y: -3rem;
  }

  .xl\:hover\:tw--translate-y-16:hover{
    --tw-translate-y: -4rem;
  }

  .xl\:hover\:tw--translate-y-20:hover{
    --tw-translate-y: -5rem;
  }

  .xl\:hover\:tw--translate-y-24:hover{
    --tw-translate-y: -6rem;
  }

  .xl\:hover\:tw--translate-y-32:hover{
    --tw-translate-y: -8rem;
  }

  .xl\:hover\:tw--translate-y-40:hover{
    --tw-translate-y: -10rem;
  }

  .xl\:hover\:tw--translate-y-48:hover{
    --tw-translate-y: -12rem;
  }

  .xl\:hover\:tw--translate-y-56:hover{
    --tw-translate-y: -14rem;
  }

  .xl\:hover\:tw--translate-y-64:hover{
    --tw-translate-y: -16rem;
  }

  .xl\:hover\:tw--translate-y-px:hover{
    --tw-translate-y: -1px;
  }

  .xl\:hover\:tw-translate-y-1\/2:hover{
    --tw-translate-y: 50%;
  }

  .xl\:hover\:tw-translate-y-1\/3:hover{
    --tw-translate-y: 33.333333%;
  }

  .xl\:hover\:tw-translate-y-2\/3:hover{
    --tw-translate-y: 66.666667%;
  }

  .xl\:hover\:tw-translate-y-1\/4:hover{
    --tw-translate-y: 25%;
  }

  .xl\:hover\:tw-translate-y-2\/4:hover{
    --tw-translate-y: 50%;
  }

  .xl\:hover\:tw-translate-y-3\/4:hover{
    --tw-translate-y: 75%;
  }

  .xl\:hover\:tw-translate-y-full:hover{
    --tw-translate-y: 100%;
  }

  .xl\:hover\:tw--translate-y-1\/2:hover{
    --tw-translate-y: -50%;
  }

  .xl\:hover\:tw--translate-y-1\/3:hover{
    --tw-translate-y: -33.333333%;
  }

  .xl\:hover\:tw--translate-y-2\/3:hover{
    --tw-translate-y: -66.666667%;
  }

  .xl\:hover\:tw--translate-y-1\/4:hover{
    --tw-translate-y: -25%;
  }

  .xl\:hover\:tw--translate-y-2\/4:hover{
    --tw-translate-y: -50%;
  }

  .xl\:hover\:tw--translate-y-3\/4:hover{
    --tw-translate-y: -75%;
  }

  .xl\:hover\:tw--translate-y-full:hover{
    --tw-translate-y: -100%;
  }

  .xl\:focus\:tw-translate-x-0:focus{
    --tw-translate-x: 0;
  }

  .xl\:focus\:tw-translate-x-1:focus{
    --tw-translate-x: 0.25rem;
  }

  .xl\:focus\:tw-translate-x-2:focus{
    --tw-translate-x: 0.5rem;
  }

  .xl\:focus\:tw-translate-x-3:focus{
    --tw-translate-x: 0.75rem;
  }

  .xl\:focus\:tw-translate-x-4:focus{
    --tw-translate-x: 1rem;
  }

  .xl\:focus\:tw-translate-x-5:focus{
    --tw-translate-x: 1.25rem;
  }

  .xl\:focus\:tw-translate-x-6:focus{
    --tw-translate-x: 1.5rem;
  }

  .xl\:focus\:tw-translate-x-8:focus{
    --tw-translate-x: 2rem;
  }

  .xl\:focus\:tw-translate-x-10:focus{
    --tw-translate-x: 2.5rem;
  }

  .xl\:focus\:tw-translate-x-12:focus{
    --tw-translate-x: 3rem;
  }

  .xl\:focus\:tw-translate-x-16:focus{
    --tw-translate-x: 4rem;
  }

  .xl\:focus\:tw-translate-x-20:focus{
    --tw-translate-x: 5rem;
  }

  .xl\:focus\:tw-translate-x-24:focus{
    --tw-translate-x: 6rem;
  }

  .xl\:focus\:tw-translate-x-32:focus{
    --tw-translate-x: 8rem;
  }

  .xl\:focus\:tw-translate-x-40:focus{
    --tw-translate-x: 10rem;
  }

  .xl\:focus\:tw-translate-x-48:focus{
    --tw-translate-x: 12rem;
  }

  .xl\:focus\:tw-translate-x-56:focus{
    --tw-translate-x: 14rem;
  }

  .xl\:focus\:tw-translate-x-64:focus{
    --tw-translate-x: 16rem;
  }

  .xl\:focus\:tw-translate-x-px:focus{
    --tw-translate-x: 1px;
  }

  .xl\:focus\:tw--translate-x-1:focus{
    --tw-translate-x: -0.25rem;
  }

  .xl\:focus\:tw--translate-x-2:focus{
    --tw-translate-x: -0.5rem;
  }

  .xl\:focus\:tw--translate-x-3:focus{
    --tw-translate-x: -0.75rem;
  }

  .xl\:focus\:tw--translate-x-4:focus{
    --tw-translate-x: -1rem;
  }

  .xl\:focus\:tw--translate-x-5:focus{
    --tw-translate-x: -1.25rem;
  }

  .xl\:focus\:tw--translate-x-6:focus{
    --tw-translate-x: -1.5rem;
  }

  .xl\:focus\:tw--translate-x-8:focus{
    --tw-translate-x: -2rem;
  }

  .xl\:focus\:tw--translate-x-10:focus{
    --tw-translate-x: -2.5rem;
  }

  .xl\:focus\:tw--translate-x-12:focus{
    --tw-translate-x: -3rem;
  }

  .xl\:focus\:tw--translate-x-16:focus{
    --tw-translate-x: -4rem;
  }

  .xl\:focus\:tw--translate-x-20:focus{
    --tw-translate-x: -5rem;
  }

  .xl\:focus\:tw--translate-x-24:focus{
    --tw-translate-x: -6rem;
  }

  .xl\:focus\:tw--translate-x-32:focus{
    --tw-translate-x: -8rem;
  }

  .xl\:focus\:tw--translate-x-40:focus{
    --tw-translate-x: -10rem;
  }

  .xl\:focus\:tw--translate-x-48:focus{
    --tw-translate-x: -12rem;
  }

  .xl\:focus\:tw--translate-x-56:focus{
    --tw-translate-x: -14rem;
  }

  .xl\:focus\:tw--translate-x-64:focus{
    --tw-translate-x: -16rem;
  }

  .xl\:focus\:tw--translate-x-px:focus{
    --tw-translate-x: -1px;
  }

  .xl\:focus\:tw-translate-x-1\/2:focus{
    --tw-translate-x: 50%;
  }

  .xl\:focus\:tw-translate-x-1\/3:focus{
    --tw-translate-x: 33.333333%;
  }

  .xl\:focus\:tw-translate-x-2\/3:focus{
    --tw-translate-x: 66.666667%;
  }

  .xl\:focus\:tw-translate-x-1\/4:focus{
    --tw-translate-x: 25%;
  }

  .xl\:focus\:tw-translate-x-2\/4:focus{
    --tw-translate-x: 50%;
  }

  .xl\:focus\:tw-translate-x-3\/4:focus{
    --tw-translate-x: 75%;
  }

  .xl\:focus\:tw-translate-x-full:focus{
    --tw-translate-x: 100%;
  }

  .xl\:focus\:tw--translate-x-1\/2:focus{
    --tw-translate-x: -50%;
  }

  .xl\:focus\:tw--translate-x-1\/3:focus{
    --tw-translate-x: -33.333333%;
  }

  .xl\:focus\:tw--translate-x-2\/3:focus{
    --tw-translate-x: -66.666667%;
  }

  .xl\:focus\:tw--translate-x-1\/4:focus{
    --tw-translate-x: -25%;
  }

  .xl\:focus\:tw--translate-x-2\/4:focus{
    --tw-translate-x: -50%;
  }

  .xl\:focus\:tw--translate-x-3\/4:focus{
    --tw-translate-x: -75%;
  }

  .xl\:focus\:tw--translate-x-full:focus{
    --tw-translate-x: -100%;
  }

  .xl\:focus\:tw-translate-y-0:focus{
    --tw-translate-y: 0;
  }

  .xl\:focus\:tw-translate-y-1:focus{
    --tw-translate-y: 0.25rem;
  }

  .xl\:focus\:tw-translate-y-2:focus{
    --tw-translate-y: 0.5rem;
  }

  .xl\:focus\:tw-translate-y-3:focus{
    --tw-translate-y: 0.75rem;
  }

  .xl\:focus\:tw-translate-y-4:focus{
    --tw-translate-y: 1rem;
  }

  .xl\:focus\:tw-translate-y-5:focus{
    --tw-translate-y: 1.25rem;
  }

  .xl\:focus\:tw-translate-y-6:focus{
    --tw-translate-y: 1.5rem;
  }

  .xl\:focus\:tw-translate-y-8:focus{
    --tw-translate-y: 2rem;
  }

  .xl\:focus\:tw-translate-y-10:focus{
    --tw-translate-y: 2.5rem;
  }

  .xl\:focus\:tw-translate-y-12:focus{
    --tw-translate-y: 3rem;
  }

  .xl\:focus\:tw-translate-y-16:focus{
    --tw-translate-y: 4rem;
  }

  .xl\:focus\:tw-translate-y-20:focus{
    --tw-translate-y: 5rem;
  }

  .xl\:focus\:tw-translate-y-24:focus{
    --tw-translate-y: 6rem;
  }

  .xl\:focus\:tw-translate-y-32:focus{
    --tw-translate-y: 8rem;
  }

  .xl\:focus\:tw-translate-y-40:focus{
    --tw-translate-y: 10rem;
  }

  .xl\:focus\:tw-translate-y-48:focus{
    --tw-translate-y: 12rem;
  }

  .xl\:focus\:tw-translate-y-56:focus{
    --tw-translate-y: 14rem;
  }

  .xl\:focus\:tw-translate-y-64:focus{
    --tw-translate-y: 16rem;
  }

  .xl\:focus\:tw-translate-y-px:focus{
    --tw-translate-y: 1px;
  }

  .xl\:focus\:tw--translate-y-1:focus{
    --tw-translate-y: -0.25rem;
  }

  .xl\:focus\:tw--translate-y-2:focus{
    --tw-translate-y: -0.5rem;
  }

  .xl\:focus\:tw--translate-y-3:focus{
    --tw-translate-y: -0.75rem;
  }

  .xl\:focus\:tw--translate-y-4:focus{
    --tw-translate-y: -1rem;
  }

  .xl\:focus\:tw--translate-y-5:focus{
    --tw-translate-y: -1.25rem;
  }

  .xl\:focus\:tw--translate-y-6:focus{
    --tw-translate-y: -1.5rem;
  }

  .xl\:focus\:tw--translate-y-8:focus{
    --tw-translate-y: -2rem;
  }

  .xl\:focus\:tw--translate-y-10:focus{
    --tw-translate-y: -2.5rem;
  }

  .xl\:focus\:tw--translate-y-12:focus{
    --tw-translate-y: -3rem;
  }

  .xl\:focus\:tw--translate-y-16:focus{
    --tw-translate-y: -4rem;
  }

  .xl\:focus\:tw--translate-y-20:focus{
    --tw-translate-y: -5rem;
  }

  .xl\:focus\:tw--translate-y-24:focus{
    --tw-translate-y: -6rem;
  }

  .xl\:focus\:tw--translate-y-32:focus{
    --tw-translate-y: -8rem;
  }

  .xl\:focus\:tw--translate-y-40:focus{
    --tw-translate-y: -10rem;
  }

  .xl\:focus\:tw--translate-y-48:focus{
    --tw-translate-y: -12rem;
  }

  .xl\:focus\:tw--translate-y-56:focus{
    --tw-translate-y: -14rem;
  }

  .xl\:focus\:tw--translate-y-64:focus{
    --tw-translate-y: -16rem;
  }

  .xl\:focus\:tw--translate-y-px:focus{
    --tw-translate-y: -1px;
  }

  .xl\:focus\:tw-translate-y-1\/2:focus{
    --tw-translate-y: 50%;
  }

  .xl\:focus\:tw-translate-y-1\/3:focus{
    --tw-translate-y: 33.333333%;
  }

  .xl\:focus\:tw-translate-y-2\/3:focus{
    --tw-translate-y: 66.666667%;
  }

  .xl\:focus\:tw-translate-y-1\/4:focus{
    --tw-translate-y: 25%;
  }

  .xl\:focus\:tw-translate-y-2\/4:focus{
    --tw-translate-y: 50%;
  }

  .xl\:focus\:tw-translate-y-3\/4:focus{
    --tw-translate-y: 75%;
  }

  .xl\:focus\:tw-translate-y-full:focus{
    --tw-translate-y: 100%;
  }

  .xl\:focus\:tw--translate-y-1\/2:focus{
    --tw-translate-y: -50%;
  }

  .xl\:focus\:tw--translate-y-1\/3:focus{
    --tw-translate-y: -33.333333%;
  }

  .xl\:focus\:tw--translate-y-2\/3:focus{
    --tw-translate-y: -66.666667%;
  }

  .xl\:focus\:tw--translate-y-1\/4:focus{
    --tw-translate-y: -25%;
  }

  .xl\:focus\:tw--translate-y-2\/4:focus{
    --tw-translate-y: -50%;
  }

  .xl\:focus\:tw--translate-y-3\/4:focus{
    --tw-translate-y: -75%;
  }

  .xl\:focus\:tw--translate-y-full:focus{
    --tw-translate-y: -100%;
  }

  .xl\:tw-rotate-0{
    --tw-rotate: 0;
  }

  .xl\:tw-rotate-1{
    --tw-rotate: 1deg;
  }

  .xl\:tw-rotate-2{
    --tw-rotate: 2deg;
  }

  .xl\:tw-rotate-3{
    --tw-rotate: 3deg;
  }

  .xl\:tw-rotate-6{
    --tw-rotate: 6deg;
  }

  .xl\:tw-rotate-12{
    --tw-rotate: 12deg;
  }

  .xl\:tw-rotate-45{
    --tw-rotate: 45deg;
  }

  .xl\:tw-rotate-90{
    --tw-rotate: 90deg;
  }

  .xl\:tw-rotate-180{
    --tw-rotate: 180deg;
  }

  .xl\:tw--rotate-180{
    --tw-rotate: -180deg;
  }

  .xl\:tw--rotate-90{
    --tw-rotate: -90deg;
  }

  .xl\:tw--rotate-45{
    --tw-rotate: -45deg;
  }

  .xl\:tw--rotate-12{
    --tw-rotate: -12deg;
  }

  .xl\:tw--rotate-6{
    --tw-rotate: -6deg;
  }

  .xl\:tw--rotate-3{
    --tw-rotate: -3deg;
  }

  .xl\:tw--rotate-2{
    --tw-rotate: -2deg;
  }

  .xl\:tw--rotate-1{
    --tw-rotate: -1deg;
  }

  .xl\:hover\:tw-rotate-0:hover{
    --tw-rotate: 0;
  }

  .xl\:hover\:tw-rotate-1:hover{
    --tw-rotate: 1deg;
  }

  .xl\:hover\:tw-rotate-2:hover{
    --tw-rotate: 2deg;
  }

  .xl\:hover\:tw-rotate-3:hover{
    --tw-rotate: 3deg;
  }

  .xl\:hover\:tw-rotate-6:hover{
    --tw-rotate: 6deg;
  }

  .xl\:hover\:tw-rotate-12:hover{
    --tw-rotate: 12deg;
  }

  .xl\:hover\:tw-rotate-45:hover{
    --tw-rotate: 45deg;
  }

  .xl\:hover\:tw-rotate-90:hover{
    --tw-rotate: 90deg;
  }

  .xl\:hover\:tw-rotate-180:hover{
    --tw-rotate: 180deg;
  }

  .xl\:hover\:tw--rotate-180:hover{
    --tw-rotate: -180deg;
  }

  .xl\:hover\:tw--rotate-90:hover{
    --tw-rotate: -90deg;
  }

  .xl\:hover\:tw--rotate-45:hover{
    --tw-rotate: -45deg;
  }

  .xl\:hover\:tw--rotate-12:hover{
    --tw-rotate: -12deg;
  }

  .xl\:hover\:tw--rotate-6:hover{
    --tw-rotate: -6deg;
  }

  .xl\:hover\:tw--rotate-3:hover{
    --tw-rotate: -3deg;
  }

  .xl\:hover\:tw--rotate-2:hover{
    --tw-rotate: -2deg;
  }

  .xl\:hover\:tw--rotate-1:hover{
    --tw-rotate: -1deg;
  }

  .xl\:focus\:tw-rotate-0:focus{
    --tw-rotate: 0;
  }

  .xl\:focus\:tw-rotate-1:focus{
    --tw-rotate: 1deg;
  }

  .xl\:focus\:tw-rotate-2:focus{
    --tw-rotate: 2deg;
  }

  .xl\:focus\:tw-rotate-3:focus{
    --tw-rotate: 3deg;
  }

  .xl\:focus\:tw-rotate-6:focus{
    --tw-rotate: 6deg;
  }

  .xl\:focus\:tw-rotate-12:focus{
    --tw-rotate: 12deg;
  }

  .xl\:focus\:tw-rotate-45:focus{
    --tw-rotate: 45deg;
  }

  .xl\:focus\:tw-rotate-90:focus{
    --tw-rotate: 90deg;
  }

  .xl\:focus\:tw-rotate-180:focus{
    --tw-rotate: 180deg;
  }

  .xl\:focus\:tw--rotate-180:focus{
    --tw-rotate: -180deg;
  }

  .xl\:focus\:tw--rotate-90:focus{
    --tw-rotate: -90deg;
  }

  .xl\:focus\:tw--rotate-45:focus{
    --tw-rotate: -45deg;
  }

  .xl\:focus\:tw--rotate-12:focus{
    --tw-rotate: -12deg;
  }

  .xl\:focus\:tw--rotate-6:focus{
    --tw-rotate: -6deg;
  }

  .xl\:focus\:tw--rotate-3:focus{
    --tw-rotate: -3deg;
  }

  .xl\:focus\:tw--rotate-2:focus{
    --tw-rotate: -2deg;
  }

  .xl\:focus\:tw--rotate-1:focus{
    --tw-rotate: -1deg;
  }

  .xl\:tw-skew-x-0{
    --tw-skew-x: 0;
  }

  .xl\:tw-skew-x-1{
    --tw-skew-x: 1deg;
  }

  .xl\:tw-skew-x-2{
    --tw-skew-x: 2deg;
  }

  .xl\:tw-skew-x-3{
    --tw-skew-x: 3deg;
  }

  .xl\:tw-skew-x-6{
    --tw-skew-x: 6deg;
  }

  .xl\:tw-skew-x-12{
    --tw-skew-x: 12deg;
  }

  .xl\:tw--skew-x-12{
    --tw-skew-x: -12deg;
  }

  .xl\:tw--skew-x-6{
    --tw-skew-x: -6deg;
  }

  .xl\:tw--skew-x-3{
    --tw-skew-x: -3deg;
  }

  .xl\:tw--skew-x-2{
    --tw-skew-x: -2deg;
  }

  .xl\:tw--skew-x-1{
    --tw-skew-x: -1deg;
  }

  .xl\:tw-skew-y-0{
    --tw-skew-y: 0;
  }

  .xl\:tw-skew-y-1{
    --tw-skew-y: 1deg;
  }

  .xl\:tw-skew-y-2{
    --tw-skew-y: 2deg;
  }

  .xl\:tw-skew-y-3{
    --tw-skew-y: 3deg;
  }

  .xl\:tw-skew-y-6{
    --tw-skew-y: 6deg;
  }

  .xl\:tw-skew-y-12{
    --tw-skew-y: 12deg;
  }

  .xl\:tw--skew-y-12{
    --tw-skew-y: -12deg;
  }

  .xl\:tw--skew-y-6{
    --tw-skew-y: -6deg;
  }

  .xl\:tw--skew-y-3{
    --tw-skew-y: -3deg;
  }

  .xl\:tw--skew-y-2{
    --tw-skew-y: -2deg;
  }

  .xl\:tw--skew-y-1{
    --tw-skew-y: -1deg;
  }

  .xl\:hover\:tw-skew-x-0:hover{
    --tw-skew-x: 0;
  }

  .xl\:hover\:tw-skew-x-1:hover{
    --tw-skew-x: 1deg;
  }

  .xl\:hover\:tw-skew-x-2:hover{
    --tw-skew-x: 2deg;
  }

  .xl\:hover\:tw-skew-x-3:hover{
    --tw-skew-x: 3deg;
  }

  .xl\:hover\:tw-skew-x-6:hover{
    --tw-skew-x: 6deg;
  }

  .xl\:hover\:tw-skew-x-12:hover{
    --tw-skew-x: 12deg;
  }

  .xl\:hover\:tw--skew-x-12:hover{
    --tw-skew-x: -12deg;
  }

  .xl\:hover\:tw--skew-x-6:hover{
    --tw-skew-x: -6deg;
  }

  .xl\:hover\:tw--skew-x-3:hover{
    --tw-skew-x: -3deg;
  }

  .xl\:hover\:tw--skew-x-2:hover{
    --tw-skew-x: -2deg;
  }

  .xl\:hover\:tw--skew-x-1:hover{
    --tw-skew-x: -1deg;
  }

  .xl\:hover\:tw-skew-y-0:hover{
    --tw-skew-y: 0;
  }

  .xl\:hover\:tw-skew-y-1:hover{
    --tw-skew-y: 1deg;
  }

  .xl\:hover\:tw-skew-y-2:hover{
    --tw-skew-y: 2deg;
  }

  .xl\:hover\:tw-skew-y-3:hover{
    --tw-skew-y: 3deg;
  }

  .xl\:hover\:tw-skew-y-6:hover{
    --tw-skew-y: 6deg;
  }

  .xl\:hover\:tw-skew-y-12:hover{
    --tw-skew-y: 12deg;
  }

  .xl\:hover\:tw--skew-y-12:hover{
    --tw-skew-y: -12deg;
  }

  .xl\:hover\:tw--skew-y-6:hover{
    --tw-skew-y: -6deg;
  }

  .xl\:hover\:tw--skew-y-3:hover{
    --tw-skew-y: -3deg;
  }

  .xl\:hover\:tw--skew-y-2:hover{
    --tw-skew-y: -2deg;
  }

  .xl\:hover\:tw--skew-y-1:hover{
    --tw-skew-y: -1deg;
  }

  .xl\:focus\:tw-skew-x-0:focus{
    --tw-skew-x: 0;
  }

  .xl\:focus\:tw-skew-x-1:focus{
    --tw-skew-x: 1deg;
  }

  .xl\:focus\:tw-skew-x-2:focus{
    --tw-skew-x: 2deg;
  }

  .xl\:focus\:tw-skew-x-3:focus{
    --tw-skew-x: 3deg;
  }

  .xl\:focus\:tw-skew-x-6:focus{
    --tw-skew-x: 6deg;
  }

  .xl\:focus\:tw-skew-x-12:focus{
    --tw-skew-x: 12deg;
  }

  .xl\:focus\:tw--skew-x-12:focus{
    --tw-skew-x: -12deg;
  }

  .xl\:focus\:tw--skew-x-6:focus{
    --tw-skew-x: -6deg;
  }

  .xl\:focus\:tw--skew-x-3:focus{
    --tw-skew-x: -3deg;
  }

  .xl\:focus\:tw--skew-x-2:focus{
    --tw-skew-x: -2deg;
  }

  .xl\:focus\:tw--skew-x-1:focus{
    --tw-skew-x: -1deg;
  }

  .xl\:focus\:tw-skew-y-0:focus{
    --tw-skew-y: 0;
  }

  .xl\:focus\:tw-skew-y-1:focus{
    --tw-skew-y: 1deg;
  }

  .xl\:focus\:tw-skew-y-2:focus{
    --tw-skew-y: 2deg;
  }

  .xl\:focus\:tw-skew-y-3:focus{
    --tw-skew-y: 3deg;
  }

  .xl\:focus\:tw-skew-y-6:focus{
    --tw-skew-y: 6deg;
  }

  .xl\:focus\:tw-skew-y-12:focus{
    --tw-skew-y: 12deg;
  }

  .xl\:focus\:tw--skew-y-12:focus{
    --tw-skew-y: -12deg;
  }

  .xl\:focus\:tw--skew-y-6:focus{
    --tw-skew-y: -6deg;
  }

  .xl\:focus\:tw--skew-y-3:focus{
    --tw-skew-y: -3deg;
  }

  .xl\:focus\:tw--skew-y-2:focus{
    --tw-skew-y: -2deg;
  }

  .xl\:focus\:tw--skew-y-1:focus{
    --tw-skew-y: -1deg;
  }

  .xl\:tw-scale-0{
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .xl\:tw-scale-50{
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .xl\:tw-scale-75{
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .xl\:tw-scale-90{
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .xl\:tw-scale-95{
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .xl\:tw-scale-100{
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .xl\:tw-scale-105{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .xl\:tw-scale-110{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .xl\:tw-scale-125{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .xl\:tw-scale-150{
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .xl\:hover\:tw-scale-0:hover{
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .xl\:hover\:tw-scale-50:hover{
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .xl\:hover\:tw-scale-75:hover{
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .xl\:hover\:tw-scale-90:hover{
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .xl\:hover\:tw-scale-95:hover{
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .xl\:hover\:tw-scale-100:hover{
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .xl\:hover\:tw-scale-105:hover{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .xl\:hover\:tw-scale-110:hover{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .xl\:hover\:tw-scale-125:hover{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .xl\:hover\:tw-scale-150:hover{
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .xl\:focus\:tw-scale-0:focus{
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .xl\:focus\:tw-scale-50:focus{
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .xl\:focus\:tw-scale-75:focus{
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .xl\:focus\:tw-scale-90:focus{
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .xl\:focus\:tw-scale-95:focus{
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .xl\:focus\:tw-scale-100:focus{
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .xl\:focus\:tw-scale-105:focus{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .xl\:focus\:tw-scale-110:focus{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .xl\:focus\:tw-scale-125:focus{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .xl\:focus\:tw-scale-150:focus{
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .xl\:tw-scale-x-0{
    --tw-scale-x: 0;
  }

  .xl\:tw-scale-x-50{
    --tw-scale-x: .5;
  }

  .xl\:tw-scale-x-75{
    --tw-scale-x: .75;
  }

  .xl\:tw-scale-x-90{
    --tw-scale-x: .9;
  }

  .xl\:tw-scale-x-95{
    --tw-scale-x: .95;
  }

  .xl\:tw-scale-x-100{
    --tw-scale-x: 1;
  }

  .xl\:tw-scale-x-105{
    --tw-scale-x: 1.05;
  }

  .xl\:tw-scale-x-110{
    --tw-scale-x: 1.1;
  }

  .xl\:tw-scale-x-125{
    --tw-scale-x: 1.25;
  }

  .xl\:tw-scale-x-150{
    --tw-scale-x: 1.5;
  }

  .xl\:tw-scale-y-0{
    --tw-scale-y: 0;
  }

  .xl\:tw-scale-y-50{
    --tw-scale-y: .5;
  }

  .xl\:tw-scale-y-75{
    --tw-scale-y: .75;
  }

  .xl\:tw-scale-y-90{
    --tw-scale-y: .9;
  }

  .xl\:tw-scale-y-95{
    --tw-scale-y: .95;
  }

  .xl\:tw-scale-y-100{
    --tw-scale-y: 1;
  }

  .xl\:tw-scale-y-105{
    --tw-scale-y: 1.05;
  }

  .xl\:tw-scale-y-110{
    --tw-scale-y: 1.1;
  }

  .xl\:tw-scale-y-125{
    --tw-scale-y: 1.25;
  }

  .xl\:tw-scale-y-150{
    --tw-scale-y: 1.5;
  }

  .xl\:hover\:tw-scale-x-0:hover{
    --tw-scale-x: 0;
  }

  .xl\:hover\:tw-scale-x-50:hover{
    --tw-scale-x: .5;
  }

  .xl\:hover\:tw-scale-x-75:hover{
    --tw-scale-x: .75;
  }

  .xl\:hover\:tw-scale-x-90:hover{
    --tw-scale-x: .9;
  }

  .xl\:hover\:tw-scale-x-95:hover{
    --tw-scale-x: .95;
  }

  .xl\:hover\:tw-scale-x-100:hover{
    --tw-scale-x: 1;
  }

  .xl\:hover\:tw-scale-x-105:hover{
    --tw-scale-x: 1.05;
  }

  .xl\:hover\:tw-scale-x-110:hover{
    --tw-scale-x: 1.1;
  }

  .xl\:hover\:tw-scale-x-125:hover{
    --tw-scale-x: 1.25;
  }

  .xl\:hover\:tw-scale-x-150:hover{
    --tw-scale-x: 1.5;
  }

  .xl\:hover\:tw-scale-y-0:hover{
    --tw-scale-y: 0;
  }

  .xl\:hover\:tw-scale-y-50:hover{
    --tw-scale-y: .5;
  }

  .xl\:hover\:tw-scale-y-75:hover{
    --tw-scale-y: .75;
  }

  .xl\:hover\:tw-scale-y-90:hover{
    --tw-scale-y: .9;
  }

  .xl\:hover\:tw-scale-y-95:hover{
    --tw-scale-y: .95;
  }

  .xl\:hover\:tw-scale-y-100:hover{
    --tw-scale-y: 1;
  }

  .xl\:hover\:tw-scale-y-105:hover{
    --tw-scale-y: 1.05;
  }

  .xl\:hover\:tw-scale-y-110:hover{
    --tw-scale-y: 1.1;
  }

  .xl\:hover\:tw-scale-y-125:hover{
    --tw-scale-y: 1.25;
  }

  .xl\:hover\:tw-scale-y-150:hover{
    --tw-scale-y: 1.5;
  }

  .xl\:focus\:tw-scale-x-0:focus{
    --tw-scale-x: 0;
  }

  .xl\:focus\:tw-scale-x-50:focus{
    --tw-scale-x: .5;
  }

  .xl\:focus\:tw-scale-x-75:focus{
    --tw-scale-x: .75;
  }

  .xl\:focus\:tw-scale-x-90:focus{
    --tw-scale-x: .9;
  }

  .xl\:focus\:tw-scale-x-95:focus{
    --tw-scale-x: .95;
  }

  .xl\:focus\:tw-scale-x-100:focus{
    --tw-scale-x: 1;
  }

  .xl\:focus\:tw-scale-x-105:focus{
    --tw-scale-x: 1.05;
  }

  .xl\:focus\:tw-scale-x-110:focus{
    --tw-scale-x: 1.1;
  }

  .xl\:focus\:tw-scale-x-125:focus{
    --tw-scale-x: 1.25;
  }

  .xl\:focus\:tw-scale-x-150:focus{
    --tw-scale-x: 1.5;
  }

  .xl\:focus\:tw-scale-y-0:focus{
    --tw-scale-y: 0;
  }

  .xl\:focus\:tw-scale-y-50:focus{
    --tw-scale-y: .5;
  }

  .xl\:focus\:tw-scale-y-75:focus{
    --tw-scale-y: .75;
  }

  .xl\:focus\:tw-scale-y-90:focus{
    --tw-scale-y: .9;
  }

  .xl\:focus\:tw-scale-y-95:focus{
    --tw-scale-y: .95;
  }

  .xl\:focus\:tw-scale-y-100:focus{
    --tw-scale-y: 1;
  }

  .xl\:focus\:tw-scale-y-105:focus{
    --tw-scale-y: 1.05;
  }

  .xl\:focus\:tw-scale-y-110:focus{
    --tw-scale-y: 1.1;
  }

  .xl\:focus\:tw-scale-y-125:focus{
    --tw-scale-y: 1.25;
  }

  .xl\:focus\:tw-scale-y-150:focus{
    --tw-scale-y: 1.5;
  }

  .xl\:tw-animate-none{
    -webkit-animation: none;
    animation: none;
  }

  .xl\:tw-animate-spin{
    -webkit-animation: tw-spin 1s linear infinite;
    animation: tw-spin 1s linear infinite;
  }

  .xl\:tw-animate-ping{
    -webkit-animation: tw-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
    animation: tw-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .xl\:tw-animate-pulse{
    -webkit-animation: tw-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    animation: tw-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .xl\:tw-animate-bounce{
    -webkit-animation: tw-bounce 1s infinite;
    animation: tw-bounce 1s infinite;
  }

  .xl\:tw-cursor-auto{
    cursor: auto;
  }

  .xl\:tw-cursor-default{
    cursor: default;
  }

  .xl\:tw-cursor-pointer{
    cursor: pointer;
  }

  .xl\:tw-cursor-wait{
    cursor: wait;
  }

  .xl\:tw-cursor-text{
    cursor: text;
  }

  .xl\:tw-cursor-move{
    cursor: move;
  }

  .xl\:tw-cursor-not-allowed{
    cursor: not-allowed;
  }

  .xl\:tw-select-none{
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .xl\:tw-select-text{
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }

  .xl\:tw-select-all{
    -webkit-user-select: all;
    -ms-user-select: all;
    user-select: all;
  }

  .xl\:tw-select-auto{
    -webkit-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
  }

  .xl\:tw-resize-none{
    resize: none;
  }

  .xl\:tw-resize-y{
    resize: vertical;
  }

  .xl\:tw-resize-x{
    resize: horizontal;
  }

  .xl\:tw-resize{
    resize: both;
  }

  .xl\:tw-list-inside{
    list-style-position: inside;
  }

  .xl\:tw-list-outside{
    list-style-position: outside;
  }

  .xl\:tw-list-none{
    list-style-type: none;
  }

  .xl\:tw-list-disc{
    list-style-type: disc;
  }

  .xl\:tw-list-decimal{
    list-style-type: decimal;
  }

  .xl\:tw-appearance-none{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .xl\:tw-auto-cols-auto{
    grid-auto-columns: auto;
  }

  .xl\:tw-auto-cols-min{
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
  }

  .xl\:tw-auto-cols-max{
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
  }

  .xl\:tw-auto-cols-fr{
    grid-auto-columns: minmax(0, 1fr);
  }

  .xl\:tw-grid-flow-row{
    grid-auto-flow: row;
  }

  .xl\:tw-grid-flow-col{
    grid-auto-flow: column;
  }

  .xl\:tw-grid-flow-row-dense{
    grid-auto-flow: row dense;
  }

  .xl\:tw-grid-flow-col-dense{
    grid-auto-flow: column dense;
  }

  .xl\:tw-auto-rows-auto{
    grid-auto-rows: auto;
  }

  .xl\:tw-auto-rows-min{
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
  }

  .xl\:tw-auto-rows-max{
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
  }

  .xl\:tw-auto-rows-fr{
    grid-auto-rows: minmax(0, 1fr);
  }

  .xl\:tw-grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .xl\:tw-grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xl\:tw-grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:tw-grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:tw-grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .xl\:tw-grid-cols-6{
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .xl\:tw-grid-cols-7{
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .xl\:tw-grid-cols-8{
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .xl\:tw-grid-cols-9{
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .xl\:tw-grid-cols-10{
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .xl\:tw-grid-cols-11{
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .xl\:tw-grid-cols-12{
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .xl\:tw-grid-cols-none{
    grid-template-columns: none;
  }

  .xl\:tw-grid-rows-1{
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .xl\:tw-grid-rows-2{
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .xl\:tw-grid-rows-3{
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .xl\:tw-grid-rows-4{
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .xl\:tw-grid-rows-5{
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .xl\:tw-grid-rows-6{
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .xl\:tw-grid-rows-none{
    grid-template-rows: none;
  }

  .xl\:tw-flex-row{
    flex-direction: row;
  }

  .xl\:tw-flex-row-reverse{
    flex-direction: row-reverse;
  }

  .xl\:tw-flex-col{
    flex-direction: column;
  }

  .xl\:tw-flex-col-reverse{
    flex-direction: column-reverse;
  }

  .xl\:tw-flex-wrap{
    flex-wrap: wrap;
  }

  .xl\:tw-flex-wrap-reverse{
    flex-wrap: wrap-reverse;
  }

  .xl\:tw-flex-nowrap{
    flex-wrap: nowrap;
  }

  .xl\:tw-place-content-center{
    place-content: center;
  }

  .xl\:tw-place-content-start{
    place-content: start;
  }

  .xl\:tw-place-content-end{
    place-content: end;
  }

  .xl\:tw-place-content-between{
    place-content: space-between;
  }

  .xl\:tw-place-content-around{
    place-content: space-around;
  }

  .xl\:tw-place-content-evenly{
    place-content: space-evenly;
  }

  .xl\:tw-place-content-stretch{
    place-content: stretch;
  }

  .xl\:tw-place-items-start{
    place-items: start;
  }

  .xl\:tw-place-items-end{
    place-items: end;
  }

  .xl\:tw-place-items-center{
    place-items: center;
  }

  .xl\:tw-place-items-stretch{
    place-items: stretch;
  }

  .xl\:tw-content-center{
    align-content: center;
  }

  .xl\:tw-content-start{
    align-content: flex-start;
  }

  .xl\:tw-content-end{
    align-content: flex-end;
  }

  .xl\:tw-content-between{
    align-content: space-between;
  }

  .xl\:tw-content-around{
    align-content: space-around;
  }

  .xl\:tw-content-evenly{
    align-content: space-evenly;
  }

  .xl\:tw-items-start{
    align-items: flex-start;
  }

  .xl\:tw-items-end{
    align-items: flex-end;
  }

  .xl\:tw-items-center{
    align-items: center;
  }

  .xl\:tw-items-baseline{
    align-items: baseline;
  }

  .xl\:tw-items-stretch{
    align-items: stretch;
  }

  .xl\:tw-justify-start{
    justify-content: flex-start;
  }

  .xl\:tw-justify-end{
    justify-content: flex-end;
  }

  .xl\:tw-justify-center{
    justify-content: center;
  }

  .xl\:tw-justify-between{
    justify-content: space-between;
  }

  .xl\:tw-justify-around{
    justify-content: space-around;
  }

  .xl\:tw-justify-evenly{
    justify-content: space-evenly;
  }

  .xl\:tw-justify-items-start{
    justify-items: start;
  }

  .xl\:tw-justify-items-end{
    justify-items: end;
  }

  .xl\:tw-justify-items-center{
    justify-items: center;
  }

  .xl\:tw-justify-items-stretch{
    justify-items: stretch;
  }

  .xl\:tw-gap-0{
    gap: 0;
  }

  .xl\:tw-gap-1{
    gap: 0.25rem;
  }

  .xl\:tw-gap-2{
    gap: 0.5rem;
  }

  .xl\:tw-gap-3{
    gap: 0.75rem;
  }

  .xl\:tw-gap-4{
    gap: 1rem;
  }

  .xl\:tw-gap-5{
    gap: 1.25rem;
  }

  .xl\:tw-gap-6{
    gap: 1.5rem;
  }

  .xl\:tw-gap-8{
    gap: 2rem;
  }

  .xl\:tw-gap-10{
    gap: 2.5rem;
  }

  .xl\:tw-gap-12{
    gap: 3rem;
  }

  .xl\:tw-gap-16{
    gap: 4rem;
  }

  .xl\:tw-gap-20{
    gap: 5rem;
  }

  .xl\:tw-gap-24{
    gap: 6rem;
  }

  .xl\:tw-gap-32{
    gap: 8rem;
  }

  .xl\:tw-gap-40{
    gap: 10rem;
  }

  .xl\:tw-gap-48{
    gap: 12rem;
  }

  .xl\:tw-gap-56{
    gap: 14rem;
  }

  .xl\:tw-gap-64{
    gap: 16rem;
  }

  .xl\:tw-gap-px{
    gap: 1px;
  }

  .xl\:tw-gap-x-0{
    -webkit-column-gap: 0;
    column-gap: 0;
  }

  .xl\:tw-gap-x-1{
    -webkit-column-gap: 0.25rem;
    column-gap: 0.25rem;
  }

  .xl\:tw-gap-x-2{
    -webkit-column-gap: 0.5rem;
    column-gap: 0.5rem;
  }

  .xl\:tw-gap-x-3{
    -webkit-column-gap: 0.75rem;
    column-gap: 0.75rem;
  }

  .xl\:tw-gap-x-4{
    -webkit-column-gap: 1rem;
    column-gap: 1rem;
  }

  .xl\:tw-gap-x-5{
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }

  .xl\:tw-gap-x-6{
    -webkit-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }

  .xl\:tw-gap-x-8{
    -webkit-column-gap: 2rem;
    column-gap: 2rem;
  }

  .xl\:tw-gap-x-10{
    -webkit-column-gap: 2.5rem;
    column-gap: 2.5rem;
  }

  .xl\:tw-gap-x-12{
    -webkit-column-gap: 3rem;
    column-gap: 3rem;
  }

  .xl\:tw-gap-x-16{
    -webkit-column-gap: 4rem;
    column-gap: 4rem;
  }

  .xl\:tw-gap-x-20{
    -webkit-column-gap: 5rem;
    column-gap: 5rem;
  }

  .xl\:tw-gap-x-24{
    -webkit-column-gap: 6rem;
    column-gap: 6rem;
  }

  .xl\:tw-gap-x-32{
    -webkit-column-gap: 8rem;
    column-gap: 8rem;
  }

  .xl\:tw-gap-x-40{
    -webkit-column-gap: 10rem;
    column-gap: 10rem;
  }

  .xl\:tw-gap-x-48{
    -webkit-column-gap: 12rem;
    column-gap: 12rem;
  }

  .xl\:tw-gap-x-56{
    -webkit-column-gap: 14rem;
    column-gap: 14rem;
  }

  .xl\:tw-gap-x-64{
    -webkit-column-gap: 16rem;
    column-gap: 16rem;
  }

  .xl\:tw-gap-x-px{
    -webkit-column-gap: 1px;
    column-gap: 1px;
  }

  .xl\:tw-gap-y-0{
    row-gap: 0;
  }

  .xl\:tw-gap-y-1{
    row-gap: 0.25rem;
  }

  .xl\:tw-gap-y-2{
    row-gap: 0.5rem;
  }

  .xl\:tw-gap-y-3{
    row-gap: 0.75rem;
  }

  .xl\:tw-gap-y-4{
    row-gap: 1rem;
  }

  .xl\:tw-gap-y-5{
    row-gap: 1.25rem;
  }

  .xl\:tw-gap-y-6{
    row-gap: 1.5rem;
  }

  .xl\:tw-gap-y-8{
    row-gap: 2rem;
  }

  .xl\:tw-gap-y-10{
    row-gap: 2.5rem;
  }

  .xl\:tw-gap-y-12{
    row-gap: 3rem;
  }

  .xl\:tw-gap-y-16{
    row-gap: 4rem;
  }

  .xl\:tw-gap-y-20{
    row-gap: 5rem;
  }

  .xl\:tw-gap-y-24{
    row-gap: 6rem;
  }

  .xl\:tw-gap-y-32{
    row-gap: 8rem;
  }

  .xl\:tw-gap-y-40{
    row-gap: 10rem;
  }

  .xl\:tw-gap-y-48{
    row-gap: 12rem;
  }

  .xl\:tw-gap-y-56{
    row-gap: 14rem;
  }

  .xl\:tw-gap-y-64{
    row-gap: 16rem;
  }

  .xl\:tw-gap-y-px{
    row-gap: 1px;
  }

  .xl\:tw-space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw-space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw-space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw-space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw-space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw-space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw-space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw-space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw-space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw-space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw-space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw-space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw-space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw-space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw-space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw-space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw-space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw-space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw-space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw--space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw--space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw--space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw--space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw--space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw--space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw--space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw--space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw--space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw--space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw--space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw--space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw--space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw--space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw--space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw--space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw--space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw--space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:tw-space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .xl\:tw-space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }

  .xl\:tw-space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }

  .xl\:tw-space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }

  .xl\:tw-space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .xl\:tw-space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .xl\:tw-space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .xl\:tw-space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .xl\:tw-space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }

  .xl\:tw-space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }

  .xl\:tw-space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .xl\:tw-space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .xl\:tw-space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }

  .xl\:tw-space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }

  .xl\:tw-space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }

  .xl\:tw-space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }

  .xl\:tw-space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }

  .xl\:tw-space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }

  .xl\:tw-space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }

  .xl\:tw--space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
  }

  .xl\:tw--space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
  }

  .xl\:tw--space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
  }

  .xl\:tw--space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }

  .xl\:tw--space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }

  .xl\:tw--space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }

  .xl\:tw--space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }

  .xl\:tw--space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }

  .xl\:tw--space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }

  .xl\:tw--space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }

  .xl\:tw--space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }

  .xl\:tw--space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }

  .xl\:tw--space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }

  .xl\:tw--space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }

  .xl\:tw--space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }

  .xl\:tw--space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }

  .xl\:tw--space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }

  .xl\:tw--space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }

  .xl\:tw-space-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 1;
  }

  .xl\:tw-space-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 1;
  }

  .xl\:tw-divide-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xl\:tw-divide-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xl\:tw-divide-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xl\:tw-divide-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xl\:tw-divide-x-default > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xl\:tw-divide-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .xl\:tw-divide-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }

  .xl\:tw-divide-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }

  .xl\:tw-divide-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }

  .xl\:tw-divide-y-default > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .xl\:tw-divide-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 1;
  }

  .xl\:tw-divide-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 1;
  }

  .xl\:tw-divide-solid > :not([hidden]) ~ :not([hidden]){
    border-style: solid;
  }

  .xl\:tw-divide-dashed > :not([hidden]) ~ :not([hidden]){
    border-style: dashed;
  }

  .xl\:tw-divide-dotted > :not([hidden]) ~ :not([hidden]){
    border-style: dotted;
  }

  .xl\:tw-divide-double > :not([hidden]) ~ :not([hidden]){
    border-style: double;
  }

  .xl\:tw-divide-none > :not([hidden]) ~ :not([hidden]){
    border-style: none;
  }

  .xl\:tw-divide-transparent > :not([hidden]) ~ :not([hidden]){
    border-color: transparent;
  }

  .xl\:tw-divide-current > :not([hidden]) ~ :not([hidden]){
    border-color: currentColor;
  }

  .xl\:tw-divide-black > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(44, 44, 44, var(--tw-divide-opacity));
  }

  .xl\:tw-divide-white > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }

  .xl\:tw-divide-gray-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(247, 250, 252, var(--tw-divide-opacity));
  }

  .xl\:tw-divide-gray-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(237, 242, 247, var(--tw-divide-opacity));
  }

  .xl\:tw-divide-gray-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-divide-opacity));
  }

  .xl\:tw-divide-gray-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(203, 213, 224, var(--tw-divide-opacity));
  }

  .xl\:tw-divide-gray-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(160, 174, 192, var(--tw-divide-opacity));
  }

  .xl\:tw-divide-gray-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(113, 128, 150, var(--tw-divide-opacity));
  }

  .xl\:tw-divide-gray-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(74, 85, 104, var(--tw-divide-opacity));
  }

  .xl\:tw-divide-gray-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(45, 55, 72, var(--tw-divide-opacity));
  }

  .xl\:tw-divide-gray-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(26, 32, 44, var(--tw-divide-opacity));
  }

  .xl\:tw-divide-blue-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(235, 248, 255, var(--tw-divide-opacity));
  }

  .xl\:tw-divide-blue-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(190, 227, 248, var(--tw-divide-opacity));
  }

  .xl\:tw-divide-blue-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(144, 205, 244, var(--tw-divide-opacity));
  }

  .xl\:tw-divide-blue-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(99, 179, 237, var(--tw-divide-opacity));
  }

  .xl\:tw-divide-blue-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(66, 153, 225, var(--tw-divide-opacity));
  }

  .xl\:tw-divide-blue-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(49, 130, 206, var(--tw-divide-opacity));
  }

  .xl\:tw-divide-blue-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(43, 108, 176, var(--tw-divide-opacity));
  }

  .xl\:tw-divide-blue-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(44, 82, 130, var(--tw-divide-opacity));
  }

  .xl\:tw-divide-blue-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(42, 67, 101, var(--tw-divide-opacity));
  }

  .xl\:tw-divide-opacity-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0;
  }

  .xl\:tw-divide-opacity-25 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.25;
  }

  .xl\:tw-divide-opacity-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.5;
  }

  .xl\:tw-divide-opacity-75 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.75;
  }

  .xl\:tw-divide-opacity-90 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.9;
  }

  .xl\:tw-divide-opacity-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
  }

  .xl\:tw-place-self-auto{
    place-self: auto;
  }

  .xl\:tw-place-self-start{
    place-self: start;
  }

  .xl\:tw-place-self-end{
    place-self: end;
  }

  .xl\:tw-place-self-center{
    place-self: center;
  }

  .xl\:tw-place-self-stretch{
    place-self: stretch;
  }

  .xl\:tw-self-auto{
    align-self: auto;
  }

  .xl\:tw-self-start{
    align-self: flex-start;
  }

  .xl\:tw-self-end{
    align-self: flex-end;
  }

  .xl\:tw-self-center{
    align-self: center;
  }

  .xl\:tw-self-stretch{
    align-self: stretch;
  }

  .xl\:tw-self-baseline{
    align-self: baseline;
  }

  .xl\:tw-justify-self-auto{
    justify-self: auto;
  }

  .xl\:tw-justify-self-start{
    justify-self: start;
  }

  .xl\:tw-justify-self-end{
    justify-self: end;
  }

  .xl\:tw-justify-self-center{
    justify-self: center;
  }

  .xl\:tw-justify-self-stretch{
    justify-self: stretch;
  }

  .xl\:tw-overflow-auto{
    overflow: auto;
  }

  .xl\:tw-overflow-hidden{
    overflow: hidden;
  }

  .xl\:tw-overflow-visible{
    overflow: visible;
  }

  .xl\:tw-overflow-scroll{
    overflow: scroll;
  }

  .xl\:tw-overflow-x-auto{
    overflow-x: auto;
  }

  .xl\:tw-overflow-y-auto{
    overflow-y: auto;
  }

  .xl\:tw-overflow-x-hidden{
    overflow-x: hidden;
  }

  .xl\:tw-overflow-y-hidden{
    overflow-y: hidden;
  }

  .xl\:tw-overflow-x-visible{
    overflow-x: visible;
  }

  .xl\:tw-overflow-y-visible{
    overflow-y: visible;
  }

  .xl\:tw-overflow-x-scroll{
    overflow-x: scroll;
  }

  .xl\:tw-overflow-y-scroll{
    overflow-y: scroll;
  }

  .xl\:tw-overscroll-auto{
    overscroll-behavior: auto;
  }

  .xl\:tw-overscroll-contain{
    overscroll-behavior: contain;
  }

  .xl\:tw-overscroll-none{
    overscroll-behavior: none;
  }

  .xl\:tw-overscroll-y-auto{
    overscroll-behavior-y: auto;
  }

  .xl\:tw-overscroll-y-contain{
    overscroll-behavior-y: contain;
  }

  .xl\:tw-overscroll-y-none{
    overscroll-behavior-y: none;
  }

  .xl\:tw-overscroll-x-auto{
    overscroll-behavior-x: auto;
  }

  .xl\:tw-overscroll-x-contain{
    overscroll-behavior-x: contain;
  }

  .xl\:tw-overscroll-x-none{
    overscroll-behavior-x: none;
  }

  .xl\:tw-truncate{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .xl\:tw-overflow-ellipsis{
    text-overflow: ellipsis;
  }

  .xl\:tw-overflow-clip{
    text-overflow: clip;
  }

  .xl\:tw-whitespace-normal{
    white-space: normal;
  }

  .xl\:tw-whitespace-nowrap{
    white-space: nowrap;
  }

  .xl\:tw-whitespace-pre{
    white-space: pre;
  }

  .xl\:tw-whitespace-pre-line{
    white-space: pre-line;
  }

  .xl\:tw-whitespace-pre-wrap{
    white-space: pre-wrap;
  }

  .xl\:tw-break-normal{
    overflow-wrap: normal;
    word-break: normal;
  }

  .xl\:tw-break-words{
    overflow-wrap: break-word;
  }

  .xl\:tw-break-all{
    word-break: break-all;
  }

  .xl\:tw-rounded-none{
    border-radius: 0;
  }

  .xl\:tw-rounded-sm{
    border-radius: 0.125rem;
  }

  .xl\:tw-rounded-default{
    border-radius: 0.25rem;
  }

  .xl\:tw-rounded-md{
    border-radius: 0.375rem;
  }

  .xl\:tw-rounded-lg{
    border-radius: 0.5rem;
  }

  .xl\:tw-rounded-xl{
    border-radius: 0.75rem;
  }

  .xl\:tw-rounded-2xl{
    border-radius: 1rem;
  }

  .xl\:tw-rounded-3xl{
    border-radius: 1.5rem;
  }

  .xl\:tw-rounded-full{
    border-radius: 9999px;
  }

  .xl\:tw-rounded-t-none{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .xl\:tw-rounded-t-sm{
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }

  .xl\:tw-rounded-t-default{
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .xl\:tw-rounded-t-md{
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  .xl\:tw-rounded-t-lg{
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .xl\:tw-rounded-t-xl{
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }

  .xl\:tw-rounded-t-2xl{
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .xl\:tw-rounded-t-3xl{
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .xl\:tw-rounded-t-full{
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .xl\:tw-rounded-r-none{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .xl\:tw-rounded-r-sm{
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }

  .xl\:tw-rounded-r-default{
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .xl\:tw-rounded-r-md{
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .xl\:tw-rounded-r-lg{
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .xl\:tw-rounded-r-xl{
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  .xl\:tw-rounded-r-2xl{
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .xl\:tw-rounded-r-3xl{
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .xl\:tw-rounded-r-full{
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .xl\:tw-rounded-b-none{
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .xl\:tw-rounded-b-sm{
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .xl\:tw-rounded-b-default{
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .xl\:tw-rounded-b-md{
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .xl\:tw-rounded-b-lg{
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .xl\:tw-rounded-b-xl{
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .xl\:tw-rounded-b-2xl{
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .xl\:tw-rounded-b-3xl{
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .xl\:tw-rounded-b-full{
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .xl\:tw-rounded-l-none{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .xl\:tw-rounded-l-sm{
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .xl\:tw-rounded-l-default{
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .xl\:tw-rounded-l-md{
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .xl\:tw-rounded-l-lg{
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .xl\:tw-rounded-l-xl{
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .xl\:tw-rounded-l-2xl{
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .xl\:tw-rounded-l-3xl{
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .xl\:tw-rounded-l-full{
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .xl\:tw-rounded-tl-none{
    border-top-left-radius: 0;
  }

  .xl\:tw-rounded-tl-sm{
    border-top-left-radius: 0.125rem;
  }

  .xl\:tw-rounded-tl-default{
    border-top-left-radius: 0.25rem;
  }

  .xl\:tw-rounded-tl-md{
    border-top-left-radius: 0.375rem;
  }

  .xl\:tw-rounded-tl-lg{
    border-top-left-radius: 0.5rem;
  }

  .xl\:tw-rounded-tl-xl{
    border-top-left-radius: 0.75rem;
  }

  .xl\:tw-rounded-tl-2xl{
    border-top-left-radius: 1rem;
  }

  .xl\:tw-rounded-tl-3xl{
    border-top-left-radius: 1.5rem;
  }

  .xl\:tw-rounded-tl-full{
    border-top-left-radius: 9999px;
  }

  .xl\:tw-rounded-tr-none{
    border-top-right-radius: 0;
  }

  .xl\:tw-rounded-tr-sm{
    border-top-right-radius: 0.125rem;
  }

  .xl\:tw-rounded-tr-default{
    border-top-right-radius: 0.25rem;
  }

  .xl\:tw-rounded-tr-md{
    border-top-right-radius: 0.375rem;
  }

  .xl\:tw-rounded-tr-lg{
    border-top-right-radius: 0.5rem;
  }

  .xl\:tw-rounded-tr-xl{
    border-top-right-radius: 0.75rem;
  }

  .xl\:tw-rounded-tr-2xl{
    border-top-right-radius: 1rem;
  }

  .xl\:tw-rounded-tr-3xl{
    border-top-right-radius: 1.5rem;
  }

  .xl\:tw-rounded-tr-full{
    border-top-right-radius: 9999px;
  }

  .xl\:tw-rounded-br-none{
    border-bottom-right-radius: 0;
  }

  .xl\:tw-rounded-br-sm{
    border-bottom-right-radius: 0.125rem;
  }

  .xl\:tw-rounded-br-default{
    border-bottom-right-radius: 0.25rem;
  }

  .xl\:tw-rounded-br-md{
    border-bottom-right-radius: 0.375rem;
  }

  .xl\:tw-rounded-br-lg{
    border-bottom-right-radius: 0.5rem;
  }

  .xl\:tw-rounded-br-xl{
    border-bottom-right-radius: 0.75rem;
  }

  .xl\:tw-rounded-br-2xl{
    border-bottom-right-radius: 1rem;
  }

  .xl\:tw-rounded-br-3xl{
    border-bottom-right-radius: 1.5rem;
  }

  .xl\:tw-rounded-br-full{
    border-bottom-right-radius: 9999px;
  }

  .xl\:tw-rounded-bl-none{
    border-bottom-left-radius: 0;
  }

  .xl\:tw-rounded-bl-sm{
    border-bottom-left-radius: 0.125rem;
  }

  .xl\:tw-rounded-bl-default{
    border-bottom-left-radius: 0.25rem;
  }

  .xl\:tw-rounded-bl-md{
    border-bottom-left-radius: 0.375rem;
  }

  .xl\:tw-rounded-bl-lg{
    border-bottom-left-radius: 0.5rem;
  }

  .xl\:tw-rounded-bl-xl{
    border-bottom-left-radius: 0.75rem;
  }

  .xl\:tw-rounded-bl-2xl{
    border-bottom-left-radius: 1rem;
  }

  .xl\:tw-rounded-bl-3xl{
    border-bottom-left-radius: 1.5rem;
  }

  .xl\:tw-rounded-bl-full{
    border-bottom-left-radius: 9999px;
  }

  .xl\:tw-border-0{
    border-width: 0;
  }

  .xl\:tw-border-2{
    border-width: 2px;
  }

  .xl\:tw-border-4{
    border-width: 4px;
  }

  .xl\:tw-border-8{
    border-width: 8px;
  }

  .xl\:tw-border-default{
    border-width: 1px;
  }

  .xl\:tw-border-t-0{
    border-top-width: 0;
  }

  .xl\:tw-border-t-2{
    border-top-width: 2px;
  }

  .xl\:tw-border-t-4{
    border-top-width: 4px;
  }

  .xl\:tw-border-t-8{
    border-top-width: 8px;
  }

  .xl\:tw-border-t-default{
    border-top-width: 1px;
  }

  .xl\:tw-border-r-0{
    border-right-width: 0;
  }

  .xl\:tw-border-r-2{
    border-right-width: 2px;
  }

  .xl\:tw-border-r-4{
    border-right-width: 4px;
  }

  .xl\:tw-border-r-8{
    border-right-width: 8px;
  }

  .xl\:tw-border-r-default{
    border-right-width: 1px;
  }

  .xl\:tw-border-b-0{
    border-bottom-width: 0;
  }

  .xl\:tw-border-b-2{
    border-bottom-width: 2px;
  }

  .xl\:tw-border-b-4{
    border-bottom-width: 4px;
  }

  .xl\:tw-border-b-8{
    border-bottom-width: 8px;
  }

  .xl\:tw-border-b-default{
    border-bottom-width: 1px;
  }

  .xl\:tw-border-l-0{
    border-left-width: 0;
  }

  .xl\:tw-border-l-2{
    border-left-width: 2px;
  }

  .xl\:tw-border-l-4{
    border-left-width: 4px;
  }

  .xl\:tw-border-l-8{
    border-left-width: 8px;
  }

  .xl\:tw-border-l-default{
    border-left-width: 1px;
  }

  .xl\:tw-border-solid{
    border-style: solid;
  }

  .xl\:tw-border-dashed{
    border-style: dashed;
  }

  .xl\:tw-border-dotted{
    border-style: dotted;
  }

  .xl\:tw-border-double{
    border-style: double;
  }

  .xl\:tw-border-none{
    border-style: none;
  }

  .xl\:tw-border-transparent{
    border-color: transparent;
  }

  .xl\:tw-border-current{
    border-color: currentColor;
  }

  .xl\:tw-border-black{
    --tw-border-opacity: 1;
    border-color: rgba(44, 44, 44, var(--tw-border-opacity));
  }

  .xl\:tw-border-white{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .xl\:tw-border-gray-100{
    --tw-border-opacity: 1;
    border-color: rgba(247, 250, 252, var(--tw-border-opacity));
  }

  .xl\:tw-border-gray-200{
    --tw-border-opacity: 1;
    border-color: rgba(237, 242, 247, var(--tw-border-opacity));
  }

  .xl\:tw-border-gray-300{
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
  }

  .xl\:tw-border-gray-400{
    --tw-border-opacity: 1;
    border-color: rgba(203, 213, 224, var(--tw-border-opacity));
  }

  .xl\:tw-border-gray-500{
    --tw-border-opacity: 1;
    border-color: rgba(160, 174, 192, var(--tw-border-opacity));
  }

  .xl\:tw-border-gray-600{
    --tw-border-opacity: 1;
    border-color: rgba(113, 128, 150, var(--tw-border-opacity));
  }

  .xl\:tw-border-gray-700{
    --tw-border-opacity: 1;
    border-color: rgba(74, 85, 104, var(--tw-border-opacity));
  }

  .xl\:tw-border-gray-800{
    --tw-border-opacity: 1;
    border-color: rgba(45, 55, 72, var(--tw-border-opacity));
  }

  .xl\:tw-border-gray-900{
    --tw-border-opacity: 1;
    border-color: rgba(26, 32, 44, var(--tw-border-opacity));
  }

  .xl\:tw-border-blue-100{
    --tw-border-opacity: 1;
    border-color: rgba(235, 248, 255, var(--tw-border-opacity));
  }

  .xl\:tw-border-blue-200{
    --tw-border-opacity: 1;
    border-color: rgba(190, 227, 248, var(--tw-border-opacity));
  }

  .xl\:tw-border-blue-300{
    --tw-border-opacity: 1;
    border-color: rgba(144, 205, 244, var(--tw-border-opacity));
  }

  .xl\:tw-border-blue-400{
    --tw-border-opacity: 1;
    border-color: rgba(99, 179, 237, var(--tw-border-opacity));
  }

  .xl\:tw-border-blue-500{
    --tw-border-opacity: 1;
    border-color: rgba(66, 153, 225, var(--tw-border-opacity));
  }

  .xl\:tw-border-blue-600{
    --tw-border-opacity: 1;
    border-color: rgba(49, 130, 206, var(--tw-border-opacity));
  }

  .xl\:tw-border-blue-700{
    --tw-border-opacity: 1;
    border-color: rgba(43, 108, 176, var(--tw-border-opacity));
  }

  .xl\:tw-border-blue-800{
    --tw-border-opacity: 1;
    border-color: rgba(44, 82, 130, var(--tw-border-opacity));
  }

  .xl\:tw-border-blue-900{
    --tw-border-opacity: 1;
    border-color: rgba(42, 67, 101, var(--tw-border-opacity));
  }

  .xl\:hover\:tw-border-transparent:hover{
    border-color: transparent;
  }

  .xl\:hover\:tw-border-current:hover{
    border-color: currentColor;
  }

  .xl\:hover\:tw-border-black:hover{
    --tw-border-opacity: 1;
    border-color: rgba(44, 44, 44, var(--tw-border-opacity));
  }

  .xl\:hover\:tw-border-white:hover{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .xl\:hover\:tw-border-gray-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(247, 250, 252, var(--tw-border-opacity));
  }

  .xl\:hover\:tw-border-gray-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(237, 242, 247, var(--tw-border-opacity));
  }

  .xl\:hover\:tw-border-gray-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
  }

  .xl\:hover\:tw-border-gray-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(203, 213, 224, var(--tw-border-opacity));
  }

  .xl\:hover\:tw-border-gray-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(160, 174, 192, var(--tw-border-opacity));
  }

  .xl\:hover\:tw-border-gray-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(113, 128, 150, var(--tw-border-opacity));
  }

  .xl\:hover\:tw-border-gray-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(74, 85, 104, var(--tw-border-opacity));
  }

  .xl\:hover\:tw-border-gray-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(45, 55, 72, var(--tw-border-opacity));
  }

  .xl\:hover\:tw-border-gray-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(26, 32, 44, var(--tw-border-opacity));
  }

  .xl\:hover\:tw-border-blue-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(235, 248, 255, var(--tw-border-opacity));
  }

  .xl\:hover\:tw-border-blue-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(190, 227, 248, var(--tw-border-opacity));
  }

  .xl\:hover\:tw-border-blue-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(144, 205, 244, var(--tw-border-opacity));
  }

  .xl\:hover\:tw-border-blue-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(99, 179, 237, var(--tw-border-opacity));
  }

  .xl\:hover\:tw-border-blue-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(66, 153, 225, var(--tw-border-opacity));
  }

  .xl\:hover\:tw-border-blue-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(49, 130, 206, var(--tw-border-opacity));
  }

  .xl\:hover\:tw-border-blue-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(43, 108, 176, var(--tw-border-opacity));
  }

  .xl\:hover\:tw-border-blue-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(44, 82, 130, var(--tw-border-opacity));
  }

  .xl\:hover\:tw-border-blue-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(42, 67, 101, var(--tw-border-opacity));
  }

  .xl\:focus\:tw-border-transparent:focus{
    border-color: transparent;
  }

  .xl\:focus\:tw-border-current:focus{
    border-color: currentColor;
  }

  .xl\:focus\:tw-border-black:focus{
    --tw-border-opacity: 1;
    border-color: rgba(44, 44, 44, var(--tw-border-opacity));
  }

  .xl\:focus\:tw-border-white:focus{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .xl\:focus\:tw-border-gray-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(247, 250, 252, var(--tw-border-opacity));
  }

  .xl\:focus\:tw-border-gray-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(237, 242, 247, var(--tw-border-opacity));
  }

  .xl\:focus\:tw-border-gray-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
  }

  .xl\:focus\:tw-border-gray-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(203, 213, 224, var(--tw-border-opacity));
  }

  .xl\:focus\:tw-border-gray-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(160, 174, 192, var(--tw-border-opacity));
  }

  .xl\:focus\:tw-border-gray-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(113, 128, 150, var(--tw-border-opacity));
  }

  .xl\:focus\:tw-border-gray-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(74, 85, 104, var(--tw-border-opacity));
  }

  .xl\:focus\:tw-border-gray-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(45, 55, 72, var(--tw-border-opacity));
  }

  .xl\:focus\:tw-border-gray-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(26, 32, 44, var(--tw-border-opacity));
  }

  .xl\:focus\:tw-border-blue-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(235, 248, 255, var(--tw-border-opacity));
  }

  .xl\:focus\:tw-border-blue-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(190, 227, 248, var(--tw-border-opacity));
  }

  .xl\:focus\:tw-border-blue-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(144, 205, 244, var(--tw-border-opacity));
  }

  .xl\:focus\:tw-border-blue-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(99, 179, 237, var(--tw-border-opacity));
  }

  .xl\:focus\:tw-border-blue-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(66, 153, 225, var(--tw-border-opacity));
  }

  .xl\:focus\:tw-border-blue-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(49, 130, 206, var(--tw-border-opacity));
  }

  .xl\:focus\:tw-border-blue-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(43, 108, 176, var(--tw-border-opacity));
  }

  .xl\:focus\:tw-border-blue-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(44, 82, 130, var(--tw-border-opacity));
  }

  .xl\:focus\:tw-border-blue-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(42, 67, 101, var(--tw-border-opacity));
  }

  .xl\:tw-border-opacity-0{
    --tw-border-opacity: 0;
  }

  .xl\:tw-border-opacity-25{
    --tw-border-opacity: 0.25;
  }

  .xl\:tw-border-opacity-50{
    --tw-border-opacity: 0.5;
  }

  .xl\:tw-border-opacity-75{
    --tw-border-opacity: 0.75;
  }

  .xl\:tw-border-opacity-90{
    --tw-border-opacity: 0.9;
  }

  .xl\:tw-border-opacity-100{
    --tw-border-opacity: 1;
  }

  .xl\:hover\:tw-border-opacity-0:hover{
    --tw-border-opacity: 0;
  }

  .xl\:hover\:tw-border-opacity-25:hover{
    --tw-border-opacity: 0.25;
  }

  .xl\:hover\:tw-border-opacity-50:hover{
    --tw-border-opacity: 0.5;
  }

  .xl\:hover\:tw-border-opacity-75:hover{
    --tw-border-opacity: 0.75;
  }

  .xl\:hover\:tw-border-opacity-90:hover{
    --tw-border-opacity: 0.9;
  }

  .xl\:hover\:tw-border-opacity-100:hover{
    --tw-border-opacity: 1;
  }

  .xl\:focus\:tw-border-opacity-0:focus{
    --tw-border-opacity: 0;
  }

  .xl\:focus\:tw-border-opacity-25:focus{
    --tw-border-opacity: 0.25;
  }

  .xl\:focus\:tw-border-opacity-50:focus{
    --tw-border-opacity: 0.5;
  }

  .xl\:focus\:tw-border-opacity-75:focus{
    --tw-border-opacity: 0.75;
  }

  .xl\:focus\:tw-border-opacity-90:focus{
    --tw-border-opacity: 0.9;
  }

  .xl\:focus\:tw-border-opacity-100:focus{
    --tw-border-opacity: 1;
  }

  .xl\:tw-bg-transparent{
    background-color: transparent;
  }

  .xl\:tw-bg-current{
    background-color: currentColor;
  }

  .xl\:tw-bg-black{
    --tw-bg-opacity: 1;
    background-color: rgba(44, 44, 44, var(--tw-bg-opacity));
  }

  .xl\:tw-bg-white{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .xl\:tw-bg-gray-100{
    --tw-bg-opacity: 1;
    background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
  }

  .xl\:tw-bg-gray-200{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 242, 247, var(--tw-bg-opacity));
  }

  .xl\:tw-bg-gray-300{
    --tw-bg-opacity: 1;
    background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
  }

  .xl\:tw-bg-gray-400{
    --tw-bg-opacity: 1;
    background-color: rgba(203, 213, 224, var(--tw-bg-opacity));
  }

  .xl\:tw-bg-gray-500{
    --tw-bg-opacity: 1;
    background-color: rgba(160, 174, 192, var(--tw-bg-opacity));
  }

  .xl\:tw-bg-gray-600{
    --tw-bg-opacity: 1;
    background-color: rgba(113, 128, 150, var(--tw-bg-opacity));
  }

  .xl\:tw-bg-gray-700{
    --tw-bg-opacity: 1;
    background-color: rgba(74, 85, 104, var(--tw-bg-opacity));
  }

  .xl\:tw-bg-gray-800{
    --tw-bg-opacity: 1;
    background-color: rgba(45, 55, 72, var(--tw-bg-opacity));
  }

  .xl\:tw-bg-gray-900{
    --tw-bg-opacity: 1;
    background-color: rgba(26, 32, 44, var(--tw-bg-opacity));
  }

  .xl\:tw-bg-blue-100{
    --tw-bg-opacity: 1;
    background-color: rgba(235, 248, 255, var(--tw-bg-opacity));
  }

  .xl\:tw-bg-blue-200{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 227, 248, var(--tw-bg-opacity));
  }

  .xl\:tw-bg-blue-300{
    --tw-bg-opacity: 1;
    background-color: rgba(144, 205, 244, var(--tw-bg-opacity));
  }

  .xl\:tw-bg-blue-400{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 179, 237, var(--tw-bg-opacity));
  }

  .xl\:tw-bg-blue-500{
    --tw-bg-opacity: 1;
    background-color: rgba(66, 153, 225, var(--tw-bg-opacity));
  }

  .xl\:tw-bg-blue-600{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 130, 206, var(--tw-bg-opacity));
  }

  .xl\:tw-bg-blue-700{
    --tw-bg-opacity: 1;
    background-color: rgba(43, 108, 176, var(--tw-bg-opacity));
  }

  .xl\:tw-bg-blue-800{
    --tw-bg-opacity: 1;
    background-color: rgba(44, 82, 130, var(--tw-bg-opacity));
  }

  .xl\:tw-bg-blue-900{
    --tw-bg-opacity: 1;
    background-color: rgba(42, 67, 101, var(--tw-bg-opacity));
  }

  .xl\:hover\:tw-bg-transparent:hover{
    background-color: transparent;
  }

  .xl\:hover\:tw-bg-current:hover{
    background-color: currentColor;
  }

  .xl\:hover\:tw-bg-black:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(44, 44, 44, var(--tw-bg-opacity));
  }

  .xl\:hover\:tw-bg-white:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .xl\:hover\:tw-bg-gray-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
  }

  .xl\:hover\:tw-bg-gray-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 242, 247, var(--tw-bg-opacity));
  }

  .xl\:hover\:tw-bg-gray-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
  }

  .xl\:hover\:tw-bg-gray-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(203, 213, 224, var(--tw-bg-opacity));
  }

  .xl\:hover\:tw-bg-gray-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(160, 174, 192, var(--tw-bg-opacity));
  }

  .xl\:hover\:tw-bg-gray-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(113, 128, 150, var(--tw-bg-opacity));
  }

  .xl\:hover\:tw-bg-gray-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(74, 85, 104, var(--tw-bg-opacity));
  }

  .xl\:hover\:tw-bg-gray-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(45, 55, 72, var(--tw-bg-opacity));
  }

  .xl\:hover\:tw-bg-gray-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(26, 32, 44, var(--tw-bg-opacity));
  }

  .xl\:hover\:tw-bg-blue-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(235, 248, 255, var(--tw-bg-opacity));
  }

  .xl\:hover\:tw-bg-blue-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 227, 248, var(--tw-bg-opacity));
  }

  .xl\:hover\:tw-bg-blue-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(144, 205, 244, var(--tw-bg-opacity));
  }

  .xl\:hover\:tw-bg-blue-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 179, 237, var(--tw-bg-opacity));
  }

  .xl\:hover\:tw-bg-blue-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(66, 153, 225, var(--tw-bg-opacity));
  }

  .xl\:hover\:tw-bg-blue-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 130, 206, var(--tw-bg-opacity));
  }

  .xl\:hover\:tw-bg-blue-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(43, 108, 176, var(--tw-bg-opacity));
  }

  .xl\:hover\:tw-bg-blue-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(44, 82, 130, var(--tw-bg-opacity));
  }

  .xl\:hover\:tw-bg-blue-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(42, 67, 101, var(--tw-bg-opacity));
  }

  .xl\:focus\:tw-bg-transparent:focus{
    background-color: transparent;
  }

  .xl\:focus\:tw-bg-current:focus{
    background-color: currentColor;
  }

  .xl\:focus\:tw-bg-black:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(44, 44, 44, var(--tw-bg-opacity));
  }

  .xl\:focus\:tw-bg-white:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .xl\:focus\:tw-bg-gray-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
  }

  .xl\:focus\:tw-bg-gray-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 242, 247, var(--tw-bg-opacity));
  }

  .xl\:focus\:tw-bg-gray-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
  }

  .xl\:focus\:tw-bg-gray-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(203, 213, 224, var(--tw-bg-opacity));
  }

  .xl\:focus\:tw-bg-gray-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(160, 174, 192, var(--tw-bg-opacity));
  }

  .xl\:focus\:tw-bg-gray-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(113, 128, 150, var(--tw-bg-opacity));
  }

  .xl\:focus\:tw-bg-gray-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(74, 85, 104, var(--tw-bg-opacity));
  }

  .xl\:focus\:tw-bg-gray-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(45, 55, 72, var(--tw-bg-opacity));
  }

  .xl\:focus\:tw-bg-gray-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(26, 32, 44, var(--tw-bg-opacity));
  }

  .xl\:focus\:tw-bg-blue-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(235, 248, 255, var(--tw-bg-opacity));
  }

  .xl\:focus\:tw-bg-blue-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 227, 248, var(--tw-bg-opacity));
  }

  .xl\:focus\:tw-bg-blue-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(144, 205, 244, var(--tw-bg-opacity));
  }

  .xl\:focus\:tw-bg-blue-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 179, 237, var(--tw-bg-opacity));
  }

  .xl\:focus\:tw-bg-blue-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(66, 153, 225, var(--tw-bg-opacity));
  }

  .xl\:focus\:tw-bg-blue-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 130, 206, var(--tw-bg-opacity));
  }

  .xl\:focus\:tw-bg-blue-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(43, 108, 176, var(--tw-bg-opacity));
  }

  .xl\:focus\:tw-bg-blue-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(44, 82, 130, var(--tw-bg-opacity));
  }

  .xl\:focus\:tw-bg-blue-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(42, 67, 101, var(--tw-bg-opacity));
  }

  .xl\:tw-bg-opacity-0{
    --tw-bg-opacity: 0;
  }

  .xl\:tw-bg-opacity-25{
    --tw-bg-opacity: 0.25;
  }

  .xl\:tw-bg-opacity-50{
    --tw-bg-opacity: 0.5;
  }

  .xl\:tw-bg-opacity-75{
    --tw-bg-opacity: 0.75;
  }

  .xl\:tw-bg-opacity-90{
    --tw-bg-opacity: 0.9;
  }

  .xl\:tw-bg-opacity-100{
    --tw-bg-opacity: 1;
  }

  .xl\:hover\:tw-bg-opacity-0:hover{
    --tw-bg-opacity: 0;
  }

  .xl\:hover\:tw-bg-opacity-25:hover{
    --tw-bg-opacity: 0.25;
  }

  .xl\:hover\:tw-bg-opacity-50:hover{
    --tw-bg-opacity: 0.5;
  }

  .xl\:hover\:tw-bg-opacity-75:hover{
    --tw-bg-opacity: 0.75;
  }

  .xl\:hover\:tw-bg-opacity-90:hover{
    --tw-bg-opacity: 0.9;
  }

  .xl\:hover\:tw-bg-opacity-100:hover{
    --tw-bg-opacity: 1;
  }

  .xl\:focus\:tw-bg-opacity-0:focus{
    --tw-bg-opacity: 0;
  }

  .xl\:focus\:tw-bg-opacity-25:focus{
    --tw-bg-opacity: 0.25;
  }

  .xl\:focus\:tw-bg-opacity-50:focus{
    --tw-bg-opacity: 0.5;
  }

  .xl\:focus\:tw-bg-opacity-75:focus{
    --tw-bg-opacity: 0.75;
  }

  .xl\:focus\:tw-bg-opacity-90:focus{
    --tw-bg-opacity: 0.9;
  }

  .xl\:focus\:tw-bg-opacity-100:focus{
    --tw-bg-opacity: 1;
  }

  .xl\:tw-bg-none{
    background-image: none;
  }

  .xl\:tw-bg-gradient-to-t{
    background-image: linear-gradient(to top, var(--gradient-color-stops));
  }

  .xl\:tw-bg-gradient-to-tr{
    background-image: linear-gradient(to top right, var(--gradient-color-stops));
  }

  .xl\:tw-bg-gradient-to-r{
    background-image: linear-gradient(to right, var(--gradient-color-stops));
  }

  .xl\:tw-bg-gradient-to-br{
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops));
  }

  .xl\:tw-bg-gradient-to-b{
    background-image: linear-gradient(to bottom, var(--gradient-color-stops));
  }

  .xl\:tw-bg-gradient-to-bl{
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops));
  }

  .xl\:tw-bg-gradient-to-l{
    background-image: linear-gradient(to left, var(--gradient-color-stops));
  }

  .xl\:tw-bg-gradient-to-tl{
    background-image: linear-gradient(to top left, var(--gradient-color-stops));
  }

  .xl\:tw-from-transparent{
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xl\:tw-from-current{
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:tw-from-black{
    --tw-gradient-from: #2c2c2c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 44, 44, 0));
  }

  .xl\:tw-from-white{
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:tw-from-gray-100{
    --tw-gradient-from: #f7fafc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 250, 252, 0));
  }

  .xl\:tw-from-gray-200{
    --tw-gradient-from: #edf2f7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 242, 247, 0));
  }

  .xl\:tw-from-gray-300{
    --tw-gradient-from: #e2e8f0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(226, 232, 240, 0));
  }

  .xl\:tw-from-gray-400{
    --tw-gradient-from: #cbd5e0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(203, 213, 224, 0));
  }

  .xl\:tw-from-gray-500{
    --tw-gradient-from: #a0aec0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(160, 174, 192, 0));
  }

  .xl\:tw-from-gray-600{
    --tw-gradient-from: #718096;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(113, 128, 150, 0));
  }

  .xl\:tw-from-gray-700{
    --tw-gradient-from: #4a5568;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 85, 104, 0));
  }

  .xl\:tw-from-gray-800{
    --tw-gradient-from: #2d3748;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 72, 0));
  }

  .xl\:tw-from-gray-900{
    --tw-gradient-from: #1a202c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 32, 44, 0));
  }

  .xl\:tw-from-blue-100{
    --tw-gradient-from: #ebf8ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(235, 248, 255, 0));
  }

  .xl\:tw-from-blue-200{
    --tw-gradient-from: #bee3f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 227, 248, 0));
  }

  .xl\:tw-from-blue-300{
    --tw-gradient-from: #90cdf4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 205, 244, 0));
  }

  .xl\:tw-from-blue-400{
    --tw-gradient-from: #63b3ed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 179, 237, 0));
  }

  .xl\:tw-from-blue-500{
    --tw-gradient-from: #4299e1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 153, 225, 0));
  }

  .xl\:tw-from-blue-600{
    --tw-gradient-from: #3182ce;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 130, 206, 0));
  }

  .xl\:tw-from-blue-700{
    --tw-gradient-from: #2b6cb0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(43, 108, 176, 0));
  }

  .xl\:tw-from-blue-800{
    --tw-gradient-from: #2c5282;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 82, 130, 0));
  }

  .xl\:tw-from-blue-900{
    --tw-gradient-from: #2a4365;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(42, 67, 101, 0));
  }

  .xl\:hover\:tw-from-transparent:hover{
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xl\:hover\:tw-from-current:hover{
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:hover\:tw-from-black:hover{
    --tw-gradient-from: #2c2c2c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 44, 44, 0));
  }

  .xl\:hover\:tw-from-white:hover{
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:hover\:tw-from-gray-100:hover{
    --tw-gradient-from: #f7fafc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 250, 252, 0));
  }

  .xl\:hover\:tw-from-gray-200:hover{
    --tw-gradient-from: #edf2f7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 242, 247, 0));
  }

  .xl\:hover\:tw-from-gray-300:hover{
    --tw-gradient-from: #e2e8f0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(226, 232, 240, 0));
  }

  .xl\:hover\:tw-from-gray-400:hover{
    --tw-gradient-from: #cbd5e0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(203, 213, 224, 0));
  }

  .xl\:hover\:tw-from-gray-500:hover{
    --tw-gradient-from: #a0aec0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(160, 174, 192, 0));
  }

  .xl\:hover\:tw-from-gray-600:hover{
    --tw-gradient-from: #718096;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(113, 128, 150, 0));
  }

  .xl\:hover\:tw-from-gray-700:hover{
    --tw-gradient-from: #4a5568;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 85, 104, 0));
  }

  .xl\:hover\:tw-from-gray-800:hover{
    --tw-gradient-from: #2d3748;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 72, 0));
  }

  .xl\:hover\:tw-from-gray-900:hover{
    --tw-gradient-from: #1a202c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 32, 44, 0));
  }

  .xl\:hover\:tw-from-blue-100:hover{
    --tw-gradient-from: #ebf8ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(235, 248, 255, 0));
  }

  .xl\:hover\:tw-from-blue-200:hover{
    --tw-gradient-from: #bee3f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 227, 248, 0));
  }

  .xl\:hover\:tw-from-blue-300:hover{
    --tw-gradient-from: #90cdf4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 205, 244, 0));
  }

  .xl\:hover\:tw-from-blue-400:hover{
    --tw-gradient-from: #63b3ed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 179, 237, 0));
  }

  .xl\:hover\:tw-from-blue-500:hover{
    --tw-gradient-from: #4299e1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 153, 225, 0));
  }

  .xl\:hover\:tw-from-blue-600:hover{
    --tw-gradient-from: #3182ce;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 130, 206, 0));
  }

  .xl\:hover\:tw-from-blue-700:hover{
    --tw-gradient-from: #2b6cb0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(43, 108, 176, 0));
  }

  .xl\:hover\:tw-from-blue-800:hover{
    --tw-gradient-from: #2c5282;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 82, 130, 0));
  }

  .xl\:hover\:tw-from-blue-900:hover{
    --tw-gradient-from: #2a4365;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(42, 67, 101, 0));
  }

  .xl\:focus\:tw-from-transparent:focus{
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xl\:focus\:tw-from-current:focus{
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:focus\:tw-from-black:focus{
    --tw-gradient-from: #2c2c2c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 44, 44, 0));
  }

  .xl\:focus\:tw-from-white:focus{
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:focus\:tw-from-gray-100:focus{
    --tw-gradient-from: #f7fafc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 250, 252, 0));
  }

  .xl\:focus\:tw-from-gray-200:focus{
    --tw-gradient-from: #edf2f7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 242, 247, 0));
  }

  .xl\:focus\:tw-from-gray-300:focus{
    --tw-gradient-from: #e2e8f0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(226, 232, 240, 0));
  }

  .xl\:focus\:tw-from-gray-400:focus{
    --tw-gradient-from: #cbd5e0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(203, 213, 224, 0));
  }

  .xl\:focus\:tw-from-gray-500:focus{
    --tw-gradient-from: #a0aec0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(160, 174, 192, 0));
  }

  .xl\:focus\:tw-from-gray-600:focus{
    --tw-gradient-from: #718096;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(113, 128, 150, 0));
  }

  .xl\:focus\:tw-from-gray-700:focus{
    --tw-gradient-from: #4a5568;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(74, 85, 104, 0));
  }

  .xl\:focus\:tw-from-gray-800:focus{
    --tw-gradient-from: #2d3748;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 72, 0));
  }

  .xl\:focus\:tw-from-gray-900:focus{
    --tw-gradient-from: #1a202c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(26, 32, 44, 0));
  }

  .xl\:focus\:tw-from-blue-100:focus{
    --tw-gradient-from: #ebf8ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(235, 248, 255, 0));
  }

  .xl\:focus\:tw-from-blue-200:focus{
    --tw-gradient-from: #bee3f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 227, 248, 0));
  }

  .xl\:focus\:tw-from-blue-300:focus{
    --tw-gradient-from: #90cdf4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(144, 205, 244, 0));
  }

  .xl\:focus\:tw-from-blue-400:focus{
    --tw-gradient-from: #63b3ed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 179, 237, 0));
  }

  .xl\:focus\:tw-from-blue-500:focus{
    --tw-gradient-from: #4299e1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(66, 153, 225, 0));
  }

  .xl\:focus\:tw-from-blue-600:focus{
    --tw-gradient-from: #3182ce;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 130, 206, 0));
  }

  .xl\:focus\:tw-from-blue-700:focus{
    --tw-gradient-from: #2b6cb0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(43, 108, 176, 0));
  }

  .xl\:focus\:tw-from-blue-800:focus{
    --tw-gradient-from: #2c5282;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(44, 82, 130, 0));
  }

  .xl\:focus\:tw-from-blue-900:focus{
    --tw-gradient-from: #2a4365;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(42, 67, 101, 0));
  }

  .xl\:tw-via-transparent{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xl\:tw-via-current{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:tw-via-black{
    --tw-gradient-stops: var(--tw-gradient-from), #2c2c2c, var(--tw-gradient-to, rgba(44, 44, 44, 0));
  }

  .xl\:tw-via-white{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:tw-via-gray-100{
    --tw-gradient-stops: var(--tw-gradient-from), #f7fafc, var(--tw-gradient-to, rgba(247, 250, 252, 0));
  }

  .xl\:tw-via-gray-200{
    --tw-gradient-stops: var(--tw-gradient-from), #edf2f7, var(--tw-gradient-to, rgba(237, 242, 247, 0));
  }

  .xl\:tw-via-gray-300{
    --tw-gradient-stops: var(--tw-gradient-from), #e2e8f0, var(--tw-gradient-to, rgba(226, 232, 240, 0));
  }

  .xl\:tw-via-gray-400{
    --tw-gradient-stops: var(--tw-gradient-from), #cbd5e0, var(--tw-gradient-to, rgba(203, 213, 224, 0));
  }

  .xl\:tw-via-gray-500{
    --tw-gradient-stops: var(--tw-gradient-from), #a0aec0, var(--tw-gradient-to, rgba(160, 174, 192, 0));
  }

  .xl\:tw-via-gray-600{
    --tw-gradient-stops: var(--tw-gradient-from), #718096, var(--tw-gradient-to, rgba(113, 128, 150, 0));
  }

  .xl\:tw-via-gray-700{
    --tw-gradient-stops: var(--tw-gradient-from), #4a5568, var(--tw-gradient-to, rgba(74, 85, 104, 0));
  }

  .xl\:tw-via-gray-800{
    --tw-gradient-stops: var(--tw-gradient-from), #2d3748, var(--tw-gradient-to, rgba(45, 55, 72, 0));
  }

  .xl\:tw-via-gray-900{
    --tw-gradient-stops: var(--tw-gradient-from), #1a202c, var(--tw-gradient-to, rgba(26, 32, 44, 0));
  }

  .xl\:tw-via-blue-100{
    --tw-gradient-stops: var(--tw-gradient-from), #ebf8ff, var(--tw-gradient-to, rgba(235, 248, 255, 0));
  }

  .xl\:tw-via-blue-200{
    --tw-gradient-stops: var(--tw-gradient-from), #bee3f8, var(--tw-gradient-to, rgba(190, 227, 248, 0));
  }

  .xl\:tw-via-blue-300{
    --tw-gradient-stops: var(--tw-gradient-from), #90cdf4, var(--tw-gradient-to, rgba(144, 205, 244, 0));
  }

  .xl\:tw-via-blue-400{
    --tw-gradient-stops: var(--tw-gradient-from), #63b3ed, var(--tw-gradient-to, rgba(99, 179, 237, 0));
  }

  .xl\:tw-via-blue-500{
    --tw-gradient-stops: var(--tw-gradient-from), #4299e1, var(--tw-gradient-to, rgba(66, 153, 225, 0));
  }

  .xl\:tw-via-blue-600{
    --tw-gradient-stops: var(--tw-gradient-from), #3182ce, var(--tw-gradient-to, rgba(49, 130, 206, 0));
  }

  .xl\:tw-via-blue-700{
    --tw-gradient-stops: var(--tw-gradient-from), #2b6cb0, var(--tw-gradient-to, rgba(43, 108, 176, 0));
  }

  .xl\:tw-via-blue-800{
    --tw-gradient-stops: var(--tw-gradient-from), #2c5282, var(--tw-gradient-to, rgba(44, 82, 130, 0));
  }

  .xl\:tw-via-blue-900{
    --tw-gradient-stops: var(--tw-gradient-from), #2a4365, var(--tw-gradient-to, rgba(42, 67, 101, 0));
  }

  .xl\:hover\:tw-via-transparent:hover{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xl\:hover\:tw-via-current:hover{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:hover\:tw-via-black:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2c2c2c, var(--tw-gradient-to, rgba(44, 44, 44, 0));
  }

  .xl\:hover\:tw-via-white:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:hover\:tw-via-gray-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f7fafc, var(--tw-gradient-to, rgba(247, 250, 252, 0));
  }

  .xl\:hover\:tw-via-gray-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #edf2f7, var(--tw-gradient-to, rgba(237, 242, 247, 0));
  }

  .xl\:hover\:tw-via-gray-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e2e8f0, var(--tw-gradient-to, rgba(226, 232, 240, 0));
  }

  .xl\:hover\:tw-via-gray-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #cbd5e0, var(--tw-gradient-to, rgba(203, 213, 224, 0));
  }

  .xl\:hover\:tw-via-gray-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a0aec0, var(--tw-gradient-to, rgba(160, 174, 192, 0));
  }

  .xl\:hover\:tw-via-gray-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #718096, var(--tw-gradient-to, rgba(113, 128, 150, 0));
  }

  .xl\:hover\:tw-via-gray-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4a5568, var(--tw-gradient-to, rgba(74, 85, 104, 0));
  }

  .xl\:hover\:tw-via-gray-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2d3748, var(--tw-gradient-to, rgba(45, 55, 72, 0));
  }

  .xl\:hover\:tw-via-gray-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1a202c, var(--tw-gradient-to, rgba(26, 32, 44, 0));
  }

  .xl\:hover\:tw-via-blue-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ebf8ff, var(--tw-gradient-to, rgba(235, 248, 255, 0));
  }

  .xl\:hover\:tw-via-blue-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #bee3f8, var(--tw-gradient-to, rgba(190, 227, 248, 0));
  }

  .xl\:hover\:tw-via-blue-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #90cdf4, var(--tw-gradient-to, rgba(144, 205, 244, 0));
  }

  .xl\:hover\:tw-via-blue-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #63b3ed, var(--tw-gradient-to, rgba(99, 179, 237, 0));
  }

  .xl\:hover\:tw-via-blue-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4299e1, var(--tw-gradient-to, rgba(66, 153, 225, 0));
  }

  .xl\:hover\:tw-via-blue-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3182ce, var(--tw-gradient-to, rgba(49, 130, 206, 0));
  }

  .xl\:hover\:tw-via-blue-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2b6cb0, var(--tw-gradient-to, rgba(43, 108, 176, 0));
  }

  .xl\:hover\:tw-via-blue-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2c5282, var(--tw-gradient-to, rgba(44, 82, 130, 0));
  }

  .xl\:hover\:tw-via-blue-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2a4365, var(--tw-gradient-to, rgba(42, 67, 101, 0));
  }

  .xl\:focus\:tw-via-transparent:focus{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xl\:focus\:tw-via-current:focus{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:focus\:tw-via-black:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2c2c2c, var(--tw-gradient-to, rgba(44, 44, 44, 0));
  }

  .xl\:focus\:tw-via-white:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:focus\:tw-via-gray-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f7fafc, var(--tw-gradient-to, rgba(247, 250, 252, 0));
  }

  .xl\:focus\:tw-via-gray-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #edf2f7, var(--tw-gradient-to, rgba(237, 242, 247, 0));
  }

  .xl\:focus\:tw-via-gray-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e2e8f0, var(--tw-gradient-to, rgba(226, 232, 240, 0));
  }

  .xl\:focus\:tw-via-gray-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #cbd5e0, var(--tw-gradient-to, rgba(203, 213, 224, 0));
  }

  .xl\:focus\:tw-via-gray-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a0aec0, var(--tw-gradient-to, rgba(160, 174, 192, 0));
  }

  .xl\:focus\:tw-via-gray-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #718096, var(--tw-gradient-to, rgba(113, 128, 150, 0));
  }

  .xl\:focus\:tw-via-gray-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4a5568, var(--tw-gradient-to, rgba(74, 85, 104, 0));
  }

  .xl\:focus\:tw-via-gray-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2d3748, var(--tw-gradient-to, rgba(45, 55, 72, 0));
  }

  .xl\:focus\:tw-via-gray-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1a202c, var(--tw-gradient-to, rgba(26, 32, 44, 0));
  }

  .xl\:focus\:tw-via-blue-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ebf8ff, var(--tw-gradient-to, rgba(235, 248, 255, 0));
  }

  .xl\:focus\:tw-via-blue-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #bee3f8, var(--tw-gradient-to, rgba(190, 227, 248, 0));
  }

  .xl\:focus\:tw-via-blue-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #90cdf4, var(--tw-gradient-to, rgba(144, 205, 244, 0));
  }

  .xl\:focus\:tw-via-blue-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #63b3ed, var(--tw-gradient-to, rgba(99, 179, 237, 0));
  }

  .xl\:focus\:tw-via-blue-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4299e1, var(--tw-gradient-to, rgba(66, 153, 225, 0));
  }

  .xl\:focus\:tw-via-blue-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3182ce, var(--tw-gradient-to, rgba(49, 130, 206, 0));
  }

  .xl\:focus\:tw-via-blue-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2b6cb0, var(--tw-gradient-to, rgba(43, 108, 176, 0));
  }

  .xl\:focus\:tw-via-blue-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2c5282, var(--tw-gradient-to, rgba(44, 82, 130, 0));
  }

  .xl\:focus\:tw-via-blue-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2a4365, var(--tw-gradient-to, rgba(42, 67, 101, 0));
  }

  .xl\:tw-to-transparent{
    --tw-gradient-to: transparent;
  }

  .xl\:tw-to-current{
    --tw-gradient-to: currentColor;
  }

  .xl\:tw-to-black{
    --tw-gradient-to: #2c2c2c;
  }

  .xl\:tw-to-white{
    --tw-gradient-to: #fff;
  }

  .xl\:tw-to-gray-100{
    --tw-gradient-to: #f7fafc;
  }

  .xl\:tw-to-gray-200{
    --tw-gradient-to: #edf2f7;
  }

  .xl\:tw-to-gray-300{
    --tw-gradient-to: #e2e8f0;
  }

  .xl\:tw-to-gray-400{
    --tw-gradient-to: #cbd5e0;
  }

  .xl\:tw-to-gray-500{
    --tw-gradient-to: #a0aec0;
  }

  .xl\:tw-to-gray-600{
    --tw-gradient-to: #718096;
  }

  .xl\:tw-to-gray-700{
    --tw-gradient-to: #4a5568;
  }

  .xl\:tw-to-gray-800{
    --tw-gradient-to: #2d3748;
  }

  .xl\:tw-to-gray-900{
    --tw-gradient-to: #1a202c;
  }

  .xl\:tw-to-blue-100{
    --tw-gradient-to: #ebf8ff;
  }

  .xl\:tw-to-blue-200{
    --tw-gradient-to: #bee3f8;
  }

  .xl\:tw-to-blue-300{
    --tw-gradient-to: #90cdf4;
  }

  .xl\:tw-to-blue-400{
    --tw-gradient-to: #63b3ed;
  }

  .xl\:tw-to-blue-500{
    --tw-gradient-to: #4299e1;
  }

  .xl\:tw-to-blue-600{
    --tw-gradient-to: #3182ce;
  }

  .xl\:tw-to-blue-700{
    --tw-gradient-to: #2b6cb0;
  }

  .xl\:tw-to-blue-800{
    --tw-gradient-to: #2c5282;
  }

  .xl\:tw-to-blue-900{
    --tw-gradient-to: #2a4365;
  }

  .xl\:hover\:tw-to-transparent:hover{
    --tw-gradient-to: transparent;
  }

  .xl\:hover\:tw-to-current:hover{
    --tw-gradient-to: currentColor;
  }

  .xl\:hover\:tw-to-black:hover{
    --tw-gradient-to: #2c2c2c;
  }

  .xl\:hover\:tw-to-white:hover{
    --tw-gradient-to: #fff;
  }

  .xl\:hover\:tw-to-gray-100:hover{
    --tw-gradient-to: #f7fafc;
  }

  .xl\:hover\:tw-to-gray-200:hover{
    --tw-gradient-to: #edf2f7;
  }

  .xl\:hover\:tw-to-gray-300:hover{
    --tw-gradient-to: #e2e8f0;
  }

  .xl\:hover\:tw-to-gray-400:hover{
    --tw-gradient-to: #cbd5e0;
  }

  .xl\:hover\:tw-to-gray-500:hover{
    --tw-gradient-to: #a0aec0;
  }

  .xl\:hover\:tw-to-gray-600:hover{
    --tw-gradient-to: #718096;
  }

  .xl\:hover\:tw-to-gray-700:hover{
    --tw-gradient-to: #4a5568;
  }

  .xl\:hover\:tw-to-gray-800:hover{
    --tw-gradient-to: #2d3748;
  }

  .xl\:hover\:tw-to-gray-900:hover{
    --tw-gradient-to: #1a202c;
  }

  .xl\:hover\:tw-to-blue-100:hover{
    --tw-gradient-to: #ebf8ff;
  }

  .xl\:hover\:tw-to-blue-200:hover{
    --tw-gradient-to: #bee3f8;
  }

  .xl\:hover\:tw-to-blue-300:hover{
    --tw-gradient-to: #90cdf4;
  }

  .xl\:hover\:tw-to-blue-400:hover{
    --tw-gradient-to: #63b3ed;
  }

  .xl\:hover\:tw-to-blue-500:hover{
    --tw-gradient-to: #4299e1;
  }

  .xl\:hover\:tw-to-blue-600:hover{
    --tw-gradient-to: #3182ce;
  }

  .xl\:hover\:tw-to-blue-700:hover{
    --tw-gradient-to: #2b6cb0;
  }

  .xl\:hover\:tw-to-blue-800:hover{
    --tw-gradient-to: #2c5282;
  }

  .xl\:hover\:tw-to-blue-900:hover{
    --tw-gradient-to: #2a4365;
  }

  .xl\:focus\:tw-to-transparent:focus{
    --tw-gradient-to: transparent;
  }

  .xl\:focus\:tw-to-current:focus{
    --tw-gradient-to: currentColor;
  }

  .xl\:focus\:tw-to-black:focus{
    --tw-gradient-to: #2c2c2c;
  }

  .xl\:focus\:tw-to-white:focus{
    --tw-gradient-to: #fff;
  }

  .xl\:focus\:tw-to-gray-100:focus{
    --tw-gradient-to: #f7fafc;
  }

  .xl\:focus\:tw-to-gray-200:focus{
    --tw-gradient-to: #edf2f7;
  }

  .xl\:focus\:tw-to-gray-300:focus{
    --tw-gradient-to: #e2e8f0;
  }

  .xl\:focus\:tw-to-gray-400:focus{
    --tw-gradient-to: #cbd5e0;
  }

  .xl\:focus\:tw-to-gray-500:focus{
    --tw-gradient-to: #a0aec0;
  }

  .xl\:focus\:tw-to-gray-600:focus{
    --tw-gradient-to: #718096;
  }

  .xl\:focus\:tw-to-gray-700:focus{
    --tw-gradient-to: #4a5568;
  }

  .xl\:focus\:tw-to-gray-800:focus{
    --tw-gradient-to: #2d3748;
  }

  .xl\:focus\:tw-to-gray-900:focus{
    --tw-gradient-to: #1a202c;
  }

  .xl\:focus\:tw-to-blue-100:focus{
    --tw-gradient-to: #ebf8ff;
  }

  .xl\:focus\:tw-to-blue-200:focus{
    --tw-gradient-to: #bee3f8;
  }

  .xl\:focus\:tw-to-blue-300:focus{
    --tw-gradient-to: #90cdf4;
  }

  .xl\:focus\:tw-to-blue-400:focus{
    --tw-gradient-to: #63b3ed;
  }

  .xl\:focus\:tw-to-blue-500:focus{
    --tw-gradient-to: #4299e1;
  }

  .xl\:focus\:tw-to-blue-600:focus{
    --tw-gradient-to: #3182ce;
  }

  .xl\:focus\:tw-to-blue-700:focus{
    --tw-gradient-to: #2b6cb0;
  }

  .xl\:focus\:tw-to-blue-800:focus{
    --tw-gradient-to: #2c5282;
  }

  .xl\:focus\:tw-to-blue-900:focus{
    --tw-gradient-to: #2a4365;
  }

  .xl\:tw-decoration-slice{
    -webkit-box-decoration-break: slice;
    box-decoration-break: slice;
  }

  .xl\:tw-decoration-clone{
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  .xl\:tw-bg-auto{
    background-size: auto;
  }

  .xl\:tw-bg-cover{
    background-size: cover;
  }

  .xl\:tw-bg-contain{
    background-size: contain;
  }

  .xl\:tw-bg-fixed{
    background-attachment: fixed;
  }

  .xl\:tw-bg-local{
    background-attachment: local;
  }

  .xl\:tw-bg-scroll{
    background-attachment: scroll;
  }

  .xl\:tw-bg-clip-border{
    background-clip: border-box;
  }

  .xl\:tw-bg-clip-padding{
    background-clip: padding-box;
  }

  .xl\:tw-bg-clip-content{
    background-clip: content-box;
  }

  .xl\:tw-bg-clip-text{
    -webkit-background-clip: text;
    background-clip: text;
  }

  .xl\:tw-bg-bottom{
    background-position: bottom;
  }

  .xl\:tw-bg-center{
    background-position: center;
  }

  .xl\:tw-bg-left{
    background-position: left;
  }

  .xl\:tw-bg-left-bottom{
    background-position: left bottom;
  }

  .xl\:tw-bg-left-top{
    background-position: left top;
  }

  .xl\:tw-bg-right{
    background-position: right;
  }

  .xl\:tw-bg-right-bottom{
    background-position: right bottom;
  }

  .xl\:tw-bg-right-top{
    background-position: right top;
  }

  .xl\:tw-bg-top{
    background-position: top;
  }

  .xl\:tw-bg-repeat{
    background-repeat: repeat;
  }

  .xl\:tw-bg-no-repeat{
    background-repeat: no-repeat;
  }

  .xl\:tw-bg-repeat-x{
    background-repeat: repeat-x;
  }

  .xl\:tw-bg-repeat-y{
    background-repeat: repeat-y;
  }

  .xl\:tw-bg-repeat-round{
    background-repeat: round;
  }

  .xl\:tw-bg-repeat-space{
    background-repeat: space;
  }

  .xl\:tw-bg-origin-border{
    background-origin: border-box;
  }

  .xl\:tw-bg-origin-padding{
    background-origin: padding-box;
  }

  .xl\:tw-bg-origin-content{
    background-origin: content-box;
  }

  .xl\:tw-fill-current{
    fill: currentColor;
  }

  .xl\:tw-stroke-current{
    stroke: currentColor;
  }

  .xl\:tw-stroke-0{
    stroke-width: 0;
  }

  .xl\:tw-stroke-1{
    stroke-width: 1;
  }

  .xl\:tw-stroke-2{
    stroke-width: 2;
  }

  .xl\:tw-object-contain{
    object-fit: contain;
  }

  .xl\:tw-object-cover{
    object-fit: cover;
  }

  .xl\:tw-object-fill{
    object-fit: fill;
  }

  .xl\:tw-object-none{
    object-fit: none;
  }

  .xl\:tw-object-scale-down{
    object-fit: scale-down;
  }

  .xl\:tw-object-bottom{
    object-position: bottom;
  }

  .xl\:tw-object-center{
    object-position: center;
  }

  .xl\:tw-object-left{
    object-position: left;
  }

  .xl\:tw-object-left-bottom{
    object-position: left bottom;
  }

  .xl\:tw-object-left-top{
    object-position: left top;
  }

  .xl\:tw-object-right{
    object-position: right;
  }

  .xl\:tw-object-right-bottom{
    object-position: right bottom;
  }

  .xl\:tw-object-right-top{
    object-position: right top;
  }

  .xl\:tw-object-top{
    object-position: top;
  }

  .xl\:tw-p-0{
    padding: 0;
  }

  .xl\:tw-p-1{
    padding: 0.25rem;
  }

  .xl\:tw-p-2{
    padding: 0.5rem;
  }

  .xl\:tw-p-3{
    padding: 0.75rem;
  }

  .xl\:tw-p-4{
    padding: 1rem;
  }

  .xl\:tw-p-5{
    padding: 1.25rem;
  }

  .xl\:tw-p-6{
    padding: 1.5rem;
  }

  .xl\:tw-p-8{
    padding: 2rem;
  }

  .xl\:tw-p-10{
    padding: 2.5rem;
  }

  .xl\:tw-p-12{
    padding: 3rem;
  }

  .xl\:tw-p-16{
    padding: 4rem;
  }

  .xl\:tw-p-20{
    padding: 5rem;
  }

  .xl\:tw-p-24{
    padding: 6rem;
  }

  .xl\:tw-p-32{
    padding: 8rem;
  }

  .xl\:tw-p-40{
    padding: 10rem;
  }

  .xl\:tw-p-48{
    padding: 12rem;
  }

  .xl\:tw-p-56{
    padding: 14rem;
  }

  .xl\:tw-p-64{
    padding: 16rem;
  }

  .xl\:tw-p-px{
    padding: 1px;
  }

  .xl\:tw-px-0{
    padding-left: 0;
    padding-right: 0;
  }

  .xl\:tw-px-1{
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .xl\:tw-px-2{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .xl\:tw-px-3{
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .xl\:tw-px-4{
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .xl\:tw-px-5{
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .xl\:tw-px-6{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .xl\:tw-px-8{
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .xl\:tw-px-10{
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .xl\:tw-px-12{
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .xl\:tw-px-16{
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .xl\:tw-px-20{
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .xl\:tw-px-24{
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .xl\:tw-px-32{
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .xl\:tw-px-40{
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .xl\:tw-px-48{
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .xl\:tw-px-56{
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .xl\:tw-px-64{
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .xl\:tw-px-px{
    padding-left: 1px;
    padding-right: 1px;
  }

  .xl\:tw-py-0{
    padding-top: 0;
    padding-bottom: 0;
  }

  .xl\:tw-py-1{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .xl\:tw-py-2{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .xl\:tw-py-3{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .xl\:tw-py-4{
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .xl\:tw-py-5{
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .xl\:tw-py-6{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .xl\:tw-py-8{
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .xl\:tw-py-10{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .xl\:tw-py-12{
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .xl\:tw-py-16{
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .xl\:tw-py-20{
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .xl\:tw-py-24{
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .xl\:tw-py-32{
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .xl\:tw-py-40{
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .xl\:tw-py-48{
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .xl\:tw-py-56{
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .xl\:tw-py-64{
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .xl\:tw-py-px{
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .xl\:tw-pt-0{
    padding-top: 0;
  }

  .xl\:tw-pt-1{
    padding-top: 0.25rem;
  }

  .xl\:tw-pt-2{
    padding-top: 0.5rem;
  }

  .xl\:tw-pt-3{
    padding-top: 0.75rem;
  }

  .xl\:tw-pt-4{
    padding-top: 1rem;
  }

  .xl\:tw-pt-5{
    padding-top: 1.25rem;
  }

  .xl\:tw-pt-6{
    padding-top: 1.5rem;
  }

  .xl\:tw-pt-8{
    padding-top: 2rem;
  }

  .xl\:tw-pt-10{
    padding-top: 2.5rem;
  }

  .xl\:tw-pt-12{
    padding-top: 3rem;
  }

  .xl\:tw-pt-16{
    padding-top: 4rem;
  }

  .xl\:tw-pt-20{
    padding-top: 5rem;
  }

  .xl\:tw-pt-24{
    padding-top: 6rem;
  }

  .xl\:tw-pt-32{
    padding-top: 8rem;
  }

  .xl\:tw-pt-40{
    padding-top: 10rem;
  }

  .xl\:tw-pt-48{
    padding-top: 12rem;
  }

  .xl\:tw-pt-56{
    padding-top: 14rem;
  }

  .xl\:tw-pt-64{
    padding-top: 16rem;
  }

  .xl\:tw-pt-px{
    padding-top: 1px;
  }

  .xl\:tw-pr-0{
    padding-right: 0;
  }

  .xl\:tw-pr-1{
    padding-right: 0.25rem;
  }

  .xl\:tw-pr-2{
    padding-right: 0.5rem;
  }

  .xl\:tw-pr-3{
    padding-right: 0.75rem;
  }

  .xl\:tw-pr-4{
    padding-right: 1rem;
  }

  .xl\:tw-pr-5{
    padding-right: 1.25rem;
  }

  .xl\:tw-pr-6{
    padding-right: 1.5rem;
  }

  .xl\:tw-pr-8{
    padding-right: 2rem;
  }

  .xl\:tw-pr-10{
    padding-right: 2.5rem;
  }

  .xl\:tw-pr-12{
    padding-right: 3rem;
  }

  .xl\:tw-pr-16{
    padding-right: 4rem;
  }

  .xl\:tw-pr-20{
    padding-right: 5rem;
  }

  .xl\:tw-pr-24{
    padding-right: 6rem;
  }

  .xl\:tw-pr-32{
    padding-right: 8rem;
  }

  .xl\:tw-pr-40{
    padding-right: 10rem;
  }

  .xl\:tw-pr-48{
    padding-right: 12rem;
  }

  .xl\:tw-pr-56{
    padding-right: 14rem;
  }

  .xl\:tw-pr-64{
    padding-right: 16rem;
  }

  .xl\:tw-pr-px{
    padding-right: 1px;
  }

  .xl\:tw-pb-0{
    padding-bottom: 0;
  }

  .xl\:tw-pb-1{
    padding-bottom: 0.25rem;
  }

  .xl\:tw-pb-2{
    padding-bottom: 0.5rem;
  }

  .xl\:tw-pb-3{
    padding-bottom: 0.75rem;
  }

  .xl\:tw-pb-4{
    padding-bottom: 1rem;
  }

  .xl\:tw-pb-5{
    padding-bottom: 1.25rem;
  }

  .xl\:tw-pb-6{
    padding-bottom: 1.5rem;
  }

  .xl\:tw-pb-8{
    padding-bottom: 2rem;
  }

  .xl\:tw-pb-10{
    padding-bottom: 2.5rem;
  }

  .xl\:tw-pb-12{
    padding-bottom: 3rem;
  }

  .xl\:tw-pb-16{
    padding-bottom: 4rem;
  }

  .xl\:tw-pb-20{
    padding-bottom: 5rem;
  }

  .xl\:tw-pb-24{
    padding-bottom: 6rem;
  }

  .xl\:tw-pb-32{
    padding-bottom: 8rem;
  }

  .xl\:tw-pb-40{
    padding-bottom: 10rem;
  }

  .xl\:tw-pb-48{
    padding-bottom: 12rem;
  }

  .xl\:tw-pb-56{
    padding-bottom: 14rem;
  }

  .xl\:tw-pb-64{
    padding-bottom: 16rem;
  }

  .xl\:tw-pb-px{
    padding-bottom: 1px;
  }

  .xl\:tw-pl-0{
    padding-left: 0;
  }

  .xl\:tw-pl-1{
    padding-left: 0.25rem;
  }

  .xl\:tw-pl-2{
    padding-left: 0.5rem;
  }

  .xl\:tw-pl-3{
    padding-left: 0.75rem;
  }

  .xl\:tw-pl-4{
    padding-left: 1rem;
  }

  .xl\:tw-pl-5{
    padding-left: 1.25rem;
  }

  .xl\:tw-pl-6{
    padding-left: 1.5rem;
  }

  .xl\:tw-pl-8{
    padding-left: 2rem;
  }

  .xl\:tw-pl-10{
    padding-left: 2.5rem;
  }

  .xl\:tw-pl-12{
    padding-left: 3rem;
  }

  .xl\:tw-pl-16{
    padding-left: 4rem;
  }

  .xl\:tw-pl-20{
    padding-left: 5rem;
  }

  .xl\:tw-pl-24{
    padding-left: 6rem;
  }

  .xl\:tw-pl-32{
    padding-left: 8rem;
  }

  .xl\:tw-pl-40{
    padding-left: 10rem;
  }

  .xl\:tw-pl-48{
    padding-left: 12rem;
  }

  .xl\:tw-pl-56{
    padding-left: 14rem;
  }

  .xl\:tw-pl-64{
    padding-left: 16rem;
  }

  .xl\:tw-pl-px{
    padding-left: 1px;
  }

  .xl\:tw-text-left{
    text-align: left;
  }

  .xl\:tw-text-center{
    text-align: center;
  }

  .xl\:tw-text-right{
    text-align: right;
  }

  .xl\:tw-text-justify{
    text-align: justify;
  }

  .xl\:tw-align-baseline{
    vertical-align: baseline;
  }

  .xl\:tw-align-top{
    vertical-align: top;
  }

  .xl\:tw-align-middle{
    vertical-align: middle;
  }

  .xl\:tw-align-bottom{
    vertical-align: bottom;
  }

  .xl\:tw-align-text-top{
    vertical-align: text-top;
  }

  .xl\:tw-align-text-bottom{
    vertical-align: text-bottom;
  }

  .xl\:tw-font-sans{
    font-family: "Gotham SSm A", "Gotham SSm B", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  .xl\:tw-font-serif{
    font-family: Georgia, Cambria, "Times New Roman", Times, serif;
  }

  .xl\:tw-font-mono{
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

  .xl\:tw-text-xs{
    font-size: 0.75rem;
  }

  .xl\:tw-text-sm{
    font-size: 0.875rem;
  }

  .xl\:tw-text-base{
    font-size: 1rem;
  }

  .xl\:tw-text-lg{
    font-size: 1.125rem;
  }

  .xl\:tw-text-xl{
    font-size: 1.25rem;
  }

  .xl\:tw-text-2xl{
    font-size: 1.5rem;
  }

  .xl\:tw-text-3xl{
    font-size: 1.875rem;
  }

  .xl\:tw-text-4xl{
    font-size: 2.25rem;
  }

  .xl\:tw-text-5xl{
    font-size: 3rem;
  }

  .xl\:tw-text-6xl{
    font-size: 4rem;
  }

  .xl\:tw-font-hairline{
    font-weight: 100;
  }

  .xl\:tw-font-thin{
    font-weight: 200;
  }

  .xl\:tw-font-light{
    font-weight: 300;
  }

  .xl\:tw-font-normal{
    font-weight: 400;
  }

  .xl\:tw-font-medium{
    font-weight: 500;
  }

  .xl\:tw-font-semibold{
    font-weight: 600;
  }

  .xl\:tw-font-bold{
    font-weight: 700;
  }

  .xl\:tw-font-extrabold{
    font-weight: 800;
  }

  .xl\:tw-font-black{
    font-weight: 900;
  }

  .xl\:hover\:tw-font-hairline:hover{
    font-weight: 100;
  }

  .xl\:hover\:tw-font-thin:hover{
    font-weight: 200;
  }

  .xl\:hover\:tw-font-light:hover{
    font-weight: 300;
  }

  .xl\:hover\:tw-font-normal:hover{
    font-weight: 400;
  }

  .xl\:hover\:tw-font-medium:hover{
    font-weight: 500;
  }

  .xl\:hover\:tw-font-semibold:hover{
    font-weight: 600;
  }

  .xl\:hover\:tw-font-bold:hover{
    font-weight: 700;
  }

  .xl\:hover\:tw-font-extrabold:hover{
    font-weight: 800;
  }

  .xl\:hover\:tw-font-black:hover{
    font-weight: 900;
  }

  .xl\:focus\:tw-font-hairline:focus{
    font-weight: 100;
  }

  .xl\:focus\:tw-font-thin:focus{
    font-weight: 200;
  }

  .xl\:focus\:tw-font-light:focus{
    font-weight: 300;
  }

  .xl\:focus\:tw-font-normal:focus{
    font-weight: 400;
  }

  .xl\:focus\:tw-font-medium:focus{
    font-weight: 500;
  }

  .xl\:focus\:tw-font-semibold:focus{
    font-weight: 600;
  }

  .xl\:focus\:tw-font-bold:focus{
    font-weight: 700;
  }

  .xl\:focus\:tw-font-extrabold:focus{
    font-weight: 800;
  }

  .xl\:focus\:tw-font-black:focus{
    font-weight: 900;
  }

  .xl\:tw-uppercase{
    text-transform: uppercase;
  }

  .xl\:tw-lowercase{
    text-transform: lowercase;
  }

  .xl\:tw-capitalize{
    text-transform: capitalize;
  }

  .xl\:tw-normal-case{
    text-transform: none;
  }

  .xl\:tw-italic{
    font-style: italic;
  }

  .xl\:tw-not-italic{
    font-style: normal;
  }

  .xl\:tw-ordinal, .xl\:tw-slashed-zero, .xl\:tw-lining-nums, .xl\:tw-oldstyle-nums, .xl\:tw-proportional-nums, .xl\:tw-tabular-nums, .xl\:tw-diagonal-fractions, .xl\:tw-stacked-fractions{
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .xl\:tw-normal-nums{
    font-variant-numeric: normal;
  }

  .xl\:tw-ordinal{
    --tw-ordinal: ordinal;
  }

  .xl\:tw-slashed-zero{
    --tw-slashed-zero: slashed-zero;
  }

  .xl\:tw-lining-nums{
    --tw-numeric-figure: lining-nums;
  }

  .xl\:tw-oldstyle-nums{
    --tw-numeric-figure: oldstyle-nums;
  }

  .xl\:tw-proportional-nums{
    --tw-numeric-spacing: proportional-nums;
  }

  .xl\:tw-tabular-nums{
    --tw-numeric-spacing: tabular-nums;
  }

  .xl\:tw-diagonal-fractions{
    --tw-numeric-fraction: diagonal-fractions;
  }

  .xl\:tw-stacked-fractions{
    --tw-numeric-fraction: stacked-fractions;
  }

  .xl\:tw-leading-3{
    line-height: .75rem;
  }

  .xl\:tw-leading-4{
    line-height: 1rem;
  }

  .xl\:tw-leading-5{
    line-height: 1.25rem;
  }

  .xl\:tw-leading-6{
    line-height: 1.5rem;
  }

  .xl\:tw-leading-7{
    line-height: 1.75rem;
  }

  .xl\:tw-leading-8{
    line-height: 2rem;
  }

  .xl\:tw-leading-9{
    line-height: 2.25rem;
  }

  .xl\:tw-leading-10{
    line-height: 2.5rem;
  }

  .xl\:tw-leading-none{
    line-height: 1;
  }

  .xl\:tw-leading-tight{
    line-height: 1.25;
  }

  .xl\:tw-leading-snug{
    line-height: 1.375;
  }

  .xl\:tw-leading-normal{
    line-height: 1.5;
  }

  .xl\:tw-leading-relaxed{
    line-height: 1.625;
  }

  .xl\:tw-leading-loose{
    line-height: 2;
  }

  .xl\:tw-tracking-tighter{
    letter-spacing: -0.05em;
  }

  .xl\:tw-tracking-tight{
    letter-spacing: -0.025em;
  }

  .xl\:tw-tracking-normal{
    letter-spacing: 0;
  }

  .xl\:tw-tracking-wide{
    letter-spacing: 0.025em;
  }

  .xl\:tw-tracking-wider{
    letter-spacing: 0.05em;
  }

  .xl\:tw-tracking-widest{
    letter-spacing: 0.1em;
  }

  .xl\:tw-text-transparent{
    color: transparent;
  }

  .xl\:tw-text-current{
    color: currentColor;
  }

  .xl\:tw-text-black{
    --tw-text-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-text-opacity));
  }

  .xl\:tw-text-white{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .xl\:tw-text-gray-100{
    --tw-text-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-text-opacity));
  }

  .xl\:tw-text-gray-200{
    --tw-text-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-text-opacity));
  }

  .xl\:tw-text-gray-300{
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));
  }

  .xl\:tw-text-gray-400{
    --tw-text-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-text-opacity));
  }

  .xl\:tw-text-gray-500{
    --tw-text-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-text-opacity));
  }

  .xl\:tw-text-gray-600{
    --tw-text-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-text-opacity));
  }

  .xl\:tw-text-gray-700{
    --tw-text-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-text-opacity));
  }

  .xl\:tw-text-gray-800{
    --tw-text-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-text-opacity));
  }

  .xl\:tw-text-gray-900{
    --tw-text-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-text-opacity));
  }

  .xl\:tw-text-blue-100{
    --tw-text-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-text-opacity));
  }

  .xl\:tw-text-blue-200{
    --tw-text-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-text-opacity));
  }

  .xl\:tw-text-blue-300{
    --tw-text-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-text-opacity));
  }

  .xl\:tw-text-blue-400{
    --tw-text-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-text-opacity));
  }

  .xl\:tw-text-blue-500{
    --tw-text-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-text-opacity));
  }

  .xl\:tw-text-blue-600{
    --tw-text-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-text-opacity));
  }

  .xl\:tw-text-blue-700{
    --tw-text-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-text-opacity));
  }

  .xl\:tw-text-blue-800{
    --tw-text-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-text-opacity));
  }

  .xl\:tw-text-blue-900{
    --tw-text-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-text-opacity));
  }

  .xl\:hover\:tw-text-transparent:hover{
    color: transparent;
  }

  .xl\:hover\:tw-text-current:hover{
    color: currentColor;
  }

  .xl\:hover\:tw-text-black:hover{
    --tw-text-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-text-opacity));
  }

  .xl\:hover\:tw-text-white:hover{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .xl\:hover\:tw-text-gray-100:hover{
    --tw-text-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-text-opacity));
  }

  .xl\:hover\:tw-text-gray-200:hover{
    --tw-text-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-text-opacity));
  }

  .xl\:hover\:tw-text-gray-300:hover{
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));
  }

  .xl\:hover\:tw-text-gray-400:hover{
    --tw-text-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-text-opacity));
  }

  .xl\:hover\:tw-text-gray-500:hover{
    --tw-text-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-text-opacity));
  }

  .xl\:hover\:tw-text-gray-600:hover{
    --tw-text-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-text-opacity));
  }

  .xl\:hover\:tw-text-gray-700:hover{
    --tw-text-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-text-opacity));
  }

  .xl\:hover\:tw-text-gray-800:hover{
    --tw-text-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-text-opacity));
  }

  .xl\:hover\:tw-text-gray-900:hover{
    --tw-text-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-text-opacity));
  }

  .xl\:hover\:tw-text-blue-100:hover{
    --tw-text-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-text-opacity));
  }

  .xl\:hover\:tw-text-blue-200:hover{
    --tw-text-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-text-opacity));
  }

  .xl\:hover\:tw-text-blue-300:hover{
    --tw-text-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-text-opacity));
  }

  .xl\:hover\:tw-text-blue-400:hover{
    --tw-text-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-text-opacity));
  }

  .xl\:hover\:tw-text-blue-500:hover{
    --tw-text-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-text-opacity));
  }

  .xl\:hover\:tw-text-blue-600:hover{
    --tw-text-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-text-opacity));
  }

  .xl\:hover\:tw-text-blue-700:hover{
    --tw-text-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-text-opacity));
  }

  .xl\:hover\:tw-text-blue-800:hover{
    --tw-text-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-text-opacity));
  }

  .xl\:hover\:tw-text-blue-900:hover{
    --tw-text-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-text-opacity));
  }

  .xl\:focus\:tw-text-transparent:focus{
    color: transparent;
  }

  .xl\:focus\:tw-text-current:focus{
    color: currentColor;
  }

  .xl\:focus\:tw-text-black:focus{
    --tw-text-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-text-opacity));
  }

  .xl\:focus\:tw-text-white:focus{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .xl\:focus\:tw-text-gray-100:focus{
    --tw-text-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-text-opacity));
  }

  .xl\:focus\:tw-text-gray-200:focus{
    --tw-text-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-text-opacity));
  }

  .xl\:focus\:tw-text-gray-300:focus{
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));
  }

  .xl\:focus\:tw-text-gray-400:focus{
    --tw-text-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-text-opacity));
  }

  .xl\:focus\:tw-text-gray-500:focus{
    --tw-text-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-text-opacity));
  }

  .xl\:focus\:tw-text-gray-600:focus{
    --tw-text-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-text-opacity));
  }

  .xl\:focus\:tw-text-gray-700:focus{
    --tw-text-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-text-opacity));
  }

  .xl\:focus\:tw-text-gray-800:focus{
    --tw-text-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-text-opacity));
  }

  .xl\:focus\:tw-text-gray-900:focus{
    --tw-text-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-text-opacity));
  }

  .xl\:focus\:tw-text-blue-100:focus{
    --tw-text-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-text-opacity));
  }

  .xl\:focus\:tw-text-blue-200:focus{
    --tw-text-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-text-opacity));
  }

  .xl\:focus\:tw-text-blue-300:focus{
    --tw-text-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-text-opacity));
  }

  .xl\:focus\:tw-text-blue-400:focus{
    --tw-text-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-text-opacity));
  }

  .xl\:focus\:tw-text-blue-500:focus{
    --tw-text-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-text-opacity));
  }

  .xl\:focus\:tw-text-blue-600:focus{
    --tw-text-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-text-opacity));
  }

  .xl\:focus\:tw-text-blue-700:focus{
    --tw-text-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-text-opacity));
  }

  .xl\:focus\:tw-text-blue-800:focus{
    --tw-text-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-text-opacity));
  }

  .xl\:focus\:tw-text-blue-900:focus{
    --tw-text-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-text-opacity));
  }

  .xl\:tw-text-opacity-0{
    --tw-text-opacity: 0;
  }

  .xl\:tw-text-opacity-25{
    --tw-text-opacity: 0.25;
  }

  .xl\:tw-text-opacity-50{
    --tw-text-opacity: 0.5;
  }

  .xl\:tw-text-opacity-75{
    --tw-text-opacity: 0.75;
  }

  .xl\:tw-text-opacity-90{
    --tw-text-opacity: 0.9;
  }

  .xl\:tw-text-opacity-100{
    --tw-text-opacity: 1;
  }

  .xl\:hover\:tw-text-opacity-0:hover{
    --tw-text-opacity: 0;
  }

  .xl\:hover\:tw-text-opacity-25:hover{
    --tw-text-opacity: 0.25;
  }

  .xl\:hover\:tw-text-opacity-50:hover{
    --tw-text-opacity: 0.5;
  }

  .xl\:hover\:tw-text-opacity-75:hover{
    --tw-text-opacity: 0.75;
  }

  .xl\:hover\:tw-text-opacity-90:hover{
    --tw-text-opacity: 0.9;
  }

  .xl\:hover\:tw-text-opacity-100:hover{
    --tw-text-opacity: 1;
  }

  .xl\:focus\:tw-text-opacity-0:focus{
    --tw-text-opacity: 0;
  }

  .xl\:focus\:tw-text-opacity-25:focus{
    --tw-text-opacity: 0.25;
  }

  .xl\:focus\:tw-text-opacity-50:focus{
    --tw-text-opacity: 0.5;
  }

  .xl\:focus\:tw-text-opacity-75:focus{
    --tw-text-opacity: 0.75;
  }

  .xl\:focus\:tw-text-opacity-90:focus{
    --tw-text-opacity: 0.9;
  }

  .xl\:focus\:tw-text-opacity-100:focus{
    --tw-text-opacity: 1;
  }

  .xl\:tw-underline{
    text-decoration: underline;
  }

  .xl\:tw-line-through{
    text-decoration: line-through;
  }

  .xl\:tw-no-underline{
    text-decoration: none;
  }

  .xl\:hover\:tw-underline:hover{
    text-decoration: underline;
  }

  .xl\:hover\:tw-line-through:hover{
    text-decoration: line-through;
  }

  .xl\:hover\:tw-no-underline:hover{
    text-decoration: none;
  }

  .xl\:focus\:tw-underline:focus{
    text-decoration: underline;
  }

  .xl\:focus\:tw-line-through:focus{
    text-decoration: line-through;
  }

  .xl\:focus\:tw-no-underline:focus{
    text-decoration: none;
  }

  .xl\:tw-antialiased{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .xl\:tw-subpixel-antialiased{
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .xl\:tw-placeholder-transparent::-webkit-input-placeholder{
    color: transparent;
  }

  .xl\:tw-placeholder-transparent::-ms-input-placeholder{
    color: transparent;
  }

  .xl\:tw-placeholder-transparent::placeholder{
    color: transparent;
  }

  .xl\:tw-placeholder-current::-webkit-input-placeholder{
    color: currentColor;
  }

  .xl\:tw-placeholder-current::-ms-input-placeholder{
    color: currentColor;
  }

  .xl\:tw-placeholder-current::placeholder{
    color: currentColor;
  }

  .xl\:tw-placeholder-black::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-black::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-black::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-white::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-white::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-white::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-100::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-200::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-300::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-400::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-500::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-600::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-700::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-800::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-900::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-100::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-200::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-300::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-400::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-500::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-600::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-700::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-800::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-900::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-transparent:focus::-webkit-input-placeholder{
    color: transparent;
  }

  .xl\:focus\:tw-placeholder-transparent:focus::-ms-input-placeholder{
    color: transparent;
  }

  .xl\:focus\:tw-placeholder-transparent:focus::placeholder{
    color: transparent;
  }

  .xl\:focus\:tw-placeholder-current:focus::-webkit-input-placeholder{
    color: currentColor;
  }

  .xl\:focus\:tw-placeholder-current:focus::-ms-input-placeholder{
    color: currentColor;
  }

  .xl\:focus\:tw-placeholder-current:focus::placeholder{
    color: currentColor;
  }

  .xl\:focus\:tw-placeholder-black:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-black:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-black:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 44, 44, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-white:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-white:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-white:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-100:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(247, 250, 252, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-200:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 242, 247, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-300:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-400:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-500:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-600:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-700:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(74, 85, 104, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-800:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(45, 55, 72, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-900:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-100:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(235, 248, 255, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-200:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 227, 248, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-300:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(144, 205, 244, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-400:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 179, 237, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-500:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(66, 153, 225, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-600:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 130, 206, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-700:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(43, 108, 176, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-800:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(44, 82, 130, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-900:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(42, 67, 101, var(--tw-placeholder-opacity));
  }

  .xl\:tw-placeholder-opacity-0::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0;
  }

  .xl\:tw-placeholder-opacity-0::-ms-input-placeholder{
    --tw-placeholder-opacity: 0;
  }

  .xl\:tw-placeholder-opacity-0::placeholder{
    --tw-placeholder-opacity: 0;
  }

  .xl\:tw-placeholder-opacity-25::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.25;
  }

  .xl\:tw-placeholder-opacity-25::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.25;
  }

  .xl\:tw-placeholder-opacity-25::placeholder{
    --tw-placeholder-opacity: 0.25;
  }

  .xl\:tw-placeholder-opacity-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.5;
  }

  .xl\:tw-placeholder-opacity-50::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.5;
  }

  .xl\:tw-placeholder-opacity-50::placeholder{
    --tw-placeholder-opacity: 0.5;
  }

  .xl\:tw-placeholder-opacity-75::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.75;
  }

  .xl\:tw-placeholder-opacity-75::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.75;
  }

  .xl\:tw-placeholder-opacity-75::placeholder{
    --tw-placeholder-opacity: 0.75;
  }

  .xl\:tw-placeholder-opacity-90::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.9;
  }

  .xl\:tw-placeholder-opacity-90::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.9;
  }

  .xl\:tw-placeholder-opacity-90::placeholder{
    --tw-placeholder-opacity: 0.9;
  }

  .xl\:tw-placeholder-opacity-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
  }

  .xl\:tw-placeholder-opacity-100::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
  }

  .xl\:tw-placeholder-opacity-100::placeholder{
    --tw-placeholder-opacity: 1;
  }

  .xl\:focus\:tw-placeholder-opacity-0:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0;
  }

  .xl\:focus\:tw-placeholder-opacity-0:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 0;
  }

  .xl\:focus\:tw-placeholder-opacity-0:focus::placeholder{
    --tw-placeholder-opacity: 0;
  }

  .xl\:focus\:tw-placeholder-opacity-25:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.25;
  }

  .xl\:focus\:tw-placeholder-opacity-25:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.25;
  }

  .xl\:focus\:tw-placeholder-opacity-25:focus::placeholder{
    --tw-placeholder-opacity: 0.25;
  }

  .xl\:focus\:tw-placeholder-opacity-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.5;
  }

  .xl\:focus\:tw-placeholder-opacity-50:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.5;
  }

  .xl\:focus\:tw-placeholder-opacity-50:focus::placeholder{
    --tw-placeholder-opacity: 0.5;
  }

  .xl\:focus\:tw-placeholder-opacity-75:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.75;
  }

  .xl\:focus\:tw-placeholder-opacity-75:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.75;
  }

  .xl\:focus\:tw-placeholder-opacity-75:focus::placeholder{
    --tw-placeholder-opacity: 0.75;
  }

  .xl\:focus\:tw-placeholder-opacity-90:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.9;
  }

  .xl\:focus\:tw-placeholder-opacity-90:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 0.9;
  }

  .xl\:focus\:tw-placeholder-opacity-90:focus::placeholder{
    --tw-placeholder-opacity: 0.9;
  }

  .xl\:focus\:tw-placeholder-opacity-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1;
  }

  .xl\:focus\:tw-placeholder-opacity-100:focus::-ms-input-placeholder{
    --tw-placeholder-opacity: 1;
  }

  .xl\:focus\:tw-placeholder-opacity-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
  }

  .xl\:tw-opacity-0{
    opacity: 0;
  }

  .xl\:tw-opacity-25{
    opacity: 0.25;
  }

  .xl\:tw-opacity-50{
    opacity: 0.5;
  }

  .xl\:tw-opacity-75{
    opacity: 0.75;
  }

  .xl\:tw-opacity-90{
    opacity: 0.9;
  }

  .xl\:tw-opacity-100{
    opacity: 1;
  }

  .xl\:hover\:tw-opacity-0:hover{
    opacity: 0;
  }

  .xl\:hover\:tw-opacity-25:hover{
    opacity: 0.25;
  }

  .xl\:hover\:tw-opacity-50:hover{
    opacity: 0.5;
  }

  .xl\:hover\:tw-opacity-75:hover{
    opacity: 0.75;
  }

  .xl\:hover\:tw-opacity-90:hover{
    opacity: 0.9;
  }

  .xl\:hover\:tw-opacity-100:hover{
    opacity: 1;
  }

  .xl\:focus\:tw-opacity-0:focus{
    opacity: 0;
  }

  .xl\:focus\:tw-opacity-25:focus{
    opacity: 0.25;
  }

  .xl\:focus\:tw-opacity-50:focus{
    opacity: 0.5;
  }

  .xl\:focus\:tw-opacity-75:focus{
    opacity: 0.75;
  }

  .xl\:focus\:tw-opacity-90:focus{
    opacity: 0.9;
  }

  .xl\:focus\:tw-opacity-100:focus{
    opacity: 1;
  }

  .xl\:tw-bg-blend-normal{
    background-blend-mode: normal;
  }

  .xl\:tw-bg-blend-multiply{
    background-blend-mode: multiply;
  }

  .xl\:tw-bg-blend-screen{
    background-blend-mode: screen;
  }

  .xl\:tw-bg-blend-overlay{
    background-blend-mode: overlay;
  }

  .xl\:tw-bg-blend-darken{
    background-blend-mode: darken;
  }

  .xl\:tw-bg-blend-lighten{
    background-blend-mode: lighten;
  }

  .xl\:tw-bg-blend-color-dodge{
    background-blend-mode: color-dodge;
  }

  .xl\:tw-bg-blend-color-burn{
    background-blend-mode: color-burn;
  }

  .xl\:tw-bg-blend-hard-light{
    background-blend-mode: hard-light;
  }

  .xl\:tw-bg-blend-soft-light{
    background-blend-mode: soft-light;
  }

  .xl\:tw-bg-blend-difference{
    background-blend-mode: difference;
  }

  .xl\:tw-bg-blend-exclusion{
    background-blend-mode: exclusion;
  }

  .xl\:tw-bg-blend-hue{
    background-blend-mode: hue;
  }

  .xl\:tw-bg-blend-saturation{
    background-blend-mode: saturation;
  }

  .xl\:tw-bg-blend-color{
    background-blend-mode: color;
  }

  .xl\:tw-bg-blend-luminosity{
    background-blend-mode: luminosity;
  }

  .xl\:tw-mix-blend-normal{
    mix-blend-mode: normal;
  }

  .xl\:tw-mix-blend-multiply{
    mix-blend-mode: multiply;
  }

  .xl\:tw-mix-blend-screen{
    mix-blend-mode: screen;
  }

  .xl\:tw-mix-blend-overlay{
    mix-blend-mode: overlay;
  }

  .xl\:tw-mix-blend-darken{
    mix-blend-mode: darken;
  }

  .xl\:tw-mix-blend-lighten{
    mix-blend-mode: lighten;
  }

  .xl\:tw-mix-blend-color-dodge{
    mix-blend-mode: color-dodge;
  }

  .xl\:tw-mix-blend-color-burn{
    mix-blend-mode: color-burn;
  }

  .xl\:tw-mix-blend-hard-light{
    mix-blend-mode: hard-light;
  }

  .xl\:tw-mix-blend-soft-light{
    mix-blend-mode: soft-light;
  }

  .xl\:tw-mix-blend-difference{
    mix-blend-mode: difference;
  }

  .xl\:tw-mix-blend-exclusion{
    mix-blend-mode: exclusion;
  }

  .xl\:tw-mix-blend-hue{
    mix-blend-mode: hue;
  }

  .xl\:tw-mix-blend-saturation{
    mix-blend-mode: saturation;
  }

  .xl\:tw-mix-blend-color{
    mix-blend-mode: color;
  }

  .xl\:tw-mix-blend-luminosity{
    mix-blend-mode: luminosity;
  }

  .xl\:tw-shadow-xs{
    --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:tw-shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:tw-shadow-default{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:tw-shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:tw-shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:tw-shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:tw-shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:tw-shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:tw-shadow-outline{
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:tw-shadow-none{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:tw-shadow-xs:hover{
    --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:tw-shadow-sm:hover{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:tw-shadow-default:hover{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:tw-shadow-md:hover{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:tw-shadow-lg:hover{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:tw-shadow-xl:hover{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:tw-shadow-2xl:hover{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:tw-shadow-inner:hover{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:tw-shadow-outline:hover{
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:tw-shadow-none:hover{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:tw-shadow-xs:focus{
    --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:tw-shadow-sm:focus{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:tw-shadow-default:focus{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:tw-shadow-md:focus{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:tw-shadow-lg:focus{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:tw-shadow-xl:focus{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:tw-shadow-2xl:focus{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:tw-shadow-inner:focus{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:tw-shadow-outline:focus{
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:tw-shadow-none:focus{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:tw-outline-none{
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .xl\:tw-outline-white{
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .xl\:tw-outline-black{
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .xl\:focus\:tw-outline-none:focus{
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .xl\:focus\:tw-outline-white:focus{
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .xl\:focus\:tw-outline-black:focus{
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .xl\:tw-ring-0{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:tw-ring-1{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:tw-ring-2{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:tw-ring-4{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:tw-ring-8{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:tw-ring{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus-within\:tw-ring-0:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus-within\:tw-ring-1:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus-within\:tw-ring-2:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus-within\:tw-ring-4:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus-within\:tw-ring-8:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus-within\:tw-ring:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus\:tw-ring-0:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus\:tw-ring-1:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus\:tw-ring-2:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus\:tw-ring-4:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus\:tw-ring-8:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus\:tw-ring:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:tw-ring-inset{
    --tw-ring-inset: inset;
  }

  .xl\:focus-within\:tw-ring-inset:focus-within{
    --tw-ring-inset: inset;
  }

  .xl\:focus\:tw-ring-inset:focus{
    --tw-ring-inset: inset;
  }

  .xl\:tw-ring-transparent{
    --tw-ring-color: transparent;
  }

  .xl\:tw-ring-current{
    --tw-ring-color: currentColor;
  }

  .xl\:tw-ring-black{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(44, 44, 44, var(--tw-ring-opacity));
  }

  .xl\:tw-ring-white{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .xl\:tw-ring-gray-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 250, 252, var(--tw-ring-opacity));
  }

  .xl\:tw-ring-gray-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 242, 247, var(--tw-ring-opacity));
  }

  .xl\:tw-ring-gray-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(226, 232, 240, var(--tw-ring-opacity));
  }

  .xl\:tw-ring-gray-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(203, 213, 224, var(--tw-ring-opacity));
  }

  .xl\:tw-ring-gray-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(160, 174, 192, var(--tw-ring-opacity));
  }

  .xl\:tw-ring-gray-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(113, 128, 150, var(--tw-ring-opacity));
  }

  .xl\:tw-ring-gray-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 85, 104, var(--tw-ring-opacity));
  }

  .xl\:tw-ring-gray-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(45, 55, 72, var(--tw-ring-opacity));
  }

  .xl\:tw-ring-gray-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 32, 44, var(--tw-ring-opacity));
  }

  .xl\:tw-ring-blue-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(235, 248, 255, var(--tw-ring-opacity));
  }

  .xl\:tw-ring-blue-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 227, 248, var(--tw-ring-opacity));
  }

  .xl\:tw-ring-blue-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 205, 244, var(--tw-ring-opacity));
  }

  .xl\:tw-ring-blue-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 179, 237, var(--tw-ring-opacity));
  }

  .xl\:tw-ring-blue-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 153, 225, var(--tw-ring-opacity));
  }

  .xl\:tw-ring-blue-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 130, 206, var(--tw-ring-opacity));
  }

  .xl\:tw-ring-blue-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(43, 108, 176, var(--tw-ring-opacity));
  }

  .xl\:tw-ring-blue-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(44, 82, 130, var(--tw-ring-opacity));
  }

  .xl\:tw-ring-blue-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(42, 67, 101, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:tw-ring-transparent:focus-within{
    --tw-ring-color: transparent;
  }

  .xl\:focus-within\:tw-ring-current:focus-within{
    --tw-ring-color: currentColor;
  }

  .xl\:focus-within\:tw-ring-black:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(44, 44, 44, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:tw-ring-white:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:tw-ring-gray-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 250, 252, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:tw-ring-gray-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 242, 247, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:tw-ring-gray-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(226, 232, 240, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:tw-ring-gray-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(203, 213, 224, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:tw-ring-gray-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(160, 174, 192, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:tw-ring-gray-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(113, 128, 150, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:tw-ring-gray-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 85, 104, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:tw-ring-gray-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(45, 55, 72, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:tw-ring-gray-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 32, 44, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:tw-ring-blue-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(235, 248, 255, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:tw-ring-blue-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 227, 248, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:tw-ring-blue-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 205, 244, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:tw-ring-blue-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 179, 237, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:tw-ring-blue-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 153, 225, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:tw-ring-blue-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 130, 206, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:tw-ring-blue-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(43, 108, 176, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:tw-ring-blue-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(44, 82, 130, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:tw-ring-blue-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(42, 67, 101, var(--tw-ring-opacity));
  }

  .xl\:focus\:tw-ring-transparent:focus{
    --tw-ring-color: transparent;
  }

  .xl\:focus\:tw-ring-current:focus{
    --tw-ring-color: currentColor;
  }

  .xl\:focus\:tw-ring-black:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(44, 44, 44, var(--tw-ring-opacity));
  }

  .xl\:focus\:tw-ring-white:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .xl\:focus\:tw-ring-gray-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(247, 250, 252, var(--tw-ring-opacity));
  }

  .xl\:focus\:tw-ring-gray-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 242, 247, var(--tw-ring-opacity));
  }

  .xl\:focus\:tw-ring-gray-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(226, 232, 240, var(--tw-ring-opacity));
  }

  .xl\:focus\:tw-ring-gray-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(203, 213, 224, var(--tw-ring-opacity));
  }

  .xl\:focus\:tw-ring-gray-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(160, 174, 192, var(--tw-ring-opacity));
  }

  .xl\:focus\:tw-ring-gray-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(113, 128, 150, var(--tw-ring-opacity));
  }

  .xl\:focus\:tw-ring-gray-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(74, 85, 104, var(--tw-ring-opacity));
  }

  .xl\:focus\:tw-ring-gray-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(45, 55, 72, var(--tw-ring-opacity));
  }

  .xl\:focus\:tw-ring-gray-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(26, 32, 44, var(--tw-ring-opacity));
  }

  .xl\:focus\:tw-ring-blue-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(235, 248, 255, var(--tw-ring-opacity));
  }

  .xl\:focus\:tw-ring-blue-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 227, 248, var(--tw-ring-opacity));
  }

  .xl\:focus\:tw-ring-blue-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(144, 205, 244, var(--tw-ring-opacity));
  }

  .xl\:focus\:tw-ring-blue-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 179, 237, var(--tw-ring-opacity));
  }

  .xl\:focus\:tw-ring-blue-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(66, 153, 225, var(--tw-ring-opacity));
  }

  .xl\:focus\:tw-ring-blue-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 130, 206, var(--tw-ring-opacity));
  }

  .xl\:focus\:tw-ring-blue-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(43, 108, 176, var(--tw-ring-opacity));
  }

  .xl\:focus\:tw-ring-blue-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(44, 82, 130, var(--tw-ring-opacity));
  }

  .xl\:focus\:tw-ring-blue-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(42, 67, 101, var(--tw-ring-opacity));
  }

  .xl\:tw-ring-opacity-0{
    --tw-ring-opacity: 0;
  }

  .xl\:tw-ring-opacity-25{
    --tw-ring-opacity: 0.25;
  }

  .xl\:tw-ring-opacity-50{
    --tw-ring-opacity: 0.5;
  }

  .xl\:tw-ring-opacity-75{
    --tw-ring-opacity: 0.75;
  }

  .xl\:tw-ring-opacity-90{
    --tw-ring-opacity: 0.9;
  }

  .xl\:tw-ring-opacity-100{
    --tw-ring-opacity: 1;
  }

  .xl\:focus-within\:tw-ring-opacity-0:focus-within{
    --tw-ring-opacity: 0;
  }

  .xl\:focus-within\:tw-ring-opacity-25:focus-within{
    --tw-ring-opacity: 0.25;
  }

  .xl\:focus-within\:tw-ring-opacity-50:focus-within{
    --tw-ring-opacity: 0.5;
  }

  .xl\:focus-within\:tw-ring-opacity-75:focus-within{
    --tw-ring-opacity: 0.75;
  }

  .xl\:focus-within\:tw-ring-opacity-90:focus-within{
    --tw-ring-opacity: 0.9;
  }

  .xl\:focus-within\:tw-ring-opacity-100:focus-within{
    --tw-ring-opacity: 1;
  }

  .xl\:focus\:tw-ring-opacity-0:focus{
    --tw-ring-opacity: 0;
  }

  .xl\:focus\:tw-ring-opacity-25:focus{
    --tw-ring-opacity: 0.25;
  }

  .xl\:focus\:tw-ring-opacity-50:focus{
    --tw-ring-opacity: 0.5;
  }

  .xl\:focus\:tw-ring-opacity-75:focus{
    --tw-ring-opacity: 0.75;
  }

  .xl\:focus\:tw-ring-opacity-90:focus{
    --tw-ring-opacity: 0.9;
  }

  .xl\:focus\:tw-ring-opacity-100:focus{
    --tw-ring-opacity: 1;
  }

  .xl\:tw-ring-offset-0{
    --tw-ring-offset-width: 0px;
  }

  .xl\:tw-ring-offset-1{
    --tw-ring-offset-width: 1px;
  }

  .xl\:tw-ring-offset-2{
    --tw-ring-offset-width: 2px;
  }

  .xl\:tw-ring-offset-4{
    --tw-ring-offset-width: 4px;
  }

  .xl\:tw-ring-offset-8{
    --tw-ring-offset-width: 8px;
  }

  .xl\:focus-within\:tw-ring-offset-0:focus-within{
    --tw-ring-offset-width: 0px;
  }

  .xl\:focus-within\:tw-ring-offset-1:focus-within{
    --tw-ring-offset-width: 1px;
  }

  .xl\:focus-within\:tw-ring-offset-2:focus-within{
    --tw-ring-offset-width: 2px;
  }

  .xl\:focus-within\:tw-ring-offset-4:focus-within{
    --tw-ring-offset-width: 4px;
  }

  .xl\:focus-within\:tw-ring-offset-8:focus-within{
    --tw-ring-offset-width: 8px;
  }

  .xl\:focus\:tw-ring-offset-0:focus{
    --tw-ring-offset-width: 0px;
  }

  .xl\:focus\:tw-ring-offset-1:focus{
    --tw-ring-offset-width: 1px;
  }

  .xl\:focus\:tw-ring-offset-2:focus{
    --tw-ring-offset-width: 2px;
  }

  .xl\:focus\:tw-ring-offset-4:focus{
    --tw-ring-offset-width: 4px;
  }

  .xl\:focus\:tw-ring-offset-8:focus{
    --tw-ring-offset-width: 8px;
  }

  .xl\:tw-ring-offset-transparent{
    --tw-ring-offset-color: transparent;
  }

  .xl\:tw-ring-offset-current{
    --tw-ring-offset-color: currentColor;
  }

  .xl\:tw-ring-offset-black{
    --tw-ring-offset-color: #2c2c2c;
  }

  .xl\:tw-ring-offset-white{
    --tw-ring-offset-color: #fff;
  }

  .xl\:tw-ring-offset-gray-100{
    --tw-ring-offset-color: #f7fafc;
  }

  .xl\:tw-ring-offset-gray-200{
    --tw-ring-offset-color: #edf2f7;
  }

  .xl\:tw-ring-offset-gray-300{
    --tw-ring-offset-color: #e2e8f0;
  }

  .xl\:tw-ring-offset-gray-400{
    --tw-ring-offset-color: #cbd5e0;
  }

  .xl\:tw-ring-offset-gray-500{
    --tw-ring-offset-color: #a0aec0;
  }

  .xl\:tw-ring-offset-gray-600{
    --tw-ring-offset-color: #718096;
  }

  .xl\:tw-ring-offset-gray-700{
    --tw-ring-offset-color: #4a5568;
  }

  .xl\:tw-ring-offset-gray-800{
    --tw-ring-offset-color: #2d3748;
  }

  .xl\:tw-ring-offset-gray-900{
    --tw-ring-offset-color: #1a202c;
  }

  .xl\:tw-ring-offset-blue-100{
    --tw-ring-offset-color: #ebf8ff;
  }

  .xl\:tw-ring-offset-blue-200{
    --tw-ring-offset-color: #bee3f8;
  }

  .xl\:tw-ring-offset-blue-300{
    --tw-ring-offset-color: #90cdf4;
  }

  .xl\:tw-ring-offset-blue-400{
    --tw-ring-offset-color: #63b3ed;
  }

  .xl\:tw-ring-offset-blue-500{
    --tw-ring-offset-color: #4299e1;
  }

  .xl\:tw-ring-offset-blue-600{
    --tw-ring-offset-color: #3182ce;
  }

  .xl\:tw-ring-offset-blue-700{
    --tw-ring-offset-color: #2b6cb0;
  }

  .xl\:tw-ring-offset-blue-800{
    --tw-ring-offset-color: #2c5282;
  }

  .xl\:tw-ring-offset-blue-900{
    --tw-ring-offset-color: #2a4365;
  }

  .xl\:focus-within\:tw-ring-offset-transparent:focus-within{
    --tw-ring-offset-color: transparent;
  }

  .xl\:focus-within\:tw-ring-offset-current:focus-within{
    --tw-ring-offset-color: currentColor;
  }

  .xl\:focus-within\:tw-ring-offset-black:focus-within{
    --tw-ring-offset-color: #2c2c2c;
  }

  .xl\:focus-within\:tw-ring-offset-white:focus-within{
    --tw-ring-offset-color: #fff;
  }

  .xl\:focus-within\:tw-ring-offset-gray-100:focus-within{
    --tw-ring-offset-color: #f7fafc;
  }

  .xl\:focus-within\:tw-ring-offset-gray-200:focus-within{
    --tw-ring-offset-color: #edf2f7;
  }

  .xl\:focus-within\:tw-ring-offset-gray-300:focus-within{
    --tw-ring-offset-color: #e2e8f0;
  }

  .xl\:focus-within\:tw-ring-offset-gray-400:focus-within{
    --tw-ring-offset-color: #cbd5e0;
  }

  .xl\:focus-within\:tw-ring-offset-gray-500:focus-within{
    --tw-ring-offset-color: #a0aec0;
  }

  .xl\:focus-within\:tw-ring-offset-gray-600:focus-within{
    --tw-ring-offset-color: #718096;
  }

  .xl\:focus-within\:tw-ring-offset-gray-700:focus-within{
    --tw-ring-offset-color: #4a5568;
  }

  .xl\:focus-within\:tw-ring-offset-gray-800:focus-within{
    --tw-ring-offset-color: #2d3748;
  }

  .xl\:focus-within\:tw-ring-offset-gray-900:focus-within{
    --tw-ring-offset-color: #1a202c;
  }

  .xl\:focus-within\:tw-ring-offset-blue-100:focus-within{
    --tw-ring-offset-color: #ebf8ff;
  }

  .xl\:focus-within\:tw-ring-offset-blue-200:focus-within{
    --tw-ring-offset-color: #bee3f8;
  }

  .xl\:focus-within\:tw-ring-offset-blue-300:focus-within{
    --tw-ring-offset-color: #90cdf4;
  }

  .xl\:focus-within\:tw-ring-offset-blue-400:focus-within{
    --tw-ring-offset-color: #63b3ed;
  }

  .xl\:focus-within\:tw-ring-offset-blue-500:focus-within{
    --tw-ring-offset-color: #4299e1;
  }

  .xl\:focus-within\:tw-ring-offset-blue-600:focus-within{
    --tw-ring-offset-color: #3182ce;
  }

  .xl\:focus-within\:tw-ring-offset-blue-700:focus-within{
    --tw-ring-offset-color: #2b6cb0;
  }

  .xl\:focus-within\:tw-ring-offset-blue-800:focus-within{
    --tw-ring-offset-color: #2c5282;
  }

  .xl\:focus-within\:tw-ring-offset-blue-900:focus-within{
    --tw-ring-offset-color: #2a4365;
  }

  .xl\:focus\:tw-ring-offset-transparent:focus{
    --tw-ring-offset-color: transparent;
  }

  .xl\:focus\:tw-ring-offset-current:focus{
    --tw-ring-offset-color: currentColor;
  }

  .xl\:focus\:tw-ring-offset-black:focus{
    --tw-ring-offset-color: #2c2c2c;
  }

  .xl\:focus\:tw-ring-offset-white:focus{
    --tw-ring-offset-color: #fff;
  }

  .xl\:focus\:tw-ring-offset-gray-100:focus{
    --tw-ring-offset-color: #f7fafc;
  }

  .xl\:focus\:tw-ring-offset-gray-200:focus{
    --tw-ring-offset-color: #edf2f7;
  }

  .xl\:focus\:tw-ring-offset-gray-300:focus{
    --tw-ring-offset-color: #e2e8f0;
  }

  .xl\:focus\:tw-ring-offset-gray-400:focus{
    --tw-ring-offset-color: #cbd5e0;
  }

  .xl\:focus\:tw-ring-offset-gray-500:focus{
    --tw-ring-offset-color: #a0aec0;
  }

  .xl\:focus\:tw-ring-offset-gray-600:focus{
    --tw-ring-offset-color: #718096;
  }

  .xl\:focus\:tw-ring-offset-gray-700:focus{
    --tw-ring-offset-color: #4a5568;
  }

  .xl\:focus\:tw-ring-offset-gray-800:focus{
    --tw-ring-offset-color: #2d3748;
  }

  .xl\:focus\:tw-ring-offset-gray-900:focus{
    --tw-ring-offset-color: #1a202c;
  }

  .xl\:focus\:tw-ring-offset-blue-100:focus{
    --tw-ring-offset-color: #ebf8ff;
  }

  .xl\:focus\:tw-ring-offset-blue-200:focus{
    --tw-ring-offset-color: #bee3f8;
  }

  .xl\:focus\:tw-ring-offset-blue-300:focus{
    --tw-ring-offset-color: #90cdf4;
  }

  .xl\:focus\:tw-ring-offset-blue-400:focus{
    --tw-ring-offset-color: #63b3ed;
  }

  .xl\:focus\:tw-ring-offset-blue-500:focus{
    --tw-ring-offset-color: #4299e1;
  }

  .xl\:focus\:tw-ring-offset-blue-600:focus{
    --tw-ring-offset-color: #3182ce;
  }

  .xl\:focus\:tw-ring-offset-blue-700:focus{
    --tw-ring-offset-color: #2b6cb0;
  }

  .xl\:focus\:tw-ring-offset-blue-800:focus{
    --tw-ring-offset-color: #2c5282;
  }

  .xl\:focus\:tw-ring-offset-blue-900:focus{
    --tw-ring-offset-color: #2a4365;
  }

  .xl\:tw-filter{
    --tw-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
    -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:tw-filter-none{
    -webkit-filter: none;
    filter: none;
  }

  .xl\:tw-blur-0{
    --tw-blur: blur(0);
  }

  .xl\:tw-blur-none{
    --tw-blur: blur(0);
  }

  .xl\:tw-blur-sm{
    --tw-blur: blur(4px);
  }

  .xl\:tw-blur{
    --tw-blur: blur(8px);
  }

  .xl\:tw-blur-md{
    --tw-blur: blur(12px);
  }

  .xl\:tw-blur-lg{
    --tw-blur: blur(16px);
  }

  .xl\:tw-blur-xl{
    --tw-blur: blur(24px);
  }

  .xl\:tw-blur-2xl{
    --tw-blur: blur(40px);
  }

  .xl\:tw-blur-3xl{
    --tw-blur: blur(64px);
  }

  .xl\:tw-brightness-0{
    --tw-brightness: brightness(0);
  }

  .xl\:tw-brightness-50{
    --tw-brightness: brightness(.5);
  }

  .xl\:tw-brightness-75{
    --tw-brightness: brightness(.75);
  }

  .xl\:tw-brightness-90{
    --tw-brightness: brightness(.9);
  }

  .xl\:tw-brightness-95{
    --tw-brightness: brightness(.95);
  }

  .xl\:tw-brightness-100{
    --tw-brightness: brightness(1);
  }

  .xl\:tw-brightness-105{
    --tw-brightness: brightness(1.05);
  }

  .xl\:tw-brightness-110{
    --tw-brightness: brightness(1.1);
  }

  .xl\:tw-brightness-125{
    --tw-brightness: brightness(1.25);
  }

  .xl\:tw-brightness-150{
    --tw-brightness: brightness(1.5);
  }

  .xl\:tw-brightness-200{
    --tw-brightness: brightness(2);
  }

  .xl\:tw-contrast-0{
    --tw-contrast: contrast(0);
  }

  .xl\:tw-contrast-50{
    --tw-contrast: contrast(.5);
  }

  .xl\:tw-contrast-75{
    --tw-contrast: contrast(.75);
  }

  .xl\:tw-contrast-100{
    --tw-contrast: contrast(1);
  }

  .xl\:tw-contrast-125{
    --tw-contrast: contrast(1.25);
  }

  .xl\:tw-contrast-150{
    --tw-contrast: contrast(1.5);
  }

  .xl\:tw-contrast-200{
    --tw-contrast: contrast(2);
  }

  .xl\:tw-drop-shadow-sm{
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05));
  }

  .xl\:tw-drop-shadow{
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
  }

  .xl\:tw-drop-shadow-md{
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
  }

  .xl\:tw-drop-shadow-lg{
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
  }

  .xl\:tw-drop-shadow-xl{
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
  }

  .xl\:tw-drop-shadow-2xl{
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15));
  }

  .xl\:tw-drop-shadow-none{
    --tw-drop-shadow: drop-shadow(0 0 #0000);
  }

  .xl\:tw-grayscale-0{
    --tw-grayscale: grayscale(0);
  }

  .xl\:tw-grayscale{
    --tw-grayscale: grayscale(100%);
  }

  .xl\:tw-hue-rotate-0{
    --tw-hue-rotate: hue-rotate(0deg);
  }

  .xl\:tw-hue-rotate-15{
    --tw-hue-rotate: hue-rotate(15deg);
  }

  .xl\:tw-hue-rotate-30{
    --tw-hue-rotate: hue-rotate(30deg);
  }

  .xl\:tw-hue-rotate-60{
    --tw-hue-rotate: hue-rotate(60deg);
  }

  .xl\:tw-hue-rotate-90{
    --tw-hue-rotate: hue-rotate(90deg);
  }

  .xl\:tw-hue-rotate-180{
    --tw-hue-rotate: hue-rotate(180deg);
  }

  .xl\:tw--hue-rotate-180{
    --tw-hue-rotate: hue-rotate(-180deg);
  }

  .xl\:tw--hue-rotate-90{
    --tw-hue-rotate: hue-rotate(-90deg);
  }

  .xl\:tw--hue-rotate-60{
    --tw-hue-rotate: hue-rotate(-60deg);
  }

  .xl\:tw--hue-rotate-30{
    --tw-hue-rotate: hue-rotate(-30deg);
  }

  .xl\:tw--hue-rotate-15{
    --tw-hue-rotate: hue-rotate(-15deg);
  }

  .xl\:tw-invert-0{
    --tw-invert: invert(0);
  }

  .xl\:tw-invert{
    --tw-invert: invert(100%);
  }

  .xl\:tw-saturate-0{
    --tw-saturate: saturate(0);
  }

  .xl\:tw-saturate-50{
    --tw-saturate: saturate(.5);
  }

  .xl\:tw-saturate-100{
    --tw-saturate: saturate(1);
  }

  .xl\:tw-saturate-150{
    --tw-saturate: saturate(1.5);
  }

  .xl\:tw-saturate-200{
    --tw-saturate: saturate(2);
  }

  .xl\:tw-sepia-0{
    --tw-sepia: sepia(0);
  }

  .xl\:tw-sepia{
    --tw-sepia: sepia(100%);
  }

  .xl\:tw-backdrop-filter{
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:tw-backdrop-filter-none{
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
  }

  .xl\:tw-backdrop-blur-0{
    --tw-backdrop-blur: blur(0);
  }

  .xl\:tw-backdrop-blur-none{
    --tw-backdrop-blur: blur(0);
  }

  .xl\:tw-backdrop-blur-sm{
    --tw-backdrop-blur: blur(4px);
  }

  .xl\:tw-backdrop-blur{
    --tw-backdrop-blur: blur(8px);
  }

  .xl\:tw-backdrop-blur-md{
    --tw-backdrop-blur: blur(12px);
  }

  .xl\:tw-backdrop-blur-lg{
    --tw-backdrop-blur: blur(16px);
  }

  .xl\:tw-backdrop-blur-xl{
    --tw-backdrop-blur: blur(24px);
  }

  .xl\:tw-backdrop-blur-2xl{
    --tw-backdrop-blur: blur(40px);
  }

  .xl\:tw-backdrop-blur-3xl{
    --tw-backdrop-blur: blur(64px);
  }

  .xl\:tw-backdrop-brightness-0{
    --tw-backdrop-brightness: brightness(0);
  }

  .xl\:tw-backdrop-brightness-50{
    --tw-backdrop-brightness: brightness(.5);
  }

  .xl\:tw-backdrop-brightness-75{
    --tw-backdrop-brightness: brightness(.75);
  }

  .xl\:tw-backdrop-brightness-90{
    --tw-backdrop-brightness: brightness(.9);
  }

  .xl\:tw-backdrop-brightness-95{
    --tw-backdrop-brightness: brightness(.95);
  }

  .xl\:tw-backdrop-brightness-100{
    --tw-backdrop-brightness: brightness(1);
  }

  .xl\:tw-backdrop-brightness-105{
    --tw-backdrop-brightness: brightness(1.05);
  }

  .xl\:tw-backdrop-brightness-110{
    --tw-backdrop-brightness: brightness(1.1);
  }

  .xl\:tw-backdrop-brightness-125{
    --tw-backdrop-brightness: brightness(1.25);
  }

  .xl\:tw-backdrop-brightness-150{
    --tw-backdrop-brightness: brightness(1.5);
  }

  .xl\:tw-backdrop-brightness-200{
    --tw-backdrop-brightness: brightness(2);
  }

  .xl\:tw-backdrop-contrast-0{
    --tw-backdrop-contrast: contrast(0);
  }

  .xl\:tw-backdrop-contrast-50{
    --tw-backdrop-contrast: contrast(.5);
  }

  .xl\:tw-backdrop-contrast-75{
    --tw-backdrop-contrast: contrast(.75);
  }

  .xl\:tw-backdrop-contrast-100{
    --tw-backdrop-contrast: contrast(1);
  }

  .xl\:tw-backdrop-contrast-125{
    --tw-backdrop-contrast: contrast(1.25);
  }

  .xl\:tw-backdrop-contrast-150{
    --tw-backdrop-contrast: contrast(1.5);
  }

  .xl\:tw-backdrop-contrast-200{
    --tw-backdrop-contrast: contrast(2);
  }

  .xl\:tw-backdrop-grayscale-0{
    --tw-backdrop-grayscale: grayscale(0);
  }

  .xl\:tw-backdrop-grayscale{
    --tw-backdrop-grayscale: grayscale(100%);
  }

  .xl\:tw-backdrop-hue-rotate-0{
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
  }

  .xl\:tw-backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
  }

  .xl\:tw-backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
  }

  .xl\:tw-backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
  }

  .xl\:tw-backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
  }

  .xl\:tw-backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
  }

  .xl\:tw--backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  }

  .xl\:tw--backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  }

  .xl\:tw--backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  }

  .xl\:tw--backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  }

  .xl\:tw--backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  }

  .xl\:tw-backdrop-invert-0{
    --tw-backdrop-invert: invert(0);
  }

  .xl\:tw-backdrop-invert{
    --tw-backdrop-invert: invert(100%);
  }

  .xl\:tw-backdrop-opacity-0{
    --tw-backdrop-opacity: opacity(0);
  }

  .xl\:tw-backdrop-opacity-25{
    --tw-backdrop-opacity: opacity(0.25);
  }

  .xl\:tw-backdrop-opacity-50{
    --tw-backdrop-opacity: opacity(0.5);
  }

  .xl\:tw-backdrop-opacity-75{
    --tw-backdrop-opacity: opacity(0.75);
  }

  .xl\:tw-backdrop-opacity-90{
    --tw-backdrop-opacity: opacity(0.9);
  }

  .xl\:tw-backdrop-opacity-100{
    --tw-backdrop-opacity: opacity(1);
  }

  .xl\:tw-backdrop-saturate-0{
    --tw-backdrop-saturate: saturate(0);
  }

  .xl\:tw-backdrop-saturate-50{
    --tw-backdrop-saturate: saturate(.5);
  }

  .xl\:tw-backdrop-saturate-100{
    --tw-backdrop-saturate: saturate(1);
  }

  .xl\:tw-backdrop-saturate-150{
    --tw-backdrop-saturate: saturate(1.5);
  }

  .xl\:tw-backdrop-saturate-200{
    --tw-backdrop-saturate: saturate(2);
  }

  .xl\:tw-backdrop-sepia-0{
    --tw-backdrop-sepia: sepia(0);
  }

  .xl\:tw-backdrop-sepia{
    --tw-backdrop-sepia: sepia(100%);
  }

  .xl\:tw-transition-none{
    transition-property: none;
  }

  .xl\:tw-transition-all{
    transition-property: all;
  }

  .xl\:tw-transition-default{
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  }

  .xl\:tw-transition-colors{
    transition-property: background-color, border-color, color, fill, stroke;
  }

  .xl\:tw-transition-opacity{
    transition-property: opacity;
  }

  .xl\:tw-transition-shadow{
    transition-property: box-shadow;
  }

  .xl\:tw-transition-transform{
    transition-property: transform;
  }

  .xl\:tw-delay-75{
    transition-delay: 75ms;
  }

  .xl\:tw-delay-100{
    transition-delay: 100ms;
  }

  .xl\:tw-delay-150{
    transition-delay: 150ms;
  }

  .xl\:tw-delay-200{
    transition-delay: 200ms;
  }

  .xl\:tw-delay-300{
    transition-delay: 300ms;
  }

  .xl\:tw-delay-500{
    transition-delay: 500ms;
  }

  .xl\:tw-delay-700{
    transition-delay: 700ms;
  }

  .xl\:tw-delay-1000{
    transition-delay: 1000ms;
  }

  .xl\:tw-duration-75{
    transition-duration: 75ms;
  }

  .xl\:tw-duration-100{
    transition-duration: 100ms;
  }

  .xl\:tw-duration-150{
    transition-duration: 150ms;
  }

  .xl\:tw-duration-200{
    transition-duration: 200ms;
  }

  .xl\:tw-duration-300{
    transition-duration: 300ms;
  }

  .xl\:tw-duration-500{
    transition-duration: 500ms;
  }

  .xl\:tw-duration-700{
    transition-duration: 700ms;
  }

  .xl\:tw-duration-1000{
    transition-duration: 1000ms;
  }

  .xl\:tw-ease-linear{
    transition-timing-function: linear;
  }

  .xl\:tw-ease-in{
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .xl\:tw-ease-out{
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .xl\:tw-ease-in-out{
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}

/* purgecss end ignore */
