/* purgecss start ignore */

.modal {
  z-index: 100000;
}

.modalContent {
  width: 100%;
}

/* purgecss end ignore */
